import {
  electricPurple500,
  scarpaGray50,
  scarpaGray500,
  scarpaGray600,
  scarpaGray700,
  scarpaGray800,
  maximumYellow500,
  maximumYellow50,
  maximumYellow800,
  white,
  crystal400,
  spanishViridian25,
  crystal700,
  cobalBlue25,
  cobalBlue200,
  cobalBlue700,
  tomatoCoral50,
  tomatoCoral600,
  scarpaGray300,
  electricPurple50,
} from '@habitech/shared';
import styled from 'styled-components';
import {
  brightTurquoise000,
  brightTurquoise700,
  disabled2,
  tomatoCoral200,
} from '../../../../styles/stylesConstants';

export const WrapperUpdateLogic = styled.div`
  display: grid;
  grid-template-columns: repeat(9, 1fr);
  gap: 24px;

  .container-table {
    grid-column: 3/10;
    max-width: 700px;
    .disabled {
      border: 1px solid ${scarpaGray300};
      border: 1px solid ${scarpaGray300};
      text-align: center;
      width: 104px;
      padding-left: 15px;
      input {
        opacity: 0.6;
        color: ${scarpaGray500};
        font-weight: 600;
        height: 40px;
      }
    }
  }
  table {
    thead {
      tr > th {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 20px;
        color: ${scarpaGray800};
      }
    }
    tbody {
      tr {
        th {
          padding: 12px 24px;
          font-family: 'Montserrat';
          font-style: normal;
          font-weight: 600;
          font-size: 13px;
          line-height: 20px;
          color: ${scarpaGray800};
        }
      }
    }
  }
  .dropdown-el.extra-large {
    min-height: 44px !important;
    top: -5px !important;
  }
  .dropdown-el label:nth-child(2),
  .dropdown-el.expanded label,
  .dropdown-el.extra-large input:checked + label,
  .dropdown-el.large input:checked + label {
    font-size: 14px;
  }
  .dropdown-el label {
    line-height: 3rem !important;
  }
  .dropdown-el.extra-large input:checked + label:nth-child(2) {
    margin-top: 8px !important;
  }
  .dropdown-el.large input:checked + label:nth-child(2) {
    margin-top: 0 !important;
  }
`;

export const SectionEdit = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 46px;

  input {
    max-width: 84px;
  }
  .actions {
    width: 92px;
    display: grid;
    padding: 0 14px;
    height: 36px;
    align-self: center;
  }
  button {
    border-radius: 6px;
  }
`;

export const WrapperInputQuota = styled.div`
  display: grid;
  grid-template-columns: 37px 44px 37px;
  border: 1px solid ${electricPurple500};
  border-radius: 6px;
  height: 40px;
  width: 116px;

  .disabled-more {
    filter: grayscale(1);
  }
  input {
    max-width: 44px;
    border: none;
    text-align: center;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: ${scarpaGray600};

    &:disabled {
      background-color: ${white};
    }
  }

  .less {
    width: 36px;
    height: 40px;
    border-right: 1px solid ${electricPurple500};
    display: grid;
    border-radius: 6px 0 0 6px;
    justify-content: center;
    background-color: ${white};
    align-items: center;
    font-size: 24px;
    color: ${electricPurple500};
    &:hover {
      cursor: pointer;
    }
  }
  .more {
    width: 36px;
    height: 40px;
    border-left: 1px solid ${electricPurple500};
    display: grid;
    justify-content: center;
    align-items: center;
    color: ${electricPurple500};
    font-size: 24px;

    &:hover {
      cursor: pointer;
    }
  }
  .positive {
    background-color: ${electricPurple50};
    border-radius: 0 6px 6px 0;
    width: 33px;
  }
`;

interface ActionButtonProps {
  save: boolean;
}

export const ActionButton = styled.div<ActionButtonProps>`
  width: 34px;
  height: 34px;
  background: ${(props) => (props.save ? brightTurquoise000 : white)};
  border: 1px solid ${(props) => (props.save ? brightTurquoise000 : disabled2)};
  border-radius: 8px;
  display: grid;
  justify-content: center;
  align-items: center;
  .icon {
    font-size: 18px;
  }
  .edit {
    color: ${electricPurple500};
  }
  .save {
    color: ${brightTurquoise700};
  }
  &:hover {
    cursor: pointer;
  }
`;

export const SubTitle = styled.div`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: ${scarpaGray800};
  margin-bottom: 4px;
`;

export const WrapperDescription = styled.div`
  grid-column: 1/3;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 18px;
  color: ${scarpaGray500};
`;

export const IndicatorList = styled.div<IIndicatorsListProps>`
  display: grid;
  justify-content: center;
  align-items: center;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  width: 129px;
  height: 40px;
  border-radius: 6px;
  color: ${(props) => theme_indicators[props.type]['color']};
  background-color: ${(props) =>
    theme_indicators[props.type]['backgroundColor']};
  border: 1px solid ${(props) => theme_indicators[props.type]['borderColor']};
`;
interface IIndicatorsListProps {
  type: string;
}

const theme_indicators: any = {
  greylist: {
    borderColor: scarpaGray600,
    backgroundColor: scarpaGray50,
    color: scarpaGray700,
  },
  whithout_list: {
    borderColor: maximumYellow500,
    backgroundColor: maximumYellow50,
    color: maximumYellow800,
  },
  good_complex: {
    borderColor: crystal400,
    backgroundColor: spanishViridian25,
    color: crystal700,
  },
  whitelist: {
    borderColor: cobalBlue200,
    backgroundColor: cobalBlue25,
    color: cobalBlue700,
  },
  blacklist: {
    borderColor: tomatoCoral200,
    backgroundColor: tomatoCoral50,
    color: tomatoCoral600,
  },
};

export const WrapperFilters = styled.div`
  grid-column: 3/10;
  display: grid;
  grid-template-columns: 2fr 2fr 2fr 1fr;
  gap: 24px;
`;

export const WrapperAction = styled.div`
  display: grid;
  align-items: end;
  .primary {
    border-radius: 6px;
    width: 100%;
  }
`;
