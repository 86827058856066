import { useEffect, useState } from 'react';

import {
  electricPurple700,
  scarpaGray200,
  skyBlue100,
  skyBlue300,
  skyBlue400,
  skyBlue800,
} from '@habitech/shared';
import {
  GraphicsContainer,
  GraphicWrapper,
  TitleAndSubtitle,
  TitleGraphic,
  TitleZone,
  AntiFunnelGraphWrapper,
} from './styles';
import LocationIcon from './assets/location.svg';
import {
  electricPurple100,
  electricPurple200,
  electricPurple400,
} from '../../../../styles/stylesConstants';
import Graphic from './Components/Graphic/Graphic';
import { useAppSelector } from '../../../../redux/hooks';
import { TPieChartAntifunnel } from '../AntiFunnel/model';

const AntiFunnelGraph: React.FC<{
  zonePricing: TPieChartAntifunnel;
  metropolitanPricing: TPieChartAntifunnel;
}> = ({ zonePricing, metropolitanPricing }) => {
  const [statistics, setStatistics] = useState<any>([]);
  const zoneState = useAppSelector((state) => state.zoneStore);

  useEffect(() => {
    const newStatistics = [];

    if (metropolitanPricing) {
      newStatistics.push({
        data: { ...metropolitanPricing },
        type: 'primary',
        first: true,
        typeZone: 'Área Metropolitana',
        name: zoneState.zone.area.label,
        colors: [
          electricPurple700,
          electricPurple400,
          electricPurple200,
          electricPurple100,
          scarpaGray200,
        ],
      });
    }

    if (zonePricing) {
      newStatistics.push({
        data: { ...zonePricing },
        type: 'secondary',
        typeZone: 'Zona Mediana',
        first: false,
        name: zoneState.zoneInfo.city,
        colors: [skyBlue800, skyBlue400, skyBlue300, skyBlue100, scarpaGray200],
      });
    }

    setStatistics(newStatistics);
  }, [zonePricing, metropolitanPricing]);

  return (
    <AntiFunnelGraphWrapper>
      <GraphicsContainer>
        {statistics.map((statistic: any) => {
          return (
            <GraphicWrapper key={statistic.name} first={statistic.first}>
              <TitleZone>
                <img src={LocationIcon} alt="pin del mapa" />
                <TitleAndSubtitle>
                  <div className="title">{statistic.name}</div>
                  <div className="subtitle">{statistic.typeZone}</div>
                </TitleAndSubtitle>
              </TitleZone>
              <TitleGraphic>Motivaciones de abandono</TitleGraphic>
              <Graphic statistic={statistic} />
            </GraphicWrapper>
          );
        })}
      </GraphicsContainer>
    </AntiFunnelGraphWrapper>
  );
};

export default AntiFunnelGraph;
