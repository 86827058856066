import styled from "styled-components";

import {
  scarpaGray500,
  scarpaGray800,
  monserratRegular,
  robotoRegular
} from '@habitech/shared';

export const Styled404Article = styled.article.attrs({
  className: "Styled404Article"
})`
  min-height: 100vh;
  display: grid;
  place-items: center;
`

export const StyledWrapper404 = styled.div.attrs({
  className: "StyledWrapper404"
})`
  margin-top: -90px;
  display: grid;
  place-items: center;
`

export const StyledTitle404 = styled.h2.attrs({
  className: "StyledTitle404"
})`
  color: ${scarpaGray800};
  font-family: ${monserratRegular};
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 40px;
  margin: 32px 0 0;
`

export const StyledText404 = styled.p.attrs({
  className: "StyledText404"
})`
  color: ${scarpaGray500};
  font-family: ${robotoRegular};
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  margin: 24px auto ;
  width: min(630px, 90%);
`