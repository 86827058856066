import { useState, useEffect } from 'react';
import { Button } from '@habitech/call-to-action';
import { useNavigate } from 'react-router-dom';
import Characteristics from '../Characteristics/Characteristics';
import DiscountApplied from '../DiscountsApplied/DiscountApplied';
import TotalDiscount from '../DiscountsApplied/TotalDiscount';
import Searcher from '../Searcher/Searcher';
import TapRules from '../TabRules/TapRules';
import getInfoProperty, { getDiscountEngineRules } from '../../../../apis/discountEngine/discountEngine';
import HabiLoader from '../../../../components/HabiLoader/HabiLoader';

import {
  ContainerWrapper,
  TitleEngine,
  CardWrapper,
  WrapperDiscountEngine,
  Separator,
  SeparatorWrapper,
  EditRulesButtonWrapper,
} from './style';
import NotFound from '../NotFound/NotFound';
import DiscountAppliedGlobal from '../DiscountsApplied/DiscountAppliedGlobal';

const ContainerDiscountEngine = () => {
  const navigate = useNavigate();
  const [firstSelected, setFirstSelected] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);
  const [showDetail, setShowDetail] = useState<boolean>(false);
  const [isNotFound, setIsNotFound] = useState<boolean>(false);
  const [nidValue, setNidValue] = useState();
  const [data, setData] = useState({});
  const [globalRules, setGlobalRules] = useState({});

  const goToEditRules = () => {
    navigate('/motor-de-descuento/editar-reglas');
  };

  const handleCallData = async () => {
    const newData = { nid: nidValue };
    setLoading(true);

    try {
      const response = await getInfoProperty(newData);

      if (response) {
        setData(response?.data);
        setLoading(false);
        setIsNotFound(false);
        setShowDetail(true);
      }
    } catch (error) {
      setShowDetail(false);
      setLoading(false);
      setIsNotFound(true);
    }
  };

  const handelCallService = () => {
    handleCallData();
  };

  useEffect(() => {
    getDiscountEngineRules().then((response) => {
      if (response.success) {
        setGlobalRules(response.data);
      }
    });
  }, []);

  return (
    <ContainerWrapper>
      <TitleEngine>Motor de descuentos</TitleEngine>
      <CardWrapper>
        <WrapperDiscountEngine>
          <TapRules
            firstSelected={firstSelected}
            setFirstSelected={setFirstSelected}
          />
          <SeparatorWrapper>
            <Separator />
          </SeparatorWrapper>
          {(firstSelected && (
            <>
              <Searcher
                setNidValue={setNidValue}
                nidValue={nidValue}
                handelCallService={handelCallService}
              />

              {showDetail && (
                <>
                  <SeparatorWrapper>
                    <Separator />
                  </SeparatorWrapper>

                  <Characteristics data={data} />
                  <SeparatorWrapper>
                    <Separator />
                  </SeparatorWrapper>
                  <DiscountApplied data={data} />
                  <SeparatorWrapper>
                    <Separator />
                  </SeparatorWrapper>
                  <TotalDiscount data={data} />
                </>
              )}
              {loading && (
                <SeparatorWrapper>
                  <HabiLoader size="small" />
                </SeparatorWrapper>
              )}
              {isNotFound && (
                <SeparatorWrapper>
                  <Separator />
                  <NotFound />
                </SeparatorWrapper>
              )}
            </>
          )) || (
            <>
              <DiscountAppliedGlobal globalRules={globalRules} />
              <EditRulesButtonWrapper>
                <Button
                  dataId="edit-rules"
                  size="extra-large"
                  onClick={() => goToEditRules()}
                >
                  Editar Reglas
                </Button>
              </EditRulesButtonWrapper>
            </>
          )}
        </WrapperDiscountEngine>
      </CardWrapper>
    </ContainerWrapper>
  );
};

export default ContainerDiscountEngine;
