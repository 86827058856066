import { SetStateAction } from 'react';
import GlobalTab from '../../../../components/Tab/GlobalTab';
import {
  ContainerModifyParameterTab,
  ColumnDescription,
  ContainerTabs,
} from '../../styles';

interface IModifyParameterTab {
  isPercentil: boolean;
  setIsPercentil: React.Dispatch<SetStateAction<boolean>>;
}

const ModifyParameterTab = ({
  isPercentil,
  setIsPercentil,
}: IModifyParameterTab) => {
  return (
    <ContainerModifyParameterTab>
      <ColumnDescription>
        <div className="subtitle">
          Selecciona el parámetro que quieres modificar
        </div>
      </ColumnDescription>
      <ContainerTabs>
        <GlobalTab
          firstSelected={isPercentil}
          setFirstSelected={setIsPercentil}
          titleOne="Percentil"
          titleTwo="Unit"
        />
      </ContainerTabs>
    </ContainerModifyParameterTab>
  );
};

export default ModifyParameterTab;
