import {
  ColumnDescription,
  ContainerCardDiscount,
  BorderSeparator,
} from './styles';
import homeMoney from '../../../../assets/images/homeMoney.svg';
import homemsn from '../../../../assets/images/homemsn.svg';
import aptos from '../../../../assets/images/aptos.svg';
import capitalize from '../../../../utils/capitalize';
import handleOrderAndTranslateData from './DiscountApplied.utils';

interface IDiscountApplied {
  data?: any;
}

const DiscountApplied: React.FC<IDiscountApplied> = ({ data }) => {
  return (
    <>
      <ColumnDescription>
        <div className="subtitle">Descuentos aplicados</div>
      </ColumnDescription>
      <ContainerCardDiscount>
        {data?.global_rules.map((item: any) => (
          <div className="container-flex" key={item.name}>
            <img src={homeMoney} alt="home_money" className="image" />
            <div>
              <div className="title">
                Regla global aplicada a la zona del NID
              </div>
              <div className="subtitle">
                Viviendas con {handleOrderAndTranslateData(item.name)}
                de descuento
              </div>
            </div>
          </div>
        ))}
        <BorderSeparator />
        <div className="container-flex">
          <img src={homemsn} alt="home_money" className="image" />
          <div>
            <div className="title">Descuento base en la zona</div>
            <div className="subtitle">
              <img src={aptos} alt="home_money" className="image-low " />
              <span>
                {capitalize(data?.property_type)} {data?.zone_discount} %
              </span>
            </div>
          </div>
        </div>
      </ContainerCardDiscount>
    </>
  );
};

export default DiscountApplied;
