import { scarpaGray800 } from '@habitech/shared';
import styled from 'styled-components';

export const MainWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(10, 1fr);
  .container-section {
    display: grid;
    grid-column: 1/11;
  }
`;

export const ReturnWrapper = styled.div`
  grid-column: 1 / 6;
`;

export const MainTitle = styled.p`
  grid-column: 1 / 6;
  font-family: 'Montserrat';
  font-size: 30px;
  font-weight: 600;
  color: ${scarpaGray800};
  margin: 1rem 0 34px 0;
`;
export default MainWrapper;
