import { robotoRegular, scarpaGray400, scarpaGray800 } from '@habitech/shared';
import styled from 'styled-components';

export const InputContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  .label {
    font-family: ${robotoRegular};
    font-weight: 400;
    font-size: 14px;
    color: ${scarpaGray800};
    margin-bottom: 4px;
  }
  .rule-input {
    height: 48px;
    border-radius: 6px;
    padding: 8px, 12px;
    border: 1px solid ${scarpaGray400};
    height: 46px;
    padding: 0 12px;
    font-family: ${robotoRegular};
    font-weight: 400;
    font-size: 16px;
    &.error {
      border: 2px solid rgb(229, 23, 23);
    }
  }
`;

export const ErrorContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  padding: 4px 0px 0px 6px;
  .error-message {
    color: rgb(229, 23, 23);
    font-family: ${robotoRegular};
    font-weight: 400;
    font-size: 12px;
  }
  img {
    margin-right: 6px;
  }
`;
