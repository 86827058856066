import { useEffect, useState } from 'react';
import {
  Paper,
  Table,
  TableBody,
  TableContainer,
  TablePagination,
  TableRow,
} from '@mui/material';
import { cobalBlue600 } from '@habitech/shared';
import {
  ContainerTable,
  CustomPaginatorContainer,
  StyledDataCell,
  StyledDataCellHeader,
  StyledHeader,
} from '../TwoDimensionalTable/styles';
import { TablePaginationContainer } from '../InverterTable/styles';
import { NoDataContainer } from '../../../../styles';

const OneDimensionTable = ({
  data,
  pivot,
  countPivot,
  totalRows,
  pivotValue,
}: any) => {
  const rowsPerPageOptions = [3];
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[0]);
  const [loading, setLoading] = useState(true);
  const [dataTable, setDataTable] = useState<any[]>([]);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    if (data !== undefined) {
      const temp = [...data]?.sort((a: any, b: any) => {
        if (a[countPivot] !== null) {
          return a[countPivot] - b[countPivot];
        }

        return b[countPivot];
      });
      setDataTable(temp);
    } else {
      setDataTable([]);
    }

    setLoading(false);
  }, []);

  return (
    <ContainerTable>
      {dataTable.length > 0 && (
        <Paper
          style={{
            borderRadius: '6px',
            boxShadow: 'none',
          }}
        >
          {loading && <div>Loading...</div>}
          <TableContainer
            component={Paper}
            data-testid="detail-elevator-typology"
            sx={{ boxShadow: 'none', borderRadius: '6px 6px 0 0' }}
          >
            <Table sx={{ minWidth: 650 }} size="small">
              <StyledHeader>
                <TableRow>
                  {dataTable.map((row: any) => (
                    <StyledDataCellHeader key={row[pivot]}>
                      {Number(row[countPivot]) % 1 !== 0
                        ? Number(row[countPivot]).toFixed(1)
                        : row[countPivot]}
                    </StyledDataCellHeader>
                  ))}
                </TableRow>
              </StyledHeader>

              <TableBody>
                <TableRow>
                  {dataTable.map((row: any, index: number) => {
                    return (
                      <StyledDataCell
                        role="cell"
                        key={row[pivot]}
                        sx={{
                          background: index === pivotValue ? cobalBlue600 : '',
                          color:
                            index === pivotValue ? '#ffffff !important' : '',
                        }}
                      >
                        {Number(row[pivot]) % 1 !== 0
                          ? Number(row[pivot]).toFixed(1)
                          : row[pivot]}
                      </StyledDataCell>
                    );
                  })}
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <TablePaginationContainer>
            <CustomPaginatorContainer>
              <div className="results">{totalRows} Resultados</div>
              <TablePagination
                rowsPerPageOptions={rowsPerPageOptions}
                component="div"
                count={totalRows}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                labelRowsPerPage="RESULTADOS POR PÁGINA"
                labelDisplayedRows={({ from, to, count }) =>
                  `${from}-${to} de ${count}`
                }
              />
            </CustomPaginatorContainer>
          </TablePaginationContainer>
        </Paper>
      )}
      {dataTable.length === 0 && (
        <NoDataContainer>No hay datos</NoDataContainer>
      )}
    </ContainerTable>
  );
};

export default OneDimensionTable;
