import { AuthZonas } from '../api';

export const authUser = async (
  GoogleCredential: string,
  country: string | null
) => {
  return AuthZonas.post('/auth', {
    GoogleCredential,
    country,
  });
};
export const authFresh = async (
  token: string,
  country: string,
  user: string
) => {
  return AuthZonas.post('/auth', {
    country,
    RefreshToken: token,
    userName: user,
  });
};
export const authRevoke = async (token: string, country: string | null) => {
  return AuthZonas.post('/auth', {
    country,
    RevokeToken: token,
  });
};
