import { SimpleTextField } from '@habitech/keychain-atoms-inputs';
import { SetStateAction } from 'react';
import {
  CardDescriptionParameter,
  ColumnDescription,
  ContainerNewParameter,
  ContainerParameter,
} from '../../styles';
import calculator from '../../../../assets/images/calculator.svg';
import TEXT_NEW_PARAMETER from './utils';
import { tabItemsPlural, tabItemsSingular } from '../../HesH.utils';

interface INewParameter {
  isPercentil: boolean;
  tabSelected: number;
  categorySelect: string;
  isSingular: boolean;
  summary: any;
  newValue: string;
  setNewValue: React.Dispatch<SetStateAction<string>>;
  error: boolean;
}

interface TabItem {
  id: number;
  label: string;
}

const NewParameter = ({
  isPercentil,
  tabSelected = 0,
  categorySelect = '',
  isSingular = true,
  summary,
  newValue,
  setNewValue,
  error,
}: INewParameter) => {
  const tabItems = isSingular ? tabItemsSingular : tabItemsPlural;
  const foundTabItem: TabItem | undefined = tabItems.find(
    (item) => item.id === tabSelected
  );

  const handleGenerateTitle = () => {
    const pluralLabel = isSingular ? '' : 's';
    const label = foundTabItem?.label || '';

    const title = isPercentil ? 'Percentil' : 'Unit';
    const titleDescription = `${title} actual para la${
      isSingular ? ' ' : pluralLabel
    } ${label} seleccionada${pluralLabel} - Revenue ${categorySelect}`;

    return titleDescription;
  };

  const handleGenerateDescription = () => {
    if (tabSelected === 1 && isSingular) {
      const value = isPercentil
        ? summary.median_percentile
        : summary.median_unit;
      return `Valor actual del parámetro ${value}`;
    }

    const symbol = isPercentil ? ' ' : '%';
    const min = isPercentil ? summary.min_percentile : summary.min_unit;
    const max = isPercentil ? summary.max_percentile : summary.max_unit;
    const median = isPercentil
      ? summary.median_percentile
      : summary.median_unit;

    return `Min: ${min} ${symbol} - Max: ${max} ${symbol} - Mediana: ${median} ${symbol}`;
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;

    if (isPercentil) {
      if (value.length <= 2) {
        if (/^[0-9]*$/.test(value)) {
          setNewValue(value);
        }
      }
    } else if (/^[0-9.]*$/.test(value)) {
      if (value.length <= 4) {
        setNewValue(value);
      }
    }
  };

  return (
    <ContainerNewParameter>
      <ColumnDescription>
        <div className="subtitle">Establezca el nuevo parámetro</div>
      </ColumnDescription>
      <ContainerParameter>
        <CardDescriptionParameter>
          <img src={calculator} alt="calculator" height={48} />
          <div>
            <div className="title-description">{handleGenerateTitle()}</div>
            <div className="subtitle-description">
              {handleGenerateDescription()}
            </div>
          </div>
        </CardDescriptionParameter>
        <div>
          <div className="title-modify">
            {isPercentil
              ? TEXT_NEW_PARAMETER.title_percentil
              : TEXT_NEW_PARAMETER.title_util}
          </div>
          <div className="container-input">
            <SimpleTextField
              name="PercentilUnit"
              label={isPercentil ? 'Percentil' : 'Unit %'}
              onChange={handleInputChange}
              value={newValue}
              placeholder={isPercentil ? '16' : '5.5'}
              error={error}
              errorMessage={
                isPercentil
                  ? 'El valor debe ser entre 0 y 90'
                  : 'El valor debe ser entre 4% y 11%'
              }
            />
          </div>
        </div>
      </ContainerParameter>
    </ContainerNewParameter>
  );
};

export default NewParameter;
