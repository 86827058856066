import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '../types';

const selectUserState = (state: RootState) => state.userStore;

export const selectIsUserLogged = (state: RootState) =>
  !!state.userStore.user?.name;

export const selectUser = (state: RootState) => state.userStore.user;
export const selectUserDate = (state: RootState) =>
  new Date(state.userStore.userDate);

const selectDateRange = createSelector(
  selectUserState,
  (userStore) => userStore.dateRange
);

export const selectUserDateRange = createSelector(
  selectDateRange,
  (dateRange) => {
    return dateRange.map((date) => new Date(date !== undefined ? date : ''));
  }
);

export const selectUserFullName = (state: RootState) =>
  `${state.userStore.user?.name} ${state.userStore.user?.lastName}`;
