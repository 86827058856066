import styled from 'styled-components';
import {
  scarpaGray800,
  scarpaGray400,
  robotoRegular,
  white,
  scarpaGray100,
  electricPurple500,
  electricPurple100,
  disabledColor,
} from '@habitech/shared';

export const ColumnDescription = styled.div`
  grid-column: 1/3;
  font-family: ${robotoRegular};
  .size {
    width: 130px;
  }
  .subtitle {
    width: 100%;
    font-family: ${robotoRegular};
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: ${scarpaGray800};
  }
`;
export const ContainerButtons = styled.div`
  grid-column: 3/11;
  display: flex;
  margin-bottom: 26px;
`;
export const ContainerTabs = styled.div`
  grid-column: 3/8;
  margin-bottom: 31px;
`;
export const ContainerTitle = styled.div`
  grid-column: 3/11;
  display: flex;
  margin-bottom: 12px;
  .title {
    font-family: ${robotoRegular};
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
  }
`;
export const ContainerDiscountToAply = styled.div`
  grid-column: 3/11;
  display: flex;
  .margin-first {
    margin-right: 24px;
  }
  .add-less {
    .title {
      font-family: ${robotoRegular};
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      margin-bottom: 9px;
    }
  }
`;

interface IImageButton {
  isDisable: boolean;
}

export const ImageButton = styled.button<IImageButton>`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background: ${white};
  border-radius: 6px;
  border: 1px solid
    ${(props) => (props.isDisable ? scarpaGray100 : electricPurple100)};
  color: ${(props) => (props.isDisable ? scarpaGray400 : electricPurple500)};
  width: 207px;
  height: 48px;
  font-family: ${robotoRegular};
  font-weight: 600;
  line-height: 20px;
  font-size: 14px;
  margin-right: 24px;
  padding-left: 16px;
  cursor: ${(props) => (props.isDisable ? 'auto' : 'pointer')};
  img {
    margin-right: ${(props) => (props.isDisable ? '18px' : '12px')};
  }
`;
export const ContainerButtonSend = styled.div`
  padding-top: 32px;
  grid-column: 3/10;
  button {
    border-color: ${electricPurple500};
    width: 207px;
    height: 48px;
    background: ${electricPurple500};
    border-radius: 6px;
    justify-content: center;
    display: flex;
    align-items: center;
    cursor: pointer;
    .text {
      font-family: ${robotoRegular};
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      display: flex;
      align-items: center;
      text-align: center;
      color: ${white};
    }
  }
  button:disabled {
    border-color: ${disabledColor};
    width: 207px;
    height: 48px;
    background: ${disabledColor};
    border-radius: 6px;
    justify-content: center;
    display: flex;
    align-items: center;
    cursor: pointer;
    .text {
      font-family: ${robotoRegular};
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      display: flex;
      align-items: center;
      text-align: center;
      color: ${white};
    }
  }
`;
