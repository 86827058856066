// Helper function to get the date for an item
function getDate(item: any, status: string): Date | null {
  const dateFieldMap: { [key: string]: string } = {
    vendido: 'sale_promise_date',
    publicado: 'published_date',
  };

  const dateField = dateFieldMap[status];

  if (dateField) {
    return item[dateField] ? new Date(item[dateField]) : null;
  }

  // For "No Publicado" or other statuses, return null
  return null;
}

export const sortData = (deals: any[], prioritizedPropertySetDb?: string) => {
  const statusRank: { [key: string]: number } = {
    vendido: 1,
    publicado: 2,
    nopublicado: 3,
  };

  const normalizeString = (str: string) =>
    str.toLowerCase().replace(/\s+/g, '').trim();

  const prioritizedProp = prioritizedPropertySetDb
    ? normalizeString(prioritizedPropertySetDb)
    : '';

  const dealsWithSortKeys = deals.map((deal) => {
    const prop = deal.property_set_db
      ? normalizeString(deal.property_set_db)
      : null;
    const isPrioritized = prop === prioritizedProp ? 0 : 1;

    const status = deal.status ? normalizeString(deal.status) : '';
    const statusRankValue = statusRank[status] || 4;

    const date = getDate(deal, status);
    const dateValue = date ? -date.getTime() : Number.MAX_SAFE_INTEGER; // Negative for most recent first

    const dealId = deal.deal_id;

    return {
      deal,
      sortKey: [isPrioritized, statusRankValue, dateValue, dealId],
    };
  });

  dealsWithSortKeys.sort((a, b) => {
    const aSortKey = a.sortKey;
    const bSortKey = b.sortKey;

    for (let i = 0; i < aSortKey.length; i += 1) {
      if (aSortKey[i] < bSortKey[i]) return -1;
      if (aSortKey[i] > bSortKey[i]) return 1;
      // Else continue to next sortKey component
    }

    return 0; // All components are equal
  });

  return dealsWithSortKeys.map((item) => item.deal);
};

export default sortData;
