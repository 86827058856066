import styled from 'styled-components';
import {
  electricPurple500,
  electricPurple600,
  robotoRegular,
  strokesAAA,
} from '@habitech/shared';
import { disabled } from '../../../../styles/stylesConstants';

export const ContainerTabLine = styled.div`
  display: flex;
  width: 100%;
  .tab-rules {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
  }
  .second-tab-rules {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    width: 100%;
  }
`;

interface IFirstTabTitle {
  isRules: boolean;
}

export const FirstTabTitle = styled.div<IFirstTabTitle>`
  color: ${(props) => (props.isRules ? electricPurple600 : strokesAAA)};
  text-align: center;
  font-family: ${robotoRegular};
  font-size: 16px;
  font-style: normal;
  font-weight: ${(props) => (props.isRules ? 500 : 400)};
  line-height: 16px;
  padding: 16px;
  cursor: pointer;
`;
export const SecondTabTitle = styled.div<IFirstTabTitle>`
  color: ${(props) => (props.isRules ? strokesAAA : electricPurple600)};
  text-align: center;
  font-family: ${robotoRegular};
  font-size: 16px;
  font-style: normal;
  font-weight: ${(props) => (props.isRules ? 400 : 500)};
  line-height: 16px;
  padding: 16px;
  cursor: pointer;
`;
export const FirstTab = styled.div`
  background: ${electricPurple500};
  border-radius: 8px;
  height: 4px;
  width: 309px;
`;
export const FirstTabNS = styled.div`
  background: ${disabled};
  border-radius: 8px;
  height: 2px;
  width: 309px;
`;
export const SecondTab = styled.div`
  background: ${disabled};
  height: 2px;
  margin-top: 1px;
  width: 100%;
`;
export const SecondTanSelected = styled.div`
  display: flex;
  width: 100%;
`;
