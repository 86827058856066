import { electricPurple500, electricPurple600, monserratRegular, robotoRegular, scarpaGray600, scarpaGray800, white } from '@habitech/shared';
import styled from 'styled-components';
import { borderGrayResume, borderSelect, secondaryAA } from '../../styles/stylesConstants';

const UtilityWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(10, 1fr);
`;

export const SubSectionTitle = styled.div`
  width: 100%;
  display: flex;
  font-family: ${monserratRegular};
  font-size: 20px;
  color: ${scarpaGray600};
  font-weight: 600;
  img {
    margin-right: 20px;
  }
`;

export const DateContainer = styled.div`
  width: 400px;
  margin-top: 20px;
`;

export const Separator = styled.div`
  width: 100%;
  height: 1px;
  border-top: 1px solid ${borderGrayResume};
  margin: 2rem 0;
`;

export const UtilityInfoContainer = styled.div`
  width: 100%;
`;

export const UtilityInfoContent = styled.div`
  width: 80%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const UtilityInfoWrapper = styled.div`
  width: 300px;
  background: ${white};
  border-radius: 8px;
  border: 1px solid ${borderSelect};
`;

export const InfoItem = styled.div`
  padding: 16px;
  border-bottom: 1px solid ${borderSelect};
  display: flex;
  justify-content: space-between;
  align-items: center;

  &.no-border {
    border: none;
  }
`;

export const Description = styled.div`
  display: flex;
  flex-direction: column;
  .title {
    font-family: ${robotoRegular};
    font-weight: 400;
    font-size: 12px;
    color: ${secondaryAA};
  }
  .percent {
    font-family: ${monserratRegular};
    font-weight: 600;
    font-size: 16px;
    color: ${scarpaGray800};
    margin-top: 3px;
  }
`;

export const UtilityObjetiveWrapper = styled.div`
  width: 300px;
  background: ${electricPurple600};
  border-radius: 8px;
  border: 1px solid ${electricPurple500};
  padding: 16px;
  color: ${white};
  position: relative;
  height: 136px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  .icon {
    position: absolute;
    top: 12px;
    right: 12px;
  }
  .title {
    font-family: ${robotoRegular};
    font-weight: 400;
    font-size: 12px;
  }
  .percent {
    font-family: ${monserratRegular};
    font-weight: 600;
    font-size: 32px;
    margin-top: 3px;
  }
`;

export const UtilityEmptyContainer = styled.div`
  width: 80%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  img {
    margin-right: 24px;
  }
  .title {
    font-family: ${monserratRegular};
    font-weight: 600;
    font-size: 24px;
    color: ${scarpaGray800};
    margin-bottom: 27px;
  }
  .text {
    font-family: ${robotoRegular};
    font-weight: 400;
    font-size: 16px;
    color: ${scarpaGray800};
    margin-bottom: 27px;
  }
  strong {
    font-weight: bold;
  }
`;

export const LoaderContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

`;

export default UtilityWrapper;
