// Libraries
import { useEffect, useState } from 'react';
import moment, { Moment } from 'moment';
import { MonthPicker } from '@habitech/month-picker';
import { useAppSelector } from '../../redux/hooks';

// Components
import HabiLoader from '../../components/HabiLoader/HabiLoader';
import ActualDiscount from './components/ActualDiscount/ActualDiscount';
import withRoutePage, { RoutePage } from '../../utils/withRoutePage';
import {
  getPricingMetropolitanArea,
  getPricingZone,
} from '../../services/zoneServices';
import ReturnLink from '../../components/ReturnLink';
import Opportunities from './components/Opportunities/Opportunities';
import ModalDiscount from './components/ModalDiscount/ModalDiscount';
import getZoneInfo from '../ZoneDetail/services/zone.services';
import DateDisclaimer from '../../components/DateDisclaimer';
import LineGraphicMape from './components/LineGraphicMape/index';
import AutoPricing from './components/AutoPricing/index';
import EmptyState from '../../components/EmptyState';

// Images
import habiMoney from '../../static/assets/icons/habi-money.svg';
import gpsPrice from '../../static/assets/icons/gps-price.svg';
import percentageA from '../../static/assets/icons/bg-percentage-a.png';
import percentageB from '../../static/assets/icons/bg-percentage-b.png';

// styles
import { PricingWrapper, AlertWrapper, ReturnWrapper } from './styles';
import { Separator } from '../FunnelConvertion/styles';

const Pricing: RoutePage = () => {
  const zoneState = useAppSelector((state) => state.zoneStore);
  const userDate = useAppSelector((state) => state.userStore.userDate);
  const [pricingDate, setPricingDate] = useState<Moment>(moment(userDate));
  const [mapeDate, setMapeDate] = useState(userDate);
  const [zonePricing, setZonePricing] = useState({
    percent_initial_comite: 0,
    percent_initial_client: 0,
    percent_mape_customer: 0,
    percent_mape_committee: 0,
    pricing: {
      apartment_discount: 0,
      house_discount: 0,
    },
    loaded: false,
  });
  const [metropolitanPricing, setMetropolitanPricing] = useState<any>({
    am_label: '',
    percent_manual: '',
    percent_automatico: '',
    percent_n_nh: '',
    percent_initial_client: 0,
    percent_initial_comite: 0,
    percent_mape_customer: 0,
    percent_mape_committee: 0,
    loaded: false,
  });
  const [modalOpen, setModalOpen] = useState(false);
  const { area, middleZone } = zoneState.zone;

  const round = (number: number) => {
    return Math.round(number * 10) / 10;
  };

  useEffect(() => {
    if (userDate) {
      setPricingDate(moment(userDate));
      setMapeDate(userDate);
    }
  }, [userDate]);

  useEffect(() => {
    if (!pricingDate) return;
    getPricingZone(middleZone.id, pricingDate.toDate()).then((response) => {
      if (response.success) {
        setZonePricing((pricing) => ({
          ...pricing,
          ...response.data,
          loaded: true,
        }));
      }
    });

    getZoneInfo(middleZone.id).then((response) => {
      if (response.success) {
        setZonePricing((pricing) => ({
          ...pricing,
          pricing: response.data as any,
        }));
      }
    });
  }, [middleZone.id, pricingDate]);

  useEffect(() => {
    if (!pricingDate) return;
    getPricingMetropolitanArea(area.id, pricingDate.toDate()).then(
      (response) => {
        const pricingMetropolitan = response.success ? response.data : {};

        setMetropolitanPricing((pricing: any) => ({
          ...pricing,
          ...pricingMetropolitan,
          loaded: true,
        }));
      }
    );
  }, [area.id, pricingDate]);

  const loaded = zonePricing && metropolitanPricing;

  const handleClickDate = (value: any) => {
    setMetropolitanPricing({ ...metropolitanPricing, loaded: false });
    setZonePricing({ ...zonePricing, loaded: false });
    setPricingDate(moment(value[0]));
    setMapeDate(value[0]);
  };

  if (!loaded) {
    return (
      <div className="flex justify mt-20">
        <HabiLoader size="small" />
      </div>
    );
  }

  return (
    <PricingWrapper>
      <AlertWrapper>
        <ReturnWrapper>
          <ReturnLink
            text="Regresar a análisis de zona "
            link="/portal/analisis-de-zona"
          />
        </ReturnWrapper>
      </AlertWrapper>

      <div className="section-title">Pricing</div>

      <ActualDiscount
        setModalOpen={() => setModalOpen(!modalOpen)}
        pricing={zonePricing.pricing}
      />

      <div className="section-container">
        <div className="flex align between">
          <div className="flex align">
            <img src={habiMoney} alt="icono de una casa" className="mr-12" />

            <div className="fz-18 montserrat semi-bold nightBlue500">
              Variación de precio m2
            </div>
          </div>
          <div>&nbsp;</div>
        </div>

        <div className="date-wrapper">
          <MonthPicker
            dataId="pricing-month"
            value={[pricingDate.toDate(), undefined] as [Date, Date?]}
            onChangeValue={handleClickDate}
            isRange={false}
            startYear={2019}
          />
        </div>

        <DateDisclaimer date={pricingDate.toDate()} />

        {!zonePricing.loaded && !metropolitanPricing.loaded ? (
          <HabiLoader size="small" />
        ) : (
          <div className="flex align between mt-28 container-price">
            <div className="flex column pricing-item">
              <div className="flex align-start">
                <img src={gpsPrice} className="mr-12" alt="icono de gps" />
                <div className="flex column">
                  <div className="montserrat fz-14 semi-bold sgray">
                    {area.label}
                  </div>

                  <div className="fz-12 text-gray mt-8">Área Metropolitana</div>
                </div>
              </div>

              {metropolitanPricing?.percent_initial_comite ? (
                <div className="wrap flex align around mt-20">
                  <div className="flex column align">
                    <div className="percentage-a">
                      <img src={percentageA} alt="" />
                      <div className="info">
                        {round(metropolitanPricing.percent_initial_comite)}%
                      </div>
                    </div>
                    <div className="percentage-button">
                      Ask price inicial
                      <br />
                      vs Comité
                    </div>
                  </div>

                  <div className="flex column align">
                    <div className="percentage-a">
                      <img src={percentageA} alt="" />
                      <div className="info">
                        {round(metropolitanPricing.percent_initial_client)}%
                      </div>
                    </div>
                    <div className="percentage-button">
                      Precio manual inicial
                      <br />
                      vs Ask Cliente
                    </div>
                  </div>

                  <div className="flex-break" />

                  <div className="flex column align">
                    <div className="percentage-a">
                      <img src={percentageA} alt="" />
                      <div className="info">
                        {round(metropolitanPricing.percent_mape_committee)}%
                      </div>
                    </div>

                    <span className="percentage-button">MAPE Comite</span>
                  </div>

                  <div className="flex column align">
                    <div className="percentage-a">
                      <img src={percentageA} alt="" />
                      <div className="info">
                        {round(metropolitanPricing.percent_mape_customer)}%
                      </div>
                    </div>

                    <span className="percentage-button">MAPE Inicial</span>
                  </div>
                </div>
              ) : (
                <EmptyState
                  title="No se encontraron datos."
                  body="Es probable que el area no cuente con data. Puedes intentar cambiar la fecha para obtener resultados."
                />
              )}
            </div>

            <div className="separator" />

            <div className="flex column pricing-item">
              <div className="flex align-start">
                <img src={gpsPrice} className="mr-12" alt="icono de gps" />
                <div className="flex column">
                  <div className="montserrat fz-14 semi-bold sgray">
                    {middleZone.label}
                  </div>
                  <div className="fz-12 text-gray mt-8">Zona Mediana</div>
                </div>
              </div>

              {metropolitanPricing?.percent_initial_comite ? (
                <div className="wrap flex align around mt-20">
                  <div className="flex column align">
                    <div className="percentage-a">
                      <img src={percentageB} alt="" />
                      <div className="info">
                        {round(zonePricing.percent_initial_comite)}%
                      </div>
                    </div>

                    <div className="percentage-button">
                      Ask price inicial
                      <br />
                      vs Comité
                    </div>
                  </div>

                  <div className="flex column align">
                    <div className="percentage-a">
                      <img src={percentageB} alt="" />
                      <div className="info">
                        {round(zonePricing.percent_initial_client)}%
                      </div>
                    </div>

                    <div className="percentage-button">
                      Precio manual inicial
                      <br />
                      vs Ask Cliente
                    </div>
                  </div>

                  <div className="flex-break" />

                  <div className="flex column align">
                    <div className="percentage-a">
                      <img src={percentageB} alt="" />
                      <div className="info">
                        {round(zonePricing.percent_mape_committee)}%
                      </div>
                    </div>

                    <span className="percentage-button">MAPE Comite</span>
                  </div>

                  <div className="flex column align">
                    <div className="percentage-a">
                      <img src={percentageB} alt="" />
                      <div className="info">
                        {round(zonePricing.percent_mape_customer)}%
                      </div>
                    </div>

                    <span className="percentage-button">MAPE Inicial</span>
                  </div>
                </div>
              ) : (
                <EmptyState
                  title="No se encontraron datos."
                  body="Es probable que la zona mediana no cuente con data. Puedes intentar cambiar la fecha para obtener resultados."
                />
              )}
            </div>
          </div>
        )}

        {/* MAPE */}
        <LineGraphicMape date={mapeDate} />

        <Separator />

        {!!metropolitanPricing?.percent_initial_comite && (
          <Opportunities
            zonePricing={zonePricing}
            metropolitanPricing={metropolitanPricing}
          />
        )}

        <AutoPricing metropolitanArea={area} />

        {modalOpen && (
          <ModalDiscount
            setModalOpen={() => setModalOpen(!modalOpen)}
            zonePricing={zonePricing}
            setZonePricing={setZonePricing}
          />
        )}
      </div>
    </PricingWrapper>
  );
};

Pricing.requiresAuth = true;

export default withRoutePage(Pricing);
