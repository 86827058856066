// Libraries
import { useEffect, useState } from 'react';

// Components
import withRoutePage, { RoutePage } from '../../utils/withRoutePage';
import HabiLoader from '../../components/HabiLoader/HabiLoader';
import ReturnLink from '../../components/ReturnLink';
import ContainerRejectionReasons from '../FunnelConvertion/components/ContainerRejectionReasons/ContainerRejectionsReason';

// Styles
import RejectedReasonsWrapper, { SectionContainer } from './styles';
import {
  ReturnWrapper,
  SubTitleSection,
  TitleSection,
} from '../MarketingLeads/styles';

const RejectedReasons: RoutePage = () => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    setLoaded(true);
  }, []);

  if (!loaded) {
    return (
      <div className="flex justify mt-20">
        <HabiLoader size="small" />
      </div>
    );
  }

  return (
    <RejectedReasonsWrapper>
      <ReturnWrapper>
        <ReturnLink
          text="Regresar al funnel de conversión"
          link="/portal/funnel-de-conversion"
        />
      </ReturnWrapper>

      <TitleSection>Razones de rechazo</TitleSection>

      <SubTitleSection>
        Análisis del área metropolitana y zona mediana
      </SubTitleSection>

      <SectionContainer>
        <ContainerRejectionReasons />
      </SectionContainer>
    </RejectedReasonsWrapper>
  );
};

RejectedReasons.requiresAuth = true;

export default withRoutePage(RejectedReasons);
