import { monserratRegular, scarpaGray600 } from '@habitech/shared';
import styled from 'styled-components';
import { borderGrayResume, nightBlue500, textGray3 } from '../../../../styles/stylesConstants';

export const WrapperGraphicMape = styled.div`
  margin-bottom: 20px;
`;

export const InformationWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 25px;
`;

export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 25px;
  img {
    margin-right: 10px;
  }
  span {
    font-family: ${monserratRegular};
    font-weight: 600;
    font-size: 18px;
    color: ${nightBlue500};
  }
`;

export const AreaInformationWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  padding-right: 22px;
  border-right: 1px solid ${borderGrayResume};
  img {
    margin-right: 13px;
  }
`;

export const AreaInformationText = styled.div`
  .title {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    display: flex;
    align-items: center;
    color: ${scarpaGray600};
    margin-bottom: 8px;
  }

  .subtitle {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: ${textGray3};
  }
`;

export const GraphicArea = styled.div``;

export const CustomLegendContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-left: 20px;
  width: 70%;
`;

export const CustomLegendItem = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  .text {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: #474747;
    max-width: 110px;
  }
`;

export const IndicatorColor = styled.div`
  background: ${(props) => props.color};
  border-radius: 6px;
  width: 30px;
  height: 30px;
  margin-right: 12px;
`;

export const LoaderContainer = styled.div`
  width: 100%
  display: flex;
  justify-content: center;
  align-items: center
  margin: 25px 0;
`;
