import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

import Routes from './router';
import configureAppStore from './redux/store';

import './index.scss';

const { persistor, store } = configureAppStore();

const App = () => {
  return (
    <Provider store={store} data-testid="app">
      <PersistGate persistor={persistor}>
        <Routes />
      </PersistGate>
    </Provider>
  );
};

export default App;
