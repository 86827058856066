import { scarpaGray800, white, grid } from '@habitech/shared';
import styled from 'styled-components';
import { borderGrayResume, principalFont } from '../../styles/stylesConstants';

const MarketingLeadsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(10, 1fr);
`;

export const ReturnWrapper = styled.div`
  grid-column: 1 / 6;
`;

export const ReturnContent = styled.div`
  display: flex;
  margin-bottom: 50px;
  cursor: pointer;
`;

export const ReturnText = styled.p`
  font-family: ${principalFont};
  font-weight: 700;
  font-size: 14px;
  color: ${scarpaGray800};
  margin: 0;
`;

export const TitleSection = styled.p`
  grid-column: 1 / 6;
  font-family: ${principalFont};
  font-size: 30px;
  font-weight: 600;
  color: ${scarpaGray800};
  margin: 1rem 0;
`;

export const SubTitleSection = styled.p`
  grid-column: 1 / 6;
  font-family: ${principalFont};
  font-size: 16px;
  font-weight: 600;
  color: ${scarpaGray800};
  margin: 20px 0;
`;

interface ISectionContainer {
  noPadding?: boolean;
}

export const SectionContainer = styled.div<ISectionContainer>`
  grid-column: 1 / 11;
  box-shadow: 0px 0px 1px rgba(48, 49, 51, 0.05),
    0px 2px 4px rgba(48, 49, 51, 0.1);
  border-radius: 6px;
  padding-bottom: 16px;
  background: ${white};
  padding: ${(props) => (props.noPadding ? '0' : '16px')};
  .grid-container-graph {
    display: grid;
    grid-gap: ${grid?.desktop?.gap};
    grid-template-columns: repeat(10, 1fr);
  }
  .title-graph {
    margin-bottom: 51px;
  }

  .date-wrapper {
    max-width: 300px;
  }

  .date-divider {
    height: 1px;
    width: 100%;
    margin: 16px auto;
    background-color: ${borderGrayResume};
  }
`;

export default MarketingLeadsWrapper;
