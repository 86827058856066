import { Button } from '@habitech/call-to-action';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from '../../../../redux/hooks';
import {
  DescriptionArea,
  IconDiscount,
  Indicator,
  Indicators,
  Title,
  WrapperActualRulesApproval,
  WrapperButton,
  ActualRulesApprovalTitle,
} from './styles';
import LogoDiscount from '../../../../assets/icons/discount-logo.svg';
import ToleranceIcon from './assets/tolerance-icon.svg';
import PercentilIcon from './assets/percentil-icon.svg';

const ActualRulesApproval: React.FC = () => {
  const zoneState = useAppSelector((state) => state.zoneStore);
  const navigate = useNavigate();
  return (
    <>
      <ActualRulesApprovalTitle>Accionable disponible</ActualRulesApprovalTitle>
      <WrapperActualRulesApproval>
        <IconDiscount>
          <img src={LogoDiscount} alt="icono de descuento" />
        </IconDiscount>
        <DescriptionArea>
          <Title>Reglas de aprobación actuales</Title>
          <Indicators>
            <Indicator last={false}>
              <img src={ToleranceIcon} alt="icono de apartamentos" />
              <div className="text">
                Tolerancia <b>{zoneState.zoneInfo?.tolerance}%</b>
              </div>
            </Indicator>
            <Indicator last>
              <img src={PercentilIcon} alt="icono de casa" />
              <div className="text">
                Percentil <b>{zoneState.zoneInfo?.percentile_admin}%</b>
              </div>
            </Indicator>
          </Indicators>
        </DescriptionArea>
        <WrapperButton>
          <Button
            dataId="discount-button"
            size="large"
            onClick={() => navigate('/portal/reglas-de-aprobacion')}
          >
            Revisión de políticas
          </Button>
        </WrapperButton>
      </WrapperActualRulesApproval>
    </>
  );
};

export default ActualRulesApproval;
