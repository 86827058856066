export const reasonList = [
  { label: 'Alto riesgo', value: 'Alto riesgo' },
  {
    label: 'Antigüedad mayor a 30 años',
    value: 'Antigüedad mayor a 30 años',
  },
  {
    label: 'Conjunto no Habi (Tipología / Precio)',
    value: 'Conjunto no Habi (Tipología / Precio)',
  },
  {
    label: 'Mala experiencia comercial',
    value: 'Mala experiencia comercial',
  },
  { label: 'Malas vías de acceso', value: 'Malas vías de acceso' },
  {
    label: 'Problemas de humedad/olores en el conjunto',
    value: 'Problemas de humedad/olores en el conjunto',
  },
  { label: 'Problemas estructurales', value: 'Problemas estructurales' },
  { label: 'Prostitución en la zona', value: 'Prostitución en la zona' },
  { label: 'Zona insegura', value: 'Zona insegura' },
  { label: 'Zona mala', value: 'Zona mala' },
  { label: 'Otro motivo', value: 'Otro motivo' },
];

export const optionsQuotas = [
  { label: 'Black', value: 'B' },
  { label: 'Grey', value: 'G' },
  { label: 'Sin lista', value: 'Sin Lista' },
  { label: 'Buen conjunto', value: 'BC' },
  { label: 'White', value: 'W' },
];
