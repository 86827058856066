import styled from 'styled-components';
import { monserratRegular, scarpaGray100 } from '@habitech/shared';

export const TitleZone = styled.div`
  grid-column: 1/10;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  margin-left: 32px;
  .image {
    margin-right: 18px;
  }
  .title {
    font-family: ${monserratRegular};
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
  }
`;
export const SeparatorWrapper = styled.div`
  grid-column: 1/10;
  margin-left: 32px;
`;

export const Separator = styled.div`
  width: 100%;
  border-top: 1px solid ${scarpaGray100};
  margin: 16px 0;
`;
export const GridComplete = styled.div`
  grid-column: 1/10;
`;
