import { FC } from 'react';

import cardCalendar from '../../assets/icons/cardCalendar.svg';
import TitleEvolution from './style';

const EvolutionTitte: FC = () => {
  return (
    <TitleEvolution>
      <img src={cardCalendar} alt="calendar-img" className="imagen-calendar" />
      <span className="text">Evolución de los leads en el tiempo</span>
    </TitleEvolution>
  );
};

export default EvolutionTitte;
