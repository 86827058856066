import { monserratRegular, robotoRegular, scarpaGray800 } from '@habitech/shared';
import styled from 'styled-components';

const EmptyStateWrapper = styled.div`
  width: 90%;
  margin-top: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 25px;
  img {
    margin-right: 25px;
  }
`;

export const InformationContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TitleText = styled.div`
  font-family: ${monserratRegular};
  font-weight: 600;
  font-size: 24px;
  color: ${scarpaGray800};
  margin-bottom: 25px;
`;

export const BodyText = styled.div`
  font-family: ${robotoRegular};
  font-weight: 400;
  font-size: 16px;
  color: ${scarpaGray800};
  margin-bottom: 25px;
`;

export const FooterText = styled.div`
  font-family: ${robotoRegular};
  font-weight: 600;
  font-size: 16px;
  color: ${scarpaGray800};
`;

export default EmptyStateWrapper;
