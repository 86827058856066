import { useDispatch, useSelector } from 'react-redux';
import { updateCardState } from '../../../redux/reducers/approverCardsSlice';
import { setResult } from '../../../redux/reducers/searchSlice';
import { handleInfoApproved } from './useTypologies';
import { getLemonLimit } from '../../../apis/approvalRules/approvalRules';
import { formatMoney } from '../../../utils/formatDecimal';

export const useLemons = () => {
  const dispatch = useDispatch();
  const search = useSelector((state: any) => state.searchStore.search);

  const handleLemons = async () => {
    try {
      const response = await getLemonLimit({ nid: search });

      const updatedData = {
        id: 23,
        size: 's',
        edited: false,
        approved: handleInfoApproved(response.approval_result),
        loading: false,
        showDetail: false,
        data: {
          title: 'Tope de Precio en la zona',
          values: {
            text_one: 'Ask Price Comité',
            value_one: formatMoney(response?.ask_price_comite),
            text_two: 'Top Price',
            value_two: formatMoney(response?.top_price),
          },
        },
        table: response,
      };

      dispatch(updateCardState({ id: 23, newData: updatedData }));
      const status =
        response.approval_result === 'approved' ? 'ready' : 'error';
      dispatch(setResult(status));
    } catch (error) {
      console.error('Error en handleLemons:', error);
    }
  };

  return { handleLemons };
};
