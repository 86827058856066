import {
  blueLinks,
  electricPurple600,
  robotoRegular,
  scarpaGray800,
  white,
} from '@habitech/shared';
import styled from 'styled-components';
import { borderSelect } from '../../styles/stylesConstants';

export const WrapperSearch = styled.div`
  display: grid;
  justify-content: right;

  .title {
    font-family: ${robotoRegular};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding: 0 0 4px 6px;
    color: ${scarpaGray800};
  }
`;

export const SearchArea = styled.div`
  display: grid;
  position: relative;
  width: 262px;

  .MuiInputBase-root {
    border-radius: 6px;
    height: 48px;
    padding-top: 1px !important;
  }
  .MuiAutocomplete-root {
    height: 48px;
  }
  .MuiInputBase-input {
    font-family: ${robotoRegular};
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    color: ${scarpaGray800};

    &::placeholder {
      color: ${borderSelect};
    }
  }

  .li-element {
    background: green;
  }
  .MuiInputBase-root > .Mui-focused {
    border-width: 2px 0px 2px 2px !important;
    border-style: solid;
    border-color: ${blueLinks};
  }
  .MuiAutocomplete-clearIndicator {
    margin-right: 48px;
    margin-top: 4px;
  }

  .clear-icon {
    margin-right: 34px;
    svg {
      font-size: 24px;
    }
  }
`;

export const AddOn = styled.div`
  display: grid;
  position: absolute;
  right: 0;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  background: ${electricPurple600};
  border-radius: 0px 6px 6px 0px;
  font-size: 20px;
  .icon {
    font-size: 24px;
    color: ${white};
  }
  &:hover {
    cursor: pointer;
  }
`;
