import styled from 'styled-components';
import {
  btnDisable,
  monserratRegular,
  robotoRegular,
  scarpaGray800,
  white,
} from '@habitech/shared';

export const CardLargeContainer = styled.div`
  position: relative;
  display: grid;
  border: 1px solid ${btnDisable};
  grid-column: span 3;
  height: 108px;
  padding: 24px;
  .corner {
    position: absolute;
    right: 0;
  }
  .hover-buttons {
    width: 100%;
    height: 100%;
    background: rgba(37, 33, 41, 0.8);
    position: absolute;
    top: 0px;
    right: 0px;
    z-index: 201;
    display: none;
  }

  &:hover .hover-buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    .buttons {
      display: flex;
      gap: 8px;
      opacity: none !important;
    }
  }
  .container-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .container-title {
      display: flex;
      justify-content: space-between;
      .title {
        color: ${scarpaGray800};
        font-family: ${monserratRegular};
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }
    }
  }
  .container-description {
    display: flex;
    justify-content: space-between;
    .text {
      color: var(--font-habi-pcom-secundary-aa, #6d6970);
      font-family: ${robotoRegular};
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
    .value {
      color: var(--font-habi-pcom-main-aaa, #252129);
      font-family: ${robotoRegular};
      font-size: 24px;
      font-style: normal;
      font-weight: 300;
      line-height: normal;
      margin-top: 4px;
    }
  }
`;
export const CustomButton = styled.button`
  display: flex;
  height: 40px;
  padding: 8px 24px;
  justify-content: center;
  align-items: center;
  flex: 1 0 0;
  border-radius: 6px;
  background-color: ${white};
  border: none;
  width: 126px;
  opacity: 1;
  cursor: pointer;
  .text {
    color: var(--Brand-Electric-purple, #7c01ff);
    text-align: center;
    font-family: ${robotoRegular};
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  &.disabled {
    background: ${btnDisable};
    cursor: not-allowed;
    opacity: 0.8;
  }
`;
