import { TextField } from '@mui/material';
import { SetStateAction, useContext } from 'react';
import { Button } from '@habitech/call-to-action';
import { useDispatch, useSelector } from 'react-redux';
import {
  GeneralSummaryContainer,
  IndicatorContainer,
  IndicatorsSummary,
  Subtitle,
  BasicInfo,
  Title,
  ContainerComments,
  Comment,
  IndicatorBody,
  IndicatorValue,
} from './style';
import SuccessCheck from './assets/success.svg';
import ErrorSign from './assets/error.svg';
import refreshIcon from './assets/refresh.svg';
import positive from '../../../../assets/icons/positive.svg';
import { HoverButtons } from '../CardMedium/styles';
import { CustomButton } from '../CardSmall/styles';
import {
  updateBusinessOpportunity,
  updateIsRules,
  updateShowDetail,
} from '../../../../redux/reducers/searchSlice';
// eslint-disable-next-line import/no-cycle
import { CardContext } from '../../Approver';
import useApprover from '../../hooks/useApprover';
import { RevenueCardMX } from './components/RevenueCardMX';

const GeneralSummary = () => {
  const dispatch = useDispatch();
  const { handleGetApis } = useApprover();
  const stateCards = useSelector((state: any) => state.cardsStore.stateCards);
  const revenue = stateCards.filter((item: any) => item.id === 2);
  const historicalDeals = useSelector(
    (state: any) => state.cardsStore.historicalDeals
  );
  const approval = useSelector((state: any) => state.searchStore.approval);
  const trustLevel = useSelector((state: any) => state.searchStore.trustLevel);
  const hesH = useSelector((state: any) => state.searchStore.hesH);
  const role = localStorage.getItem('ROL');
  const country = localStorage.getItem('country');
  const context: any = useContext(CardContext);
  const {
    handleShowDialog,
    setComments,
    comments,
    disableButtonApprove,
    changeDecision,
    setChangeDecision,
  } = context;

  const handleComment = (event: {
    target: { value: SetStateAction<string> };
  }) => {
    setComments(event.target.value);
  };

  const scrollToHalfPage = () => {
    const halfPagePosition = window.innerHeight * 1.65;
    window.scrollTo({
      top: halfPagePosition,
      behavior: 'smooth',
    });
  };

  const handleShowDetail = () => {
    dispatch(updateIsRules(false));
    dispatch(
      updateShowDetail({
        itemName: 'historicalBusiness',
        itemValue: true,
      })
    );
    scrollToHalfPage();
  };

  return (
    <GeneralSummaryContainer>
      <Title>Resumen general</Title>
      <BasicInfo>
        <div>{approval.deal_name}</div>
        <div className="separator-content">|</div>
        <div className="badge">{approval.business_opportunity || '-'}</div>
      </BasicInfo>
      <IndicatorsSummary>
        <IndicatorContainer>
          <IndicatorBody>
            <div className="body">
              <div className="title">Confianza en Pricing</div>
              <img src={positive} alt="" width={24} height={24} />
            </div>
            <IndicatorValue>{trustLevel}</IndicatorValue>
          </IndicatorBody>
        </IndicatorContainer>
        <IndicatorContainer>
          <IndicatorBody>
            <div className="body">
              <div className="title">Negocios históricos</div>
              <img src={positive} alt="" width={24} height={24} />
            </div>
            <IndicatorValue>
              {historicalDeals?.negocios_historico}
            </IndicatorValue>
          </IndicatorBody>
          <HoverButtons className="hover-buttons">
            <div className="buttons">
              <CustomButton onClick={() => handleShowDetail()}>
                <span className="text">Ver detalle</span>
              </CustomButton>
            </div>
          </HoverButtons>
        </IndicatorContainer>
        <IndicatorContainer>
          {country === 'MX' ? (
            <RevenueCardMX />
          ) : (
            <IndicatorBody>
              <div className="body">
                <div className="title">Revenue</div>
                <img src={positive} alt="" width={24} height={24} />
              </div>
              <div className="revenue">
                <div className="title">Clasificación</div>
                <div className="value">
                  {revenue[0]?.data?.values[0]?.value || '-'}
                </div>
              </div>
            </IndicatorBody>
          )}
        </IndicatorContainer>
        <IndicatorContainer>
          <IndicatorBody>
            <div className="body">
              <div className="title">Habi es Habi</div>
              <img src={positive} alt="" width={24} height={24} />
            </div>
            <div className="hesh">
              <div className="title">
                Percentil: <span> {hesH?.percentil || '-'}</span>
              </div>
              <div className="subtitle">
                Descuento : <span> {hesH?.discount?.toFixed(4) || '-'}</span>
              </div>
            </div>
          </IndicatorBody>
        </IndicatorContainer>
      </IndicatorsSummary>
      <Subtitle>Resultado para esta propiedad</Subtitle>
      {(!changeDecision && (
        <ContainerComments>
          <Comment>
            <div className="label">Comentario obligatorio *</div>
            <TextField
              inputProps={{ 'data-testid': 'comments' }}
              disabled={role !== 'aprobologo'}
              multiline
              rows={4}
              placeholder="Escriba sus comentarios"
              onChange={handleComment}
            />
          </Comment>
          <div className="buttons-area">
            <div>
              <Button
                disabled={role !== 'aprobologo' || disableButtonApprove}
                dataId="approve-zone"
                variant="primary"
                size="large"
                onClick={() => {
                  handleShowDialog(true, 'approved');
                  dispatch(updateBusinessOpportunity('Aprobado'));
                }}
                // eslint-disable-next-line jsx-a11y/alt-text
                iconRight={<img src={SuccessCheck} />}
              >
                Aprobar
              </Button>
              <Button
                disabled={role !== 'aprobologo' || comments === ''}
                dataId="revoke-zone"
                variant="stroke"
                size="large"
                onClick={() => {
                  handleShowDialog(true, 'rejected');
                  dispatch(updateBusinessOpportunity('Rechazado'));
                }}
                // eslint-disable-next-line jsx-a11y/alt-text
                iconRight={<img src={ErrorSign} />}
              >
                Rechazar
              </Button>
            </div>
          </div>
        </ContainerComments>
      )) || (
        <ContainerComments>
          <div style={{ width: '230px' }}>
            <Button
              dataId="change-decision"
              variant="stroke"
              size="large"
              onClick={() => {
                setChangeDecision(false);
                setComments('');
                handleGetApis();
              }}
              // eslint-disable-next-line jsx-a11y/alt-text
              iconRight={<img src={refreshIcon} />}
            >
              Cambiar decisión
            </Button>
          </div>
        </ContainerComments>
      )}
    </GeneralSummaryContainer>
  );
};

export default GeneralSummary;
