import React from 'react';
import GlobalTab from '../../../../components/Tab/GlobalTab';
import ColumnDescription from './style';

interface ITapRules {
  firstSelected: boolean;
  setFirstSelected: any;
}

const TapRules: React.FC<ITapRules> = ({ firstSelected, setFirstSelected }) => {
  return (
    <>
      <ColumnDescription>
        <div className="subtitle">Seleccionar la forma de descuento </div>
      </ColumnDescription>
      <GlobalTab
        firstSelected={firstSelected}
        setFirstSelected={setFirstSelected}
        titleOne="Consultar NID"
        titleTwo="Reglas globales"
      />
    </>
  );
};

export default TapRules;
