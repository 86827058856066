import { useRef } from 'react';
import useScript from '../../hooks/useScript';
import { IGoogleLogin } from './model';

const GoogleButton = ({
  onGoogleSignIn,
  text = 'signin_with',
}: IGoogleLogin) => {
  const googleSignInButton = useRef(null);
  useScript('https://accounts.google.com/gsi/client', () => {
    (window as any).google.accounts.id.initialize({
      client_id: process.env.REACT_APP_GOOGLE_AUTH,
      callback: onGoogleSignIn,
    });
    (window as any).google.accounts.id.prompt();
    (window as any).google.accounts.id.renderButton(
      googleSignInButton.current,
      { theme: 'outline', size: 'large', text, width: 250 }
    );
  });

  return <div className="google-auth" ref={googleSignInButton} />;
};

export default GoogleButton;
