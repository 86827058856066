import ReturnLink from '../../components/ReturnLink';
import { SectionProps } from './model';
import { MainTitle, MainWrapper, ReturnWrapper } from './styles';

const SectionTemplate: React.FC<SectionProps> = ({ children, title }) => {
  return (
    <MainWrapper>
      <ReturnWrapper>
        <ReturnLink
          text="Regresar a análisis de zona"
          link="/portal/analisis-de-zona"
        />
      </ReturnWrapper>
      <MainTitle>{title}</MainTitle>

      <div className="container-section">{children}</div>
    </MainWrapper>
  );
};
export default SectionTemplate;
