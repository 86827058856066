import { ChangeEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getApprovalRuleAdministration,
  getApprovalFloorElevator,
  getApprovalElevator,
  getRevenue,
  getBedroomBathroom,
  getGarageTypology,
  getApprovalGoodZone,
  getHistoricalInfo,
  getApproval,
  getHistoricalDeals,
  getQuotas,
  getRiskAlert,
  getCommitteePrice,
  getPricingAccordingZone,
  getFinalPrice,
  getBlacklist,
  getRemo,
  getPricingTrust,
  getApprovalDiscountHesh,
  getRevenueCategory,
} from '../../../apis/approvalRules/approvalRules';
import {
  updateCardState,
  resetCardState,
  updateHistorical,
  cleanHistorical,
  updateHistoricalDeals,
  cleanHistoricalDeals,
} from '../../../redux/reducers/approverCardsSlice';
import {
  setSearch,
  setResult,
  updateApproval,
  resetApproval,
  updateTrustLevel,
  resetTrustLevel,
  updateHesH,
  resetHesH,
  updateEditable,
} from '../../../redux/reducers/searchSlice';
import {
  setDefaultState,
  setRevenueData,
} from '../../../redux/reducers/revenueSlice';
import { useGetTypologies } from './useTypologies';

const country = localStorage.getItem('country');

const useApprover = () => {
  const dispatch = useDispatch();
  const search = useSelector((state: any) => state.searchStore.search);
  const {
    handleGetElevatorMX,
    handleGetAreaTypologyMX,
    handleGetGarageTypologyMX,
    handleGetBedroomBathroomMX,
  } = useGetTypologies();

  const handleSearch = (event: ChangeEvent<HTMLInputElement>) => {
    if (!event.target.value.match(/\D/) && event.target.value.length <= 11) {
      dispatch(setSearch(event.target.value));
    }
  };

  const handleClear = () => {
    dispatch(setSearch(''));
    dispatch(cleanHistoricalDeals());
    dispatch(resetCardState());
    dispatch(resetApproval());
    dispatch(setResult('start'));
    dispatch(cleanHistorical());
    dispatch(resetTrustLevel());
    dispatch(resetHesH());
    dispatch(setDefaultState());
    return true;
  };

  const handleApprovedInfo = (value: string) => {
    if (value === 'approved') {
      return 'positive';
    }

    if (value !== 'rejected') {
      return 'warning';
    }

    return 'negative';
  };

  const formatCurrency = (value: number | undefined) => {
    if (value === undefined) {
      return '-';
    }

    return value.toLocaleString('es-CO', {
      style: 'currency',
      currency: 'COP',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
      useGrouping: true,
    });
  };

  const getSafeValue = (value: string) =>
    value !== undefined && value !== null ? value : '-';

  const handleGetRemo = async () => {
    try {
      const data = await getRemo({ nids: search, country });
      const updateData = {
        id: 14,
        size: 's',
        edited: false,
        approved: handleApprovedInfo(data.approval_result[0]?.approval_result),
        loading: false,
        data: {
          title: 'Comentarios Remodelación',
          values: {
            text_one: 'Comité:',
            text_two: data.approval_result[0]?.committee,
          },
        },
      };
      dispatch(updateCardState({ id: 14, newData: updateData }));
    } catch (error) {
      console.error(error);
    }
  };

  const handleFloorGetElevator = async () => {
    try {
      const data = await getApprovalFloorElevator({
        nids: search,
      });

      const updatedData = {
        id: 12,
        size: 's',
        approved: handleApprovedInfo(data.approval_result[0]?.approval_result),
        edited: false,
        loading: false,
        data: {
          title: 'Piso y ascensores',
          values: {
            text_one: `${
              data.approval_result[0]?.property_type
                ? getSafeValue(data.approval_result[0]?.property_type)
                : '-'
            }: Piso ${getSafeValue(data.approval_result[0]?.floor_number)}`,
            text_two: `Ascensor: ${getSafeValue(
              data.approval_result[0]?.elevator_number
            )}`,
            text_three: `Último piso: ${getSafeValue(
              data.approval_result[0]?.last_floor
            )}`,
          },
        },
      };
      dispatch(updateCardState({ id: 12, newData: updatedData }));
      dispatch(setResult('ready'));
    } catch (error) {
      console.error(error);
    }

    return true;
  };

  const handleGetElevator = async () => {
    try {
      const data = await getApprovalElevator({
        nid: search,
      });

      const updatedData = {
        id: 10,
        size: 's',
        approved: handleApprovedInfo(data.approval_result?.approval_result),
        edited: false,
        loading: false,
        showDetail: true,
        data: {
          title: 'Tipologías de Elevadores',
          values: {
            text_one: 'Elevadores',
            value_one: getSafeValue(data.approval_result?.elevator),
          },
          dataTable: data.approval_result.table_data,
          elevator: data.approval_result.elevator,
          nid: data.approval_result.nid,
          approval_result: data.approval_result.approval_result,
          median_zone: data.approval_result.median_zone,
        },
      };
      dispatch(updateCardState({ id: 10, newData: updatedData }));
      dispatch(setResult('ready'));
    } catch (error) {
      console.error(error);
    }
  };

  const handleGetAdministrationInfo = async () => {
    try {
      const data = await getApprovalRuleAdministration({
        country: 'CO',
        nid: search,
      });
      const updatedData = {
        id: 15,
        size: 's',
        edited: false,
        approved: handleApprovedInfo(data.approval_result[0]?.approval_result),
        loading: false,
        data: {
          title: 'Administración',
          values: {
            text_one: 'Valor:',
            text_two: 'Tope:',
            value_one: formatCurrency(data.approval_result[0]?.admin_price),
            value_two: formatCurrency(data.approval_result[0]?.admin_limit),
          },
        },
      };

      dispatch(updateCardState({ id: 15, newData: updatedData }));
      const status = data.approval_result.length === 0 ? 'error' : 'ready';
      dispatch(setResult(status));
    } catch (error: any) {
      console.error(error);
    }
  };

  const constructData = (newData: any) => {
    if (newData.approval_result.length === 0) {
      return {
        id: 2,
        size: 'm',
        edited: false,
        approved: 'warning',
        loading: false,
        data: {
          title: 'Revenue demasiado bajo',
          values: [
            { title: 'Categoría', value: '-' },
            { title: 'Expectativa días venta', value: '-' },
            { title: '¿Habi es Habi?', value: '-' },
          ],
        },
      };
    }

    return {
      id: 2,
      size: 'm',
      edited: false,
      approved: handleApprovedInfo(newData.approval_result[0]?.approval_result),
      loading: false,
      data: {
        title: 'Revenue demasiado bajo',
        values: [
          {
            title: 'Categoría',
            value: getSafeValue(newData.approval_result[0].revenue),
          },
          {
            title: 'Expectativa días venta',
            value: `${getSafeValue(
              newData.approval_result[0].expected_days.toFixed(0)
            )} Días`,
          },
          {
            title: '¿Habi es Habi?',
            value: newData.approval_result[0].HesH ? 'Si' : 'No',
          },
        ],
      },
    };
  };

  const handleGetRevenue = async () => {
    try {
      const newData = await getRevenue({ country: 'CO', nid: search });
      const updatedData = constructData(newData);
      dispatch(updateCardState({ id: 2, newData: updatedData }));
      return true;
    } catch (error) {
      console.error(error);
      return false;
    }
  };

  const handleGetBedroomBathroom = async () => {
    try {
      const response = await getBedroomBathroom({ nid: search });
      const data = response.approval_result;
      const updatedData = {
        id: 3,
        size: 's',
        edited: false,
        approved: handleApprovedInfo(data[0]?.approval_result),
        loading: false,
        showDetail: true,
        data: {
          title: 'Tipología Habitaciones y Baños',
          values: {
            text_one: 'Habitaciones',
            value_one: getSafeValue(data[0]?.axis_x_y[0]),
            text_two: 'Baños',
            value_two: getSafeValue(data[0]?.axis_x_y[1]),
          },
        },
        table: response,
      };

      dispatch(updateCardState({ id: 3, newData: updatedData }));
      const status = data.approval_result.length === 0 ? 'error' : 'ready';
      dispatch(setResult(status));
    } catch (error) {
      console.error('error');
    }

    return true;
  };

  const handleGetGarageTypology = async () => {
    try {
      const response = await getGarageTypology({ nid: search });
      const data = response.approval_result;
      const updatedData = {
        id: 7,
        size: 's',
        edited: false,
        approved: handleApprovedInfo(data?.approval_result),
        loading: false,
        showDetail: true,
        data: {
          title: 'Tipologías de Estacionamiento',
          values: {
            text_one: 'Estacionamientos',
            value_one: data.garage,
          },
        },
        table: response,
      };

      dispatch(updateCardState({ id: 7, newData: updatedData }));
      const status =
        data.approval_result.table_data.length === 0 ? 'error' : 'ready';
      dispatch(setResult(status));
    } catch (error) {
      console.error('error');
    }

    return true;
  };

  const handleGetHistoricalDeals = async () => {
    try {
      const response = await getHistoricalDeals({ nid: search });
      dispatch(updateHistoricalDeals(response[0]));
    } catch (error) {
      console.error(error);
    }
  };

  const handleGetQuotas = async () => {
    let approvedStatus = 'negative';
    let probDays;

    try {
      const response = await getQuotas({ nid: search });
      approvedStatus = response[0].cupo_final === '0' ? 'negative' : 'positive';

      if (country === 'MX') {
        const { DiasProb, Categoria } = await getRevenueCategory({
          nid: search,
        });
        probDays = Math.floor(Number(DiasProb));
        dispatch(
          setRevenueData({
            probDays,
            revenueCategory: Categoria,
          })
        );
        approvedStatus =
          Number(response[0].inventario.inv_conjunto) > 3
            ? 'negative'
            : approvedStatus;
      }

      const updatedData = {
        id: 13,
        size: 'm',
        edited: false,
        approved: approvedStatus,
        loading: false,
        data: {
          title: 'Reporte High Level',
          values: [
            {
              title: 'Inventario',
              value: response[0]?.lista,
            },

            {
              title: 'Cupos disponibles en zona',
              value: response[0]?.cupos_radio,
            },

            {
              title: 'Inventario en la zona',
              value:
                // eslint-disable-next-line no-unsafe-optional-chaining
                +response[0].inventario?.no_publicado +
                // eslint-disable-next-line no-unsafe-optional-chaining
                +response[0].inventario?.publicado,
            },
            ...(country === 'MX'
              ? [
                  {
                    title: 'Días esperados venta',
                    value: probDays,
                  },
                ]
              : []),
          ],
        },
      };
      dispatch(updateCardState({ id: 13, newData: updatedData }));
    } catch (error) {
      console.error(error);
    }
  };

  const handleGetHistoricalInfo = async () => {
    try {
      const response = await getHistoricalInfo({ nid: search });
      const updateData = response.approval_result[0];
      dispatch(updateHistorical(updateData));
    } catch (error) {
      console.error(error);
    }
  };

  const handleGetApprovalGoodZone = async () => {
    try {
      const response = await getApprovalGoodZone({ nid: search });
      const data = response.appproval_result;
      const updatedData = {
        id: 6,
        size: 's',
        edited: false,
        approved: handleApprovedInfo(data?.approval_result),
        loading: false,
        showDetail: true,
        data: {
          title: 'Zona buena',
        },
        table: response,
      };

      dispatch(updateCardState({ id: 6, newData: updatedData }));
      const status =
        data[0].approval_result.table_data.length === 0 ? 'error' : 'ready';
      dispatch(setResult(status));
    } catch (error) {
      console.error(error);
    }

    return true;
  };

  const handleGetRiskAlert = async () => {
    try {
      const response = await getRiskAlert(search);
      const data = response.approval_result[0];

      const updatedData = {
        approved: handleApprovedInfo(data?.approval_result),
        edited: false,
        loading: false,
        data: {
          title: 'Riesgo / Amenazas',
          values: {
            text_one: 'Inundación',
            text_two: 'Remoción en masa',
            text_three: 'Riesgo',
            value_one: getSafeValue(data?.threat_flood),
            value_two: getSafeValue(data?.threat_masses),
            value_three: getSafeValue(data?.risk_property),
          },
        },
      };
      dispatch(updateCardState({ id: 17, newData: updatedData }));
      dispatch(setResult('ready'));
    } catch (error) {
      console.error(error);
    }
  };

  const handleGetCommitteePrice = async () => {
    try {
      const response = await getCommitteePrice(Number(search));
      const data = response.approval_result[0];

      const updatedData = {
        edited: false,
        approved: handleApprovedInfo(data?.approval_result),
        loading: false,
        data: {
          title: 'Alertas precios del comité final y Hubspot',
          values: {
            ask_price_committee: getSafeValue(
              formatCurrency(data?.ask_price_committe)
            ),
            ask_price_hubspot: getSafeValue(
              formatCurrency(data?.ask_price_hubspot)
            ),
            purchasing_committee: getSafeValue(
              formatCurrency(data?.purchase_committe)
            ),
            manual_price_hubspot: getSafeValue(
              formatCurrency(data?.manual_price_hubspot)
            ),
            gray_work: getSafeValue(data?.grey_work),
          },
        },
      };
      dispatch(updateCardState({ id: 1, newData: updatedData }));
      dispatch(setResult('ready'));
    } catch (error) {
      console.error(error);
    }
  };

  const handleGetPricingAccordingZone = async () => {
    try {
      const response = await getPricingAccordingZone(Number(search));
      const data = response.approval_result[0];

      let status1 = 'warning';

      if (data?.ask_price_committee <= data?.top_price_area) {
        status1 = 'positive';
      }

      if (data?.ask_price_committee > data?.top_price_area) {
        status1 = 'negative';
      }

      const updatedData1 = {
        approved: status1,
        loading: false,
        edited: false,
        data: {
          title: 'Pricing Acorde a Zona',
          values: {
            text_one: 'AskPrice final:',
            text_two: 'Tope:',
            value_one: data?.ask_price_committee
              ? formatCurrency(Number(getSafeValue(data?.ask_price_committee)))
              : '-',
            value_two: data?.top_price_area
              ? formatCurrency(Number(getSafeValue(data?.top_price_area)))
              : '-',
          },
        },
      };
      dispatch(updateCardState({ id: 8, newData: updatedData1 }));

      let status2 = 'warning';

      if (Number(data?.antiquity) < 30) {
        status2 = 'positive';
      }

      const updateData2 = {
        approved: status2,
        loading: false,
        edited: false,
        data: {
          title: 'Castigo por antigüedad para comité automático',
          values: [
            { title: 'Antigüedad', value: getSafeValue(data?.antiquity) },
            {
              title: 'Comité automático',
              value: getSafeValue(data?.check_committee),
            },
            {
              title: 'Clasificación Remo',
              value: getSafeValue(data?.remo_qualification),
            },
          ],
        },
      };
      dispatch(updateCardState({ id: 11, newData: updateData2 }));

      let status3 = 'warning';
      let updateData3 = {};

      if (country === 'CO') {
        if (
          Number(data?.ask_price_committee) <
          Number(data?.manual_price_commitee)
        ) {
          status3 = 'positive';
        }

        updateData3 = {
          approved: status3,
          edited: false,
          loading: false,
          data: {
            title: 'Alerta precio manual superior a Ask Price',
            values: {
              text_one: 'Askprice Hubspot',
              text_two: 'Precio Manual comité Hubspot',
              value_one: data?.ask_price_committee
                ? formatCurrency(
                    Number(getSafeValue(data?.ask_price_committee))
                  )
                : '-',
              value_two: data?.manual_price_commitee
                ? formatCurrency(
                    Number(getSafeValue(data?.manual_price_commitee))
                  )
                : '-',
            },
          },
        };
      } else {
        if (
          Number(data?.buy_and_cell_price) <
            Number(data?.ask_price_committee) &&
          Number(data?.lending_price) < Number(data?.ask_price_committee)
        ) {
          status3 = 'positive';
        }

        updateData3 = {
          approved: status3,
          edited: false,
          loading: false,
          data: {
            title: 'Alerta precio manual superior a Ask Price',
            values: {
              text_one: 'Askprice Hubspot',
              text_two: 'Préstamo',
              text_three: 'Compra y Venta',
              value_one: formatCurrency(
                Number(getSafeValue(data?.ask_price_committee))
              ),
              value_two: formatCurrency(
                Number(getSafeValue(data?.lending_price))
              ),
              value_three: formatCurrency(
                Number(getSafeValue(data?.buy_and_cell_price))
              ),
            },
          },
        };
      }

      dispatch(updateCardState({ id: 18, newData: updateData3 }));

      let status4 = 'warning';

      if (Number(data?.area) > 38) {
        status4 = 'positive';
      }

      const updateData4 = {
        edited: false,
        approved: status4,
        loading: false,
        data: {
          title: 'Normativa del Infonavit o Fovissste',
          values: [{ title: 'Area', value: getSafeValue(data?.area) }],
        },
      };
      dispatch(updateCardState({ id: 21, newData: updateData4 }));

      dispatch(setResult('ready'));
    } catch (error) {
      console.error(error);
    }
  };

  const handleGetFinalPrice = async () => {
    try {
      const response = await getFinalPrice(search);
      const data = response.approval_result[0];

      const updatedData = {
        edited: false,
        approved: handleApprovedInfo(data?.approval_result),
        loading: false,
        data: {
          title: 'Alerta precio final',
          values: [
            {
              title: 'Rango superior oferta:',
              value: data?.top_offer_range
                ? formatCurrency(Number(getSafeValue(data?.top_offer_range)))
                : '-',
            },
            {
              title: 'Precio oferta final',
              value: data?.final_price_offer
                ? formatCurrency(Number(getSafeValue(data?.final_price_offer)))
                : '-',
            },
          ],
          description: getSafeValue(data?.message),
        },
      };
      dispatch(updateCardState({ id: 9, newData: updatedData }));
      dispatch(setResult('ready'));
    } catch (error) {
      console.error(error);
    }
  };

  const handlePricingTrust = async () => {
    try {
      const response = await getPricingTrust({ nid: search });
      const trustLevel = response.approval_result[0].trust_level.toFixed(1);
      dispatch(updateTrustLevel(trustLevel || '-'));
    } catch (error) {
      console.error(error, 'handlePricingTrust');
    }
  };

  const handleGetApprovalDiscountHesh = async () => {
    try {
      const response = await getApprovalDiscountHesh({ nid: search });
      dispatch(updateHesH(response.approval_result[0]));
    } catch (error) {
      console.error(error);
    }
  };

  const handleGetBlacklist = async () => {
    try {
      const response = await getBlacklist(search);
      const data = response.approval_result[0];

      const updatedData = {
        size: 's',
        edited: false,
        approved: handleApprovedInfo(data?.approval_result),
        loading: false,
        data: {
          title: 'Blacklist',
          values: {
            text_one: getSafeValue(data?.list),
            text_two: getSafeValue(data?.lote_id),
          },
        },
      };
      dispatch(updateCardState({ id: 16, newData: updatedData }));
      dispatch(setResult('ready'));
    } catch (error) {
      console.error(error);
    }
  };

  const handleGetApisCol = () => {
    handlePricingTrust();
    handleGetRevenue();
    handleGetAdministrationInfo();
    handleGetBedroomBathroom();
    handleGetFinalPrice();
    handleGetElevator();
    handleGetRiskAlert();
    handleGetGarageTypology();
  };

  const callGeneralApis = (editable: boolean) => {
    handleGetApprovalDiscountHesh();
    handleGetCommitteePrice();
    handleGetApprovalGoodZone();
    handleGetPricingAccordingZone();
    handleFloorGetElevator();
    handleGetQuotas();
    handleGetBlacklist();
    handleGetRemo();
    handleGetHistoricalInfo();
    handleGetHistoricalDeals();

    if (country === 'MX') {
      handleGetBedroomBathroomMX();
      handleGetElevatorMX();
      handleGetGarageTypologyMX();
      handleGetAreaTypologyMX();
    }

    if (country === 'CO') {
      handleGetApisCol();
    }

    setTimeout(() => {
      dispatch(updateEditable(editable));
    }, 18000);
  };

  const handleGetApis = () => {
    const development = 'development';
    const production = 'production';
    const environment = process.env.REACT_ENV;

    dispatch(resetCardState());
    dispatch(resetApproval());
    dispatch(setResult('loading'));

    if (environment === development) {
      const newData = {
        nid: '19366543739',
        deal_name: 'TEST',
        business_opportunity: 'aprobacion_fase1',
        approval_history_id: 51419,
      };
      dispatch(updateApproval(newData));

      callGeneralApis(true);
    }

    if (environment === production) {
      getApproval({ nid: search, country })
        .then((data) => {
          const newData = {
            nid: data.nid,
            deal_name: data.deal_name,
            business_opportunity: data.business_opportunity,
            approval_history_id: data.approval_history_id,
          };
          dispatch(updateApproval(newData));

          callGeneralApis(data.editable);
        })
        .catch(() => {
          dispatch(setResult('error'));
        });
    }

    return true;
  };

  return {
    handleGetApis,
    search,
    setSearch,
    handleSearch,
    handleClear,
    handleGetBedroomBathroom,
    handleApprovedInfo,
    formatCurrency,
    handleFloorGetElevator,
    handleGetRevenue,
    handleGetGarageTypology,
    handleGetApprovalGoodZone,
    handleGetHistoricalInfo,
    constructData,
    handleGetRiskAlert,
    handleGetCommitteePrice,
    handleGetPricingAccordingZone,
    handleGetFinalPrice,
    handleGetBlacklist,
    handleGetRemo,
    handlePricingTrust,
    handleGetApisCol,
    handleGetApprovalDiscountHesh,
    handleGetQuotas,
  };
};

export default useApprover;
