import { useEffect, useState } from 'react';
import { useAppSelector } from '../../../../redux/hooks';
import ModalConfirmation from '../../../../components/ModalConfirmation';
import SelectDecision from '../SelectDecision/SelectDecision';
import SelectTypeZone from '../SelectTypeZone';
import ZoneSelector from '../ZoneSelector';
import ImageOffice from '../../../../assets/images/OfficeWorking.svg';
import ImageOfficeTwo from '../../../../assets/images/office-working2.svg';
import ImageOfficeThree from '../../../../assets/images/office-working3.svg';
import {
  getAllCities,
  getMetropolitanArea,
  putDiscountMedianZones,
  putPlacesMedianZones,
} from '../../../../apis/selectZone/areas';
import filterBy from '../../ZoneValidation.utils';
import { TAutocompleteValue } from '../../model';
import {
  WrapperZoneValidation,
  TitleZonesValidation,
  ContainerValidationZone,
} from '../../styles';
import { getZoneByMatch } from '../../../../apis/searchZones/searchZones';

const ContainerZoneValidation = () => {
  const [tabSelected, setTabSelected] = useState(1);
  const [modalToShow, setModalToShow] = useState(0);
  const [discountApartment, setDiscountApartment] = useState('');
  const [discountHouse, setDiscountHouse] = useState('');
  const [enableSend, setEnableSend] = useState(false);
  const [rangeIds, setRangeIds] = useState([]);
  const [typeDiscount, setTypeDiscount] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [tabDiscountPlaces, setTabDiscountPlaces] = useState('discount');
  const [noInmuebles, setNoInmuebles] = useState(0);
  const [revenue, setRevenue] = useState('');
  const [typeList, setTypeList] = useState('');
  const [countList, setCountList] = useState(0);
  const [selectedOptions, setSelectedOptions] = useState<TAutocompleteValue[]>(
    []
  );
  const [selectorOptions, setSelectorOptions] = useState<TAutocompleteValue[]>(
    []
  );
  const userMail = useAppSelector((state) => state.userStore?.user?.email);

  const mapOptions = (response: any, zones: boolean) => {
    const newData: TAutocompleteValue[] = [];
    // eslint-disable-next-line array-callback-return
    response.data.map((item: any) => {
      const data = {
        label: !zones ? item.label : item.zm_label,
        value: !zones ? item.id : item.zm_id,
      };
      newData.push(data);
    });
    setSelectorOptions(newData);
    setIsLoaded(true);
  };

  useEffect(() => {
    setIsLoaded(false);
    setSelectedOptions([]);

    switch (tabSelected) {
      case 1:
        // get metropolitan areas
        getMetropolitanArea().then((response) => {
          if (response.success) {
            mapOptions(response, false);
          }
        });
        break;

      case 2:
        // get all cities
        getAllCities().then((response) => {
          if (response.success) {
            mapOptions(response, false);
          }
        });
        break;

      case 3:
        getZoneByMatch('los').then((response: any) => {
          if (response.data.success) {
            mapOptions(response.data, true);
          }
        });
        break;

      default:
        break;
    }
  }, [tabSelected]);

  useEffect(() => {
    const newData: any = [];

    // eslint-disable-next-line array-callback-return
    selectedOptions.forEach((item) => {
      newData.push(item.value);
    });

    setRangeIds(newData);
  }, [selectedOptions, tabSelected]);

  // useEffect to clean discount Data

  useEffect(() => {
    setEnableSend(false);

    if (typeDiscount) {
      setDiscountApartment('0');
      setDiscountHouse('0');
    } else {
      setDiscountApartment('');
      setDiscountHouse('');
    }
  }, [typeDiscount]);

  // useEffect to validate data an enable button send

  useEffect(() => {
    if (typeDiscount) {
      if (Number(discountApartment) === 0 && Number(discountHouse) === 0) {
        setEnableSend(false);
      } else {
        setEnableSend(true);
      }
    } else if (discountApartment === '' && discountHouse === '') {
      setEnableSend(false);
    } else {
      setEnableSend(true);
    }
  }, [discountApartment, discountHouse, typeDiscount]);

  const handleOrderDataToSend = async () => {
    setModalToShow(3);
    const dataToSend = {
      filter_by: filterBy[tabSelected],
      type: typeDiscount ? 'add_less' : 'change_all',
      country: 'CO',
      user_email: userMail,
      data: {
        apartment: discountApartment ? +discountApartment : null,
        house: discountHouse ? +discountHouse : null,
        range_ids: rangeIds,
      },
    };

    try {
      await putDiscountMedianZones(dataToSend);
      setModalToShow(1);
    } catch (error) {
      setModalToShow(2);
      console.error(error);
    }
  };

  const handleSaveUpdateList = async () => {
    setModalToShow(3);

    let minMaxValues = noInmuebles
      ? { min: noInmuebles, max: noInmuebles + 99 }
      : null;

    if (noInmuebles && noInmuebles === 50) {
      minMaxValues = noInmuebles ? { min: 10, max: 50 } : null;
    }

    if (noInmuebles && noInmuebles === 51) {
      minMaxValues = noInmuebles ? { min: 51, max: 100 } : null;
    }

    if (noInmuebles && noInmuebles === 1001) {
      minMaxValues = noInmuebles
        ? { min: noInmuebles, max: noInmuebles + 999 }
        : null;
    }

    const dataToSend = {
      filter_by: filterBy[tabSelected],
      type_list: typeList,
      country: 'CO',
      range_ids: rangeIds,
      value: Number(countList),
      quota_type: revenue === '' ? 'G' : revenue,
      min: minMaxValues?.min,
      max: minMaxValues?.max,
    };

    try {
      await putPlacesMedianZones(dataToSend);
      setModalToShow(1);
    } catch (error) {
      setModalToShow(2);
    }

    setNoInmuebles(0);
    setRevenue('');
    setTypeList('');
    setCountList(0);
  };

  const handleFinish = () => {
    setModalToShow(0);
    setShowModal(false);
  };

  const modalInfo = [
    {
      title: '¿Estás seguro de editar los descuentos para las zonas?',
      title2: '',
      subtitle:
        'El editar los descuentos de casas y apartamentos afectará a todos los inmuebles que pertenecen a las Áreas Metropolitanas seleccionadas',
      buttonOk: 'Si, cambiar',
      buttonCancel: 'No, cancelar',
      action: handleOrderDataToSend,
      image: ImageOffice,
      showButtons: true,
    },
    {
      title: 'Descuento editado',
      title2: 'Has editado los descuentos actuales',
      subtitle:
        'Puedes regresar a la zona y revisar que los cambios se hayan aplicado.',
      buttonOk: 'Ver validaciones',
      buttonCancel: 'Cancelar',
      action: handleFinish,
      actionToCancel: handleFinish,
      cancelFunction: true,
      image: ImageOfficeTwo,
      showButtons: true,
    },
    {
      title: 'Cambio no aplicado',
      title2: 'No se pudieron editar los descuentos',
      subtitle:
        'Sí identificas que es un error del portal, repórtalo al equipo de Spacestation.',
      buttonOk: 'Volver a intentarlo',
      buttonCancel: 'Cancelar',
      action: handleFinish,
      image: ImageOfficeThree,
      showButtons: true,
    },
    {
      title: 'Estamos despegando',
      title2: 'El cambio de cupos puede tomar un tiempo',
      subtitle:
        'En cualquier momento verás la notificación del que el cambio fue realizado o sí por el contrario ocurrió un error',
      buttonOk: '',
      buttonCancel: '',
      action: () => {},
      // eslint-disable-next-line global-require
      image: require('../../../../assets/images/ship.gif'),
      showButtons: false,
    },
  ];

  const modalInfoPlaces = [
    {
      title: '¿Estás seguro de editar los cupos por lista para estas zonas?',
      title2: '',
      subtitle:
        'El editar los cupos de las listas afectará a todos los conjuntos que pertenecen a las áreas Metropolitanas seleccionadas',
      buttonOk: 'Si, cambiar',
      buttonCancel: 'No, cancelar',
      action: handleSaveUpdateList,
      image: ImageOffice,
      showButtons: true,
    },
    {
      title: 'Cupos editados',
      title2: 'Has editado el número de cupos por lista',
      subtitle:
        'Puedes regresar a la zona y revisar que los cambios se hayan aplicado.',
      buttonOk: 'Ver validaciones',
      buttonCancel: 'Cancelar',
      action: handleFinish,
      actionToCancel: handleFinish,
      cancelFunction: true,
      image: ImageOfficeTwo,
      showButtons: true,
    },
    {
      title: 'Cambio no aplicado',
      title2: 'No se pudieron editar los cupos',
      subtitle:
        'Sí identificas que es un error del portal, repórtalo al equipo de Spacestation.',
      buttonOk: 'Volver a intentarlo',
      buttonCancel: 'Cancelar',
      action: handleFinish,
      image: ImageOfficeThree,
      showButtons: true,
    },
    {
      title: 'Estamos despegando',
      title2: 'El cambio de cupos puede tomar un tiempo',
      subtitle:
        'En cualquier momento verás la notificación del que el cambio fue realizado o sí por el contrario ocurrió un error',
      buttonOk: '',
      buttonCancel: '',
      action: () => {},
      // eslint-disable-next-line global-require
      image: require('../../../../assets/images/ship.gif'),
      showButtons: false,
    },
  ];

  const getZonesByWord = (word: string) => {
    getZoneByMatch(word).then((response: any) => {
      if (response.data.success) {
        mapOptions(response.data, true);
      }
    });
  };

  return (
    <WrapperZoneValidation>
      {(tabDiscountPlaces === 'discount' && (
        <ModalConfirmation
          showModal={showModal}
          setShowModal={setShowModal}
          modalInfo={modalInfo[modalToShow]}
          id={0}
          showButtons={modalInfo[modalToShow].showButtons}
          cancelFunction={modalInfo[modalToShow].cancelFunction}
          actionToCancel={modalInfo[modalToShow].actionToCancel}
        />
      )) || (
        <ModalConfirmation
          showModal={showModal}
          setShowModal={setShowModal}
          modalInfo={modalInfoPlaces[modalToShow]}
          id={0}
          showButtons={modalInfoPlaces[modalToShow].showButtons}
          cancelFunction={modalInfoPlaces[modalToShow].cancelFunction}
          actionToCancel={modalInfoPlaces[modalToShow].actionToCancel}
        />
      )}
      <TitleZonesValidation>Validaciones de zona</TitleZonesValidation>
      <ContainerValidationZone>
        <SelectTypeZone
          tabSelected={tabSelected}
          setTabSelected={setTabSelected}
        />
        <ZoneSelector
          selectedOptions={selectedOptions}
          tabSelected={tabSelected}
          setSelectedOptions={setSelectedOptions}
          selectorOptions={selectorOptions}
          setSelectorOptions={setSelectorOptions}
          isLoaded={isLoaded}
          getZonesByWord={getZonesByWord}
        />
        {selectedOptions.length > 0 && (
          <SelectDecision
            sendAction={() => {
              setModalToShow(0);
              setShowModal(true);
            }}
            firstSelected={typeDiscount}
            setFirstSelected={setTypeDiscount}
            discountApartment={discountApartment}
            setDiscountApartment={setDiscountApartment}
            discountHouse={discountHouse}
            setDiscountHouse={setDiscountHouse}
            disabledButton={!enableSend}
            tabDiscountPlaces={tabDiscountPlaces}
            setTabDiscountPlaces={setTabDiscountPlaces}
            setNoInmuebles={setNoInmuebles}
            noInmuebles={noInmuebles}
            setRevenue={setRevenue}
            setTypeList={setTypeList}
            typeList={typeList}
            countList={countList}
            setCountList={setCountList}
            revenue={revenue}
          />
        )}
      </ContainerValidationZone>
    </WrapperZoneValidation>
  );
};

export default ContainerZoneValidation;
