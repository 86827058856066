import { useSelector, useDispatch } from 'react-redux';
import { updateIsRules } from '../../../../redux/reducers/searchSlice';
import {
  ContainerTabLine,
  FirstTab,
  FirstTabNS,
  FirstTabTitle,
  SecondTabTitle,
  SecondTanSelected,
  SecondTab,
} from './styles';

const TabLine = () => {
  const dispatch = useDispatch();
  const isRules = useSelector((state: any) => state.searchStore.isRules);
  return (
    <ContainerTabLine>
      <div className="tab-rules">
        <FirstTabTitle
          onClick={() => {
            dispatch(updateIsRules(true));
          }}
          isRules={isRules}
        >
          Reglas
        </FirstTabTitle>
        {isRules ? <FirstTab /> : <FirstTabNS />}
      </div>
      <div className="second-tab-rules">
        <SecondTabTitle
          onClick={() => {
            dispatch(updateIsRules(false));
          }}
          isRules={isRules}
        >
          Validaciones
        </SecondTabTitle>
        {!isRules ? (
          <SecondTanSelected>
            <FirstTab /> <SecondTab />
          </SecondTanSelected>
        ) : (
          <SecondTab />
        )}
      </div>
    </ContainerTabLine>
  );
};

export default TabLine;
