// libraries.
import { useSelector } from 'react-redux';

// styles.
import { IndicatorBody } from '../style';

// assets.
import positive from '../../../../../assets/icons/positive.svg';

export const RevenueCardMX = () => {
  const { revenueCategory } = useSelector((state: any) => state.revenueStore);

  return (
    <IndicatorBody>
      <div className="body">
        <div className="title">Revenue</div>
        <img src={positive} alt="" width={24} height={24} />
      </div>
      <div className="revenue">
        <div className="title">Clasificación</div>
        <div className="value">{revenueCategory || '-'}</div>
      </div>
    </IndicatorBody>
  );
};
