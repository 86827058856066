import { Button } from '@habitech/call-to-action';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { useDispatch } from 'react-redux';
import {
  Styled404Article,
  StyledText404,
  StyledTitle404,
  StyledWrapper404,
} from './Page404.styles';

import img404 from '../../assets/images/404-image.svg';
import { userStoreActions } from '../../redux/reducers/userReducer';
import { TOKEN } from '../../shared/constants';
import { authRevoke } from '../../apis/auth/auth.api';

const Page404 = () => {
  const dispatch = useDispatch();
  const country = localStorage.getItem('country');

  const handleRevokeToken = async () => {
    try {
      await authRevoke(TOKEN?.RefreshToken, country);
    } catch (error) {
      console.error(error);
    }

    localStorage.clear();
    window.location.href = "/iniciar-sesion";
  };

  const logoutRedirect = () => {
    dispatch(userStoreActions.clearUser());
    handleRevokeToken();
  };

  return (
    <Styled404Article>
      <StyledWrapper404>
        <img src={img404} alt='' />
        <StyledTitle404>¡Lo sentimos!</StyledTitle404>
        <StyledText404>
          Nos perdimos en el camino. No hemos encontrado lo que buscabas pero
          estamos trabajando para solucionarlo.
        </StyledText404>
        <Button dataId='test_id' onClick={logoutRedirect}>
          Regresar
        </Button>
      </StyledWrapper404>
    </Styled404Article>
  );
};

export default Page404;
