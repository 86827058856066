import { monserratRegular, robotoRegular, scarpaGray600 } from '@habitech/shared';
import styled from 'styled-components';


export const WrapperGraphic = styled.div`
  display: grid;
  grid-template-columns: 45% 45%;
  padding: 0 0 0 24px;
  .chart {
    display: grid;
    justify-content: right;
  }
`;

export const ItemLegend = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  margin-bottom: 14px;
  gap: 8px;
`;

export const ItemText = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  .text {
    font-family: ${robotoRegular};
    font-weight: 600;
    font-size: 12px;
    color: ${scarpaGray600};
  }
  .value {
    font-family: ${monserratRegular};
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 17px;
    display: flex;
    align-items: center;
    letter-spacing: -0.5px;
    color: ${scarpaGray600};
  }
`;

export const LegendColor = styled.div`
  width: 16px;
  height: 16px;
  background: ${(props) => props.color};
  border-radius: 4px;
`;
export const WrapperLegend = styled.div``;
