import zonesClient from '../../../utils/zonesClient';

export interface ApiResponse<T> {
  success: boolean;
  message: string;
  data: T;
}

export interface InfoZone {
  zm_id: number;
  zm_label: string;
  zm_code: number;
  zg_label: string;
  city: string;
  total_purchases: number;
  total_sales: number;
  current_inventory: number;
  market_share: number;
  zm_polygon: ZmPolygon[];
}

export interface ZmPolygon {
  lng: number;
  lat: number;
}

type InfoZoneResponse = ApiResponse<InfoZone>;

const getZoneInfo = async (zoneId: number) => {
  const { data } = await zonesClient.get<InfoZoneResponse>('get_info_zone', {
    params: { zone_id: zoneId },
  });

  return data;
};

export default getZoneInfo;
