import { useState } from 'react';
import DoughnutGraph from './components/DoughnutGraph/DoughnutGraph';
import {
  ContainerBusinessProjection,
  ColumnDescription,
  ContainerCardBusinessProjection,
  ContainerComparison,
} from './styles';

const BusinessProjection = ({ date, setDate, data, loading }: any) => {
  const [editDate, setEditDate] = useState(false);

  const formatDate = (inputDate: string): string => {
    const months: Record<string, string> = {
      '01': 'enero',
      '02': 'febrero',
      '03': 'marzo',
      '04': 'abril',
      '05': 'mayo',
      '06': 'junio',
      '07': 'julio',
      '08': 'agosto',
      '09': 'septiembre',
      '10': 'octubre',
      '11': 'noviembre',
      '12': 'diciembre',
    };

    const [year, month, day] = inputDate.split('-');
    const monthName = months[month];

    return `${day} de ${monthName} de ${year}`;
  };

  return (
    <ContainerBusinessProjection>
      <ColumnDescription>
        <div className="subtitle">Edita las reglas de aprobación</div>
      </ColumnDescription>
      <ContainerCardBusinessProjection>
        <div className="container-title ">
          <div className="title">Proyección de negocios</div>
          <div className="subtitle">
            Última modificación de reglas para estas zonas:{' '}
            {data.last_date_updated ? formatDate(data.last_date_updated) : '--'}
          </div>
          <ContainerComparison>
            <DoughnutGraph dataToShow={data.month} loading={loading} />
            <DoughnutGraph
              title="Rango de fecha"
              showEdit
              editDate={editDate}
              setEditDate={setEditDate}
              date={date}
              setDate={setDate}
              dataToShow={data.range}
              loading={loading}
            />
          </ContainerComparison>
        </div>
      </ContainerCardBusinessProjection>
    </ContainerBusinessProjection>
  );
};

export default BusinessProjection;
