import { Dispatch, SetStateAction } from 'react';

export type IConditions = {
  equal_to: string;
  less_than: string;
  greater_than: string;
  less_equal_to: string;
  greater_equal_to: string;
};
export type IAcceptReject = {
  equal_to: string;
  less_than: string;
  greater_than: string;
  less_equal_to: string;
  greater_equal_to: string;
};

export interface IApprovalRule {
  id: number;
  approval_rule_id: number;
  accept_all: number;
  approval_rule_name: string;
  general_rule_label: string;
  general_rule_conditon: string;
  general_rule_value: string;
  zone_applied: number;
}

export interface ICurrentGlobalRules {
  rules: IApprovalRule[];
  total_zones: number;
  success?: boolean;
  handleShowModal?: Function;
  setShowCreate?: Dispatch<SetStateAction<boolean>>;
  loadingRules?: boolean;
}
export interface IRulesResponse {
  rules: IApprovalRule[];
  total_zones: number;
  success?: boolean;
}

export const initialGeneralRules: IRulesResponse = {
  rules: [],
  total_zones: 0,
  success: false,
};
