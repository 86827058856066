import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import BidimensionalTable from '../TwoDimensionalTable/TwoDimensionalTable';
import { ContainerComment, Subtitle } from './styles';
import HabiLoader from '../../../../../../components/HabiLoader/HabiLoader';

const DetailsRoomsBathrooms = () => {
  const cardId = 3;
  const [roomsBathroomsData, setRoomsBathroomsData] = useState<any>(null);
  const [axis, setAxis] = useState<any>(null);
  const [statusInformation, setStatusInformation] = useState<any>(null);
  const nid = useSelector((state: any) => state.searchStore.search);
  const cardState = useSelector((state: any) => state.cardsStore.stateCards);
  const cardBathroomBedroom = cardState.find((card: any) => card.id === cardId);

  useEffect(() => {
    const response = cardBathroomBedroom.table;
    const { loading } = cardBathroomBedroom;

    if (response.approval_result.length > 0 && !loading) {
      setRoomsBathroomsData(response);

      const axisData = response?.approval_result[0].axis_x_y;
      setAxis(axisData);

      const status = response?.approval_result[0].approval_result;
      const index = response?.approval_result[0].percentage_by_column.index[0];
      const percentageData =
        response?.approval_result[0].percentage_by_column.data;
      const percentage =
        index === 1
          ? percentageData[axisData[1] - 1][axisData[0] - 1]
          : percentageData[axisData[1]][axisData[0]];
      let statusText = '';

      switch (status) {
        case 'approved':
          statusText = `OK - Se aprueba ya que el inmueble con nid ${nid} posee una tipología de baños y habitaciones presente en un ${Number(percentage) % 1 !== 0 ? Number(percentage).toFixed(1) : percentage}% de los inmuebles en el barrio con el mismo número de habitaciones (habitaciones: ${axisData[0]}, baños: ${axisData[1]})`;
          break;
        case 'warning':
          statusText = `REVISAR - Se debe validar ya que el inmueble con nid ${nid} posee una tipología de baños y habitaciones presente en un ${Number(percentage) % 1 !== 0 ? Number(percentage).toFixed(1) : percentage}% de todos los inmuebles de la zona mediana.`;
          break;
        case 'rejected':
          statusText = `RECHAZADO - Se rechaza ya que el inmueble con nid ${nid} posee una tipología de baños y habitaciones presente en un ${Number(percentage) % 1 !== 0 ? Number(percentage).toFixed(1) : percentage}% de los inmuebles en el barrio con el mismo número de habitaciones (habitaciones: ${axisData[0]}, baños: ${axisData[1]})`;
          break;
        default:
          statusText = '-';
          break;
      }

      setStatusInformation(statusText);
    } else {
      setStatusInformation('No se encontraron datos');
    }
  }, [nid, cardBathroomBedroom]);

  return (
    <>
      <ContainerComment>
        <div className="title">Comentario: </div>
        <div className="subtitle" data-testid="description">
          {statusInformation}
        </div>
      </ContainerComment>
      {(roomsBathroomsData && (
        <>
          <Subtitle>Cantidad de baños y habitaciones</Subtitle>
          <BidimensionalTable
            data={roomsBathroomsData.approval_result[0].count}
            totalCount={roomsBathroomsData.approval_result.length}
            keyMap="count"
            axis={axis}
          />
          <Subtitle>
            Porcentaje con respecto a la cantidad de inmuebles
          </Subtitle>
          <BidimensionalTable
            data={roomsBathroomsData.approval_result[0].percentage}
            totalCount={roomsBathroomsData.approval_result.length}
            keyMap="percentage"
            axis={axis}
          />
          <Subtitle>
            Porcentaje con respecto a la cantidad de inmuebles con el mismo número de habitaciones
          </Subtitle>
          <BidimensionalTable
            data={roomsBathroomsData.approval_result[0].percentage_by_column}
            totalCount={roomsBathroomsData.approval_result.length}
            keyMap="percentage_by_column"
            axis={axis}
          />
        </>
      )) || <HabiLoader size="small" />}
    </>
  );
};

export default DetailsRoomsBathrooms;
