import { useEffect, useState } from 'react';
import { SimpleTextField } from '@habitech/keychain-atoms-inputs';
import { InputContainer } from './style';
import { IRulesValues } from '../../../../model';

interface IInputRule {
  field: any;
  handleInputValue: (value: string, error: boolean, field: any) => void;
  rulesValues: IRulesValues[];
  selectedRule: any;
}

const InputRule = ({
  field,
  handleInputValue,
  rulesValues,
  selectedRule,
}: IInputRule) => {
  const [inputValue, setInputValue] = useState('');
  const [error, setError] = useState(false);
  const [searchChanges, setSearchChanges] = useState(false);
  const [isFirstNumber, setIsFirstNumber] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');

  const handleValidateMinMax = (
    min: number | undefined,
    max: number | undefined,
    errorMsg: (min: number | undefined, max?: number) => string
  ) => {
    if (
      (min !== undefined && +inputValue < min) ||
      (max !== undefined && +inputValue > max)
    ) {
      setError(true);
      setErrorMessage(errorMsg(min, max));
    } else {
      setError(false);
    }
  };

  const isDecimal = field.reg && field.format === 'decimal';

  const handleInputValueValidationDecimal = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { value } = e.target;
    const validateRegex = (regex: RegExp) => regex.test(value);
    const isValidNumberRegex = /^-?\d*\.?\d+$/;

    if (isValidNumberRegex) {
      if (validateRegex(/^\d*(\.\d?)?$/)) {
        setIsFirstNumber(false);
        setInputValue(value);
      } else if (isFirstNumber) {
        setInputValue('');
      }
    }
  };

  const handleInputValueValidationInteger = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { value } = e.target;
    const validateRegex = (regex: RegExp) => regex.test(value);
    const isValidNumberRegex = /^-?\d*\.?\d+$/;

    if (isValidNumberRegex) {
      if (validateRegex(/^\d{0,10}$|^$/)) {
        setIsFirstNumber(false);
        setInputValue(value);
      } else if (isFirstNumber) {
        setInputValue('');
      }
    }
  };

  useEffect(() => {
    if ((inputValue || typeof inputValue === 'string') && searchChanges) {
      handleInputValue(inputValue, error, field);
      handleValidateMinMax(
        field.minimum,
        field.maximum,
        (min, max) =>
          `${
            max !== undefined
              ? `El valor debe estar entre ${min} y ${max}`
              : ` El valor debe ser mayor a ${min}`
          }`
      );
    }
  }, [error, inputValue]);

  useEffect(() => {
    const tempValue = rulesValues.find((item) => item.id === selectedRule.id);

    if (tempValue) {
      const val: any = tempValue?.values.find(
        (item) => item.name === field.name
      );

      if (val) {
        setSearchChanges(true);
        setInputValue(val.value);
      }
    }
  }, []);

  return (
    <InputContainer>
      <SimpleTextField
        value={inputValue}
        name={field.name}
        label={field.label}
        onChange={
          isDecimal
            ? handleInputValueValidationDecimal
            : handleInputValueValidationInteger
        }
        placeholder={field.placeholder}
        error={error}
        errorMessage={errorMessage}
      />
    </InputContainer>
  );
};

export default InputRule;
