import { useLayoutEffect, useState } from 'react';
import { createPortal } from 'react-dom';

interface IReactPortalProps {
  children?: JSX.Element[] | JSX.Element;
  wrapperId: string;
}

const ReactPortal: React.FC<IReactPortalProps> = ({ children, wrapperId }) => {
  const [wrapperElement, setWrapperElement] = useState<HTMLElement>();

  const createWrapperAndAppendToBody = (wrapperId: string) => {
    const wrapperElement = document.createElement('div');
    wrapperElement.setAttribute('id', wrapperId);
    document.body.appendChild(wrapperElement);
    return wrapperElement;
  };

  useLayoutEffect(() => {
    let element = document.getElementById(wrapperId) as HTMLElement;
    let systemCreated: boolean = false;

    if (!element) {
      systemCreated = true;

      element = createWrapperAndAppendToBody(wrapperId);
    }
    setWrapperElement(element);
    return () => {
      // delete the programatically created element
      if (systemCreated && element.parentNode) {
        element.parentNode.removeChild(element);
      }
    };
  }, [wrapperId]);

  if (!wrapperElement) {
    return null;
  } else {
    return createPortal(children, wrapperElement);
  }
};
export default ReactPortal;
