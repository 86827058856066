import { robotoRegular, white, electricPurple800 } from '@habitech/shared';
import styled from 'styled-components';
import { borderGrayResume, scarpaGray100 } from '../../styles/stylesConstants';

const FunnelConvertionWrapper = styled.div`
  width: 100%;
`;

export const ReturnWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(10, 1fr);
`;

export const ReturnContent = styled.div`
  grid-column: 1 / 6;
  display: flex;
  cursor: pointer;
`;

export const DateInformation = styled.div`
  width: 100%;
  font-family: ${robotoRegular};
  font-weight: 600;
  font-size: 16px;
  display: flex;
  margin-top: 21px;
  color: ${electricPurple800};
  img {
    margin-right: 10px;
  }
`;

export const Separator = styled.div`
  width: 100%;
  height: 1px;
  border-top: 1px solid ${borderGrayResume};
  margin: 12px 0;
`;

export const FunnelGraphsWrapper = styled.div`
  width: 100%;
  display: flex;
`;

export const VerticalSeparator = styled.div`
  position: relative;
  width: 1px;
  height: 100%;
  border-left: 1px solid ${borderGrayResume};
`;

export const VsContainer = styled.div`
  position: absolute;
  right: -25px;
  top: 20px;
  width: 50px;
  height: 40px;
  border-radius: 8px;
  border: 1px solid ${scarpaGray100};
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  font-size: 16px;
  background: ${white};
`;

export default FunnelConvertionWrapper;
