import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';

import { TitleGraphic } from '../ShoppingDistribution/style';

import {
  ItemLegend,
  ItemText,
  LegendColor,
  WrapperGraphic,
  WrapperLegend,
} from './styles';

const Graphic: React.FC<{
  statistics: {
    id: number;
    label: string;
    month_category: string;
    basic_percent: number;
    bone_percent: number;
    prime_percent: number;
    subprime_percent: number;
    regular_percent: number;
    basic_count: number;
    bone_count: number;
    prime_count: number;
    subprime_count: number;
    regular_count: number;
    colors: string[];
  };
}> = ({ statistics }) => {
  ChartJS.register(ArcElement, Tooltip, Legend);
  const hoverLabel = [' Basic', ' Bone', ' Prime', ' Regular', ' Sub - prime'];
  const dataCounts = [
    statistics.basic_count,
    statistics.bone_count,
    statistics.prime_count,
    statistics.regular_count,
    statistics.subprime_count,
  ];
  const data = {
    labels: ['[Basic]', '[Bone]', '[Prime]', '[Regular]', '[Sub - prime]'],
    datasets: [
      {
        data: [
          statistics.basic_percent,
          statistics.bone_percent,
          statistics.prime_percent,
          statistics.regular_percent,
          statistics.subprime_percent,
        ],
        backgroundColor: statistics.colors,
        borderColor: statistics.colors,
        borderWidth: 1,
        datalabels: {
          display: false,
        },
      },
    ],
  };
  const options = {
    responsive: false,
    tooltips: {
      titleFontStyle: 'bold',
    },
    plugins: {
      legend: {
        display: false,
        maxWidth: 206,
        maxHeight: 206,
        position: 'left',
        labels: {
          font: {
            size: 12,
            family: 'Open Sans',
          },
        },
      },
      tooltip: {
        titleFont: { weight: 'bold' },
        callbacks: {
          label: (context: any) =>
            `${context.label} - ${context.parsed}% (${
              dataCounts[context.dataIndex]
            })`,
        },
      },
    },
  };

  return (
    <WrapperGraphic>
      <WrapperLegend>
        <TitleGraphic>
          Porcentaje de compras
          <br />
          según revenue
        </TitleGraphic>
        {data.datasets[0].backgroundColor.map((color: any, p: any) => {
          return (
            <ItemLegend key={color}>
              <LegendColor color={color} />
              <ItemText>
                <div className="title">{hoverLabel[p]}</div>
                <div className="value">{data.datasets[0].data[p]}%</div>
              </ItemText>
            </ItemLegend>
          );
        })}
      </WrapperLegend>

      <div className="chart">
        <Pie data={data} options={options as any} width="200" height="200" />
      </div>
    </WrapperGraphic>
  );
};

export default Graphic;
