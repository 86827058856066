import styled from 'styled-components';
import {
  electricPurple600,
  goldenYellow500,
  lightBlue000,
  nightBlue500,
  redWarning,
  scarpaGray100,
  scarpaGray400,
  scarpaGray800,
  separator,
  white,
} from '../../../../styles/stylesConstants';

export const WrapperModalDiscount = styled.div`
  background: ${nightBlue500}80;
  backdrop-filter: blur(10px);
  width: 100vw;
  height: 100vh;
  z-index: 200;
  top: 0;
  left: 0;
  position: fixed;
`;

export const WrapperContent = styled.div`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: 24px;
  height: 100vh;
`;

export const Modal = styled.div`
  background: ${white};
  grid-column: 4/10;
  display: grid;
  position: relative;
  grid-template-columns: repeat(6, 1fr);
  gap: 24px;
  box-shadow: 0px 0px 1px rgba(48, 49, 51, 0.05),
    0px 16px 24px rgba(48, 49, 51, 0.1);
  border-radius: 16px;
  margin: auto 0;
`;
export const ModalContent = styled.div`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-column: 2/6;
  padding: 60px 0 30px 0;
  .icon {
    width: 29px;
    height: 29px;
    position: absolute;
    right: 22px;
    top: 28px;
    &:hover {
      cursor: pointer;
    }
  }
  .container-buttons {
    display: grid;
    grid-template-columns: auto 1fr;
    gap: 24px;
    button {
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 40px;
      white-space: nowrap;
      color: ${white};
    }
    .stroke {
      color: ${electricPurple600};
    }
  }
`;
export const TitleSection = styled.div`
  grid-column: 1/7;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;s
  color: ${scarpaGray800};
  display: flex;
  gap: 8px;
  margin-bottom: 20px;
`;

export const WrapperActualDiscount = styled.div`
  grid-column: 1/7;
  padding-top: 16px;
  border-top: 1px solid ${scarpaGray100};
`;
export const WrapperNewDiscount = styled.div`
  grid-column: 1/7;
  margin-bottom: 20px;
  border-bottom: 1px solid ${scarpaGray100};
`;

export const Subtitle = styled.div`
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  color: ${scarpaGray800};
  margin-bottom: 15px;
`;

export const WrapperItems = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 0 16px 26px 16px;
  gap: 18px;
`;

export const Item = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 28px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: ${scarpaGray800};
  b {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 20px;
    color: ${electricPurple600};
    margin-bottom: 3px;
  }
  .text {
    display: grid;
  }
`;
export const InputIcon = styled.div`
  border: 1px solid ${scarpaGray400};
  border-radius: 8px;
  display: flex;
  margin-bottom: 29px;
  height: 40px;
  img {
    width: 24px;
    height: 24px;
    display: flex;
    margin: auto 11px;
  }
  input {
    width: 100%;
    border-radius: 8px;
    box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0),
      inset 0 0 0 100px rgba(255, 255, 255, 1);
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 40px;
    color: ${scarpaGray800};
    border: none;
    color: ${scarpaGray400};
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }
  input:focus {
    outline: none;
  }
  .close {
    color: ${separator};
    font-size: 20px;
    margin: auto;
    margin-right: 10px;
  }
`;

export const WrapperInputs = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 24px;
`;

export const WarningCard = styled.div`
  grid-column: 1/7;
  display: grid;
  grid-template-columns: auto 1fr;
`;

export const IconWarning = styled.div`
  justify-content: center;
  align-items: center;
  padding: 4px;
  height: 75px;
  background: ${goldenYellow500};
  border-radius: 8px 0 0 8px;
  display: grid;
`;

export const IconError = styled.div`
  justify-content: center;
  align-items: center;
  padding: 4px;
  height: 75px;
  background: ${redWarning};
  border-radius: 8px 0 0 8px;
  display: grid;
`;

export const TextWarning = styled.div`
  background: ${lightBlue000};
  padding: 8px 16px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: ${scarpaGray800};
  display: grid;
  align-items: center;
  border-radius: 0 8px 8px 0;
  margin-bottom: 18px;
  height: 75px;
`;
