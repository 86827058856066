import tableLamp from '../../../../assets/images/tableLamp.svg';
import ContainerNotFound from './styles';

const NotFound = () => {
  return (
    <ContainerNotFound>
      <img src={tableLamp} alt="img table" className="container-image" />
      <div className="container-text">
        <div className="title">¡Lo sentimos!</div>
        <div className="subtitle">
          Nos perdimos en el camino. No hemos encontrado Información del NID que
          buscas.
        </div>
      </div>
    </ContainerNotFound>
  );
};

export default NotFound;
