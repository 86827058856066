import * as jose from 'jose';
import { type TCredentialData, type TGoogleResponse } from './model';

import GoogleButton from '../../../templates/GoogleButton/GoogleButton';
import { authUser } from '../../../apis/auth/auth.api';

export interface IUserGoogle {
  // roles: string[];
  email: string;
  name: string;
  lastName: string;
  imageProfile: string;
  exp: number;
  iat: number;
}
export interface Itoken {
  AccessToken?: any;
  IdToken?: any;
  token: string;
  country: string;
  RefreshToken: string;
  role: string;
  message: string;
  success: boolean;
  userName: string;
  ExpiresIn: number;
}

interface LoginGoogleProps {
  onNextStep: (user: IUserGoogle) => void;
  onError: (error: unknown) => void;
  setIsLogin?: (ban: boolean) => void;
  setError?: (error: string) => void;
  // eslint-disable-next-line react/no-unused-prop-types
  country: string;
}

const LoginGoogle = ({
  onNextStep,
  onError,
  setIsLogin,
  setError,
  country,
}: LoginGoogleProps) => {
  const onSuccessLogin = async (googleResponse: TGoogleResponse) => {
    if (setIsLogin) {
      setIsLogin(true);
    }

    if (!('credential' in googleResponse)) {
      onError('user offline');
      return;
    }

    const data: TCredentialData = jose.decodeJwt(googleResponse.credential);

    try {
      const temporal = await authUser(googleResponse.credential, country);
      localStorage.setItem('ROL', temporal.data.Groups);
      const { data: dataToken } = await authUser(
        googleResponse.credential,
        country
      );

      if (dataToken) {
        localStorage.setItem('TOKEN', JSON.stringify(dataToken));
      }

      if (data) {
        onNextStep({
          email: data.email as string,
          name: data.given_name as string,
          lastName: data.family_name as string,
          imageProfile: data.picture as string,
          exp: data.exp as number,
          iat: data.iat as number,
        });
      } else {
        onError('user no permission');
      }
    } catch (error) {
      if (setIsLogin) {
        setIsLogin(false);
      }

      if (setError) {
        setError('Ocurrió un error o no estás asignado al pool de usuarios');
      }
    }
  };

  return (
    <div>
      <GoogleButton onGoogleSignIn={onSuccessLogin} />
    </div>
  );
};

export default LoginGoogle;
