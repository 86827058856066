import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { ContainerComment, Subtitle } from '../DetailsRoomsBathrooms/styles';
import OneDimensionTable from '../OneDimensionTable/OneDimensionTable';
import HabiLoader from '../../../../../../components/HabiLoader/HabiLoader';
import getStatusText from './DetailGarage.utils';

const DetailGarageTypology = () => {
  const cardId = 7;
  const nid = useSelector((state: any) => state.searchStore.search);
  const [garageData, setGarageData] = useState<any>(null);
  const [statusInformation, setStatusInformation] = useState<any>(null);
  const cardState = useSelector((state: any) => state.cardsStore.stateCards);
  const cardGarages = cardState.find((card: any) => card.id === cardId);
  const [numGarages, setNumGarages] = useState(0);

  useEffect(() => {
    const response = cardGarages.table;
    const { loading } = cardGarages;

    if (response && response.approval_result.table_data && loading === false) {
      setGarageData(response);

      const status = response?.approval_result.approval_result;
      const garages = response?.approval_result.garage;
      const type = response?.approval_result.property_type;
      const medianZone = response?.approval_result.median_zone;
      const percentageObject = response.approval_result.table_data.find(
        (item: any) => item.num_garages === garages
      );
      let percentage = 0;

      if (percentageObject) {
        percentage = percentageObject.total_percentage;
      }

      const statusText = getStatusText(
        status,
        nid,
        percentage,
        garages,
        type,
        medianZone
      );

      setStatusInformation(statusText);
      setNumGarages(garages);
    } else {
      setStatusInformation('No se encontraron resultados');
    }
  }, [nid, cardGarages]);

  return (
    <>
      <ContainerComment>
        <div className="title">Comentario: </div>
        <div className="subtitle" data-testid="description">
          {statusInformation}
        </div>
      </ContainerComment>
      {(garageData && (
        <>
          <Subtitle>Cantidad de garajes</Subtitle>
          <OneDimensionTable
            data={garageData.approval_result.table_data}
            pivot="total_count"
            countPivot="num_garages"
            totalRows={1}
            pivotValue={numGarages}
          />
          <Subtitle>Porcentaje de garajes</Subtitle>
          <OneDimensionTable
            data={garageData.approval_result.table_data}
            pivot="total_percentage"
            countPivot="num_garages"
            totalRows={1}
            pivotValue={numGarages}
          />
        </>
      )) || <HabiLoader size="small" />}
    </>
  );
};

export default DetailGarageTypology;
