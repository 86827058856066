// Libraries
import { FC } from 'react';
import { Outlet } from 'react-router-dom';
import AlertToast from '../../pages/Pricing/components/AlertToast/AlertToast';

// Components
import LayoutHeader from '../LayoutHeader';
import LayoutSidebar from '../LayoutSidebar';
import { alertStoreActions } from '../../redux/reducers/alertReducer';

// Styles
import { LayoutWrapper, ContainerPage } from './style';

// Redux
import { useAppSelector, useAppDispatch } from '../../redux/hooks';

const Layout: FC = () => {
  const alertState = useAppSelector((state) => state.alertStore);
  const dispatch = useAppDispatch();
  const { hideAlert } = alertStoreActions;

  const handleAlert = () => {
    dispatch(hideAlert());
  };

  return (
    <LayoutWrapper>
      <LayoutSidebar />
      <ContainerPage className="ksad">
        <LayoutHeader />
        <div className="content-wrapper container-habi">
          <AlertToast
            type={alertState.type}
            text={alertState.text}
            showAlert={alertState.showAlert}
            setShowAlert={handleAlert}
          />
          <Outlet />
        </div>
      </ContainerPage>
    </LayoutWrapper>
  );
};

export default Layout;
