/**
 * It takes two colors and a fade value, and returns a color that is a mix of the two colors based on
 * the fade value
 * @param {string} rawColor1 - The first color to fade from.
 * @param {string} rawColor2 - The color you want to fade to.
 * @param {number} fade - Number from 0 to 1 to define how faded will color 1 be respect color 2
 * @returns A string that is a hex color code.
 */
const generateColor = (rawColor1: string, rawColor2: string, fade: number) => {
  const color1 = [
    parseInt(rawColor1.substring(0, 2), 16),
    parseInt(rawColor1.substring(2, 4), 16),
    parseInt(rawColor1.substring(4, 6), 16),
  ];
  const color2 = [
    parseInt(rawColor2.substring(0, 2), 16),
    parseInt(rawColor2.substring(2, 4), 16),
    parseInt(rawColor2.substring(4, 6), 16),
  ];

  const diffs = [
    color1[0] - color2[0],
    color1[1] - color2[1],
    color1[2] - color2[2],
  ];

  const result = [
    '#',
    (color1[0] - Math.round(diffs[0] * fade)).toString(16),
    (color1[1] - Math.round(diffs[1] * fade)).toString(16),
    (color1[2] - Math.round(diffs[2] * fade)).toString(16),
  ];

  return result.join('');
};

export default generateColor;
