import { FC, ReactNode } from 'react';

import ButtonContainer from './styled';

interface InteractiveButtonProps {
  onClick: () => void;
  children: ReactNode;
}

const InteractiveButton: FC<InteractiveButtonProps> = ({
  onClick,
  children,
}) => {
  return <ButtonContainer onClick={onClick}>{children}</ButtonContainer>;
};

export default InteractiveButton;
