import {
  blueLinks,
  electricPurple500,
  electricPurple600,
  lightBlue,
  scarpaGray800,
  white,
} from '@habitech/shared';
import styled from 'styled-components';
import { borderSelect, gray6 } from '../../../../styles/stylesConstants';

export const WrapperSearchZone = styled.div`
  display: grid;
`;

export const Note = styled.div`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 18px;
  color: ${scarpaGray800};
  margin-bottom: 11px;
`;

export const SearchArea = styled.div`
  display: grid;
  position: relative;

  .MuiInputBase-root {
    border-radius: 6px;
    height: 40px;
    padding-top: 1px !important;
  }
  .MuiAutocomplete-root {
    height: 40px;
  }
  .MuiAutocomplete-input {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 16px;
    color: ${scarpaGray800};

    &::placeholder {
      color: ${borderSelect};
    }
  }

  .li-element {
    background: green;
  }
  .MuiInputBase-root > .Mui-focused {
    border-width: 2px 0px 2px 2px !important;
    border-style: solid;
    border-color: ${blueLinks};
  }
  .MuiAutocomplete-clearIndicator {
    margin-right: 40px;
    margin-top: 4px;
  }
`;

export const AddOn = styled.div`
  display: grid;
  position: absolute;
  right: 0;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  background: ${electricPurple600};
  border-radius: 0px 6px 6px 0px;
  font-size: 20px;
  .icon {
    font-size: 24px;
    color: ${white};
  }
  &:hover {
    cursor: pointer;
  }
`;

export const LiElement = styled.li`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 19px;
  color: ${scarpaGray800};
  padding-top: 8px;
  padding-bottom: 8px;
  text-transform: capitalize;
  &:hover,
  &:focus {
    border-left: 2px solid ${electricPurple500};
    background-color: ${lightBlue} !important;
  }
`;

export const NoteExample = styled.div`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 18px;
  color: ${gray6};
  margin-bottom: 16px;
`;
