import { useEffect, useState } from 'react';
import SelectArea from '../SelectArea/SelectArea';
import { saveDiscountMx } from '../../../../apis/selectZoneMx/selectZone';
import {
  ContainerZoneValidationMx,
  Divisor,
  ContainerSendButton,
} from '../../styles';
import SelectDecisionMx from '../SelectDecisionMx/SelectDecisionMx';
import SelectTypeZoneMx from '../SelectTypeZoneMx/SelectTypeZoneMx';
import { IOption } from '../SelectArea/interfaces';
import {
  MAX_DISCOUNT,
  MIN_DISCOUNT,
} from '../SelectDecisionMx/selectDecisionMx.utils';
import ModalConfirmation from '../../../../components/ModalConfirmation';
import { TModalInformation } from '../../../RulesEditor/model';
import getDataZoneType from '../../../../utils/getDataZonType';

const CardContainerForm = () => {
  const [tabSelected, setTabSelected] = useState(0);
  const [loading, setLoading] = useState(false);
  const [selectedPlaces, setSelectedPlaces] = useState<IOption[]>([]);
  const [selectData, setSelectData] = useState({
    data: [],
    message: '',
    success: false,
  });
  const [discountApartment, setDiscountApartment] = useState('0');
  const [discountSingleHouse, setDiscountSingleHouse] = useState('0');
  const [discountCondominiumHouse, setDiscountCondominiumHouse] = useState('0');
  const [discountApartmentTotal, setDiscountApartmentTotal] = useState('');
  const [discountSingleHouseTotal, setDiscountSingleHouseTotal] = useState('');
  const [discountCondominiumHouseTotal, setDiscountCondominiumHouseTotal] = useState('');
  const [rangeFrom, setRangeFrom] = useState('0');
  const [rangeTo, setRangeTo] = useState('0');
  const [typeDiscount, setTypeDiscount] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [isloan, setIsLoan] = useState(true);
  const [modalInfo, setModalInfo] = useState<TModalInformation>({
    title: '',
    subtitle: '',
    buttonOk: '',
    buttonCancel: '',
    action: () => {},
    image: '',
    showButtons: true,
  });

  const validateTypeDiscount = () => {
    if (typeDiscount) {
      if (
        +discountApartment !== 0 ||
        +discountSingleHouse !== 0 ||
        +discountCondominiumHouse !== 0
      ) {
        return true;
      }
    } else {
      let isValid1 = false;
      let isValid2 = false;
      let isValid3 = false;

      if (+discountApartmentTotal !== 0) {
        if (
          +discountApartmentTotal >= MIN_DISCOUNT && +discountApartmentTotal <= MAX_DISCOUNT
        ) {
          isValid1 = true;
        }
      } else {
        isValid1 = true;
      }

      if (+discountSingleHouseTotal !== 0) {
        if (
          +discountSingleHouseTotal >= MIN_DISCOUNT && +discountSingleHouseTotal <= MAX_DISCOUNT
        ) {
          isValid2 = true;
        }
      } else {
        isValid2 = true;
      }

      if (+discountCondominiumHouseTotal !== 0) {
        if (
          +discountCondominiumHouseTotal >= MIN_DISCOUNT && +discountCondominiumHouseTotal <= MAX_DISCOUNT
        ) {
          isValid3 = true;
        }
      } else {
        isValid3 = true;
      }

      if (
        discountApartmentTotal === '' &&
        discountSingleHouseTotal === '' &&
        discountCondominiumHouseTotal === ''
      ) {
        return false;
      }

      if (isValid1 && isValid2 && isValid3) {
        return true;
      }

      return false;

    }
  };

  const buttonDisable =
    tabSelected !== 0 &&
    selectedPlaces.length > 0 &&
    (rangeFrom.length >= 1 &&
    rangeFrom.length <= 7 &&
    rangeTo.length >= 1 &&
    rangeTo.length <= 7 &&
    +rangeFrom < +rangeTo) &&
    validateTypeDiscount();

  const handleConfirmationClick = () => {
    const filterBy: {
      [key: number]: string;
    } = {
      1: 'median_zone',
      2: 'big_zone',
      3: 'city',
      4: 'metropolitan_area',
    };

    const body = {
      filter_by: filterBy[tabSelected],
      discount_type: isloan ? 'promesa': 'compraventa',
      type: typeDiscount ? 'add_less' : 'change_all',
      data: {
        apartment: typeDiscount ? +discountApartment : +discountApartmentTotal,
        house: typeDiscount
          ? +discountCondominiumHouse
          : +discountCondominiumHouseTotal,
        single_house: typeDiscount
          ? +discountSingleHouse
          : +discountSingleHouseTotal,
        range_price: {
          floor: +rangeFrom,
          ceil: +rangeTo,
        },
        range_ids: selectedPlaces.map((place) => {
          return place.id;
        }),
      },
    };

    setModalInfo({
      title: 'Estamos despegando',
      title2: 'El cambio puede tomar un tiempo',
      subtitle:
        'En cualquier momento verás la notificación de que el cambio fue realizado o ,sí por el contrario, ocurrió un error.',
      buttonOk: '',
      buttonCancel: '',
      action: () => {},
      // eslint-disable-next-line global-require
      image: require('../../../../assets/images/ship.gif'),
      showButtons: false,
    });

    saveDiscountMx(body).then((response) => {
        if (response.success) {
          setModalInfo({
            title: 'Descuento editado',
            title2: 'Has editado los descuentos actuales',
            subtitle:
              'Puedes regresar a la zona y revisar que los cambios se hayan aplicado.',
            buttonOk: 'Aceptar',
            buttonCancel: 'Cancelar',
            action: () => {
              window.location.reload();
            },
            // eslint-disable-next-line global-require
            image: require('../../../../assets/images/office-working2.svg'),
            showButtons: true,
          });
        }
      })
      .catch((error) => {
        setModalInfo({
          title: 'Cambio no aplicado',
          title2: 'No se pudieron editar los descuentos',
          subtitle:
            'Si identificas que es un error del portal, repórtalo al canal de #inconsistencias-dudas-vendedores.',
          buttonOk: 'Volver a intentarlo',
          buttonCancel: 'Cancelar',
          action: handleConfirmationClick,
          // eslint-disable-next-line global-require
          image: require('../../../../assets/images/office-working3.svg'),
          showButtons: true,
        });
      });
  };

  const handleSaveClick = () => {
    setModalInfo({
      title: '¿Estás seguro de editar estos descuentos?',
      subtitle: 'El editar los descuentos afectará a todos los inmuebles.',
      buttonOk: 'Sí, cambiar',
      buttonCancel: 'No, cancelar',
      action: handleConfirmationClick,
      // eslint-disable-next-line global-require
      image: require('../../../../assets/images/office-working.svg'),
      showButtons: true,
    });

    setShowModal(true);
  };


  useEffect(() => {
    getDataZoneType({ setLoading, tabSelected, setSelectData });
  }, [tabSelected]);

  return (
    <ContainerZoneValidationMx>
      <SelectTypeZoneMx
        tabSelected={tabSelected}
        setTabSelected={setTabSelected}
      />
      <Divisor />
      <SelectArea
        selectedData={selectData}
        loading={loading}
        selectedPlaces={selectedPlaces}
        setSelectedPlaces={setSelectedPlaces}
      />
      {selectedPlaces.length > 0 && (
        <>
          <Divisor />
          <SelectDecisionMx
            discountApartment={discountApartment}
            setDiscountApartment={setDiscountApartment}
            discountSingleHouse={discountSingleHouse}
            setDiscountSingleHouse={setDiscountSingleHouse}
            discountCondominiumHouse={discountCondominiumHouse}
            setDiscountCondominiumHouse={setDiscountCondominiumHouse}
            discountApartmentTotal={discountApartmentTotal}
            setDiscountApartmentTotal={setDiscountApartmentTotal}
            discountSingleHouseTotal={discountSingleHouseTotal}
            setDiscountSingleHouseTotal={setDiscountSingleHouseTotal}
            discountCondominiumHouseTotal={discountCondominiumHouseTotal}
            setDiscountCondominiumHouseTotal={setDiscountCondominiumHouseTotal}
            rangeFrom={rangeFrom}
            setRangeFrom={setRangeFrom}
            rangeTo={rangeTo}
            setRangeTo={setRangeTo}
            typeDiscount={typeDiscount}
            setTypeDiscount={setTypeDiscount}
            isloan={isloan}
            setIsLoan={setIsLoan}
          />
          <ContainerSendButton>
            <button onClick={handleSaveClick} disabled={!buttonDisable}>
              <span className="text">Guardar cambios en zonas</span>
            </button>
          </ContainerSendButton>
        </>
      )}

      <ModalConfirmation
        showModal={showModal}
        setShowModal={setShowModal}
        modalInfo={modalInfo}
        id={1}
        showButtons={modalInfo.showButtons}
      />
    </ContainerZoneValidationMx>
  );
};

export default CardContainerForm;
