import styled from 'styled-components';
import { robotoRegular, scarpaGray800 } from '@habitech/shared';

const ColumnDescription = styled.div`
  grid-column: 1/3;
  .size {
    width: 130px;
  }
  .subtitle {
    font-family: ${robotoRegular};
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: ${scarpaGray800};
  }
`;

export default ColumnDescription;
