import { useEffect, useState } from 'react';
import { getZoneRules } from '../../../../apis/selectZone/areas';
import { useAppSelector } from '../../../../redux/hooks';
import HabiLoader from '../../../../components/HabiLoader/HabiLoader';
import homes from '../../../../assets/icons/homes.svg';
import officeWorking from '../../../../assets/images/office-working2.svg';
import officeWorking2 from '../../../../assets/images/office-working3.svg';
import AppliedRules from '../AppliedRules/AppliedRules';
import EditGeneralRules from '../EditGeneralRules/EditGeneralRules';
import ModalEditRules from '../ModalEditRules/ModalEditRules';
import { TitleZone, Separator, SeparatorWrapper, GridComplete } from './styles';
import EmptyState from '../EmptyState/EmptyState';
import { TModalInformation } from '../../../RulesEditor/model';
import ModalConfirmation from '../../../../components/ModalConfirmation';

const CardZoneBusinessRuler = () => {
  const [showEdit, setShowEdit] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [firstSelected, setFirstSelected] = useState<boolean>(true);
  const zoneState = useAppSelector((state) => state.zoneStore);
  const [data, setData] = useState({
    name: '',
    status: '',
    vis: {
      max_price: 0,
      max_antiquity: 0,
    },
    no_vis: {
      min_price: 0,
      max_price: 0,
      max_antiquity: 0,
    },
    buckets: [],
    single_house: false,
    ask_price_top: 0,
  });
  const [dataToModal, setDataToModal] = useState({});
  const [noData, setNoData] = useState<boolean>(false);
  const [showModalConfirmation, setShowModalConfirmation] = useState(false);
  const [modalInfo, setModalInfo] = useState<TModalInformation>({
    title: '',
    title2: '',
    subtitle: '',
    buttonOk: '',
    buttonCancel: '',
    action: () => {},
    image: '',
  });

  const handleGetData = async () => {
    setLoading(true);

    try {
      const response: any = await getZoneRules({
        zoneId: zoneState.zone.middleZone.id,
      });
      setLoading(false);
      setData(response?.data);
    } catch (error) {
      console.error(error);
      setLoading(false);
      setNoData(true);
    }
  };

  const handelCloseModal = () => {
    setIsModalOpen(false);
  };

  const handelModalChangeInfoclick = (confirm: boolean) => {
    const info = modalInfo;

    if (confirm) {
      info.title = 'Cambio realizado';
      info.title2 = 'Has editado las reglas de negocio en la zona';
      info.subtitle =
        'Puedes regresar a la zona y revisar que los cambios se han aplicado.';
      info.buttonOk = 'Ver reglas';
      info.buttonCancel = 'Cancelar';
      info.image = officeWorking;

      info.action = () => {
        setShowModalConfirmation(false);
      };
    } else {
      info.title = 'Cambio no aplicado';
      info.title2 = 'No se pudieron editar las reglas en esta zona';
      info.subtitle =
        'Sí identificas que es un error del portal, repórtalo al equipo de Spacestation.';
      info.buttonOk = 'Volver a intentarlo';
      info.buttonCancel = 'Cancelar';
      info.image = officeWorking2;

      info.action = () => {
        setShowModalConfirmation(false);
      };
    }

    setModalInfo(info);
    setShowModalConfirmation(true);
  };

  useEffect(() => {
    handleGetData();
  }, []);

  return (
    <>
      <ModalEditRules
        isModalOpen={isModalOpen}
        data={dataToModal}
        zoneId={zoneState.zone.middleZone.id}
        setIsModalOpen={handelCloseModal}
        handelModalChangeInfoclick={handelModalChangeInfoclick}
        handleGetData={handleGetData}
      />
      <ModalConfirmation
        showModal={showModalConfirmation}
        setShowModal={setShowModalConfirmation}
        modalInfo={modalInfo}
        id={0}
      />
      <TitleZone>
        <img src={homes} alt="area_img" className="image" />
        <div className="title">
          {data?.name || zoneState.zone.middleZone.label}
        </div>
      </TitleZone>
      <SeparatorWrapper>
        <Separator />
      </SeparatorWrapper>
      {loading ? (
        <GridComplete>
          <HabiLoader size="small" />
        </GridComplete>
      ) : (
        !noData && (
          <AppliedRules
            showEdit={showEdit}
            setShowEdit={setShowEdit}
            data={data}
          />
        )
      )}

      {noData && (
        <GridComplete>
          <EmptyState />
        </GridComplete>
      )}

      {showEdit && (
        <>
          <SeparatorWrapper>
            <Separator />
          </SeparatorWrapper>
          <EditGeneralRules
            data={data}
            firstSelected={firstSelected}
            setFirstSelected={setFirstSelected}
            setIsModalOpen={setIsModalOpen}
            setDataToModal={setDataToModal}
          />
        </>
      )}
    </>
  );
};

export default CardZoneBusinessRuler;
