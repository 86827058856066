import { useContext } from 'react';
import { CardSmallContainer, CustomButton } from './styles';
import negative from '../../../../assets/icons/negative.svg';
import positive from '../../../../assets/icons/positive.svg';
import warning from '../../../../assets/icons/warning.svg';
import cornerPurple from '../../../../assets/icons/cornerPurple.svg';
import { useDispatch } from 'react-redux';
import {
  updateIsRules,
  updateShowDetail,
} from '../../../../redux/reducers/searchSlice';
import { CardContext } from '../../Approver';

const CardSmall = ({ data, approved, edited, showDetail = false, id }: any) => {
  const dispatch = useDispatch();
  const context: any = useContext(CardContext);
  const { handleEditCard, approval } = context;
  const iconMap: Record<string, JSX.Element> = {
    negative: (
      <img
        src={negative}
        alt="negative"
        data-testid="negative"
        width={24}
        height={24}
      />
    ),
    positive: (
      <img
        src={positive}
        alt="positive"
        data-testid="positive"
        width={24}
        height={24}
      />
    ),
    warning: (
      <img
        src={warning}
        alt="warning"
        data-testid="warning"
        width={24}
        height={24}
      />
    ),
  };

  const role = localStorage.getItem('ROL');

  const handleReturnIcon = (iconType: string) => {
    return iconMap[iconType] || null;
  };

  const handleShowDetail = (title: string = '') => {
    const tempText = title && title.toLocaleLowerCase().replace(/\s/g, '');
    let detail: string = '';
    let scroll = 1;

    switch (tempText) {
      case 'zonabuena':
        detail = 'goodZone';
        scroll = 1.1;
        break;
      case 'tipologíahabitacionesybaños':
        detail = 'roomsBathrooms';
        scroll = 1.2;
        break;
      case 'tipologíasdeelevadores':
        detail = 'elevator';
        scroll = 1.4;
        break;
      case 'tipologíasdeestacionamiento':
        detail = 'garage';
        scroll = 1.8;
        break;

      default:
        detail = '-';
    }

    dispatch(updateIsRules(false));
    dispatch(
      updateShowDetail({
        itemName: detail,
        itemValue: true,
      })
    );
    scrollToHalfPage(scroll);
  };

  const scrollToHalfPage = (scroll: number) => {
    const halfPagePosition = window.innerHeight * scroll;
    window.scrollTo({
      top: halfPagePosition,
      behavior: 'smooth',
    });
  };

  const handleReturnDetails = (title: string, values: any) => {
    const tempText = title && title.toLocaleLowerCase().replace(/\s/g, '');

    switch (tempText) {
      case 'fotos':
      case 'zonabuena':
        return <div />;
      case 'reportehighlevel':
        return (
          <div className="container-description">
            <div>
              <div className="text-one">{values?.text_one}</div>
              <div className="text-two">{values?.text_two}</div>
            </div>
            <div className="number">{values?.number}</div>
          </div>
        );
      case 'administración':
      case 'pricingacordeazona':
      case 'topedeprecioenlazona':
        return (
          <div className="container-description">
            <div>
              <div className="text-one">{values?.text_one}</div>
              <div className="text-two">{values?.text_two}</div>
            </div>
            <div>
              <div className="value-one">{values?.value_one}</div>
              <div className="value-two">{values?.value_two}</div>
            </div>
          </div>
        );
      case 'riesgo/amenazas':
      case 'alertapreciomanualsuperioraaskprice':
        return (
          <div className="container-description">
            <div>
              <div className="text-one-double">{values?.text_one}</div>
              <div className="value-one-double">{values?.value_one}</div>
            </div>
            <div>
              <div className="value-end">
                <div className="text-two-double ">{values?.text_two}</div>
              </div>
              <div className="value-end">
                <div className="value-two-double ">{values?.value_two}</div>
              </div>
            </div>
          </div>
        );
      case 'tipologíasdeestacionamiento':
      case 'tipologíasdeelevadores':
      case 'tipologíadeárea':
        return (
          <div className="container-description">
            <div className="single">
              <div>
                <div className="text-two">{values?.text_one}</div>
              </div>
              <div>
                <div className="number">{values?.value_one}</div>
              </div>
            </div>
          </div>
        );
      case 'comentariosremodelación':
        return (
          <div className="container-description">
            <div>
              <div className="text-two">{values?.text_one}</div>
              <div className="text-one">{values?.text_two}</div>
            </div>
          </div>
        );
      case 'blacklist':
        return (
          <div className="container-description">
            <div>
              <div className="text-one">{values?.text_one}</div>
              <div className="text-two">{values?.text_two}</div>
            </div>
          </div>
        );
      case 'pisoyascensores':
        return (
          <div className="container-description">
            <div>
              <div className="text-one">{values?.text_one}</div>
              <div className="text-two">
                {values?.text_two} <span className="separator-small">|</span>
                <span>{values?.text_three}</span>
              </div>
            </div>
          </div>
        );
      case 'tipologíahabitacionesybaños':
        return (
          <div className="container-description">
            <div className="double">
              <div className="number-text">
                <div className="number">{values.value_one}</div>
                <div className="text">{values.text_one}</div>
              </div>
              <div className="separator" />
              <div className="number-text">
                <div className="number">{values.value_two}</div>
                <div className="text">{values.text_two}</div>
              </div>
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <CardSmallContainer
      data-testid="small"
      className={`
        ${approved === 'negative' && 'negative'}
        ${approved === 'warning' && 'warning'}
      `}
    >
      {edited && (
        <img src={cornerPurple} alt="cornerPurple" className="corner" />
      )}
      <div className="container-content">
        <div className="container-title">
          <div className="title">{data?.title}</div>
          {handleReturnIcon(approved)}
        </div>
        {handleReturnDetails(data?.title, data?.values)}
        <div className="hover-buttons">
          <div className="buttons">
            <CustomButton
              className={`${
                role !== 'aprobologo' || !approval.editable ? 'disabled' : ''
              }`}
              data-testid="edit-button"
              onClick={() => {
                if (role === 'aprobologo' && approval.editable) {
                  handleEditCard(id, data);
                }
              }}
            >
              <span className="text">Editar</span>
            </CustomButton>
            {showDetail && (
              <CustomButton
                onClick={() => handleShowDetail(data?.title)}
                data-testid="view-button"
              >
                <span className="text">Ver detalle</span>
              </CustomButton>
            )}
          </div>
        </div>
      </div>
    </CardSmallContainer>
  );
};

export default CardSmall;
