import { useEffect, useState } from 'react';
import SelectTypeZoneMx from '../ZoneValidationMx/componets/SelectTypeZoneMx/SelectTypeZoneMx';
import { ContainerQuotaLogic, TitleContainer, Divisor } from './styles';
import getDataZoneType from '../../utils/getDataZonType';
import HistoricalRange from './components/HistoricalRange/HistoricalRange';
import SelectArea from '../ZoneValidationMx/componets/SelectArea/SelectArea';
import { IOption } from '../ZoneValidationMx/componets/SelectArea/interfaces';
import ParameterTabs from './components/ParameterTabs/ParameterTabs';
import ExperienceType from './components/ExperienceType/ExperienceType';
import MinimalRange from './components/MinimalRange/MinimalRange';
import MarketSharePercentage from './components/MarketSharePercentage/MarketSharePercentage';
import { ButtonContainerHesh } from '../HesH/styles';
import {
  getHistoricalRate,
  getQuotaParameters,
  putParameterHistoricalLeads,
  putParameterMarketShareSales,
} from '../../apis/Quotas/Quotas';
import { TabItem } from './model';
import ModalConfirmation from '../../components/ModalConfirmation';
import {
  ItemsTabs,
  tabItemsPlural,
  tabItemsSingular,
} from '../HesH/HesH.utils';
import { TModalInformation } from '../RulesEditor/model';

const QuotaLogic = () => {
  const [tabSelected, setTabSelected] = useState(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [isSingular, setisSingular] = useState<boolean>(true);
  const [isShowProject, setIsShowProject] = useState<boolean>(false);
  const [isDisabledButton, setIsDisabledButton] = useState(true);
  const [valueBadRegular, setValueBadRegular] = useState('');
  const [valueRegularGood, setValueRegularGood] = useState('');
  const [selectedPlaces, setSelectedPlaces] = useState<IOption[]>([]);
  const [step, setStep] = useState<number>(0);
  const [selectedTabParam, setSelectedTabParam] = useState(0);
  const [historicalRate, setHistoricalRate] = useState({});
  const [minimalRange, setMinimalRange] = useState({
    max: 0,
    min: 0,
    median: 0,
  });
  const [experienceType, setExperienceType] = useState('');
  const [marketShare, setMarketShare] = useState('');
  const [minimalValue, setMinimalValue] = useState('');
  const [showModal, setShowModal] = useState<boolean>(false);
  const [modalInfo, setModalInfo] = useState<TModalInformation>({
    title: '',
    subtitle: '',
    buttonOk: '',
    buttonCancel: '',
    action: () => {},
    image: '',
    showButtons: true,
  });
  const [selectData, setSelectData] = useState({
    data: [],
    message: '',
    success: false,
  });
  const tabItems = [
    { id: 1, label: 'median_zone' },
    { id: 2, label: 'big_zone' },
    { id: 3, label: 'city' },
    { id: 4, label: 'metropolitan_area' },
  ];
  const foundItemTab: TabItem | undefined = ItemsTabs.find(
    (item) => item.id === tabSelected
  );
  const filtered = foundItemTab?.label || '';

  const tabsTofind: TabItem | undefined = tabItems.find(
    (item) => item.id === tabSelected
  );

  const getIdString = (data: any[]) => {
    const idArray = data.map((item) => item.id);
    const idString = idArray.join(',');
    return idString;
  };

  const getIdArray = (data: any[]) => {
    return data.map((item) => item.id);
  };

  const handleGetParametersHistoricalRate = async () => {
    try {
      const data = await getHistoricalRate({
        filter_by: tabsTofind?.label,
        ids: getIdString(selectedPlaces),
      });
      setHistoricalRate(data);
    } catch (error) {
      console.error(error);
    }
  };

  const handleGetParameters = async () => {
    const stat = selectedTabParam === 2 ? 'sales' : 'market_share';
    const category = experienceType;
    const filter = tabsTofind ? tabsTofind?.label : '';
    const ids = getIdString(selectedPlaces);

    try {
      if (experienceType !== '') {
        const data = await getQuotaParameters(stat, category, filter, ids);
        setMinimalRange(data.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  // eslint-disable-next-line consistent-return
  const validationParam = () => {
    if (selectedTabParam === 1) {
      return isShowProject && !isDisabledButton;
    }

    if (selectedTabParam === 2) {
      if (experienceType === 'Sin Experiencia') {
        if (minimalValue === '0') {
          return true;
        }

        return false;
      }

      return !(+minimalValue < 2 && +minimalValue < 30);
    }

    if (selectedTabParam === 3) {
      return experienceType !== '' && marketShare !== '';
    }
  };

  const saveButtonValidation =
    tabSelected !== 0 &&
    selectedPlaces.length > 0 &&
    selectedTabParam !== 0 &&
    validationParam();

  const handleSaveHistoricalRange = async () => {
    const newData = {
      filter_by: filtered,
      ids: getIdArray(selectedPlaces),
      bad: [0, +valueBadRegular],
      regular: [+valueBadRegular, +valueRegularGood],
      good: [+valueRegularGood, 10],
    };

    setModalInfo({
      title: 'Estamos despegando',
      title2: 'El cambio puede tomar un tiempo',
      subtitle:
        'En cualquier momento verás la notificación de que el cambio fue realizado o, sí, por el contrario, ocurrió un error.',
      buttonOk: '',
      buttonCancel: '',
      action: () => {},
      // eslint-disable-next-line global-require
      image: require('../../assets/images/ship.gif'),
      showButtons: false,
    });

    try {
      await putParameterHistoricalLeads(newData);

      setModalInfo({
        title: 'Cambio exitoso',
        title2: 'Has editado los parámetros actuales',
        subtitle:
          'Puedes regresar y revisar que los cambios se hayan aplicado.',
        buttonOk: 'Aceptar',
        buttonCancel: 'Cancelar',
        action: () => {
          window.location.reload();
        },
        // eslint-disable-next-line global-require
        image: require('../../assets/images/office-working2.svg'),
        showButtons: true,
      });
    } catch (error) {
      console.error('Error en la solicitud:', error);
      setModalInfo({
        title: 'Cambio no aplicado',
        title2: 'No se pudieron editar los descuentos',
        subtitle:
          'Si identificas que es un error del portal, repórtalo al canal de inconsistencias-dudas-vendedores.',
        buttonOk: 'Volver a intentarlo',
        buttonCancel: 'Cancelar',
        action: handleSaveHistoricalRange,
        // eslint-disable-next-line global-require
        image: require('../../assets/images/office-working3.svg'),
        showButtons: true,
      });
    }
  };

  const saveMarketShareSales = () => {
    setModalInfo({
      title: 'Estamos despegando',
      title2: 'El cambio puede tomar un tiempo',
      subtitle:
        'En cualquier momento verás la notificación de que el cambio fue realizado o, sí, por el contrario, ocurrió un error.',
      buttonOk: '',
      buttonCancel: '',
      action: () => {},
      // eslint-disable-next-line global-require
      image: require('../../assets/images/ship.gif'),
      showButtons: false,
    });

    const params = {
      category: experienceType,
      filter_by: tabsTofind?.label,
      ids: getIdArray(selectedPlaces),
      parameter: selectedTabParam === 2 ? 'sales' : 'market_share',
      value: selectedTabParam === 2 ? +minimalValue : +marketShare,
    };

    putParameterMarketShareSales(params).then((response) => {
      if (response) {
        if (response.success) {
          setModalInfo({
            title: 'Cambio exitoso',
            title2: 'Has editado los parámetros actuales',
            subtitle:
              'Puedes regresar y revisar que los cambios se hayan aplicado.',
            buttonOk: 'Aceptar',
            buttonCancel: 'Cancelar',
            action: () => {
              window.location.reload();
            },
            // eslint-disable-next-line global-require
            image: require('../../assets/images/office-working2.svg'),
            showButtons: true,
          });
        }
      } else {
        setModalInfo({
          title: 'Cambio no aplicado',
          title2: 'No se pudieron editar los descuentos',
          subtitle:
            'Si identificas que es un error del portal, repórtalo al canal de inconsistencias-dudas-vendedores.',
          buttonOk: 'Volver a intentarlo',
          buttonCancel: 'Cancelar',
          action: saveMarketShareSales,
          // eslint-disable-next-line global-require
          image: require('../../assets/images/office-working3.svg'),
          showButtons: true,
        });
      }
    });
  };

  const handleSaveClick = () => {
    // eslint-disable-next-line @typescript-eslint/no-shadow
    const tabItemsLabel = isSingular ? tabItemsSingular : tabItemsPlural;
    const foundTabItemLabel: TabItem | undefined = tabItemsLabel.find(
      (item: any) => item.id === tabSelected
    );
    const pluralLabel = isSingular ? '' : 's';
    const label = foundTabItemLabel?.label || '';
    setModalInfo({
      title: '¿Estás seguro de editar estos parámetros?',
      subtitle: `El editar estos parámetros afectará a todos los inmuebles que pertenecen a la${pluralLabel} ${label}  seleccionada${pluralLabel}`,
      buttonOk: 'Sí, cambiar',
      buttonCancel: 'No, cancelar',
      action: () => {
        if (selectedTabParam === 2 || selectedTabParam === 3) {
          saveMarketShareSales();
        }

        if (selectedTabParam === 1) {
          handleSaveHistoricalRange();
        }
      },
      // eslint-disable-next-line global-require
      image: require('../../assets/images/office-working.svg'),
      showButtons: true,
    });

    setShowModal(true);
  };

  useEffect(() => {
    if (selectedPlaces.length > 1) {
      setisSingular(false);
    } else {
      setisSingular(true);
    }
  }, [selectedPlaces]);

  useEffect(() => {
    getDataZoneType({ setLoading, tabSelected, setSelectData });
    setValueBadRegular('');
    setValueRegularGood('');
    setIsDisabledButton(true);
    setIsShowProject(false);
    setSelectedTabParam(0);
  }, [tabSelected]);

  useEffect(() => {
    const shouldFetchParameters =
      selectedTabParam === 1 && selectedPlaces.length > 0;

    if (shouldFetchParameters) {
      handleGetParametersHistoricalRate();
    }
  }, [selectedTabParam, selectedPlaces]);

  useEffect(() => {
    if (selectedPlaces.length > 1) {
      setisSingular(false);
    } else {
      setisSingular(true);
    }

    setIsDisabledButton(true);
    setIsShowProject(false);
    setValueBadRegular('');
    setValueRegularGood('');

    if (selectedPlaces.length === 0) {
      setStep(1);
    }
  }, [selectedPlaces]);

  useEffect(() => {
    setIsShowProject(false);
  }, [valueBadRegular, valueRegularGood]);

  useEffect(() => {
    if (selectedTabParam === 2 || selectedTabParam === 3) {
      handleGetParameters();
    }
  }, [selectedTabParam, experienceType]);

  useEffect(() => {
    if (tabSelected !== 0 && step === 0) {
      setStep(1);
    }

    if (step >= 1 && selectedPlaces.length > 0) {
      setStep(2);
    }

    if (step >= 2 && selectedPlaces.length > 0 && experienceType !== '') {
      handleGetParameters();
      setStep(3);
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
    }
  }, [tabSelected, selectedPlaces, experienceType]);

  useEffect(() => {
    setExperienceType('');
  }, [selectedTabParam]);

  return (
    <>
      <TitleContainer>Lógica de cupos</TitleContainer>
      <ContainerQuotaLogic>
        <SelectTypeZoneMx
          tabSelected={tabSelected}
          setTabSelected={setTabSelected}
        />
        {step >= 1 && tabSelected !== 0 && (
          <>
            <Divisor />
            <SelectArea
              selectedData={selectData}
              loading={loading}
              selectedPlaces={selectedPlaces}
              setSelectedPlaces={setSelectedPlaces}
            />
          </>
        )}
        {step >= 2 && (
          <>
            <Divisor />
            <ParameterTabs
              selectedTabParam={selectedTabParam}
              setSelectedTabParam={setSelectedTabParam}
            />
          </>
        )}
        {step >= 2 && (selectedTabParam === 2 || selectedTabParam === 3) && (
          <>
            <Divisor />
            <ExperienceType setExperienceType={setExperienceType} />
          </>
        )}
        {step >= 2 && selectedTabParam === 1 && (
          <>
            <Divisor />
            <HistoricalRange
              showProject={isShowProject}
              setIsShowProject={setIsShowProject}
              historicalRate={historicalRate}
              tabSelected={tabSelected}
              isSingular={isSingular}
              ids={getIdArray(selectedPlaces)}
              isDisabledButton={isDisabledButton}
              setIsDisabledButton={setIsDisabledButton}
              valueBadRegular={valueBadRegular}
              setValueBadRegular={setValueBadRegular}
              valueRegularGood={valueRegularGood}
              setValueRegularGood={setValueRegularGood}
            />
            {isShowProject && (
              <ButtonContainerHesh>
                <button
                  className="button-hesh"
                  onClick={handleSaveClick}
                  disabled={!saveButtonValidation}
                >
                  Guardar cambios
                </button>
              </ButtonContainerHesh>
            )}
          </>
        )}
        {step >= 3 && selectedTabParam === 2 && experienceType !== '' && (
          <>
            <Divisor />
            <MinimalRange
              tabSelected={tabSelected}
              isSingular={isSingular}
              summary={minimalRange}
              type={experienceType}
              minimalValue={minimalValue}
              setMinimalValue={setMinimalValue}
            />

            <ButtonContainerHesh>
              <button
                className="button-hesh"
                onClick={handleSaveClick}
                disabled={!saveButtonValidation}
              >
                Guardar cambios
              </button>
            </ButtonContainerHesh>
          </>
        )}
        {step >= 3 && experienceType !== '' && selectedTabParam === 3 && (
          <>
            <Divisor />
            <MarketSharePercentage
              marketShare={marketShare}
              setMarketShare={setMarketShare}
              minimalRange={minimalRange}
              isSingular={isSingular}
              tabSelected={tabSelected}
              experienceType={experienceType}
            />
            <ButtonContainerHesh>
              <button
                className="button-hesh"
                onClick={handleSaveClick}
                disabled={!saveButtonValidation}
              >
                Guardar cambios
              </button>
            </ButtonContainerHesh>
          </>
        )}

        <ModalConfirmation
          showModal={showModal}
          setShowModal={setShowModal}
          modalInfo={modalInfo}
          id={1}
          showButtons={modalInfo.showButtons}
        />
      </ContainerQuotaLogic>
    </>
  );
};

export default QuotaLogic;
