import dateToParams from '../../utils/dateToParams';
import zonesClient from '../../utils/zonesClient';

import { RejectionInfo } from '../../pages/FunnelConvertion/model/rejectionReasons';

export const getRejectionReasonsMa = async (
  zoneId: number,
  date: Date,
  teamRejection?: string,
  rejectedReasons?: string[]
) => {
  const dateParams = dateToParams(date);

  const { data } = await zonesClient.get<IApiResponse<RejectionInfo>>(
    'get_rejected_reasons_ma',
    {
      params: {
        metropolitan_area_id: zoneId,
        team_rejection: teamRejection || undefined,
        reasons: rejectedReasons || undefined,
        ...dateParams,
      },
    }
  );

  return data;
};

export const getRejectionReasonsMz = (
  zoneId: number,
  date: Date,
  teamRejection?: string,
  rejectedReasons?: string[]
) => {
  const dateParams = dateToParams(date);

  return zonesClient.get<IApiResponse<RejectionInfo>>(
    'get_rejected_reasons_mz',
    {
      params: {
        median_zone_id: zoneId,
        team_rejection: teamRejection || undefined,
        reasons: rejectedReasons || undefined,
        ...dateParams,
      },
    }
  );
};
