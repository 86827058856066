import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import { zoneStoreActions } from '../../../../redux/reducers/zoneReducer';
import { selectZoneInfo } from '../../../../redux/reducers/zoneSelectors';
import { PageTab, ZonePageList, ZonePagesTabs } from '../ZonePages/styles';
import { ZoneActionItem, ZoneActionsContainer } from './styles';
import { SimpleCardAccionable } from '../../../../components/SimpleCard';
import ModalDiscount from '../../../Pricing/components/ModalDiscount/ModalDiscount';
import {
  Indicators,
  Indicator,
} from '../../../Pricing/components/ActualDiscount/styles';

import ApartmentsIcon from '../../../Pricing/components/ActualDiscount/assets/apartments.svg';
import HouseIcon from '../../../Pricing/components/ActualDiscount/assets/house-icon.svg';
import ToleranceIcon from '../../../FunnelConvertion/components/ActualRulesApproval/assets/tolerance-icon.svg';
import PercentilIcon from '../../../FunnelConvertion/components/ActualRulesApproval/assets/percentil-icon.svg';

type PagesTabs = 'actions';

const ZoneActions = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const zoneInfo = useAppSelector(selectZoneInfo);

  const [currentTab, setCurrentTab] = useState<PagesTabs>('actions');
  const [isDiscountModalOpen, setIsDiscountModalOpen] = useState(false);
  const zonePricing = { pricing: zoneInfo };

  const setZonePricing = ({ pricing }: any) => {
    dispatch(zoneStoreActions.setZoneInformation(pricing));
  };

  return (
    <ZoneActionsContainer>
      <ZonePagesTabs>
        <PageTab
          active={currentTab === 'actions'}
          onClick={() => setCurrentTab('actions')}
        >
          Accionables
        </PageTab>
      </ZonePagesTabs>

      <ZonePageList>
        {currentTab === 'actions' && (
          <>
            <SimpleCardAccionable>
              <ZoneActionItem onClick={() => setIsDiscountModalOpen(true)}>
                <p>Cambio de descuento</p>

                <div className="action-info">
                  <span>Porcentaje actual</span>

                  <Indicators>
                    <Indicator mini>
                      <img src={ApartmentsIcon} alt="icono de apartamentos" />
                      <p className="text">
                        <b>{zoneInfo.apartment_discount}%</b>
                      </p>
                    </Indicator>
                    <Indicator mini last>
                      <img src={HouseIcon} alt="icono de casa" />
                      <p className="text">
                        <b>{zoneInfo.house_discount}%</b>
                      </p>
                    </Indicator>
                  </Indicators>
                </div>
              </ZoneActionItem>
            </SimpleCardAccionable>

            <SimpleCardAccionable>
              <ZoneActionItem
                onClick={() => navigate('/portal/reglas-de-aprobacion')}
              >
                <p>Reglas de aprobación</p>

                <div className="action-info">
                  <Indicators>
                    <Indicator mini>
                      <img src={ToleranceIcon} alt="icono de apartamentos" />
                      <div className="text">
                        Tolerancia <b>{zoneInfo?.tolerance}%</b>
                      </div>
                    </Indicator>
                    <Indicator mini last>
                      <img src={PercentilIcon} alt="icono de casa" />
                      <div className="text">
                        Percentil <b>{zoneInfo?.percentile_admin}%</b>
                      </div>
                    </Indicator>
                  </Indicators>
                </div>
              </ZoneActionItem>
            </SimpleCardAccionable>
          </>
        )}
      </ZonePageList>

      {isDiscountModalOpen && (
        <ModalDiscount
          setModalOpen={() => setIsDiscountModalOpen(false)}
          zonePricing={zonePricing}
          setZonePricing={setZonePricing}
        />
      )}
    </ZoneActionsContainer>
  );
};

export default ZoneActions;
