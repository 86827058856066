import {
  white,
  electricPurple500,
  robotoRegular,
  strokesAAA,
} from '@habitech/shared';
import styled from 'styled-components';

export const ContainerFooterSave = styled.div<{ animation: boolean }>`
  background: ${white};
  bottom: 0;
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.25);
  display: flex;
  left: -32px;
  max-height: 80px;
  padding: 16px;
  position: fixed;
  transition: all 0.5s ease;
  transform: ${(props) =>
    props.animation ? 'translateY(0)' : 'translateY(100%)'};
  width: 100%;
  z-index: 200;
`;
export const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-left: auto;
  .save {
    align-items: center;
    background: ${electricPurple500};
    border-radius: 6px;
    border: 1px solid ${electricPurple500};
    color: ${white};
    display: flex;
    font-family: ${robotoRegular};
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    gap: 8px;
    height: 48px;
    justify-content: center;
    line-height: normal;
    margin: 0 16px 0 8px;
    padding: 8px 24px;
    text-align: center;
    width: 207px;
  }
  .back {
    align-items: center;
    background: ${white};
    border-radius: 6px;
    border: 1px solid ${electricPurple500};
    color: ${electricPurple500};
    display: flex;
    font-family: ${robotoRegular};
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    gap: 8px;
    height: 48px;
    justify-content: center;
    line-height: normal;
    padding: 8px 24px;
    text-align: center;
    width: 207px;
  }
  .disabled {
    background: #f3f3f3;
    cursor: not-allowed;
    color: rgb(151, 148, 153);
    border: 1px solid ${strokesAAA};
  }
`;
