import { ColumnDescription, ContainerCardDiscount } from './styles';
import homeMoney from '../../../../assets/images/homeMoney.svg';
import HabiLoader from '../../../../components/HabiLoader/HabiLoader';
import { SeparatorWrapper } from '../ContainerDiscountEngine/style';
import handleOrderAndTranslateData from './DiscountApplied.utils';

interface IDiscountAppliedGlobal {
  globalRules: any;
}

const DiscountAppliedGlobal: React.FC<IDiscountAppliedGlobal> = ({
  globalRules,
}) => {
  if (Object.keys(globalRules).length === 0) {
    return (
      <SeparatorWrapper>
        <HabiLoader size="small" />
      </SeparatorWrapper>
    );
  }

  return (
    <>
      {' '}
      <ColumnDescription>
        <div className="subtitle">Descuentos aplicados</div>
      </ColumnDescription>
      <ContainerCardDiscount>
        {globalRules.map((item: any) => (
          <div className="container-flex" key={item.id}>
            <img src={homeMoney} alt="home_money" className="image" />
            <div>
              <div className="title">Regla(s) global(es) aplicada(s)</div>
              <div className="subtitle">
                Viviendas con {handleOrderAndTranslateData(item.name)}
                de descuento
              </div>
            </div>
          </div>
        ))}
      </ContainerCardDiscount>
    </>
  );
};

export default DiscountAppliedGlobal;
