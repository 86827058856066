import { CircularProgress } from '@mui/material';

interface IDescription {
  loading: boolean;
  values: any;
}

const Description = ({ loading, values }: IDescription) => {
  return (
    <div className="description">
      {loading ? (
        <CircularProgress color="inherit" size={15} />
      ) : (
        <>
          Afectado: {String.fromCharCode(160)}
          <span className="strong">
            {values.success ? values.range_affected.toFixed(2) : ' --'}
            {values.success && '%'}
          </span>
          <span className="separator-description">|</span>
          Afectado mes: {String.fromCharCode(160)}
          <span className="normal-1">
            {values.success ? values.month_affected.toFixed(2) : ' --'}
            {values.success && '%'}
          </span>
          <span className="separator-description">|</span>
          Zonas con regla: {String.fromCharCode(160)}
          <span className="normal-2">
            {values.success ? values.zones_affected : ' --'}
          </span>
        </>
      )}
    </div>
  );
};

export default Description;
