import {
  DropdownButton,
  FloatingButton,
  Button,
} from '@habitech/call-to-action';
import { TextField } from '@habitech/keychain-atoms-inputs';
import { Autocomplete } from '@mui/material';
import { IFormEditRules } from '../../model';
import {
  RuleFormContainer,
  RuleFormWrapper,
  RuleFormItem,
  AddNewElementWrapper,
  RuleFormButton,
  FormSearchContainer,
  WarningMessage,
} from './style';
import plusWhite from '../../../../assets/icons/plus-white.svg';
import upArrow from '../../../../assets/icons/Up.svg';
import { LoaderContainer } from '../../../Utility/style';
import HabiLoader from '../../../../components/HabiLoader/HabiLoader';

const FormEditRules: React.FC<IFormEditRules> = ({
  elementOptions,
  todoOptions,
  zoneOptions,
  dataForm,
  elementChange,
  todoChange,
  handleInputRule,
  handleInputRulePoints,
  saveRule,
  validation,
  handleZoneType,
  showMore,
  setShowMore,
  maOptions,
  loaded,
  handleAmCityChange,
}) => {
  return (
    <RuleFormContainer>
      <RuleFormWrapper>
        <RuleFormItem>
          <DropdownButton
            dataId="elements"
            size="extra-large fluid"
            variant="white"
            label="* Características / Elementos"
            placeholder="Selecciona"
            disabled={false}
            listOptions={elementOptions}
            setValue={(value) => elementChange(String(value))}
            defaultSelected={dataForm.rule_item}
          />
        </RuleFormItem>
        <RuleFormItem>
          <DropdownButton
            dataId="todo"
            size="extra-large fluid"
            variant="white"
            label="* ¿Qué queremos hacer?"
            placeholder="Selecciona"
            disabled={false}
            listOptions={todoOptions}
            setValue={(value) => todoChange(String(value))}
            defaultSelected={dataForm.rule_condition}
          />
        </RuleFormItem>
        <RuleFormItem>
          <TextField
            originType="habi"
            name="rule"
            placeHolder="Escribe la regla"
            dataId="testId"
            defaultValue={String(dataForm.expected_value)}
            autoFocus={false}
            type="number"
            label="* Regla a añadir"
            disabled={
              !(dataForm.rule_item !== '' && dataForm.rule_condition !== '')
            }
            onChange={(name, value) => handleInputRule(Number(value))}
            errorMessage={validation.message}
            error={validation.error}
          />
        </RuleFormItem>
        <RuleFormItem>
          <TextField
            originType="habi"
            name="points"
            placeHolder="Escribe los puntos"
            defaultValue={dataForm.points_discount}
            dataId="testId"
            autoFocus={false}
            type="text"
            label="* Descuento en puntos"
            disabled={
              !(
                dataForm.rule_item !== '' &&
                dataForm.rule_condition !== '' &&
                dataForm.expected_value !== ''
              )
            }
            onChange={(name, value) => handleInputRulePoints(value)}
            suggestionMsg="Se aplica para ambos criterios"
          />
          <WarningMessage>Max 85 puntos</WarningMessage>
        </RuleFormItem>
        {showMore && (
          <RuleFormItem>
            <DropdownButton
              dataId="zone"
              size="extra-large fluid"
              variant="white"
              label="* Zona"
              placeholder="Selecciona"
              disabled={false}
              listOptions={zoneOptions}
              setValue={(value) => handleZoneType(String(value))}
              defaultSelected={dataForm.zone}
            />
          </RuleFormItem>
        )}
        {showMore && (
          <RuleFormItem>
            {(loaded && (
              <Autocomplete
                disablePortal
                id="search"
                options={maOptions}
                sx={{ width: 300 }}
                onChange={(e, value) => {
                  handleAmCityChange(Number(value?.id));
                }}
                renderInput={(params) => {
                  return (
                    <FormSearchContainer ref={params.InputProps.ref}>
                      <span className="text-label">
                        *Área Metropolitana o ciudad
                      </span>
                      <input
                        type="text"
                        {...params.inputProps}
                        disabled={
                          !!(
                            (dataForm.zone === '' ||
                              dataForm.zone === 'general') &&
                            showMore
                          )
                        }
                      />
                    </FormSearchContainer>
                  );
                }}
              />
            )) || (
              <LoaderContainer style={{ marginTop: '20px' }}>
                <HabiLoader size="small" />
              </LoaderContainer>
            )}
          </RuleFormItem>
        )}
        <RuleFormButton>
          <Button
            dataId="apply-button"
            variant="primary"
            size="large"
            fluid
            onClick={() => saveRule()}
            disabled={
              !(
                dataForm.rule_item !== '' &&
                dataForm.rule_condition !== '' &&
                dataForm.expected_value !== '' &&
                String(dataForm.points_discount) !== ''
              )
            }
          >
            Aplicar cambios
          </Button>
        </RuleFormButton>
      </RuleFormWrapper>

      <AddNewElementWrapper>
        <FloatingButton
          dataId="add"
          variant={showMore ? 'secondary' : 'primary'}
          onClick={() => setShowMore(!showMore)}
          customIcon={
            <img
              src={showMore ? upArrow : plusWhite}
              alt="add"
              style={{ width: '15px' }}
            />
          }
        />
      </AddNewElementWrapper>
    </RuleFormContainer>
  );
};

export default FormEditRules;
