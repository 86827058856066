import { TitleSection, WrapperBehaviourGraphic } from './styles';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { electricPurple500, white } from '@habitech/shared';
import { getElasticityLineGraphic } from '../../../../apis/elasticity/elasticity';
import { useAppSelector } from '../../../../redux/hooks';
import { useEffect, useState } from 'react';
import { selectUserDateRange } from '../../../../redux/reducers/userSelectors';
import { LoaderContainer } from '../../../Utility/style';
import HabiLoader from '../../../../components/HabiLoader/HabiLoader';

const BehaviourGraphic = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [labels, setLabels] = useState([]);
  const [datasets, setDatasets] = useState([]);
  const zoneState = useAppSelector((state) => state.zoneStore);
  const userDateRange = useAppSelector(selectUserDateRange);

  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
  );

  const options = {
    responsive: true,
    scales: {
      x: {
        grid: {
          color: function () {
            return white;
          },
        },
        title: {
          display: true,
          text: 'Descuento efectivo',
          color: '#474747',
          font: { weight: '600', family: 'Roboto', fontSize: '12px' },
        },
      },
      y: {
        title: {
          display: true,
          text: 'Porcentaje de cierres',
          color: '#474747',
          font: { weight: '600', family: 'Roboto', fontSize: '12px' },
        },

        ticks: {
          callback: function (value: any) {
            return value + '%';
          },
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
    },
  };

  const data = {
    labels,
    datasets: [
      {
        label: 'Porcentaje de cierres',
        data: datasets,
        borderColor: electricPurple500,
        backgroundColor: electricPurple500,
        tension: 0.5,
        datalabels: {
          display: false,
        },
      },
    ],
  };

  const getElasticityData = async () => {
    const start_date = new Date(userDateRange[0])
      .toISOString()
      .substring(0, 10);
    const end_date = new Date(userDateRange[1]).toISOString().substring(0, 10);
    setLoading(true);
    await getElasticityLineGraphic({
      median_zone_id: zoneState.zone.middleZone.id,
      start_date,
      end_date,
    })
      .then((response) => {
        setLoading(false);
        setLabels(response.data.labels);
        setDatasets(response.data.datasets);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getElasticityData();
  }, [userDateRange]);

  return (
    <WrapperBehaviourGraphic>
      <TitleSection>Comportamiento de la zona mediana</TitleSection>
      <div className="category">
        <div className="indicator"></div>
        <div>Porcentaje de cierres</div>
      </div>
      <div>
        {!loading ? (
          <>
            <Line options={options} data={data} />
          </>
        ) : (
          <>
            <LoaderContainer>
              <HabiLoader size="small" />
            </LoaderContainer>
          </>
        )}
      </div>
    </WrapperBehaviourGraphic>
  );
};

export default BehaviourGraphic;
