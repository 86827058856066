import { Autocomplete } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import TextField from '@mui/material/TextField';
import { useEffect, useState } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import ColumnDescription from '../../../DiscountEngine/components/TabRules/style';
import SelectorContainer, {
  CustomLabel,
  GridSelectContainer,
} from './styles';
import { IZoneSelector, TAutocompleteValue } from '../../model';
import { Divisor } from '../SelectTypeZone/styles';
import { stylesMultiSelect } from '../../../FunnelConvertion/components/ReasonsFilter/styles';

const icon = <CheckBoxOutlineBlankIcon fontSize="large" />;
const checkedIcon = <CheckBoxIcon fontSize="large" />;

const ZoneSelector: React.FC<IZoneSelector> = ({
  selectedOptions,
  setSelectedOptions,
  selectorOptions,
  tabSelected,
  isLoaded,
  getZonesByWord,
}) => {
  const [optionsFiltered, setOptionsFiltered] = useState<TAutocompleteValue[]>([]);

  const handleAutoComplete = (value: TAutocompleteValue[]) => {
    setSelectedOptions(value);
    setOptionsFiltered(selectorOptions);
  };

  const filterOptions = (value: string) => {
    if (tabSelected < 3) {
      const dataFiltered = selectorOptions.filter((item) => {
        const fullLabel = `${item.value} ${item.label}`;

        if (fullLabel.toLowerCase().search(value) !== -1) {
          return item;
        }
      });

      setOptionsFiltered(dataFiltered);
    } else {
      getZonesByWord(value);
    }
  };

  useEffect(() => {
    if (isLoaded) {
      setOptionsFiltered(selectorOptions);
    }
  }, [isLoaded, selectorOptions]);

  return (
    <>
      <ColumnDescription>
        <div className="subtitle">Donde se van a aplicar estos cambios</div>
      </ColumnDescription>
      <SelectorContainer>
        <GridSelectContainer>
          <CustomLabel>
            {tabSelected === 1 && 'Área Metropolitana'}
            {tabSelected === 2 && 'Ciudad'}
            {tabSelected === 3 && 'Zona mediana'}
          </CustomLabel>
          <Autocomplete
            multiple
            autoComplete={false}
            disablePortal
            value={selectedOptions}
            id="checkboxes-list-select"
            options={optionsFiltered}
            isOptionEqualToValue={(option, value) =>
              option.label === value.label
            }
            onChange={(e, value) => handleAutoComplete(value)}
            renderOption={(props, option, { selected }) => (
              <li {...props} style={{ fontSize: '16px' }}>
                <Checkbox
                  sx={stylesMultiSelect.checkbox}
                  icon={icon}
                  checkedIcon={checkedIcon}
                  size="medium"
                  checked={selected}
                />
                {option.value}. {option.label}
              </li>
            )}
            filterOptions={(x) => x}
            disableCloseOnSelect
            size="medium"
            loading={!isLoaded}
            sx={stylesMultiSelect.select}
            getOptionLabel={(option: any) => `${option.value} ${option.label}`}
            style={{ width: 500 }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                onChange={(e) => {
                  filterOptions(e.target.value);
                }}
                label="Área Metropolitana o ciudad"
                placeholder="Área Metropolitana o ciudad"
                sx={{
                  '& legend': { display: 'none' },
                  '& fieldset': { top: 0 },
                }}
                InputProps={{
                  ...params.InputProps,
                  style: { fontSize: `16px` },
                  endAdornment: (
                    <>
                      {!isLoaded ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
              />
            )}
          />
        </GridSelectContainer>
      </SelectorContainer>

      <Divisor />
    </>
  );
};

export default ZoneSelector;
