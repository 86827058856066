import { Outlet } from 'react-router-dom';
import { ContainerZone } from '../../pages/ZoneValidation/styles';
import withRoutePage, { RoutePage } from '../../utils/withRoutePage';
import TemplateCleanMx from '../TemplateCleanMx/TemplateCleanMx';

const LayoutMx: RoutePage = () => {
  return (
    <TemplateCleanMx>
      <ContainerZone>
        <Outlet />
      </ContainerZone>
    </TemplateCleanMx>
  );
};

LayoutMx.requiresAuth = true;

export default withRoutePage(LayoutMx);
