import {
  electricPurple100,
  electricPurple200,
  electricPurple300,
  electricPurple600,
  electricPurple800,
  skyBlue200,
  skyBlue500,
  skyBlue800,
  tomatoCoral200,
  tomatoCoral400,
  tomatoCoral600,
  turquoise100,
  turquoise300,
  turquoise600,
  turquoise700,
} from '@habitech/shared';

export const GRAPHIC_COLORS = [
  electricPurple600,
  tomatoCoral600,
  skyBlue800,
  turquoise600,
  electricPurple800,
  turquoise700,
  skyBlue500,
  electricPurple300,
  turquoise100,
  skyBlue200,
  electricPurple200,
  turquoise300,
  tomatoCoral200,
  electricPurple100,
  tomatoCoral400,
];
