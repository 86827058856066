import LayoutHeaderClean from '../../components/LayoutClean';
import LayoutSidebar from '../../components/LayoutSidebar';
import AlertToast from '../Pricing/components/AlertToast/AlertToast';
import { ContainerPage, LayoutWrapper } from '../../components/Layout/style';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { alertStoreActions } from '../../redux/reducers/alertReducer';
import NewApprovalRules from './NewApprovalRules';

const NewApprovalRulesCo = () => {
  const alertState = useAppSelector((state) => state.alertStore);
  const dispatch = useAppDispatch();
  const { hideAlert } = alertStoreActions;

  const handleAlert = () => {
    dispatch(hideAlert());
  };

  return (
    <LayoutWrapper>
      <LayoutSidebar />
      <ContainerPage>
        <LayoutHeaderClean />
        <AlertToast
          type={alertState.type}
          text={alertState.text}
          showAlert={alertState.showAlert}
          setShowAlert={handleAlert}
          top="75px"
        />
        <NewApprovalRules />
      </ContainerPage>
    </LayoutWrapper>
  );
};

export default NewApprovalRulesCo;
