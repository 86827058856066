import {
  scarpaGray100,
  scarpaGray600,
  scarpaGray700,
  scarpaGray800,
  white,
} from '@habitech/shared';
import styled from 'styled-components';
import { hintPerano200, textGray3 } from '../../../../styles/stylesConstants';

export const WrapperZoneValidations = styled.div`
  background: ${white};
  box-shadow: 0px 0px 1px rgba(48, 49, 51, 0.05),
    0px 2px 4px rgba(48, 49, 51, 0.1);
  border-radius: 6px;
  display: grid;
  padding: 24px 16px;

  .line {
    width: 100%;
    height: 1px;
    border: none;
    background-color: ${hintPerano200};
  }
`;

export const WrapperIndicators = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
`;

export const IndicatorWrapper = styled.div`
  display: grid;
  border-bottom: 1px solid ${scarpaGray100};
  padding: 24px 0 16px 0;

  .title {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: ${scarpaGray800};
    margin-bottom: 3px;
    height: 24px;
  }
  .description {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: ${scarpaGray600};
    max-width: 140px;
    height: 40px;
  }
`;

export const SectionTitle = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  margin-bottom: 33px;

  img {
    margin-right: 11px;
  }

  span {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 32px;
    color: ${scarpaGray700};
  }

  .icon {
    font-size: 20px;
    margin-right: 11px;
  }
`;

interface StageIndicatorProps {
  background: string;
  color: string;
}
export const StageIndicator = styled.div<StageIndicatorProps>`
  width: 44.52px;
  height: 42.62px;
  border-radius: 6px;
  background: ${(props) => props.background};
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${(props) => props.color};
  margin-bottom: 18px;

  svg {
    font-size: 30px;
  }
`;

export const BarIndicatorsStatus = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
`;

export const SubtitleBar = styled.div`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  color: ${textGray3};
  margin: 13px 0 10px 0;
`;
export const IndicatorWrapperStatus = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 6px;
  align-items: center;

  .title {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 18px;
    color: ${scarpaGray600};
  }
`;
export const StageIndicatorMini = styled.div<StageIndicatorProps>`
  width: 22.98px;
  height: 22px;
  border-radius: 6px;
  background: ${(props) => props.background};
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${(props) => props.color};

  svg {
    font-size: 15px;
  }
`;
