import styled from 'styled-components';
import { monserratRegular, scarpaGray600 } from '@habitech/shared';

const TitleEvolution = styled.div`
  grid-column: 1 / 6;
  margin-top: 27px;
  .imagen-calendar {
    margin-left: 18px;
    margin-right: 11px;
  }
  .text {
    font-family: ${monserratRegular};
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: ${scarpaGray600};
  }
`;

export default TitleEvolution;
