import { TextField } from '@habitech/keychain-atoms-inputs';
import { SetStateAction } from 'react';
import {
  ColumnDescription,
  ImageButton,
  ContainerButtons,
  ContainerTitle,
  ContainerTabs,
  ContainerDiscountToAply,
  ContainerButtonSend,
} from './styles';
import Percentage from '../../../../assets/icons/percentage.svg';
import PercentagePurple from '../../../../assets/icons/percentagePurple.svg';
import list from '../../../../assets/icons/list.svg';
import listPurple from '../../../../assets/icons/listPurple.svg';
import GlobalTab from '../../../../components/Tab/GlobalTab';
import AddLessInput from '../../../../components/AddLessInput/AddLessInput';
import PlacesByList from '../PlacesByList';
import { Divisor } from '../SelectTypeZone/styles';

interface ISelectDecision {
  sendAction: Function;
  firstSelected: boolean;
  setFirstSelected: React.Dispatch<SetStateAction<boolean>>;
  discountApartment: string;
  setDiscountApartment: React.Dispatch<SetStateAction<string>>;
  discountHouse: string;
  setDiscountHouse: React.Dispatch<SetStateAction<string>>;
  disabledButton: boolean;
  tabDiscountPlaces: string;
  setTabDiscountPlaces: React.Dispatch<SetStateAction<string>>;
  setNoInmuebles: React.Dispatch<SetStateAction<number>>;
  noInmuebles: number;
  setRevenue: React.Dispatch<SetStateAction<string>>;
  setTypeList: React.Dispatch<SetStateAction<string>>;
  typeList: string;
  countList: number;
  setCountList: React.Dispatch<SetStateAction<number>>;
  revenue: string;
}

const SelectDecision: React.FC<ISelectDecision> = ({
  sendAction,
  firstSelected,
  setFirstSelected,
  discountApartment,
  setDiscountApartment,
  discountHouse,
  setDiscountHouse,
  disabledButton,
  tabDiscountPlaces,
  setTabDiscountPlaces,
  setNoInmuebles,
  noInmuebles,
  setRevenue,
  setTypeList,
  typeList,
  countList,
  setCountList,
  revenue,
}) => {
  const regularnumber = /^\d+([.]\d+)?$/;

  const handleValidateData = (value: any, opt: boolean) => {
    if (value === '') {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      opt ? setDiscountHouse('') : setDiscountApartment('');
    }

    if (regularnumber.test(value)) {
      if (value < 100) {
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        opt ? setDiscountHouse(value) : setDiscountApartment(value);
      }
    }
  };

  const handleTabDiscountPlace = (type: string) => {
    setTabDiscountPlaces(type);
  };

  return (
    <>
      <ColumnDescription>
        <div className="subtitle">
          ¿Qué decisión deseas tomar sobre las zonas?
        </div>
      </ColumnDescription>
      <ContainerButtons>
        <ImageButton
          isDisable={tabDiscountPlaces !== 'discount'}
          onClick={() => handleTabDiscountPlace('discount')}
        >
          <img
            src={
              tabDiscountPlaces === 'discount' ? PercentagePurple : Percentage
            }
            alt="percentage"
          />
          <span>Cambio de descuento</span>
        </ImageButton>
        <ImageButton
          isDisable={tabDiscountPlaces !== 'places'}
          onClick={() => handleTabDiscountPlace('places')}
        >
          <img
            src={tabDiscountPlaces === 'places' ? listPurple : list}
            alt="lista"
          />
          <span>Cupos por lista</span>
        </ImageButton>
      </ContainerButtons>
      {(tabDiscountPlaces === 'discount' && (
        <>
          <ContainerTitle>
            <div className="title">Tipo de descuento a realizar en las zonas</div>
          </ContainerTitle>
          <ContainerTabs>
            <GlobalTab
              firstSelected={firstSelected}
              setFirstSelected={setFirstSelected}
              titleOne="Sumar o restar"
              titleTwo="Cambiarlo todo"
            />
          </ContainerTabs>

          <ContainerTitle>
            <div className="title">Descuentos a editar</div>
          </ContainerTitle>
          {firstSelected ? (
            <ContainerDiscountToAply>
              <div className="add-less margin-first">
                <div className="title">Apartamentos</div>
                <AddLessInput
                  inputValue={discountApartment}
                  setInputValue={setDiscountApartment}
                />
              </div>
              <div className="add-less">
                <div className="title">Casas</div>
                <AddLessInput
                  inputValue={discountHouse}
                  setInputValue={setDiscountHouse}
                />
              </div>
            </ContainerDiscountToAply>
          ) : (
            <ContainerDiscountToAply>
              <div className=" margin-first">
                <TextField
                  value={`${discountApartment}`}
                  name="apartment "
                  type="number"
                  dataId="text_field"
                  label="Nuevo descuento apartamentos"
                  placeHolder="16%"
                  onChange={(inputname, value) => handleValidateData(value, false)}
                />
              </div>
              <div>
                <TextField
                  value={`${discountHouse}`}
                  name="house"
                  type="number"
                  dataId="text_field_two"
                  label="Nuevo descuento Casas"
                  placeHolder="18%"
                  onChange={(inputname, value) => handleValidateData(value, true)}
                />
              </div>
            </ContainerDiscountToAply>
          )}
          <ContainerButtonSend>
            <button onClick={() => sendAction()} disabled={disabledButton}>
              <span className="text">Guardar cambios en zonas</span>
            </button>
          </ContainerButtonSend>
        </>
      )) || (
        <>
          <Divisor />
          <PlacesByList
            sendAction={sendAction}
            setNoInmuebles={setNoInmuebles}
            setRevenue={setRevenue}
            setTypeList={setTypeList}
            countList={countList}
            setCountList={setCountList}
            noInmuebles={noInmuebles}
            typeList={typeList}
            revenue={revenue}
          />
        </>
      )}
    </>
  );
};

export default SelectDecision;
