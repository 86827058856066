import { robotoRegular, scarpaGray600, scarpaGray800 } from '@habitech/shared';
import styled from 'styled-components';
import { principalFont } from '../../../../styles/stylesConstants';

export const WrapperMarketingFunnel = styled.div`
  display: grid;
  grid-template-columns: repeat(10, 1fr);
`;

export const SectionTitle = styled.div`
  grid-column: 1 / 6;
  display: flex;
  justify-content: start;
  align-items: center;
  margin-bottom: 2rem;
  img {
    margin-right: 11px;
  }
  span {
    font-weight: 600;
    font-size: 20px;
    font-family: ${principalFont};
    color: ${scarpaGray600};
  }
  .icon {
    font-size: 20px;
    margin-right: 11px;
  }
`;

export const DateSelector = styled.div`
  grid-column: 9/11;
`;

export const FilterWrapper = styled.div`
  grid-column: 1/9;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const FilterContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-right: 21px;
  label {
    font-family: ${robotoRegular};
    font-size: 14px;
    color: ${scarpaGray800};
    font-weight: 400;
    margin-bottom: 4px;
  }
  .date-label {
    font-size: 13px;
    font-weight: 500;
  }
  .styles__PickerContainer-nwdsad-2 {
    z-index: 3;
  }
`;

export const LabelMonthPicker = styled.div`
  font-family: ${robotoRegular};
  font-weight: 400;
  color: ${scarpaGray800};
  font-size: 14px;
  margin-bottom: 4px;
  &.active {
    font-weight: 600;
    font-size: 15px;
  }
`;
