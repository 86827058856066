// eslint-disable-next-line import/prefer-default-export
export const intToString = (array: { toString: () => string }) => {
  const newArray = array.toString().split(',');
  return newArray;
};

export const stringToFloat = (array: string[] = []) => {
  const newArray: number[] = [];
  array.forEach((element: string) => {
    newArray.push(parseFloat(element));
  });

  return newArray;
};
