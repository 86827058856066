import { ContainerComment, Subtitle } from '../DetailsRoomsBathrooms/styles';
import InverterTable from '../InverterTable/InverterTable';
import { dictionaryGoodZoneTableCo } from './utils';
import { organizeData } from '../DetailHistoricalBusinnesInformation/utils';
import { useSelector } from 'react-redux';

const DetailGoodZone = () => {
  const stateCards = useSelector((state: any) => state.cardsStore.stateCards);

  const dataGoodZone = stateCards.filter((item: any) => item.id === 6);

  const handleOrderData = organizeData(
    dataGoodZone[0]?.table?.appproval_result?.table_data,
    dictionaryGoodZoneTableCo
  );

  const handleCreateComment = (
    approval_result: string,
    rejected: string = '-',
    approved: string = '-'
  ) => {
    switch (approval_result) {
      case 'approved':
        return `OK - Se aprueba ya que se ha aprobado la zona en ${approved} aprobaciones`;
      case 'rejected':
        return `RECHAZADO - Se rechaza ya que se tienen ${rejected} rechazos en la zona por motivos inherentes a la zona`;
      case 'warning':
        return `REVISAR - No se tiene experiencia en la zona en los últimos 6 meses`;
      default:
        return '';
    }
  };
  return (
    <>
      {dataGoodZone[0]?.table.appproval_result?.table_data?.length > 0 ? (
        <>
          <ContainerComment>
            <div className="title">Comentario: </div>
            <div className="subtitle">
              {handleCreateComment(
                dataGoodZone[0]?.table.appproval_result?.approval_result,
                dataGoodZone[0]?.table.appproval_result?.rejected,
                dataGoodZone[0]?.table.appproval_result?.approved
              )}
            </div>
          </ContainerComment>
          <Subtitle>Información para aprobación de zona</Subtitle>
          <InverterTable data={handleOrderData} />
        </>
      ) : (
        <ContainerComment>
          <div className="title">Comentario: </div>
          <div className="subtitle">No se encontraron datos</div>
        </ContainerComment>
      )}
    </>
  );
};

export default DetailGoodZone;
