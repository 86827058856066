import TemplateClean from '../../components/TemplateClean/TemplateClean';
import ContainerZoneValidation from './components/ContainerZoneValidation/ContainerZoneValidation';
import withRoutePage, { RoutePage } from '../../utils/withRoutePage';
import { ContainerZone } from './styles';

const ZoneValidation: RoutePage = () => {
  return (
    <TemplateClean>
      <ContainerZone>
        <ContainerZoneValidation />
      </ContainerZone>
    </TemplateClean>
  );
};

ZoneValidation.requiresAuth = true;

export default withRoutePage(ZoneValidation);
