import styled from 'styled-components';

import {
  scarpaGray800,
  secondaryAA,
  secondaryFont,
} from '../../../../styles/stylesConstants';

export const ZoneActionsContainer = styled.div`
  margin-top: 32px;
`;

export const ZoneActionItem = styled.div`
  display: flex;
  padding: 16px 6px 6px 6px;
  flex-direction: column;
  cursor: pointer;

  p {
    color: ${scarpaGray800};
    margin: 0;
    font-weight: 700;
  }

  .action-info {
    margin-top: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  span {
    font-family: ${secondaryFont};
    color: ${secondaryAA};
    font-weight: 600;
    font-size: 12px;
  }
`;
