import { Modal } from '@mui/material';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import VisNoVis from '../AppliedRules/components/VisNoVis';
import RulesDetail from '../AppliedRules/components/RulesDetail';
import homes from '../../../../assets/icons/homes.svg';
import penEdit from '../../../../assets/icons/penEdit.svg';
import { ContainerV, RulesV } from '../AppliedRules/styles';
import { stringToFloat } from '../../../../utils/transformArray';
import { putRuleZone } from '../../../../apis/selectZone/areas';
import {
  ContainerModal,
  TitleZone,
  TitleChange,
  SeparatorWrapper,
  Separator,
  TextChange,
  ContainerButtons,
} from './styles';

interface IModalEditRules {
  isModalOpen: boolean;
  setIsModalOpen: Function;
  data: any;
  zoneId: any;
  handelModalChangeInfoclick?: any;
  handleGetData?: any;
}

const ModalEditRules: React.FC<IModalEditRules> = ({
  isModalOpen,
  setIsModalOpen,
  data,
  zoneId,
  handelModalChangeInfoclick,
  handleGetData,
}) => {
  const handleUpdateData = async () => {
    const dataToSend = {
      median_zone_id: zoneId,
      validate_column: {
        nvis: {
          price: data?.visData?.max_price,
          ano_antiguedad: data?.visData?.max_antiquity,
        },
        buckets: stringToFloat(data?.buckets),
        precio_alto: data?.noVisData?.max_price,
        precio_bajo: data?.noVisData?.min_price,
        single_house: data?.single_house ? 1 : 0,
        ask_price_top: data?.ask_price_top,
        ano_antiguedad: data?.noVisData?.max_antiquity,
      },
      validate_state: data?.status === 'Fase Cliente' ? 2 : 1,
    };

    try {
      await putRuleZone(dataToSend);
      handelModalChangeInfoclick(true);
      setIsModalOpen(false);
      handleGetData();
    } catch (error) {
      handelModalChangeInfoclick(false);
      console.error(error);
    }
  };

  return (
    <Modal open={isModalOpen} onClose={() => setIsModalOpen}>
      <ContainerModal>
        <CloseRoundedIcon
          className="icon-close"
          onClick={() => setIsModalOpen(false)}
        />
        <TitleChange>
          <img src={penEdit} alt="pen_img" className="image_pen" />
          <div className="title">Cambio de regla</div>
        </TitleChange>
        <TitleZone>
          <img src={homes} alt="area_img" className="image" />
          <div className="title">{data.name}</div>
        </TitleZone>
        <SeparatorWrapper>
          <Separator />
        </SeparatorWrapper>
        <TextChange>
          Estos son los cambios que vas realizar en las reglas de la zona
        </TextChange>
        <ContainerV isModal>
          <VisNoVis
            maxPriceVis={data?.visData?.max_price}
            maxAntiquityVis={data?.visData?.max_antiquity}
            maxPriceNoVis={data?.noVisData?.max_price}
            maxAntiquityNoVis={data?.noVisData?.max_antiquity}
            minPriceNoVis={data?.noVisData?.min_price}
          />
        </ContainerV>
        <RulesV isModal>
          <RulesDetail data={data} />
        </RulesV>
        <ContainerButtons>
          <button onClick={() => setIsModalOpen(false)} className="no-return">
            No, regresar
          </button>
          <button className="yes-save" onClick={handleUpdateData}>
            <span className="text">Si, guardar cambios</span>
          </button>
        </ContainerButtons>
      </ContainerModal>
    </Modal>
  );
};

export default ModalEditRules;
