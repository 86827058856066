import { useNavigate, useLocation } from 'react-router-dom';
import { useAppSelector } from '../../redux/hooks';
import { selectUserFullName } from '../../redux/reducers/userSelectors';
import logoPortalZones from '../../assets/images/tuhabi-logo.svg';
import zoneIcon from '../../assets/icons/zone-icon.svg';
import zoneIconGray from '../../assets/icons/zone-icon-gray.svg';
import HesH from '../../assets/icons/HesH.svg';
import Quotas from '../../assets/icons/QuotasLogo.svg';
import QuotasGray from '../../assets/icons/QuotasLogoGray.svg';
import HesHGray from '../../assets/icons/HesH-gray.svg';
import approvalRulesIcon from '../../assets/icons/approval-rules-icon.svg';
import approvalRulesIconGray from '../../assets/icons/approval-rules-icon-gray.svg';
import house_Check from '../../assets/images/house_Check.svg';
import house_check_purple from '../../assets/images/house_check_purple.svg';

import { ISideBarItems } from './model';
import { SidebarWrapper } from '../LayoutMx/styleMx';

const LayoutSidebarMx = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const userFullName = useAppSelector(selectUserFullName);
  const rol = localStorage.getItem('ROL');

  const handleRedirect = (url: string) => {
    navigate(url);
  };

  const sidebarItemsApprover = [
    {
      id: 5,
      name: 'Aprobaciones',
      url: '/aprobologo',
      image_Selected: house_check_purple,
      image: house_Check,
      selected: false,
    },
  ];

  const sidebarItemsGuru = [
    {
      id: 1,
      name: 'Validación de zona',
      url: '/validacion-de-zona',
      image_Selected: zoneIcon,
      image: zoneIconGray,
      selected: false,
    },
    {
      id: 2,
      name: 'HesH',
      url: '/habi-es-habi',
      image_Selected: HesH,
      image: HesHGray,
      selected: false,
    },
    {
      id: 3,
      name: 'Lógica de cupos',
      url: '/logica-de-cupos',
      image_Selected: Quotas,
      image: QuotasGray,
      selected: false,
    },
    {
      id: 4,
      name: 'Reglas de aprobación',
      url: '/reglas-aprobacion',
      image_Selected: approvalRulesIcon,
      image: approvalRulesIconGray,
      selected: false,
    },
    {
      id: 5,
      name: 'Aprobaciones',
      url: '/aprobologo',
      image_Selected: house_check_purple,
      image: house_Check,
      selected: false,
    },
  ];
  const sidebarItems = rol?.includes('equipo_bo')
    ? sidebarItemsGuru
    : sidebarItemsApprover;

  const handleShowRolName = () => {
    if (rol?.includes('equipo_bo')) {
      return 'GURÚ DE ZONA';
    }

    return 'APROBÓLOGO';
  };

  return (
    <SidebarWrapper>
      <div className="sidebar-container">
        <div className="logo-habi">
          <img src={logoPortalZones} alt="logo portal zonas" />
          <div className="title">Portal Zonas</div>
        </div>
        <div className="user-information flex column">
          <span className="montserrat name">{userFullName}</span>
          <span className="role">{handleShowRolName()}</span>
          <div className="separator-gray-mx" />
          {sidebarItems.map((item: ISideBarItems) => (
            <div
              key={item.id}
              role="button"
              className={
                item.url === location.pathname
                  ? 'sidebar-button-selected '
                  : 'sidebar-button-clean'
              }
              tabIndex={0}
              onClick={() => handleRedirect(item.url)}
              onKeyDown={(ev) => ev.key === 'Enter' && handleRedirect(item.url)}
            >
              <img
                src={
                  item.url === location.pathname
                    ? item.image_Selected
                    : item.image
                }
                alt="icono"
              />
              <span>{item.name}</span>
            </div>
          ))}
        </div>
      </div>
    </SidebarWrapper>
  );
};

export default LayoutSidebarMx;
