// revenueSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface RevenueState {
  probDays?: number;
  revenueCategory?: string;
}

const initialState: RevenueState = {
  probDays: 0,
  revenueCategory: '',
};

const revenueSlice = createSlice({
  name: 'revenue',
  initialState,
  reducers: {
    setRevenueData: (state, action: PayloadAction<RevenueState>) => {
      state.probDays = action.payload.probDays;
      state.revenueCategory = action.payload.revenueCategory;
    },
    setDefaultState: () => {
      return initialState;
    },
  },
});

export const { setRevenueData, setDefaultState } = revenueSlice.actions;
export default revenueSlice.reducer;
