import zonesClient from '../../utils/zonesClient';
import { IGetMarketShareDataProps, IGetMarketShareMedianZone } from './model';

export const FUNCTION_NAME = process.env.REACT_APP_GET_MARKET_SHARE || '';

export const getMarketShareMedianZone = async (
  params: IGetMarketShareMedianZone
) => {
  const { data } = await zonesClient.get<IGetMarketShareDataProps>(
    FUNCTION_NAME,
    { params }
  );

  return data;
};
