import { electricPurple600, white } from '@habitech/shared';
import styled from 'styled-components';

const MapWrapper = styled.div`
  width: 100%;
  min-height: 200px;
  height: 100%;
  position: relative;

  .map-loading {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 4rem;
  }

  .map-container {
    width: 100%;
    min-height: 100%;
    border-radius: 8px;
  }
  .gmnoprint {
    display: none;
  }
`;

export default MapWrapper;

export const WrapperControlZoom = styled.div`
  position: absolute;
  top: 16px;
  right: 16px;
  display: grid;
  gap: 6px;
`;

export const ControlZoom = styled.div`
  height: 35px !important;
  width: 35px !important;
  background-color: ${white} !important;
  box-shadow: 2px 2px 7px rgba(0, 0, 0, 0.17);
  border-radius: 8px;
  display: grid;
  justify-content: center;
  align-items: center;
  color: ${electricPurple600};
  &:hover {
    cursor: pointer;
  }
`;
