import {
  robotoRegular,
  scarpaGray800,
  electricPurple500,
} from '@habitech/shared';
import styled from 'styled-components';
import { borderSelect } from '../../styles/stylesConstants';

const RadioButtonContainer = styled.div`
  padding: 5px 14px;
  margin: 0 1rem;
  width: 200px;
  font-family: ${robotoRegular};
  font-size: 16px;
  color: ${scarpaGray800};
  border: 1px solid ${borderSelect};
  border-radius: 100px;
  .radioButton {
    &.Mui-checked {
      color: ${electricPurple500} !important;
    }
  }
  &.active {
    border: 1px solid ${electricPurple500};
  }
`;

export default RadioButtonContainer;
