import styled from 'styled-components';
import {
  nightBlue500,
  principalFont,
  scarpaGray800,
  secondaryAA,
  white,
  separator,
} from '../../../../styles/stylesConstants';

export const WrapperActualRulesApproval = styled.div`
  background: ${white};
  box-shadow: 0px 0px 1px rgba(48, 49, 51, 0.05),
    0px 2px 4px rgba(48, 49, 51, 0.1);
  border-radius: 8px;
  margin-bottom: 16px;
  display: grid;
  grid-template-columns: repeat(10, 1fr);
  gap: 24px;
`;

export const IconDiscount = styled.div`
  grid-column: 1/3;
  padding: 18px 0 18px 16px;
`;

export const DescriptionArea = styled.div`
  grid-column: 3/7;
  padding: 18px 0 18px 0;
`;

export const Title = styled.div`
  font-family: ${principalFont};
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 20px;
  color: ${nightBlue500};
  margin-bottom: 16px;
`;

export const Indicators = styled.div`
  display: flex;
`;

interface Props {
  last: boolean;
}

export const Indicator = styled.div<Props>`
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: ${secondaryAA};
  display: grid;
  gap: 8px;
  grid-template-columns: auto 1fr;
  padding: 0 8px 0 0;
  margin-right: 8px;
  border-right: ${(props) => (props.last ? '' : `1px solid ${separator}`)};
  .text {
    display: flex;
    white-space: break-spaces;
  }
  b {
    font-family: 'Montserrat';
    font-style: bold;
    font-weight: 700;
    color: ${scarpaGray800};
  }
`;

export const WrapperButton = styled.div`
  grid-column: 7/11;
  padding: 18px 16px 18px 28px;
  display: grid;
  align-items: center;
  button {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 18px;
    text-align: center;
    letter-spacing: 0.01em;
  }
`;

export const ActualRulesApprovalTitle = styled.div`
  font-size: 16px;
  font-weight: 600;
  font-family: ${principalFont};
  color: ${scarpaGray800};
  margin-bottom: 20px;
  margin-top: 30px;
`;
