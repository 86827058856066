// Libraries
import { ChangeEvent, FC } from 'react';
import { Radio } from '@mui/material';

// Styles
import RadioButtonContainer from './styles';

// Interfaces
interface RadioButtonProps {
  radioSelected: string;
  clickRadioButton: (
    event: ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => void;
  value: string;
  label: string;
  name: string;
}

const RadioButton: FC<RadioButtonProps> = ({
  radioSelected,
  clickRadioButton,
  value,
  label,
  name,
}) => {
  return (
    <label htmlFor={value}>
      <RadioButtonContainer
        className={`${radioSelected === value ? 'active' : ''}`}
      >
        <Radio
          checked={radioSelected === value}
          onChange={clickRadioButton}
          value={value}
          name={name}
          inputProps={{ 'aria-label': value }}
          className="radioButton"
          sx={{ '& .MuiSvgIcon-root': { fontSize: 20 } }}
          id={value}
        />
        {label}
      </RadioButtonContainer>
    </label>
  );
};

export default RadioButton;
