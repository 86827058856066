export interface IMyState {
  range: {
    total: number;
    rejected_remo: {
      total: number;
      percentage: number;
    };
    rejected_commite: {
      total: number;
      percentage: number;
    };
    approved: {
      total: number;
      percentage: number;
    };
  };
  month: {
    total: number;
    rejected_remo: {
      total: number;
      percentage: number;
    };
    rejected_commite: {
      total: number;
      percentage: number;
    };
    approved: {
      total: number;
      percentage: number;
    };
  };
  last_date_updated: string;
  success: boolean;
}

const initialStateGraph: IMyState = {
  range: {
    total: 0,
    rejected_remo: {
      total: 0,
      percentage: 0,
    },
    rejected_commite: {
      total: 0,
      percentage: 0,
    },
    approved: {
      total: 0,
      percentage: 0,
    },
  },
  month: {
    total: 0,
    rejected_remo: {
      total: 0,
      percentage: 0,
    },
    rejected_commite: {
      total: 0,
      percentage: 0,
    },
    approved: {
      total: 0,
      percentage: 0,
    },
  },
  last_date_updated: '',
  success: false,
};

export interface TabItem {
  id: number;
  label: string;
}

export interface IValues {
  name: string;
  value: any;
  error: boolean;
}
export interface IRulesValues {
  id: number;
  active: number;
  modified: boolean;
  values: IValues[];
}

export interface RuleValue {
  name: string;
  value: string | null;
}

export interface NewRule {
  id: number;
  active: number;
  fields: Record<string, string | null>;
}

export default initialStateGraph;
