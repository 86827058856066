import zonesClient from '../../utils/zonesClient';

export const getMetropolitanAreaMx = async () => {
  const { data } = await zonesClient.get('get_metropolitan_areas');
  return data;
};
export const getCitiesMx = async () => {
  const { data } = await zonesClient.get('get_cities');
  return data;
};
export const getBigZonesMx = async () => {
  const { data } = await zonesClient.get('get_big_zones');
  return data;
};
export const getMedianZonesMx = async () => {
  const { data } = await zonesClient.get('get_median_zones');
  return data;
};

export const saveDiscountMx = async (body: any) => {
  const { data } = await zonesClient.put('mx/median-zones/discounts', body);
  return data;
};
