import {
  robotoRegular,
  scarpaGray600,
  scarpaGray800,
  skyBlue50,
  skyBlue500,
} from '@habitech/shared';
import styled from 'styled-components';

export const SelectAreaWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  width: 100%;
  .MuiOutlinedInput-root {
    padding: 0px !important;
  }
  .MuiCircularProgress-root {
    margin-right: 40px !important;
  }
  .MuiAutocomplete-input {
    font-size: 14px;
    height: 36px;
    padding: 2px 6px 2px 12px !important;
  }
  .MuiAutocomplete-endAdornment {
    top: 50%;
  }
  .MuiSvgIcon-root {
    width: 2.5rem;
    height: 2.5rem;
  }
`;

export const SelectDescription = styled.div`
  font-family: ${robotoRegular};
  font-size: 14px;
  font-weight: 600;
  margin-right: 64px;
  width: 167px;
  color: ${scarpaGray600};
`;

export const SelectInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  .label-description {
    font-family: ${robotoRegular};
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 4px;
    color: ${scarpaGray800};
  }
  .area-selector {
    width: 300px;
  }
  .container-area {
    display: flex;
    .button-container {
      margin: 0 0 0 8px;
    }
  }
`;

export const SelectedOptionsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 660px;
  margin-top: 16px;
  align-items: center;
  justify-content: start;
`;

export const SelectedOptionItem = styled.div`
  display: flex;
  padding: 4px 8px;
  border: 1px solid ${skyBlue500};
  border-radius: 100px;
  align-items: center;
  background: ${skyBlue50};
  font-family: ${robotoRegular};
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 8px;
  margin-right: 12px;
  color: ${scarpaGray800};
  img {
    margin: 2px 0 0 6px;
    width: 15px;
    height: 15px;
    cursor: pointer;
  }
`;
export const IconButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
`;
