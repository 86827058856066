import { ContainerComment, Subtitle } from '../DetailsRoomsBathrooms/styles';
import { useSelector } from 'react-redux';
import OneDimensionTable from '../OneDimensionTable/OneDimensionTable';
import { handleGenerateComent } from './utils';

const DetailElevatorTypology = () => {
  const stateCards = useSelector((state: any) => state.cardsStore.stateCards);
  const filteredData = stateCards?.filter(
    (item: { id: number }) => item?.id === 10
  );

  return (
    <>
      <ContainerComment>
        <div className="title">Comentario: </div>
        <div className="subtitle">
          {handleGenerateComent(
            filteredData[0].data.approval_result,
            filteredData[0].data.nid,
            filteredData[0].data.elevator,
            filteredData[0].data.median_zone,
            filteredData[0].data.dataTable
          )}
        </div>
      </ContainerComment>
      <Subtitle>Cantidad de ascensores</Subtitle>
      <OneDimensionTable
        data={filteredData[0].data.dataTable}
        pivot="total_count"
        countPivot="num_elevators"
        totalRows={1}
        pivotValue={filteredData[0].data.elevator}
      />
      <Subtitle>Porcentaje de ascensores</Subtitle>
      <OneDimensionTable
        data={filteredData[0].data.dataTable}
        pivot="total_percentage"
        countPivot="num_elevators"
        totalRows={1}
        pivotValue={filteredData[0].data.elevator}
      />
    </>
  );
};

export default DetailElevatorTypology;
