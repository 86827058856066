import { scarpaGray500, scarpaGray800, white } from '@habitech/shared';
import styled from 'styled-components';
import { nightBlue500 } from '../../styles/stylesConstants';

export const WrapperModalNotification = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 999;
  background: ${nightBlue500}80;
  backdrop-filter: blur(5px);
  display: grid;
  justify-content: center;
  align-items: center;
`;

export const WrapperModalContent = styled.div`
  width: 280px;
  position: relative;
  background: ${white};
  border-radius: 8px;
  padding: 56px 24px 40px 24px;
  display: grid;
  .image {
    justify-self: center;
    margin-bottom: 16px;
  }
  .close {
    font-size: 27.32px;
    top: 16.5px;
    position: absolute;
    right: 15.5px;
    color: ${scarpaGray500};
    &:hover {
      cursor: pointer;
    }
  }

  .container-buttons {
    display: flex;
    gap: 8px;
    #retry {
      padding: 12px;
    }
  }
`;

export const Title = styled.div`
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: ${scarpaGray800};
  margin-bottom: 8px;
`;

export const SubTitle = styled.div`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: ${scarpaGray800};
  margin-bottom: 8px;
`;

export const Description = styled.div`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: ${scarpaGray500};
  margin-bottom: 16px;
`;
