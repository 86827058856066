import { TextField, TextfieldCurrency } from '@habitech/keychain-atoms-inputs';
import { useEffect, useState } from 'react';
import PercentagePurple from '../../../../assets/icons/percentagePurple.svg';
import AddLessInput from '../../../../components/AddLessInput/AddLessInput';
import GlobalTab from '../../../../components/Tab/GlobalTab';
import TEXT_SELECT_DECISION, {
  MAX_DISCOUNT,
  MIN_DISCOUNT,
} from './selectDecisionMx.utils';
import { ISelectDecisionMx } from './model';
import {
  ContainerSelectDecision,
  ContainerDiscountToAply,
  ContainerInputFromTo,
  ColumnDescription,
  ContainerButtons,
  ContainerTabs,
  ContainerTap,
  ImageButton,
  LoanPurchaseSaleContainer,
  ButtonLoanPurchaseSale,
  TextTitle,
} from '../../styles';

const SelectDecisionMx = ({
  discountApartment,
  setDiscountApartment,
  discountSingleHouse,
  setDiscountSingleHouse,
  discountCondominiumHouse,
  setDiscountCondominiumHouse,
  discountApartmentTotal,
  setDiscountApartmentTotal,
  discountSingleHouseTotal,
  setDiscountSingleHouseTotal,
  discountCondominiumHouseTotal,
  setDiscountCondominiumHouseTotal,
  rangeFrom,
  setRangeFrom,
  rangeTo,
  setRangeTo,
  typeDiscount,
  setTypeDiscount,
  isloan,
  setIsLoan,
}: ISelectDecisionMx) => {
  const [errorFrom, setErrorFrom] = useState('');
  const [errorTo, setErrorTo] = useState('');
  const errorTotalDiscount = `El valor debe estar entre ${MIN_DISCOUNT}% y ${MAX_DISCOUNT}%`;

  const handleBothItemsChange = (newValue: string) => {
    setRangeFrom(newValue);
  };

  const handleSecondItemChange = (newValue: string) => {
    setRangeTo(newValue);
  };

  useEffect(() => {
    if (rangeFrom.length > 7) {
      setErrorFrom('El valor debe ser menor o igual a 7 dígitos');
    } else {
      setErrorFrom('');
    }

    if (rangeTo.length > 7) {
      setErrorTo('El valor debe ser menor o igual a 7 dígitos');
    } else {
      setErrorTo('');
    }

    if (
      +rangeFrom >= +rangeTo &&
      rangeFrom.length >= 5 &&
      rangeTo.length >= 5
    ) {
      setErrorFrom('El valor "Hasta" debe ser mayor al valor "Desde"');
    }

    if (+rangeFrom < +rangeTo) {
      setErrorFrom('');
    }
  }, [rangeTo, rangeFrom]);

  return (
    <ContainerSelectDecision>
      <ColumnDescription>
        <div className="subtitle">{TEXT_SELECT_DECISION.Subtitle}</div>
      </ColumnDescription>
      <LoanPurchaseSaleContainer>
        <ContainerButtons>
          <ImageButton isDisable={false}>
            <img src={PercentagePurple} alt="percentage" />
            <span>{TEXT_SELECT_DECISION.Btn_text}</span>
          </ImageButton>
        </ContainerButtons>
        <ContainerTap>
          <ButtonLoanPurchaseSale
            onClick={() => setIsLoan(true)}
            data-testid="loan-button"
          >
            <div className={isloan ? 'selected' : 'not-selected'}>
              {TEXT_SELECT_DECISION.Loan}
            </div>
            <div className={isloan ? 'line-selected' : ''} />
          </ButtonLoanPurchaseSale>
          <ButtonLoanPurchaseSale
            onClick={() => setIsLoan(false)}
            data-testid="purchase-button"
          >
            <div className={!isloan ? 'selected' : 'not-selected'}>
              {TEXT_SELECT_DECISION.Purchase_sale}
            </div>
            <div className={!isloan ? 'line-selected' : ''} />
          </ButtonLoanPurchaseSale>
        </ContainerTap>
        <TextTitle>{TEXT_SELECT_DECISION.Title}</TextTitle>
        <ContainerInputFromTo>
          <div className="input-from-to">
            <TextfieldCurrency
              country="mx"
              defaultValue={rangeFrom}
              name="from"
              dataId="text_field_from"
              label="Desde"
              placeHolder="0"
              onChange={(inputname, value) => handleBothItemsChange(value)}
              error={errorFrom !== ''}
              errorMessage={errorFrom}
            />
          </div>
          <div className="input-from-to">
            <TextfieldCurrency
              country="mx"
              defaultValue={rangeTo}
              name="to"
              dataId="text_field_to"
              label="Hasta"
              placeHolder="0"
              onChange={(inputname, value) => handleSecondItemChange(value)}
              error={errorTo !== ''}
              errorMessage={errorTo}
            />
          </div>
        </ContainerInputFromTo>
        <TextTitle>{TEXT_SELECT_DECISION.TitleTwo}</TextTitle>
        <ContainerTabs>
          <GlobalTab
            firstSelected={typeDiscount}
            setFirstSelected={setTypeDiscount}
            titleOne="Sumar o restar"
            titleTwo="Cambiarlo todo"
          />
        </ContainerTabs>
        <TextTitle>
          {typeDiscount ? 'Descuento a editar' : 'Nuevos descuentos a editar'}
        </TextTitle>
        {typeDiscount ? (
          <ContainerDiscountToAply>
            <div className="add-less margin-first">
              <div className="title">Apartamento</div>
              <AddLessInput
                inputValue={discountApartment}
                setInputValue={setDiscountApartment}
              />
            </div>
            <div className="add-less margin-first">
              <div className="title">Casa sola</div>
              <AddLessInput
                inputValue={discountSingleHouse}
                setInputValue={setDiscountSingleHouse}
              />
            </div>
            <div className="add-less">
              <div className="title">Casa en condominio</div>
              <AddLessInput
                inputValue={discountCondominiumHouse}
                setInputValue={setDiscountCondominiumHouse}
              />
            </div>
          </ContainerDiscountToAply>
        ) : (
          <ContainerDiscountToAply>
            <div className=" margin-first">
              <TextField
                value={discountApartmentTotal}
                name="apartment "
                type="number"
                dataId="text_field"
                label={TEXT_SELECT_DECISION.Apartments}
                placeHolder="10%"
                onChange={(inputname, value) =>
                  setDiscountApartmentTotal(value)
                }
                error={
                  +discountApartmentTotal !== 0 &&
                  (+discountApartmentTotal < MIN_DISCOUNT ||
                    +discountApartmentTotal > MAX_DISCOUNT)
                }
                errorMessage={errorTotalDiscount}
              />
            </div>
            <div className=" margin-first">
              <TextField
                value={discountSingleHouseTotal}
                name="house"
                type="number"
                dataId="text_field_two"
                label="Casas solas"
                placeHolder="16%"
                onChange={(inputname, value) =>
                  setDiscountSingleHouseTotal(value)
                }
                error={
                  +discountSingleHouseTotal !== 0 &&
                  (+discountSingleHouseTotal < MIN_DISCOUNT ||
                    +discountSingleHouseTotal > MAX_DISCOUNT)
                }
                errorMessage={errorTotalDiscount}
              />
            </div>
            <div>
              <TextField
                value={discountCondominiumHouseTotal}
                name="house"
                type="number"
                dataId="text_field_three"
                label={TEXT_SELECT_DECISION.Condominium_house}
                placeHolder="16%"
                onChange={(inputname, value) =>
                  setDiscountCondominiumHouseTotal(value)
                }
                error={
                  +discountCondominiumHouseTotal !== 0 &&
                  (+discountCondominiumHouseTotal < MIN_DISCOUNT ||
                    +discountCondominiumHouseTotal > MAX_DISCOUNT)
                }
                errorMessage={errorTotalDiscount}
              />
            </div>
          </ContainerDiscountToAply>
        )}
      </LoanPurchaseSaleContainer>
    </ContainerSelectDecision>
  );
};

export default SelectDecisionMx;
