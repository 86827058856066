import { useNavigate } from 'react-router-dom';
import withRoutePage, { RoutePage } from '../../utils/withRoutePage';

const Initial: RoutePage = () => {
  const navigator = useNavigate();
  const country = localStorage.getItem('country');
  const rol = localStorage.getItem('ROL');

  const handleRenderInitialPage = () => {
    if (rol?.includes('equipo_bo')) {
      if (country === 'MX') {
        navigator('/validacion-de-zona');
      } else {
        navigator('/seleccion-de-zona');
      }
    } else {
      navigator('/aprobologo');
    }
  };

  setTimeout(() => {
    handleRenderInitialPage();
  }, 500);
  return <div data-testid="initial" className="initial" />;
};

Initial.requiresAuth = true;

export default withRoutePage(Initial);
