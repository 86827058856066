import { scarpaGray800 } from '@habitech/shared';
import styled from 'styled-components';

export const WrapperEmptyState = styled.div`
  margin: 82px 0;
  display: grid;
  justify-content: center;
  .content-state {
    display: grid;
    grid-template-columns: 225px auto;
    gap: 54px;
  }
`;

export const DescriptionText = styled.div`
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: ${scarpaGray800};
  align-self: center;
  display: grid;
`;
