import { useEffect, useState } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

import { useAppSelector } from '../../../../redux/hooks';
import LocationIcon from './assets/location.svg';
import mapeIcon from './assets/mape.svg';
import { LineGraphicMapeProps } from './model';

import {
  CustomLegendContainer,
  CustomLegendItem,
  GraphicArea,
  IndicatorColor,
  WrapperGraphicMape,
  InformationWrapper,
  TitleWrapper,
  AreaInformationWrapper,
  AreaInformationText,
  LoaderContainer,
} from './styles';
import { getDictLines, MONTHS_DICT } from './utils';
import { selectZone } from '../../../../redux/reducers/zoneSelectors';
import { getMapeLinePlot } from '../../../../apis/pricing/pricing';
import HabiLoader from '../../../../components/HabiLoader/HabiLoader';
import { selectUserDateRange } from '../../../../redux/reducers/userSelectors';

const LineGraphicMape: React.FC<LineGraphicMapeProps> = ({ city, date }) => {
  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
  );
  const [data, setData] = useState({
    labels: [] as any[],
    datasets: [] as any[],
  });
  const [isLoaded, setIsLoaded] = useState(false);
  const area = useAppSelector(selectZone);
  const userDateRange = useAppSelector(selectUserDateRange);

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
    },
  };

  useEffect(() => {
    const start_date = new Date(userDateRange[0])
      .toISOString()
      .substring(0, 10);
    const end_date = new Date(userDateRange[1]).toISOString().substring(0, 10);
    getMapeLinePlot({
      start_date,
      end_date,
      metropolitan_area_id: area.area.id,
    })
      .then((response) => {
        const newData = response?.data;
        const DICT_EN = MONTHS_DICT.EN;

        newData.labels.map((label: string, i: number) => {
          if (DICT_EN[label.toLowerCase()] !== undefined) {
            newData.labels[i] = DICT_EN[label.toLowerCase()];
          }
        });

        newData.datasets.map(
          (item: { data: Array<number>; label: string }, i: number) => {
            const extraData = getDictLines(item.label);
            newData.datasets[i] = {
              ...newData.datasets[i],
              ...extraData,
            };
          }
        );

        setData(newData);
        setIsLoaded(true);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [date]);

  if (!isLoaded) {
    return (
      <LoaderContainer data-testid="loader">
        <HabiLoader size="small" />
      </LoaderContainer>
    );
  }

  return (
    <WrapperGraphicMape data-testid="line-chart">
      <TitleWrapper>
        <img src={mapeIcon} alt="icono para titulo mape" />
        <span>MAPE (Error Porcentual Absoluto Medio)</span>
      </TitleWrapper>

      <InformationWrapper>
        <AreaInformationWrapper>
          <img src={LocationIcon} alt="pin del mapa" />
          <AreaInformationText>
            <div className="title">{area.area.label}</div>
            <div className="subtitle">Área Metropolitana</div>
          </AreaInformationText>
        </AreaInformationWrapper>

        <CustomLegendContainer>
          {Object.prototype.hasOwnProperty.call(data, 'datasets') &&
            data.datasets.map((legend: any, p) => {
              if (data.datasets[p]) {
                return (
                  <CustomLegendItem key={legend.label}>
                    <IndicatorColor color={legend.backgroundColor} />
                    <div className="text">{legend?.label}</div>
                  </CustomLegendItem>
                );
              }

              return true;
            })}
        </CustomLegendContainer>
      </InformationWrapper>

      <GraphicArea>
        {data.hasOwnProperty('datasets') && (
          <Line height="100" options={options} redraw data={data} />
        )}
      </GraphicArea>
    </WrapperGraphicMape>
  );
};

export default LineGraphicMape;
