import { SetStateAction, useState } from 'react';
import { Button, DropdownButton } from '@habitech/call-to-action';
import AddLessInput from '../../../../components/AddLessInput/AddLessInput';
import {
  FormContainer,
  FormItem,
  LegendRequireFields,
  SectionWrapper,
  SubtitleContent,
  TitleContent,
  PlacesContainer,
  ButtonContainer,
  LabelError,
} from './styles';

interface IPlacesByList {
  sendAction: Function;
  setNoInmuebles: React.Dispatch<SetStateAction<number>>;
  setRevenue: React.Dispatch<SetStateAction<string>>;
  setTypeList: React.Dispatch<SetStateAction<string>>;
  countList: number;
  setCountList: React.Dispatch<SetStateAction<number>>;
  noInmuebles: number;
  typeList: string;
  revenue: string;
}

const PlacesByList: React.FC<IPlacesByList> = ({
  sendAction,
  setNoInmuebles,
  setRevenue,
  setTypeList,
  countList,
  setCountList,
  noInmuebles,
  typeList,
  revenue,
}) => {
  const [errors, setErrors] = useState({
    noInmuebles: '',
    typeList: '',
    countList: '',
  });

  const RangesOptions = [
    { label: 'Menos de 50', value: 50 },
    { label: '51 - 101', value: 51 },
    { label: '101 - 201', value: 101 },
    { label: '201 - 301', value: 201 },
    { label: '301 - 401', value: 301 },
    { label: '401 - 501', value: 401 },
    { label: '501 - 601', value: 501 },
    { label: '601 - 701', value: 601 },
    { label: '701 - 801', value: 701 },
    { label: '801 - 901', value: 801 },
    { label: '901 - 1001', value: 901 },
    { label: 'Más de 1000', value: 1001 },
  ];

  const RevenueOptions = [
    { label: 'General', value: 'G' },
    { label: 'Bone / Basic', value: 'B/B' },
    { label: 'Sub-prime / Regular / Prime', value: 'R/SP/P' },
  ];

  const ListOptions = [
    { label: 'Black list', value: 'blacklist' },
    { label: 'Greylist', value: 'greylist' },
    { label: 'Sin lista', value: 'whithout_list' },
    { label: 'Buen conjunto', value: 'good_complex' },
    { label: 'White list', value: 'whitelist' },
  ];

  const handleNoInmuebles = (value: number) => {
    setNoInmuebles(value);
  };

  const handleRevenue = (value: string) => {
    setRevenue(value);
  };

  const handleTypeList = (value: string) => {
    setTypeList(value);
  };

  const saveUpdateList = () => {
    const currentErrors = errors;

    if (noInmuebles === 0) {
      currentErrors.noInmuebles = 'Este campo es obligatorio';
    } else {
      currentErrors.noInmuebles = '';
    }

    if (typeList === '') {
      currentErrors.typeList = 'Este campo es obligatorio';
    } else {
      currentErrors.typeList = '';
    }

    if (countList === 0) {
      currentErrors.countList = 'Este campo es obligatorio';
    } else {
      currentErrors.countList = '';
    }

    setErrors({ ...currentErrors });

    if (noInmuebles !== 0 && typeList !== '' && countList !== 0) {
      sendAction();
      setErrors({
        noInmuebles: '',
        typeList: '',
        countList: '',
      });
    }
  };

  return (
    <>
      <TitleContent>Edición de cupos por lista</TitleContent>
      <SubtitleContent>
        Selección y edición por tipo de lista
        <div className="content">
          Selecciona los criterios obligatorios para poder modificar los cupos en cada lista.
        </div>
      </SubtitleContent>

      <SectionWrapper>
        <LegendRequireFields>*Campos Obligatorios</LegendRequireFields>
        <FormContainer>
          <FormItem>
            <DropdownButton
              dataId="no-inmuebles"
              size="fluid"
              variant="white"
              label="*No de inmuebles"
              placeholder="Selecciona"
              disabled={false}
              listOptions={RangesOptions}
              setValue={(value) => {
                handleNoInmuebles(Number(value));
              }}
              error={errors.noInmuebles !== ''}
              errorMessage={errors.noInmuebles}
              value={noInmuebles}
            />
          </FormItem>
          <FormItem>
            <DropdownButton
              dataId="revenue"
              size="fluid"
              variant="white"
              label="Revenue"
              placeholder="Selecciona"
              disabled={false}
              listOptions={RevenueOptions}
              setValue={(value) => {
                handleRevenue(String(value));
              }}
              value={revenue}
            />
          </FormItem>
          <FormItem>
            <DropdownButton
              dataId="typeList"
              size="fluid"
              variant="white"
              label="*Tipo de lista"
              placeholder="Selecciona"
              disabled={false}
              listOptions={ListOptions}
              setValue={(value) => {
                handleTypeList(String(value));
              }}
              error={errors.typeList !== ''}
              errorMessage={errors.typeList}
              value={typeList}
            />
          </FormItem>
        </FormContainer>

        <PlacesContainer>
          <div className="label">*Cupos a asignar a la lista</div>
          <AddLessInput
            inputValue={countList}
            setInputValue={setCountList}
            initialValue={0}
            maxValueChange={20}
          />
          {errors.countList !== '' && (
            <LabelError>{errors.countList}</LabelError>
          )}
        </PlacesContainer>
        <ButtonContainer>
          <Button
            dataId="save-list"
            variant="primary"
            size="large"
            onClick={saveUpdateList}
            loading={false}
          >
            Guardar cupos en lista
          </Button>
        </ButtonContainer>
      </SectionWrapper>
    </>
  );
};

export default PlacesByList;
