import { useEffect, useMemo, useState } from 'react';

import HabiLoader from '../../components/HabiLoader/HabiLoader';
import ShoppingDistribution from './components/ShoppingDistribution/ShoppingDistribution';
import InventoryQuality from './components/InventoryQuality/InventoryQuality';
import InventoryQualityMatrix from './components/InventoryQualityMatrix';
import InventoryGraphicFilter from './components/InventoryGraphicFilter/InventoryGraphicFilter';
import ReturnLink from '../../components/ReturnLink';
import EmptyState from '../../components/EmptyState';

import withRoutePage, { RoutePage } from '../../utils/withRoutePage';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { zoneStoreActions } from '../../redux/reducers/zoneReducer';
import { selectUserDateRange } from '../../redux/reducers/userSelectors';
import useInventoryQuality from './hooks/useIntoryQuality';
import { getRevenueInfo } from '../../apis/revenue/revenue';
import getZoneInfo from '../ZoneDetail/services/zone.services';
import RevenueWrapper from './styles';

const Revenue: RoutePage = () => {
  const zoneInfo = useAppSelector((state) => state.zoneStore.zone);
  const userDateRange = useAppSelector(selectUserDateRange);

  const [qualityMonth, setQualityMonth] = useState<[Date, Date?]>([
    userDateRange[0],
    userDateRange[1],
  ]);
  const [revenueDateV2, setRevenueDateV2] = useState<[Date, Date?]>([
    userDateRange[0],
    userDateRange[1],
  ]);

  const [loaded, setLoaded] = useState(false);
  const [loadedShopping, setLoadedShopping] = useState(false);
  const [loadedQuality, setLoadedQuality] = useState(false);
  const [isEmptyData, setIsEmptyData] = useState(false);
  const [category, setCategory] = useState('bought');
  const [selectedZone, setSelectedZone] = useState<number>();
  const dispatch = useAppDispatch();

  const inventoryQuality = useInventoryQuality(
    zoneInfo.metropolitan_area?.id,
    zoneInfo.middleZone?.id,
    setLoadedQuality,
    qualityMonth
  );

  const currentZone = useMemo(() => {
    const zmQuality = inventoryQuality.qualityData?.find(
      (zone) => zone.zm_id === zoneInfo.middleZone?.id
    );

    if (!zmQuality) return zmQuality;

    return {
      ...zmQuality,
      market_share: zmQuality.market_share * 100,
      revenue_score: zmQuality.revenue_score * 100,
      purchases_negotation: zmQuality.purchases_negotation * 100,
    };
  }, [inventoryQuality.qualityData, zoneInfo.middleZone?.id]);

  const selectZone = useMemo(() => {
    const zmQuality = inventoryQuality.qualityData?.find(
      (zone) => zone.zm_id === selectedZone
    );

    if (!zmQuality) return zmQuality;

    return {
      ...zmQuality,
      market_share: zmQuality.market_share * 100,
      revenue_score: zmQuality.revenue_score * 100,
      purchases_negotation: zmQuality.purchases_negotation * 100,
    };
  }, [inventoryQuality.qualityData, selectedZone]);

  useEffect(() => {
    if (userDateRange) {
      setQualityMonth([userDateRange[0], userDateRange[1]]);
      setRevenueDateV2([userDateRange[0], userDateRange[1]]);
    }
  }, [userDateRange]);

  useEffect(() => {
    if (!zoneInfo.middleZone?.id || !zoneInfo.metropolitan_area?.id) return;
    const newZone = { ...zoneInfo };
    setLoadedShopping(false);

    getRevenueInfo({
      median_zone_id: zoneInfo.middleZone.id,
      metropolitan_area_id: zoneInfo.metropolitan_area.id,
      date: revenueDateV2,
      type: category,
    })
      .then((response) => {
        newZone.revenue = response.data;

        getZoneInfo(zoneInfo.middleZone?.id).then((responseInfo) => {
          newZone.middleZone = {
            ...zoneInfo.middleZone,
            ...responseInfo.data,
          };

          dispatch(zoneStoreActions.updateZone(newZone));
        });

        setLoadedShopping(true);
        setIsEmptyData(false);
      })
      .catch(() => {
        setLoadedShopping(true);
        setIsEmptyData(true);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dispatch,
    category,
    zoneInfo.middleZone.id,
    zoneInfo.metropolitan_area.id,
    revenueDateV2,
  ]);

  useEffect(() => {
    setLoaded(true);
  }, []);

  if (!loaded && isEmptyData) {
    return (
      <EmptyState
        title={`Jumm... no encontramos información de revenue para la zona ${zoneInfo.middleZone.label}.`}
        body="Es probable que la zona no cuente con información de revenue, puedes probar cambiando la zona que seleccionaste para obtener resultados."
        footer="Sí identificas que es un error del portal, reportarlo al equipo de Spacestation."
      />
    );
  }

  if (!loaded) {
    return (
      <div className="flex justify mt-20">
        <HabiLoader size="small" />
      </div>
    );
  }

  return (
    <RevenueWrapper>
      <ReturnLink
        text="Regresar a análisis de zona"
        link="/portal/analisis-de-zona"
      />

      <h2 className="section-title">Composición de revenue</h2>

      <div className="section-container">
        <ShoppingDistribution
          date={revenueDateV2}
          onChangeDate={setRevenueDateV2}
          onChangeCategory={setCategory}
          category={category}
          isEmptyData={isEmptyData}
          loaded={loadedShopping}
        />

        {currentZone && <InventoryQuality middleZone={currentZone} showTitle />}

        <InventoryGraphicFilter
          month={qualityMonth}
          maxMarketShare={inventoryQuality.maxMarketShare}
          setMonth={setQualityMonth}
        />

        {selectZone && <InventoryQuality middleZone={selectZone} />}

        <InventoryQualityMatrix
          data={inventoryQuality.data}
          hasError={inventoryQuality.hasError}
          maxPurchasesNegotiation={inventoryQuality.maxPurchasesNegotiation}
          onClickZone={setSelectedZone}
          loadedQuality={loadedQuality}
        />
      </div>
    </RevenueWrapper>
  );
};

Revenue.requiresAuth = true;

export default withRoutePage(Revenue);
