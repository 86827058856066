import axios from 'axios';
import globalInterceptor from './globalInterceptor';
import { TOKEN } from '../shared/constants';
const country = localStorage.getItem('country');
const CONTENT_TYPE = 'application/json';

const headers = {
  'x-api-key': process.env.REACT_APP_ZONES_API_KEY!,
  'Content-Type': CONTENT_TYPE,
};

const headersContainer = {
  'x-api-key': process.env.REACT_APP_ZONES_API_KEY_CONTAINER!,
  'Content-Type': CONTENT_TYPE,
  Authorization: `Bearer ${TOKEN?.IdToken}`,
};

const zonesClient = axios.create({
  baseURL: process.env.REACT_APP_ZONES_API_URL,
  headers,
});

export const client = axios.create({
  baseURL: process.env.REACT_APP_ZONES_API_URL,
  headers: {
    'x-api-key': process.env.REACT_APP_ZONES_API_KEY!,
    'Content-Type': CONTENT_TYPE,
    Authorization: `Bearer ${TOKEN?.IdToken}`,
  },
});

export const zonesContainer = axios.create({
  baseURL: process.env.REACT_APP_ZONES_API_URL_CONTAINER,
  headers: headersContainer,
});
export const EspecialLambdaClient = axios.create({
  baseURL: process.env.REACT_APP_ZONES_API_URL_ESPECIAL,
  headers: {
    'Content-Type': CONTENT_TYPE,
  },
});

export const historicalDealsClient = axios.create({
  baseURL:
    country === 'CO'
      ? process.env.REACT_APP_HISTORICAL_DEALS_URL
      : process.env.REACT_APP_HISTORICAL_DEALS_URL_MX,
  headers: {
    'Content-Type': CONTENT_TYPE,
    'x-api-key':
      country === 'CO'
        ? process.env.REACT_APP_HISTORICAL_DEALS_KEY!
        : process.env.REACT_APP_HISTORICAL_DEALS_KEY_MX!,
  },
});
export const quotasClient = axios.create({
  baseURL:
    country === 'CO'
      ? process.env.REACT_APP_QUOTAS
      : process.env.REACT_APP_QUOTAS_MX,
  headers: {
    'Content-Type': CONTENT_TYPE,
    'x-api-key':
      country === 'CO'
        ? process.env.REACT_APP_QUOTAS_KEY!
        : process.env.REACT_APP_QUOTAS_KEY_MX!,
  },
});

async function handleResponseError(error: any, originalRequest: any) {
  if (
    error?.response?.request?.status === 401 ||
    error?.code === 'ERR_NETWORK'
  ) {
    window.location.replace('/404');
    return new Response(originalRequest);
  }

  return Promise.reject(error);
}

zonesClient.interceptors.request.use(globalInterceptor);

zonesClient.interceptors.response.use(
  response => {
    return response;
  },
  async error => {
    const originalRequest = error.config;
    return handleResponseError(error, originalRequest);
  }
);

export default zonesClient;

export const typologiesClient = axios.create({
  baseURL: process.env.REACT_APP_TYPOLOGIES_API_URL,
  headers: {
    'x-api-key': process.env.REACT_APP_TYPOLOGIES_API_KEY ?? '',
    'Content-Type': CONTENT_TYPE,
  },
});

export const approverClient = axios.create({
  baseURL: process.env.ENV_APPROVER_API_URL,
  headers: {
    'x-api-key': process.env.ENV_APPROVER_API_KEY ?? '',
    'Content-Type': CONTENT_TYPE,
  },
});
