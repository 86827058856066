import { useEffect, useState } from 'react';
import { DropdownButton } from '@habitech/call-to-action';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Checkbox from '@mui/material/Checkbox';
import ListItemText from '@mui/material/ListItemText';
import CheckBoxRoundedIcon from '@mui/icons-material/CheckBoxRounded';
import { TextField } from '@habitech/keychain-atoms-inputs';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import EditSpecificRules from '../EditSpecificRules/EditSpecificRules';
import {
  optionsSingleHouse,
  optionsPhase,
  bucketPricing,
} from './EditGeneralRules.utils';
import {
  ColumnDescription,
  ContainerOne,
  ContainerTwo,
  ContainerThree,
  stylesMultiSelect,
  SeparatorWrapper,
  Separator,
} from './styles';
import { intToString } from '../../../../utils/transformArray';
import IEditGeneralRules from './model';

const EditGeneralRules: React.FC<IEditGeneralRules> = ({
  data,
  firstSelected,
  setFirstSelected,
  setIsModalOpen,
  setDataToModal,
}) => {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { buckets, status, single_house, ask_price_top, vis, no_vis, name } =
    data;
  const [bucketSelected, setbucketSelected] = useState<string[]>([]);

  const [zoneClient, setZoneClient] = useState<any>(
    status === 'Fase Cliente' ? 2 : 1
  );
  const [singleHouse, setSingleHouse] = useState<any>(single_house ? '1' : '0');
  const [askPriceTop, setAskPriceTop] = useState<any>(ask_price_top);
  const [visData, setVisData] = useState({
    max_antiquity: vis?.max_antiquity,
    max_price: vis?.max_price,
  });
  const [noVisData, setNoVisData] = useState({
    max_antiquity: no_vis?.max_antiquity,
    max_price: no_vis?.max_price,
    min_price: no_vis?.min_price,
  });

  const handleCreateNewArray = () => {
    const newBucket = intToString(buckets);
    const newArray: string[] = [];
    newBucket.forEach((element: string) => {
      if (bucketPricing.find((i) => i === element)) {
        newArray.push(element);
      }
    });
    return newArray;
  };

  useEffect(() => {
    const newData = handleCreateNewArray();
    setbucketSelected(newData);
  }, [buckets]);

  const handleChange = (event: SelectChangeEvent<typeof bucketSelected>) => {
    const {
      target: { value },
    } = event;
    setbucketSelected(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value
    );
  };

  const handleSendDataToModal = () => {
    setDataToModal({
      name,
      buckets: bucketSelected,
      visData,
      status: zoneClient === 2 ? 'Fase Cliente' : 'Fase Ventanero',
      single_house: singleHouse === '1',
      ask_price_top: askPriceTop,
      noVisData,
    });
  };

  return (
    <>
      <ColumnDescription>
        <div className="subtitle">Editar reglas generales para la zona</div>
      </ColumnDescription>
      <ContainerOne>
        <div className="space">
          <DropdownButton
            value={zoneClient}
            dataId="zone"
            size="fluid"
            variant="white"
            placeholder="Selecciona"
            listOptions={optionsPhase}
            label="Estado zona mediana"
            setValue={(value) => setZoneClient(value)}
          />
        </div>
        <DropdownButton
          dataId="a"
          size="fluid"
          variant="white"
          placeholder="Selecciona"
          listOptions={[]}
          label="Inmobiliaria"
          setValue={() => { console.log('value') }}
          disabled
        />
      </ContainerOne>
      <ContainerTwo>
        <div className="title">Buckets de pricing</div>
        <div className="space">
          <Select
            labelId="demo-multiple-checkbox-label"
            id="demo-multiple-checkbox"
            multiple
            value={bucketSelected}
            onChange={handleChange}
            displayEmpty
            defaultValue={['none']}
            sx={stylesMultiSelect.select}
            MenuProps={stylesMultiSelect.menu}
            inputProps={{ 'aria-label': 'Without label' }}
            IconComponent={KeyboardArrowDownRoundedIcon}
            renderValue={(selected) => {
              if (selected.length === 0) {
                return <em className="placeholder">Selecciona varios</em>;
              }

              return selected.join(', ');
            }}
          >
            {bucketPricing.map((itName: string) => (
              <MenuItem key={itName} value={itName}>
                <Checkbox
                  sx={stylesMultiSelect.checkbox}
                  checkedIcon={<CheckBoxRoundedIcon />}
                  size="medium"
                  checked={bucketSelected.indexOf(itName) > -1}
                />
                <ListItemText primary={itName} />
              </MenuItem>
            ))}
          </Select>
        </div>
        <div className="input-custom">
          <TextField
            defaultValue={askPriceTop}
            name="price"
            type="number"
            dataId="testing_text_field"
            label="Precio mínimo"
            placeHolder="Escribe el precio"
            onChange={(inputname, value) => setAskPriceTop(value)}
          />
        </div>
      </ContainerTwo>
      <ContainerThree>
        <DropdownButton
          value={singleHouse}
          dataId="singleHouse"
          size="fluid"
          variant="white"
          placeholder="Selecciona"
          listOptions={optionsSingleHouse}
          label="Casas sueltas"
          setValue={(value) => {
            setSingleHouse(value);
          }}
        />
      </ContainerThree>
      <SeparatorWrapper>
        <Separator />
      </SeparatorWrapper>
      <EditSpecificRules
        firstSelected={firstSelected}
        setFirstSelected={setFirstSelected}
        setIsModalOpen={setIsModalOpen}
        data={data}
        visData={visData}
        setVisData={setVisData}
        noVisData={noVisData}
        setNoVisData={setNoVisData}
        handleSendDataToModal={handleSendDataToModal}
      />
    </>
  );
};

export default EditGeneralRules;
