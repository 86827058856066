const dateToParams = (startDate: Date, endDate?: Date) => {
  if (!endDate) {
    return {
      year: startDate.getUTCFullYear(),
      month: startDate.getUTCMonth() + 1,
    };
  }

  // Return the date range in the format YYYY-MM-DD
  return {
    start_date: `${startDate.getUTCFullYear()}-${
      startDate.getUTCMonth() + 1
    }-${startDate.getUTCDate()}`,
    end_date: `${endDate.getUTCFullYear()}-${
      endDate.getUTCMonth() + 1
    }-${endDate.getUTCDate()}`,
  };
};

export default dateToParams;
