import { electricPurple300 } from '@habitech/shared';
import styled from 'styled-components';
import { scarpaGray100 } from '../../styles/stylesConstants';

interface SimpleCardProps {
  padding?: string;
  width?: string | number;
}

const SimpleCard = styled.div<SimpleCardProps>`
  border: 1px solid ${electricPurple300};
  border-radius: 16px;
  padding: ${({ padding }) => padding ?? '8px'};
  width: ${({ width }) => width ?? 'auto'};
  background: white;
  position: relative;
`;

export const SimpleCardAccionable = styled.div<SimpleCardProps>`
  border: 1px solid ${scarpaGray100};
  border-radius: 16px;
  padding: ${({ padding }) => padding ?? '8px'};
  width: ${({ width }) => width ?? 'auto'};
  background: white;
  position: relative;
  filter: drop-shadow(0px 6px 2px rgba(69, 69, 69, 0.01))
    drop-shadow(0px 3px 2px rgba(69, 69, 69, 0.05))
    drop-shadow(0px 1px 1px rgba(69, 69, 69, 0.09))
    drop-shadow(0px 0px 1px rgba(69, 69, 69, 0.1))
    drop-shadow(0px 0px 0px rgba(69, 69, 69, 0.1));
`;

export const SimpleCardMap = styled.div<SimpleCardProps>`
  border: 1px solid ${scarpaGray100};
  border-radius: 16px;
  padding: ${({ padding }) => padding ?? '8px'};
  width: ${({ width }) => width ?? 'auto'};
  background: white;
  position: relative;
  filter: drop-shadow(0px 6px 2px rgba(69, 69, 69, 0.01))
    drop-shadow(0px 3px 2px rgba(69, 69, 69, 0.05))
    drop-shadow(0px 1px 1px rgba(69, 69, 69, 0.09))
    drop-shadow(0px 0px 1px rgba(69, 69, 69, 0.1))
    drop-shadow(0px 0px 0px rgba(69, 69, 69, 0.1));
`;

export default SimpleCard;
