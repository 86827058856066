import { robotoRegular, scarpaGray500, scarpaGray800 } from '@habitech/shared';
import styled from 'styled-components';
import { redWarning } from '../../../../styles/stylesConstants';

export const TitleContent = styled.div`
  grid-column: 1/10;
  font-family: ${robotoRegular};
  font-weight: 600;
  font-size: 16px;
  color: ${scarpaGray800};
  margin-bottom: 30px;
`;

export const SubtitleContent = styled.div`
  grid-column: 1/3;
  font-family: ${robotoRegular};
  font-weight: 600;
  font-size: 14px;
  color: ${scarpaGray800};
  .content {
    font-weight: 400;
    font-size: 14px;
    color: ${scarpaGray500};
    margin-top: 5px;
  }
`;

export const SectionWrapper = styled.div`
  grid-column: 3/10;
  display: flex;
  flex-direction: column;
`;

export const LegendRequireFields = styled.div`
  font-family: ${robotoRegular};
  font-weight: 400;
  font-size: 10px;
  color: ${scarpaGray500};
  margin-bottom: 10px;
`;

export const FormContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const FormItem = styled.div`
  width: 205px;
  margin-right: 22px;
`;

export const PlacesContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 24px;
  .label {
    font-family: ${robotoRegular};
    font-weight: 400;
    font-size: 14px;
    color: ${scarpaGray800};
    margin-bottom: 4px;
  }
`;

export const ButtonContainer = styled.div`
  margin-top: 30px;
`;

export const LabelError = styled.div`
  font-family: ${robotoRegular};
  font-weight: 400;
  font-size: 12px;
  color: ${redWarning};
  margin-top: 4px;
`;
