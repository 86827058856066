import {
  electricPurple600,
  scarpaGray600,
  scarpaGray800,
} from '@habitech/shared';
import styled from 'styled-components';

export const WrapperBehaviourGraphic = styled.div`
  display: grid;
  .category {
    display: inline-flex;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: ${scarpaGray600};
    margin-bottom: 18px;
    align-items: center;

    .indicator {
      width: 19.2px;
      height: 4px;
      background: ${electricPurple600};
      border-radius: 6px;
      margin-right: 6px;
      align-items: center;
    }
  }
`;

export const TitleSection = styled.div`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  color: ${scarpaGray800};
  margin-bottom: 6px;
`;
