import { useEffect, useState } from 'react';
import {
  Paper,
  Table,
  TableBody,
  TableContainer,
  TablePagination,
  TableRow,
} from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import {
  ContainerTable,
  CustomPaginatorContainer,
  StyledDataCell,
  StyledFirstDataCell,
} from '../TwoDimensionalTable/styles';

import { TablePaginationContainer } from './styles';

interface RowData {
  title: any;
  data: any;
}

interface InverterTableProps {
  typeOne?: boolean;
  data: RowData[];
}

const InverterTable: React.FC<InverterTableProps> = ({
  typeOne = true,
  data = [],
}) => {
  const [loading, setLoading] = useState(true);
  const [itsTheSameSet, setItsTheSameSet] = useState([]);
  const [goodZoneData, setGoodZoneData] = useState<any[]>([]);
  const rowsPerPageOptions = [5];
  const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[0]);
  const [page, setPage] = useState(0);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    setGoodZoneData(data);
    setLoading(false);
  }, []);

  useEffect(() => {
    if (!typeOne) {
      const conjuntoData = data.find((item) => item.title === '¿En conjunto?');
      const conjuntoDataArray = conjuntoData ? conjuntoData.data : [];
      const booleanArray = conjuntoDataArray.map(
        (item: string) => item === 'Si'
      );
      setItsTheSameSet(booleanArray);
    }
  }, [goodZoneData]);

  return (
    <ContainerTable>
      <Paper
        style={{
          borderRadius: '6px',
          boxShadow: 'none',
        }}
      >
        {loading && <div>Loading...</div>}
        <TableContainer
          component={Paper}
          data-testid="inverter-table"
          sx={{ borderRadius: '6px 6px 0 0', boxShadow: 'none' }}
        >
          <Table sx={{ minWidth: 650 }} size="small">
            <TableBody>
              {goodZoneData.map((row) => {
                return (
                  <TableRow key={uuidv4()}>
                    <StyledFirstDataCell
                      sx={{ textAlign: 'left !important', width: '144px' }}
                    >
                      {row.title}
                    </StyledFirstDataCell>
                    {row.data
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((item: any, index: any) => {
                        return (
                          <StyledDataCell
                            applyCustomStyle={
                              !typeOne &&
                              itsTheSameSet[index + page * rowsPerPage]
                            }
                            key={item}
                          >
                            {item}
                          </StyledDataCell>
                        );
                      })}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePaginationContainer>
          <CustomPaginatorContainer>
            <div className="results">
              {goodZoneData[0]?.data.length} Resultados
            </div>
            <TablePagination
              rowsPerPageOptions={rowsPerPageOptions}
              component="div"
              count={goodZoneData[0]?.data?.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              labelRowsPerPage="RESULTADOS POR PÁGINA"
              onRowsPerPageChange={handleChangeRowsPerPage}
              labelDisplayedRows={({ from, to, count }) =>
                `${from}-${to} de ${count}`
              }
            />
          </CustomPaginatorContainer>
        </TablePaginationContainer>
      </Paper>
    </ContainerTable>
  );
};

export default InverterTable;
