import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import Paper from '@mui/material/Paper';
import {
  WrapperRulesTable,
  ElementText,
  RuleInfo,
  DeleteButton,
} from './style';
import redTrash from '../../../../assets/icons/red-trash.svg';
import { IRulesTable } from '../../model';

const RulesTable: React.FC<IRulesTable> = ({
  listRules,
  elementOptions,
  todoOptions,
  handleDeleteClick,
  zoneOptions,
  listMa,
  listCities,
}) => {

  return (
    <WrapperRulesTable>
      <TableContainer component={Paper} sx={{ minHeight: '120px' }}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell width="20%">Ciudad y zona</TableCell>
              <TableCell width="15%">Características</TableCell>
              <TableCell width="15%">Que sea</TableCell>
              <TableCell width="15%">Regla </TableCell>
              <TableCell width="15%">Desc en puntos</TableCell>
              <TableCell width="20%" />
            </TableRow>
          </TableHead>

          <TableBody>
            {listRules.map((item, i) => {
              const elementName = elementOptions.find(
                (el) => el.value === item.rule_item
              );

              const todoName = todoOptions.find(
                (el) => el.value === item.rule_condition
              );

              const zoneName = zoneOptions.find((el) => el.value === item.zone);

              let localeName: any;

              if (item.zone === 'ciudad_id') {
                localeName = listCities.find((el) => el.id === item.am_city);
              } else {
                localeName = listMa.find((el) => el.id === item.am_city);
              }

              return (
                <TableRow key={`row-item-${item.id}`}>
                  <TableCell>
                    <ElementText>
                      {item.zone !== '' && (
                        <>
                          <div>{zoneName?.label}</div>
                          <div className="subtitle">{localeName?.label}</div>
                        </>
                      ) ||
                        'General'}
                    </ElementText>
                  </TableCell>
                  <TableCell>
                    <ElementText>{elementName?.label}</ElementText>
                  </TableCell>
                  <TableCell>
                    <RuleInfo>{todoName?.label}</RuleInfo>
                  </TableCell>
                  <TableCell>
                    <RuleInfo>{item.expected_value}</RuleInfo>
                  </TableCell>
                  <TableCell>
                    <RuleInfo>{item.points_discount}</RuleInfo>
                  </TableCell>
                  <TableCell>
                    <DeleteButton onClick={() => handleDeleteClick(item.id)}>
                      <span>Borrar regla</span>
                      <img src={redTrash} alt="" />
                    </DeleteButton>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </WrapperRulesTable>
  );
};

export default RulesTable;
