/* eslint-disable import/no-import-module-exports */
import {
  CombinedState,
  combineReducers,
  configureStore,
} from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import expireReducer from 'redux-persist-expire';
import autoMergeLevel2 from 'redux-persist/es/stateReconciler/autoMergeLevel2';

import userReducer, { UserStore } from './reducers/userReducer';
import zoneReducer, { ZoneStore } from './reducers/zoneReducer';
import alertReducer, { AlertStoreProps } from './reducers/alertReducer';
import searchReducer, { ISearchState } from './reducers/searchSlice';
import approverCardsSlice, {
  IApproverCardsSlice,
} from './reducers/approverCardsSlice';

type Temp2 = CombinedState<{
  userStore: UserStore;
  zoneStore: ZoneStore;
  alertStore: AlertStoreProps;
  cardsStore: IApproverCardsSlice;
  searchStore: ISearchState;
}>;

function configureAppStore() {
  const reducer = combineReducers({
    userStore: userReducer,
    zoneStore: zoneReducer,
    alertStore: alertReducer,
    cardsStore: approverCardsSlice,
    searchStore: searchReducer,
  });

  const persistedReducer = persistReducer<Temp2>(
    {
      key: 'zones',
      storage,
      transforms: [
        expireReducer('userStore', { expireSeconds: 3600 * 4 }),
        expireReducer('zoneStore', { expireSeconds: 3600 * 4 }),
      ],
      stateReconciler: autoMergeLevel2<Temp2>,
    },
    reducer
  );

  const store = configureStore({
    reducer: persistedReducer,
    middleware: [],
  });

  const persistor = persistStore(store);

  if (process.env.REACT_ENV !== 'production' && module?.hot) {
    module.hot.accept(
      ['./reducers/userReducer', './reducers/zoneReducer'],
      () => store.replaceReducer(persistedReducer)
    );
  }

  return { store, persistor };
}

export default configureAppStore;
