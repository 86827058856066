const getStatusText = (
  status: any,
  nid: any,
  percentage: number,
  garages: any,
  type: any,
  medianZone: any
) => {
  let statusText;

  switch (status) {
    case 'approved':
      statusText = `OK - Se aprueba ya que el inmueble con nid ${nid} posee una tipología de garajes presente en un ${
        Number(percentage) % 1 !== 0
          ? Number(percentage).toFixed(1)
          : percentage
      }% de los inmuebles en el barrio (garajes: ${garages}) y no está en servidumbre (tipo: ${
        type || '-'
      }, zona mediana: ${medianZone || '-'})`;
      break;
    case 'warning':
      statusText = `REVISAR - El inmueble con nid ${nid} posee una tipología de garajes presente en un ${
        Number(percentage) % 1 !== 0
          ? Number(percentage).toFixed(1)
          : percentage
      }% de los inmuebles en el barrio (garajes: ${garages}, y no está en servidumbre (tipo: ${
        type || '-'
      }, zona mediana: ${medianZone || '-'})`;
      break;
    case 'rejected':
      statusText = `RECHAZADO - El inmueble con nid ${nid} posee una tipología de garajes presente en un ${
        Number(percentage) % 1 !== 0
          ? Number(percentage).toFixed(1)
          : percentage
      }% de los inmuebles en el barrio y/o está en servidumbre (tipo: ${
        type || '-'
      }, zona mediana: ${medianZone || '-'})`;
      break;
    default:
      statusText = '-';
      break;
  }

  return statusText;
};

export default getStatusText;
