import styled from 'styled-components';
import { scarpaGray500, scarpaGray600, white } from '@habitech/shared';

const WrapperInputAddLess = styled.div`
  display: grid;
  grid-template-columns: 37px 44px 37px;
  border: 1px solid ${scarpaGray500};
  border-radius: 6px;
  height: 40px;
  width: 118px;

  .disabled-more {
    filter: grayscale(1);
  }
  input {
    max-width: 44px;
    text-align: center;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: ${scarpaGray600};
    border-top: none;
    border-right: 1px solid ${scarpaGray500};
    border-bottom: none;
    border-left: 1px solid ${scarpaGray500};
    &:disabled {
      background-color: ${white};
    }
  }

  .less {
    width: 36px;
    height: 40px;
    display: grid;
    border-radius: 6px 0 0 6px;
    justify-content: center;
    background-color: ${white};
    align-items: center;
    font-size: 24px;
    border: none;
    color: ${scarpaGray500};
    &:hover {
      cursor: pointer;
    }
  }
  .add {
    width: 36px;
    height: 40px;
    display: grid;
    justify-content: center;
    align-items: center;
    border: none;
    background-color: ${white};
    border-radius: 0 6px 6px 0;
    color: ${scarpaGray500};
    font-size: 24px;

    &:hover {
      cursor: pointer;
    }
  }
  .positive {
    background-color: ${scarpaGray500};
    border-radius: 0 6px 6px 0;
    width: 33px;
  }
`;

export default WrapperInputAddLess;
