import {
  btnDisable,
  monserratRegular,
  robotoRegular,
  scarpaGray500,
  scarpaGray800,
} from '@habitech/shared';
import styled from 'styled-components';

export const CardMediumContainer = styled.div`
  position: relative;
  display: grid;
  border: 1px solid ${btnDisable};
  grid-column: span 2;
  height: 108px;
  padding: 24px;
  &:hover .hover-buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    .buttons {
      display: flex;
      gap: 8px;
      opacity: none !important;
    }
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
  .title {
    color: ${scarpaGray800};
    font-family: ${monserratRegular};
    font-size: 16px;
    font-weight: 600;
  }
`;

export const BodyContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
`;

export const ItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 170px;
  font-family: ${robotoRegular};
  .title {
    font-size: 14px;
    font-weight: 400;
    color: ${scarpaGray500};
  }
  .value {
    font-size: 24px;
    font-weight: 300;
    color: ${scarpaGray800};
    margin-top: 4px;
  }
  .description {
    max-width: 169px;
    height: 52px;
    font-size: 12px;
    font-weight: 400;
  }
`;

export const HoverButtons = styled.div`
  width: 100%;
  height: 100%;
  background: rgba(37, 33, 41, 0.8);
  position: absolute;
  top: 0px;
  right: 0px;
  display: none;
`;

export const EditedMark = styled.div`
  position: absolute;
  top: 0px;
  right: 0px;
`;
