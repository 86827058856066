import {
  robotoRegular,
  scarpaGray800,
  scarpaGray600,
  white,
  monserratRegular,
} from '@habitech/shared';
import styled from 'styled-components';
import { gray6 } from '../../../../styles/stylesConstants';

export const FunnelContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0 2rem;
`;

const FunnelGraphWrapper = styled.div`
  margin: 30px 2rem 0 2rem;
  .is-black {
    color: ${scarpaGray800};
  }
  .funnel-pipeline-chart-title {
    font-family: ${monserratRegular};
    font-weight: 700;
    color: ${white};
    font-size: 12px;
  }
  .funnel-pipeline-chart-value {
    font-weight: 700;
    font-size: 16px;
    color: ${white};
  }
  .funnel-pipeline-chart {
    clip-path: polygon(0% 0%, 100% 0%, 67% 78%, 66% 95%, 35% 100%, 35% 78%);
  }
`;

export const InfoContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: start;
  align-items: center;
  margin-bottom: 30px;
  img {
    margin-right: 10px;
  }
`;

export const InfoText = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: start;
  flex-direction: column;
  .title {
    font-family: ${monserratRegular};
    font-weight: 600;
    font-size: 14px;
    color: ${scarpaGray600};
  }
  .subtitle {
    font-weight: 400;
    font-size: 12px;
    color: ${gray6};
  }
`;

export const TableWrapper = styled.div`
  table {
    thead {
      tr > th {
        font-family: ${robotoRegular};
        font-style: normal;
        font-weight: 600;
        font-size: 10px;
        line-height: 18px;
        color: ${scarpaGray800};
        padding: 8px;
      }
    }
    tbody {
      tr {
        td {
          font-family: ${robotoRegular};
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          color: ${scarpaGray800};
        }
      }
    }
  }
  .MuiPaper-root {
    box-shadow: none;
  }
`;

export const StepWrapper = styled.div`
  background: ${(props) => props.color};
  padding: 4px 8px;
  color: ${white};
  font-family: ${robotoRegular};
  font-size: 11px;
  font-weight: 600;
  border-radius: 4px;
`;

export default FunnelGraphWrapper;
