import { useNavigate } from 'react-router-dom';
import { IListOptions } from '@habitech/call-to-action/src/lib/dropdown-button/models/dropdown-button.model';
import {
  ColumnDescription,
  ContainerDescriptions,
  ContainerType,
  ContainerCharacteristics,
} from './styles';
import { formatMoney } from '../../../../utils/formatDecimal';
import { getZoneByMatch } from '../../../../apis/searchZones/searchZones';
import area_img from '../../../../assets/images/area.svg';
import rooms_img from '../../../../assets/images/rooms.svg';
import bathtub_img from '../../../../assets/images/bathtub.svg';
import elevator_img from '../../../../assets/images/elevator.svg';
import history_img from '../../../../assets/images/history.svg';
import ask_price_img from '../../../../assets/images/request_quote.svg';
import capitalize from '../../../../utils/capitalize';
import { useAppDispatch } from '../../../../redux/hooks';
import { zoneStoreActions } from '../../../../redux/reducers/zoneReducer';

interface ICharacteristics {
  data: any;
}

export type ZoneDetail = {
  title: string;
  value: string;
  zm_id: number;
  zm_label: string;
  zg_id: number;
  zg_label: string;
  city_id: number;
  city_label: string;
  ma_id: number;
  ma_label: string;
};

const Characteristics: React.FC<ICharacteristics> = ({ data }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const characteristic = [
    {
      id: 1,
      image: area_img,
      title: 'Área',
      value: data?.properties?.area,
    },
    {
      id: 2,
      image: rooms_img,
      title: 'Habitaciones',
      value: data?.properties?.rooms,
    },
    {
      id: 3,
      image: bathtub_img,
      title: 'Baños',
      value: data?.properties?.bathrooms,
    },
    {
      id: 4,
      image: elevator_img,
      title: 'Ascensor',
      value: data?.properties?.elevator ? 'SI' : 'NO',
    },
    {
      id: 5,
      image: history_img,
      title: 'Antigüedad',
      value: `${data?.properties?.antiquity} años`,
    },
    {
      id: 6,
      image: ask_price_img,
      title: 'Ask price',
      value: formatMoney(data?.ask_price),
    },
  ];

  const mapToRedux = (item: IListOptions) => ({
    id: item.value,
    label: item.label,
  });

  const onZoneSelection = (details: ZoneDetail) => {
    const zone = {
      area: mapToRedux({ value: details.ma_id, label: details.ma_label }),
      middleZone: mapToRedux({ value: details.zm_id, label: details.zm_label }),
      metropolitan_area: mapToRedux({
        value: details.ma_id,
        label: details.ma_label,
      }),
      bigZone: mapToRedux({ value: details.zg_id, label: details.zg_label }),
      city: mapToRedux({ value: details.city_id, label: details.city_label }),
    };
    dispatch(zoneStoreActions.updateZone(zone));
    navigate('/portal/analisis-de-zona');
  };

  const handleCallZone = async () => {
    try {
      const response = await getZoneByMatch(data?.data_zone?.median_zone_name);

      if (response) {
        const newData: any = [];
        response.data?.data.forEach((element: any) => {
          newData.push({
            title: `${capitalize(element.zm_label)}, ${element.zg_label}, ${
              element.city_label
            } `,
            value: element.zm_id,
            ...element,
          });
        });
        const dataFilter = newData.filter(
          (item: { zm_id: any }) =>
            item.zm_id === data?.data_zone?.median_zone_id
        );
        onZoneSelection(dataFilter[0]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <ColumnDescription>
        <div className="subtitle">
          Características del inmueble y zona a la que pertenece
        </div>
      </ColumnDescription>

      <ContainerDescriptions>
        <div>
          <div className="title-one">NID del inmueble</div>
          <div className="subtitle-one space-bottom">{data?.nid}</div>
          <div className="title-one">Nombre de la urbanización</div>
          <div className="subtitle-one">{data?.set_name}</div>
        </div>
      </ContainerDescriptions>
      <ContainerType>
        <div className="title-two">Tipo de inmueble</div>
        <div className="subtitle-two space-bottom">{data?.property_type}</div>
        <div className="title-two">Zona a la que pertenece</div>
        <div className="flex-button">
          <div className="subtitle-two ">{data?.data_zone?.big_zone_name}</div>
          <button className="button-show" onClick={handleCallZone}>
            <span className="text">Ver análisis de la zona</span>
          </button>
        </div>
      </ContainerType>
      <ContainerCharacteristics>
        <div className="title">Características de la vivienda</div>
        <div style={{ display: 'flex' }}>
          {characteristic.map((item) => (
            <div className="card-characteristics" key={item.id}>
              <div className="title-card">{item.title}</div>
              <div className="img-value">
                <img src={item.image} alt="area_img" className="image" />
                <div className="value">{item.value}</div>
              </div>
            </div>
          ))}
        </div>
      </ContainerCharacteristics>
    </>
  );
};

export default Characteristics;
