const TEXT_SELECT_DECISION = {
  Subtitle: '¿Qué decisión deseas tomar sobre las zonas?',
  Btn_text: 'Cambio de descuento',
  Loan: 'Préstamo',
  Purchase_sale: 'Compraventa',
  Title:
    ' Selecciona el rango de precios para el cual vamos a hacer el cambio de descuento',
  TitleTwo: 'Tipo de descuento a realizar en las zonas',
  Apartments: 'Apartamentos',
  Single_house: 'Casa sola',
  Condominium_house: 'Casas en condominio',
};

export const MIN_DISCOUNT = 10;
export const MAX_DISCOUNT = 38;

export default TEXT_SELECT_DECISION;
