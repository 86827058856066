export const tabItemsSingular = [
  { id: 0, label: '' },
  { id: 1, label: 'zona mediana' },
  { id: 2, label: 'zona grande' },
  { id: 3, label: 'ciudad' },
  { id: 4, label: 'área metropolitana' },
];
export const tabItemsPlural = [
  { id: 0, label: '' },
  { id: 1, label: 'zonas medianas' },
  { id: 2, label: 'zonas grandes' },
  { id: 3, label: 'ciudades' },
  { id: 4, label: 'áreas metropolitanas' },
];

export const ItemsTabs = [
  { id: 0, label: '' },
  { id: 1, label: 'median_zone' },
  { id: 2, label: 'big_zone' },
  { id: 3, label: 'city' },
  { id: 4, label: 'metropolitan_area' },
];
