export const optionsSingleHouse = [
  { label: 'SI', value: '1' },
  { label: 'NO', value: '0' },
];
// validate_state
export const optionsPhase = [
  { label: 'Fase Ventanero', value: 1 },
  { label: 'Fase Cliente', value: 2 },
];

export const bucketPricing = [
  '1.0',
  '1.1',
  '1.4',
  '4.0',
  '4.1',
  '4.4',
  '5.0',
  '5.1',
  '5.4',
  '6.0',
  '6.1',
  '6.4',
  '9.0',
  '9.1',
  '9.4',
  '10.0',
  '10.1',
  '10.4',
  '11.0',
  '11.1',
  '11.4',
  '12.0',
  '12.1',
];
