import { useCallback, useState } from 'react';
import TemplateClean from '../../components/TemplateClean/TemplateClean';
import withRoutePage, { RoutePage } from '../../utils/withRoutePage';
import MapZones from '../../components/MapZones';
import mask from '../../static/assets/icons/mask.png';
import mask2 from '../../static/assets/icons/mask-2.svg';

import {
  PageTab,
  SelectZoneTabs,
  SelectZoneWrapper,
  ZonePageList,
  ContainerSelectZone,
} from './styles';
import ZoneFormHeader from './components/ZoneFormHeader';
import SelectZoneForm from './components/SelectZoneForm';
import { SelectZoneFormValues } from './hooks/zonesFormController';
import { getZonePolygon } from '../../services/zoneServices';
import SearchZoneInput from './components/SearchZoneInput/SearchZoneinput';
import { DescriptionSelectZone } from './components/ZoneFormHeader/styles';

const SelectZone: RoutePage = () => {
  const [location, setLocation] = useState<any>(null);
  const [polygon, setPolygon] = useState<any>(null);
  const [showMap, setShowMap] = useState(true);
  const [currentTab, setCurrentTab] = useState('busqueda');

  const resolvePolygons = useCallback((formValues: SelectZoneFormValues) => {
    if (formValues.zone?.value) {
      setLocation(null);
      return getZonePolygon('median', formValues.zone?.value as number).then(
        (response) => {
          if (response.success) {
            const newPolygon = {
              zoom: 14,
              data: response.data[0].hasOwnProperty('lng')
                ? response.data
                : response.data[0],
            };
            setPolygon(newPolygon);
          }
        }
      );
    }

    if (formValues.bz?.value) {
      setLocation(null);
      return getZonePolygon('big', formValues.bz.value as number).then(
        (response) => {
          if (response.success) {
            const newPolygon = {
              zoom: 12,
              data: response.data[0].hasOwnProperty('lng')
                ? response.data
                : response.data[0],
            };
            setPolygon(newPolygon);
          }
        }
      );
    }

    if (formValues.city) {
      setPolygon(null);

      if (
        formValues.city.location.latitude !== null ||
        formValues.city.location.longitude !== null
      ) {
        setShowMap(true);
        return setLocation({
          lat: formValues.city?.location.latitude,
          lng: formValues.city?.location.longitude,
        });
      }

      setShowMap(false);
    }

    setPolygon(null);
    return setLocation(null);
  }, []);

  const getPolygonByWordsMatch = (median_zone_id: number) => {
    return getZonePolygon('median', median_zone_id).then((response) => {
      if (response.success) {
        const newPolygon = {
          zoom: 14,
          data: response.data[0].hasOwnProperty('lng')
            ? response.data
            : response.data[0],
        };
        setPolygon(newPolygon);
      }
    });
  };

  return (
    <TemplateClean>
      <ContainerSelectZone>
        <div className="container-habi">
          <SelectZoneWrapper>
            <img src={mask} alt="" className="icon-mask" />
            <img src={mask2} alt="" className="icon-mask-right" />

            <div className="selects-wrapper">
              <ZoneFormHeader />

              <div className="container-button-logo">
                <SelectZoneTabs>
                  <PageTab
                    active={currentTab === 'busqueda'}
                    onClick={() => setCurrentTab('busqueda')}
                  >
                    Búsqueda Exacta
                  </PageTab>

                  <PageTab
                    active={currentTab === 'busqueda_zm'}
                    onClick={() => setCurrentTab('busqueda_zm')}
                  >
                    Búsqueda por zona mediana
                  </PageTab>
                </SelectZoneTabs>

                <ZonePageList>
                  {currentTab === 'busqueda' && (
                    <div className="form-search">
                      <DescriptionSelectZone>
                        <span className="description-text">
                          *Los siguientes campos son obligatorios para consultar
                          la zona.
                        </span>
                      </DescriptionSelectZone>
                      <SelectZoneForm onChange={resolvePolygons} />
                    </div>
                  )}

                  {currentTab === 'busqueda_zm' && (
                    <SearchZoneInput
                      getPolygon={getPolygonByWordsMatch}
                      clearMap={() => {
                        setPolygon(null);
                      }}
                    />
                  )}
                </ZonePageList>
              </div>
            </div>

            <div className="map-wrapper">
              {showMap || polygon !== null ? (
                <MapZones location={location} polygonZone={polygon} />
              ) : (
                <span>
                  Para esta área metropolitana o ciudad todavía no se encuentran
                  definidas las posiciones en el mapa por parte de Habi.
                </span>
              )}
            </div>
          </SelectZoneWrapper>
        </div>
      </ContainerSelectZone>
    </TemplateClean>
  );
};

SelectZone.requiresAuth = true;

export default withRoutePage(SelectZone);
