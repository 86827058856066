import {
  electricPurple500,
  scarpaGray600,
  scarpaGray700,
  scarpaGray800,
} from '@habitech/shared';
import styled from 'styled-components';
import { hintPerano200, textGray3 } from '../../../../styles/stylesConstants';

export const WrapperBehaviorMS = styled.div`
  display: grid;

  .header-graph {
    display: inline-flex;
    .divider {
      background-color: ${hintPerano200};
      border: none;
      height: 56px;
      width: 1px;
      margin: 0 16px;
    }
  }
  .graph-guides {
    display: grid;
    .colors-guides {
      display: inline-flex;
      .category {
        display: inline-flex;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 14px;
        color: ${scarpaGray600};
        margin-bottom: 18px;
        align-items: center;

        .indicator {
          width: 24px;
          height: 4px;
          background: ${electricPurple500};
          border-radius: 6px;
          margin-right: 6px;
          align-items: center;
        }
      }
    }
  }

  .subtitle {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 20px;
    color: ${scarpaGray800};
    margin-bottom: 6px;
  }
`;
export const TitleZone = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 8px;
`;

export const TitleAndSubtitle = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 2px;

  .title {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    display: flex;
    align-items: center;
    color: ${scarpaGray600};
  }

  .subtitle {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: ${textGray3};
  }
`;
export const WrapperChart = styled.div`
  height: 320px;
  display: grid;
  position: relative;
`;
export const CategoryGraph = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  height: 24px;
  gap: 12px;
  margin-right: 43px;

  .color-indicator {
    width: 24px;
    height: 24px;
    border-radius: 4px;
    background: ${(props) => props.color};
  }
  .title {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: ${scarpaGray700};
    margin: auto 0;
  }
`;
