import { FC } from 'react';
import TextField from '@mui/material/TextField';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import { IconButton } from '@mui/material';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { useSelector } from 'react-redux';
import { SearchInputProps } from './model';
import { AddOn, SearchArea, WrapperSearch } from './styles';
import useApprover from '../../pages/Approver/hooks/useApprover';

const SearchInput: FC<SearchInputProps> = ({ placeholder }) => {
  const { handleSearch, handleClear, handleGetApis } = useApprover();
  const search = useSelector((state: any) => state.searchStore.search);

  return (
    <WrapperSearch>
      <div className="title">Buscar NID</div>
      <SearchArea>
        <TextField
          placeholder={placeholder}
          onChange={handleSearch}
          value={search}
          data-testid="search-input"
          InputProps={{
            inputMode: 'numeric',
            endAdornment: search && (
              <IconButton
                id="clear-button"
                data-testid="clear-button"
                className="clear-icon"
                onClick={handleClear}
              >
                <CloseRoundedIcon />
              </IconButton>
            ),
          }}
        />

        <AddOn id="add-on" data-testid="add-on" onClick={() => handleGetApis()}>
          <SearchRoundedIcon className="icon" />
        </AddOn>
      </SearchArea>
    </WrapperSearch>
  );
};

export default SearchInput;
