import { Dispatch } from 'react';
import { SimpleTextField } from '@habitech/keychain-atoms-inputs';
import {
  ColumnDescription,
  ContainerRevenueCategory,
} from '../../../HesH/styles';
import {
  MarketShareContainer,
  MarketShareForm,
  MarketShareWrapper,
} from '../../styles';
import marketShareIcon from '../../../../assets/icons/marketshare-icon.svg';
import { tabItemsPlural, tabItemsSingular } from '../../../HesH/HesH.utils';
import { TabItem } from '../../model';

interface IMarketSharePercentage {
  marketShare: string;
  setMarketShare: Dispatch<React.SetStateAction<string>>;
  minimalRange: any;
  isSingular: boolean;
  tabSelected?: number;
  experienceType: string;
}

const MarketSharePercentage = ({
  marketShare,
  setMarketShare,
  minimalRange,
  isSingular = false,
  tabSelected,
  experienceType,
}: IMarketSharePercentage) => {
  const tabItems = isSingular ? tabItemsSingular : tabItemsPlural;
  const foundTabItem: TabItem | undefined = tabItems.find(
    (item) => item.id === tabSelected
  );

  const handleValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    const regex = /^\d*\.?\d{0,3}$/;

    if (Number(value) >= 0 && Number(value) <= 70 && regex.test(value)) {
      setMarketShare(value);
    }
  };

  const handleGenerateTitle = () => {
    const pluralLabel = isSingular ? '' : 's';
    const label = foundTabItem?.label || '';

    const titleDescription = `Market share actual de la${
      isSingular ? ' ' : pluralLabel
    }  ${label} seleccionada${pluralLabel}`;

    return titleDescription;
  };

  const handleGenerateDescription = () => {
    const { min, max, median } = minimalRange;
    return `Menor : ${+(min * 100) || '0'}%  - Mediana: ${
      +(median * 100) || '0'
    }%  - Mayor: ${+(max * 100) || '0'}% - ${experienceType || ''}`;
  };

  return (
    <ContainerRevenueCategory>
      <ColumnDescription>
        <div className="subtitle">
          Edita porcentaje a modificar del market share
        </div>
      </ColumnDescription>
      <MarketShareWrapper>
        <MarketShareContainer>
          <img src={marketShareIcon} alt="" />
          <div className="info-container">
            <div className="title">{handleGenerateTitle()}</div>
            <div className="subtitle">{handleGenerateDescription()}</div>
          </div>
        </MarketShareContainer>
        <MarketShareForm>
          <SimpleTextField
            name="market"
            label="Nuevo marketshare en decimales"
            onChange={handleValue}
            value={marketShare}
            placeholder="0.08"
            error={false}
            errorMessage="El valor debe ser entre 0% y 100%"
          />
        </MarketShareForm>
      </MarketShareWrapper>
    </ContainerRevenueCategory>
  );
};

export default MarketSharePercentage;
