import {
  useEffect,
  useState,
  useCallback,
  ClassAttributes,
  HTMLAttributes,
} from 'react';
import {
  Autocomplete,
  Checkbox,
  CircularProgress,
  TextField,
} from '@mui/material';
import { Button } from '@habitech/call-to-action';
import CheckBoxOutlineBlank from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBox from '@mui/icons-material/CheckBox';
import {
  electricPurple500,
  error,
  robotoRegular,
  scarpaGray800,
} from '@habitech/shared';
import closeIcon from '../../../../assets/icons/close2.svg';
import downIcon from '../../../../assets/icons/icon-down.svg';
import {
  SelectAreaWrapper,
  SelectDescription,
  SelectInputContainer,
  SelectedOptionItem,
  SelectedOptionsContainer,
  IconButton,
} from './styles';
import { IOption, ISelectArea } from './interfaces';
import capitalize from '../../../../utils/capitalize';

const ListboxComponent = (
  props: JSX.IntrinsicAttributes &
    ClassAttributes<HTMLUListElement> &
    HTMLAttributes<HTMLUListElement>
  // eslint-disable-next-line react/jsx-props-no-spreading
) => <ul {...props} style={{ height: '170px' }} id="listboxContent" />;

const SelectArea = ({
  selectedData,
  loading,
  selectedPlaces,
  setSelectedPlaces,
  buttonProperties = {
    showButtonContinue: false,
    variant: 'primary',
    disabled: false,
  },
  setContinueAction = () => {},
}: ISelectArea) => {
  const [label, setLabel] = useState('');
  const [options, setOptions] = useState<IOption[]>([]);
  const icon = <CheckBoxOutlineBlank fontSize="large" />;
  const checkedIcon = (
    <CheckBox fontSize="large" style={{ color: electricPurple500 }} />
  );

  const handleDeleteClick = (id: number) => {
    const places = [...selectedPlaces];
    const list = places.filter((place) => place.id !== id);
    setSelectedPlaces(list);
  };

  useEffect(() => {
    if (selectedData.success) {
      switch (selectedData.message) {
        case 'Median zones':
          setLabel('Zona mediana');
          break;
        case 'Big zones':
          setLabel('Zona grande');
          break;

        case 'Cities':
          setLabel('Ciudad');
          break;

        case 'Metropolitan areas':
          setLabel('Área metropolitana');
          break;

        default:
          setLabel('');
          break;
      }

      setSelectedPlaces([]);
      setOptions(
        selectedData.data.map((item: any) => {
          const replace = item.label
            .toLowerCase()
            .replace('zona metropolitana', '')
            .trim();
          // eslint-disable-next-line no-param-reassign
          item.label = capitalize(replace);
          return item;
        })
      );
    }
  }, [selectedData]);

  useEffect(() => {
    setTimeout(() => {
      const element: any = document.getElementById('listboxContent');
      const last = selectedPlaces[selectedPlaces.length - 1];

      if (element && last) {
        const li = document.getElementById(`item${last.id}`);

        if (li) {
          const liPosition: any = li.getAttribute('data-id');
          const scroll = liPosition * 40;
          element.scrollTop = scroll - 80;
        }
      }
    }, 50);
  }, [selectedPlaces]);

  const memoizedListboxComponent = useCallback(ListboxComponent, []);
  return (
    <SelectAreaWrapper data-testid="area-selector">
      <SelectDescription>
        Donde se van a aplicar estos cambios
      </SelectDescription>
      <SelectInputContainer>
        <div className="label-description">
          {label || String.fromCharCode(160)}
        </div>
        <div className="container-area">
          <Autocomplete
            multiple
            data-testid="select-area"
            autoSelect={false}
            renderTags={() => null}
            id="checkboxes-tags-options"
            options={options}
            disableCloseOnSelect
            getOptionLabel={(option) => option.label}
            popupIcon={
              <img src={downIcon} alt="down" data-testid="img-open-dropdown" />
            }
            noOptionsText={
              <div
                className="error"
                style={{
                  color: error,
                  fontFamily: robotoRegular,
                  fontSize: '14px',
                }}
              >
                Debe seleccionar un tipo de zona
              </div>
            }
            isOptionEqualToValue={(option, value) => option.id === value.id}
            // eslint-disable-next-line react/no-unstable-nested-components
            ListboxComponent={memoizedListboxComponent}
            renderOption={(props: any, option, { selected }) => {
              return (
                <li
                  {...props}
                  id={`item${String(option.id)}`}
                  data-id={props['data-option-index']}
                  style={{
                    fontSize: '16px',
                    fontFamily: robotoRegular,
                    fontWeight: 400,
                    color: scarpaGray800,
                    height: '40px',
                  }}
                >
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8, width: 20, height: 20 }}
                    checked={selected}
                  />
                  {option.label}
                </li>
              );
            }}
            style={{ width: 300 }}
            value={selectedPlaces}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder={
                  selectedPlaces.length === 0
                    ? 'Selecciona las que consideres'
                    : 'Zonas seleccionadas'
                }
                InputProps={{
                  ...params.InputProps,
                  style: { fontSize: `14px` },
                  endAdornment: (
                    <>
                      {loading ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
              />
            )}
            onChange={(event, values) => {
              setSelectedPlaces(values);
            }}
          />
          {buttonProperties.showButtonContinue && (
            <span className="button-container">
              <Button
                dataId="aplicar"
                size="large"
                disabled={buttonProperties.disabled}
                variant={buttonProperties.variant}
                onClick={() => setContinueAction(true)}
              >
                Aplicar
              </Button>
            </span>
          )}
        </div>
        {selectedPlaces.length > 0 && (
          <SelectedOptionsContainer>
            {selectedPlaces.map((place) => {
              return (
                <SelectedOptionItem key={place.id}>
                  <div>{place.label}</div>
                  <IconButton onClick={() => handleDeleteClick(place.id)}>
                    <img src={closeIcon} alt="" />
                  </IconButton>
                </SelectedOptionItem>
              );
            })}
          </SelectedOptionsContainer>
        )}
      </SelectInputContainer>
    </SelectAreaWrapper>
  );
};

export default SelectArea;
