import styled from 'styled-components';
import { electricPurple800, robotoRegular } from '@habitech/shared';

const DateDisclaimerContainer = styled.div`
  margin-top: 16px;

  img {
    margin-right: 8px;
  }

  .disclaimer-text {
    font-family: ${robotoRegular};
    font-size: 16px;
    font-weight: 600;
    color: ${electricPurple800};
  }

  &.no-margin {
    margin: 0px;
  }
`;

export default DateDisclaimerContainer;
