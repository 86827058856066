import styled from 'styled-components';
import {
  scarpaGray100,
  scarpaGray800,
  robotoRegular,
  skyBlue25,
} from '@habitech/shared';

export const ColumnDescription = styled.div`
  grid-column: 1/3;
  font-family: ${robotoRegular};
  .size {
    width: 130px;
  }
  .subtitle {
    font-family: ${robotoRegular};
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: ${scarpaGray800};
  }
`;

export const ContainerCardDiscount = styled.div`
  grid-column: 3/10;
  padding-bottom: 20px;
  .container-flex {
    background-color: ${skyBlue25};
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }
  .image {
    padding: 25px 22px;
  }
  .title {
    font-family: ${robotoRegular};
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    padding: 5px 30px 5px 30px;
  }
  .subtitle {
    font-family: ${robotoRegular};
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    padding-left: 30px;
    padding-right: 30px;
    justify-content: center;
    display: flex;
  }
  .border-blue {
    border-left: 1px solid #84daff;
    margin: 8px 0;
  }
  .image-low {
    margin-right: 16px;
  }
`;
export const BorderSeparator = styled.div`
  padding-top: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid ${scarpaGray100};
`;
