import { FC } from 'react';

import { TitleSection } from '../ShoppingDistribution/style';
import {
  QualityIndicator,
  TitleAndSubtitle,
  TitleZone,
  WrapperIndicators,
  WrapperInventoryQuality,
} from './styles';
import Check from './assets/check.svg';
import LocationIcon from '../ShoppingDistribution/assets/location.svg';
import { MedianZoneQuality } from '../../types';
import {formatNumberDecimal} from '../../../../utils/formatDecimal';
interface InventoryQualityProps {
  middleZone: MedianZoneQuality & { zm_label?: string };
  showTitle?: boolean;
}

const InventoryQuality: FC<InventoryQualityProps> = ({
  middleZone,
  showTitle,
}) => {
  const marketShare =
    middleZone?.market_share_revenue ?? middleZone?.market_share;
  return (
    <WrapperInventoryQuality>
      {showTitle && (
        <TitleSection className="section-title">
          <img src={Check} alt="" />
          <div>Calidad del inventario comprado</div>
        </TitleSection>
      )}
      <TitleZone>
        <img src={LocationIcon} alt="pin del mapa" />
        <TitleAndSubtitle>
          <div className="title">
            {middleZone.zm_label || middleZone.label_zm}
          </div>
          <div className="subtitle">Zona Mediana</div>
        </TitleAndSubtitle>
      </TitleZone>
      <WrapperIndicators>
        <QualityIndicator>
          Average Market Share <b>{formatNumberDecimal(marketShare) || 0}</b>
        </QualityIndicator>
        <QualityIndicator>
          Puntaje revenue <b>{formatNumberDecimal(middleZone?.revenue_score) || 0}</b>
        </QualityIndicator>
        <QualityIndicator>
          Compras / Gestiones{' '}
          <b>{formatNumberDecimal(middleZone?.purchases_negotation) || 0}</b>
        </QualityIndicator>
        <QualityIndicator>
          Compras totales <b>{middleZone?.sellers || 0}</b>
        </QualityIndicator>
      </WrapperIndicators>
    </WrapperInventoryQuality>
  );
};

export default InventoryQuality;
