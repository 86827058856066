import styled from 'styled-components';
import { monserratRegular, scarpaGray800 } from '@habitech/shared';

const SelectZoneContainer = styled.div`
  display: grid;

  button {
    font-family: ${monserratRegular};
    font-weight: bold;
    font-size: 14px;
  }

  // TODO: This is a hack, we should recommend it to design system team
  div.fluid.habi {
    width: 100%;
  }
`;

export default SelectZoneContainer;

export const DropdownStylesContainer = styled.div`
  display: grid;
  margin-bottom: 20px;
  label {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    color: ${scarpaGray800};
    margin-bottom: 8px;
  }
`;
