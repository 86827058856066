import styled from 'styled-components';
import {
  disabledColor,
  electricPurple500,
  monserratRegular,
  scarpaGray800,
} from '@habitech/shared';

import { gray6 } from '../../../../styles/stylesConstants';

export const TitleSelectZone = styled.h2`
  font-family: ${monserratRegular};
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: ${scarpaGray800};
  margin: 0;

  span {
    font-weight: 900;
  }
`;

export const WelcomeTitle = styled.h4`
  margin-bottom: 9px;
  font-family: ${monserratRegular};
  font-weight: 600;
  font-size: 10px;
  color: ${electricPurple500};
  letter-spacing: 5px;
  text-transform: uppercase;
`;

export const DescriptionSelectZone = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 3px;
  margin-bottom: 12px;

  font-family: 'Roboto';
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 18px;
  color: ${gray6};
`;

export const ZoneFormHeaderContainer = styled.div`
  .separator {
    width: 100%;
    height: 1px;
    background: ${disabledColor};
    margin: 11px 0;
  }
`;
