// Libraries
import React, { ReactElement, useEffect } from 'react';

// Styles
import { WrapperToast, PlecaToast } from './styles';

// Images
import alertCheck from '../../../../static/assets/icons/alert-check.png';
import warningIcon from '../ModalDiscount/assets/warning.svg';

export type AlertTypes = 'success' | 'warning' | 'error';
interface AlertToastProps {
  text: string;
  type: AlertTypes;
  showAlert: boolean;
  setShowAlert: Function;
  top?: string;
}

interface TypeInfo {
  img: ReactElement;
  classColor: string;
}

interface AlertType {
  success: TypeInfo;
  warning: TypeInfo;
  error: TypeInfo;
}

const AlertToast: React.FC<AlertToastProps> = ({
  text,
  type,
  showAlert,
  setShowAlert,
  top = '125px',
}) => {
  const alertType: AlertType = {
    success: {
      img: <img src={alertCheck} alt="" />,
      classColor: 'success',
    },
    warning: {
      img: <img src={warningIcon} alt="" />,
      classColor: 'warning',
    },
    error: {
      img: <img src={warningIcon} alt="" />,
      classColor: 'error',
    },
  };

  const typeInfo = alertType[type];

  useEffect(() => {
    if (showAlert) {
      setTimeout(() => {
        setShowAlert();
      }, 20000);
    }
  }, [showAlert]);

  return (
    <>
      {showAlert && (
        <WrapperToast theme={{ top }}>
          <PlecaToast className={typeInfo.classColor}>
            {typeInfo.img}
          </PlecaToast>
          <div className="toast-content">{text}</div>
        </WrapperToast>
      )}
    </>
  );
};

export default AlertToast;
