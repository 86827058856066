import { useEffect, useState } from 'react';

import withRoutePage, { RoutePage } from '../../utils/withRoutePage';
import LineGraphic from './components/LineGraphic/LineGraphic';
import DateDisclaimer from './components/DateDisclaimer';
import { useAppSelector } from '../../redux/hooks';
import { selectUserDateRange } from '../../redux/reducers/userSelectors';
import ReturnLink from '../../components/ReturnLink';

import MarketingLeadsWrapper, {
  ReturnWrapper,
  SectionContainer,
  SubTitleSection,
  TitleSection,
} from './styles';
import EvolutionTitte from '../../components/EvolutionTittle';

const MarketingLeads: RoutePage = () => {
  const userDateRange = useAppSelector(selectUserDateRange);

  const [leadsDateRange, setLeadsDateRange] = useState(userDateRange);

  useEffect(() => {
    setLeadsDateRange(userDateRange);
  }, [userDateRange]);

  return (
    <MarketingLeadsWrapper>
      <ReturnWrapper>
        <ReturnLink
          text="Regresar a análisis de zona"
          link="/portal/analisis-de-zona"
        />
      </ReturnWrapper>

      <TitleSection>Leads de marketing</TitleSection>

      <SubTitleSection>
        Análisis del área metropolitana y zona mediana
      </SubTitleSection>

      <SectionContainer noPadding>
        <div className="grid-container-graph title-graph">
          <EvolutionTitte />
          <DateDisclaimer date={leadsDateRange[1]} />
        </div>
        <div className="grid-container-graph">
          <LineGraphic date={leadsDateRange} />
        </div>
      </SectionContainer>
    </MarketingLeadsWrapper>
  );
};

MarketingLeads.requiresAuth = true;

export default withRoutePage(MarketingLeads);
