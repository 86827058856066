import styled from 'styled-components';
import {
  scarpaGray800,
  robotoRegular,
  white,
  electricPurple500,
  scarpaGray25,
} from '@habitech/shared';

export const ColumnDescription = styled.div`
  grid-column: 1/3;
  font-family: ${robotoRegular};
  .size {
    width: 130px;
  }
  .subtitle {
    width: 120px;
    font-family: ${robotoRegular};
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: ${scarpaGray800};
  }
`;
export const ContainerDescriptions = styled.div`
  grid-column: 3/6;
  .title-one {
    font-family: ${robotoRegular};
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    align-items: center;
    margin-bottom: 20px;
  }
  .subtitle-one {
    font-family: ${robotoRegular};
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
  }
  .space-bottom {
    margin-bottom: 36px;
  }
`;

export const ContainerType = styled.div`
  grid-column: 6/10;
  .title-two {
    font-family: ${robotoRegular};
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    align-items: center;
    margin-bottom: 20px;
  }
  .space-bottom {
    margin-bottom: 36px;
  }
  .subtitle-two {
    font-family: ${robotoRegular};
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
  }
  .flex-button {
    display: flex;
    .button-show {
      margin-left: 32px;
      width: 184px;
      height: 40px;
      left: 964px;
      top: 656px;
      border: 1px solid ${electricPurple500};
      border-radius: 6px;
      background-color: ${white};
      cursor: pointer;
      .text {
        font-family: ${robotoRegular};
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        display: flex;
        align-items: center;
        text-align: center;
        color: ${electricPurple500};
        justify-content: center;
      }
    }
  }
`;
export const ContainerCharacteristics = styled.div`
  grid-column: 3/10;
  .title {
    font-family: ${robotoRegular};
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 12px;
  }
  .card-characteristics {
    background: ${scarpaGray25};
    border-radius: 6px;
    padding: 8px 12px;
    width: max-content;
    margin-right: 16px;
    .title-card {
      font-family: ${robotoRegular};
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 20px;
    }
    .img-value {
      display: flex;
      .image {
        margin-right: 10px;
      }
      .value {
        font-family: ${robotoRegular};
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        display: flex;
        align-items: center;
      }
    }
  }
`;
