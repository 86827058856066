import styled from 'styled-components';

const RejectedReasonsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(10, 1fr);
`;

export const SectionContainer = styled.div`
  grid-column: 1 / 11;
`;

export default RejectedReasonsWrapper;
