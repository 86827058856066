import {
  electricPurple300,
  scarpaGray100,
  scarpaGray500,
  scarpaGray700,
  tomatoCoral200,
  tomatoCoral600,
  white,
} from '@habitech/shared';
import styled from 'styled-components';

export const WrapperHistogram = styled.div`
  display: grid;
  position: relative;
`;

export const IndicatorsZone = styled.div`
  display: grid;
  grid-template-columns: 5fr 5fr;
  gap: 24px;
  margin-bottom: 40px;

  .line {
    margin-right: 16px;
    height: 44px;
    border-radius: 8px;
    width: 1px;
    border: none;
    background-color: ${scarpaGray100};
  }
`;

export const SectionIndicator = styled.div`
  display: grid;
  grid-template-columns: auto auto 1fr;
`;
export const DaysIndicator = styled.div`
  display: grid;
  grid-template-columns: 24px auto;
  gap: 22px;
  margin-right: 16px;

  .icon {
  }
  .content {
    display: grid;
    gap: 18px;
    max-width: 153px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: ${scarpaGray700};

    .value {
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      color: ${scarpaGray500};
    }
  }
`;

export const DaysMediaIndicator = styled.div`
  display: grid;
  grid-template-columns: 24px auto;
  gap: 22px;
  .icon {
  }
  .content {
    display: grid;
    gap: 18px;
    max-width: 130px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: ${scarpaGray700};

    .value {
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      color: ${scarpaGray500};
    }
  }
`;
export const CategoryGraph = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  height: 24px;
  gap: 14px;
  margin-right: 43px;
  margin-top: 6px;

  .color-indicator {
    width: 24px;
    height: 24px;
    border-radius: 4px;
    background: ${(props) => props.color};
  }
  .title {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: #474747;
    margin: auto 0;
  }
`;
export const WrapperChart = styled.div`
  height: 320px;
  display: grid;
  position: relative;
`;

export const Indicator150Days = styled.div`
  background-color: ${white};
  position: absolute;
  top: 24px;
  justify-self: center;
  width: 189px;
  border-radius: 8px;
  filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.15));

  &::after {
    content: '';
    height: 20px;
    width: 20px;
    background-color: ${white};
    bottom: -7px;
    position: absolute;
    justify-self: center;
    left: calc(50% - 10.6px);
    transform: rotate(45deg);
    z-index: -1;
  }
`;
export const Indicator150Title = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  gap: 4px;
  background: ${tomatoCoral200};
  border-radius: 8px 8px 0px 0px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  color: ${tomatoCoral600};
  padding: 10px 0 7px 0;
  .icon {
    margin-top: 3px;
  }
`;

export const Content150Days = styled.div`
  display: grid;
  align-content: center;
  justify-content: center;
  height: 49px;
  button {
    border-width: 1px !important;
  }
  .red-line {
    position: absolute;
    top: calc(100% + 3px);
    // height: 170px;
    width: 1px;
    background-color: ${electricPurple300};
    left: calc(50% - 1px);
    border: none;
  }
`;
