import { IPostDiscountEngine } from '../../pages/RulesEditor/model';
import zonesClient from '../../utils/zonesClient';

const url = process.env.REACT_APP_ZONES_API_URL;

interface IGetInfoProperty {
  nid?: any;
}

const getInfoProperty = async ({ nid }: IGetInfoProperty) => {
  const api = `/discount-engine/${nid}`;
  const endpoint = url + api;

  const { data } = await zonesClient.get<IApiResponse>(endpoint!, {});
  return data;
};

export const getDiscountEngineRules = async () => {
  const pathDiscountEngineRules =
    process.env.REACT_APP_GET_DISCOUNT_ENGINE_RULES;
  const { data } = await zonesClient.get<IApiResponse>(
    pathDiscountEngineRules!,
    {}
  );
  return data;
};

export const postDiscountEngineRules = async (params: IPostDiscountEngine) => {
  const pathDiscountEngineRules =
    process.env.REACT_APP_GET_DISCOUNT_ENGINE_RULES;
  const { data } = await zonesClient.post<IApiResponse>(
    pathDiscountEngineRules!,
    params
  );
  return data;
};

export const deleteDiscountEngineRules = async (id: number) => {
  const pathDiscountEngineRules = `${process.env.REACT_APP_ZONES_API_URL}${process.env.REACT_APP_GET_DISCOUNT_ENGINE_RULES}/${id}`;
  const { data } = await zonesClient.delete<IApiResponse>(
    pathDiscountEngineRules!,
    {}
  );
  return data;
};

export default getInfoProperty;
