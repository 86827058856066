import {
  ColumnDescription,
  ContainerV,
  RulesV,
  EditButtonRuleZone,
} from './styles';
import chevron from '../../../../assets/icons/chevron.svg';
import VisNoVis from './components/VisNoVis';
import RulesDetail from './components/RulesDetail';

interface IAppliedRules {
  showEdit: boolean;
  setShowEdit: any;
  data: any;
}

const AppliedRules: React.FC<IAppliedRules> = ({
  showEdit,
  setShowEdit,
  data,
}) => {
  return (
    <>
      <ColumnDescription>
        <div className="subtitle">Reglas aplicadas en la zona</div>
      </ColumnDescription>
      <ContainerV isModal={false}>
        <VisNoVis
          maxPriceVis={data?.vis?.max_price}
          maxAntiquityVis={data?.vis?.max_antiquity}
          maxPriceNoVis={data?.no_vis?.max_price}
          minPriceNoVis={data?.no_vis?.min_price}
          maxAntiquityNoVis={data?.no_vis?.max_antiquity}
        />
        <EditButtonRuleZone
          onClick={() => {
            setShowEdit(!showEdit);
          }}
        >
          <span>Editar reglas de la zona</span>
          <img src={chevron} alt="arrow" />
        </EditButtonRuleZone>
      </ContainerV>
      <RulesV isModal={false}>
        <RulesDetail data={data} />
      </RulesV>
    </>
  );
};

export default AppliedRules;
