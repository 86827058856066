import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';
import { TPieChartAntifunnel } from '../../../AntiFunnel/model';

import {
  ItemLegend,
  ItemText,
  LegendColor,
  WrapperGraphic,
  WrapperLegend,
} from './styles';

const Graphic: React.FC<{
  statistic: TPieChartAntifunnel;
}> = ({ statistic }) => {
  ChartJS.register(ArcElement, Tooltip, Legend);
  const DICT_LABELS: Record<string, string> = {
    duplicado: 'Inmueble descartado',
    referido_para_inmobiliaria: 'Referido para inmobiliaria',
    vendido_sales: 'Vendidos',
    rechazó_oferta: 'Rechazó oferta',
    no_contesta_sales: 'No contesta',
    descartado: 'Inmueble descartado',
    no_gestionado: 'No gestionado',
    pendiente_de_contactar: 'Pendiente de contactar',
    no_interesado: 'No interesado después de visita',
    captado_para_inmobiliaria: 'Captado para inmobiliaria',
    cierre___comprado: 'Cierre comprado',
    descartado_por_comité: 'Descartado por comité',
    volver_a_llamar: 'Volver a llamar',
    no_contactable: 'El usuario no se puede contactar',
    stock_para___citas: 'Stock para más citas',
    rechazó_oferta___no_volver_a_llamar: 'Rechazó oferta no volver a llamar',
    interesado: 'Interesado',
    no_interesado_después_visita: 'No interesado después de visita',
    interesado_aplazado: 'Interesado aplazado',
    cita_agendada: 'Cita agendada',
    visita_no_efectuada: 'Visita no efectuada',
    pendiente_respuesta_oferta: 'Pendiente de respuesta para oferta',
    descartado_por_dirección: 'Descartado por dirección',
    pre_comité_validado: 'Pre comité validado',
    visita_efectuada: 'Visita efectuada',
    aceptó_oferta___aplazado: 'Aceptó oferta aplazado',
    aceptó_oferta___pendiente_firma: 'Aceptó oferta pendiente de firma',
    inmueble_aprobado: 'Inmueble aprobado',
    negado_comité_por_dif_de_precios: 'Negado comité por diferencia de precio',
    imposible_enviar_a_pre_comité: 'Imposible enviar a pre comité',
    faltan_docs_verificación: 'Faltan dos verificaciones',
    promesa_en_elaboración: 'Promesa en la elaboración',
    aprobación_comité_final: 'Aprobación comité final',
    aprobación_fase_1: 'Aprobación fase 1',
    otros: 'Otros',
  };

  const list: any = { ...statistic.data };
  const values: any = [];
  const counts: any = [];
  const labels: any = [];
  Object.keys(list).forEach((key) => {
    const position = key.search('count_');

    if (position === -1) {
      values.push(list[key]);
      const filterKey = key.replace('-', '_').replace('+', '_');
      labels.push(DICT_LABELS[filterKey]);
    } else {
      counts.push(list[key]);
    }
  });

  const data = {
    datasets: [
      {
        data: values,
        backgroundColor: statistic.colors,
        borderColor: statistic.colors,
        borderWidth: 1,
        datalabels: {
          display: false,
        },
      },
    ],
  };

  const options = {
    responsive: false,
    tooltips: {
      titleFontStyle: 'bold',
    },
    plugins: {
      legend: {
        display: false,
        maxWidth: 206,
        maxHeight: 206,
        position: 'left',
        labels: {
          font: {
            size: 12,
            family: 'Open Sans',
          },
        },
      },
      tooltip: {
        titleFont: { weight: 'bold' },
        callbacks: {
          label: (context: any) =>
            `${context.label} - ${context.parsed}% (${
              counts[context.dataIndex]
            })`,
        },
      },
    },
  };

  return (
    <WrapperGraphic>
      <WrapperLegend>
        {data?.datasets[0].backgroundColor?.map((color: string, p: number) => {
          return (
            <ItemLegend key={data.datasets[0].data[p]}>
              <LegendColor color={color} />
              <ItemText>
                <div className="text">{labels[p]}</div>
                <div className="value">{data.datasets[0].data[p]}%</div>
              </ItemText>
            </ItemLegend>
          );
        })}
      </WrapperLegend>
      <div className="chart">
        <Pie data={data} options={options as any} width="156" height="156" />
      </div>
    </WrapperGraphic>
  );
};

export default Graphic;
