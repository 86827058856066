import { useEffect, useState } from 'react';
import {
  ContainerHistoricalRange,
  ColumnDescription,
  ContainerCounter,
  CardDescriptionCounter,
  CardContainerInputs,
  ButtonProject,
} from '../../styles';
import checkSquare from '../../../../assets/images/check-square.svg';
import chevronsRight from '../../../../assets/images/chevrons-right.svg';
import {
  tabItemsPlural,
  tabItemsSingular,
  ItemsTabs,
} from '../../../HesH/HesH.utils';
import CustomInput from '../CustomInput/CustomInput';
import { postQuotaProject } from '../../../../apis/Quotas/Quotas';

interface IHistoricaRange {
  isSingular?: boolean;
  tabSelected?: number;
  showProject: boolean;
  setIsShowProject: React.Dispatch<React.SetStateAction<boolean>>;
  historicalRate: any;
  ids: any;
  isDisabledButton: boolean;
  setIsDisabledButton: React.Dispatch<React.SetStateAction<boolean>>;
  valueBadRegular: string;
  setValueBadRegular: React.Dispatch<React.SetStateAction<string>>;
  valueRegularGood: string;
  setValueRegularGood: React.Dispatch<React.SetStateAction<string>>;
}
interface TabItem {
  id: number;
  label: string;
}

const HistoricalRange = ({
  isSingular = false,
  tabSelected,
  showProject,
  setIsShowProject,
  historicalRate,
  ids,
  isDisabledButton,
  setIsDisabledButton,
  valueBadRegular,
  setValueBadRegular,
  valueRegularGood,
  setValueRegularGood,
}: IHistoricaRange) => {
  const [project, setProject] = useState({
    total: 0,
    mala_experiencia: 0,
    experiencia_regular: 0,
    buena_experiencia: 0,
  });
  const tabItems = isSingular ? tabItemsSingular : tabItemsPlural;
  const foundTabItem: TabItem | undefined = tabItems.find(
    (item) => item.id === tabSelected
  );
  const foundItemTab: TabItem | undefined = ItemsTabs.find(
    (item) => item.id === tabSelected
  );
  const filter = foundItemTab?.label || '';
  const label = foundTabItem?.label || '';

  const handleGenerateTitle = () => {
    const pluralLabel = isSingular ? '' : 's';

    const titleDescription = `Conteo actual de la${
      isSingular ? ' ' : pluralLabel
    } zona${isSingular ? ' ' : pluralLabel} en la${
      isSingular ? ' ' : pluralLabel
    } ${label} seleccionada${pluralLabel}`;

    return titleDescription;
  };

  useEffect(() => {
    if (valueBadRegular !== '') {
      const newValue =
        +valueBadRegular === 10 ? +valueBadRegular : +valueBadRegular + 0.1;
      const value = newValue.toFixed(1);
      setValueRegularGood(value.toString());
    }
  }, [valueBadRegular]);

  useEffect(() => {
    if (
      (+valueBadRegular > 0 || +valueRegularGood > 0) &&
      +valueBadRegular <= +valueRegularGood
    ) {
      setIsDisabledButton(false);
    } else {
      setIsDisabledButton(true);
    }
  }, [valueBadRegular, valueRegularGood]);

  const handleGetProject = async () => {
    try {
      const dataToSend = {
        filter_by: filter,
        ids,
        bad: [0, +valueBadRegular],
        regular: [+valueBadRegular, +valueRegularGood],
        good: [+valueRegularGood, 10],
      };
      const data = await postQuotaProject(dataToSend);
      setProject(data);
      setIsShowProject(true);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <ContainerHistoricalRange>
      <ColumnDescription>
        <div className="subtitle">
          Edita el rango de puntaje histórico de negocios
        </div>
      </ColumnDescription>
      <ContainerCounter>
        <CardDescriptionCounter>
          <img src={checkSquare} alt="calculator" height={48} />
          <div>
            <div className="title-description">{handleGenerateTitle()}</div>
            <div className="subtitle-description">
              <div className="zones">
                ZONAS:{' '}
                <span className="value">
                  {' '}
                  {historicalRate ? historicalRate?.total : ''}
                </span>
              </div>
              <div className="border" />
              <div className="good">
                BUENA:{' '}
                <span className="value">
                  {historicalRate ? historicalRate?.buena_experiencia : '0'}
                </span>
              </div>
              <div className="border" />
              <div className="regular">
                REGULAR:{' '}
                <span className="value">
                  {' '}
                  {historicalRate ? historicalRate?.experiencia_regular : '0'}
                </span>
              </div>
              <div className="border" />
              <div className="bad">
                MALA:{' '}
                <span className="value">
                  {' '}
                  {historicalRate ? historicalRate?.mala_experiencia : '0'}
                </span>
              </div>
            </div>
          </div>
        </CardDescriptionCounter>
        <CardContainerInputs>
          <div className="header">
            <div className="firts">Experiencia</div>
            <div className="second">Desde</div>
            <div className="third">
              Hasta <span className="text">(Valor excluyente)</span>
            </div>
          </div>
          <div className="column">
            <div className="firts red-text">Mala</div>
            <div className="second">
              <div className="container-input">
                <CustomInput disabled value="0" />
              </div>
            </div>
            <div className="third">
              <div className="container-input">
                <CustomInput
                  value={valueBadRegular}
                  setValue={setValueBadRegular}
                />
              </div>
            </div>
          </div>
          <div className="column">
            <div className="firts orange-text">Regular</div>
            <div className="second">
              <div className="container-input">
                <CustomInput
                  value={valueBadRegular}
                  setValue={setValueBadRegular}
                />
              </div>
            </div>
            <div className="third">
              <div className="container-input">
                <CustomInput
                  value={valueRegularGood}
                  setValue={setValueRegularGood}
                  limitValue={Number(valueBadRegular) + 0.1}
                />
              </div>
            </div>
          </div>
          <div className="column">
            <div className="firts green-text ">Buena</div>
            <div className="second">
              <div className="container-input">
                <CustomInput
                  value={valueRegularGood}
                  setValue={setValueRegularGood}
                  limitValue={Number(valueBadRegular) + 0.1}
                />
              </div>
            </div>
            <div className="third">
              <div className="container-input">
                <CustomInput disabled value="10" />
              </div>
            </div>
          </div>
        </CardContainerInputs>
        <ButtonProject onClick={handleGetProject} disabled={isDisabledButton}>
          Proyectar conteo
        </ButtonProject>
        {showProject && !isDisabledButton && (
          <CardDescriptionCounter project>
            <img src={chevronsRight} alt="calculator" height={48} />
            <div>
              <div className="title-description">
                Conteo proyectado al guardar los cambios
              </div>
              <div className="subtitle-description">
                <div className="zones">
                  ZONAS: <span className="value"> {project.total} </span>
                </div>
                <div className="border" />
                <div className="good">
                  BUENA:{' '}
                  <span className="value">{project.buena_experiencia}</span>
                </div>
                <div className="border" />
                <div className="regular">
                  REGULAR:{' '}
                  <span className="value">{project.experiencia_regular}</span>
                </div>
                <div className="border" />
                <div className="bad">
                  MALA:{' '}
                  <span className="value">{project.mala_experiencia}</span>
                </div>
              </div>
            </div>
          </CardDescriptionCounter>
        )}
      </ContainerCounter>
    </ContainerHistoricalRange>
  );
};

export default HistoricalRange;
