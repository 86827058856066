import styled from 'styled-components';

import {
  monserratRegular,
  scarpaGray300,
  disabledColor,
  scarpaGray100,
  scarpaGray600,
  electricPurple500,
  skyBlue600,
  turquoise600,
  error,
  white,
  robotoRegular,
  scarpaGray700,
  scarpaGray800,
  skyBlue50,
} from '@habitech/shared';

export const TitleContainer = styled.div`
  display: grid;
  grid-column: 1 / span 10;
  margin: 40px 0 12px 0;
  height: 33px;
  color: ${scarpaGray800};
  font-family: ${monserratRegular};
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
  letter-spacing: -0.3px;
`;

export const ContainerQuotaLogic = styled.div`
  padding: 40px;
  display: grid;
  grid-column: 1 / span 10;
  border-radius: 6px;
  margin: 0 24px 24px 0;
  background: var(--color-night-blue-000, #fff);
  box-shadow: 0px 2px 4px 0px rgba(48, 49, 51, 0.1),
    0px 0px 1px 0px rgba(48, 49, 51, 0.05);
`;
export const Divisor = styled.div`
  display: flex;
  align-items: center;
  border-top: 1px solid ${disabledColor};
  margin: 28px 0;
`;
export const ContainerHistoricalRange = styled.div`
  display: flex;
`;
export const ColumnDescription = styled.div`
  .size {
    width: 130px;
  }
  .subtitle {
    font-family: ${robotoRegular};
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: ${scarpaGray600};
    width: 167px;
    margin-right: 64px;
  }
`;
export const ContainerCounter = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  .title-modify {
    color: ${scarpaGray800};
    font-family: ${robotoRegular};
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    margin-bottom: 16px;
  }
  .container-input {
    width: 212px;
  }

  .button-project {
    color: ${white};
    text-align: center;
    font-family: ${robotoRegular};
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    display: flex;
    width: 207px;
    height: 48px;
    padding: 8px 24px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    flex-shrink: 0;
    border-radius: 6px;
    cursor: pointer;
    margin: 32px 0;
    background: ${electricPurple500};
  }
`;

export const ButtonProject = styled.button`
  text-align: center;
  font-family: ${robotoRegular};
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;
  width: 207px;
  height: 48px;
  padding: 8px 24px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;
  border-radius: 6px;
  cursor: pointer;
  margin: 32px 0;
  color: ${white};
  background: ${(props) =>
    props.disabled ? disabledColor : electricPurple500};
  border: none;
`;

interface CardDescriptionCounterProps {
  project?: boolean;
}

export const CardDescriptionCounter = styled.div<CardDescriptionCounterProps>`
  display: flex;
  max-width: 664px;
  border: ${({ project }) =>
    project
      ? '0.5px solid var(--behavior-neutral-colors-scarpa-gray-100, #E6E5E7)'
      : 'none'};
  border-radius: 8px;
  background: ${({ project }) => (project ? white : skyBlue50)};
  padding: 12px 0 12px 12px;
  margin-bottom: 28px;
  box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.08);
  img {
    margin-right: 20px;
  }
  .title-description {
    color: ${scarpaGray600};
    font-family: ${robotoRegular};
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    margin-bottom: 4px;
  }
  .subtitle-description-minimal {
    color: ${scarpaGray700};
    font-family: ${robotoRegular};
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }
  .subtitle-description {
    width: 544px;
    justify-content: space-between;
    display: flex;
    font-family: ${robotoRegular};
    line-height: 24px;
    font-size: 10px;
    font-style: normal;
    font-weight: 600;
    letter-spacing: 2px;
    text-transform: uppercase;
    .border {
      margin-top: 2.5px;
      background: ${scarpaGray300};
      width: 0.5px;
      height: 19px;
    }
    .value {
      color: ${scarpaGray800};
    }
    .zones {
      color: ${skyBlue600};
    }
    .good {
      color: ${turquoise600};
    }
    .regular {
      color: #f09700;
    }
    .bad {
      color: ${error};
    }
  }
  . .container-input {
    width: 212px;
  }
`;
export const CardContainerInputs = styled.div`
  .header {
    display: flex;
    color: var(--behavior-neutral-colors-scarpa-gray-600, #433e47);
    font-family: ${robotoRegular};
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    border-bottom: 1px solid ${scarpaGray100};
    padding: 0 0 16px 0;
  }
  .column {
    display: flex;
    height: 64px;
    border-bottom: 1px solid ${scarpaGray100};
    align-items: center;
    font-family: ${robotoRegular};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    .container-input {
      width: 96px;
    }
  }
  .red-text {
    color: ${error};
  }
  .orange-text {
    color: #f09700;
  }
  .green-text {
    color: ${turquoise600};
  }
  .firts {
    width: 28%;
  }
  .second {
    width: 36%;
  }
  .third {
    width: 36%;
    .text {
      color: var(--behavior-neutral-colors-scarpa-gray-600, #433e47);
      font-family: ${robotoRegular};
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
    }
  }
`;

export const MarketShareWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-right: 24px;
`;

export const MarketShareContainer = styled.div`
  border-radius: 8px;
  width: 100%;
  background: ${skyBlue50};
  padding: 12px;
  display: flex;
  align-items: center;
  img {
    margin-right: 20px;
  }
  .info-container {
    width: 100%;
    font-family: ${robotoRegular};
    .title {
      font-size: 14px;
      font-weight: 600;
      color: ${scarpaGray800};
      margin-bottom: 8px;
    }
    .subtitle {
      font-size: 16px;
      font-weight: 400;
      color: ${scarpaGray700};
    }
  }
`;

export const MarketShareForm = styled.div`
  margin-top: 24px;
  width: 210px;
  input: {
    height: 40px !important;
  }
`;
