import styled from 'styled-components';

import {
  monserratRegular,
  scarpaGray100,
  scarpaGray600,
  scarpaGray800,
  white,
} from '@habitech/shared';
import { hintPerano200 } from '../../styles/stylesConstants';

export const InventoryWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(10, 1fr);
`;

export const SubSectionTitle = styled.div`
  width: 100%;
  display: flex;
  font-family: ${monserratRegular};
  font-size: 20px;
  color: ${scarpaGray600};
  font-weight: 600;

  img {
    margin-right: 20px;
  }
`;

export const DateContainer = styled.div`
  width: 100%;
  padding-bottom: 12px;
  margin-top: 20px;
  border-bottom: 1px solid ${hintPerano200};
  margin-bottom: 20px;
`;

export const WrapperVersus = styled.div`
  display: grid;
  height: 88px;
  align-items: center;
  position: relative;

  .vs-indicator {
    position: absolute;
    justify-self: center;
    align-self: center;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 51px;
    height: 40px;
    background: ${white};
    border: 1px solid ${scarpaGray100};
    border-radius: 8px;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 18px;
    color: ${scarpaGray800};
  }
  .divider {
    background-color: ${hintPerano200};
    border: none;
    height: 1px;
    width: 100%;
  }
`;
