import styled from 'styled-components';
import {
  disabledColor,
  electricPurple500,
  monserratRegular,
  robotoRegular,
  scarpaGray500,
  scarpaGray600,
  scarpaGray800,
  skyBlue200,
  tomatoCoral500,
  turquoise500,
  white,
} from '@habitech/shared';

export const ContainerBusinessProjection = styled.div`
  display: flex;
  position: relative;
`;
export const ContainerComparison = styled.div`
  display: flex;
`;
export const ContainerComparisonModal = styled.div`
  display: flex;
  margin-bottom: 40px;
`;

export const ContainerCardBusinessProjection = styled.div`
  width: 616px;
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  background: ${white};
  border-radius: 8px;
  border: 1px solid ${disabledColor};
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 24px;
  width: 614px;
  .container-title {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    .title {
      align-self: stretch;
      color: ${scarpaGray800};
      font-family: ${monserratRegular};
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
    }
    .subtitle {
      align-self: stretch;
      color: ${scarpaGray500};
      font-family: ${robotoRegular};
      font-size: 10px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px;
      margin-bottom: 16px;
    }
  }
`;
export const ColumnDescription = styled.div`
  .size {
    width: 130px;
  }
  .subtitle {
    color: ${scarpaGray600};
    font-family: ${robotoRegular};
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    margin-right: 64px;
    width: 167px;
  }
`;
export const ContainerDoughnutGraph = styled.div`
  width: 308px;
  .date-indicator {
    align-items: center;
    color: ${scarpaGray800};
    display: flex;
    font-family: ${monserratRegular};
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    margin-bottom: 8px;
    .value {
      margin: 0 12px;
      font-weight: 400 !important;
    }
    .img-edit-btn {
      background: none;
      border: none;
      padding: 0;
      cursor: pointer;
    }
    .img-edit {
      cursor: pointer;
    }
    .month-range-picker__input {
      font-family: ${monserratRegular} !important;
      font-size: 12px;
      font-weight: 400 !important;
      margin: 0 12px;
      max-width: 140px !important;
      height: 22px;
    }
    .styles__PickerContainer-nwdsad-2 {
      left: unset !important;
      width: 270px !important;
      z-index: 1;
    }
    .styles__PickerMonthsGrid-nwdsad-7 {
      font-weight: 400 !important;
      font-family: ${monserratRegular} !important;
    }
  }
  .values-graphs {
    display: flex;
    .data-info {
      margin-right: 32px;
      .top-values {
        margin-bottom: 12px;
        .value-top {
          color: ${scarpaGray800};
          font-family: ${monserratRegular};
          font-size: 14px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
        }
        .value-top-sub {
          color: ${scarpaGray500};
          font-family: ${robotoRegular};
          font-size: 10px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
      }

      .item-value {
        align-items: center;
        color: ${scarpaGray800};
        display: flex;
        font-family: ${robotoRegular};
        font-size: 10px;
        font-style: normal;
        font-weight: 400;
        justify-content: flex-start;
        line-height: 20px;
        .dark {
          font-weight: 700;
        }
        .point-g {
          width: 12px;
          height: 12px;
          border-radius: 50%;
          margin-right: 8px;
        }
        .turquoise {
          background-color: ${turquoise500};
        }
        .tomato {
          background-color: ${tomatoCoral500};
        }
        .sky-blue {
          background-color: ${skyBlue200};
        }
      }
    }
    .graph {
      padding-bottom: 10px;
    }
  }
  .img-ship {
    height: 100px;
  }
`;

export const ModalWrapper = styled.div`
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 200;
`;

export const ModalContent = styled.div`
  align-items: flex-start;
  background: white;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  padding: 20px;
  padding: 24px 32px;
  width: 638px;
  .title {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;
    .title-up {
      color: ${scarpaGray800};
      font-family: ${monserratRegular};
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
    }
    .title-bottom {
      color: ${scarpaGray500};
      font-family: ${robotoRegular};
      font-size: 10px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px;
    }
  }
`;
export const RightAlignedIcon = styled.div`
  margin-left: auto;
`;

export const CloseButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  font-size: 24px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-left: auto;
  .save {
    align-items: center;
    background: ${electricPurple500};
    border-radius: 6px;
    border: 1px solid ${electricPurple500};
    color: ${white};
    display: flex;
    font-family: ${robotoRegular};
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    gap: 8px;
    height: 48px;
    justify-content: center;
    line-height: normal;
    margin-left: 8px;
    padding: 8px 24px;
    text-align: center;
    width: 207px;
  }
  .back {
    align-items: center;
    background: ${white};
    border-radius: 6px;
    border: 1px solid ${electricPurple500};
    color: ${electricPurple500};
    display: flex;
    font-family: ${robotoRegular};
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    gap: 8px;
    height: 48px;
    justify-content: center;
    line-height: normal;
    padding: 8px 24px;
    text-align: center;
    width: 207px;
  }
`;
