import styled from 'styled-components';

export const RuleFormContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 697px;
  .simpleInput {
    height: 40px;
  }
  .placeholder {
    line-height: 18px !important;
  }
  .input-simple-container {
    min-width: 200px;
    margin-right: 32px;
  }
`;

export const RuleFormItem = styled.div`
  display: flex;
`;
