import styled from 'styled-components';
import { monserratRegular, robotoRegular } from '@habitech/shared';

export const ContainerComment = styled.div`
  display: flex;
  padding: 16px 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  border-radius: 6px;
  background: var(--habi-Background-Light_blue, #f5fcff);
  margin: 24px 0;
  .title {
    color: var(--font-habi-pcom-main-aaa, #252129);

    font-family: ${robotoRegular};
    font-size: 10px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 2px;
    text-transform: uppercase;
  }
  .subtitle {
    color: var(--font-habi-pcom-secundary-aa, #6d6970);
    font-family: ${robotoRegular};
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }
`;
export const Subtitle = styled.div`
  color: var(--font-habi-pcom-main-aaa, #252129);
  font-family: ${monserratRegular};
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin: 16px 0px;
`;
