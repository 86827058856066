import { useEffect, useRef, useState } from 'react';
import { Toggle } from '@habitech/selectors';
import { styled } from '@mui/material/styles';
import { Tooltip, TooltipProps, tooltipClasses } from '@mui/material';
import {
  disabledBorder,
  robotoRegular,
  scarpaGray500,
  white,
} from '@habitech/shared';
import infoIcon from '../../../../../assets/icons/info.svg';
import editIcon from '../../../../../assets/icons/edit2.svg';
import lockIcon from '../../../../../assets/icons/lock.svg';
import { RulesItem, RuleInformation, RuleTitle, RuleType } from '../styles';
import capitalize from '../../../../../utils/capitalize';
import { getRuleDescriptionById } from '../../../../../apis/approvalRules/approvalRules';
import {
  RULES_VALIDATION_CO,
  RULES_VALIDATION_MX,
} from '../../RuleSidebarForm/utils';
import Description from './components/Description';

interface IRuleItem {
  rules: any[];
  rule: any;
  index: number;
  description: any;
  handleSaveForm: (id: number, status: boolean) => void;
  handleShowRuleForm: (rule: any) => void;
  tabSelected: number;
  timer: boolean;
}

const RuleItem = ({
  rules,
  rule,
  index,
  handleSaveForm,
  handleShowRuleForm,
  description,
  tabSelected,
  timer,
}: IRuleItem) => {
  const ruleZones = [2, 3, 4];
  const [selected, setSelected] = useState(true);
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState({
    range_affected: 0,
    month_affected: 0,
    zones_affected: 0,
    success: true,
  });
  const country = localStorage.getItem('country');
  const RULES_VALIDATION =
    country === 'MX' ? RULES_VALIDATION_MX : RULES_VALIDATION_CO;

  const handleGetDescription = async () => {
    setLoading(true);

    try {
      const newData = { ...description, id: rule.id };
      const data = await getRuleDescriptionById(newData);
      setValues(data);
      setLoading(false);
    } catch (error: any) {
      if (error.response && error.response.status === 404) {
        setValues({
          range_affected: 0,
          month_affected: 0,
          zones_affected: 0,
          success: false,
        });
        setLoading(false);
      } else {
        console.error('Otro tipo de error:', error.message);
      }
    }
  };

  const prevDescriptionRef = useRef({
    start_date: null,
    end_date: null,
    ids: null,
  });

  useEffect(() => {
    const prevDescription = prevDescriptionRef.current;

    if (
      timer &&
      (description.start_date !== prevDescription.start_date ||
        description.end_date !== prevDescription.end_date)
    ) {
      handleGetDescription();
    }

    prevDescriptionRef.current = description;
  }, [description.start_date, description.end_date, timer]);

  const HabiTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: scarpaGray500,
      color: white,
      border: `1px solid ${disabledBorder}`,
      fontSize: 12,
      padding: '12px 16px',
      fontFamily: robotoRegular,
      fontWeight: '400',
      width: 348,
      borderRadius: '8px',
    },
  }));
  const classNames = [
    index === 0 && 'first',
    index > 0 && index < rules.length && 'middle',
    index + 1 === rules.length && 'last',
    !selected && rule.rule_type === 'on_off' && 'disabled',
  ]
    .filter(Boolean)
    .join(' ');

  return (
    <RulesItem className={classNames}>
      <HabiTooltip title={rule.description} placement="top-start">
        <img src={infoIcon} className="info" alt="" />
      </HabiTooltip>
      <RuleInformation>
        <RuleTitle>
          <div className="title">{capitalize(rule.name)}.</div>
          <Description loading={loading} values={values} />
        </RuleTitle>
        <RuleType>
          {rule.rule_type === 'on_off' && (
            <Toggle
              dataId="toggle"
              name={`toggle${index}`}
              value=""
              selected={selected}
              onToggle={(value, status) => {
                setSelected(status);
                handleSaveForm(rule.id, status);
              }}
            />
          )}
          {rule.rule_type === 'editable' &&
            (rule.name !== RULES_VALIDATION.risk_zone ||
              !ruleZones.includes(tabSelected)) && (
              <div
                className="edit"
                onClick={() => {
                  handleShowRuleForm(rule);
                }}
                aria-hidden="true"
              >
                <span>Editar regla</span>
                <img src={editIcon} width={24} height={24} alt="" />
              </div>
            )}
          {(rule.rule_type === 'blocked' ||
            (ruleZones.includes(tabSelected) &&
              rule.name === RULES_VALIDATION.risk_zone)) && (
            <img src={lockIcon} width={24} height={24} alt="" />
          )}
        </RuleType>
      </RuleInformation>
    </RulesItem>
  );
};

export default RuleItem;
