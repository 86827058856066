import React, { useEffect, useState, useRef, Fragment } from 'react';
import Paper from '@mui/material/Paper';
import {
  styled,
  Switch,
  SwitchProps,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import Pagination from '@mui/material/Pagination';
import { electricPurple500 } from '@habitech/shared';

import {
  getMedianZones,
  getListDetail,
  patchList /* putList */,
} from '../../../../apis/politics/politics';
import { useAppSelector, useAppDispatch } from '../../../../redux/hooks';
import { alertStoreActions } from '../../../../redux/reducers/alertReducer';

// Components
import HabiLoader from '../../../../components/HabiLoader/HabiLoader';

// Images
import searchButton from '../../../../assets/icons/search-button.png';
import closeImage from '../../../../assets/icons/close.svg';
import editImage from '../../../../assets/icons/edit.svg';
import ModalEditList from '../ModalEditList/ModalEditList';
import {
  WrapperAssignListToSet,
  PaginationWrapper,
  SearchContent,
  TableLoader,
  TableWrapper,
  ColumnDescription,
  SeparatorWrapper,
  Separator,
  ZoneInfoTable,
  CurrentListWrapper,
  SwitchWrapper,
  EditButton,
  PaginationInfo,
} from './styles';
import { WrapperUpdateLogic } from '../UpdateLogic/styles';
import capitalize from '../../../../utils/capitalize';
import { separator } from '../../../../styles/stylesConstants';

interface ZoneUpdateProps {
  zone_id: number;
  type_list: string;
  lote_name: string;
  reason: string;
  note: string;
  rotation: number;
  user_email: string;
}

export interface AlertProps {
  type: 'success' | 'warning' | 'error';
  text: string;
  showAlert: boolean;
}

type RowTable = {
  lote_name: string;
  current_list: string;
  rotation: number;
  lote_id: number;
};

const AssignListToSet: React.FC = () => {
  const [medianZones, setMedianZones] = useState<any>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [test, setTest] = useState<any>({});
  const [history, setHistory] = useState<any>({});
  const [loaded, setLoaded] = useState(false);
  const [search, setSearch] = useState<string>('');
  const [page, setPages] = useState<number>(1);
  const tableRef = useRef<any>(null);
  const zoneState = useAppSelector((state) => state.zoneStore);
  const itemsPerPage = 10;
  const dispatch = useAppDispatch();
  const [zoneUpdate, setZoneUpdate] = useState<ZoneUpdateProps>({
    zone_id: 0,
    type_list: '',
    lote_name: '',
    reason: '',
    note: '',
    rotation: 0,
    user_email: '',
  });

  const getMedianZonesFunction = async (textSearch: string) => {
    await getMedianZones({
      zone_id: zoneState.zoneInfo.zm_id,
      lote_name: textSearch,
      page,
      items: itemsPerPage,
    }).then((response) => {
      if (response?.success) {
        setMedianZones(response?.data);
        setLoaded(true);
      }
    });
  };

  const handlePagination = (_e: any, newPage: number) => {
    setPages(newPage);
  };

  const searchClick = () => {
    setPages(1);
    getMedianZonesFunction(search);
  };

  const cancelClick = () => {
    setSearch('');
    getMedianZonesFunction('');
  };

  const patchListInfo = async (data?: any) => {
    await patchList(data || zoneUpdate).then((response) => {
      if (response?.success) {
        const alertData: AlertProps = {
          type: 'success',
          text: 'Se han cambiado la rotación en la zona con éxito',
          showAlert: true,
        };
        dispatch(alertStoreActions.updateAlert(alertData));
      } else {
        const alertData: AlertProps = {
          type: 'error',
          text: 'Ocurrió un error al guardar los datos, Intente más tarde',
          showAlert: true,
        };
        dispatch(alertStoreActions.updateAlert(alertData));
      }
    });
  };

  const handleChangeRotation = (values: any) => {
    const { value, row } = values;

    patchListInfo({
      zone_id: zoneState.zoneInfo.zm_id,
      lote_name: row.lote_name,
      rotation: value,
    });
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const IOSSwitch = styled((props: SwitchProps) => (
    <Switch
      focusVisibleClassName=".Mui-focusVisible"
      disableRipple
      {...props}
    />
  ))(({ theme }) => ({
    width: 60,
    height: 32,
    padding: 0,
    '& .MuiSwitch-switchBase': {
      padding: 0,
      margin: 1,
      transitionDuration: '300ms',
      '&.Mui-checked': {
        transform: 'translateX(27px)',
        color: '#fff',
        '& + .MuiSwitch-track': {
          backgroundColor:
            theme.palette.mode === 'dark' ? '#2ECA45' : electricPurple500,
          opacity: 1,
          border: 0,
        },
        '&.Mui-disabled + .MuiSwitch-track': {
          opacity: 0.5,
        },
      },
      '&.Mui-focusVisible .MuiSwitch-thumb': {
        color: '#33cf4d',
        border: '6px solid #fff',
      },
      '&.Mui-disabled .MuiSwitch-thumb': {
        color:
          theme.palette.mode === 'light'
            ? theme.palette.grey[100]
            : theme.palette.grey[600],
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
      },
    },
    '& .MuiSwitch-thumb': {
      boxSizing: 'border-box',
      width: 30,
      height: 30,
    },
    '& .MuiSwitch-track': {
      borderRadius: 36 / 2,
      backgroundColor: theme.palette.mode === 'light' ? separator : '#39393D',
      opacity: 1,
      transition: theme.transitions.create(['background-color'], {
        duration: 500,
      }),
    },
  }));

  useEffect(() => {
    setZoneUpdate({ ...zoneUpdate });
  }, []);

  useEffect(() => {
    getMedianZonesFunction(search);
  }, [page]);

  const handleOpenModal = (data: any) => {
    setIsModalOpen(true);
    setTest(data);
  };

  const getDetail = async () => {
    try {
      const rta = await getListDetail({
        median_zone_id: zoneState?.zoneInfo?.zm_id,
        lote_name: test?.lote_name || '',
      });
      const lastChange = rta?.data[rta?.data.length - 1];
      setHistory(lastChange);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getDetail();
  }, [test]);

  return (
    <WrapperAssignListToSet>
      <ModalEditList
        isModalOpen={isModalOpen}
        setIsModalOpen={handleCloseModal}
        idResidential={test}
        zona={zoneState?.zoneInfo}
        history={history}
        updateData={() => getMedianZonesFunction('')}
      />
      {loaded && (
        <>
          <ColumnDescription>
            <div className="title size">
              Busca o filtra por nombre del conjunto
            </div>
            <div className="subtitle size">
              Buscar por nombre del conjunto en la zona mediana
            </div>
          </ColumnDescription>
          <SearchContent>
            <input
              name="searchInput"
              placeholder=" Escribe el nombre del conjunto"
              type="text"
              onChange={(e) => setSearch(e.target.value)}
              value={search}
            />

            {search !== '' && (
              <img
                alt="close"
                src={closeImage}
                className="close"
                onClick={cancelClick}
              />
            )}

            <img
              alt="search"
              src={searchButton}
              className={`search ${search !== '' ? 'search-close' : ''}`}
              onClick={searchClick}
            />
          </SearchContent>
        </>
      )}

      <SeparatorWrapper>
        <Separator />
      </SeparatorWrapper>

      <ColumnDescription>
        <div className="title">Editar la lista asignada del conjunto</div>
        <div className="subtitle">Modifica la lista de cada conjunto</div>
      </ColumnDescription>

      <TableWrapper>
        {!loaded && (
          <TableLoader>
            <HabiLoader size="small" />
          </TableLoader>
        )}

        {loaded && (
          <WrapperUpdateLogic>
            <TableContainer
              component={Paper}
              sx={{ minHeight: '300px', gridColumn: '1/10' }}
            >
              <Table aria-label="simple table" ref={tableRef}>
                <TableHead>
                  <TableRow>
                    <TableCell width="30%">Nombre y ID del conjunto</TableCell>
                    <TableCell width="20%">Lista actual asignada</TableCell>
                    <TableCell width="25%">Rotación de lotes SI/NO </TableCell>
                    <TableCell width="25%">Editar lista de conjunto </TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {medianZones?.result.map((row: RowTable, p: number) => (
                    <Fragment key={row.lote_name}>
                      <TableRow
                        sx={{
                          '&:last-child td, &:last-child th': { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          <ZoneInfoTable>
                            <div className="title">
                              {capitalize(row.lote_name)}
                            </div>
                            <div className="zone-id">{row.lote_id}</div>
                          </ZoneInfoTable>
                        </TableCell>
                        <TableCell align="left">
                          <CurrentListWrapper
                            className={row.current_list
                              .toLowerCase()
                              .replace(' ', '-')}
                          >
                            {row.current_list}
                          </CurrentListWrapper>
                        </TableCell>
                        <TableCell align="left" component="th">
                          <SwitchWrapper>
                            <IOSSwitch
                              sx={{ m: 1 }}
                              defaultChecked={!!row.rotation}
                              onChange={(
                                event: React.ChangeEvent<HTMLInputElement>
                              ) => {
                                handleChangeRotation({
                                  value: event.target.checked ? 1 : 0,
                                  name: row.lote_id,
                                  row,
                                });
                              }}
                            />
                          </SwitchWrapper>
                        </TableCell>
                        <TableCell align="left">
                          <EditButton onClick={() => handleOpenModal(row)}>
                            <span>Editar Lista</span>
                            <img src={editImage} alt="" />
                          </EditButton>
                        </TableCell>
                      </TableRow>
                    </Fragment>
                  ))}
                  <TableRow>
                    <TableCell align="left" colSpan={4}>
                      <PaginationWrapper>
                        <PaginationInfo>
                          {medianZones.pagination.page_size} de{' '}
                          {medianZones.pagination.total_length} resultados
                        </PaginationInfo>
                        <Pagination
                          count={medianZones.pagination.total_pages}
                          size="small"
                          onChange={handlePagination}
                        />
                      </PaginationWrapper>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </WrapperUpdateLogic>
        )}
      </TableWrapper>
    </WrapperAssignListToSet>
  );
};

export default AssignListToSet;
