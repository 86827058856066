// Libraries
import { useState, useEffect, Fragment } from 'react';
import { FunnelChart } from 'react-funnel-pipeline';
import Paper from '@mui/material/Paper';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';

// Styles
import FunnelGraphWrapper, {
  FunnelContainer,
  InfoContainer,
  InfoText,
  StepWrapper,
  TableWrapper,
} from './style';
import 'react-funnel-pipeline/dist/index.css';
import { Separator } from '../../styles';

// Images
import locationIcon from '../../../../assets/icons/location-icon.svg';

// Interfaces
import {
  FunnelDataArrayProps,
  FunnelGraphProps,
  GraphValuesProps,
} from '../../model';
import { subtitle } from '../../../../styles/stylesConstants';

// Constants
const DICT_LABEL: any = {
  total: 'Registros totales',
  unique_leads: 'Registros únicos',
  complete: 'Registros completos',
  unique_in_zone: 'Registros únicos en zona',
  is_lead: 'Es leads',
  pricing: 'Registros apricing',
  assigned: 'Registros asignados',
  managed: 'Gestionados Sales',
  interesed: 'Interesados Sales',
  visited: 'Visitados',
  committe_approved: 'Aprobados cómite',
  clousure: 'Cierre efectuado',
};

export const FUNNEL_PALLETTE = [
  '#450083',
  '#45007C',
  '#72419E',
  '#9069B4',
  '#ADE6FF',
  '#84DAFF',
  '#5BCDFF',
  '#3483FA',
  '#3483FA',
  '#3483FA',
  '#3483FA',
  '#3483FA',
];

const FunnelGraph: React.FC<FunnelGraphProps> = ({ title, subTitle, data }) => {
  const [dataGraph, setDataGraph] = useState<GraphValuesProps[]>([]);

  useEffect(() => {
    const newDataGraph: GraphValuesProps[] = data.map((item, i) => ({
      name: (
        <div key={item.step} className={`${i > 3 ? 'is-black' : ''}`}>
          {DICT_LABEL[item.step]}
        </div>
      ),
      value: item.value,
    }));
    setDataGraph(newDataGraph);
  }, [data]);
  return (
    <FunnelContainer>
      <FunnelGraphWrapper>
        <InfoContainer>
          <img src={locationIcon} alt="" />
          <InfoText>
            <span className="title">{title}</span>
            <span className="subtitle">{subTitle}</span>
          </InfoText>
        </InfoContainer>
        <FunnelChart
          data={dataGraph}
          pallette={FUNNEL_PALLETTE}
          getToolTip={(row) => {
            return `${row.name} - ${row.value}`;
          }}
          // eslint-disable-next-line react/no-unstable-nested-components
          decorateValue={(row, index) => {
            return (
              <div className={`${index > 3 ? 'is-black' : ''}`}>
                {row.value}
              </div>
            );
          }}
          style={{ marginBottom: '0px' }}
        />
      </FunnelGraphWrapper>
      <Separator />
      <TableWrapper>
        <TableContainer component={Paper} sx={{ minHeight: '300px' }}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell width="40%">Etapa del funnel</TableCell>
                <TableCell width="20%">%Conversiones</TableCell>
                <TableCell width="20%">%Etapa (variable)</TableCell>
                <TableCell width="20%">Lead Rezagados</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((item: FunnelDataArrayProps, i) => {
                return (
                  <Fragment key={`f-${item.step}-${subtitle}`}>
                    <TableRow
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell>
                        <StepWrapper color={FUNNEL_PALLETTE[i]}>
                          {DICT_LABEL[item.step]}
                        </StepWrapper>
                      </TableCell>
                      <TableCell>{item.lead_conversion}</TableCell>
                      <TableCell>{item.variable_conversion}</TableCell>
                      <TableCell>{item.unconverted_leads}</TableCell>
                    </TableRow>
                  </Fragment>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </TableWrapper>
    </FunnelContainer>
  );
};

export default FunnelGraph;
