import { SearchPropertyContainer, Subtitle, Title } from './styles';
import SearchInput from '../../../../components/SearchInput/SearchInput';

const SearchProperty = () => {
  return (
    <SearchPropertyContainer>
      <div>
        <Title>Buscar propiedad</Title>
        <Subtitle>Ingrese un NID para cargar los datos</Subtitle>
      </div>
      <div className="container-input">
        <SearchInput placeholder="Ingrese un NID" />
      </div>
    </SearchPropertyContainer>
  );
};

export default SearchProperty;
