import styled from 'styled-components';
import {
  principalFont,
  nightBlue500,
  scarpaGray600,
  textGray3,
  scarpaGray100,
  scarpaGray800,
  hintPerano200,
} from '../../styles/stylesConstants';

export const PricingWrapper = styled.div`
  width: 100%;
  margin-bottom: 16px;

  .night-blue {
    color: ${nightBlue500};
  }
  .sgray {
    color: ${scarpaGray600};
  }
  .sgray8 {
    color: ${scarpaGray800};
  }
  .text-gray {
    color: ${textGray3};
  }

  .date-wrapper {
    max-width: 300px;
    margin-top: 24px;
  }

  .styles__PickerContainer-nwdsad-2 {
    z-index: 3;
  }

  .pricing-item {
    width: 50%;
  }
  .container-price {
    padding-bottom: 28px;
    margin-bottom: 21px;
    border-bottom: 1px solid ${hintPerano200};
  }

  .percentage-a {
    position: relative;
    width: 99px;
    height: 99px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
    img {
      position: absolute;
      top: 0px;
      left: 0px;
      z-index: -1;
    }
    .info {
      font-family: ${principalFont};
      font-size: 32px;
      font-weight: 600;
      color: ${scarpaGray600};
      margin-top: 3rem;
    }
  }

  .percentage-button {
    border: 1px solid ${scarpaGray100};
    border-radius: 8px;
    background: ${scarpaGray100};
    padding: 10px 18px;
    font-size: 14px;
    font-family: ${principalFont};
    font-weight: bold;
    color: ${scarpaGray800};
    margin-top: 28px;
    text-align: center;
  }

  .flex-break {
    flex-basis: 100%;
    height: 20px;
  }

  .wrap {
    flex-wrap: wrap;
  }

  .separator {
    width: 1px;
    height: 200px;
    margin: 70px 1.5rem 0 1.5rem;
    background: ${hintPerano200};
  }
`;

export const AlertWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(10, 1fr);
`;

export const ReturnWrapper = styled.div`
  grid-column: 1 / 6;
  display: flex;
  align-items: center;
  cursor: pointer;
`;
