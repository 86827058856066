/* eslint-disable no-param-reassign */
import { Dispatch, SetStateAction, useState } from 'react';
import { capitalize } from '@mui/material';
import {
  PlacesContainerRule,
  RuleCloseForm,
  RuleDescription,
  RulesFormContainer,
  RulesFormWrapper,
} from './styles';
import closeRuleIcon from '../../../../assets/icons/close_rule.svg';
import InputRule from './components/InputRule/InputRule';
import DropdownRule from './components/DropdownRule/SelectRule';
// eslint-disable-next-line import/no-cycle
import { ButtonContainerHesh } from '../../../HesH/styles';
import { IRulesValues } from '../../model';
import { IOption } from '../../../ZoneValidationMx/componets/SelectArea/interfaces';
import { RULES_VALIDATION_CO, RULES_VALIDATION_MX } from './utils';

interface IRuleSidebarForm {
  setShowModalForm: Dispatch<SetStateAction<boolean>>;
  selectedRule: any;
  rulesValues: IRulesValues[];
  setRulesValues: Dispatch<SetStateAction<IRulesValues[]>>;
  selectedPlaces: IOption[];
}

const RuleSidebarForm = ({
  setShowModalForm,
  selectedRule,
  rulesValues,
  setRulesValues,
  selectedPlaces,
}: IRuleSidebarForm) => {
  const [disabledButton, setDisabledButton] = useState(true);
  const [tempValue, setTempValue] = useState<any>({});
  const country = localStorage.getItem('country');
  const RULES_VALIDATION =
    country === 'MX' ? RULES_VALIDATION_MX : RULES_VALIDATION_CO;

  const handleCleanPoint = (value: any) => {
    if (value === '') {
      return null;
    }

    return String(+value);
  };

  const handleInputValue = (
    inputValue: string,
    error: boolean,
    field: any,
    isDropdown?: boolean
  ) => {
    const newRulesValues = [...rulesValues];
    const index = newRulesValues.findIndex(
      (rule) => rule.id === selectedRule.id
    );

    if (index !== -1) {
      const temp = newRulesValues[index];
      temp.values.forEach((item: any) => {
        if (item.name === field.name) {
          item.value = isDropdown ? inputValue : handleCleanPoint(inputValue);
          item.error = error;
        }

        return item;
      });
      temp.values.forEach((item) => {
        if (item.value !== null && item.value !== '') {
          setDisabledButton(error);
        } else if (selectedRule.name !== RULES_VALIDATION.typology) {
          setDisabledButton(true);
        }
      });

      if (selectedRule.name === RULES_VALIDATION.typology) {
        const hasError = temp.values.some(
          (value) => value.error === true && value.value !== null
        );
        setDisabledButton(hasError);

        const hasError2 = temp.values.every((value) => value.value === null);

        if (hasError2) {
          setDisabledButton(hasError2);
        }
      } else {
        const hasError = temp.values.some(
          (value) =>
            value.error === true ||
            value.value === null ||
            value.value === '' ||
            value.value === 'null'
        );
        setDisabledButton(hasError);
      }

      setTempValue(temp);
    }
  };

  const handleFinishEdition = () => {
    const newValues = [...rulesValues];
    const index = newValues.findIndex((item) => item.id === selectedRule.id);

    if (index !== -1) {
      tempValue.modified = true;
      newValues[index] = tempValue;
    }

    setShowModalForm(false);
    setRulesValues(newValues);
    setTempValue({});
  };

  return (
    <RulesFormWrapper>
      <RulesFormContainer>
        <RuleCloseForm>
          <img
            src={closeRuleIcon}
            alt=""
            onClick={() => {
              setShowModalForm(false);
            }}
            aria-hidden="true"
          />
        </RuleCloseForm>
        <RuleDescription className="spaces">
          <div className="title">{capitalize(selectedRule.name)}</div>
          <div className="description">
            Los cambios que realice a esta regla solo se verán reflejados
            después de <span className="bold">guardar los cambios</span> de
            todas las reglas.
          </div>
          {selectedRule.name === RULES_VALIDATION.risk_zone && (
            <PlacesContainerRule>
              {selectedPlaces.map((item) => {
                return (
                  <div key={item.id} className="place-item">
                    {item.label}
                  </div>
                );
              })}
            </PlacesContainerRule>
          )}
        </RuleDescription>
        {selectedRule.rule_structure.fields.map((field: any) => {
          switch (field.field) {
            case 'input':
              return (
                <div className="spaces" key={field.id}>
                  <InputRule
                    field={field}
                    handleInputValue={handleInputValue}
                    rulesValues={rulesValues}
                    selectedRule={selectedRule}
                  />
                </div>
              );

            case 'select':
              return (
                <div className="spaces" key={field.id}>
                  <DropdownRule
                    field={field}
                    handleInputValue={handleInputValue}
                    rulesValues={rulesValues}
                    selectedRule={selectedRule}
                  />
                </div>
              );

            default:
              return null;
          }
        })}

        <ButtonContainerHesh style={{ justifyContent: 'flex-start' }}>
          <button
            className="button-hesh"
            onClick={() => {
              handleFinishEdition();
            }}
            disabled={disabledButton}
            style={{ fontSize: '16px' }}
          >
            Terminar edición
          </button>
        </ButtonContainerHesh>
      </RulesFormContainer>
    </RulesFormWrapper>
  );
};

export default RuleSidebarForm;
