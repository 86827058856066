import { RouteObject, Navigate } from 'react-router-dom';

import Layout from '../components/Layout';
import ApprovalRules from '../pages/ApprovalRules';
import Elasticity from '../pages/Elasticity';
import FunnelConversion from '../pages/FunnelConvertion';
import InventoryTime from '../pages/InventoryTime';
import LoginPage from '../pages/Login';
import MarketingLeads from '../pages/MarketingLeads';
import MarketShare from '../pages/MarketShare';
import Pricing from '../pages/Pricing';
import RejectedReasons from '../pages/RejectedReasons';
import Revenue from '../pages/Revenue';
import SelectZone from '../pages/SelectZone';
import Utility from '../pages/Utility';
import ZoneDetail from '../pages/ZoneDetail';
import DiscountEngine from '../pages/DiscountEngine';
import RulesEditor from '../pages/RulesEditor';
import ZonaBusinessRules from '../pages/ZoneBusinessRules';
import ZonaValidation from '../pages/ZoneValidation';
import ZonaValidationMx from '../pages/ZoneValidationMx';
import Initial from '../pages/Initial/Initial';
import LayoutMx from '../components/LayoutMx/LayoutMx';
import HesH from '../pages/HesH/HesH';
import QuotaLogic from '../pages/QuotaLogic/QuotaLogic';
import QuotaLogicCO from '../pages/QuotaLogic/QuotasLogicCo';
import Page404 from '../pages/404/Page404';
import NewApprovalRules from '../pages/NewApprovalRules/NewApprovalRules';
import NewApprovalRulesCo from '../pages/NewApprovalRules/NewApprovalRulesCo';
import Approver from '../pages/Approver/Approver';
import ApproverCo from '../pages/Approver/ApproverCo';

const routesMap: RouteObject[] = [
  {
    path: '/portal',
    element: <Layout />,
    children: [
      { path: 'analisis-de-zona', element: <ZoneDetail /> },
      { path: 'pricing', element: <Pricing /> },
      { path: 'revenue', element: <Revenue /> },
      { path: 'reglas-de-aprobacion', element: <ApprovalRules /> },
      { path: 'funnel-de-conversion', element: <FunnelConversion /> },
      { path: 'leads-de-marketing', element: <MarketingLeads /> },
      { path: 'utility', element: <Utility /> },
      { path: 'tiempo-inventario', element: <InventoryTime /> },
      { path: 'razones-rechazo', element: <RejectedReasons /> },
      { path: 'elasticidad', element: <Elasticity /> },
      { path: 'market-share', element: <MarketShare /> },
      { path: 'zone-business-rules', element: <ZonaBusinessRules /> },
      { path: '*', element: <Navigate to="/analisis-de-zona" replace /> },
    ],
  },
  { path: '/motor-de-descuento', element: <DiscountEngine /> },
  { path: '/validacion-de-zona', element: <ZonaValidation /> },
  {
    path: '/motor-de-descuento/editar-reglas',
    element: <RulesEditor />,
  },
  { path: '/seleccion-de-zona', element: <SelectZone /> },
  { path: '/iniciar-sesion', element: <LoginPage /> },
  { path: '/start', element: <Initial /> },
  {
    path: '*',
    element: <Navigate to="/iniciar-sesion" replace />,
  },
  { path: '/habi-es-habi', element: <HesH /> },
  { path: '/logica-de-cupos', element: <QuotaLogicCO /> },
  { path: '/reglas-aprobacion', element: <NewApprovalRulesCo /> },
  { path: '/aprobologo', element: <ApproverCo /> },
  { path: '/404', element: <Page404 /> },
];

export const routesMapMX: RouteObject[] = [
  {
    path: '/',
    element: <LayoutMx />,
    children: [
      { path: '/validacion-de-zona', element: <ZonaValidationMx /> },
      { path: '/habi-es-habi', element: <HesH /> },
      { path: '/logica-de-cupos', element: <QuotaLogic /> },
      { path: '/reglas-aprobacion', element: <NewApprovalRules /> },
      { path: '/aprobologo', element: <Approver /> },
      { path: '/start', element: <Initial /> },
      { path: '/', element: <Initial /> },
      {
        path: '*',
        element: <Navigate to="/iniciar-sesion" replace />,
      },
    ],
  },
  { path: '/iniciar-sesion', element: <LoginPage /> },
  { path: '/404', element: <Page404 /> },
];
export const routesApprover: RouteObject[] = [
  {
    path: '/',
    element: <LayoutMx />,
    children: [
      { path: '/aprobologo', element: <Approver /> },
      { path: '/start', element: <Initial /> },
      { path: '/', element: <Initial /> },
      {
        path: '*',
        element: <Navigate to="/iniciar-sesion" replace />,
      },
    ],
  },
  { path: '/iniciar-sesion', element: <LoginPage /> },
  { path: '/404', element: <Page404 /> },
];
export const routesApproverMX: RouteObject[] = [
  { path: '/iniciar-sesion', element: <LoginPage /> },
  { path: '/start', element: <Initial /> },
  {
    path: '*',
    element: <Navigate to="/iniciar-sesion" replace />,
  },

  { path: '/aprobologo', element: <ApproverCo /> },
  { path: '/404', element: <Page404 /> },
];

export default routesMap;
