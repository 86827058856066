export type TDataForm = {
  id: number;
  rule_item: string;
  rule_condition: string;
  expected_value: string;
  points_discount: string;
  zone: string;
  am_city: number;
};

export type TValidationMessage = {
  error: boolean;
  message: string;
};

export type TModalInformation = {
  title: string;
  title2?: string;
  subtitle: string;
  buttonOk: string;
  buttonCancel: string;
  action: (id?: number) => void;
  actionToCancel?: (id?: number) => void;
  image: string;
  cancelFunction?: boolean;
  showButtons?: boolean;
  showButtonsCancel?: boolean;
};

export type TOptions = {
  label: string;
  value: string;
};

export type TOptionsMa = {
  label: string;
  id: number;
};

export interface IFormEditRules {
  elementOptions: Array<TOptions>;
  todoOptions: Array<TOptions>;
  zoneOptions: Array<TOptions>;
  dataForm: TDataForm;
  elementChange: Function;
  todoChange: Function;
  handleInputRule: Function;
  handleInputRulePoints: Function;
  saveRule: Function;
  validation: TValidationMessage;
  handleZoneType: Function;
  showMore: boolean;
  setShowMore: Function;
  maOptions: Array<TOptionsMa>;
  loaded: boolean;
  handleAmCityChange: Function;
}

export interface IRulesTable {
  listRules: Array<TDataForm>;
  elementOptions: Array<TOptions>;
  todoOptions: Array<TOptions>;
  handleDeleteClick: Function;
  zoneOptions: Array<TOptions>;
  listMa: Array<TOptionsMa>;
  listCities: Array<TOptionsMa>;
}

type TRulesOptions = {
  rule_item: string;
  rule_condition: string;
  expected_value: number;
};

export interface IPostDiscountEngine {
  rules: Array<TRulesOptions>;
  points_discount: number;
}

export const initialModalState: TModalInformation = {
  title: '',
  subtitle: '',
  buttonOk: '',
  buttonCancel: '',
  action: () => {},
  actionToCancel: () => {},
  image: '',
  cancelFunction: false,
  showButtons: true,
  showButtonsCancel: true,
};
