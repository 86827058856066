import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../../redux/hooks';
import LoginGoogle, { type IUserGoogle } from './loginGoogle';
import returnArrow from '../../static/assets/icons/return-arrow.svg';
import imgMexico from '../../static/assets/icons/mexico.svg';
import imgColombia from '../../static/assets/icons/colombia.png';
import imgLoading from '../../static/assets/icons/loader-search.svg';
import { userStoreActions } from '../../redux/reducers/userReducer';
import {
  CountryWrapper,
  HomeLeftContainer,
  HomePrincipalContainer,
  LoginContainer,
  CountryContainer,
  LoginWrapper,
  StyledErrorLogin,
  HomeRightContainer,
  HomeLoadingContent,
  RotatingImage,
  ButtonBack,
} from './styles';

const LoginPage = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [country, setCountry] = useState('');
  const [selectCountry, setSelectCountry] = useState(false);
  const [isLogin, setIsLogin] = useState(false);
  const [error, setError] = useState('');

  function credentialLogin(user: IUserGoogle) {
    localStorage.setItem('user', JSON.stringify({ ...user, isAuth: true }));
    dispatch(userStoreActions.updateUser(user));
    navigate("/start");
    window.location.reload();
  }

  const onLoginSuccess = (user: IUserGoogle) => {
    credentialLogin(user);
  };

  const handleClick = (countrySelected: string) => {
    localStorage.setItem('country', countrySelected);
    setCountry(countrySelected);
  };

  const handleGoogleError = () => {
    console.log("error");
  };

  const handleReturn = () => {
    setSelectCountry(false);
    setCountry('');
    setError('');
  };

  return (
    <HomePrincipalContainer>
      {!isLogin ? (
        <>
          <HomeLeftContainer>
            <LoginContainer>
              {selectCountry && (
                <div className="information">
                  <ButtonBack onClick={handleReturn}>
                    <img src={returnArrow} className="return" alt="" />
                  </ButtonBack>
                  <img
                    src={country === 'MX' ? imgMexico : imgColombia}
                    width={30}
                    height={30}
                    alt=""
                  />
                </div>
              )}

              <div className="title">Portal zonas</div>
              {!selectCountry && (
                <>
                  <div className="subtitle" data-testid="login-subtitle">
                    Te damos la bienvenida, a continuación{' '}
                    <span className="strong">selecciona el país</span> con el
                    que quieres ingresar.
                  </div>
                  <CountryWrapper>
                    <CountryContainer
                      className=""
                      onClick={() => {
                        handleClick('MX');
                        setSelectCountry(true);
                        setError('');
                      }}
                    >
                      <img src={imgMexico} alt="TuHabi méxico" />
                      <span>México</span>
                    </CountryContainer>
                    <CountryContainer
                      className=""
                      onClick={() => {
                        handleClick('CO');
                        setSelectCountry(true);
                        setError('');
                      }}
                    >
                      <img src={imgColombia} alt="Habi Colombia" />
                      <span>Colombia</span>
                    </CountryContainer>
                  </CountryWrapper>
                </>
              )}

              {country !== '' && (
                <>
                  <div className="subtitle">
                    Ahora ingresa con tu correo empresarial
                  </div>
                  <LoginWrapper>
                    <>
                      <LoginGoogle
                        onError={handleGoogleError}
                        onNextStep={onLoginSuccess}
                        setIsLogin={setIsLogin}
                        setError={setError}
                        country={country}
                      />
                      {error !== '' && (
                        <StyledErrorLogin>
                          <p>{error}</p>
                        </StyledErrorLogin>
                      )}
                    </>
                  </LoginWrapper>
                </>
              )}
            </LoginContainer>
          </HomeLeftContainer>
          <HomeRightContainer />
        </>
      ) : (
        <HomeLoadingContent>
          <RotatingImage
            src={imgLoading}
            width={32}
            height={32}
            alt="cargando"
          />
        </HomeLoadingContent>
      )}
    </HomePrincipalContainer>
  );
};

export default LoginPage;
