import { useEffect, useState } from 'react';
import moment, { Moment } from 'moment';

import { useAppSelector } from '../../redux/hooks';
import { selectUserDate } from '../../redux/reducers/userSelectors';
import { RoutePage } from '../../utils/withRoutePage';
import ReturnLink from '../../components/ReturnLink';
import DateDisclaimer from '../../components/DateDisclaimer';

import {
  ReturnWrapper,
  SectionContainer,
  SubTitleSection,
  TitleSection,
} from '../MarketingLeads/styles';
import {
  InventoryWrapper,
  DateContainer,
  SubSectionTitle,
  WrapperVersus,
} from './style';

import unionIcon from '../../assets/icons/union.svg';
import HistogramMetropolitanA from './components/HistogramMetropolitanA/HistogramMetropolitanA';
import HistogramMedianZone from './components/HistogramMedianZone/HistogramMedianZone';

const InventoryTime: RoutePage = () => {
  const userDate = useAppSelector(selectUserDate);
  const [date, setDate] = useState<Moment>(moment(userDate));

  useEffect(() => {
    if (userDate) {
      setDate(moment(userDate));
    }
  }, [userDate]);

  return (
    <InventoryWrapper>
      <ReturnWrapper>
        <ReturnLink
          text="Regresar a análisis de zona"
          link="/portal/analisis-de-zona"
        />
      </ReturnWrapper>

      <TitleSection>Tiempo de inventario</TitleSection>

      <SubTitleSection>
        Análisis del área metropolitana y zona mediana
      </SubTitleSection>

      <SectionContainer>
        <SubSectionTitle>
          <img src={unionIcon} alt="" />
          Inmuebles agrupados por días en inventario (10 días)
        </SubSectionTitle>

        <DateContainer>
          <DateDisclaimer date={date.toDate()} />
        </DateContainer>

        <HistogramMetropolitanA />
        <WrapperVersus>
          <hr className="divider" />
          <div className="vs-indicator">Vs</div>
        </WrapperVersus>
        <HistogramMedianZone />
      </SectionContainer>
    </InventoryWrapper>
  );
};

InventoryTime.requiresAuth = true;

export default InventoryTime;
