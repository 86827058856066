import { useState, useEffect } from 'react';
import ContainerTextArea from './styles';

export interface ITextArea {
  rows?: number;
  cols?: number;
  handleChange: (text: string) => void;
  styles?: object;
  value?: string;
  placeholder?: string;
  maxLength?: number;
  label?: string;
}

const TextArea = ({
  rows = 5,
  cols = 50,
  handleChange,
  styles,
  value = '',
  placeholder = '',
  maxLength = 150,
  label,
}: ITextArea) => {
  const [commentLength, setCommentLength] = useState(0);
  const [limit, setLimit] = useState(false);
  useEffect(() => {
    setCommentLength(value?.length);

    if (value?.length >= maxLength) {
      setLimit(true);
    } else {
      setLimit(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);
  return (
    <ContainerTextArea limit={limit}>
      {!!label && <div className="label">{label}</div>}
      <textarea
        maxLength={maxLength}
        data-testid="inbox_message"
        placeholder={placeholder}
        style={styles}
        value={value}
        className="text_area"
        rows={rows}
        cols={cols}
        onChange={({ target }) => handleChange(target.value)}
      />
      <div className="counter">{`${commentLength}/${maxLength}`}</div>
    </ContainerTextArea>
  );
};

export default TextArea;
