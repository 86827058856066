import LayoutHeaderClean from '../../components/LayoutClean';
import AlertToast from '../Pricing/components/AlertToast/AlertToast';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { alertStoreActions } from '../../redux/reducers/alertReducer';
import LayoutSidebar from '../../components/LayoutSidebar';
import EditRule from './components/EditRule';
import withRoutePage, { RoutePage } from '../../utils/withRoutePage';
import { LayoutWrapper, ContainerPage } from '../DiscountEngine/style';

const RulesEditor: RoutePage = () => {
  const alertState = useAppSelector((state) => state.alertStore);
  const dispatch = useAppDispatch();
  const { hideAlert } = alertStoreActions;

  const handleAlert = () => {
    dispatch(hideAlert());
  };

  return (
    <LayoutWrapper>
      <LayoutSidebar />
      <ContainerPage>
        <LayoutHeaderClean />
        <AlertToast
          type={alertState.type}
          text={alertState.text}
          showAlert={alertState.showAlert}
          setShowAlert={handleAlert}
          top="75px"
        />
        <EditRule />
      </ContainerPage>
    </LayoutWrapper>
  );
};

RulesEditor.requiresAuth = true;

export default withRoutePage(RulesEditor);
