import styled from 'styled-components';

import {
  monserratRegular,
  scarpaGray800,
  electricPurple500,
  robotoRegular,
  white,
  scarpaGray100,
  electricPurple100,
  scarpaGray400,
  scarpaGray600,
  disabledColor,
  scarpaGray50,
} from '@habitech/shared';
import { gray6, textGray3 } from '../../styles/stylesConstants';

const ContainerZone = styled.div`
  justify-content: center;
  display: grid;
  grid-column-gap: 24px;
  grid-row-gap: 0;
  grid-template-columns: repeat(10, 1fr);
  margin-right: 24px;
`;

export const ContainerSelectTypeZoneMx = styled.div`
  display: flex;
`;
export const ContainerSelectDecision = styled.div`
  display: flex;
`;

export const TitleContainer = styled.div`
  display: grid;
  grid-column: 1 / span 10;
  margin: 40px 0 12px 0;
  height: 33px;
  color: ${scarpaGray800};
  font-family: ${monserratRegular};
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
  letter-spacing: -0.3px;
`;
export const ContainerZoneValidationMx = styled.div`
  padding: 40px;
  display: grid;
  grid-column: 1 / span 10;
  border-radius: 6px;
  margin: 0 24px 24px 0;
  background: var(--color-night-blue-000, #fff);
  box-shadow: 0px 2px 4px 0px rgba(48, 49, 51, 0.1),
    0px 0px 1px 0px rgba(48, 49, 51, 0.05);
`;

export const TabContainer = styled.div`
  grid-column: 3/10;
  display: flex;
  align-items: center;
`;

export const TabItem = styled.div`
  width: 137px;
  background: ${white};
  border: 1px solid ${gray6};
  padding: 14px;
  color: ${scarpaGray800};
  font-family: ${robotoRegular};
  font-weight: 400;
  font-size: 16px;
  text-align: center;
  cursor: pointer;
  border-right: none;
  &.first {
    border-radius: 6px 0 0 6px;
  }
  &.last {
    border-right: 1px solid ${gray6};
    border-radius: 0 6px 6px 0;
  }
  &.selected {
    background: ${electricPurple500};
    color: ${white};
    border: 1px solid ${electricPurple500};
  }
  &.quota {
    width: 193px;
  }
  &.disabled {
    background: ${scarpaGray50};
    cursor: not-allowed;
    color: rgb(151, 148, 153);
  }
`;

export const Divisor = styled.div`
  display: flex;
  align-items: center;
  border-top: 1px solid ${disabledColor};
  margin: 28px 0;
`;
export const ColumnDescription = styled.div`
  .size {
    width: 130px;
  }
  .subtitle {
    font-family: ${robotoRegular};
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: ${scarpaGray600};
    width: 167px;
    margin-right: 64px;
  }
`;
export const ContainerButtons = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: 26px;
`;
export const ButtonLoanPurchaseSale = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
`;
export const ContainerTabs = styled.div`
  grid-column: 3/8;
  margin-bottom: 31px;
`;

export const ContainerTap = styled.div`
  display: flex;
  border-bottom: 1px solid ${disabledColor};
  width: 100%;
  margin-bottom: 24px;
  .selected {
    color: ${scarpaGray800};
    text-align: center;
    font-family: ${monserratRegular};
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
    padding: 16px;
  }
  .not-selected {
    color: ${textGray3};
    text-align: center;
    font-family: ${monserratRegular};
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
    padding: 16px;
  }
  .line-selected {
    background: ${electricPurple500};
    height: 4px;
    border-radius: 8px;
  }
`;
export const LoanPurchaseSaleContainer = styled.div`
  width: 100%;
`;
export const ContainerDiscountToAply = styled.div`
  grid-column: 3/11;
  display: flex;
  margin-bottom: 40px;
  .margin-first {
    margin-right: 24px;
  }
  .add-less {
    .title {
      font-family: ${robotoRegular};
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      margin-bottom: 9px;
      color: ${scarpaGray800};
    }
  }
`;
export const ContainerInputFromTo = styled.div`
  display: flex;
  margin-bottom: 24px;
  .input-from-to {
    width: 210px;
    margin-right: 24px;
  }
`;
export const TextTitle = styled.div`
  color: ${scarpaGray800};
  font-family: ${robotoRegular};
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  margin-bottom: 24px;
`;

interface IImageButton {
  isDisable: boolean;
}

export const ImageButton = styled.button<IImageButton>`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background: ${white};
  border-radius: 6px;
  border: 1px solid
    ${(props) => (props.isDisable ? scarpaGray100 : electricPurple100)};
  color: ${(props) => (props.isDisable ? scarpaGray400 : electricPurple500)};
  width: 207px;
  height: 48px;
  font-family: ${robotoRegular};
  font-weight: 600;
  line-height: 20px;
  font-size: 14px;
  margin-right: 24px;
  padding-left: 16px;
  cursor: ${(props) => (props.isDisable ? 'auto' : 'pointer')};
  img {
    margin-right: ${(props) => (props.isDisable ? '18px' : '12px')};
  }
`;

export const BorderDivision = styled.div`
  background: ${disabledColor};
  width: 100%;
  height: 1px;
  margin: 24px 0;
`;
export const ContainerSendButton = styled.div`
  display: flex;
  justify-content: end;
  button {
    border-color: ${electricPurple500};
    width: 207px;
    height: 48px;
    background: ${electricPurple500};
    border-radius: 6px;
    justify-content: center;
    display: flex;
    align-items: center;
    cursor: pointer;
    .text {
      font-family: ${robotoRegular};
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      display: flex;
      align-items: center;
      text-align: center;
      color: ${white};
    }
  }
  button:disabled {
    border-color: ${disabledColor};
    width: 207px;
    height: 48px;
    background: ${disabledColor};
    border-radius: 6px;
    justify-content: center;
    display: flex;
    align-items: center;
    cursor: pointer;
    .text {
      font-family: ${robotoRegular};
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      display: flex;
      align-items: center;
      text-align: center;
      color: ${white};
    }
  }
`;

export default ContainerZone;
