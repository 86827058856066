import styled from 'styled-components';
import { white, monserratRegular } from '@habitech/shared';

export const WrapperZoneBusinessRules = styled.div`
  display: grid;
`;

export const TitleZonseBussinesRules = styled.div`
  font-family: ${monserratRegular};
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 32px;
  margin-bottom: 20px;
`;

export const ContainerRulesBusinessZone = styled.div`
  background: ${white};
  box-shadow: 0px 0px 1px rgba(48, 49, 51, 0.05),
    0px 2px 4px rgba(48, 49, 51, 0.1);
  border-radius: 6px;
  display: grid;
  grid-template-columns: repeat(10, 1fr);
  gap: 0 24px;
  padding: 24px 18px;
`;
export const ReturnWrapper = styled.div`
  grid-column: 1 / 6;
`;
