import { monserratRegular, robotoRegular, scarpaGray800, skyBlue200, skyBlue800 } from '@habitech/shared';
import styled from 'styled-components';
import { indicator } from '../../../../styles/stylesConstants';

const ElasticityIndicatorsWrapper = styled.div`
  width: 100%;
`;

export const ZoneInfoContainer = styled.div`
  margin: 10px 0 0 0;
`;

export const IndicatorsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 6px;
  background: ${indicator};
  padding: 12px;
  margin-bottom: 16px;
`;

export const IndicatorItem = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  width: 19%;
  .title {
    font-family: ${robotoRegular};
    font-size: 14px;
    font-weight: 600;
    color: ${skyBlue800};
  }
  .discount {
    font-family: ${monserratRegular};
    font-size: 16px;
    font-weight: 600;
    color: ${scarpaGray800};
    margin-top: 3px;
  }
  &.border-right {
    border-right: 1.5px solid ${skyBlue200};
  }
`;

export default ElasticityIndicatorsWrapper;
