/* eslint-disable no-param-reassign */
import { Dispatch, SetStateAction } from 'react';
import { IRulesValues } from '../../model';
// eslint-disable-next-line import/no-cycle
import {
  ColumnDescription,
  ContainerBusinessProjection,
} from '../BusinessProjection/styles';
import RuleItem from './RuleItem/RuleItem';
import { RulesContainer } from './styles';

interface IRulesList {
  rules: any[];
  description: any;
  rulesValues: IRulesValues[];
  setRulesValues: Dispatch<SetStateAction<IRulesValues[]>>;
  handleShowRuleForm: (rule: any) => void;
  tabSelected: number;
  timer: boolean;
}

const RulesList = ({
  rules,
  rulesValues,
  setRulesValues,
  handleShowRuleForm,
  description,
  tabSelected,
  timer,
}: IRulesList) => {
  const handleSaveForm = (id: number, status: boolean) => {
    const newValues = [...rulesValues];
    const index = newValues.findIndex((item) => item.id === id);

    if (index !== -1) {
      newValues[index].active = status ? 1 : 0;
      newValues[index].modified = true;
    }

    setRulesValues(newValues);
  };

  return (
    <ContainerBusinessProjection>
      <ColumnDescription>
        <div className="subtitle">&nbsp;</div>
      </ColumnDescription>
      <RulesContainer>
        {rules.map((item: any, index: number) => {
          return (
            <RuleItem
              key={item.id}
              rules={rules}
              rule={item}
              index={index}
              description={description}
              handleSaveForm={handleSaveForm}
              handleShowRuleForm={handleShowRuleForm}
              tabSelected={tabSelected}
              timer={timer}
            />
          );
        })}
      </RulesContainer>
    </ContainerBusinessProjection>
  );
};

export default RulesList;
