import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import pricingImage from '../../../../assets/images/pricing.svg';
import leadsMarketing from '../../../../assets/images/marketing_leads_icon.svg';
import revenueImage from '../../../../assets/images/revenue.svg';
import funnelConvertionImage from '../../../../assets/images/funnel_convertion.svg';
import utilityIcon from '../../../../assets/images/utility-logo.svg';
import inventoryTimeIcon from '../../../../assets/images/inventory_time.svg';
import arrowRight from '../../../../assets/icons/arrow-right.svg';
import CalculatorIcon from '../../../../assets/images/calculator.jpg';
import MarketShareIcon from '../../../../assets/icons/market-share.svg';
import notepad from '../../../../assets/icons/notepad.svg';

import SimpleCard from '../../../../components/SimpleCard';
import {
  ZonePageItem,
  ZonePagesContainer,
  ZonePagesTabs,
  ZonePageList,
  PageTab,
} from './styles';

type PagesTabs = 'sellers' | 'buyers' | 'zone';

const ZonePages = () => {
  const [currentTab, setCurrentTab] = useState<PagesTabs>('sellers');
  const navigate = useNavigate();

  const goToModule = (moduleLink: string) => {
    navigate(`/portal/${moduleLink}`);
  };

  return (
    <ZonePagesContainer>
      <ZonePagesTabs>
        <PageTab
          active={currentTab === 'zone'}
          onClick={() => setCurrentTab('zone')}
        >
          Zona
        </PageTab>
        <PageTab
          active={currentTab === 'sellers'}
          onClick={() => setCurrentTab('sellers')}
        >
          Funnel Sellers
        </PageTab>

        <PageTab
          active={currentTab === 'buyers'}
          onClick={() => setCurrentTab('buyers')}
        >
          Buyers
        </PageTab>
      </ZonePagesTabs>

      <ZonePageList>
        {currentTab === 'zone' && (
          <>
            <SimpleCard>
              <ZonePageItem onClick={() => goToModule('elasticidad')}>
                <img src={CalculatorIcon} alt="" />
                <p>Elasticidad</p>
                <img src={arrowRight} alt="" />
              </ZonePageItem>
            </SimpleCard>

            <SimpleCard>
              <ZonePageItem onClick={() => goToModule('market-share')}>
                <img src={MarketShareIcon} alt="" />
                <p>Market Share</p>
                <img src={arrowRight} alt="" />
              </ZonePageItem>
            </SimpleCard>

            <SimpleCard>
              <ZonePageItem onClick={() => goToModule('zone-business-rules')}>
                <img src={notepad} alt="notepad" />
                <p>Reglas de negocio</p>
                <img src={arrowRight} alt="" />
              </ZonePageItem>
            </SimpleCard>
          </>
        )}
        {currentTab === 'sellers' && (
          <>
            <SimpleCard>
              <ZonePageItem onClick={() => goToModule('pricing')}>
                <img src={pricingImage} alt="" />
                <p>Pricing</p>
                <img src={arrowRight} alt="" />
              </ZonePageItem>
            </SimpleCard>

            <SimpleCard>
              <ZonePageItem onClick={() => goToModule('leads-de-marketing')}>
                <img src={leadsMarketing} alt="" />
                <p>Leads de marketing</p>
                <img src={arrowRight} alt="" />
              </ZonePageItem>
            </SimpleCard>

            <SimpleCard>
              <ZonePageItem onClick={() => goToModule('funnel-de-conversion')}>
                <img src={funnelConvertionImage} alt="" />
                <p>Funnel de conversión</p>
                <img src={arrowRight} alt="" />
              </ZonePageItem>
            </SimpleCard>

            <SimpleCard>
              <ZonePageItem onClick={() => goToModule('tiempo-inventario')}>
                <img src={inventoryTimeIcon} alt="" />
                <p>Tiempo en inventario</p>
                <img src={arrowRight} alt="" />
              </ZonePageItem>
            </SimpleCard>
          </>
        )}

        {currentTab === 'buyers' && (
          <>
            <SimpleCard>
              <ZonePageItem onClick={() => goToModule('revenue')}>
                <img src={revenueImage} alt="" />
                <p>Composición de revenue</p>
                <img src={arrowRight} alt="" />
              </ZonePageItem>
            </SimpleCard>

            <SimpleCard>
              <ZonePageItem onClick={() => goToModule('utility')}>
                <img src={utilityIcon} alt="" />
                <p>Utilidad</p>
                <img src={arrowRight} alt="" />
              </ZonePageItem>
            </SimpleCard>
          </>
        )}
      </ZonePageList>
    </ZonePagesContainer>
  );
};

export default ZonePages;
