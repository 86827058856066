import styled from 'styled-components';
import {
  brightTurquoise600,
  error,
  goldenYellow500,
  white,
} from '../../../../styles/stylesConstants';

export const WrapperToast = styled.div`
  position: fixed;
  right: 24px;
  top: ${(props) => props.theme.top};
  z-index: 99;
  width: 550px;
  height: auto;
  border-radius: 8px;
  display: flex;
  filter: drop-shadow(0px 9px 4px rgba(116, 106, 78, 0.01))
    drop-shadow(0px 5px 3px rgba(116, 106, 78, 0.05))
    drop-shadow(0px 2px 2px rgba(116, 106, 78, 0.09))
    drop-shadow(0px 1px 1px rgba(116, 106, 78, 0.1))
    drop-shadow(0px 0px 0px rgba(116, 106, 78, 0.1));
  background: ${white};

  .toast-content {
    margin: 15px;
    font-size: 14px;
  }

  .success {
    background: ${brightTurquoise600};
  }
  .warning {
    background: ${goldenYellow500};
  }
  .error {
    background: ${error};
  }
`;

export const PlecaToast = styled.div`
  background: ${brightTurquoise600};
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
`;
