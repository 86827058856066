import styled from 'styled-components';

const ButtonContainer = styled.button`
  aspect-ratio: 1 / 1;
  padding: 6px;
  border-radius: 50%;
  background: transparent;
  border: none;
`;

export default ButtonContainer;
