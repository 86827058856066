import { useState } from 'react';
import { ContainerFooterSave, ButtonContainer } from './style';

const FooterSave = ({
  handleOpenModal = () => {},
  handleOpenModalProjection = () => {},
}) => {
  const [first, setfirst] = useState(false);

  setTimeout(() => {
    setfirst(true);
  }, 500);

  return (
    <ContainerFooterSave animation={first}>
      <ButtonContainer>
        <button onClick={handleOpenModalProjection} className="back disabled">
          Proyectar cambios
        </button>
        <button onClick={handleOpenModal} className="save">
          Guardar cambios
        </button>
      </ButtonContainer>
    </ContainerFooterSave>
  );
};

export default FooterSave;
