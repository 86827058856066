import {
  white,
  disabledColor,
  monserratRegular,
  robotoRegular,
  scarpaGray500,
  scarpaGray800,
  electricPurple500,
} from '@habitech/shared';
import styled from 'styled-components';

export const SearchPropertyContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 16px;
  padding: 32px 24px;
  align-items: center;
  border-radius: 6px;
  border: 1px solid ${disabledColor};
  background: ${white};

  .container-input {
    input {
      border-radius: 8px;
    }
    .right {
      display: grid;
      align-items: center;
      justify-content: center;
      height: 48px;
      background-color: ${electricPurple500};
      border-radius: 0 5px 5px 0;
      img {
        height: 24px;
        width: 24px;
        margin: 12px;
      }
    }
  }
`;

export const Title = styled.div`
  font-family: ${monserratRegular};
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 8px;
  color: ${scarpaGray800};
`;

export const Subtitle = styled.div`
  font-family: ${robotoRegular};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: ${scarpaGray500};
`;
