import { SubTitle, WrapperApprovalRules } from './styles';
import PoliticsAndTolerance from './components/PoliticsAndTolerance/PoliticsAndTolerance';
import ReturnLink from '../../components/ReturnLink';
import withRoutePage, { RoutePage } from '../../utils/withRoutePage';

const ApprovalRules: RoutePage = () => {
  return (
    <WrapperApprovalRules>
      <ReturnLink
        text="Regresar al funnel de conversión"
        link="/portal/funnel-de-conversion"
      />
      <SubTitle>Accionable</SubTitle>

      <PoliticsAndTolerance />
    </WrapperApprovalRules>
  );
};

ApprovalRules.requiresAuth = true;

export default withRoutePage(ApprovalRules);
