import zonesClient from '../../utils/zonesClient';
import { GetZoneByMatchProps } from './model';

export const FUNCTION_NAME = process.env.REACT_APP_GET_ZONES_BY_SEARCH || '';

export const getZoneByMatch = async (zone: string) => {
  return zonesClient.get<GetZoneByMatchProps>(FUNCTION_NAME, {
    params: { search: zone },
  });
};
