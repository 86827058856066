import styled from 'styled-components';
import {
  electricPurple800,
  electricPurple25,
  robotoRegular,
} from '@habitech/shared';

const DateDisclaimerContainer = styled.div`
  background: ${electricPurple25};
  height: 50px;
  justify-content: center;
  grid-column: 7/ 11;
  .text {
    line-height: 50px;
    text-align: center;
    vertical-align: middle;
  }
  img {
    margin-right: 8px;
  }

  .disclaimer-text {
    font-family: ${robotoRegular};
    font-size: 16px;
    font-weight: 600;
    color: ${electricPurple800};
  }

  &.no-margin {
    margin: 0px;
  }
`;

export default DateDisclaimerContainer;
