import { DescriptionText, WrapperEmptyState } from './style';
import EmptyStateIcon from './assets/no-data.png';

const EmptyState = () => {
  return (
    <WrapperEmptyState>
      <div className="content-state">
        <img src={EmptyStateIcon} width="225" />
        <DescriptionText>
          Ups, esta zona no cuenta
          <br />
          con reglas de negocio activas
        </DescriptionText>
      </div>
    </WrapperEmptyState>
  );
};

export default EmptyState;
