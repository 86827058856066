import { robotoRegular, scarpaGray500, scarpaGray800 } from '@habitech/shared';
import styled from 'styled-components';
import {
  blue100,
  borderSelect,
  disabled2,
} from '../../../../styles/stylesConstants';

const SelectorContainer = styled.div`
  grid-column: 3/10;
  display: flex;
  align-items: center;
  .MuiInputBase-root {
    border: 1px solid ${borderSelect};
    border-radius: 6px;
  }
  .MuiInputBase-root:focus {
    border: 1px solid blue;
    border-radius: 6px;
  }
  .MuiAutocomplete-root {
    width: 100%;
  }
  .MuiAutocomplete-listbox {
    font-family: ${robotoRegular};
    font-size: 16px;
    overflow: auto;
    .MuiAutocomplete-option[aria-selected='true'] {
      border-left: 1px solid ${blue100};
    }
  }
`;

export const SelectedItemContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-left: -8px;
`;

export const SelectedItem = styled.div`
  border-radius: 100px;
  background: ${disabled2};
  border: 1px solid ${scarpaGray500};
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 8px;
  padding: 4px 16px;
  img {
    margin-left: 8px;
    cursor: pointer;
  }
`;

export const GridSelectContainer = styled.div`
  .MuiFormLabel-root {
    font-size: 16px;
    width: 0;
  }
  .MuiChip-filled {
    font-size: 12px;
  }
`;

export const CustomLabel = styled.div`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: ${scarpaGray800};
  margin-bottom: 4px;
`;

export default SelectorContainer;
