import { FC, ComponentProps, MouseEvent, useRef } from 'react';
import { Bubble, getDatasetAtEvent } from 'react-chartjs-2';
import { Chart, LinearScale, PointElement } from 'chart.js';
import { ChartJSOrUndefined } from 'react-chartjs-2/dist/types';

import {
  principalFont,
  scarpaGray800,
} from '../../../../styles/stylesConstants';
import { BubbleData, BubblePoint } from '../../types';
import { LoaderContainer } from '../../../Pricing/components/LineGraphicMape/styles';
import HabiLoader from '../../../../components/HabiLoader/HabiLoader';

Chart.register(LinearScale, PointElement);

type BubbleOptions = ComponentProps<typeof Bubble<BubblePoint>>['options'];

interface InventoryQualityMatrixProps {
  data?: BubbleData[];
  hasError: boolean;
  maxPurchasesNegotiation: number;
  onClickZone: (medianZoneId: number) => void;
  loadedQuality: boolean;
}

const logRadius = (x: number) => (x < 20 ? x : (x - 20) * (1 / x ** 0.2) + 20);

const InventoryQualityMatrix: FC<InventoryQualityMatrixProps> = ({
  data,
  hasError,
  maxPurchasesNegotiation,
  onClickZone,
  loadedQuality,
}) => {
  const chartRef = useRef<ChartJSOrUndefined<'bubble', [BubblePoint]>>();

  if (hasError) {
    return <p>Failed loading data</p>;
  }

  if (!data) {
    return <p>loading...</p>;
  }

  const BUBBLE_OPTIONS: BubbleOptions = {
    layout: {
      padding: {
        left: 0,
        top: -10,
      },
    },
    responsive: true,
    plugins: {
      legend: { display: false },
      tooltip: {
        callbacks: {
          label(tooltipItem) {
            const rawData = tooltipItem.raw as BubblePoint;

            return `${tooltipItem.label}: (${rawData.y}, ${rawData.x}, ${rawData.sells})`;
          },
        },
      },
    },

    elements: {
      point: {
        radius(ctx) {
          const value: any = ctx.dataset.data[ctx.dataIndex];
          // Math.floor(logRadius(value.sells *3)) change the value * to point size
          return Math.floor(logRadius(value.sells * 0.5));
        },
      },
    },
    scales: {
      y: {
        min: 0,
        max: 110,
        title: {
          display: true,
          text: 'Puntaje revenue',
          color: scarpaGray800,
          font: { weight: '700', family: principalFont },
        },
      },
      x: {
        min: 0,
        max: maxPurchasesNegotiation + 0.25,
        title: {
          display: true,
          text: 'Compras / Gestiones',
          color: scarpaGray800,
          font: { weight: '700', family: principalFont },
        },
      },
    },
  };

  const handleClick = (e: MouseEvent<HTMLCanvasElement>) => {
    if (chartRef.current) {
      const [eventData] = getDatasetAtEvent(chartRef.current, e);
      const selectedId = (eventData.element as any).$context.raw.id;

      onClickZone(selectedId);
    }
  };

  if (!loadedQuality) {
    return (
      <LoaderContainer>
        <HabiLoader size="small" />
      </LoaderContainer>
    );
  }

  return (
    <Bubble
      ref={chartRef}
      data={{ datasets: data }}
      options={BUBBLE_OPTIONS}
      onClick={handleClick}
      redraw={false}
      height="200"
    />
  );
};

export default InventoryQualityMatrix;
