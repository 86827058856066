import styled from 'styled-components';
import {
  white,
  scarpaGray500,
  monserratRegular,
  scarpaGray100,
  robotoRegular,
  electricPurple500,
} from '@habitech/shared';

export const TitleChange = styled.div`
  grid-column: 1/7;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  height: max-content;
  padding-top: 11px;
  .image_pen {
    margin-right: 10px;
  }
  .title {
    font-family: ${monserratRegular};
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
  }
`;

export const TitleZone = styled.div`
  grid-column: 1/7;
  display: flex;
  align-items: center;
  height: max-content;
  .image {
    margin-right: 18px;
  }
  .title {
    font-family: ${monserratRegular};
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
  }
`;
export const TextChange = styled.div`
  grid-column: 1/7;
  font-family: ${robotoRegular};
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 9px;
`;
export const ContainerButtons = styled.div`
  display: flex;
  grid-column: 1/7;
  justify-content: end;
  .no-return {
    width: 145px;
    height: 40px;
    font-family: ${robotoRegular};
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    align-items: center;
    text-align: center;
    border: none;
    color: ${electricPurple500};
    background: ${white};
    cursor: pointer;
  }
  .yes-save {
    display: flex;
    justify-content: center;
    align-items: center;
    background: ${electricPurple500};
    border-radius: 6px;
    border: ${electricPurple500};
    width: 193px;
    height: 40px;
    cursor: pointer;
    .text {
      font-family: ${robotoRegular};
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 16px;
      color: ${white};
    }
  }
`;
export const ContainerModal = styled.div`
  position: relative;
  width: 600px;
  margin: 5% auto;
  justify-self: center;
  align-self: center;
  background: ${white};
  outline: none;
  padding: 35px 20px 19px 22px;
  box-shadow: 0px 0px 1px rgba(48, 49, 51, 0.05),
    0px 16px 24px rgba(48, 49, 51, 0.1);
  height: 77vh;
  overflow-y: auto;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 0 24px;
  padding: 24px 22px;
  .icon-close {
    color: ${scarpaGray500};
    font-size: 19px;
    position: absolute;
    right: 19px;
    top: 19px;

    &:hover {
      cursor: pointer;
    }
    .icon {
      margin-right: 12px;
    }
  }
`;
export const SeparatorWrapper = styled.div`
  grid-column: 1/7;
`;

export const Separator = styled.div`
  width: 100%;
  border-top: 1px solid ${scarpaGray100};
  margin: 16px 0;
`;
