import React from 'react';
import StyledInput from './styles';

interface ICustomInput {
  disabled?: boolean;
  value?: string;
  setValue?: React.Dispatch<React.SetStateAction<string>>;
  limitValue?: number;
}

const CustomInput: React.FC<ICustomInput> = ({
  disabled = false,
  value,
  setValue,
  limitValue = 0,
}) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;

    const regex = new RegExp(
      `^(${limitValue}(\\.[0-9]{1,1})?|10(\\.0{1,1})?|[${limitValue}-9](\\.[0-9]{0,1})?)$`
    );

    if (regex.test(newValue) || newValue === '') {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      setValue && setValue(newValue);
    }
  };

  return (
    <StyledInput
      disabled={disabled}
      value={value}
      onChange={handleChange}
      placeholder="0"
    />
  );
};

export default CustomInput;
