import styled from 'styled-components';
import {
  hintPerano200,
  scarpaGray600,
  scarpaGray800,
  textGray3,
} from '../../../../styles/stylesConstants';

export const WrapperInventoryQuality = styled.div`
  border: 1px solid ${hintPerano200};
  border-left: none;
  border-right: none;
  padding: 20px 0 10px 0;

  .section-title {
    margin-bottom: 24px;
  }
`;
export const TitleZone = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 8px;
`;

export const TitleAndSubtitle = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 2px;
  margin-bottom: 15px;

  .title {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    display: flex;
    align-items: center;
    color: ${scarpaGray600};
  }

  .subtitle {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: ${textGray3};
  }
`;

export const WrapperIndicators = styled.div`
  display: grid;
  grid-template-columns: auto auto auto 1fr;
  gap: 24px;
`;

export const QualityIndicator = styled.div`
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: ${textGray3};
  b {
    font-weight: 700;
    color: ${scarpaGray800};
  }
`;
