import zonesClient from '../../utils/zonesClient';
import {
  IGetInfoQuotasProps,
  IPutQuotasProps,
  IGetMedianZonesProps,
  IPutListProps,
} from './model';

const path: any = process.env.REACT_APP_ZONES_GET_INFO_QUOTAS;

export const getInfoQuotas = async ({
  zone_id,
  quota_type,
  min,
  max,
}: // eslint-disable-next-line consistent-return
IGetInfoQuotasProps) => {
  if (
    process.env.REACT_APP_ZONES_API_URL &&
    process.env.REACT_APP_ZONES_GET_INFO_QUOTAS &&
    process.env.REACT_APP_ZONES_API_KEY
  ) {
    const { data } = await zonesClient.get(path, {
      params: {
        zone_id,
        quota_type,
        min,
        max,
      },
    });

    return data;
  }
};

const pathQuotas: any = process.env.REACT_APP_ZONES_PUT_QUOTAS;

export const putQuotas = async ({
  zone_id,
  type_list,
  value,
  min,
  max,
  quota_type,
}: // eslint-disable-next-line consistent-return
IPutQuotasProps = {}) => {
  if (
    process.env.REACT_APP_ZONES_API_URL &&
    process.env.REACT_APP_ZONES_GET_INFO_QUOTAS &&
    process.env.REACT_APP_ZONES_API_KEY
  ) {
    const { data } = await zonesClient.put(pathQuotas, {
      zone_id,
      type_list,
      value,
      quota_type,
      min,
      max,
    });

    return data;
  }
};

const pathGetMedianZones: any = process.env.REACT_APP_GET_LOTES_MEDIAN_ZONE;

export const getMedianZones = async ({
  zone_id,
  lote_name,
  page,
  items,
}: // eslint-disable-next-line consistent-return
IGetMedianZonesProps = {}) => {
  if (
    process.env.REACT_APP_ZONES_API_URL &&
    process.env.REACT_APP_ZONES_GET_INFO_QUOTAS &&
    process.env.REACT_APP_ZONES_API_KEY
  ) {
    const { data } = await zonesClient.get(pathGetMedianZones, {
      params: {
        zone_id,
        lote_name,
        page,
        items,
      },
    });

    return data;
  }
};

interface IgetListDetail {
  median_zone_id?: number;
  lote_name?: string;
}
const pathGetDetailList: any = process.env.REACT_APP_GET_DETAIL_LIST;

export const getListDetail = async ({
  median_zone_id,
  lote_name,
}: // eslint-disable-next-line consistent-return
IgetListDetail = {}) => {
  if (
    process.env.REACT_APP_ZONES_API_URL &&
    process.env.REACT_APP_ZONES_GET_INFO_QUOTAS &&
    process.env.REACT_APP_ZONES_API_KEY
  ) {
    const { data } = await zonesClient.get(pathGetDetailList, {
      params: {
        median_zone_id,
        lote_name,
      },
    });

    return data;
  }
};

const pathPutList: any = process.env.REACT_APP_PUT_LIST;

export const putList = async ({
  zone_id,
  type_list,
  lote_name,
  reason,
  note,
  rotation,
  user_email,
  current_list,
}: // eslint-disable-next-line consistent-return
IPutListProps = {}) => {
  if (
    process.env.REACT_APP_ZONES_API_URL &&
    process.env.REACT_APP_ZONES_GET_INFO_QUOTAS &&
    process.env.REACT_APP_ZONES_API_KEY
  ) {
    const { data } = await zonesClient.put(pathPutList, {
      zone_id,
      type_list,
      lote_name,
      reason,
      note,
      rotation,
      user_email,
      current_list,
    });

    return data;
  }
};

export const patchList = async ({
  zone_id,
  lote_name,
  rotation,
}: // eslint-disable-next-line consistent-return
IPutListProps = {}) => {
  if (
    process.env.REACT_APP_ZONES_API_URL &&
    process.env.REACT_APP_ZONES_GET_INFO_QUOTAS &&
    process.env.REACT_APP_ZONES_API_KEY
  ) {
    const { data } = await zonesClient.patch(pathPutList, {
      zone_id,
      lote_name,
      rotation,
    });

    return data;
  }
};
