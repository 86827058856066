import { FC, useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart, BarElement } from 'chart.js';
import {
  robotoRegular,
  scarpaGray600,
  skyBlue100,
  skyBlue900,
} from '@habitech/shared';

import { TitleSection } from '../Opportunities/styles';
import AutoPricingContainer from './styles';
import {
  TitleAndSubtitle,
  TitleZone,
} from '../../../MarketingLeads/components/LineGraphic/styles';
import { MetropolitanAreas } from '../../../SelectZone/types';
import { getAutoPricing } from '../../../../apis/pricing/pricing';

import HabiMoney from '../Opportunities/assets/habi-money.svg';
import LocationIcon from '../../../MarketingLeads/components/LineGraphic/assets/location.svg';
import { LoaderContainer } from '../../../Utility/style';
import HabiLoader from '../../../../components/HabiLoader/HabiLoader';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { useAppSelector } from '../../../../redux/hooks';
import { selectUserDateRange } from '../../../../redux/reducers/userSelectors';

interface AutoPricingProps {
  metropolitanArea: MetropolitanAreas;
}

const AutoPricing: FC<AutoPricingProps> = ({ metropolitanArea }) => {
  const [autoPricingData, setAutoPricingData] = useState<number[]>([]);
  const [autoPricingLabels, setAutoPricingLabels] = useState<string[]>([]);
  const userDateRange = useAppSelector(selectUserDateRange);
  Chart.register(BarElement, ChartDataLabels);

  useEffect(() => {
    const start_date = new Date(userDateRange[0])
      .toISOString()
      .substring(0, 10);
    const end_date = new Date(userDateRange[1]).toISOString().substring(0, 10);
    getAutoPricing({
      metropolitan_area_id: metropolitanArea.id,
      start_date,
      end_date,
    }).then((response) => {
      if (response.success) {
        const data: number[] = [];
        const labels: string[] = [];
        const dateFormatter = new Intl.DateTimeFormat('default', {
          timeZone: 'UTC',
          month: 'short',
          year: 'numeric',
        });

        response.data.forEach((item) => {
          const itemDate = new Date(item.month_metro);
          const parsedDate = dateFormatter.format(itemDate);

          data.push(item.per_automatic_committee);
          labels.push(parsedDate);
        });

        setAutoPricingData(data);
        setAutoPricingLabels(labels);
      }
    });
  }, [userDateRange]);

  const loaded = !!autoPricingLabels.length && !!autoPricingData;

  return (
    <AutoPricingContainer>
      <TitleSection>
        <img src={HabiMoney} alt="Icono de una casa con dinero" />

        <div className="title">Autopricing</div>
      </TitleSection>

      <TitleZone>
        <img src={LocationIcon} alt="pin del mapa" />
        <TitleAndSubtitle>
          <div className="title">{metropolitanArea.label}</div>
          <div className="subtitle">Área Metropolitana</div>
        </TitleAndSubtitle>
      </TitleZone>

      {loaded ? (
        <Bar
          id="auto-pricing"
          data={{
            labels: autoPricingLabels,
            datasets: [
              {
                data: autoPricingData,
                backgroundColor: [skyBlue100],
                datalabels: {
                  color: skyBlue900,
                  formatter: function (value) {
                    return value + '%';
                  },
                  font: {
                    weight: 600,
                    family: 'Montserrat',
                  },
                },
              },
            ],
          }}
          options={{
            plugins: { legend: { display: false } },
            scales: {
              y: {
                title: {
                  display: true,
                  text: 'Autopricing',
                  color: scarpaGray600,
                  font: { weight: '600', family: robotoRegular },
                },
              },
              x: {
                title: {
                  display: true,
                  text: 'Fecha precio',
                  color: scarpaGray600,
                  font: { weight: '600', family: robotoRegular },
                },
              },
            },
          }}
        />
      ) : (
        <LoaderContainer>
          <HabiLoader size="small" />
        </LoaderContainer>
      )}
    </AutoPricingContainer>
  );
};

export default AutoPricing;
