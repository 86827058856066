import { ContainerAreasProps } from './model';
import { ContentWrapper, TitleContainer, WrapperContainer } from './styles';

const ContainerAreas: React.FC<ContainerAreasProps> = ({ title, children }) => {
  return (
    <WrapperContainer>
      {title && <TitleContainer>{title}</TitleContainer>}
      <ContentWrapper>{children}</ContentWrapper>
    </WrapperContainer>
  );
};

export default ContainerAreas;
