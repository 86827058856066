import zonesClient from '../../utils/zonesClient';
import {
  IGetElasticityGraphicProps,
  IGetElasticityIndicatorsProps,
} from './model';

const pathElasticityIndicators =
  process.env.REACT_APP_GET_ELASTICITY_INDICATORS;

export const getElasticityIndicators = async (
  params: IGetElasticityIndicatorsProps
) => {
  const { data } = await zonesClient.get<IApiResponse>(
    pathElasticityIndicators!,
    { params }
  );

  return data;
};

const pathElasticity = process.env.REACT_APP_GET_ELASTICITY_LINE;

export const getElasticityLineGraphic = async (
  params: IGetElasticityGraphicProps
) => {
  const { data } = await zonesClient.get<IApiResponse>(pathElasticity!, {
    params,
  });

  return data;
};
