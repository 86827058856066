import styled from 'styled-components';

interface ReasonItemColorProps {
  color: string;
}

const ReasonItemColor = styled.div<ReasonItemColorProps>`
  display: inline-block;
  margin-right: 8px;
  width: 12px;
  height: 12px;
  background-color: ${({ color }) => color};
  border-radius: 2px;
`;

export default ReasonItemColor;
