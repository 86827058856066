import {
  electricPurple500,
  skyBlue500,
  tomatoCoral400,
} from '@habitech/shared';

export const MONTHS_DICT: any = {
  EN: {
    january: 'Enero',
    february: 'Febrero',
    march: 'Marzo',
    april: 'Abril',
    may: 'Mayo',
    june: 'Junio',
    july: 'Julio',
    august: 'Agosto',
    september: 'Septiembre',
    october: 'Octubre',
    november: 'Noviembre',
    necember: 'Diciembre',
  },
};

export const getDictLines = (label: any) => {
  switch (label) {
    case 'initial_expectation':
      return {
        borderColor: electricPurple500,
        backgroundColor: electricPurple500,
        label: 'Precios por arriba',
        borderWidth: 1.5,
        datalabels: {
          display: false,
        },
      };
    case 'initial_customer':
      return {
        borderColor: tomatoCoral400,
        backgroundColor: tomatoCoral400,
        label: 'Precios dentro del rango',
        borderWidth: 1.5,
        datalabels: {
          display: false,
        },
      };
    case 'initial_committee':
      return {
        borderColor: skyBlue500,
        backgroundColor: skyBlue500,
        label: 'Precios por debajo',
        borderWidth: 1.5,
        datalabels: {
          display: false,
        },
      };
    default:
      break;
  }
};
