// Imges
import emptyLogo from '../../assets/icons/utilidad-empty.svg';

// Styles
import EmptyStateWrapper, {
  BodyText,
  FooterText,
  InformationContainer,
  TitleText,
} from './styles';

interface EmptyStateProps {
  title: string;
  body: string;
  footer?: string;
}

const EmptyState = ({ title, body, footer }: EmptyStateProps) => {
  return (
    <EmptyStateWrapper>
      <img src={emptyLogo} alt="" />
      <InformationContainer>
        <TitleText>{title}</TitleText>
        <BodyText>{body}</BodyText>
        {footer && <FooterText>{footer}</FooterText>}
      </InformationContainer>
    </EmptyStateWrapper>
  );
};

export default EmptyState;
