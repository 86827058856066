export const dictionaryGoodZoneTableCo = {
  date_committee: 'Fecha de comité',
  nid: 'NID',
  property_set: 'Conjunto',
  good_zone: 'Zona',
  commercial_attractive: 'Atractivo comercial',
  historical_deals: 'Negocios Históticos',
  pricing_trust: 'Confianza en pricing',
  approbology_trust_rating: 'Calificación',
  reject_reason: 'Razón rechazo',
  approval_user: 'Aprobólogo',
  distance: 'Distancia',
};
