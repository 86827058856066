import dateToParams from '../../utils/dateToParams';
import zonesClient from '../../utils/zonesClient';
import { GetMarketingLeadsProps } from './model';

export const FUNCTION_NAME = process.env.REACT_APP_GET_LEADS_LINE || '';

export const getMarketingLeads = async (zoneId: number, date: Date[]) => {
  const dateParams = dateToParams(date[0], date[1]);

  const { data } = await zonesClient.get<GetMarketingLeadsProps>(
    FUNCTION_NAME,
    {
      params: { zone_id: zoneId, ...dateParams },
    }
  );

  return data;
};
