import { FC } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@mui/material';

import { TableWrapper } from '../FunnelGraph/style';
import { RejectedReason } from '../../model/rejectionReasons';
import ReasonItemColor from './styles';
import { GRAPHIC_COLORS } from '../RejectionReasonsGraphic/utils';

interface ReasonsTableProps {
  reasons: RejectedReason[];
}

const reasonsSortAlg = (reasonA: RejectedReason, reasonB: RejectedReason) =>
  Math.sign(reasonB.value - reasonA.value);

const reasonsMapColor = (reason: RejectedReason, i: number) => {
  return {
    ...reason,
    color: GRAPHIC_COLORS[i % GRAPHIC_COLORS.length],
  };
};

const ReasonsTable: FC<ReasonsTableProps> = ({ reasons }) => {
  return (
    <TableWrapper>
      <TableContainer component={Paper} sx={{ minHeight: '300px' }}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell width="80%">
                Razones de rechazo en el área metropolitana
              </TableCell>
              <TableCell width="20%">% de rechazo</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {reasons
              .map(reasonsMapColor)
              .sort(reasonsSortAlg)
              .map((item) => {
                return (
                  <TableRow sx={{ td: { padding: '8px' } }} key={item.label}>
                    <TableCell>
                      <ReasonItemColor color={item.color} />
                      <span>{item.label}</span>
                    </TableCell>
                    <TableCell>{item.value}%</TableCell>
                  </TableRow>
                );
              })}

            <TableRow
              sx={{
                td: { padding: '8px' },
                '&:last-child td, &:last-child th': {
                  border: 0,
                  fontWeight: 600,
                },
              }}
            >
              <TableCell>Porcentaje total</TableCell>
              <TableCell>
                {reasons.reduce((acc, val) => acc + val.value, 0).toFixed(0)}%
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </TableWrapper>
  );
};

export default ReasonsTable;
