import {
  scarpaGray800,
  robotoRegular,
  scarpaGray500,
  tomatoCoral200,
  tomatoCoral600,
  scarpaGray600,
  scarpaGray700,
  white,
  electricPurple500,
} from '@habitech/shared';
import styled from 'styled-components';

import {
  blueLink,
  borderGrayResume,
  disabled2,
  errorBackground,
  principalFont,
  withoutListBorder,
  withoutList,
  withoutListColor,
  whiteList,
  goodComplex,
  goodComplexBorder,
  goodComplexColor,
} from '../../../../styles/stylesConstants';

export const WrapperAssignListToSet = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: repeat(9, 1fr);
  gap: 24px;
`;

export const ColumnDescription = styled.div`
  grid-column: 1/3;
  font-family: ${robotoRegular};
  .title {
    font-size: 14px;
    font-weight: 600;
    color: ${scarpaGray800};
    margin-bottom: 6px;
  }
  .size {
    width: 130px;
  }
  .subtitle {
    font-size: 10px;
    font-weight: 400;
    color: ${scarpaGray500};
  }
`;

export const SeparatorWrapper = styled.div`
  grid-column: 1/10;
`;

export const Separator = styled.div`
  width: 100%;
  border-top: 1px solid ${borderGrayResume};
  margin: 15px 0;
`;

export const SearchContent = styled.div`
  grid-column: 3/8;
  display: flex;

  input {
    height: 38px;
    padding-right: 70px;
    border-radius: 6px;
    width: 100%;
    border: 2px solid ${blueLink};
    color: ${scarpaGray800};
    font-size: 16px;

    &:focus,
    &:active {
      outline: none;
    }
  }

  .search {
    width: 40px;
    height: 43px;
    position: relative;
    right: 38px;
    cursor: pointer;

    &.search-close {
      right: 68px;
    }
  }

  .close {
    width: 30px;
    height: 33px;
    margin-top: 5px;
    position: relative;
    right: 68px;
    cursor: pointer;
  }
`;

export const TableLoader = styled.div`
  margin-top: 15px;
  height: 35%;
  display: grid;
  justify-content: center;
  align-items: center;
`;

export const TableWrapper = styled.div`
  grid-column: 3/ 10;
`;

export const PaginationWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  grid-column: 1/10;
`;

export const ListButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
  margin-top: 20px;

  button {
    margin-left: 30px;
    font-family: ${principalFont};
    font-weight: 600;
    font-size: 13px;
  }
`;

export const ButtonsWrapper = styled.div`
  grid-column: 1 / 6;

  .buttons-content {
    display: flex;
    align-items: center;

    button {
      margin-right: 30px;
      font-family: ${principalFont};
      font-weight: 700;
      font-size: 14px;
    }
  }
`;

export const ZoneInfoTable = styled.div`
  display: flex;
  flex-direction: column;
  font-family: ${robotoRegular};
  font-size: 14px;
  .title {
    font-weight: 600;
    color: ${scarpaGray800};
  }
  .zone-id {
    font-weight: 400;
    color: ${scarpaGray500};
  }
`;

export const CurrentListWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  font-family: ${robotoRegular};
  border-radius: 6px;
  width: 129px;
  height: 40px;
  &.black {
    background: ${errorBackground};
    border: 1px solid ${tomatoCoral200};
    color: ${tomatoCoral600};
  }
  &.grey {
    background: ${disabled2};
    border: 1px solid ${scarpaGray600};
    color: ${scarpaGray700};
  }
  &.sin-lista {
    background: ${withoutList};
    border: 1px solid ${withoutListBorder};
    color: ${withoutListColor};
  }
  &.white {
    background: ${whiteList};
    border: 1px solid ${withoutListBorder};
    color: ${withoutListColor};
  }
  &.buen-conjunto {
    background: ${goodComplex};
    border: 1px solid ${goodComplexBorder};
    color: ${goodComplexColor};
  }
`;

export const SwitchWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const EditButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${white};
  border-radius: 6px;
  border: 1px solid ${electricPurple500};
  color: ${electricPurple500};
  width: 133px;
  height: 40px;
  font-family: ${robotoRegular};
  font-weight: 400;
  font-size: 16px;
  cursor: pointer;
  img {
    margin-left: 10px;
  }
`;

export const PaginationInfo = styled.div`
  font-family: ${robotoRegular};
  font-size: 14px;
  font-weight: 600;
  color: ${scarpaGray700};
`;
