// Libraires
import { useRef, useEffect, useState, useCallback } from 'react';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import RemoveRoundedIcon from '@mui/icons-material/RemoveRounded';

// Images
import markerLocation from '../../../static/assets/icons/location.svg';
import { electricPurple200, purple } from '../../../styles/stylesConstants';
import { ControlZoom, WrapperControlZoom } from '../styles';

interface MapProps {
  location: any | null;
  polygonZone?: any;
}

interface PolygonPoint {
  lat: number;
  lng: number;
}

declare global {
  interface Window {
    google: any;
  }
}

const MapComponent = ({ location, polygonZone }: MapProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const [map, setMap] = useState<any>(null);
  const [marker, setMarker] = useState<any>(null);
  const [circle, setCircle] = useState<any>(null);
  const [, setPolygon] = useState<any>(null);

  const setMarkerCenter = useCallback((center: any, currentMap: any) => {
    if (currentMap !== null) {
      const newMarker = new window.google.maps.Marker({
        position: center,
        title: 'Hola!',
        icon: markerLocation,
        map: currentMap,
      });

      currentMap.setCenter(center);

      setMarker((oldMarker: any) => {
        oldMarker?.setMap(null);

        return newMarker;
      });
    }
  }, []);

  const setCircleMap = useCallback(
    (center: any, currentMap: any, radius: number) => {
      if (currentMap !== null) {
        const newCircle = new window.google.maps.Circle({
          strokeColor: purple,
          strokeOpacity: 1,
          strokeWeight: 1.5,
          fillColor: electricPurple200,
          fillOpacity: 0.5,
          map: currentMap,
          center,
          radius,
        });

        setCircle((oldCircle: any) => {
          oldCircle?.setMap(null);

          return newCircle;
        });
      }
    },
    []
  );

  const setPolygonMap = useCallback(
    (polygonInfo: PolygonPoint[], currentMap: any) => {
      if (currentMap !== null) {
        circle?.setMap(null);

        const newPolygon = new window.google.maps.Polygon({
          paths: polygonInfo,
          strokeColor: purple,
          strokeOpacity: 0.8,
          strokeWeight: 2,
          fillColor: electricPurple200,
          fillOpacity: 0.35,
        });
        newPolygon.setMap(currentMap);

        setPolygon((oldPolygon: any) => {
          oldPolygon?.setMap(null);

          return newPolygon;
        });
      }
    },
    [circle]
  );

  useEffect(() => {
    if (!ref.current) return;

    let lat = 3.5133736622381644;
    let lng = -73.1887258732071;

    if (process.env.REACT_COUNTRY === 'MX') {
      lat = 19.388737;
      lng = -99.138815;
    }

    const center = { lat, lng };

    // Init map
    const currentMap = new window.google.maps.Map(ref.current, {
      center,
      zoom: 6,
      streetViewControl: false,
      mapTypeControl: false,
      controlSize: 34,
      fullscreenControl: false,
      streetViewControlOptions: {
        position: window.google.maps.ControlPosition.LEFT_BOTTOM,
      },
      styles: [
        {
          featureType: 'water',
          elementType: 'geometry.fill',
          stylers: [
            {
              color: '#e5e9fb',
            },
          ],
        },
        {
          featureType: 'transit',
          stylers: [
            {
              color: '#808080',
            },
            {
              visibility: 'off',
            },
          ],
        },
        {
          featureType: 'road.highway',
          elementType: 'geometry.stroke',
          stylers: [
            {
              visibility: 'on',
            },
            {
              color: '#b3b3b3',
            },
          ],
        },
        {
          featureType: 'road.highway',
          elementType: 'geometry.fill',
          stylers: [
            {
              color: '#ffffff',
            },
          ],
        },
        {
          featureType: 'road.local',
          elementType: 'geometry.fill',
          stylers: [
            {
              visibility: 'on',
            },
            {
              color: '#f0f0f0',
            },
            {
              weight: 1.8,
            },
          ],
        },
        {
          featureType: 'road.local',
          elementType: 'geometry.stroke',
          stylers: [
            {
              color: '#d7d7d7',
            },
          ],
        },
        {
          featureType: 'poi',
          elementType: 'geometry.fill',
          stylers: [
            {
              visibility: 'on',
            },
            {
              color: '#ebebeb',
            },
          ],
        },
        {
          featureType: 'administrative',
          elementType: 'geometry',
          stylers: [
            {
              color: '#a7a7a7',
            },
          ],
        },
        {
          featureType: 'road.arterial',
          elementType: 'geometry.fill',
          stylers: [
            {
              color: '#ffffff',
            },
          ],
        },
        {
          featureType: 'road.arterial',
          elementType: 'geometry.fill',
          stylers: [
            {
              color: '#ffffff',
            },
          ],
        },
        {
          featureType: 'landscape',
          elementType: 'geometry.fill',
          stylers: [
            {
              visibility: 'on',
            },
            {
              color: '#ffffff',
            },
          ],
        },
        {
          featureType: 'road',
          elementType: 'labels.text.fill',
          stylers: [
            {
              color: '#696969',
            },
          ],
        },
        {
          featureType: 'administrative',
          elementType: 'labels.text.fill',
          stylers: [
            {
              visibility: 'on',
            },
            {
              color: '#737373',
            },
          ],
        },
        {
          featureType: 'poi',
          elementType: 'labels.icon',
          stylers: [
            {
              visibility: 'off',
            },
          ],
        },
        {
          featureType: 'poi',
          elementType: 'labels',
          stylers: [
            {
              visibility: 'off',
            },
          ],
        },
        {
          featureType: 'road.arterial',
          elementType: 'geometry.stroke',
          stylers: [
            {
              color: '#d6d6d6',
            },
          ],
        },
        {
          featureType: 'road',
          elementType: 'labels.icon',
          stylers: [
            {
              visibility: 'off',
            },
          ],
        },
        {
          featureType: 'poi',
          elementType: 'geometry.fill',
          stylers: [
            {
              color: '#dadada',
            },
          ],
        },
      ],
    });

    if (polygonZone === null) {
      // Principal marker
      setMarkerCenter(center, currentMap);
    }

    setMap(currentMap);
  }, [polygonZone, setCircleMap, setMarkerCenter]);

  // location update
  useEffect(() => {
    if (location !== null && map !== null) {
      // Update principal marker
      setMarkerCenter(location, map);

      // update Circle
      setCircleMap(location, map, 4000);

      map.setZoom(11);
    }
  }, [location, map, setCircleMap, setMarkerCenter]);

  // polygon update
  useEffect(() => {
    if (polygonZone !== null && map !== null) {
      marker?.setMap(null);

      // Update polygon
      setPolygonMap(polygonZone.data, map);

      // Get center off polygon
      const bounds = new window.google.maps.LatLngBounds();

      let tempPolygon = [];

      if (polygonZone.data[0].length === undefined) {
        tempPolygon = polygonZone.data;
      } else {
        tempPolygon = polygonZone.data[0];
      }

      tempPolygon.map((point: PolygonPoint) => {
        bounds.extend(point);
        return new window.google.maps.LatLng(point.lat, point.lng);
      });

      const centerPolygon = {
        lat: bounds.getCenter().lat(),
        lng: bounds.getCenter().lng(),
      };

      map.setCenter(centerPolygon);
      map.setZoom(polygonZone.zoom);
    }
  }, [polygonZone, map, marker, setPolygonMap]);

  const zoomIn = () => {
    if (map.zoom < 18 && ref.current) {
      map.setZoom(map.zoom + 1);
    }
  };

  const zoomOut = () => {
    if (map.zoom > 1) map.setZoom(map.zoom - 1);
  };

  return (
    <>
      <div ref={ref} id="map" className="map-container" />
      <WrapperControlZoom>
        <ControlZoom
          className='zoom-in'
          data-testid='zoom-in'
          onClick={() => {
            zoomIn();
          }}
        >
          <AddRoundedIcon />
        </ControlZoom>
        <ControlZoom
          className='zoom-out'
          data-testid='zoom-out'
          onClick={() => {
            zoomOut();
          }}
        >
          <RemoveRoundedIcon />
        </ControlZoom>
      </WrapperControlZoom>
    </>
  );
};

export default MapComponent;
