import styled from 'styled-components';
import {
  monserratRegular,
  robotoRegular,
  scarpaGray300,
  scarpaGray500,
  scarpaGray800,
  tomatoCoral600,
  white,
} from '@habitech/shared';
import { ruleInfo } from '../../../../styles/stylesConstants';

export const WrapperRulesTable = styled.div`
  display: flex;
  margin-top: 19px;
  .container-table {
    max-width: 700px;
    .disabled {
      border: 1px solid ${scarpaGray300};
      border: 1px solid ${scarpaGray300};
      text-align: center;
      width: 104px;
      padding-left: 15px;
      input {
        opacity: 0.6;
        color: ${scarpaGray500};
        font-weight: 600;
        height: 40px;
      }
    }
  }
  table {
    thead {
      tr > th {
        font-family: ${robotoRegular};
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 20px;
        color: ${scarpaGray800};
      }
    }
    tbody {
      tr {
        th {
          padding: 12px 24px;
          font-family: ${monserratRegular};
          font-style: normal;
          font-weight: 600;
          font-size: 13px;
          line-height: 20px;
          color: ${scarpaGray800};
        }
      }
    }
  }
`;

export const ElementText = styled.div`
  font-family: ${robotoRegular};
  font-weight: 600;
  font-size: 14px;
  color: ${scarpaGray800};
  .subtitle {
    font-weight: 400;
    color: ${scarpaGray500};
  }
`;

export const RuleInfo = styled.div`
  display: flex;
  justify-content: center;
  border-radius: 6px;
  padding: 8px 16px;
  background: ${ruleInfo};
  border: 1px solid ${scarpaGray300};
  font-family: ${robotoRegular};
  font-weight: 600;
  font-size: 14px;
`;

export const DeleteButton = styled.button`
  display: flex;
  justify-content: center;
  border-radius: 6px;
  padding: 8px 16px;
  background: ${white};
  border: 1px solid ${tomatoCoral600};
  font-family: ${robotoRegular};
  font-weight: 400;
  font-size: 14px;
  color: ${tomatoCoral600};
  cursor: pointer;
  padding: 8px 16px;
  img {
    margin-left: 6px;
  }
`;
