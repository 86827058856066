import ColumnDescription from '../../../DiscountEngine/components/TabRules/style';
import { ISelectTypeZone } from '../../model';
import TabContainer, { Divisor, TabItem } from './styles';

const SelectTypeZone: React.FC<ISelectTypeZone> = ({
  tabSelected,
  setTabSelected,
}) => {
  const handleSelected = (id: number) => {
    setTabSelected(id);
  };

  return (
    <>
      <ColumnDescription>
        <div className="subtitle">
          Selecciona el tipo de zona al que quieres aplicar los cambios
        </div>
      </ColumnDescription>

      <TabContainer>
        <TabItem
          className={`first ${tabSelected === 1 && 'selected'}`}
          onClick={() => {
            handleSelected(1);
          }}
        >
          Área metropolitana
        </TabItem>
        <TabItem
          className={`${tabSelected === 2 && 'selected'}`}
          onClick={() => {
            handleSelected(2);
          }}
        >
          Ciudad
        </TabItem>
        <TabItem
          className={`last ${tabSelected === 3 && 'selected'}`}
          onClick={() => {
            handleSelected(3);
          }}
        >
          Zona mediana
        </TabItem>
      </TabContainer>

      <Divisor />
    </>
  );
};

export default SelectTypeZone;
