import ReturnLink from '../../components/ReturnLink';
import CardZoneBusinessRuler from './components/CardZoneRules/CardZoneBusinessRuler';
import {
  WrapperZoneBusinessRules,
  TitleZonseBussinesRules,
  ContainerRulesBusinessZone,
} from './styles';

const ZonaBussinessRules = () => {
  return (
    <WrapperZoneBusinessRules>
      <ReturnLink
        text="Regresar a análisis de zona"
        link="/portal/analisis-de-zona"
      />
      <TitleZonseBussinesRules>
        Reglas de negocio en la zona
      </TitleZonseBussinesRules>
      <ContainerRulesBusinessZone>
        <CardZoneBusinessRuler />
      </ContainerRulesBusinessZone>
    </WrapperZoneBusinessRules>
  );
};

export default ZonaBussinessRules;
