import {
  monserratRegular,
  robotoRegular,
  scarpaGray500,
  scarpaGray800,
  white,
} from '@habitech/shared';
import styled from 'styled-components';
import { titleRule } from '../../../../styles/stylesConstants';

const CardWrapperRule = styled.div`
  background: ${white};
  box-shadow: 0px 0px 1px rgba(48, 49, 51, 0.05),
    0px 2px 4px rgba(48, 49, 51, 0.1);
  border-radius: 6px;
  display: grid;
  padding: 25px 31px;
  margin-top: 57px;
`;

export const ReturnLinkWrapper = styled.div`
  display: flex;
  margin-top: 29px;
  cursor: pointer;
  img {
    margin-right: 15px;
  }
`;

export const ReturnLinkText = styled.div`
  font-family: ${monserratRegular};
  font-weight: 700;
  font-size: 14px;
  color: ${scarpaGray800};
`;

export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  img {
    margin-right: 10px;
  }
  .title {
    font-family: ${monserratRegular};
    font-size: 16px;
    font-weight: 600;
    color: ${titleRule};
  }
`;

export const SubtitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  .title {
    font-family: ${robotoRegular};
    font-size: 16px;
    font-weight: 600;
    color: ${scarpaGray800};
  }
  .subtitle {
    font-family: ${robotoRegular};
    font-size: 12px;
    font-weight: 400;
    color: ${scarpaGray500};
  }
`;

export const ContainerModalRule = styled.div`
  position: relative;
  width: 328px;
  margin: 5% auto;
  justify-self: center;
  align-self: center;
  background: ${white};
  outline: none;
  padding: 35px 20px 19px 22px;
  box-shadow: 0px 0px 1px rgba(48, 49, 51, 0.05), 0px 16px 24px rgba(48, 49, 51, 0.1);
  overflow-y: auto;
  .icon-close {
    color:${scarpaGray500}
    font-size: 19px;
    position: absolute;
    right: 19px;
    top: 19px;

    &:hover {
      cursor: pointer;
    }
  }
  .icon{
    margin-right: 12px;
  }
`;

export const ModalImage = styled.div`
  text-align: center;
  margin: 16px 0;
`;

export const ModalContent = styled.div`
  .title {
    font-family: ${monserratRegular};
    font-size: 24px;
    font-weight: 600;
    color: ${scarpaGray800};
  }
  .subtitle {
    font-family: ${robotoRegular};
    font-size: 14px;
    font-weight: 400;
    color: ${scarpaGray500};
    margin-bottom: 16px;
    margin-top: 8px;
  }
`;

export const ModalButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-bottom: 40px;
`;

export default CardWrapperRule;
