import type { AxiosRequestConfig } from 'axios';
import deepClone from './deepClone';

const globalInterceptor = (config: AxiosRequestConfig): AxiosRequestConfig => {
  const newConfig: AxiosRequestConfig = deepClone(config);
  const country = localStorage.getItem('country');
  const token = JSON.parse(String(localStorage.getItem('TOKEN')));

  if (newConfig && newConfig.headers) {
    newConfig.headers.Authorization = `Bearer ${token ? token?.IdToken : ''}`;
  }

  if (country === 'CO') {
    if (config.method === 'get' || config.method === 'delete') {
      newConfig.url = `${config.url}?country=${process.env.REACT_COUNTRY}`;
    } else {
      newConfig.data.country = process.env.REACT_COUNTRY;
    }
  } else if (config.method === 'get' || config.method === 'delete') {
    newConfig.url = `${config.url}?country=MX`;
  } else {
    newConfig.data.country = 'MX';
  }

  return {
    ...config,
    url: newConfig.url,
    data: newConfig.data,
    headers: newConfig.headers,
  };
};

export default globalInterceptor;
