import styled from 'styled-components';
import { borderGrayResume } from '../../styles/stylesConstants';

const RevenueWrapper = styled.div`
  width: 100%;

  .section-divider {
    height: 1px;
    width: 100%;
    margin: 16px auto;
    background-color: ${borderGrayResume};
  }
`;

export default RevenueWrapper;
