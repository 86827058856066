import {
  TitleSelectZone,
  WelcomeTitle,
  ZoneFormHeaderContainer,
} from './styles';

const ZoneFormHeader = () => {
  return (
    <ZoneFormHeaderContainer>
      <WelcomeTitle>ENCUENTRA LA ZONA</WelcomeTitle>

      <TitleSelectZone>
        <span>Selecciona </span>
        la zona que deseas consultar
      </TitleSelectZone>
    </ZoneFormHeaderContainer>
  );
};

export default ZoneFormHeader;
