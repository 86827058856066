import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import BtnChevrondown from '../../assets/icons/BtnChevrondown.svg';
import BtnChevronUp from '../../assets/icons/BtnChevronUp.svg';
import imgMexico from '../../static/assets/icons/mexico.svg';
import imgColombia from '../../static/assets/icons/colombia.png';
import exitIcon from '../../assets/icons/exit.svg';
import { HeaderWrapper } from '../Layout/style';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { selectUser } from '../../redux/reducers/userSelectors';
import { userStoreActions } from '../../redux/reducers/userReducer';
import InteractiveButton from '../InteractiveButton';
import { TOKEN } from '../../shared/constants';
import { authRevoke } from '../../apis/auth/auth.api';

const LayoutHeaderClean = () => {
  const navigator = useNavigate();
  const dispatch = useAppDispatch();
  const user = useAppSelector(selectUser);
  const [showMenu, setShowMenu] = useState(false);
  const country = localStorage.getItem('country');

  const handleUserMenu = () => {
    setShowMenu(!showMenu);
  };

  const handleRevokeToken = async () => {
    try {
      await authRevoke(TOKEN?.RefreshToken, country);
    } catch (error) {
      console.error(error);
    }

    window.history.replaceState(null, '', '/');
    navigator('/');
    localStorage.clear();
  };

  const onLogout = () => {
    dispatch(userStoreActions.clearUser());
    handleRevokeToken();
  };

  return (
    <HeaderWrapper>
      <div className="header-container">
        <div className="header-divider" />

        <div className="user-info">
          <div className="country-image">
            <img src={country === 'MX' ? imgMexico : imgColombia} alt="" />
          </div>
          <div className="user-image">
            <img
              src={user?.imageProfile}
              referrerPolicy="no-referrer"
              alt="Imagen de perfil"
            />
          </div>

          <InteractiveButton onClick={handleUserMenu}>
            <img
              src={showMenu ? BtnChevronUp : BtnChevrondown}
              className="pointer"
              alt="menu para cerrar sesion"
            />
          </InteractiveButton>

          {showMenu && (
            <div
              role="button"
              className="menu-user"
              tabIndex={0}
              onKeyDown={(ev) => ev.key === 'Enter' && onLogout()}
              onClick={onLogout}
            >
              Cerrar sesión
              <img src={exitIcon} alt="Icono de cerrar sesión" />
            </div>
          )}
        </div>
      </div>
    </HeaderWrapper>
  );
};

export default LayoutHeaderClean;
