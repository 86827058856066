import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { useAppSelector } from '../../../../redux/hooks';
import {
  CategoryGraph,
  Content150Days,
  DaysIndicator,
  DaysMediaIndicator,
  Indicator150Days,
  Indicator150Title,
  IndicatorsZone,
  SectionIndicator,
  WrapperChart,
  WrapperHistogram,
} from './styles';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { useEffect, useState } from 'react';
import { getInventoryTimeMA } from '../../../../apis/inventoryTime/inventoryTime';
import {
  histogramData,
  labelsHistogram,
  optionsHistogram,
} from '../../utils/utilsGraphic';
import { TData } from './model';
import {
  TitleAndSubtitle,
  TitleZone,
} from '../../../Pricing/components/Opportunities/styles';
import LocationIcon from '../../assets/location.svg';
import CalendarIcon from '../../assets/calendar-home.svg';
import HouseIcon from '../../assets/house-price.svg';
import { skyBlue100, electricPurple300 } from '@habitech/shared';
import { Button } from '@habitech/call-to-action';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { LoaderContainer } from '../../../Utility/style';
import HabiLoader from '../../../../components/HabiLoader/HabiLoader';
import ModalInventoryTime from '../ModalInventoryTime/ModalInventoryTime';
import { selectUserDateRange } from '../../../../redux/reducers/userSelectors';

const HistogramMetropolitanA = () => {
  const [data, setData] = useState<TData>();
  const [open, setOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [averageDays, setAverageDays] = useState<number>(0);
  const [medianDays, setMedianDays] = useState<number>(0);
  const [inventoryMiddle, setInventoryMiddle] = useState([]);
  const { metropolitan_area } = useAppSelector((state) => state.zoneStore.zone);
  const userDateRange = useAppSelector(selectUserDateRange);

  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    ChartDataLabels
  );

  const getHistogramData = async () => {
    setLoading(true);
    const start_date = new Date(userDateRange[0])
      .toISOString()
      .substring(0, 10);
    const end_date = new Date(userDateRange[1]).toISOString().substring(0, 10);
    const metropolitan_area_id = metropolitan_area.id;

    await getInventoryTimeMA({
      metropolitan_area_id,
      start_date,
      end_date,
    })
      .then((response) => {
        formatData(response.data);
        setAverageDays(response.data.average_days);
        setMedianDays(response.data.median_days);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const formatData = (data: any) => {
    let sold_data: number[] = [],
      to_sell: number[] = [];
    labelsHistogram.forEach((label, position) => {
      const result_inventory = data.in_inventory.filter(
        (inventory: any) => inventory.inventory_time === parseInt(label)
      );
      const result_sold_inventory = data.sold_inventory.filter(
        (inventory: any) => inventory.inventory_time === parseInt(label)
      );
      to_sell.push(result_inventory[0].properties.length);
      sold_data.push(result_sold_inventory[0].properties.length);
    });
    let new_properties: any = [];
    data.in_inventory
      .slice(15, data.in_inventory.length)
      .forEach((item: any) => {
        new_properties = new_properties.concat(item.properties);
      });
    new_properties.sort(
      (a: any, b: any) => a.days_in_inventory - b.days_in_inventory
    );
    if (new_properties.length > 0) {
      setInventoryMiddle(new_properties);
    } else {
      setInventoryMiddle([]);
    }
    const histogram = histogramData(to_sell, sold_data);
    setData(histogram);
  };

  useEffect(() => {
    getHistogramData();
  }, [userDateRange]);

  const handleClose = () => {
    setOpen(!open);
  };
  return (
    <>
      {loading ? (
        <LoaderContainer>
          <HabiLoader size="small" />
        </LoaderContainer>
      ) : (
        <WrapperHistogram>
          <TitleZone>
            <img src={LocationIcon} alt="pin del mapa" />
            <TitleAndSubtitle>
              <div className="title">{metropolitan_area.label}</div>
              <div className="subtitle">Área Metropolitana</div>
            </TitleAndSubtitle>
          </TitleZone>
          <IndicatorsZone>
            <SectionIndicator>
              <DaysIndicator>
                <div className="icon">
                  <img src={CalendarIcon} />
                </div>
                <div className="content">
                  Promedio de días en inventario
                  <div className="value">{averageDays}</div>
                </div>
              </DaysIndicator>
              <hr className="line" />
              <DaysMediaIndicator>
                <div className="icon">
                  <img src={HouseIcon} />
                </div>
                <div className="content">
                  Mediana días de inventario
                  <div className="value">{medianDays}</div>
                </div>
              </DaysMediaIndicator>
            </SectionIndicator>
            <SectionIndicator>
              <CategoryGraph color={electricPurple300}>
                <div className="color-indicator"></div>
                <div className="title">Por vender</div>
              </CategoryGraph>
              <CategoryGraph color={skyBlue100}>
                <div className="color-indicator"></div>
                <div className="title">Vendidos</div>
              </CategoryGraph>
            </SectionIndicator>
          </IndicatorsZone>
          <WrapperChart>
            {data && <Bar options={optionsHistogram} data={data} redraw />}
            {inventoryMiddle.length > 0 && (
              <Indicator150Days>
                <Indicator150Title>
                  <InfoOutlinedIcon className="icon" />
                  150 días en inventario
                </Indicator150Title>
                <Content150Days>
                  <Button
                    dataId="cancelList"
                    id="cancelList"
                    variant="stroke"
                    size="small"
                    onClick={handleClose}
                  >
                    Ver inmuebles en este rango
                  </Button>
                  <hr className="red-line" />
                </Content150Days>
              </Indicator150Days>
            )}
          </WrapperChart>
          {open && (
            <ModalInventoryTime
              properties={inventoryMiddle}
              handleClose={handleClose}
            />
          )}
        </WrapperHistogram>
      )}
    </>
  );
};

export default HistogramMetropolitanA;
