import { useContext } from 'react';
import {
  BodyContainer,
  CardMediumContainer,
  EditedMark,
  HoverButtons,
  ItemContainer,
  TitleContainer,
} from './styles';
import positive from '../../../../assets/icons/positive.svg';
import negative from '../../../../assets/icons/negative.svg';
import warning from '../../../../assets/icons/warning.svg';
import editedMark from '../../../../assets/icons/edited-mark.svg';
import { CustomButton } from '../CardSmall/styles';
// eslint-disable-next-line import/no-cycle
import { CardContext } from '../../Approver';

interface ICardMedium {
  cardData: any;
}

const CardMedium = ({ cardData }: ICardMedium) => {
  const context: any = useContext(CardContext);
  const { handleEditCard, approval } = context;
  const { id, approved, edited, data } = cardData;
  const { title, description, values } = data;
  let imageStatus = null;
  const role = localStorage.getItem('ROL');

  switch (approved) {
    case 'positive':
      imageStatus = positive;
      break;

    case 'negative':
      imageStatus = negative;
      break;

    case 'warning':
      imageStatus = warning;
      break;

    default:
      imageStatus = positive;
      break;
  }

  return (
    <CardMediumContainer>
      {edited && (
        <EditedMark>
          <img src={editedMark} alt="" width={24} height={24} />
        </EditedMark>
      )}
      <TitleContainer>
        <div className="title">{title}</div>
        <img src={imageStatus} alt={imageStatus} width={24} height={24} />
      </TitleContainer>
      <BodyContainer>
        {values &&
          values.map((item: any) => {
            return (
              <ItemContainer key={`medium${item.value}${id}`}>
                <div className="title">{item.title}</div>
                <div className="value">{item.value}</div>
              </ItemContainer>
            );
          })}
        {description && (
          <ItemContainer>
            <div className="description">{description}</div>
          </ItemContainer>
        )}
      </BodyContainer>
      <HoverButtons className="hover-buttons">
        <div className="buttons">
          <CustomButton
            className={`${
              role !== 'aprobologo' || !approval.editable ? 'disabled' : ''
            }`}
            onClick={() => {
              if (role === 'aprobologo' && approval.editable) {
                handleEditCard(id, data);
              }
            }}
            data-testid="edit-button"
          >
            <span className="text">Editar</span>
          </CustomButton>
        </div>
      </HoverButtons>
    </CardMediumContainer>
  );
};

export default CardMedium;
