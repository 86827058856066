import { robotoRegular, scarpaGray800, tomatoCoral100 } from '@habitech/shared';
import styled from 'styled-components';
import { disabled2, errorSecondary, goodComplex, whiteList, withoutList } from '../../../../styles/stylesConstants';

const TotalPlacesWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const DescriptionContainer = styled.div`
  width: 15%;
  align-items: center;
  font-family: ${robotoRegular};
  font-weight: 600;
  font-size: 14px;
  color: ${scarpaGray800};
  margin-right: 5%;
`;

export const ListTotalContainer = styled.div`
  display: flex;
  width: 80%;
  align-items: center;
`;

export const ListTotalItem = styled.div`
  padding: 12px 24px;
  border-radius: 6px;
  margin: 0 8px;
  background: ${tomatoCoral100};
  font-family: ${robotoRegular};
  font-weight: 600;
  min-width: 75px;
  .title {
    font-size: 12px;
    margin-bottom: 4px;
  }
  .item-value {
    font-size: 16px;
  }
  &.blacklist {
    background: ${errorSecondary};
  }
  &.greylist {
    background: ${disabled2};
  }
  &.whithout_list {
    background: ${withoutList};
  }
  &.good_complex {
    background: ${goodComplex};
  }
  &.whitelist {
    background: ${whiteList};
  }
`;

export default TotalPlacesWrapper;
