import { useCallback } from 'react';
import {
  Autocomplete,
  Checkbox,
  CircularProgress,
  TextField,
} from '@mui/material';
import CheckBoxOutlineBlank from '@mui/icons-material/CheckBoxOutlineBlank';
import {
  robotoRegular,
  scarpaGray800,
  electricPurple500,
} from '@habitech/shared';
import { CheckBox } from '@mui/icons-material';
import downIcon from '../../../../../assets/icons/icon-down.svg';
import SelectMultiWrapper from './styles';

const MultiSelect = ({
  selectedRules,
  setSelectedRules,
  approvalRules,
  loading,
  disabled,
}: any) => {
  const icon = <CheckBoxOutlineBlank fontSize="large" />;
  const checkedIcon = (
    <CheckBox fontSize="large" style={{ color: electricPurple500 }} />
  );
  return (
    <SelectMultiWrapper>
      <div className="label-description">* Reglas a evaluar</div>
      <Autocomplete
        disabled={disabled}
        multiple
        autoSelect={false}
        renderTags={() => null}
        id="checkboxes-tags-options"
        options={approvalRules}
        disableCloseOnSelect
        getOptionLabel={(option) => option.label}
        popupIcon={<img src={downIcon} alt="down" />}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        // eslint-disable-next-line react/no-unstable-nested-components
        ListboxComponent={useCallback(
          (
            props: JSX.IntrinsicAttributes &
              React.ClassAttributes<HTMLUListElement> &
              React.HTMLAttributes<HTMLUListElement>
          ) => (
            <ul {...props} style={{ height: '170px' }} id="listboxContent" />
          ),
          []
        )}
        renderOption={(props: any, option, { selected }) => {
          return (
            <li
              {...props}
              id={`item${String(option.id)}`}
              data-id={props['data-option-index']}
              style={{
                fontSize: '16px',
                fontFamily: robotoRegular,
                fontWeight: 400,
                color: scarpaGray800,
                height: '40px',
              }}
            >
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8, width: 20, height: 20 }}
                checked={selected}
              />
              {option.label}
            </li>
          );
        }}
        style={{ width: 432, height: 40 }}
        value={selectedRules}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder={
              selectedRules.length === 0
                ? 'Seleccione las reglas a evaluar'
                : 'Reglas seleccionadas'
            }
            InputProps={{
              ...params.InputProps,
              style: { fontSize: `14px` },
              endAdornment: (
                <>
                  {loading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
        onChange={(event, values) => {
          setSelectedRules(values);
        }}
      />
    </SelectMultiWrapper>
  );
};

export default MultiSelect;
