import styled from 'styled-components';
import {
  disabledColor,
  monserratRegular,
  robotoRegular,
  scarpaGray800,
  white,
} from '@habitech/shared';

export const ContainerRulesPendingApproved = styled.div`
  display: flex;
  flex-direction: column;
  background: ${white};
  padding: 24px;
  border-radius: 6px;
  margin-bottom: 24px;
  border: 1px solid ${disabledColor};
`;
export const StyledButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  display: inline-block;
`;

export const TitleNameCounterRules = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  .icon-title {
    display: flex;

    .title {
      color: ${scarpaGray800};
      font-family: ${monserratRegular};
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      margin: 16px 0 16px 8px;
    }
  }
  .rules-number {
    color: ${scarpaGray800};
    font-family: ${robotoRegular};
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 2px;
    text-transform: uppercase;
    margin: 16px 0;
  }
`;
