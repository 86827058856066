import { electricPurple800, monserratRegular } from '@habitech/shared';
import styled from 'styled-components';
import { footerBlue } from '../../../../styles/stylesConstants';

const AntiFunnelWrapper = styled.div`
  width: 100%;
  margin-top: 40px;
`;

export const AntiFunnelFilterContent = styled.div`
  width: 70%;
  margin-top: 25px;
  margin-bottom: 40px;
`;

export const BannerRejectionReasons = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 6px;
  padding: 35px;
  background: ${footerBlue};
  margin-top: 25px;
  .title {
    font-family: ${monserratRegular};
    font-weight: 600;
    font-size: 24px;
    color: ${electricPurple800};
    margin: 0 20px;
    width: 50%;
  }
`;

export default AntiFunnelWrapper;
