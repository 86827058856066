import { Loader } from '@habitech/loader';
import LoaderWrapper from './styles';

interface HabiLoaderProps {
  size: 'small' | 'large' | 'middle';
}

const HabiLoader = ({ size }: HabiLoaderProps) => {
  return (
    <LoaderWrapper data-testid="loader-wrapper">
      <Loader size={size} />
    </LoaderWrapper>
  );
};

export default HabiLoader;
