import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface IApproval {
  nid: number;
  deal_name: string;
  business_opportunity: string;
  approval_history_id: number | null;
  editable: boolean;
}
interface IShowDetail {
  goodZone: boolean;
  roomsBathrooms: boolean;
  historicalBusiness: boolean;
  elevator: boolean;
  garage: boolean;
}

export interface ISearchState {
  search: string;
  result: string;
  approval: IApproval;
  showDetail: IShowDetail;
  isRules: boolean;
  trustLevel?: number | string;
  hesH?: object;
}

interface UpdateShowDetailActionPayload {
  itemName: string;
  itemValue: any;
}

const initialState: ISearchState = {
  search: '',
  result: 'start',
  approval: {
    nid: 0,
    deal_name: '-',
    business_opportunity: '-',
    approval_history_id: 0,
    editable: false,
  },
  showDetail: {
    goodZone: false,
    roomsBathrooms: false,
    historicalBusiness: false,
    elevator: false,
    garage: false,
  },
  isRules: true,
  trustLevel: '-',
  hesH: {
    discount: 0,
    percentil: 0,
    nid: 0,
  },
};

const searchSlice = createSlice({
  name: 'search',
  initialState,
  reducers: {
    setSearch: (state, action: PayloadAction<string>) => {
      state.search = action.payload;
    },
    setResult: (state, action: PayloadAction<string>) => {
      state.result = action.payload;
    },
    resetSearch: (state) => {
      state.search = initialState.search;
      state.result = initialState.result;
    },
    updateApproval: (state, action: PayloadAction<any>) => {
      state.approval = action.payload;
    },
    updateBusinessOpportunity: (state, action: PayloadAction<string>) => {
      state.approval.business_opportunity = action.payload;
    },
    resetApproval: (state) => {
      state.approval = initialState.approval;
    },
    updateShowDetail: (
      state,
      action: PayloadAction<UpdateShowDetailActionPayload>
    ) => {
      const { itemName, itemValue } = action.payload;

      state.showDetail = {
        ...state.showDetail,
        [itemName]: itemValue,
      };
    },
    updateIsRules: (state, action: PayloadAction<boolean>) => {
      state.isRules = action.payload;
    },
    updateTrustLevel: (state, action: PayloadAction<string | number>) => {
      state.trustLevel = action.payload;
    },
    resetTrustLevel: (state) => {
      state.trustLevel = initialState.trustLevel;
    },
    updateHesH: (state, action: PayloadAction<any>) => {
      state.hesH = action.payload;
    },
    resetHesH: (state) => {
      state.hesH = initialState.hesH;
    },
    updateEditable: (state, action: PayloadAction<boolean>) => {
      state.approval.editable = action.payload;
    },
  },
});

export const {
  setSearch,
  setResult,
  resetSearch,
  updateApproval,
  updateBusinessOpportunity,
  resetApproval,
  updateShowDetail,
  updateIsRules,
  updateTrustLevel,
  resetTrustLevel,
  updateHesH,
  resetHesH,
  updateEditable,
} = searchSlice.actions;
export default searchSlice.reducer;
