import { TextField } from '@habitech/keychain-atoms-inputs';
import {
  ColumnDescription,
  TabContainer,
  ContainerOne,
  ContainerTwo,
  ContainerThree,
  ContainerButtonSend,
} from './styles';

interface ITapRules {
  firstSelected: boolean;
  setFirstSelected: any;
  setIsModalOpen: Function;
  data: any;
  visData: any;
  setVisData: any;
  noVisData: any;
  setNoVisData: any;
  handleSendDataToModal: any;
}

const EditSpecificRules: React.FC<ITapRules> = ({
  firstSelected,
  setFirstSelected,
  setIsModalOpen,
  visData,
  setVisData,
  noVisData,
  setNoVisData,
  handleSendDataToModal,
}) => {
  const handleSelectedFirst = () => {
    setFirstSelected(true);
  };

  const handleSelectedSecond = () => {
    setFirstSelected(false);
  };

  const handleOpenModal = () => {
    handleSendDataToModal();
    setIsModalOpen(true);
  };

  return (
    <>
      <ColumnDescription>
        <div className="subtitle">Editar reglas especificas para la zona</div>
      </ColumnDescription>
      <TabContainer firstSelected={firstSelected}>
        <button
          className={`${
            firstSelected ? 'button-selected' : 'button-not-selected'
          } size-one`}
          onClick={handleSelectedFirst}
        >
          <span className="text">Vivienda VIS</span>
        </button>
        <button
          className={`${
            firstSelected ? 'button-not-selected' : 'button-selected'
          }`}
          onClick={handleSelectedSecond}
        >
          <span className="text">Vivienda No VIS</span>
        </button>
      </TabContainer>
      <ContainerOne>
        {firstSelected ? (
          <TextField
            defaultValue={visData.max_price}
            disabled={firstSelected}
            name="price"
            dataId="testing_text_field"
            label="Precio máximo"
            type="number"
            placeHolder="Escribe el precio"
          />
        ) : (
          <TextField
            defaultValue={noVisData.min_price}
            name="price"
            dataId="testing_text_field"
            label="Precio máximo ask price"
            placeHolder="Escribe el precio"
            type="number"
            onChange={(inputname, value) =>
              setNoVisData({ ...noVisData, min_price: value })
            }
          />
        )}
      </ContainerOne>
      <ContainerTwo>
        {firstSelected ? (
          <TextField
            defaultValue={visData.max_antiquity}
            name="antiquity"
            dataId="testing_text_field"
            label="Antigüedad máxima (Años)"
            placeHolder="Escribe los años"
            type="number"
            onChange={(inputname, value) => {
              setVisData({ ...visData, max_antiquity: value });
            }}
          />
        ) : (
          <TextField
            defaultValue={noVisData.max_price}
            name="max-price"
            dataId="testing_text_field"
            label="Precio tope"
            placeHolder="Escribe el precio"
            type="number"
            onChange={(inputname, value) =>
              setNoVisData({ ...noVisData, max_price: value })
            }
          />
        )}
      </ContainerTwo>
      {!firstSelected && (
        <ContainerThree>
          <TextField
            defaultValue={noVisData.max_antiquity}
            name="antiquity"
            dataId="testing_text_field"
            label="Antigüedad máxima (Años)"
            placeHolder="Escribe los años"
            type="number"
            onChange={(inputname, value) =>
              setNoVisData({ ...noVisData, max_antiquity: value })
            }
          />
        </ContainerThree>
      )}
      <ContainerButtonSend>
        <button onClick={handleOpenModal}>
          <span className="text">Guardar cambios de la zona</span>
        </button>
      </ContainerButtonSend>
    </>
  );
};

export default EditSpecificRules;
