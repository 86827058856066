import { FC, useState } from 'react';
import { DropdownButton } from '@habitech/call-to-action';
import { IListOptions } from '@habitech/call-to-action/src/lib/dropdown-button/models/dropdown-button.model';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import CheckBoxRoundedIcon from '@mui/icons-material/CheckBoxRounded';

import { FormControl } from '@mui/material';
import { WrapperDropdown } from '../../../SelectZone/styles';
import ReasonsFilterContainer, { stylesMultiSelect } from './styles';

interface ReasonsFilterProps {
  rejectionTeam?: string;
  onSelectReason: (reason: string) => void;
  rejectedReasons?: any;
  onSelectedRejectedReasons: (reasons: string[]) => void;
}

const REJECTION_TEAMS: IListOptions[] = [
  { label: 'General', value: 'general' },
  { label: 'Remodelaciones', value: 'Rechazo Remo' },
  { label: 'Aprobologos', value: 'Rechazo Comite' },
];

const ReasonsFilter: FC<ReasonsFilterProps> = ({
  rejectionTeam,
  onSelectReason,
  rejectedReasons,
  onSelectedRejectedReasons,
}) => {
  const [reasonRejectedName, setReasonRejectedName] = useState<string[]>([]);

  const handleChange = (
    event: SelectChangeEvent<typeof reasonRejectedName>
  ) => {
    const {
      target: { value },
    } = event;
    setReasonRejectedName(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value
    );
    onSelectedRejectedReasons(
      typeof value === 'string' ? value.split(',') : value
    );
  };

  const hasReason = typeof rejectionTeam === 'string';

  return (
    <ReasonsFilterContainer>
      <WrapperDropdown selected={hasReason}>
        <DropdownButton
          label="Filtrar por equipo que rechazo el inmueble"
          dataId="rejectionTeam"
          size="extra-large"
          listOptions={REJECTION_TEAMS}
          setValue={(v) => onSelectReason(v as string)}
        />
      </WrapperDropdown>
      {rejectedReasons && (
        <FormControl sx={stylesMultiSelect.form} className="select-multiple">
          <div className="label-span">Filtrar por razón de rechazo</div>
          {reasonRejectedName.length === 0 && (
            <div className="placeholder"> Selecciona la razón de rechazo</div>
          )}
          <Select
            labelId="demo-multiple-checkbox-label"
            id="demo-multiple-checkbox"
            multiple
            value={reasonRejectedName}
            onChange={handleChange}
            displayEmpty
            defaultValue={['none']}
            sx={stylesMultiSelect.select}
            MenuProps={stylesMultiSelect.menu}
            inputProps={{ 'aria-label': 'Without label' }}
            renderValue={(selected) => selected.join(', ')}
            IconComponent={KeyboardArrowDownRoundedIcon}
          >
            {rejectedReasons.map((name: string) => (
              <MenuItem key={name} value={name}>
                <Checkbox
                  sx={stylesMultiSelect.checkbox}
                  checkedIcon={<CheckBoxRoundedIcon />}
                  size="medium"
                  checked={reasonRejectedName.indexOf(name) > -1}
                />
                <ListItemText primary={name} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
    </ReasonsFilterContainer>
  );
};

export default ReasonsFilter;
