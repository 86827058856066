import styled from 'styled-components';
import {
  gray6,
  scarpaGray800,
  secondaryFont,
  skyBlue300,
} from '../../../../styles/stylesConstants';

export const GraphicFilterWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(10, 1fr);
  margin-bottom: 25px;
`;

export const FilterWrapper = styled.div`
  display: grid;
  grid-column: 1 / 4;
  margin-top: 28px;

  .form-label {
    font-weight: bold;
    font-size: 13px;
    color: ${scarpaGray800};
    margin-bottom: 8px;
  }

  .form-input {
    border-radius: 8px;
    border: 1px solid ${gray6};
    padding: 0 16px;
    height: 48px;
    font-family: ${secondaryFont};
  }
`;

export const HeatMapWrapper = styled.div`
  display: grid;
  grid-column: 5 / 7;
  margin-top: 28px;

  .map-label {
    font-weight: 700;
    font-size: 12px;
    color: ${scarpaGray800};
    margin-bottom: 8px;
  }
`;

export const HeatMapContent = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  font-size: 12px;

  .map-color {
    height: 48px;
    width: 100%;
    background: linear-gradient(270deg, ${skyBlue300} -2.67%, #bc7eff 93.33%);
    border-radius: 4px;
  }
`;

export default GraphicFilterWrapper;
