import styled from 'styled-components';

const ZoneDetailWrapper = styled.div`
  width: 100%;
  margin-bottom: 32px;

  .map-wrapper {
    width: 100%;
    height: 300px;
  }

  .info-container {
    position: relative;
    padding: 0 16px;
    margin-top: -72px;
  }
`;

export default ZoneDetailWrapper;
