import { Dispatch, SetStateAction } from 'react';
import {
  ContainerSelectTypeZoneMx,
  ColumnDescription,
  TabContainer,
  TabItem,
} from '../../styles';

const tabItems = [
  { id: 1, label: 'Zona mediana' },
  { id: 2, label: 'Zona grande' },
  { id: 3, label: 'Ciudad' },
  { id: 4, label: 'Área metropolitana' },
];
interface ISelectTypeZoneMxProps {
  tabSelected: number;
  setTabSelected: Dispatch<SetStateAction<number>>;
}

const SelectTypeZoneMx = ({
  tabSelected,
  setTabSelected,
}: ISelectTypeZoneMxProps) => {
  const handleSelected = (id: number) => {
    setTabSelected(id);
  };

  const getTabItemClasses = (tab: { id: any; label?: string }) => {
    const classes = [];

    if (tab.id === tabSelected) {
      classes.push('selected');
    }

    if (tab.id === 1) {
      classes.push('first');
    }

    if (tab.id === 4) {
      classes.push('last');
    }

    return classes.join(' ');
  };

  return (
    <ContainerSelectTypeZoneMx>
      <ColumnDescription>
        <div className="subtitle">
          Selecciona el tipo de zona al que quieres aplicar los cambios
        </div>
      </ColumnDescription>

      <TabContainer>
        {tabItems.map((tab) => (
          <TabItem
            data-testid={`tab-${tab.id}`}
            key={tab.id}
            className={getTabItemClasses(tab)}
            onClick={() => handleSelected(tab.id)}
          >
            {tab.label}
          </TabItem>
        ))}
      </TabContainer>
    </ContainerSelectTypeZoneMx>
  );
};

export default SelectTypeZoneMx;
