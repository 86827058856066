import zonesClient from '../../utils/zonesClient';
import {
  BigZoneAreas,
  CityAreas,
  MetropolitanAreas,
  MiddleZoneAreas,
} from '../../pages/SelectZone/types';

export const getMetropolitanArea = async () => {
  const { data } = await zonesClient.get<IApiResponse<MetropolitanAreas[]>>(
    'get_metropolitan_areas'
  );

  return data;
};

export const getCities = async (metropolitanArea: number) => {
  const { data } = await zonesClient.get<IApiResponse<CityAreas[]>>(
    'get_cities',
    { params: { metropolitan_area: metropolitanArea } }
  );

  return data;
};

export const getAllCities = async () => {
  const { data } = await zonesClient.get<IApiResponse<CityAreas[]>>(
    'get_cities',
    {}
  );

  return data;
};

export const getBigZones = async (city: number) => {
  const { data } = await zonesClient.get<IApiResponse<BigZoneAreas[]>>(
    'get_big_zones',
    { params: { city } }
  );

  return data;
};

export const getMiddleZones = async (bigZone: number) => {
  const { data } = await zonesClient.get<IApiResponse<MiddleZoneAreas[]>>(
    'get_median_zones',
    { params: { big_zone: bigZone } }
  );

  return data;
};

const url = process.env.REACT_APP_ZONES_API_URL;

interface IGetZoneRules {
  zoneId?: number;
}

export const getZoneRules = async ({ zoneId }: IGetZoneRules) => {
  const api = `zones/rules/${zoneId}`;
  const endpoint = url + api;
  return zonesClient.get<IApiResponse>(endpoint, {});
};

interface IputRuleZone {
  median_zone_id?: number;
  validate_column?: any;
  active?: number;
  validate_state?: number;
}

export const putRuleZone = async ({
  median_zone_id,
  validate_column,
  active,
  validate_state,
}: IputRuleZone = {}) => {
  const api = '/put_rule_Zone';
  const path = url + api;

  const { data } = await zonesClient.put<IApiResponse>(path, {
    median_zone_id,
    validate_column,
    active,
    validate_state,
  });

  return data;
};

interface IMedianZonesData {
  apartment?: number | null;
  house?: number | null;
  range_ids?: never[];
}
interface IputDiscountMedianZones {
  filter_by?: string;
  type?: string;
  country?: string;
  user_email?: string;
  data?: IMedianZonesData;
}

export const putDiscountMedianZones = async ({
  filter_by,
  type,
  user_email,
  data,
}: IputDiscountMedianZones = {}) => {
  const api = '/discount/median_zones';
  const path = url + api;

  return zonesClient.put<IApiResponse>(path, {
    filter_by,
    type,
    user_email,
    data,
  });
};

interface IPutPlacesMedianZones {
  filter_by: string;
  type_list: string;
  country: string;
  range_ids: never[];
  value: number;
  quota_type: string;
  min: number | undefined;
  max: number | undefined;
}

export const putPlacesMedianZones = async ({
  filter_by,
  type_list,
  range_ids,
  value,
  quota_type,
  min,
  max,
}: IPutPlacesMedianZones) => {
  const api = '/quotes/median_zones';
  const path = url + api;

  return zonesClient.put<IApiResponse>(path, {
    filter_by,
    type_list,
    range_ids,
    value,
    quota_type,
    min,
    max,
  });
};
