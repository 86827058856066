import { useSelector } from 'react-redux';
import ControlDown from '../../../../../../assets/images/ControlDown.svg';
import ControlUp from '../../../../../../assets/images/ControlUp.svg';
import { useAppDispatch } from '../../../../../../redux/hooks';
import { updateShowDetail } from '../../../../../../redux/reducers/searchSlice';
import DetailsRoomsBathrooms from '../DetailsRoomsBathrooms/DetailsRoomsBathrooms';
import {
  ContainerValidationRoomsBathrooms,
  TitleNameCounterValidations,
  StyledButton,
} from './styles';

const ValidationRoomsBathrooms = () => {
  const showDetail = useSelector((state: any) => state.searchStore.showDetail);
  const imgControl = showDetail ? ControlUp : ControlDown;
  const dispatch = useAppDispatch();
  const handleChangeShowDetail = () => {
    dispatch(
      updateShowDetail({
        itemName: 'roomsBathrooms',
        itemValue: !showDetail.roomsBathrooms,
      })
    );
  };
  return (
    <ContainerValidationRoomsBathrooms>
      <TitleNameCounterValidations>
        <div className="icon-title">
          <StyledButton onClick={handleChangeShowDetail}>
            <img src={imgControl} alt="ampliar replegar" />
          </StyledButton>
          <div className="title">Validación Habitaciones - baños</div>
        </div>
      </TitleNameCounterValidations>
      {showDetail.roomsBathrooms && <DetailsRoomsBathrooms />}
    </ContainerValidationRoomsBathrooms>
  );
};

export default ValidationRoomsBathrooms;
