import {
  BarIndicatorsStatus,
  IndicatorWrapper,
  IndicatorWrapperStatus,
  SectionTitle,
  StageIndicator,
  StageIndicatorMini,
  SubtitleBar,
  WrapperIndicators,
  WrapperZoneValidations,
} from './style';
import IconSection from './assets/icon-section.svg';
import { getZoneValidations } from '../../../../apis/funnel/funnel';
import { useEffect, useState } from 'react';
import { useAppSelector } from '../../../../redux/hooks';
import { selectUserDate } from '../../../../redux/reducers/userSelectors';
import {
  scarpaGray100,
  scarpaGray300,
  skyBlue100,
  skyBlue500,
  tomatoCoral100,
  tomatoCoral400,
} from '@habitech/shared';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import RemoveCircleRoundedIcon from '@mui/icons-material/RemoveCircleRounded';
import HelpRoundedIcon from '@mui/icons-material/HelpRounded';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import { LoaderContainer } from '../../../Utility/style';
import HabiLoader from '../../../../components/HabiLoader/HabiLoader';

const ZoneValidations = () => {
  const [indicators, setIndicators] = useState<any>({});
  const [loaded, setLoaded] = useState<boolean>(false);

  const STATUS_DICT: any = {
    1: {
      status_id: 1,
      status_label: 'Bien, estado esperado',
      backgroundColor: skyBlue100,
      color: skyBlue500,
      icon: <CheckCircleRoundedIcon />,
    },
    2: {
      status_id: 2,
      status_label: 'Revisión, depende de caso a caso',
      backgroundColor: '#FCF1CC',
      color: '#FFC123',
      icon: <HelpRoundedIcon />,
    },
    3: {
      status_id: 3,
      status_label: 'Hay algo raro en la zona',
      backgroundColor: tomatoCoral100,
      color: tomatoCoral400,
      icon: <CancelRoundedIcon />,
    },
    0: {
      status_id: 0,
      status_label: 'No aplica',
      backgroundColor: scarpaGray100,
      color: scarpaGray300,
      icon: <RemoveCircleRoundedIcon />,
    },
  };
  const stages: any = {
    marketing_leads: {
      title: 'Leads de marketing',
      description: {
        0: 'No aplica',
        1: 'Leads mayores al promedio de la ciudad',
        2: 'Leads diferencia menor a 10% de la ciudad',
        3: 'Rojo Leads menor a 10% de la ciudad',
      },
    },
    pricing: {
      title: 'Pricing',
      description: {
        0: 'No aplica',
        1: 'Mape menor 5%',
        2: 'MAPE entre 5% y 15% ',
        3: 'MAPE mayor 5%',
      },
    },
    interested: {
      title: 'Interesados',
      description: {
        0: 'No aplica',
        1: 'Interesados mayor a la ciudad',
        2: 'Interesados un poco mayor a la ciudad',
        3: 'Interesados mucho menor a la ciudad',
      },
    },
    committee_approval: {
      title: 'Aprobación comité',
      description: {
        0: 'No aplica',
        1: 'Aprobación mayor a la ciudad',
        2: 'Aprobación un poco mayor a la ciudad',
        3: 'Aprobación mucho menor a la ciudad',
      },
    },
    closure_result: {
      title: 'Conversión al cierre',
      description: {
        0: 'No aplica',
        1: 'Conversión a cierre mayor a la ciudad',
        2: 'Conversión a cierre un poco menor a la ciudad',
        3: 'Conversión a cierre menor a la ciudad',
      },
    },
    stock_days: {
      title: 'Tiempo en inventario',
      description: {
        0: 'No aplica',
        1: 'Promedio de días en inventario menor a ciudad',
        2: 'Promedio de días en inventario un poco por encima de la ciudad',
        3: 'Promedio de días en inventario mayor a ciudad',
      },
    },
    revenue_composition: {
      title: 'Composición revenue',
      description: {
        0: 'No aplica',
        1: 'Revenue mayor a 40',
        2: 'Revenue entre a 30 y 40',
        3: 'Revenue menor a 30',
      },
    },
    profit: {
      title: 'Utilidad',
      description: {
        0: 'No aplica',
        1: 'Utilidad mayor a 6.5%',
        2: 'Utilidad entre 5.5% y  6.5%',
        3: 'Utilidad menor a 5.5%',
      },
    },
    market_share: {
      title: 'Market share',
      description: {
        0: 'No aplica',
        1: 'Market share mayor a 10%',
        2: 'Market share entre 5% y 10%',
        3: 'Market share menor a 5%',
      },
    },
    elasticity: {
      title: 'Elasticidad',
      description: {
        0: 'No aplica',
        1: 'Elasticidad mayor a 1.1',
        2: 'Elasticidad entre 0.9 y 1.1',
        3: 'Elasticidad mayor a 0.9',
      },
    },
  };
  const userDate = useAppSelector(selectUserDate);
  const {
    zone: { middleZone },
  } = useAppSelector((state) => state.zoneStore);

  const datePicked = new Date(userDate);

  const getIndicators = async () => {
    setLoaded(false);
    await getZoneValidations({
      month: datePicked.getMonth() + 1,
      year: datePicked.getFullYear(),
      zone_id: middleZone.id,
    }).then((response) => {
      setLoaded(true);
      setIndicators(response.data.indicators);
    });
  };

  useEffect(() => {
    getIndicators();
  }, []);

  return (
    <>
      <WrapperZoneValidations>
        <SectionTitle>
          <img src={IconSection} alt="Validaciones de la zona" />
          <span>Validaciones de la zona</span>
        </SectionTitle>
        <hr className="line" />
        {loaded ? (
          <WrapperIndicators>
            {Object.keys(indicators).length > 0 &&
              Object.keys(stages).map((stage) => {
                if (indicators[stage] !== undefined) {
                  const { backgroundColor, icon, color } = STATUS_DICT[indicators[stage]['status']];
                  return (
                    <IndicatorWrapper key={stage}>
                      <StageIndicator background={backgroundColor} color={color}>
                        {icon}
                      </StageIndicator>
                      <div className="title">{stages[stage]['title']}</div>
                      <div className="description">
                        {
                          stages[stage]['description'][
                            indicators[stage]['status']
                          ]
                        }
                      </div>
                    </IndicatorWrapper>
                  );
                }
              })}
          </WrapperIndicators>
        ) : (
          <>
            <LoaderContainer>
              <HabiLoader size="small" />
            </LoaderContainer>
          </>
        )}
        <SubtitleBar>Diccionario de validaciones</SubtitleBar>
        <BarIndicatorsStatus>
          {Object.values(STATUS_DICT).map((value: any, p) => {
            return (
              <IndicatorWrapperStatus key={value.status_id + 'mini-indicator'}>
                <StageIndicatorMini
                  background={value.backgroundColor}
                  color={value.color}
                >
                  {value.icon}
                </StageIndicatorMini>
                <div className="title">{value.status_label}</div>
              </IndicatorWrapperStatus>
            );
          })}
        </BarIndicatorsStatus>
      </WrapperZoneValidations>
    </>
  );
};
export default ZoneValidations;
