export const MONTHS_DICT: any = {
  EN: {
    january: 'Ene ',
    february: 'Feb ',
    march: 'Mar ',
    april: 'Abr ',
    may: 'May ',
    june: 'Jun ',
    july: 'Jul ',
    august: 'Ago ',
    september: 'Sep ',
    october: 'Oct ',
    november: 'Nov ',
    december: 'Dic ',
  },
};

export const getDictLines = (label: any) => {
  switch (label) {
    case 'leads_pricing':
      return {
        borderColor: 'rgb(69, 0, 131)',
        backgroundColor: 'rgb(69, 0, 131)',
        label: 'Registros que llegan a pricing',
        borderWidth: 1.5,
        datalabels: {
          display: false,
        },
      };
    case 'leads_zone':
      return {
        borderColor: 'rgb(50, 193, 255)',
        backgroundColor: 'rgb(50, 193, 255)',
        label: 'Promedio de registro en la ciudad',
        borderWidth: 1.5,
        datalabels: {
          display: false,
        },
      };
    case 'leads_area':
      return {
        borderColor: 'rgb(255, 122, 121)',
        backgroundColor: 'rgb(255, 122, 121)',
        label: 'Comportamiento de la zona mediana',
        borderDash: [4, 4],
        borderWidth: 1.5,
        datalabels: {
          display: false,
        },
      };
    default:
      break;
  }
};
