import { Fragment } from 'react';
import { useAppSelector } from '../../../../redux/hooks';
import TotalPlacesWrapper, {
  DescriptionContainer,
  ListTotalContainer,
  ListTotalItem,
} from './styles';

const TotalPlacesPerList = () => {
  const zoneState = useAppSelector((state) => state.zoneStore);
  const DICT_LIST: Record<string, string> = {
    blacklist: 'Black list',
    greylist: 'Grey list',
    whithout_list: 'Sin lista',
    good_complex: 'Buen conjunto',
    whitelist: 'White list',
  };

  return (
    <TotalPlacesWrapper>
      <DescriptionContainer>
        Cupos totales disponibles por lista
      </DescriptionContainer>
      <ListTotalContainer>
        {zoneState.quotas &&
          zoneState.quotas.map((item: any) => {
            return (
              <Fragment key={item.list_type}>
                <ListTotalItem className={item.list_type}>
                  <div className="title">{DICT_LIST[item.list_type]}</div>
                  <div className="item-value">{item.quota_number ?? 0}</div>
                </ListTotalItem>
              </Fragment>
            );
          })}
      </ListTotalContainer>
    </TotalPlacesWrapper>
  );
};

export default TotalPlacesPerList;
