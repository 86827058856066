import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useEffect, useState } from 'react';
import moment, { Moment } from 'moment';
import { ArcElement, Chart } from 'chart.js';

import { MonthPicker } from '@habitech/month-picker';
import { RejectionInfo } from '../../model/rejectionReasons';
import { useAppSelector } from '../../../../redux/hooks';
import { selectUserDate } from '../../../../redux/reducers/userSelectors';
import ContainerAreas from '../../../MarketingLeads/components/ContainerAreas/ContainerAreas';
import ReasonsFilter from '../ReasonsFilter';
import ReasonsTable from '../ReasonsTable';
import RejectionReasonsGraphic from '../RejectionReasonsGraphic/RejectionReasonsGraphic';
import {
  GraphicsDivider,
  ContainerGraphics,
  SectionTitle,
  VersusIndicator,
  SectionFilter,
  DateFilter,
} from './style';
import {
  selectMedianZone,
  selectMetropolitanArea,
} from '../../../../redux/reducers/zoneSelectors';
import {
  getRejectionReasonsMa,
  getRejectionReasonsMz,
} from '../../../../apis/rejectionReasons/rejectionReasons';
import EmptyState from '../../../../components/EmptyState';
import { LabelMonthPicker } from '../FunnelFilters/styles';

Chart.register(ArcElement);

const ContainerRejectionReasons = () => {
  const userDate = useAppSelector(selectUserDate);
  const metropolitanArea = useAppSelector(selectMetropolitanArea);
  const medianZone = useAppSelector(selectMedianZone);

  const [rejectionTeam, setRejectionTeam] = useState<string>();
  const [rejectedReasonsList, setRejectedReasonsList] = useState<string[]>();
  const [rejectedReasons, setRejectedReasons] = useState<string[]>();
  const [resultsForReasons, setResultsForReasons] = useState<boolean>(true);
  const [gestionDate, setGestionDate] = useState<Moment>(moment(userDate));
  const [metropolitanRejectionData, setMetropolitanRejectionData] =
    useState<RejectionInfo>();
  const [zoneRejectionData, setZoneRejectionData] = useState<RejectionInfo>();
  const loaded = metropolitanRejectionData && zoneRejectionData;

  useEffect(() => {
    getRejectionReasonsMa(
      metropolitanArea.id,
      gestionDate.toDate(),
      rejectionTeam === 'general' ? '' : rejectionTeam,
      rejectedReasons
    )
      .then((response) => {
        setMetropolitanRejectionData(response.data);

        if (!rejectedReasonsList) {
          setRejectedReasonsList(response.data.labels);
        }
      })
      .catch(() => {});
  }, [
    metropolitanArea.id,
    rejectionTeam,
    gestionDate,
    rejectedReasons,
    rejectedReasonsList,
  ]);

  useEffect(() => {
    getRejectionReasonsMz(
      medianZone.id,
      gestionDate.toDate(),
      rejectionTeam === 'general' ? '' : rejectionTeam,
      rejectedReasons
    )
      .then((response) => {
        if (response) {
          setZoneRejectionData(response.data.data);
          setResultsForReasons(true);
        }
      })
      .catch((error) => {
        if (
          error.response &&
          error.response.status &&
          error.response.status === 404 &&
          rejectedReasons
        ) {
          setResultsForReasons(false);
        }
      });
  }, [medianZone.id, rejectionTeam, gestionDate, rejectedReasons]);

  const onChangeGestionDate = (date: Date | null) => {
    if (date) {
      setGestionDate(moment(date));
    }
  };

  return (
    <ContainerAreas>
      <SectionTitle>
        <InfoOutlinedIcon className="icon" />
        <span>Distribución razones de rechazo en comité</span>
      </SectionTitle>

      <SectionFilter>
        <DateFilter>
          <LabelMonthPicker>Filtrar por fecha de gestión</LabelMonthPicker>
          <MonthPicker
            dataId="rejection-date"
            value={[gestionDate.toDate(), undefined] as [Date, Date?]}
            onChangeValue={(value: any) => {
              onChangeGestionDate(value[0]);
            }}
            isRange={false}
            startYear={2019}
          />
        </DateFilter>
        <br />
        <ReasonsFilter
          rejectionTeam={rejectionTeam}
          rejectedReasons={rejectedReasonsList}
          onSelectReason={setRejectionTeam}
          onSelectedRejectedReasons={setRejectedReasons}
        />
      </SectionFilter>

      {loaded ? (
        <ContainerGraphics>
          <GraphicsDivider>
            <RejectionReasonsGraphic
              first
              resultsForReasons
              city={metropolitanArea.label}
              subtitle="Área Metropolitana"
              rejectionData={metropolitanRejectionData}
            />

            <ReasonsTable
              reasons={metropolitanRejectionData.rejected_reasons}
            />
          </GraphicsDivider>

          <VersusIndicator>Vs</VersusIndicator>

          <GraphicsDivider>
            <RejectionReasonsGraphic
              city={medianZone.label}
              subtitle="Zona Mediana"
              rejectionData={zoneRejectionData}
              resultsForReasons={resultsForReasons}
            />

            {resultsForReasons && (
              <ReasonsTable reasons={zoneRejectionData.rejected_reasons} />
            )}
          </GraphicsDivider>
        </ContainerGraphics>
      ) : (
        <EmptyState
          title="No encontramos información."
          body="No pudimos encontrar razones de rechazo para esta zona, puedes probar cambiando la fecha que seleccionaste para obtener resultados o cambiar de zona."
        />
      )}
    </ContainerAreas>
  );
};

export default ContainerRejectionReasons;
