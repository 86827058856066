import styled from 'styled-components';
import {
  electricPurple500,
  grid,
  scarpaGray800,
  white,
} from '@habitech/shared';

import {
  principalFont,
  secondaryAA,
  separator,
  textGray3,
  purple,
} from '../../styles/stylesConstants';

export const SelectZoneWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(${grid?.desktop?.columns}, 1fr);
  grid-gap: ${grid?.desktop?.gap};
  margin-top: 40px;
  .purple {
    color: ${electricPurple500};
  }

  .sgray {
    color: ${scarpaGray800};
  }

  .text-gray {
    color: ${textGray3};
  }

  .header-wrapper {
    grid-column: 1 / ${Number(grid?.desktop?.columns) + 1};
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 24px;
    .container-button-logo {
      display: flex;
    }
    .img-col {
      margin-left: 24px;
    }
  }

  .selects-wrapper {
    grid-column: 2 / 6;
    display: flex;
    flex-direction: column;
  }

  .map-wrapper {
    grid-column: 7 / ${Number(grid?.desktop?.columns)};
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    min-height: 80vh;
  }

  .icon-mask {
    position: absolute;
    bottom: 0px;
    left: 0px;
  }

  .icon-mask-right {
    position: absolute;
    top: 0;
    right: 0;
  }
`;

interface WrapperDropdownProps {
  selected?: boolean;
}

export const WrapperDropdown = styled.div<WrapperDropdownProps>``;
export const SelectZoneTabs = styled.div`
  width: 100%;
  border-bottom: 1px solid ${separator};
`;

export const PageTab = styled.button<{ active: boolean }>`
  display: inline-block;
  padding: 16px;
  background: none;
  border: none;
  outline: none;
  font-size: 14px;
  font-weight: 600;
  color: ${secondaryAA};
  font-family: ${principalFont};
  &:hover {
    cursor: pointer;
  }
  position: relative;
  ${({ active }) =>
    active &&
    `
    color: ${scarpaGray800};
    &:after {
      content: '';
      position: absolute;
      left: 0px;
      right: 0;
      bottom: -2px;
      background: ${purple};
      height: 4px;
      border-radius: 10px;
    }
  `}
`;

export const ZonePageItem = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: space-around;

  p {
    color: ${scarpaGray800};
    margin-left: 16px;
    font-weight: 700;
  }
`;

export const ZonePageList = styled.div`
  display: grid;
  padding-top: 16px;
`;

export const ContainerSelectZone = styled.div`
  display: flex;
  flex-direction: column;
  height: 93%;
  width: 100%;
  margin-left: -24px;
  padding-right: 24px;
  background: ${white};
`;
