import { useEffect, useState } from 'react';
import SelectTypeZoneMx from '../ZoneValidationMx/componets/SelectTypeZoneMx/SelectTypeZoneMx';
import {
  ContainerZoneValidationMx,
  TitleContainer,
  Divisor,
} from '../ZoneValidationMx/styles';
import ModifyParameterTab from './components/ModifyParameterTab/ModifyParameterTab';
import RevenueCategory from './components/RevenueCategory/RevenueCategory';
import LayoutSidebar from '../../components/LayoutSidebar';
import ModalConfirmation from '../../components/ModalConfirmation';
import LayoutHeaderClean from '../../components/LayoutClean';
import AlertToast from '../Pricing/components/AlertToast/AlertToast';
import { ContainerPage, LayoutWrapper } from '../../components/Layout/style';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { alertStoreActions } from '../../redux/reducers/alertReducer';
import NewParameter from './components/NewParameter/NewParameter';
import SelectArea from '../ZoneValidationMx/componets/SelectArea/SelectArea';
import { IOption } from '../ZoneValidationMx/componets/SelectArea/interfaces';
import getDataZoneType from '../../utils/getDataZonType';
import { getHesHParameters, putHesHParameters } from '../../apis/HesH/Hesh';
import { ButtonContainerHesh } from './styles';
import { TModalInformation } from '../RulesEditor/model';
import { tabItemsPlural, tabItemsSingular } from './HesH.utils';

interface TabItem {
  id: number;
  label: string;
}

const ZoneValidation = () => {
  const [tabSelected, setTabSelected] = useState(0);
  const [categorySelect, setCategorySelect] = useState<any>();
  const [sumary, setSumary] = useState<any>({});
  const [isPercentil, setIsPercentil] = useState<boolean>(true);
  const [isSingular, setisSingular] = useState<boolean>(true);
  const [valueError, setValueError] = useState<boolean>(false);
  const [inputError, setInputError] = useState<boolean>(false);
  const [newValue, setNewValue] = useState<string>('');
  const [step, setStep] = useState<number>(0);
  const [selectedPlaces, setSelectedPlaces] = useState<IOption[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [modalInfo, setModalInfo] = useState<TModalInformation>({
    title: '',
    subtitle: '',
    buttonOk: '',
    buttonCancel: '',
    action: () => {},
    image: '',
    showButtons: true,
  });
  const [selectData, setSelectData] = useState({
    data: [],
    message: '',
    success: false,
  });
  const tabItems = [
    { id: 1, label: 'median_zone' },
    { id: 2, label: 'big_zone' },
    { id: 3, label: 'city' },
    { id: 4, label: 'metropolitan_area' },
  ];

  const country = localStorage.getItem('country');
  useEffect(() => {
    getDataZoneType({ setLoading, tabSelected, setSelectData });
  }, [tabSelected]);

  useEffect(() => {
    const isOutOfRange = isPercentil
      ? +newValue < 0 || +newValue > 90
      : +newValue < 4 || +newValue > 11;

    setInputError(newValue.length > 0 && isOutOfRange);
    setValueError(newValue.length === 0 || isOutOfRange);
  }, [newValue, isPercentil]);

  useEffect(() => {
    if (selectedPlaces.length > 1) {
      setisSingular(false);
    } else {
      setisSingular(true);
    }

    if (selectedPlaces.length === 0) {
      setStep(1);
      setCategorySelect('');
    }
  }, [selectedPlaces]);

  useEffect(() => {
    if (tabSelected !== 0 && step === 0) {
      setStep(1);
    }

    if (step >= 1 && selectedPlaces.length > 0) {
      setStep(2);
    }

    if (
      step >= 2 &&
      categorySelect &&
      categorySelect !== '' &&
      selectedPlaces.length > 0
    ) {
      setStep(3);
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      handleGetParameters();
    }
  }, [tabSelected, selectedPlaces, categorySelect]);

  const tabsTofind: TabItem | undefined = tabItems.find(
    (item) => item.id === tabSelected
  );

  const getIdString = (data: any[]) => {
    const idArray = data.map((item) => item.id);
    const idString = idArray.join(',');
    return idString;
  };

  const handleGetParameters = async () => {
    try {
      const data = await getHesHParameters({
        revenue_type: categorySelect,
        filter_by: tabsTofind?.label,
        ids: getIdString(selectedPlaces),
      });
      setSumary(data.data);
    } catch (error) {
      console.error(error);
    }
  };

  const handleConfirmationClick = async () => {
    setModalInfo({
      title: 'Estamos despegando',
      title2: 'El cambio puede tomar un tiempo',
      subtitle:
        'En cualquier momento verás la notificación de que el cambio fue realizado o, sí, por el contrario, ocurrió un error.',
      buttonOk: '',
      buttonCancel: '',
      action: () => {},
      // eslint-disable-next-line global-require
      image: require('../../assets/images/ship.gif'),
      showButtons: false,
    });
    const dataToSend = {
      revenue: categorySelect,
      filter_by: tabsTofind?.label,
      ids: selectedPlaces.map((item) => item.id),
      parameter: isPercentil ? 'percentile' : 'unit',
      value: +newValue,
    };

    try {
      await putHesHParameters(dataToSend, country);
      setModalInfo({
        title: 'Cambio exitoso',
        title2: 'Has editado los parámetros actuales',
        subtitle:
          'Puedes regresar y revisar que los cambios se hayan aplicado.',
        buttonOk: 'Aceptar',
        buttonCancel: 'Cancelar',
        action: () => {
          window.location.reload();
        },
        // eslint-disable-next-line global-require
        image: require('../../assets/images/office-working2.svg'),
        showButtons: true,
      });
    } catch (error) {
      console.error(error);
      setModalInfo({
        title: 'Cambio no aplicado',
        title2: 'No se pudieron editar los descuentos',
        subtitle:
          'Si identificas que es un error del portal, repórtalo al canal de inconsistencias-dudas-vendedores.',
        buttonOk: 'Volver a intentarlo',
        buttonCancel: 'Cancelar',
        action: handleConfirmationClick,
        // eslint-disable-next-line global-require
        image: require('../../assets/images/office-working3.svg'),
        showButtons: true,
      });
    }
  };

  const handleSaveClick = () => {
    // eslint-disable-next-line @typescript-eslint/no-shadow
    const tabItemsLabel = isSingular ? tabItemsSingular : tabItemsPlural;
    const foundTabItemLabel: TabItem | undefined = tabItemsLabel.find(
      (item) => item.id === tabSelected
    );
    const pluralLabel = isSingular ? '' : 's';
    const label = foundTabItemLabel?.label || '';
    setModalInfo({
      title: '¿Estás seguro de editar estos parámetros?',
      subtitle: `El editar estos parámetros afectará a todos los inmuebles que pertenecen a la${pluralLabel} ${label}  seleccionada${pluralLabel}`,
      buttonOk: 'Sí, cambiar',
      buttonCancel: 'No, cancelar',
      action: handleConfirmationClick,
      // eslint-disable-next-line global-require
      image: require('../../assets/images/office-working.svg'),
      showButtons: true,
    });

    setShowModal(true);
  };

  return (
    <>
      <TitleContainer>Habi es Habi</TitleContainer>
      <ContainerZoneValidationMx>
        <SelectTypeZoneMx
          tabSelected={tabSelected}
          setTabSelected={setTabSelected}
        />
        {step >= 1 && tabSelected !== 0 && (
          <>
            <Divisor />
            <SelectArea
              selectedData={selectData}
              loading={loading}
              selectedPlaces={selectedPlaces}
              setSelectedPlaces={setSelectedPlaces}
            />
          </>
        )}
        {step >= 2 && (
          <>
            <Divisor />
            <RevenueCategory setCategorySelect={setCategorySelect} />
          </>
        )}
        {step >= 3 && (
          <>
            <Divisor />
            <ModifyParameterTab
              isPercentil={isPercentil}
              setIsPercentil={setIsPercentil}
            />
            <Divisor />
            <NewParameter
              isPercentil={isPercentil}
              tabSelected={tabSelected}
              categorySelect={categorySelect}
              isSingular={isSingular}
              summary={sumary}
              newValue={newValue}
              setNewValue={setNewValue}
              error={inputError}
            />
            <ButtonContainerHesh>
              <button
                className="button-hesh"
                onClick={() => {
                  handleSaveClick();
                }}
                disabled={valueError}
              >
                Guardar cambios
              </button>
            </ButtonContainerHesh>
          </>
        )}

        <ModalConfirmation
          showModal={showModal}
          setShowModal={setShowModal}
          modalInfo={modalInfo}
          id={1}
          showButtons={modalInfo.showButtons}
        />
      </ContainerZoneValidationMx>
    </>
  );
};

const HesH = () => {
  const country = localStorage.getItem('country');
  const alertState = useAppSelector((state) => state.alertStore);
  const dispatch = useAppDispatch();
  const { hideAlert } = alertStoreActions;

  const handleAlert = () => {
    dispatch(hideAlert());
  };

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {country === 'MX' ? (
        <ZoneValidation />
      ) : (
        <LayoutWrapper>
          <LayoutSidebar />
          <ContainerPage>
            <LayoutHeaderClean />
            <AlertToast
              type={alertState.type}
              text={alertState.text}
              showAlert={alertState.showAlert}
              setShowAlert={handleAlert}
              top="75px"
            />
            <ZoneValidation />
          </ContainerPage>
        </LayoutWrapper>
      )}
    </>
  );
};

export default HesH;
