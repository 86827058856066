import { useEffect, useState } from 'react';

import { getInventoryQuality } from '../../../apis/revenue/revenue';
import { BubbleData, MedianZoneQuality } from '../types';
import generateColor from '../utils/generateColor';

const parseToPercentage = (val = 0) => {
  const rawPercentage = val * 100;

  return +rawPercentage.toFixed(2);
};

const useInventoryQuality = (
  metropolitanId: number,
  medianZoneId: number,
  setLoadedQuality: (val: boolean) => void,
  dateRange: [Date, Date?]
) => {
  const [rawInventoryQuality, setRawInventoryQuality] =
    useState<MedianZoneQuality[]>();
  const [cleanInventoryQuality, setCleanInventoryQuality] =
    useState<MedianZoneQuality[]>();
  const [maxMarketShare, setMaxMarketShare] = useState(0);
  const [maxPurchasesNegotiation, setMaxPurchasesNegotiation] = useState(0);
  const [hasError, setHasError] = useState(false);

  const [inventoryQuality, setInventoryQuality] = useState<BubbleData[]>();

  useEffect(() => {
    if (!dateRange || !metropolitanId) return;

    setLoadedQuality(false);

    getInventoryQuality(metropolitanId, dateRange)
      .then((response) => {
        setRawInventoryQuality(response.data.median_zones);
        setHasError(false);
        setLoadedQuality(true);
      })
      .catch(() => {
        setHasError(true);
        setLoadedQuality(true);
      });
  }, [dateRange, metropolitanId, setLoadedQuality]);

  useEffect(() => {
    if (!rawInventoryQuality) return;

    const cleanIQ = rawInventoryQuality.filter(
      (medianZone) =>
        medianZone.market_share &&
        medianZone.market_share < 1 &&
        medianZone.sellers &&
        (medianZone.purchases_negotation || medianZone.revenue_score)
    );
    const zonesMarketShares = cleanIQ.map(
      (medianZone) => medianZone.market_share
    );
    const calcMarketShare = Math.max(...zonesMarketShares, 0);
    const zonesPurchasesNegotiation = cleanIQ.map(
      (medianZone) => medianZone.purchases_negotation
    );
    const calcPurchasesNegotiation = Math.max(...zonesPurchasesNegotiation);

    setMaxMarketShare(calcMarketShare);
    setMaxPurchasesNegotiation(calcPurchasesNegotiation);
    setCleanInventoryQuality(cleanIQ);
  }, [rawInventoryQuality]);

  useEffect(() => {
    if (!cleanInventoryQuality) return;

    const parsedIQ = cleanInventoryQuality.map<BubbleData>((medianZone) => ({
      backgroundColor: generateColor(
        'BC7EFF',
        '84DAFF',
        (medianZone.market_share ?? 0) / maxMarketShare
      ),
      borderColor:
        medianZone.zm_id === medianZoneId
          ? '000000'
          : generateColor(
              'BC7EFF',
              '84DAFF',
              (medianZone.market_share ?? 0) / maxMarketShare
            ),
      borderWidth: 1,
      label: medianZone.label_zm,
      datalabels: {
        display: false,
      },
      data: [
        {
          sells: medianZone.sellers,
          x: parseToPercentage(medianZone.purchases_negotation),
          y: parseToPercentage(medianZone.revenue_score),
          id: medianZone.zm_id,
        },
      ],
    }));

    setInventoryQuality(parsedIQ);
  }, [cleanInventoryQuality, maxMarketShare, medianZoneId]);

  return {
    data: inventoryQuality,
    qualityData: cleanInventoryQuality,
    maxMarketShare: parseToPercentage(maxMarketShare),
    maxPurchasesNegotiation: parseToPercentage(maxPurchasesNegotiation),
    hasError,
  };
};

export default useInventoryQuality;
