import { Dispatch, SetStateAction } from 'react';
import {
  ColumnDescription,
  ContainerSelectTypeZoneMx,
  TabContainer,
  TabItem,
} from '../../../ZoneValidationMx/styles';

interface IRuleTypeTab {
  selectedTypeRule: number;
  setSelectedTypeRule: Dispatch<SetStateAction<number>>;
}

const RuleTypeTab = ({
  selectedTypeRule,
  setSelectedTypeRule,
}: IRuleTypeTab) => {
  const tabItems = [
    { id: 1, label: 'Reglas por zona' },
    { id: 2, label: 'Reglas globales' },
  ];

  return (
    <ContainerSelectTypeZoneMx>
      <ColumnDescription>
        <div className="subtitle">
          Selecciona el tipo de regla que quieres editar
        </div>
      </ColumnDescription>
      <TabContainer>
        {tabItems.map((tab) => {
          let className = '';

          if (tab.id === selectedTypeRule) {
            className += `${tab.id === 1 ? 'first' : 'last'} selected`;
          } else if (tab.id === 1) {
            className += 'first';
          } else if (tab.id === 2) {
            className += 'last';
          }

          return (
            <TabItem
              key={tab.id}
              className={className}
              onClick={() => setSelectedTypeRule(tab.id)}
            >
              {tab.label}
            </TabItem>
          );
        })}
      </TabContainer>
    </ContainerSelectTypeZoneMx>
  );
};

export default RuleTypeTab;
