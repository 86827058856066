import { useEffect, useState } from 'react';

import {
  GraphicsContainer,
  GraphicWrapper,
  TitleAndSubtitle,
  TitleGraphic,
  TitleSection,
  TitleZone,
  WrapperOpportunities,
} from './styles';
import HabiMoney from './assets/habi-money.svg';
import LocationIcon from './assets/location.svg';
import Graphic from '../Graphic/Graphic';
import {
  electricPurple100,
  electricPurple200,
  electricPurple300,
  electricPurple400,
  tomatoCoral100,
  tomatoCoral200,
  tomatoCoral400,
  tomatoCoral500,
} from '../../../../styles/stylesConstants';

const Opportunities: React.FC<{
  zonePricing: any;
  metropolitanPricing: {
    am_label: string;
    percent_manual: number;
    percent_automatico: number;
    percent_n: number;
    percent_sweeper: number;
  };
}> = ({ zonePricing, metropolitanPricing }) => {
  const [statistics, setStatistics] = useState<any>([]);

  useEffect(() => {
    const newStatistics = [];

    if (metropolitanPricing) {
      newStatistics.push({
        ...metropolitanPricing,
        type: 'primary',
        first: true,
        typeZone: 'Área Metropolitana',
        name: metropolitanPricing.am_label,
        colors: [
          electricPurple400,
          electricPurple200,
          electricPurple100,
          electricPurple300,
        ],
      });
    }

    if (zonePricing) {
      newStatistics.push({
        ...zonePricing,
        type: 'secondary',
        typeZone: 'Zona Mediana',
        first: false,
        name: zonePricing.zm_label,
        colors: [
          tomatoCoral400,
          tomatoCoral100,
          tomatoCoral200,
          tomatoCoral500,
        ],
      });
    }

    setStatistics(newStatistics);
  }, [zonePricing, metropolitanPricing]);

  return (
    <WrapperOpportunities>
      <TitleSection>
        <img src={HabiMoney} alt="Icono de una casa con dinero" />

        <div className="title">
          Cantidad de N&apos;s en la oportunidad de negocio en la zona
        </div>
      </TitleSection>
      <GraphicsContainer>
        {statistics.map((statistic: any) => {
          return (
            <GraphicWrapper key={statistic.name} first={statistic.first}>
              <TitleZone>
                <img src={LocationIcon} alt="pin del mapa" />
                <TitleAndSubtitle>
                  <div className="title">{statistic.name}</div>
                  <div className="subtitle">{statistic.typeZone}</div>
                </TitleAndSubtitle>
              </TitleZone>
              <TitleGraphic>Forma en la que se asignó precio</TitleGraphic>
              <Graphic statistic={statistic} />
            </GraphicWrapper>
          );
        })}
      </GraphicsContainer>
    </WrapperOpportunities>
  );
};

export default Opportunities;
