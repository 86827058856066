import styled from 'styled-components';
import {
  disabledColor,
  monserratRegular,
  robotoRegular,
  white,
} from '@habitech/shared';
export const ContainerImagesResult = styled.div`
  margin: 44px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .image-show {
    margin: 13px 0 16px 0;
  }
  .title {
    color: var(--font-habi-pcom-main-aaa, #252129);
    text-align: center;
    font-family: ${monserratRegular};
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    max-width: 336px;
    margin-bottom: 16px;
  }
  .subtitle {
    color: var(--font-habi-pcom-secundary-aa, #6d6970);
    text-align: center;
    font-family: ${robotoRegular};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    max-width: 336px;
  }
`;
export const ContainerRulesPendinsssgApproved = styled.div`
  display: flex;
  flex-direction: column;
  background: ${white};
  padding: 24px;
  border-radius: 6px;
  margin-bottom: 24px;
  border: 1px solid ${disabledColor};
`;
