import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface AlertStoreProps {
  type: 'success' | 'warning' | 'error';
  text: string;
  showAlert: boolean;
}

const initialState: AlertStoreProps = {
  type: 'success',
  text: '',
  showAlert: false,
};

const alertSlice = createSlice({
  name: 'alertStore',
  initialState,
  reducers: {
    updateAlert: (state, action: PayloadAction<AlertStoreProps>) => {
      state.type = action.payload.type;
      state.text = action.payload.text;
      state.showAlert = action.payload.showAlert;
    },
    showAlert: (state) => {
      state.showAlert = true;
    },
    hideAlert: (state) => {
      state.showAlert = false;
    },
  },
});

export const alertStoreActions = alertSlice.actions;

export default alertSlice.reducer;
