import { useEffect, useState } from 'react';
import ReactPortal from '../ReactPortal';
import { IModalNotificationProps } from './model';
import {
  WrapperModalNotification,
  WrapperModalContent,
  Title,
  Description,
  SubTitle,
} from './styles';
import BadCaseImg from './assets/bad-case.png';
import GoodCaseImg from './assets/good-case.png';
import { Button } from '@habitech/call-to-action';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';

const ModalNotification: React.FC<IModalNotificationProps> = ({
  success,
  copiesForCases = {
    success: {
      title: 'Cambio exitoso',
      subtitle: 'Has editado el número de cupos',
      description:
        'Ya puedes volver al listado de cupos por listas y añadir o borrar a las listas existentes.',
    },
    fail: {
      title: 'Cambio sin exito',
      subtitle: 'No se pudo editar el cupo en la lista',
      description:
        'Si identificas que es un error del portal, repórtalo al equipo de Spacestation.',
    },
  },
  handleClose,
  handleRetry,
}) => {
  const [copies, setCopies] = useState<any>({
    title: '',
    subtitle: '',
    description: '',
  });

  useEffect(() => {
    if (success) {
      setCopies(copiesForCases.success);
    } else {
      setCopies(copiesForCases.fail);
    }
  }, [success]);

  return (
    <ReactPortal wrapperId="portal-root">
      <WrapperModalNotification>
        <WrapperModalContent>
          <CloseRoundedIcon className="close" onClick={handleClose} />
          <img
            className="image"
            src={success ? GoodCaseImg : BadCaseImg}
            width="132"
          />
          <Title>{copies.title}</Title>
          <SubTitle>{copies.subtitle}</SubTitle>
          <Description>{copies.description}</Description>
          {success ? (
            <>
              <Button dataId="cameback" size="large" onClick={handleClose}>
                Volver a la lista
              </Button>
            </>
          ) : (
            <div className="container-buttons">
              <Button
                dataId="retry"
                id="retry"
                size="large"
                onClick={handleRetry}
              >
                Volver a intentarlo
              </Button>
              <Button
                dataId="cancel"
                size="large"
                variant="white"
                onClick={handleClose}
              >
                Cancelar
              </Button>
            </div>
          )}
        </WrapperModalContent>
      </WrapperModalNotification>
    </ReactPortal>
  );
};

export default ModalNotification;
