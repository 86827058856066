import dateToParams from '../../utils/dateToParams';
import zonesClient from '../../utils/zonesClient';
import {
  IGetAntiFunnelAmPieProps,
  IGetAntiFunnelZmPieProps,
  IGetFunnelMedianZoneProps,
  IGetFunnelMetropolitanAreaProps,
  IGetZoneIndicators,
} from './model';

const pathMetropolitanArea = process.env.REACT_APP_GET_FUNNEL_METROPOLITAN_AREA;

export const getFunnelMetropolitanArea = async ({
  metropolitan_area_id,
  date,
  filter,
}: IGetFunnelMetropolitanAreaProps) => {
  const dateParams = dateToParams(date);

  const { data } = await zonesClient.get<IApiResponse>(pathMetropolitanArea!, {
    params: {
      ...dateParams,
      metropolitan_area_id,
      filter,
    },
  });

  return data;
};

const pathMedianZone = process.env.REACT_APP_GET_FUNNEL_MEDIAN_ZONE;

export const getFunnelMedianZone = async ({
  median_zone_id,
  date,
  filter,
}: IGetFunnelMedianZoneProps) => {
  const dateParams = dateToParams(date);

  const { data } = await zonesClient.get<IApiResponse>(pathMedianZone!, {
    params: {
      ...dateParams,
      median_zone_id,
      filter,
    },
  });

  return data;
};

const pathFunnelStages = process.env.REACT_APP_GET_FUNNEL_STAGES;

export const getFunnelStages = async () => {
  const { data } = await zonesClient.get<IApiResponse>(pathFunnelStages!, {});
  return data;
};

const pathAntiFunnelAM = process.env.REACT_APP_GET_ANTIFUNNEL_PIE_AM;

export const getAntiFunnelPieAM = async (
  paramsApi: IGetAntiFunnelAmPieProps
) => {
  const dateParams = dateToParams(paramsApi.date);

  const { data } = await zonesClient.get<IApiResponse>(pathAntiFunnelAM!, {
    params: {
      ...dateParams,
      metropolitan_area_id: paramsApi.metropolitan_area_id,
      filter: paramsApi.filter,
      stage: paramsApi.stageFilter,
    },
  });

  return data;
};

const pathAntiFunnelZM = process.env.REACT_APP_GET_ANTIFUNNEL_PIE_ZM;

export const getAntiFunnelPieZM = async (
  paramsApi: IGetAntiFunnelZmPieProps
) => {
  const dateParams = dateToParams(paramsApi.date);

  const { data } = await zonesClient.get<IApiResponse>(pathAntiFunnelZM!, {
    params: {
      ...dateParams,
      median_zone_id: paramsApi.median_zone_id,
      filter: paramsApi.filter,
      stage: paramsApi.stageFilter,
    },
  });

  return data;
};

const pathIndicatorsGet = process.env.REACT_APP_GET_ZONE_INDICATORS;

export const getZoneValidations = async (params: IGetZoneIndicators) => {
  const { data } = await zonesClient.get<IApiResponse>(pathIndicatorsGet!, {
    params,
  });
  return data;
};
