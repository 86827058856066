import styled from 'styled-components';

import {
  borderGrayResume,
  hintPerano200,
  principalFont,
  purple,
  scarpaGray600,
  scarpaGray800,
  secondaryAA,
  separator,
  nightBlue500,
  white,
} from '../../../../styles/stylesConstants';

export const WrapperTolerance = styled.div`
  position: relative;
  z-index: 10;
  margin: 20px 0;
`;
export const TitleSectionTop = styled.div`
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 32px;
  color: ${nightBlue500};
  display: flex;
  gap: 8px;
  margin-bottom: 26px;
`;

export const RulesWrapper = styled.div`
  background: ${white};
  box-shadow: 0px 0px 1px rgba(48, 49, 51, 0.05),
    0px 2px 4px rgba(48, 49, 51, 0.1);
  border-radius: 6px;
  display: grid;
  padding: 24px 18px;
`;
export const ZonePagesContainer = styled.div``;

export const ZonePagesTabs = styled.div`
  width: 100%;
  height: 48px;

  margin-bottom: 24px;
  border-bottom: 1px solid ${separator};
`;

export const PageTab = styled.button<{ active: boolean }>`
  display: inline-block;
  padding: 16px;
  background: none;
  border: none;
  outline: none;
  font-size: 14px;
  font-weight: 600;
  color: ${secondaryAA};
  height: 48px;
  font-family: ${principalFont};
  &:hover {
    cursor: pointer;
  }
  ${({ active }) =>
    active &&
    `
    color: ${scarpaGray800};
    border-bottom: 4px solid ${purple};
  `}
`;

export const ZonePageItem = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;

  p {
    color: ${scarpaGray800};
    margin-left: 16px;
    font-weight: 700;
  }
`;

export const ZonePageList = styled.div`
  display: grid;
  gap: 25px;
`;

export const PoliticsSection = styled.div`
  display: grid;
  padding: 0 0 0 15px;
  .actions {
    gap: 24px;
    display: grid;
    grid-template-columns: auto 1fr;
    button {
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 40px;
      height: 40px;
    }
  }
`;

interface PoliticsSubSectionProps {
  last: string;
}

export const PoliticsSubSection = styled.div<PoliticsSubSectionProps>`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 40px;
  padding: 0 0 34px 15px;
  border-bottom: ${({ last }) =>
    last === 'false' ? `1px solid ${hintPerano200}` : ''};
  margin-bottom: 30px;
  align-items: center;

  label {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 24px;
    color: ${scarpaGray800};
  }
  input {
    font-family: 'Open Sans' !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 16px !important;
    line-height: 40px !important;
    color: ${secondaryAA} !important;
  }
`;

export const SubTitleSection = styled.div`
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: ${scarpaGray800};
  margin-bottom: 22px;
  padding: 0 0 0 15px;
`;
export const TitleSection = styled.div`
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: ${scarpaGray800};
  margin-bottom: 20px;
`;

export const Indicator = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  gap: 18px;
`;

export const IndicatorText = styled.div`
  display: grid;
  .value {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 20px;
    color: ${scarpaGray600};
  }
  .note {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: ${scarpaGray800};
  }
`;

export const Separator = styled.div`
  width: 100%;
  border-top: 1px solid ${borderGrayResume};
  margin: 35px 0;
`;
