import withRoutePage, { RoutePage } from '../../utils/withRoutePage';
import CardContainerForm from './componets/CardContainerForm/CardContainerForm';
import { TitleContainer } from './styles';

const ZoneValidation: RoutePage = () => {
  return (
    <>
      <TitleContainer>Validación de zona</TitleContainer>
      <CardContainerForm />
    </>
  );
};

ZoneValidation.requiresAuth = true;

export default withRoutePage(ZoneValidation);
