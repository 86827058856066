import zonesClient from '../../utils/zonesClient';
import {
  IGetAutoPricingParams,
  MapeGraphicProps,
  UpdateAdminParamsProps,
  UpdateZonePricing,
} from './model';

const path = process.env.REACT_APP_ZONES_PUT_PERCENT;

export const putPercentZone = async ({
  apartment_discount,
  house_discount,
  zone_id,
  userEmail,
}: // eslint-disable-next-line consistent-return
UpdateZonePricing = {}) => {
  if (
    process.env.REACT_APP_ZONES_API_URL &&
    process.env.REACT_APP_ZONES_GET_INFO_QUOTAS &&
    process.env.REACT_APP_ZONES_API_KEY
  ) {
    const { data } = await zonesClient.put<IApiResponse>(path!, {
      apartment_discount,
      house_discount,
      zone_id,
      user_email: userEmail,
    });

    return data;
  }
};

const pathParams = process.env.REACT_APP_ZONES_PUT_ADMIN_PARAMETERS;

export const putAdminParameters = async ({
  zone_id,
  tolerance,
  limit_admin,
  user_email,
}: // eslint-disable-next-line consistent-return
UpdateAdminParamsProps = {}) => {
  if (
    process.env.REACT_APP_ZONES_API_URL &&
    process.env.REACT_APP_ZONES_GET_INFO_QUOTAS &&
    process.env.REACT_APP_ZONES_API_KEY
  ) {
    const { data } = await zonesClient.put<IApiResponse>(pathParams!, {
      tolerance,
      limit_admin,
      zone_id,
      user_email,
    });

    return data;
  }
};

const mapeGraphicUrl = process.env.REACT_APP_GET_MAPE_LINE_PLOT;

export const getMapeLinePlot = async ({
  start_date,
  end_date,
  metropolitan_area_id,
}: MapeGraphicProps) => {
  const { data } = await zonesClient.get<IApiResponse>(mapeGraphicUrl!, {
    params: { start_date, end_date, metropolitan_area_id },
  });

  return data;
};

interface AutoPricingData {
  per_automatic_committee: number;
  month_metro: string;
}

export const getAutoPricing = async (params: IGetAutoPricingParams) => {
  const { data } = await zonesClient.get<IApiResponse<AutoPricingData[]>>(
    'get_auto_pricing_ma',
    { params }
  );

  return data;
};
