import {
  Chart as ChartJS,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController,
} from 'chart.js';
import { Chart } from 'react-chartjs-2';
import {
  CategoryGraph,
  TitleAndSubtitle,
  TitleZone,
  WrapperBehaviorMS,
  WrapperChart,
} from './styles';
import LocationIcon from './assets/location.svg';
import { selectMedianZone } from '../../../../redux/reducers/zoneSelectors';
import { useAppSelector } from '../../../../redux/hooks';
import {
  electricPurple500,
  skyBlue100,
  skyBlue300,
  white,
} from '@habitech/shared';
import { useEffect, useState } from 'react';
import { getMarketShareMedianZone } from '../../../../apis/marketShare/marketShare';
import { selectUserDateRange } from '../../../../redux/reducers/userSelectors';
import { MONTHS_DICT } from '../../../MarketingLeads/components/LineGraphic/utils';
import { LoaderContainer } from '../../../Utility/style';
import HabiLoader from '../../../../components/HabiLoader/HabiLoader';

const BehaviorMarketShare = () => {
  const [marketShareData, setMarketShareData] = useState();
  const [monthlyPurchases, setMonthlyPurchases] = useState();
  const [labels, setLabels] = useState();
  const [loading, setLoading] = useState<boolean>(true);
  const median_zone = useAppSelector(selectMedianZone);
  const userDateRange = useAppSelector(selectUserDateRange);

  ChartJS.register(
    LinearScale,
    CategoryScale,
    BarElement,
    PointElement,
    LineElement,
    Legend,
    Tooltip,
    LineController,
    BarController
  );

  const data = {
    labels,
    datasets: [
      {
        type: 'line' as const,
        label: 'Market Share',
        borderColor: electricPurple500,
        borderWidth: 2.5,
        fill: false,
        data: marketShareData,
        pointBorderWidth: 14,
        radius: 4,
        pointBackgroundColor: electricPurple500,
        yAxisID: 'y',
        datalabels: {
          display: false,
        },
      },

      {
        type: 'bar' as const,
        label: 'Compras mensuales',
        backgroundColor: skyBlue100,
        data: monthlyPurchases,
        yAxisID: 'y1',
        datalabels: {
          display: false,
        },
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        grid: {
          color: function () {
            return white;
          },
        },
        title: {
          display: true,
          text: 'Fecha precio',
          color: '#474747',
          font: { weight: '600', family: 'Roboto', fontSize: '12px' },
        },
      },
      y: {
        type: 'linear' as const,
        display: true,
        position: 'left' as const,
        title: {
          display: true,
          text: 'Compras mensuales',
          color: '#474747',
          font: { weight: '600', family: 'Roboto', fontSize: '12px' },
        },
      },
      y1: {
        type: 'linear' as const,
        display: true,
        position: 'right' as const,
        grid: {
          drawOnChartArea: false,
        },
        title: {
          display: true,
          text: 'Market share',
          color: '#474747',
          font: { weight: '600', family: 'Roboto', fontSize: '12px' },
        },
        ticks: {
          callback: function (value: any) {
            return value + '%';
          },
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
      tooltip: {
        displayColors: false,
        callbacks: {
          label: (context: any) => {
            return `${context.dataset.label}: ${
              context.dataset.type !== 'bar'
                ? `${parseFloat(context.formattedValue).toFixed(1)}%`
                : context.formattedValue
            }`;
          },
        },
      },
    },
  };

  const getMarketShareData = async () => {
    const start_date = new Date(userDateRange[0])
      .toISOString()
      .substring(0, 10);
    const end_date = new Date(userDateRange[1]).toISOString().substring(0, 10);

    setLoading(true);
    await getMarketShareMedianZone({
      median_zone_id: median_zone.id,
      start_date,
      end_date,
    }).then((response) => {
      setLoading(false);
      let new_labels: any = [];
      response.data.labels.forEach((label: string) => {
        new_labels.push(MONTHS_DICT.EN[label.toLocaleLowerCase()]);
      });
      setMonthlyPurchases(response.data.monthly_purchases);
      let newMarkertShare: any = [];
      response.data.market_share.forEach((item: any) => {
        newMarkertShare.push(item * 100);
      });
      setMarketShareData(newMarkertShare);
      setLabels(new_labels);
    });
  };

  useEffect(() => {
    getMarketShareData();
  }, [userDateRange]);

  return (
    <WrapperBehaviorMS>
      {loading ? (
        <LoaderContainer>
          <HabiLoader size="small" />
        </LoaderContainer>
      ) : (
        <>
          <div className="header-graph">
            <TitleZone>
              <img src={LocationIcon} alt="pin del mapa" />
              <TitleAndSubtitle>
                <div className="title">{median_zone.label}</div>
                <div className="subtitle">Zona mediana</div>
              </TitleAndSubtitle>
            </TitleZone>
            <hr className="divider" />
            <div className="graph-guides">
              <div className="subtitle">Comportamiento de la zona</div>
              <div className="colors-guides">
                <CategoryGraph color={skyBlue300}>
                  <div className="color-indicator"></div>
                  <div className="title">Market Share</div>
                </CategoryGraph>
                <div className="category">
                  <div className="indicator"></div>
                  <div>Compras mensuales</div>
                </div>
              </div>
            </div>
          </div>
          <WrapperChart>
            <Chart type="bar" options={options} data={data} redraw />
          </WrapperChart>
        </>
      )}
    </WrapperBehaviorMS>
  );
};

export default BehaviorMarketShare;
