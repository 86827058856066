import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import { useEffect, useRef, useState } from 'react';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { TextField } from '@habitech/keychain-atoms-inputs';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { Button, DropdownButton } from '@habitech/call-to-action';

import HorizontalRuleRoundedIcon from '@mui/icons-material/HorizontalRuleRounded';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import { getInfoQuotas, putQuotas } from '../../../../apis/politics/politics';
import { useAppSelector, useAppDispatch } from '../../../../redux/hooks';
import { zoneStoreActions } from '../../../../redux/reducers/zoneReducer';
import {
  SectionEdit,
  WrapperDescription,
  WrapperUpdateLogic,
  SubTitle,
  IndicatorList,
  WrapperInputQuota,
  WrapperFilters,
  WrapperAction,
} from './styles';
import HabiLoader from '../../../../components/HabiLoader/HabiLoader';
import ModalNotification from '../../../../components/ModalNotification/ModalNotification';
import { alertStoreActions } from '../../../../redux/reducers/alertReducer';
import { AlertProps } from '../PoliticsAndTolerance/model';

const UpdateLogic = () => {
  const [data, setData] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [saveSuccess, setSaveSuccess] = useState<boolean>(false);
  const [propertiesNumber, setPropertiesNumber] = useState<any>();
  const [revenueValue, setRevenueValue] = useState<any>();
  const [selectedValues, setSelectedValues] = useState<any>({});
  const [valuesQuotas, setValuesQuotas] = useState<any>({});
  const [previousValue, setPreviousValue] = useState<any>({
    name: '',
    value: 0,
  });

  const [dictLabels] = useState<any>({
    blacklist: 'Black list',
    greylist: 'Greylist',
    whithout_list: 'Sin lista',
    good_complex: 'Buen conjunto',
    whitelist: 'White list',
  });
  const dispatch = useAppDispatch();
  const { setQuotas } = zoneStoreActions;

  const schema = Yup.object().shape({
    blacklist: Yup.number().typeError('Debe ser un número'),
    greylist: Yup.number().typeError('Debe ser un número'),
    whithout_list: Yup.number().typeError('Debe ser un número'),
    good_complex: Yup.number().typeError('Debe ser un número'),
    whitelist: Yup.number().typeError('Debe ser un número'),
  });

  const { reset } = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
  });
  const tableRef = useRef<any>(null);
  const zoneState = useAppSelector((state) => state.zoneStore);

  const getQuotas = async () => {
    setData([]);
    setLoading(true);
    let number_properties = propertiesNumber
      ? { min: propertiesNumber, max: propertiesNumber + 99 }
      : null;

    if (propertiesNumber && propertiesNumber === 50) {
      number_properties = propertiesNumber ? { min: 10, max: 50 } : null;
    }

    if (propertiesNumber && propertiesNumber === 51) {
      number_properties = propertiesNumber ? { min: 51, max: 100 } : null;
    }

    if (propertiesNumber && propertiesNumber === 1001) {
      number_properties = propertiesNumber
        ? { min: propertiesNumber, max: propertiesNumber + 999 }
        : null;
    }

    await getInfoQuotas({
      ...number_properties,
      zone_id: zoneState.zoneInfo.zm_id,
      quota_type: revenueValue === '' ? 'null' : revenueValue,
    }).then((response) => {
      setLoading(false);
      setData(response?.data);
      dispatch(setQuotas(response?.data));
    });
  };

  useEffect(() => {
    getQuotas();
  }, []);

  const saveNewQuota = async (name: string) => {
    setLoading(true);
    let number_properties = propertiesNumber
      ? { min: propertiesNumber, max: propertiesNumber + 99 }
      : null;

    if (propertiesNumber && propertiesNumber === 50) {
      number_properties = propertiesNumber ? { min: 10, max: 50 } : null;
    }

    if (propertiesNumber && propertiesNumber === 51) {
      number_properties = propertiesNumber ? { min: 51, max: 100 } : null;
    }

    await putQuotas({
      ...number_properties,
      zone_id: zoneState.zoneInfo.zm_id,
      type_list: name,
      value: valuesQuotas[name],
      quota_type: revenueValue === '' ? 'null' : revenueValue,
    })
      .then(() => {
        setShowModal(true);
        setSaveSuccess(true);

        reset();
        setValuesQuotas({});
        getQuotas();
      })
      .catch(() => {
        setPreviousValue({ name, value: valuesQuotas[name] || 0 });
        setShowModal(true);
        setSaveSuccess(false);
      });
  };

  type RowTable = {
    list_type: string;
    quota_number: string;
    total_lots: string;
  };

  const optionsRanges = [
    { label: 'Menos de 50', value: 50 },
    { label: '51 - 101', value: 51 },
    { label: '101 - 201', value: 101 },
    { label: '201 - 301', value: 201 },
    { label: '301 - 401', value: 301 },
    { label: '401 - 501', value: 401 },
    { label: '501 - 601', value: 501 },
    { label: '601 - 701', value: 601 },
    { label: '701 - 801', value: 701 },
    { label: '801 - 901', value: 801 },
    { label: '901 - 1001', value: 901 },
    { label: 'Más de 1000', value: 1001 },
  ];

  const optionsRevenue = [
    { label: 'General', value: 'G' },
    { label: 'Bone / Basic', value: 'B/B' },
    { label: 'Sub-prime / Regular / Prime', value: 'R/SP/P' },
  ];

  const handleFilter = () => {
    if (propertiesNumber || revenueValue) {
      setSelectedValues({ propertiesNumber, revenueValue });
      getQuotas();
    }
  };

  const addQuota = (name: string) => {
    if (parseInt(valuesQuotas[name]) !== 10) {
      setValuesQuotas({
        [name]: valuesQuotas[name] ? valuesQuotas[name] + 1 : 1,
      });
    }
  };

  const handleLessQuota = (name: string, quota: string) => {
    const next_quota = valuesQuotas[name] ? valuesQuotas[name] - 1 : -1;

    if (parseInt(quota) > 0 && -next_quota <= parseInt(quota)) {
      setValuesQuotas({
        [name]: valuesQuotas[name] ? valuesQuotas[name] - 1 : -1,
      });
    } else {
      const alertData: AlertProps = {
        type: 'error',
        text: 'No se puede eliminar más cupos ya que no puede haber un valor negativo',
        showAlert: true,
      };
      dispatch(alertStoreActions.updateAlert(alertData));
    }
  };

  return (
    <>
      <WrapperUpdateLogic style={{ marginBottom: '24px' }}>
        <WrapperDescription>
          <SubTitle>Selección de lista</SubTitle>
          <div>
            Selecciona los criterios para poder modificar la regla general de
            listas.
          </div>
        </WrapperDescription>
        <WrapperFilters>
          <DropdownButton
            dataId="funnel-states"
            size="fluid"
            variant="white"
            label="No. de inmuebles"
            placeholder="Selecciona"
            disabled={false}
            listOptions={optionsRanges}
            setValue={(value) => setPropertiesNumber(value)}
          />
          <DropdownButton
            dataId="revenue-state"
            size="fluid"
            variant="white"
            label="Revenue"
            placeholder="Selecciona"
            disabled={false}
            listOptions={optionsRevenue}
            setValue={(value) => setRevenueValue(value)}
          />
          <WrapperAction>
            <Button
              dataId="change-zone"
              variant="primary"
              size="large"
              onClick={handleFilter}
              disabled={!(propertiesNumber || revenueValue)}
            >
              Ver lista
            </Button>
          </WrapperAction>
        </WrapperFilters>
      </WrapperUpdateLogic>
      <WrapperUpdateLogic>
        <WrapperDescription>
          <SubTitle>Editar la lista asignada del conjunto</SubTitle>
          <div>
            Para añadir o editar los cupos asignados, se deben añadir de 1 en 1
            o de 2 en 2 y no puede ser mayor a los cupos actuales.
          </div>
        </WrapperDescription>
        <TableContainer component={Paper} className="container-table">
          {loading ? (
            <HabiLoader size="small" />
          ) : (
            <Table aria-label="simple table" ref={tableRef}>
              <TableHead>
                <TableRow>
                  <TableCell width="20%">Lista a la que pertenece</TableCell>
                  <TableCell width="140px" align="left">
                    No. actual de cupos
                  </TableCell>
                  {!selectedValues.hasOwnProperty('revenueValue') && (
                    <>
                      {Object.keys(valuesQuotas).length === 0 && (
                        <TableCell width="150px" align="left">
                          Total de lotes en lista
                        </TableCell>
                      )}
                    </>
                  )}
                  <TableCell align="left">
                    Cupo(s) a asignar a la lista
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.map((row: RowTable) => {
                  const quota_value = parseInt(valuesQuotas[row.list_type]);
                  return (
                    <TableRow
                      key={row.list_type}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        <IndicatorList type={row.list_type}>
                          {dictLabels[row.list_type]}
                        </IndicatorList>
                      </TableCell>
                      <TableCell align="left">
                        <TextField
                          originType="habi"
                          name="tolerance"
                          placeHolder="0"
                          defaultValue={String(row.quota_number) || ''}
                          dataId="testId"
                          autoFocus={false}
                          type="number"
                          disabled
                        />
                      </TableCell>
                      {!selectedValues.hasOwnProperty('revenueValue') && (
                        <>
                          {Object.keys(valuesQuotas).length === 0 && (
                            <TableCell align="left">
                              <TextField
                                originType="habi"
                                name="total-lots"
                                placeHolder="0"
                                defaultValue={row.total_lots}
                                dataId="testId"
                                autoFocus={false}
                                type="number"
                                disabled
                              />
                            </TableCell>
                          )}
                        </>
                      )}
                      <TableCell align="left">
                        <form>
                          <SectionEdit>
                            <WrapperInputQuota>
                              <div
                                className="less "
                                onClick={() => {
                                  handleLessQuota(
                                    row.list_type,
                                    row.quota_number
                                  );
                                }}
                              >
                                <HorizontalRuleRoundedIcon />
                              </div>
                              <input
                                key={valuesQuotas[row.list_type]}
                                disabled
                                defaultValue={0}
                                value={valuesQuotas[row.list_type]}
                              />
                              <div
                                className={`more ${
                                  quota_value > 0 ? 'positive ' : ''
                                } ${quota_value === 10 ? 'disabled-more' : ''}`}
                                onClick={() => addQuota(row.list_type)}
                              >
                                <AddRoundedIcon />
                              </div>
                            </WrapperInputQuota>
                            {valuesQuotas.hasOwnProperty(row.list_type) &&
                              valuesQuotas[row.list_type] !== 0 && (
                                <Button
                                  dataId="save-quota"
                                  variant="stroke"
                                  size="large"
                                  iconRight={<InsertDriveFileOutlinedIcon />}
                                  onClick={() => saveNewQuota(row.list_type)}
                                >
                                  Guardar
                                </Button>
                              )}
                          </SectionEdit>
                        </form>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          )}
        </TableContainer>
        {showModal && (
          <ModalNotification
            success={saveSuccess}
            handleClose={() => {
              setShowModal(false);
            }}
            handleRetry={() => {
              saveNewQuota(previousValue.name);
            }}
          />
        )}
      </WrapperUpdateLogic>
    </>
  );
};

export default UpdateLogic;
