import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface Card {
  id: number;
  size: string;
  edited: boolean;
  approved: string;
  loading: boolean;
  data: any;
  table?: any;
  showDetail?: boolean;
}
interface HistoricalCard {
  nid: string;
  published: number;
  no_published: number;
  sold: number;
  table_info?: any;
  table?: any;
}
interface IHistoricalDeals {
  nid: string;
  latitud: string;
  longitud: string;
  zona_mediana_id: string;
  negocios_historico?: string;
  radius_prices?: string;
  len_exp?: string;
}

export interface IApproverCardsSlice {
  stateCards: Card[];
  historicalBusinesses: HistoricalCard[];
  historicalDeals?: IHistoricalDeals[];
}

const initialState: IApproverCardsSlice = {
  stateCards: [
    {
      id: 1,
      size: 'l',
      edited: false,
      approved: 'warning',
      loading: true,
      data: {
        title: 'Alertas precios del comité final y Hubspot',
        values: {
          ask_price_committee: '-',
          ask_price_hubspot: '-',
          purchasing_committee: '-',
          manual_price_hubspot: '-',
          gray_work: '-',
        },
      },
    },
    {
      id: 2,
      size: 'm',
      edited: false,
      approved: 'warning',
      loading: true,
      data: {
        title: 'Revenue demasiado bajo',
        values: [
          { title: 'Categoría', value: '-' },
          { title: 'Expectativa días venta', value: '-' },
          { title: '¿Habi es Habi?', value: '-' },
        ],
      },
    },
    {
      id: 3,
      size: 's',
      edited: false,
      approved: 'warning',
      loading: true,
      showDetail: true,
      data: {
        title: 'Tipología Habitaciones y Baños',
        values: {
          text_one: 'Habitaciones',
          value_one: '2',
          text_two: 'Baños',
          value_two: '2',
        },
      },
      table: {
        approval_result: [
          {
            nid: 14880088761,
            count: {
              columns: [0, 1, 2, 3],
              index: [0, 1],
              data: [
                [1, 2, 3, 4],
                [1, 2, 3, 4],
              ],
            },
            percentage: {
              columns: [0, 1, 2, 3],
              index: [0, 1],
              data: [
                [1, 2, 3, 4],
                [1, 2, 3, 4],
              ],
            },
            percentage_by_column: {
              columns: [0, 1, 2, 3],
              index: [0, 1],
              data: [
                [1, 2, 3, 4],
                [1, 2, 3, 4],
              ],
            },
            axis_x_y: [1, 1],
            approval_result: 'approved',
          },
        ],
      },
    },
    {
      id: 4,
      size: 'm',
      edited: false,
      approved: 'warning',
      loading: true,
      data: {
        title: 'Pricing',
        values: [
          { title: 'Valor mt2', value: '$2,965,326' },
          { title: 'P50 conjunto', value: '30 Días' },
          { title: 'P50 Zona', value: '$2´950,378' },
        ],
      },
    },
    {
      id: 5,
      size: 's',
      edited: false,
      approved: 'warning',
      loading: false,
      data: { title: 'Fotos' },
    },
    {
      id: 6,
      size: 's',
      edited: false,
      approved: 'warning',
      loading: true,
      showDetail: true,
      data: {
        title: 'Zona buena',
      },
      table: [],
    },
    {
      id: 7,
      size: 's',
      edited: false,
      approved: 'warning',
      loading: true,
      showDetail: true,
      data: {
        title: 'Tipologías de Estacionamiento',
        values: {
          text_one: 'Estacionamientos',
          value_one: '2',
        },
      },
      table: {
        approval_result: {
          approval_result: 'approved',
          garages: 1,
          table_data: [
            { num_garages: 1, total_percentage: 50, total_count: 10 },
            { num_garages: 2, total_percentage: 50, total_count: 10 },
          ],
        },
      },
    },
    {
      id: 8,
      size: 's',
      edited: true,
      approved: 'warning',
      loading: true,
      data: {
        title: 'Pricing Acorde a Zona',
        values: {
          text_one: 'AskPrice final:',
          text_two: 'Tope:',
          value_one: '$192,784,984',
          value_two: '$300,000,000',
        },
      },
    },
    {
      id: 9,
      size: 'm',
      edited: false,
      approved: 'warning',
      loading: true,
      data: {
        title: 'Alerta precio final',
        values: [
          { title: 'Rango superior oferta:', value: '$129´354,084' },
          { title: 'Precio oferta final', value: '$148´777,529' },
        ],
        description:
          'El precio final de la oferta no debe exceder más del 10% el rango superior de la oferta.',
      },
    },
    {
      id: 10,
      size: 's',
      edited: false,
      approved: 'warning',
      loading: true,
      showDetail: false,
      data: {
        title: 'Tipologías de Elevadores',
        values: {
          text_one: 'Elevadores',
          value_one: '-',
        },
        dataTable: [
          { total_count: 1, num_elevators: 0, total_percentage: 11 },
          { total_count: 2, num_elevators: 1, total_percentage: 12 },
          { total_count: 3, num_elevators: 2, total_percentage: 13 },
          { total_count: 4, num_elevators: 3, total_percentage: 14 },
          { total_count: 5, num_elevators: 4, total_percentage: 15 },
          { total_count: 6, num_elevators: 5, total_percentage: 16 },
        ],
        elevator: 0,
      },
    },
    {
      id: 11,
      size: 'm',
      edited: false,
      approved: 'warning',
      loading: true,
      data: {
        title: 'Castigo por antigüedad para comité automático',
        values: [
          { title: 'Antiguedad', value: '-' },
          { title: 'Comité automático', value: '-' },
          { title: 'Clasificación Remo', value: '-' },
        ],
      },
    },
    {
      id: 12,
      size: 's',
      approved: 'warning',
      edited: false,
      loading: true,
      data: {
        title: 'Piso y ascensores',
        values: {
          text_one: 'Apartamento: Piso 4',
          text_two: 'Ascensor: No',
          text_three: 'Último piso: No',
        },
      },
    },

    {
      id: 13,
      size: 'm',
      edited: false,
      approved: 'warning',
      loading: true,
      data: {
        title: 'Reporte High Level',
        values: [
          { title: 'Inventario', value: 'Sin lista' },
          { title: 'Cupos disponibles en zona', value: '-' },
          { title: 'Inventario en la zona', value: '-' },
        ],
      },
    },
    {
      id: 14,
      size: 's',
      edited: false,
      approved: 'warning',
      loading: true,
      data: {
        title: 'Comentarios Remodelación',
        values: {
          text_one: 'Comité:',
          text_two: 'Aprobado',
        },
      },
    },
    {
      id: 15,
      size: 's',
      edited: false,
      approved: 'warning',
      loading: true,
      data: {
        title: 'Administración',
        values: {
          text_one: 'Valor:',
          text_two: 'Tope:',
          value_one: '-',
          value_two: '-',
        },
      },
    },
    {
      id: 16,
      size: 's',
      edited: false,
      approved: 'warning',
      loading: true,
      data: {
        title: 'Blacklist',
        values: {
          text_one: '-',
          text_two: '-',
        },
      },
    },
    {
      id: 17,
      size: 's',
      approved: 'warning',
      edited: false,
      loading: true,
      data: {
        title: 'Riesgo / Amenazas',
        values: {
          text_one: 'Inundación',
          text_two: 'Remoción en masa',
          value_one: '--',
          value_two: '--',
        },
      },
    },
    {
      id: 18,
      size: 's',
      approved: 'warning',
      edited: false,
      loading: true,
      data: {
        title: 'Alerta precio manual superior a Ask Price',
        values: {
          text_one: 'Askprice Hubspot',
          text_two: 'Precio Manual comité Hubspot',
          value_one: '-',
          value_two: '-',
        },
      },
    },
    {
      id: 19,
      size: 's',
      edited: false,
      approved: 'warning',
      loading: false,
      data: { title: 'Vista' },
    },
    {
      id: 20,
      size: 's',
      edited: false,
      approved: 'warning',
      loading: false,
      data: { title: 'Daños estructurales por sismo 2017 ' },
    },
    {
      id: 21,
      size: 'm',
      edited: false,
      approved: 'warning',
      loading: true,
      data: {
        title: 'Normativa del Infonavit o Fovissste',
        values: [{ title: 'Area', value: '-' }],
      },
    },
    {
      id: 22,
      size: 's',
      edited: false,
      approved: 'warning',
      loading: true,
      data: {
        title: 'Tipología de Área',
        values: {
          text_one: 'Área',
          value_one: '-',
        },
      },
    },
  ],
  historicalBusinesses: [
    {
      nid: '-',
      published: 0,
      no_published: 0,
      sold: 0,
      table_info: [],
    },
  ],
  historicalDeals: [
    {
      nid: '-',
      latitud: '-',
      longitud: '-',
      zona_mediana_id: '-',
      negocios_historico: '-',
      radius_prices: '-',
      len_exp: '-',
    },
  ],
};

const approverCardsSlice = createSlice({
  name: 'estado',
  initialState,
  reducers: {
    updateCardState: (
      state,
      action: PayloadAction<{ id: number; newData: any }>
    ) => {
      const { id, newData } = action.payload;
      state.stateCards = state.stateCards.map((card) =>
        card.id === id ? { ...card, ...newData } : card
      );
    },
    resetCardState: (state) => {
      state.stateCards = initialState.stateCards;
    },
    updateHistorical: (state, action: PayloadAction<any>) => {
      state.historicalBusinesses = action.payload;
    },
    cleanHistorical: (state) => {
      state.historicalBusinesses = initialState.historicalBusinesses;
    },
    updateHistoricalDeals: (state, action: PayloadAction<any>) => {
      state.historicalDeals = action.payload;
    },
    cleanHistoricalDeals: (state) => {
      state.historicalDeals = initialState.historicalDeals;
    },
  },
});

export const {
  updateCardState,
  resetCardState,
  updateHistorical,
  cleanHistorical,
  updateHistoricalDeals,
  cleanHistoricalDeals,
} = approverCardsSlice.actions;
export default approverCardsSlice.reducer;
