const CharacteristicsDictionary = new Map([
  ['area', 'área'],
  ['rooms', 'habitaciones'],
  ['num_piso', 'numero de piso'],
  ['bathrooms', 'baños'],
  ['num_ascensores', 'ascensor'],
  ['anos_antiguedad', 'años de antigüedad'],
  ['garajes', 'garajes'],
  ['last_ask_price', 'ask price'],
  ['estrato', 'estrato'],
]);

const CharacteristicsCondition = new Map([
  ['equal_to', '='],
  ['less_than', '<'],
  ['greater_than', '>'],
  ['less_equal_to', '<='],
  ['greater_equal_to', '>='],
]);
const CharacteristicsPlusOrLess = new Map([
  ['plus', '+'],
  ['less', '-'],
]);
const CharacteristicsSingularPlural = new Map([
  ['point', 'punto '],
  ['points', 'puntos '],
]);
const CharacteristicsCityArea = new Map([
  ['ciudad_id', 'Ciudad'],
  ['area_metropolitana_id', 'Area Metropolitana'],
]);

const handleOrderAndTranslateData = (text: string) => {
  const test = text.split('-');

  if (test.length <= 6) {
    const newText = CharacteristicsDictionary.get(test[0]);
    const newSecondText = CharacteristicsCondition.get(test[1]) || '';
    const newThirdText = CharacteristicsPlusOrLess.get(test[3]);
    const newFifthText = CharacteristicsSingularPlural.get(test[5]);
    const total = `${newText} ${newSecondText} ${test[2]} ${newThirdText} ${test[4]} ${newFifthText} `;
    return total;
  }

  const newText = CharacteristicsDictionary.get(test[0]);
  const newSecondText = CharacteristicsCondition.get(test[1]) || '';
  const newThirdText = CharacteristicsCityArea.get(test[4]);
  const newFourtText = CharacteristicsCondition.get(test[5]);
  const newFifthText = CharacteristicsPlusOrLess.get(test[7]);
  const newSixthText = CharacteristicsSingularPlural.get(test[9]);
  const total = `${newText} ${newSecondText} ${test[2]} y ${newThirdText} ${newFourtText} ${test[6]} ${newFifthText} ${test[8]} ${newSixthText}`;
  return total;
};

export default handleOrderAndTranslateData;
