import styled from 'styled-components';
import { scarpaGray800 } from '@habitech/shared';
import { electricPurple500 } from '../../styles/stylesConstants';

const WrapperReturnLink = styled.div`
  margin-bottom: 40px;
  height: 35px !important;
  cursor: pointer;
  #comeback {
    font-family: 'Montserrat' !important;
    font-style: normal !important ;
    font-weight: 700 !important;
    font-size: 14px !important;
    line-height: 32px;
    height: 32px;
    color: ${scarpaGray800} !important;
    padding: 0px !important;
    .icon {
      width: 22px;
      height: 22px;
      color: ${electricPurple500};
    }
  }
`;

export default WrapperReturnLink;
