import { DropdownButton } from '@habitech/call-to-action';
import { useEffect, useRef, useState } from 'react';
import { SimpleTextField } from '@habitech/keychain-atoms-inputs';
import { capitalize } from '@mui/material';
import {
  ColumnDescription,
  ContainerCurrentGlobalRules,
  ContainerRules,
} from '../CurrentGlobalRules/styles';
import { RuleFormContainer } from './styles';
import { getGlobalRulesFeatures } from '../../../../apis/approvalRules/approvalRules';
import MultiSelect from './components/MultiSelect';

interface IChangeRulesToApplyProps {
  rulesToApply: any;
  rules: any;
}

const ChangeRulesToApply = ({
  rulesToApply,
  rules,
}: IChangeRulesToApplyProps) => {
  const [hasExecuted, setHasExecuted] = useState(false);
  const [features, setFeatures] = useState<any>([]);
  const [approvalRules, setApprovalRules] = useState<any>([]);
  const [complementOptions, setComplementOptions] = useState<any[]>([]);
  const [complementType, setComplementType] = useState('dropdown');
  const [feature, setFeature] = useState('');
  const [condition, setCondition] = useState<any>(null);
  const [complement, setComplement] = useState('');
  const [selectedRules, setSelectedRules] = useState<any>([]);
  const [decision, setDecision] = useState('');
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isFirstNumber, setIsFirstNumber] = useState(true);
  const initialStatesDisabled = {
    input2: true,
    input3: true,
    input4: true,
    input5: true,
  };
  const [inputsDisable, setInputsDisable] = useState(initialStatesDisabled);
  const country = localStorage.getItem('country');
  const optionsHouse =
    country === 'MX'
      ? [
          { label: 'Departamento en condominio', value: '1' },
          { label: 'Casa sola', value: '2' },
          { label: 'Casa en condominio', value: '3' },
          { label: 'Departamento solo', value: '4' },
        ]
      : [
          { label: 'Apartamento', value: '1' },
          { label: 'Casa', value: '2' },
          { label: 'Casa en conjunto', value: '3' },
        ];
  const conditionOptions = [
    { label: 'Igual a', value: 'equal_to' },
    { label: 'Mayor a', value: 'greater_than' },
    { label: 'Menor a', value: 'less_than' },
    { label: 'Mayor o igual a', value: 'greater_equal_to' },
    { label: 'Menor o igual a', value: 'less_equal_to' },
  ];

  const convertToOptions = (data: any, label: string, id: any) => {
    return data.map((item: any) => ({
      label: capitalize(item[label]),
      value: item[id],
    }));
  };

  const convertToOptionsMulti = (data: any, label: string, id: any) => {
    return data.map((item: any) => ({
      label: capitalize(item[label]),
      id: item[id],
    }));
  };

  useEffect(() => {
    setComplement('');

    switch (String(feature)) {
      case '1':
        setComplementType('dropdown');
        setCondition('equal_to');
        setComplementOptions([
          { label: 'Prime', value: 'Prime' },
          { label: 'Semi-prime', value: 'Semi-prime' },
          { label: 'Regular', value: 'Regular' },
          { label: 'Basic', value: 'Basic' },
          { label: 'Bone', value: 'Bone' },
        ]);
        break;

      case '2':
        setComplementType('decimal');
        break;

      case '3':
        setComplementType('integer');
        break;

      case '4':
        setCondition('equal_to');
        setComplementType('dropdown');
        setComplementOptions([
          { label: '1', value: '1' },
          { label: '2', value: '2' },
          { label: '3', value: '3' },
          { label: '4', value: '4' },
          { label: '5', value: '5' },
          { label: '6', value: '6' },
        ]);
        break;

      case '5':
        setCondition('equal_to');
        setComplementType('dropdown');
        setComplementOptions(optionsHouse);
        break;

      default:
        break;
    }
  }, [feature, condition]);

  // eslint-disable-next-line consistent-return
  const handleValidateValues = (value: string) => {
    const numero = parseInt(value, 10);
    const valuesCo = [50000000, 600000000];
    const valuesMX = [200000, 6000000];
    const valuesForCountry = country === 'MX' ? valuesMX : valuesCo;

    if (
      // eslint-disable-next-line no-restricted-globals
      isNaN(numero) ||
      numero < valuesForCountry[0] ||
      numero > valuesForCountry[1]
    ) {
      setError(true);
      setErrorMessage('Valor no válido');
    } else {
      setError(false);
      return '';
    }
  };

  const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    const validateRegex = (regex: RegExp) => regex.test(value);
    let regex = /^[a-zA-Z0-9_.-]*$/;

    switch (complementType) {
      case 'decimal':
        regex = /^\d{0,10}$/;
        break;

      case 'integer':
        regex = /^(?:\d|[1-4]\d|50)$/;
        break;

      default:
        break;
    }

    if (validateRegex(regex)) {
      setIsFirstNumber(false);
      setComplement(value);
    } else if (isFirstNumber) {
      setComplement('');
    }

    if (value === '' || value === '0' || value.startsWith('0')) {
      setComplement(value);
      setError(true);
      setErrorMessage('Este campo es obligatorio y diferente a 0');
    } else if (complementType === 'decimal') {
      handleValidateValues(value);
    } else {
      setError(false);
      return '';
    }
  };

  const handleGetRulesFeatures = async () => {
    try {
      const res = await getGlobalRulesFeatures();

      if (res.success) {
        let options = convertToOptions(res.attributes, 'label', 'id');

        if (country === 'MX') {
          options = options.filter((item: any) => String(item.value) !== '4');
        }

        setFeatures(options);
      }

      const options = convertToOptionsMulti(rules, 'name', 'id');
      setApprovalRules(options);
    } catch (err) {
      console.error('Error en la operación:', err);
    }
  };

  useEffect(() => {
    const timerId = setTimeout(() => {
      if (!hasExecuted) {
        handleGetRulesFeatures();
        setHasExecuted(true);
      }
    }, 250);

    return () => clearTimeout(timerId);
  }, [hasExecuted]);

  useEffect(() => {
    rulesToApply(
      feature,
      condition,
      complement,
      selectedRules,
      decision,
      error
    );
  }, [feature, condition, complement, selectedRules, decision]);
  const prevMyState = useRef<any>(feature);

  useEffect(() => {
    if (feature !== '') {
      setInputsDisable({
        ...inputsDisable,
        input2: false,
        input3: false,
        input4: true,
        input5: true,
      });
    }

    setError(false);

    if (feature !== prevMyState.current) {
      setComplement('');
      setSelectedRules([]);

      prevMyState.current = feature;
    }
  }, [feature]);

  useEffect(() => {
    if (condition !== null) {
      setInputsDisable({ ...inputsDisable, input3: false });
    }
  }, [condition]);

  useEffect(() => {
    if (complement !== '') {
      setInputsDisable({ ...inputsDisable, input4: false });
    }
  }, [complement]);

  useEffect(() => {
    if (selectedRules.length > 0) {
      setInputsDisable({ ...inputsDisable, input5: false });
    } else {
      setInputsDisable({ ...inputsDisable, input5: true });
    }
  }, [selectedRules]);

  return (
    <ContainerCurrentGlobalRules>
      <ColumnDescription>
        <div className="subtitle">Cambio de reglas a aplicar</div>
        <div className="message-info">*Todos los campos son obligatorios</div>
      </ColumnDescription>
      <ContainerRules>
        <RuleFormContainer data-testid="form-container">
          <DropdownButton
            dataId="feature"
            label="*Características"
            listOptions={features}
            placeholder="Selecciona"
            setValue={(value: any) => {
              setFeature(value);
            }}
            variant="white"
            size="medium"
          />
          <DropdownButton
            disabled={inputsDisable.input2}
            dataId="condition"
            label="*Condición"
            listOptions={conditionOptions}
            placeholder="Selecciona"
            setValue={(value: any) => {
              setCondition(value);
            }}
            variant="white"
            size="medium"
            value={condition}
          />
          {complementType === 'dropdown' && (
            <DropdownButton
              disabled={inputsDisable.input3}
              dataId="complement"
              label="*Complemento"
              listOptions={complementOptions}
              placeholder="Selecciona"
              setValue={(value: any) => {
                setComplement(value);
              }}
              value={complement}
              variant="white"
              size="medium"
            />
          )}
          {complementType !== 'dropdown' && (
            <div className="input-simple-container">
              <SimpleTextField
                value={complement}
                name="complement"
                label="*Complemento"
                onChange={handleInput}
                placeholder="Valor"
                error={error}
                errorMessage={errorMessage}
              />
            </div>
          )}
        </RuleFormContainer>
        <RuleFormContainer style={{ marginTop: '24px' }}>
          <MultiSelect
            disabled={inputsDisable.input4}
            selectedRules={selectedRules}
            setSelectedRules={setSelectedRules}
            approvalRules={approvalRules}
            loading={false}
          />
          <DropdownButton
            disabled={inputsDisable.input5}
            dataId="decision"
            label="*Decisión"
            listOptions={[
              {
                label: 'Aprobar todo',
                value: '1',
              },
              {
                label: 'Rechazar todo',
                value: '0',
              },
            ]}
            placeholder="Selecciona"
            setValue={(value: any) => {
              setDecision(value);
            }}
            variant="white"
            size="medium"
          />
        </RuleFormContainer>
      </ContainerRules>
    </ContainerCurrentGlobalRules>
  );
};

export default ChangeRulesToApply;
