import zonesClient from '../../utils/zonesClient';
import {
  IGetInventoryDataProps,
  IGetInventoryMAParamsProps,
  IGetInventoryMZParamsProps,
} from './model';

export const getInventoryTimeMA = async (
  params: IGetInventoryMAParamsProps
) => {
  const FUNCTION_NAME = process.env.REACT_APP_GET_INVENTORY_MA || '';

  const { data } = await zonesClient.get<IGetInventoryDataProps>(
    FUNCTION_NAME,
    {
      params,
    }
  );

  return data;
};

export const getInventoryTimeZM = async (
  params: IGetInventoryMZParamsProps
) => {
  const FUNCTION_NAME = process.env.REACT_APP_GET_INVENTORY_ZM || '';

  const { data } = await zonesClient.get<IGetInventoryDataProps>(
    FUNCTION_NAME,
    {
      params,
    }
  );

  return data;
};
