import { monserratRegular, robotoRegular, scarpaGray500, scarpaGray800, white } from '@habitech/shared';
import styled from 'styled-components';

export const RulesFormWrapper = styled.div`
  width: 100%;
  height: 100vh;
  background: ${scarpaGray500}66;
  position: fixed;
  top: 0px;
  right: 0px;
  z-index: 201;
`;

export const RulesFormContainer = styled.div`
  width: 409px;
  height: 100vh;
  position: absolute;
  top: 0px;
  right: 0px;
  background: ${white};
  padding: 24px 40px;
  .spaces {
    margin: 31px 0px;
  }
`;

export const RuleCloseForm = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  img {
    cursor: pointer;
  }
`;

export const RuleDescription = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 16px 0px;
  .title {
    font-family: ${monserratRegular};
    font-weight: 600;
    font-size: 24px;
    color: ${scarpaGray800};
  }
  .description {
    font-family: ${robotoRegular};
    font-size: 12px;
    color: ${scarpaGray500};
    font-weight: 400;
    line-height: 20px;
    margin-bottom: 16px;
    .bold {
      font-weight: 600;
    }
  }
`;

export const PlacesContainerRule = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  .place-item {
    display: flex;
    padding: 4px 8px;
    border: 1px solid rgb(50, 193, 255);
    border-radius: 100px;
    -webkit-box-align: center;
    align-items: center;
    background: rgb(235, 249, 255);
    font-family: ${robotoRegular};
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 12px;
    margin-right: 12px;
    color: rgb(37, 33, 41);
  }
`;
