import axios from 'axios';

export const orchestratorPricingClient = axios.create({
  baseURL: process.env.REACT_APP_ORCHESTRATOR_PRICING_URL,
  headers: {
    'x-api-key': process.env.REACT_APP_ORCHESTRATOR_PRICING_API_KEY as string,
    'Content-Type': 'application/json',
  },
});

export const convertEnvStepName = (envStepName: string) => {
  return envStepName.replace(/_/g, ' ').trim();
};

export const callOrchestratorPricing = async ({
  nid,
  status,
}: {
  nid: string;
  status?: string;
}) => {
  const eventName = convertEnvStepName(
    process.env.REACT_APP_ORCHESTRATOR_STEP as string
  );
  const { data } = await orchestratorPricingClient.post('orchestrator_update', {
    country: localStorage.getItem('country'),
    nid,
    status,
    event_name: eventName,
  });
  return data;
};
