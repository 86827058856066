import { Moment } from 'moment';
import dateToParams from '../../utils/dateToParams';
import zonesClient from '../../utils/zonesClient';

interface GetUtilityZoneProps {
  medianZoneId: number;
  date: Moment;
}

export const FUNCTION_NAME = process.env.REACT_APP_GET_UTILITY_ZONE || '';
export const getUtilityZone = async (
  medianZoneId: number,
  date: [Date, Date?]
) => {
  const dateParams = dateToParams(date[0], date[1]);

  const { data } = await zonesClient.get<GetUtilityZoneProps>(FUNCTION_NAME, {
    params: {
      median_zone_id: medianZoneId,
      ...dateParams,
    },
  });

  return data;
};
