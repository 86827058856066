import { useEffect, useMemo, useState } from 'react';
import { IListOptions } from '@habitech/call-to-action/src/lib/dropdown-button/models/dropdown-button.model';
import {
  getBigZones,
  getCities,
  getMetropolitanArea,
  getMiddleZones,
} from '../../../apis/selectZone/areas';
import capitalize from '../../../utils/capitalize';

interface RawItems {
  id: number;
  label: string;
}

interface CityOption extends IListOptions {
  location: {
    latitude: number;
    longitude: number;
  };
}

export interface SelectZoneFormValues {
  ma?: IListOptions;
  city?: CityOption;
  bz?: IListOptions;
  zone?: IListOptions;
}

const mapToSelectList = (item: RawItems) => ({
  label: capitalize(item.label),
  value: item.id,
});

const useZoneController = () => {
  const [maSelects, setMaSelects] = useState<IListOptions[]>([]);
  const [maId, setMaId] = useState<number>();
  const [citySelects, setCitySelects] = useState<CityOption[]>([]);
  const [cityId, setCityId] = useState<number>();
  const [bzSelects, setBzSelects] = useState<IListOptions[]>([]);
  const [bzId, setBzId] = useState<number>();
  const [zoneSelects, setZoneSelects] = useState<IListOptions[]>([]);
  const [zoneId, setZoneId] = useState<number>();

  const formValues = useMemo<SelectZoneFormValues>(() => {
    const ma = maSelects.find(({ value }) => value === maId);
    const city = ma && citySelects.find(({ value }) => value === cityId);
    const bz = city && bzSelects.find(({ value }) => value === bzId);
    const zone = bz && zoneSelects.find(({ value }) => value === zoneId);

    return {
      ma,
      city,
      bz,
      zone,
    };
  }, [
    bzId,
    bzSelects,
    cityId,
    citySelects,
    maId,
    maSelects,
    zoneId,
    zoneSelects,
  ]);

  useEffect(() => {
    if (!formValues.city) setBzSelects([]);
    if (!formValues.bz) setZoneSelects([]);
  }, [formValues.city, formValues.bz]);

  useEffect(() => {
    getMetropolitanArea().then(({ data }) => {
      const listData = data.map(mapToSelectList);

      setMaSelects(listData);
    });
  }, []);

  useEffect(() => {
    if (maId) {
      getCities(maId).then(({ data }) => {
        const listData = data.map<CityOption>((item) => ({
          ...mapToSelectList(item),
          location: {
            latitude: item.latitude,
            longitude: item.longitude,
          },
        }));

        setCitySelects(listData);
      });
    }
  }, [maId]);

  useEffect(() => {
    if (cityId) {
      getBigZones(cityId).then(({ data }) => {
        const listData = data.map(mapToSelectList);

        setBzSelects(listData);
      });
    }
  }, [cityId]);

  useEffect(() => {
    if (bzId) {
      getMiddleZones(bzId).then(({ data }) => {
        const listData = data.map(mapToSelectList);

        setZoneSelects(listData);
      });
    }
  }, [bzId]);

  return {
    setMaId,
    setCityId,
    setBzId,
    setZoneId,
    formValues,
    formSelects: {
      ma: maSelects,
      city: citySelects,
      bz: bzSelects,
      zone: zoneSelects,
    },
  };
};

export default useZoneController;
