import {
  disabledColor,
  robotoRegular,
  scarpaGray400,
  scarpaGray50,
  scarpaGray500,
  scarpaGray800,
  skyBlue500,
  skyBlue700,
  turquoise700,
  white,
} from '@habitech/shared';
import styled from 'styled-components';

export const RulesContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-top: 16px;
  position: relative;
`;

export const RulesItem = styled.div`
  transition: all 0.5s ease-out;
  display: flex;
  align-items: flex-start;
  background: ${white};
  padding: 16px 24px;
  border: 0.5px solid #d3d3d4;
  width: 616px;
  color: ${scarpaGray800};
  &.disabled {
    background: ${scarpaGray50};
    color: ${scarpaGray400};
    .description {
      color: ${scarpaGray400};
    }
    .separator-description {
      color: ${scarpaGray500} !important;
    }
    .normal-1 {
      font-weight: 600;
    }
    .normal-2 {
      font-weight: 600;
    }
  }
  &.first {
    border-radius: 8px 8px 0px 0px;
  }
  &.middle {
    border-radius: none;
    border-top: none;
  }
  &.last {
    border-radius: 0px 0px 8px 8px;
  }
  .info {
    margin-right: 8px;
    cursor: pointer;
  }
`;

export const RuleInformation = styled.div`
  display: flex;
  justify-content: space-between;
  width: inherit;
`;

export const RuleTitle = styled.div`
  display: flex;
  flex-direction: column;
  .title {
    display: flex;
    align-items: center;
    font-family: ${robotoRegular};
    font-size: 16px;
    font-weight: 500;
  }
  .description {
    margin-top: 2px;
    font-family: ${robotoRegular};
    font-size: 12px;
    font-weight: 400;
    color: ${turquoise700};
    .strong {
      font-weight: 600;
    }
    .separator-description {
      color: ${skyBlue700};
      margin: 0px 8px;
    }
  }
`;

export const RuleType = styled.div`
  .edit {
    display: flex;
    align-items: center;
    font-family: ${robotoRegular};
    font-size: 12px;
    font-weight: 400;
    color: ${skyBlue700};
    cursor: pointer;
    img {
      margin-left: 8px;
    }
  }
  [data-id='toggle'] {
    height: 24px;
    width: 46.5px;
    min-width: 46.5px;
    input:checked + label::before {
      background: ${skyBlue500};
      outline: none;
    }
    input:checked + label::after {
      margin-left: 26px;
    }
    input + label::before {
      background: ${disabledColor};
      outline: none;
      border: none;
    }
    input + label {
      height: 24px;
      width: 46.5px;
      background: none;
    }
    label::before {
      width: 48px;
      height: 24px;
    }
    label::after {
      width: 22px;
      height: 22px;
      margin-left: 3px;
    }
  }
`;
