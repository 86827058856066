import { ColumnDescription, ContainerCardDiscount } from './styles';
import calculate from '../../../../assets/images/calculate.svg';

interface ITotalDiscount {
  data: any;
}

const TotalDiscount: React.FC<ITotalDiscount> = ({ data }) => {
  return (
    <>
      <ColumnDescription>
        <div className="subtitle">Total de descuentos para el NID</div>
      </ColumnDescription>
      <ContainerCardDiscount>
        <div className="container-flex">
          <img src={calculate} alt="home_money" className="image" />
          <div>
            <div className="title">Sumatoria de los descuentos</div>
            <div className="subtitle">
              {data?.total_discount?.base} % de descuento base +{' '}
              {data?.total_discount?.global} puntos de global
            </div>
          </div>
          <div className="border-blue" />
          <div>
            <div className="title ">Total</div>
            <div className="subtitle ">
              {data?.total_discount?.total} % de descuento
            </div>
          </div>
        </div>
      </ContainerCardDiscount>
    </>
  );
};

export default TotalDiscount;
