import LayoutHeaderClean from '../../components/LayoutClean';
import LayoutSidebar from '../../components/LayoutSidebar';
import { ContainerPage, LayoutWrapper } from '../../components/Layout/style';
import Approver from './Approver';

const ApproverCo = () => {
  return (
    <LayoutWrapper>
      <LayoutSidebar />
      <ContainerPage>
        <LayoutHeaderClean />
        <Approver />
      </ContainerPage>
    </LayoutWrapper>
  );
};

export default ApproverCo;
