import {
  electricPurple300,
  skyBlue100,
  skyBlue900,
  white,
} from '@habitech/shared';

export const optionsHistogram = {
  responsive: true,
  maintainAspectRatio: false,
  layout: {
    padding: {
      left: -100,
    },
  },
  scales: {
    x: {
      grid: {
        color: function () {
          return white;
        },
      },
    },
  },
  plugins: {
    legend: {
      display: false,
    },
    title: {
      display: false,
    },
  },
};

export const labelsHistogram = [
  '0',
  '10',
  '20',
  '30',
  '40',
  '50',
  '60',
  '70',
  '80',
  '90',
  '100',
  '110',
  '120',
  '130',
  '140',
  '150',
  '160',
  '170',
  '180',
  '190',
  '200',
  '210',
  '220',
  '230',
  '240',
  '250',
  '260',
  '270',
  '280',
  '290',
  '300',
];

export const histogramData = (toSell?: number[], selled?: number[]) => {
  const data = {
    labels: labelsHistogram,
    datasets: [
      {
        label: 'Vendidos',
        data: selled || [],
        backgroundColor: skyBlue100,
        stack: 'Stack 0',
        datalabels: {
          color: function (context: any) {
            let index = context.dataIndex;
            let value = context.dataset.data[index];
            return value >= 5 ? skyBlue900 : 'transparent';
          },
          font: {
            weight: 600,
            family: 'Montserrat',
          },
        },
      },
      {
        label: 'Por vender',
        data: toSell || [],
        backgroundColor: electricPurple300,
        stack: 'Stack 0',
        datalabels: {
          color: function (context: any) {
            let index = context.dataIndex;
            let value = context.dataset.data[index];
            return value >= 5 ? white : 'transparent';
          },
          font: {
            weight: 600,
            family: 'Montserrat',
          },
        },
      },
    ],
  };
  return data;
};

export const histogramDataZm = (toSell?: number[], selled?: number[]) => {
  const data = {
    labels: labelsHistogram,
    datasets: [
      {
        label: 'Vendidos',
        data: selled || [],
        backgroundColor: skyBlue100,
        stack: 'Stack 0',
        datalabels: {
          color: function (context: any) {
            const index = context.dataIndex;
            const value = context.dataset.data[index];
            return value >= 1 ? skyBlue900 : '';
          },
          font: {
            weight: 600,
            family: 'Montserrat',
          },
        },
      },
      {
        label: 'Por vender',
        data: toSell || [],
        backgroundColor: electricPurple300,
        stack: 'Stack 0',
        datalabels: {
          color: function (context: any) {
            const index = context.dataIndex;
            const value = context.dataset.data[index];
            return value >= 1 ? white : '';
          },
          font: {
            weight: 600,
            family: 'Montserrat',
          },
        },
      },
    ],
  };
  return data;
};
