import {
  scarpaGray500,
  scarpaGray700,
  scarpaGray100,
  scarpaGray800,
} from '@habitech/shared';
import styled from 'styled-components';
import { RejectionIndicator, WrapperRejections } from './model';

export const WrapperRejectionReasonsGraphic = styled.div<WrapperRejections>`
  display: grid;
  margin-bottom: 40px;
`;

export const ReasonsIndicatorsContainer = styled.div`
  display: grid;
  grid-template-columns: 161px auto;
  gap: 20px;
  margin-bottom: 48px;
`;

export const ReasonsIndicator = styled.div<RejectionIndicator>`
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 8px;
  border-right: ${(props) => (props.first ? `1px solid ${scarpaGray100}` : '')};

  .title {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: ${scarpaGray700};
  }

  .indicator {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: ${scarpaGray500};
  }
  .text {
    display: grid;
  }
`;
export const GraphicContainer = styled.div`
  position: relative;
  width: 100%;
  display: grid;
  align-items: center;
  justify-content: center;
  .graphic {
    width: 370px;
  }
`;

export const TextMiddle = styled.div`
  width: 130px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: ${scarpaGray500};
  text-align: center;
  top: 42%;
  left: 37%;
  position: absolute;
  .value {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    color: ${scarpaGray700};
  }
`;

export const NoResultsContainer = styled.div`
  display: grid;
  justify-content: center;
  align-items: center;
  padding-top: 80px;
  .icon {
    margin-bottom: 16px;
    justify-self: center;
  }
`;

export const DescriptionOne = styled.div`
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: ${scarpaGray800};
  text-align: center;
  margin-bottom: 76px;
`;

export const DescriptionTwo = styled.div`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: ${scarpaGray800};
  text-align: center;
`;
