import CalendarIconPurple from '../../assets/calendar-home-purple.svg';
import { Modal, TablePagination } from '@mui/material';
import { BadgeDays, ContainerModal } from './styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';

import { InventoryTimeProps } from './model';
import { useState } from 'react';

const ModalInventoryTime: React.FC<InventoryTimeProps> = ({
  properties,
  handleClose,
}) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(6);
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  return (
    <Modal
      open={true}
      onClose={handleClose}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
    >
      <ContainerModal>
        <div className="title">
          <img src={CalendarIconPurple} className="icon" />
          <span>Inmuebles con 150 días en inventario</span>
        </div>
        <CloseRoundedIcon className="icon-close" onClick={handleClose} />
        <div className="table-content">
          <TableContainer component={Paper} sx={{ maxWidth: 633 }}>
            <Table sx={{ maxWidth: 633 }}>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ width: '20%' }}>NID</TableCell>
                  <TableCell align="left" sx={{ width: '60%' }}>
                    Nombre del conjunto
                  </TableCell>
                  <TableCell align="center" sx={{ width: '20%' }}>
                    Días en inventario
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {properties
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => (
                    <TableRow
                      key={row.nid}
                      sx={{
                        '&:last-child td, &:last-child th': { border: 0 },
                      }}
                    >
                      <TableCell component="th" scope="row" className="nid-row">
                        {row.nid}
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        className="row-name"
                      >
                        {row.conjunto_edificio}
                      </TableCell>
                      <TableCell align="center">
                        <BadgeDays>{row.days_in_inventory}</BadgeDays>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
              <TablePagination
                rowsPerPageOptions={[6]}
                component="div"
                count={properties.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Table>
          </TableContainer>
        </div>
      </ContainerModal>
    </Modal>
  );
};

export default ModalInventoryTime;
