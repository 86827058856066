import HomeMoney from '../../../../../assets/icons/HomeMoney.svg';
import HomeStart from '../../../../../assets/icons/HomeStart.svg';
import HomePoint from '../../../../../assets/icons/HomePoint.svg';
import HomeI from '../../../../../assets/icons/HomeI.svg';
import HomeKey from '../../../../../assets/icons/HomeKey.svg';
import { formatMoney } from '../../../../../utils/formatDecimal';

interface IRulesDetail {
  data?: any;
}

const RulesDetail: React.FC<IRulesDetail> = ({ data }) => {
  const rulesInfo = [
    {
      id: 1,
      image: HomeMoney,
      title: 'Precio mínimo',
      value: formatMoney(data?.ask_price_top) || '',
    },
    {
      id: 2,
      image: HomeStart,
      title: 'Bucket de pricing',
      value: data?.buckets?.toString().replaceAll(',', ', '),
    },
    {
      id: 3,
      image: HomePoint,
      title: 'Estado de la zona mediana',
      value: data?.status,
    },
    {
      id: 4,
      image: HomeI,
      title: 'Casas sueltas',
      value: data?.single_house ? 'Si' : 'No',
    },
    {
      id: 5,
      image: HomeKey,
      title: 'Inmobiliaria',
      value: '(por definir)',
    },
  ];

  return (
    <>
      {rulesInfo.map((item) => (
        <div className="card-info" key={item.id}>
          <img src={item.image} alt="area_img" className="image" />
          <div className="info-text">
            <span className="title">{item.title}</span>
            <span className="subtitle ">{item.value}</span>
          </div>
        </div>
      ))}
    </>
  );
};

export default RulesDetail;
