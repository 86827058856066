import ContainerDiscountEngine from './components/ContainerDiscountEngine/ContainerDiscountEngine';
import withRoutePage, { RoutePage } from '../../utils/withRoutePage';
import TemplateClean from '../../components/TemplateClean/TemplateClean';
import { ContainerEngine } from './style';

const DiscountEngine: RoutePage = () => {
  return (
    <TemplateClean>
      <ContainerEngine>
        <ContainerDiscountEngine />
      </ContainerEngine>
    </TemplateClean>
  );
};

DiscountEngine.requiresAuth = true;

export default withRoutePage(DiscountEngine);
