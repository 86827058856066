import styled from 'styled-components';
import {
  scarpaGray800,
  robotoRegular,
  white,
  electricPurple500,
} from '@habitech/shared';

export const ColumnDescription = styled.div`
  grid-column: 1/3;
  font-family: ${robotoRegular};
  .size {
    width: 130px;
  }
  .subtitle {
    width: 120px;
    font-family: ${robotoRegular};
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: ${scarpaGray800};
    margin-left: 32px;
  }
`;
export const ContainerOne = styled.div`
  grid-column: 3/5;
`;
export const ContainerTwo = styled.div`
  grid-column: 5/7;
`;
export const ContainerThree = styled.div`
  grid-column: 7/9;
`;
export const ContainerButtonSend = styled.div`
  padding-top: 32px;
  grid-column: 6/9;
  button {
    border-color: ${electricPurple500};
    width: 258px;
    height: 48px;
    background: ${electricPurple500};
    border-radius: 6px;
    justify-content: center;
    display: flex;
    align-items: center;
    cursor: pointer;
    .text {
      font-family: ${robotoRegular};
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      display: flex;
      align-items: center;
      text-align: center;
      color: ${white};
    }
  }
`;

interface ITabContainer {
  firstSelected: boolean;
}

export const TabContainer = styled.div<ITabContainer>`
  grid-column: 3/10;
  display: flex;
  .size-one {
    width: 163px;
    height: 48px;
  }
  .button-selected {
    width: 163px;
    height: 40px;
    background: ${electricPurple500};
    border-radius: ${(props) =>
      props.firstSelected ? '6px 0px 0px 6px' : '0px 6px 6px 0px'};
    border-color: ${electricPurple500};
    text-align: center;
    padding: 0;
    border-width: 0;
    cursor: pointer;
    .text {
      margin: 0 16px;
      justify-content: center;
      font-family: ${robotoRegular};
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      display: flex;
      align-items: center;
      color: ${white};
    }
  }
  .button-not-selected {
    width: 163px;
    height: 40px;
    background: ${white};
    border-radius: ${(props) =>
      props.firstSelected ? '0px 6px 6px 0px' : '6px 0px 0px 6px'};
    border: 1px solid ${scarpaGray800};
    text-align: center;
    padding: 0;
    cursor: pointer;
    .text {
      margin: 0 16px;
      justify-content: center;
      font-family: ${robotoRegular};
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      display: flex;
      align-items: center;
      color: ${scarpaGray800};
    }
  }
`;
