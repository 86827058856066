import { useState, useEffect } from 'react';
import {
  TitleAndSubtitle,
  TitleZone,
} from '../../../FunnelConvertion/components/AntiFunnelGraph/styles';
import ElasticityIndicatorsWrapper, {
  IndicatorItem,
  IndicatorsContainer,
  ZoneInfoContainer,
} from './styles';
import LocationIcon from '../../../FunnelConvertion/components/AntiFunnelGraph/assets/location.svg';
import { useAppSelector } from '../../../../redux/hooks';
import capitalize from '../../../../utils/capitalize';
import { TIndicators } from '../../model';
import { getElasticityIndicators } from '../../../../apis/elasticity/elasticity';
import { LoaderContainer } from '../../../Pricing/components/LineGraphicMape/styles';
import HabiLoader from '../../../../components/HabiLoader/HabiLoader';

const ElasticityIndicators = () => {
  const zoneState = useAppSelector((state) => state.zoneStore);
  const [indicators, setIndicators] = useState<TIndicators>();
  const [loaded, setLoaded] = useState(false);

  const getElasticityIndicatorsFunction = () => {
    const params = { median_zone_id: zoneState.zone.middleZone.id };
    getElasticityIndicators(params).then((response) => {
      if (response.success) {
        setIndicators(response.data);
        setLoaded(true);
      }
    });
  };

  useEffect(() => {
    getElasticityIndicatorsFunction();
  }, []);

  if (!loaded) {
    return (
      <LoaderContainer>
        <HabiLoader size="small" />
      </LoaderContainer>
    );
  }

  return (
    <ElasticityIndicatorsWrapper>
      <ZoneInfoContainer>
        <TitleZone>
          <img src={LocationIcon} alt="pin del mapa" />
          <TitleAndSubtitle>
            <div className="title">
              {capitalize(zoneState.zone.middleZone.label.toLowerCase())}
            </div>
            <div className="subtitle">Zona mediana</div>
          </TitleAndSubtitle>
        </TitleZone>
      </ZoneInfoContainer>
      <IndicatorsContainer>
        <IndicatorItem className="border-right">
          <span className="title">Leads totales</span>
          <span className="discount">{indicators?.leads_totales}</span>
        </IndicatorItem>
        <IndicatorItem className="border-right">
          <span className="title">Leads mensuales</span>
          <span className="discount">{indicators?.leads_mensuales}</span>
        </IndicatorItem>
        <IndicatorItem className="border-right">
          <span className="title">Cierres</span>
          <span className="discount">{indicators?.cierres}</span>
        </IndicatorItem>
        <IndicatorItem className="border-right">
          <span className="title">Objetivo mensual</span>
          <span className="discount">{indicators?.objetivo_mensual}</span>
        </IndicatorItem>
        <IndicatorItem>
          <span className="title">Potencial de cierres</span>
          <span className="discount">{indicators?.potencial_de_cierres}</span>
        </IndicatorItem>
      </IndicatorsContainer>
      <IndicatorsContainer>
        <IndicatorItem className="border-right">
          <span className="title">Descuento mínimo objetivo</span>
          <span className="discount">
            {indicators?.descuento_mínimo_objetivo}
          </span>
        </IndicatorItem>
        <IndicatorItem className="border-right">
          <span className="title">Descuento máximo objetivo</span>
          <span className="discount">
            {indicators?.descuento_máximo_objetivo}
          </span>
        </IndicatorItem>
        <IndicatorItem className="border-right">
          <span className="title">Promedio descuento</span>
          <span className="discount">{indicators?.promedio_descuento}</span>
        </IndicatorItem>
        <IndicatorItem className="border-right">
          <span className="title">Elasticidad 50 Norm</span>
          <span className="discount">{indicators?.elasticidad_50_norm}</span>
        </IndicatorItem>
        <IndicatorItem>
          <span className="title">Elasticidad 50</span>
          <span className="discount">{indicators?.elasticidad_50}</span>
        </IndicatorItem>
      </IndicatorsContainer>
    </ElasticityIndicatorsWrapper>
  );
};

export default ElasticityIndicators;
