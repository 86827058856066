import { useEffect, useState } from 'react';

import ReturnLink from '../../components/ReturnLink';
import withRoutePage, { RoutePage } from '../../utils/withRoutePage';

import {
  ReturnWrapper,
  SectionContainer,
  SubTitleSection,
  TitleSection,
} from '../MarketingLeads/styles';
import { SubSectionTitle } from '../Utility/style';
import MarketShareWrapper from './styles';

import homeStar from '../../assets/icons/home-star.svg';
import { useAppSelector } from '../../redux/hooks';
import getZoneInfo from '../ZoneDetail/services/zone.services';
import ActualDiscount from '../Pricing/components/ActualDiscount/ActualDiscount';
import ModalDiscount from '../Pricing/components/ModalDiscount/ModalDiscount';
import BehaviorMarketShare from './components/BehaviorMarketShare/BehaviorMarketShare';

const MarketShare: RoutePage = () => {
  const zoneState = useAppSelector((state) => state.zoneStore);

  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [zonePricing, setZonePricing] = useState({
    pricing: {
      apartment_discount: 0,
      house_discount: 0,
    },
  });

  const { middleZone } = zoneState.zone;

  useEffect(() => {
    getZoneInfo(middleZone.id).then((response) => {
      if (response.success) {
        setZonePricing((pricing) => ({
          ...pricing,
          pricing: response.data as any,
        }));
      }
    });
  }, [middleZone.id]);

  return (
    <MarketShareWrapper>
      <ReturnWrapper>
        <ReturnLink
          text="Regresar a análisis de zona"
          link="/portal/analisis-de-zona"
        />
      </ReturnWrapper>

      <TitleSection>Market Share</TitleSection>

      <SubTitleSection>Accionable disponible</SubTitleSection>

      <ActualDiscount
        setModalOpen={() => setModalOpen(!modalOpen)}
        pricing={zonePricing.pricing}
      />

      <SubTitleSection>Análisis de la zona mediana</SubTitleSection>

      <SectionContainer>
        <SubSectionTitle style={{ marginBottom: '28px' }}>
          <img src={homeStar} alt="" />
          Comportamiento del Market Share
        </SubSectionTitle>
        <BehaviorMarketShare />
      </SectionContainer>

      {modalOpen && (
        <ModalDiscount
          setModalOpen={() => setModalOpen(!modalOpen)}
          zonePricing={zonePricing as any}
          setZonePricing={setZonePricing}
        />
      )}
    </MarketShareWrapper>
  );
};

MarketShare.requiresAuth = true;

export default withRoutePage(MarketShare);
