import TabContainer from './style';

interface ITap {
  firstSelected: boolean;
  setFirstSelected: any;
  titleOne: string;
  titleTwo: string;
}

const GlobalTab: React.FC<ITap> = ({
  firstSelected,
  setFirstSelected,
  titleOne,
  titleTwo,
}) => {
  const handleSelectedFirst = () => {
    setFirstSelected(true);
  };

  const handleSelectedSecond = () => {
    setFirstSelected(false);
  };

  return (
    <TabContainer firstSelected={firstSelected}>
      <button
        data-testid="first-button"
        className={`${
          firstSelected ? 'button-selected' : 'button-not-selected'
        } `}
        onClick={handleSelectedFirst}
      >
        <span className="text">{titleOne}</span>
      </button>
      <button
        data-testid="second-button"
        className={`${
          firstSelected ? 'button-not-selected' : 'button-selected'
        }`}
        onClick={handleSelectedSecond}
      >
        <span className="text">{titleTwo}</span>
      </button>
    </TabContainer>
  );
};

export default GlobalTab;
