import { useSelector } from 'react-redux';
import { ContainerComment } from '../DetailsRoomsBathrooms/styles';
import InverterTable from '../InverterTable/InverterTable';
import { dictionaryTableCo, dictionaryTableMx, organizeData } from './utils';

const DetailHistoricalBusinessInformation = () => {
  const historicalBusinesses = useSelector(
    (state: any) => state.cardsStore.historicalBusinesses
  );
  const historicalDeals = useSelector(
    (state: any) => state.cardsStore.historicalDeals
  );

  const dealName = useSelector(
    (state: any) => state.searchStore.approval.deal_name
  );

  const country = localStorage.getItem('country');
  const dictionaryTable =
    country === 'CO' ? dictionaryTableCo : dictionaryTableMx;

  const handleOrderData = organizeData(
    historicalBusinesses?.table_info,
    dictionaryTable,
    dealName
  );

  const handleCreateComment = () => {
    return (
      <span>
        Se tienen {historicalBusinesses?.sold} inmuebles vendidos,{' '}
        {historicalBusinesses?.published} publicados y{' '}
        {historicalBusinesses?.no_published} no publicados en radio de 500 mts
        (puntaje de negocios históricos{' '}
        {historicalDeals?.negocios_historico || '-'})
      </span>
    );
  };

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {historicalBusinesses && historicalBusinesses.table_info?.length > 0 ? (
        <>
          <ContainerComment>
            <div className="title" data-testid="comentario">
              Comentario:{' '}
            </div>
            <div className="subtitle">{handleCreateComment()}</div>
          </ContainerComment>
          <InverterTable typeOne={false} data={handleOrderData} />
        </>
      ) : (
        <ContainerComment>
          <div className="title">Comentario: </div>
          <div className="subtitle">No se encontraron datos</div>
        </ContainerComment>
      )}
    </>
  );
};

export default DetailHistoricalBusinessInformation;
