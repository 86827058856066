import styled, { css } from 'styled-components';

const baseStyles = css`
  display: flex;
  width: 76px;
  height: 22px;
  padding: 4px 9px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;
  border-radius: 6px;
`;

const enabledStyles = css`
  border: 1px solid var(--behavior-neutral-colors-scarpa-gray-400, #979499);
  background: var(--behavior-neutral-colors-scarpa-gray-00, #fff);
`;

const disabledStyles = css`
  border: 1px dashed var(--behavior-neutral-colors-scarpa-gray-400, #979499);
  background: var(--behavior-neutral-colors-scarpa-gray-50, #f3f3f3);
  cursor: not-allowed;
`;

const StyledInput = styled.input`
  ${baseStyles};
  display: flex;
  text-align: center;
  ${({ disabled }) => (disabled ? disabledStyles : enabledStyles)};
`;

export default StyledInput;
