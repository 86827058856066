import {
  electricPurple500,
  robotoRegular,
  scarpaGray800,
  white,
} from '@habitech/shared';
import styled from 'styled-components';
import { gray6, separator } from '../../../../styles/stylesConstants';

const TabContainer = styled.div`
  grid-column: 3/10;
  display: flex;
  align-items: center;
`;

export const TabItem = styled.div`
  min-width: 180px;
  background: ${white};
  border: 1px solid ${gray6};
  padding: 14px 16px;
  color: ${scarpaGray800};
  font-family: ${robotoRegular};
  font-weight: 400;
  font-size: 16px;
  text-align: center;
  cursor: pointer;
  &.first {
    border-right: none;
    border-radius: 6px 0 0 6px;
  }
  &.last {
    border-left: none;
    border-radius: 0 6px 6px 0;
  }
  &.selected {
    background: ${electricPurple500};
    color: ${white};
    border: 1px solid ${electricPurple500};
  }
`;

export const Divisor = styled.div`
  grid-column: 1/11;
  display: flex;
  align-items: center;
  border-top: 1px solid ${separator};
  margin: 28px 0;
`;

export default TabContainer;
