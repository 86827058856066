import { useState, useEffect } from 'react';
import { Modal } from '@mui/material';
import { Button, DropdownButton } from '@habitech/call-to-action';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import {
  ContainerModal,
  ContainerTitle,
  ContainerDescription,
  ContainerLastChange,
  ShowComment,
  ContainerNewChange,
  ContainerSelects,
  ContainerButtons,
  ContainerTotal,
} from './styles';
import Color from '../../../../assets/icons/color.svg';
import Buildings from '../../../../assets/icons/buildings.svg';
import Up from '../../../../assets/icons/Up.svg';
import Down from '../../../../assets/icons/Down.svg';
import TextArea from '../../../../components/textArea/TextArea';
import { reasonList, optionsQuotas } from './ModalEditList.Utils';
import { putList } from '../../../../apis/politics/politics';
import { useAppSelector, useAppDispatch } from '../../../../redux/hooks';
import { alertStoreActions } from '../../../../redux/reducers/alertReducer';

interface IModalEditList {
  isModalOpen: boolean;
  setIsModalOpen: Function;
  idResidential?: any;
  zona?: any;
  history?: any;
  updateData: Function;
}
interface IOpt {
  [value: string]: string;
}
interface AlertProps {
  type: 'success' | 'warning' | 'error';
  text: string;
  showAlert: boolean;
}

const ModalEditList: React.FC<IModalEditList> = ({
  isModalOpen = false,
  setIsModalOpen,
  idResidential = {
    lote_id: '',
    lote_name: '',
  },
  zona = {},
  history = {
    initial_list: '',
    final_list: '',
    agent: '',
    reason: '',
    note: '',
    date: '',
  },
  updateData,
}) => {
  const [showComment, setShowComment] = useState(false);
  const [isIncompleteData, setIsIncompleteData] = useState(true);
  const dispatch = useAppDispatch();
  const [dataToSend, setDataToSend] = useState({
    current_list: '',
    reason: '',
    note: '',
  });

  const OPT: IOpt = {
    B: 'Black',
    G: 'Grey',
    BC: 'Buen conjunto',
    'Sin Lista': 'Sin lista',
    W: 'White',
  };
  const userState = useAppSelector((state) => state.userStore) || '';

  const handleUpdateList = (value: any) => {
    setDataToSend({ ...dataToSend, current_list: value });
  };

  const handleUpdateComment = (value: any) => {
    setDataToSend({ ...dataToSend, note: value });
  };

  const handleUpdateReason = (value: any) => {
    setDataToSend({ ...dataToSend, reason: value });
  };

  useEffect(() => {
    if (
      dataToSend.current_list !== '' &&
      dataToSend.reason !== '' &&
      dataToSend.note !== ''
    ) {
      setIsIncompleteData(false);
    } else {
      setIsIncompleteData(true);
    }
  }, [dataToSend]);

  const handleCloseModal = () => {
    setDataToSend({
      current_list: '',
      reason: '',
      note: '',
    });
    setIsModalOpen(false);
  };

  const handleUpdateData = async () => {
    const data = {
      zone_id: zona.zm_id,
      lote_name: idResidential?.lote_name,
      country: 'CO',
      user_email: userState?.user?.email,
      current_list: dataToSend.current_list,
      reason: dataToSend.reason,
      note: dataToSend.note,
    };

    try {
      await putList(data).then((response) => {
        if (response?.success) {
          const alertData: AlertProps = {
            type: 'success',
            text: 'Se han cambiado las listas en la zona con éxito',
            showAlert: true,
          };
          dispatch(alertStoreActions.updateAlert(alertData));
        } else {
          const alertData: AlertProps = {
            type: 'error',
            text: 'Ocurrió un error al guardar los datos, Intente más tarde',
            showAlert: true,
          };
          dispatch(alertStoreActions.updateAlert(alertData));
        }
      });
      updateData();
      handleCloseModal();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Modal
      open={isModalOpen}
      onClose={() => setIsModalOpen}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
    >
      <ContainerTotal>
        <ContainerModal>
          <CloseRoundedIcon className="icon-close" onClick={handleCloseModal} />
          <ContainerTitle>
            <img src={Color} className="icon" alt="icon-edit" />
            <div className="text-title">Editar lista del conjunto</div>
          </ContainerTitle>
          <ContainerDescription>
            <div className="name-and-image">
              <img src={Buildings} className="icon" alt="icon-buildings" />
              <div className="text">
                <div className="title">{idResidential?.lote_name}</div>
                <div className="subtitle">
                  ID del conjunto {idResidential?.lote_id}
                </div>
              </div>
            </div>
            <div className="zone">
              <span className="text">{zona?.city}</span>
            </div>
          </ContainerDescription>
          <ContainerLastChange>
            <div className="text-title">Último cambio de lista realizado</div>
            <div className="card-info">
              <div className="first-row">
                <div className="date-info">{history.date}</div>
                <div className="previous-list">
                  <div className="text">Lista anterior</div>
                  <div className={`list   ${history.initial_list}`}>
                    {OPT[history.initial_list]}
                  </div>
                  <div />
                </div>
              </div>
              <div className="second-row">
                <div className="text">Lista asignada</div>
                <div className={`list   ${history.final_list}`}>
                  {OPT[history.final_list]}
                </div>
              </div>
              <div className="third-row">
                <div className="first-column">
                  <div className="title-change">Motivo del cambio</div>
                  <div className="reason">{history.reason}</div>
                </div>
                <div className="second-column">
                  <div className="name">
                    <div>Guru responsable</div>
                    <div className="name-title">{history.agent}</div>
                  </div>
                  <ShowComment
                    className="show-comment"
                    onClick={() => setShowComment(!showComment)}
                  >
                    {showComment ? (
                      <>
                        <span>Ocultar comentario </span>
                        <img src={Up} className="icon-up-down" alt="icon-up" />
                      </>
                    ) : (
                      <>
                        <span>Ver comentario </span>
                        <img
                          src={Down}
                          className="icon-up-down"
                          alt="icon-down"
                        />
                      </>
                    )}
                  </ShowComment>
                </div>
              </div>
              {showComment && (
                <div className="comment-row">
                  <div className="title-commen">
                    comentario del gurú para el cambio de lista
                  </div>
                  <div className="comment">{history.note}</div>
                </div>
              )}
            </div>
          </ContainerLastChange>
          <ContainerNewChange>
            <div className="title-new-change">
              Nuevo cambio de lista a la que pertenece el conjunto
            </div>
            <div className="subtitle">*Todos los campos son obligatorios</div>
            <ContainerSelects>
              <div className="first-select">
                <div className="select">
                  <DropdownButton
                    dataId="a"
                    size="fluid"
                    variant="white"
                    placeholder="Selecciona"
                    listOptions={optionsQuotas}
                    label=" * Lista a asignar"
                    setValue={(value) => handleUpdateList(value)}
                  />
                </div>
              </div>
              <div className="second-select">
                <div className="select">
                  <DropdownButton
                    dataId="b"
                    size="fluid"
                    listOptions={reasonList}
                    variant="white"
                    placeholder="Selecciona"
                    label=" * Selecciona un motivo para el cambio"
                    setValue={(value) => handleUpdateReason(value)}
                  />
                </div>
              </div>
            </ContainerSelects>
          </ContainerNewChange>
          <TextArea
            label=" * Comentario sobre el cambio de lista"
            maxLength={100}
            handleChange={handleUpdateComment}
            value={dataToSend.note}
            rows={3}
            cols={66}
            placeholder="Escribe la información adicional relacionada al cambio de lista"
            styles={{ padding: '1rem' }}
          />
          <ContainerButtons>
            <Button
              dataId="change-zone"
              variant="ghost"
              size="large"
              onClick={handleCloseModal}
            >
              Cancelar
            </Button>
            <Button
              disabled={isIncompleteData}
              dataId="change-zone"
              size="large"
              onClick={() => handleUpdateData()}
            >
              Guardar lista
            </Button>
          </ContainerButtons>
        </ContainerModal>
      </ContainerTotal>
    </Modal>
  );
};

export default ModalEditList;
