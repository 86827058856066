import DoughnutGraph from '../DoughnutGraph/DoughnutGraph';
import closeImage from '../../../../../../assets/icons/close.svg';
import {
  ButtonContainer,
  CloseButton,
  ContainerComparisonModal,
  ModalContent,
  ModalWrapper,
  RightAlignedIcon,
} from '../../styles';

interface ModalProps {
  onClose: () => void;
  onSave: () => void;
}

const CustomModal: React.FC<ModalProps> = ({ onClose, onSave }) => {
  const handleSave = () => {
    onSave();
    onClose();
  };

  return (
    <ModalWrapper>
      <ModalContent>
        <RightAlignedIcon>
          <CloseButton onClick={onClose}>
            <img src={closeImage} alt="close" className="close" />
          </CloseButton>
        </RightAlignedIcon>
        <div className="title">
          <div className="title-up">Proyección de negocios</div>
          <div className="title-bottom">
            Última modificación de reglas para estas zonas : 25 de Febrero de
            2023
          </div>
        </div>
        <ContainerComparisonModal>
          <DoughnutGraph />
          <DoughnutGraph title="Rango de referencia" />
        </ContainerComparisonModal>
        <ButtonContainer>
          <button onClick={onClose} className="back">
            Seguir editando
          </button>
          <button onClick={handleSave} className="save">
            Guardar cambios
          </button>
        </ButtonContainer>
      </ModalContent>
    </ModalWrapper>
  );
};

export default CustomModal;
