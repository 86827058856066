// Grays
export const gray2 = '#565555';
export const gray3 = '#857c81';
export const gray4 = '#b9b9b9';
export const gray5 = '#e0dddd';
export const gray6 = '#78747B';
export const backgroundLogin = '#EAEAEA';
export const borderGrayResume = '#DDDCFE';
export const borderGrayItem = '#B3B3B3';
export const GrayColorItem = '#666666';

// Colors
export const primaryGray = '#E3E4E6';
export const secondaryTextBlue = '#2D2D2D';
export const borderGray = 'rgba(35, 55, 65, 0.24)';
export const textBlue = '#233741';
export const textGray2 = '#808A8F';
export const textGray3 = '#78747B';
export const textBoldBlue = '#222132';
export const disabled = '#E4E7E8';
export const separator = '#D2D2D2';
export const borderSelect = '#949494';
export const disabled2 = '#f3f3f3';
export const ranking1 = '#F2F1FF';
export const ranking2 = '#FFF9E3';
export const ranking3 = '#FFF1DB';
export const secondaryAA = '#78747B';
export const borderButton = '#96939A';
export const backgroundSite = '#F3F3F3';
export const blueLink = '#3483FA';
export const footerBlue = '#F8F2Ff';
export const indicator = '#F5FCFF';
export const withoutList = '#FCF7EC';
export const withoutListBorder = '#E9B147';
export const withoutListColor = '#745823';
export const goodComplex = '#F1F8F5';
export const goodComplexBorder = '#4DAFBA';
export const goodComplexColor = '#1E464A';
export const whiteList = '#F5F9FF';
export const whiteListBorder = '#69A0F3';
export const whiteListColor = '#033176';
export const titleRule = '#131523';
export const ruleInfo = '#FAFAFA';
export const redWarning = '#E51717';

// Fonts
export const fontSize = '1.6rem';
export const subtitle = '#808a8f';
export const primary1 = '#6ca583';
export const accent1 = '#9b8dab';

// size
export const size = {
  mobile: '414px',
  tablet: '744px',
  tabletBigger: '1024px',
  desktop: '1280px',
  desktopBigger: '1440px',
  desktopMaxBigger: '2048px',
};

// grid
export const grid = {
  mobile: {
    gap: '2rem',
    columns: '4',
  },
  tablet: {
    gap: '2rem',
    columns: '8',
  },
  desktop: {
    gap: '2rem',
    columns: '12',
  },
};
export const darkPurple = '#5701B3';
export const purple = '#7C01FF';
export const purple100 = '#8600FF';
export const lightPurple = '#AAA7FC';
export const lightPurpleForBgs = 'rgba(170, 167, 252, 0.4)';
export const coral = '#FF5958';
export const aquamarine = '#00F2C3';
export const blue = '#32C1FF';
export const blue100 = '#3483FA';
export const lightBlue = '#B2E7FF';
export const green = '#C3FAAB';
export const lightGreen = '#E2FFD7';
export const white = '#FFFFFF';
export const black = '#2D2D2D';
export const darkGrey = '#B8B8B8';
export const lightGrey = '#F9FAFC';
export const grey = '#DDDDDD';
export const greyTabLight = '#F2E5F5';

// # NEW COLOR PALETTE #
export const purpleDark700 = '#44008C';
export const purpleSoft300 = '#EFE1FF';
export const purpleSoft200 = '#FBF8FF';
export const neutralGray700 = '#333333';
export const baseGray = '#666666';
export const expandedPurple = '#DDC0FB';
export const purpleMedium = '#450083';

// PURPLE PALETTE
export const electricPurple900 = '#190033';
export const electricPurple800 = '#320066';
export const electricPurple700 = '#4A0199';
export const electricPurple600 = '#6301CC';
export const electricPurple500 = '#7C01FF';
export const electricPurple400 = '#9634FF';
export const electricPurple300 = '#B067FF';
export const electricPurple200 = '#CB99FF';
export const electricPurple100 = '#E5CCFF';
export const electricPurple000 = '#F2E5F5';
export const electricPurple000T = '#F2E5F5BD';

// SKY BLUE PALETTE
export const skyBlue900 = '#0A2733';
export const skyBlue800 = '#144D66';
export const skyBlue700 = '#1E7499';
export const skyBlue600 = '#289ACC';
export const skyBlue500 = '#32C1FF';
export const skyBlue400 = '#5BCDFF';
export const skyBlue300 = '#84DAFF';
export const skyBlue200 = '#ADE6FF';
export const skyBlue100 = '#D6F3FF';
export const skyBlue000 = '#EBF9FF';

// BOGOTA BLUE PALETTE
export const bogotaBlue900 = '#242E33';
export const bogotaBlue800 = '#475C66';
export const bogotaBlue700 = '#6B8B99';
export const bogotaBlue600 = '#8EB9CC';
export const bogotaBlue500 = '#B2E7FF';
export const bogotaBlue400 = '#C1ECFF';
export const bogotaBlue300 = '#D1F1FF';
export const bogotaBlue200 = '#E0F5FF';
export const bogotaBlue100 = '#F0FAFF';
export const bogotaBlue000 = '#F7FDFF';

// TURQUOISE PALETTE
export const brightTurquoise900 = '#003027';
export const brightTurquoise800 = '#00614E';
export const brightTurquoise700 = '#009175';
export const brightTurquoise600 = '#00C29C';
export const brightTurquoise500 = '#00F2C3';
export const brightTurquoise400 = '#33F5CF';
export const brightTurquoise300 = '#66F7DB';
export const brightTurquoise200 = '#99FAE7';
export const brightTurquoise100 = '#CCFCF3';
export const brightTurquoise000 = '#E6FEF9';

// GRAY PALETTE = BLACK
export const scarpaGray900 = '#000000';
export const scarpaGray800 = '#252129';
export const scarpaGray700 = '#2E2933';
export const scarpaGray600 = '#433E47';
export const scarpaGray500 = '#6D6970';
export const scarpaGray400 = '#979499';
export const scarpaGray300 = '#C0BFC2';
export const scarpaGray200 = '#D3D2D4';
export const scarpaGray100 = '#E6E5E7';
export const scarpaGray000 = '#FCFCFC';
export const scarpaGray077 = '#c4c4c4';

// TOMATO CORAL
export const tomatoCoral000 = '#FFEEEE';
export const tomatoCoral100 = '#FFDEDE';
export const tomatoCoral200 = '#FFBDBC';
export const tomatoCoral400 = '#FF7A79';
export const tomatoCoral500 = '#FF5958';
export const tomatoCoral600 = '#CC4746';

// GOLDEN YELLOW
export const goldenYellow000 = '#FEF5E6';
export const goldenYellow100 = '#FCEACC';
export const goldenYellow200 = '#FEFAF2'; // Find if is the name of the color with Yesid
export const goldenYellow300 = '#F6C166';
export const goldenYellow400 = '#F3AC33';
export const goldenYellow500 = '#F09700';
export const goldenyellow600 = '#FCCA19';

// MADANG GREEN
export const madangGreen200 = '#E7FDDD';
export const madangGreen500 = '#C3FAAB';
export const madangGreen600 = '#9CC889';
export const madangGreen700 = '#759667';

// ERRORS PALETTE = BLACK
export const errorBackground = '#FFEEEE';
export const error = '#E51817';
export const disabledBackground = '#EAEAEB';
export const mainBackground = '#F8F9FA';

// Hint Perano
export const hintPerano000 = '#F7F6FF';
export const hintPerano026 = '#F2E6FF';
export const hintPerano100 = '#EEEDFE';
export const hintPerano200 = '#DDDCFE';
export const hintPerano300 = '#CCCAFD';
export const hintPerano400 = '#BBB9FD';
export const hintPerano500 = '#AAA7FC';
export const hintPerano600 = '#8886CA';

// Hint Green
export const hintGreen200 = '#F3FFEF';
export const hintGreen500 = '#E2FFD7';

// Night Blue
export const nightBlue000 = '#FFFFFF';
export const nightBlue100 = '#D3D3D8';
export const nightBlue200 = '#A7A7B1';
export const nightBlue300 = '#7B7B8A';
export const nightBlue400 = '#4F4F63';
export const nightBlue500 = '#23233C';
export const nightBlue600 = '#1C1C30';
export const nightBlue700 = '#151524';
export const nightBlue800 = '#0E0E18';
export const nightBlue900 = '#000000';
export const lightBlue000 = '#F6FCFF';

// Functional
export const successPrimary = '#009175';
export const successSecondary = '#E6FEF9';
export const successPrimary50 = 'rgba(0, 145, 117, 0.5)';
export const warningPrimary = '#F09700';
export const warningSecondary = '#FEF5E6';
export const warningPrimary50 = 'rgba(240, 151, 0, 0.5)';
export const errorPrimary = '#E61817';
export const errorSecondary = '#FFEEEE';
export const errorPrimary50 = 'rgba(230, 24, 23, 0.5)';
export const informationPrimary = '#006B99';
export const informationSecondary = '#EBF9FF';
export const informationPrimary50 = 'rgba(0, 107, 153, 0.5)';
export const neutralPrimary = '#2E2933';
export const neutralSecondary = '#FFFFFF';
export const neutralPrimary50 = 'rgba(46, 41, 51, 0.5)';
export const plainlightGray = '#F2F2F3';

// SkeletonColors
export const skeletonGray100 = '#EBEBEB';
export const skeletonGray200 = '#D5DCE1';
export const skeletonGray300 = '#D3D3D3';

// font agnostic type font
// principal
export const principalFont = 'Montserrat';

// Secondary
export const secondaryFont = 'Open Sans';

// Font-family Mulish
export const mulishFont = 'Mulish';
