import {
  electricPurple500,
  monserratRegular,
  scarpaGray500,
  electricPurple600,
  white,
  electricPurple900,
  robotoRegular,
  scarpaGray200,
  electricPurple50,
} from '@habitech/shared';
import styled from 'styled-components';
import {
  backgroundSite,
  hintPerano100,
  nightBlue400,
  purple,
} from '../../styles/stylesConstants';

export const LayoutWrapper = styled.div`
  background: ${backgroundSite};
  width: 100%;
  height: 100%;
  position: relative;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: 24px;
`;

export const ContainerPage = styled.div`
  // display: flex;
  // flex-direction: column;
  grid-column: 3 / 13;
  height: 100vh;

  .content-wrapper {
    max-width: 1016px;
    height: calc(100% - 65px);
    overflow-y: auto;
    width: 100%;
    padding: 16px 24px 0 0;
    position: relative;
  }
`;

export const SidebarWrapper = styled.div`
  background: ${white};
  height: 100%;
  position: relative;
  z-index: 200;
  grid-column: 1/3;
  padding: 0 2rem;

  .logo-habi {
    margin: 24px auto;
    display: flex;
    .title {
      width: 80px;
      margin-left: 7px;
      font-family: ${monserratRegular};
      font-size: 14px;
      font-weight: 700;
      color: ${electricPurple500};
      margin-top: 4px;
    }
  }

  .sidebar-container {
  }

  .marginb-48 {
    margin-bottom: 48px;
  }

  .user-information {
    margin-top: 72px;
    .name {
      font-size: 20px;
      font-weight: 700;
      color: ${electricPurple900};
    }
    .role {
      color: ${scarpaGray500};
      font-family: ${robotoRegular};
      font-size: 12px;
      font-weight: 400;
      margin-top: 12px;
    }
  }

  .separator-gray-mx {
    width: 100%;
    height: 1px;
    background: ${scarpaGray200};
    margin: 24px 0;
  }

  .night-blue {
    color: ${nightBlue400};
  }

  .sidebar-button {
    background: ${hintPerano100};
    border-radius: 6px;
    font-size: 14px;
    color: ${purple};
    padding: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: ${monserratRegular};
    font-weight: bold;
    cursor: pointer;
  }

  .sidebar-button-selected {
    background: ${electricPurple50};
    border-radius: 8px;
    font-size: 12px;
    color: ${electricPurple500};
    padding: 8px;
    height: 40px;
    display: flex;
    justify-content: initial;
    align-items: center;
    font-family: ${monserratRegular};
    font-weight: 700;
    cursor: pointer;
    margin-bottom: 12px;
    img {
      width: 24px;
      height: 24px;
      margin-right: 8px;
    }
  }

  .sidebar-button-clean {
    background: ${white};
    border-radius: 8px;
    color: ${scarpaGray500};
    font-size: 12px;
    padding: 8px;
    display: flex;
    height: 40px;
    justify-content: initial;
    align-items: center;
    font-family: ${monserratRegular};
    font-weight: 700;
    cursor: pointer;
    margin-bottom: 12px;
    img {
      width: 24px;
      height: 24px;
      margin-right: 8px;
    }
  }
`;

export const HeaderWrapper = styled.div`
  background: ${white};
  position: relative;
  z-index: 100;
  margin-left: -24px;
  width: calc(100% + 24px);
  height: 65px;
  border-bottom: 1px solid rgba(48, 49, 51, 0.1);
  box-shadow: 0px 0px 1px rgba(48, 49, 51, 0.1),
    0px 2px 4px rgba(48, 49, 51, 0.2);

  .header-container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;

    .header-date {
      margin-left: 24px;
    }

    button {
      font-weight: bold;
    }

    .header-divider {
      flex: 1 1;
      flex-grow: auto;
    }

    .user-info {
      margin: 0 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;

      .menu-user {
        width: 150px;
        height: 45px;
        position: absolute;
        top: 50px;
        right: 0px;
        background: ${white};
        box-shadow: rgb(0 0 0 / 20%) 0px 5px 5px -3px,
          rgb(0 0 0 / 14%) 0px 8px 10px 1px, rgb(0 0 0 / 12%) 0px 3px 14px 2px;
        display: flex;
        align-items: center;
        justify-content: space-around;
        color: rgb(99, 1, 204);
        cursor: pointer;
      }
    }
  }

  .title-zone {
    margin-left: 20px;
    font-family: ${monserratRegular};
    font-weight: 600;
    color: ${electricPurple500};
    font-size: 16px;
  }

  .user-image {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 2px solid ${electricPurple600};
    overflow: hidden;
    margin: 0 16px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .country-image {
    width: 40px;
    height: 40px;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
    }
  }
`;
