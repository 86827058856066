import styled from 'styled-components';
import {
  monserratRegular,
  scarpaGray800,
  white,
  scarpaGray100,
} from '@habitech/shared';

export const ContainerWrapper = styled.div`
  max-width: 1016px;
  height: calc(100% - 65px);
  overflow-y: auto;
  width: 100%;
  padding: 16px 24px 0 0;
  position: relative;
`;
export const TitleEngine = styled.div`
  font-family: ${monserratRegular};
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 32px;
  display: flex;
  align-items: center;
  letter-spacing: -0.01em;
  color: ${scarpaGray800};
  margin-top: 57px;
  margin-bottom: 20px;
`;

export const CardWrapper = styled.div`
  background: ${white};
  box-shadow: 0px 0px 1px rgba(48, 49, 51, 0.05),
    0px 2px 4px rgba(48, 49, 51, 0.1);
  border-radius: 6px;
  display: grid;
  padding: 57px 31px 28px 31px;
  margin-bottom: 32px;
`;

export const WrapperDiscountEngine = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: repeat(9, 1fr);
  gap: 24px;
`;

export const Separator = styled.div`
  width: 100%;
  border-top: 1px solid ${scarpaGray100};
  margin: 15px 0;
`;
export const SeparatorWrapper = styled.div`
  grid-column: 1/10;
`;

export const EditRulesButtonWrapper = styled.div`
  grid-column: 1/10;
  display: flex;
  justify-content: flex-end;
`;
