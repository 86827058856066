import { useEffect, useState } from 'react';
import { DropdownButton } from '@habitech/call-to-action';
import { IRulesValues } from '../../../../model';

interface IDropdownRule {
  field: any;
  handleInputValue: (
    value: string,
    error: boolean,
    field: any,
    isDropdown?: boolean
  ) => void;
  rulesValues: IRulesValues[];
  selectedRule: any;
}

const DropdownRule = ({
  field,
  handleInputValue,
  rulesValues,
  selectedRule,
}: IDropdownRule) => {
  const [inputValue, setInputValue] = useState<string | number>('');

  useEffect(() => {
    if (inputValue !== '') {
      handleInputValue(String(inputValue), false, field, true);
    }
  }, [inputValue]);

  useEffect(() => {
    const temp = rulesValues.find((item) => item.id === selectedRule.id);

    if (temp) {
      const val: any = temp?.values.find((item) => item.name === field.name);

      if (val) {
        setInputValue(val.value);
      }
    }
  }, []);

  return (
    <DropdownButton
      dataId={field.name}
      label={field.label}
      setValue={(val: any) => {
        setInputValue(val);
      }}
      variant="white"
      placeholder="Seleccione una opción"
      listOptions={field.options}
      size="fluid"
      value={inputValue}
    />
  );
};

export default DropdownRule;
