export const formatNumberNoDecimal = (number = 0) => {
  return Math.trunc(number);
};

export const formatNumberDecimal = (number = 0) => {
  return number.toFixed(1);
};

export const formatMoney = (number = 0) => {
  const newNumber = new Intl.NumberFormat('es-CO', {
    style: 'currency',
    currency: 'COP',
    minimumFractionDigits: 0,
  });
  return newNumber.format(number);
};
