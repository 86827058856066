import {
  scarpaGray100,
  scarpaGray700,
  scarpaGray800,
  tomatoCoral300,
  tomatoCoral800,
  white,
} from '@habitech/shared';
import styled from 'styled-components';
import { hintPerano200 } from '../../../../styles/stylesConstants';

export const ContainerModal = styled.div`
  position: relative;
  width: 660px;
  height: 490px;
  margin: 7% auto;
  justify-self: center;
  align-self: center;
  background: ${white};
  outline: none;

  .icon-close {
    font-size: 29px;
    position: absolute;
    right: 32px;
    top: 32px;

    &:hover {
      cursor: pointer;
    }
  }

  padding: 74px 74px 68px 74px;
  box-shadow: 0px 0px 1px rgba(48, 49, 51, 0.05),
    0px 16px 24px rgba(48, 49, 51, 0.1);
  border-radius: 16px;

  .MuiTableHead-root {
    tr > th {
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 600;
      font-size: 10px !important;
      line-height: 18px;
      color: ${scarpaGray700};
      border-top: 1px solid ${hintPerano200};
      border-bottom: 1px solid ${scarpaGray100};
    }
  }
  .MuiTableContainer-root {
    box-shadow: none;
  }

  .nid-row {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 20px;
    color: ${scarpaGray800};
  }
  .row-name {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: ${scarpaGray800};
  }

  .title {
    display: inline-grid;
    gap: 4px;
    grid-template-columns: auto 1fr;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: ${scarpaGray800};
    margin-bottom: 40px;
  }
  .MuiTablePagination-actions {
    width: 200px;
  }
`;

export const BadgeDays = styled.div`
  width: 70px;
  height: 20px;
  left: 867px;
  top: 526px;
  background: ${tomatoCoral300};
  border-radius: 4px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  color: ${tomatoCoral800};
  margin: auto;
`;
