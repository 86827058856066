import styled from 'styled-components';
import {
  scarpaGray800,
  robotoRegular,
  blueLinks,
  electricPurple500,
  disabledBorder,
  scarpaGray100,
} from '@habitech/shared';

export const ColumnDescription = styled.div`
  grid-column: 1/3;
  font-family: ${robotoRegular};
  .size {
    width: 130px;
  }
  .subtitle {
    width: 120px;
    font-family: ${robotoRegular};
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: ${scarpaGray800};
    margin-left: 32px;
  }
`;
export const ContainerOne = styled.div`
  grid-column: 3/5;
  .space {
    padding-bottom: 30px;
  }
`;
export const ContainerTwo = styled.div`
  grid-column: 5/7;
  .space {
    padding-bottom: 30px;
  }
  .title {
    font-family: ${robotoRegular};
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    margin-bottom: 4px;
  }
  .input-custom {
    input {
      height: 40px !important;
    }
  }
  .placeholder {
    justify-self: center;
    font-family: ${robotoRegular};
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: ${disabledBorder};
  }
`;
export const ContainerThree = styled.div`
  grid-column: 7/9;
  .space {
    padding-bottom: 30px;
  }
`;
export const stylesMultiSelect = {
  select: {
    '.MuiSelect-icon': {
      fontSize: '24px',
      color: scarpaGray800,
    },
    fontSize: '16px',
    lineHeight: '21px',
    height: '40px',
    width: '100%',
  },
  menu: {
    PaperProps: {
      sx: {
        maxHeight: 200,
        fontSize: '16px',
        'ul > li > .MuiListItemText-root > span': {
          fontSize: '16px',
        },
        'ul > li.Mui-selected': {
          borderLeft: `2px solid ${blueLinks}`,
        },
      },
    },
  },
  checkbox: {
    color: `${electricPurple500} !important`,
    '.MuiCheckbox-root': { fontSize: '18px !important' },
    '.MuiSvgIcon-root': {
      fontSize: '16px !important',
    },
  },
  form: {
    m: 1,
    width: '100%',
    '.MuiOutlinedInput-root': { borderRadius: '6px', height: 48 },
  },
};
export const SeparatorWrapper = styled.div`
  grid-column: 1/10;
  margin-left: 32px;
`;
export const Separator = styled.div`
  width: 100%;
  border-top: 1px solid ${scarpaGray100};
  margin: 16px 0;
`;
