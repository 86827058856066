import { FC } from 'react';

import { SimpleCardMap } from '../../../../components/SimpleCard';
import {
  InfoCardsContainer,
  InfoCardHeader,
  InfoZoneName,
  InfoMediumZone,
  BehaviorCardHeader,
  BehaviorCardList,
  ZoneFooter,
} from './styles';
import unionIcon from '../../../../assets/icons/union.svg';
import briefCase from '../../../../assets/icons/Briefcase.svg';
import { InfoZone } from '../../services/zone.services';
import DateDisclaimer from '../../../../components/DateDisclaimer';
import { useAppSelector } from '../../../../redux/hooks';

interface ZoneInfoCardsProps {
  infoZone: InfoZone;
  zoneId: number;
  areaName: string;
}

const ZoneInfoCards: FC<ZoneInfoCardsProps> = ({
  infoZone,
  zoneId,
  areaName,
}) => {
  const userState = useAppSelector((state) => state.userStore);

  return (
    <InfoCardsContainer>
      <SimpleCardMap padding="20px">
        <InfoCardHeader>
          <div className="header-left">
            <img src={unionIcon} alt="icono casa habi" />
            <p>Zona</p>
          </div>

          <span className="header-big-zone">{areaName}</span>
        </InfoCardHeader>

        <InfoZoneName>{infoZone.zm_label}</InfoZoneName>
        <InfoMediumZone>{infoZone.zg_label}</InfoMediumZone>
        <ZoneFooter>
          <div className="content">Zona No. {zoneId}</div>
        </ZoneFooter>
      </SimpleCardMap>

      <SimpleCardMap padding="18px">
        <BehaviorCardHeader>
          <img src={briefCase} alt="icono de verificacion" />

          <p>Comportamiento comercial de la zona</p>
        </BehaviorCardHeader>

        <BehaviorCardList>
          <div>
            <p>Compras</p>
            <span>{infoZone.total_purchases ?? '-'}</span>
          </div>

          <div>
            <p>Ventas</p>
            <span>{infoZone.total_sales ?? '-'}</span>
          </div>

          <div>
            <p>No. Inmuebles</p>
            <span>{infoZone.current_inventory ?? '-'}</span>
          </div>

          <div>
            <p>Market Share</p>
            <span>{infoZone.market_share}%</span>
          </div>
        </BehaviorCardList>
        <ZoneFooter>
          <div className="content">
            <DateDisclaimer
              date={new Date(userState.userDate)}
              margin={false}
            />
          </div>
        </ZoneFooter>
      </SimpleCardMap>
    </InfoCardsContainer>
  );
};

export default ZoneInfoCards;
