import { useState, useEffect, useCallback } from 'react';
import { MonthPicker } from '@habitech/month-picker';
import ReturnLink from '../../components/ReturnLink';
import { useAppSelector } from '../../redux/hooks';
import { selectUserDateRange } from '../../redux/reducers/userSelectors';
import DateDisclaimer from '../../components/DateDisclaimer';
import { getUtilityZone } from '../../apis/utility/utility';
import HabiLoader from '../../components/HabiLoader/HabiLoader';

// Styles
import {
  ReturnWrapper,
  SectionContainer,
  SubTitleSection,
  TitleSection,
} from '../MarketingLeads/styles';
import UtilityWrapper, {
  DateContainer,
  Description,
  InfoItem,
  LoaderContainer,
  Separator,
  SubSectionTitle,
  UtilityEmptyContainer,
  UtilityInfoContainer,
  UtilityInfoContent,
  UtilityInfoWrapper,
  UtilityObjetiveWrapper,
} from './style';
import {
  TitleAndSubtitle,
  TitleZone,
} from '../MarketingLeads/components/LineGraphic/styles';

// Images
import moneyBag from '../../assets/icons/money-bag.svg';
import LocationIcon from '../../assets/icons/location-icon.svg';
import utilidadPonderada from '../../assets/icons/utilidad-ponderada.svg';
import utilidadCompra from '../../assets/icons/utilidad-compra.svg';
import utilidadMetropolitana from '../../assets/icons/utilidad-metropolitana.svg';
import vs from '../../static/assets/icons/vs.svg';
import utilidadObjetivo from '../../assets/icons/utilidad-objetivo.svg';
import utilidadEmpty from '../../assets/icons/utilidad-empty.svg';

interface GetUtilityZoneProps {
  average_utility: number;
  ma_utility: number;
  target_utility: number;
  weighted_utility: number;
}

const Utility = () => {
  const userDateRange = useAppSelector(selectUserDateRange);
  const [utilityDate, setUtilityDate] = useState<[Date, Date?]>([
    userDateRange[0],
    userDateRange[1],
  ]);
  const [showEmptyMessage, setShowEmptyMessage] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const zoneState = useAppSelector((state) => state.zoneStore);
  const [utility, setUtility] = useState<GetUtilityZoneProps>({
    average_utility: 0.0,
    ma_utility: 0.0,
    target_utility: 0.0,
    weighted_utility: 0.0,
  });

  const getUtilityZoneFunction = useCallback(() => {
    getUtilityZone(zoneState.zone.middleZone.id, utilityDate)
      .then((response: any) => {
        if (response.success) {
          setUtility(response.data);
          setLoaded(true);
        } else {
          setShowEmptyMessage(true);
        }
      })
      .catch(() => {
        setShowEmptyMessage(true);
      });
  }, [utilityDate, zoneState.zone.middleZone.id]);

  useEffect(() => {
    getUtilityZoneFunction();
    setLoaded(false);
    setShowEmptyMessage(false);
  }, [getUtilityZoneFunction, userDateRange]);

  return (
    <UtilityWrapper>
      <ReturnWrapper>
        <ReturnLink
          text="Regresar a análisis de zona"
          link="/portal/analisis-de-zona"
        />
      </ReturnWrapper>

      <TitleSection>Utilidad</TitleSection>

      <SubTitleSection>
        Análisis del área metropolitana y zona mediana
      </SubTitleSection>

      <SectionContainer>
        <SubSectionTitle>
          <img src={moneyBag} alt="" />
          Porcentajes de utilidad para las zonas
        </SubSectionTitle>

        <DateContainer>
          <MonthPicker
            dataId="1"
            value={utilityDate as [Date, Date?]}
            onChangeValue={setUtilityDate}
            isRange
            startYear={2020}
          />
          <DateDisclaimer date={utilityDate[0]} />
        </DateContainer>

        <Separator />

        <TitleZone>
          <img src={LocationIcon} alt="pin del mapa" />
          <TitleAndSubtitle>
            <div className="title">Area</div>
            <div className="subtitle">Área Metropolitana</div>
          </TitleAndSubtitle>{' '}
        </TitleZone>

        {showEmptyMessage ? (
          <UtilityEmptyContainer>
            <img src={utilidadEmpty} alt="" />
            <div>
              <div className="title">
                Jumm... no encontramos información de la zona para esta fecha.
              </div>
              <div className="text">
                Es probable que la zona no cuente con data en la fecha que
                seleccionaste, puedes probar cambiando la fecha que
                seleccionaste para obtener resultados.
              </div>
              <strong>
                Sí identificas que es un error del portal, repórtalo al equipo
                de Spacestation.
              </strong>
            </div>
          </UtilityEmptyContainer>
        ) : (
          <UtilityInfoContainer>
            {!loaded ? (
              <LoaderContainer>
                <HabiLoader size="small" />
              </LoaderContainer>
            ) : (
              <UtilityInfoContent>
                <UtilityInfoWrapper>
                  <InfoItem>
                    <Description>
                      <span className="title">Utilidad ponderada</span>
                      <span className="percent">
                        {utility.weighted_utility}%
                      </span>
                    </Description>
                    <img src={utilidadPonderada} alt="" />
                  </InfoItem>
                  <InfoItem>
                    <Description>
                      <span className="title">
                        Utilidad valor de la compra media
                      </span>
                      <span className="percent">
                        {utility.average_utility}%
                      </span>
                    </Description>
                    <img src={utilidadCompra} alt="" />
                  </InfoItem>
                  <InfoItem className="no-border">
                    <Description>
                      <span className="title">Utilidad área metropolitana</span>
                      <span className="percent">{utility.ma_utility}%</span>
                    </Description>
                    <img src={utilidadMetropolitana} alt="" />
                  </InfoItem>
                </UtilityInfoWrapper>

                <img src={vs} alt="" />

                <UtilityObjetiveWrapper>
                  <img src={utilidadObjetivo} className="icon" alt="" />
                  <div className="title">Utilidad objetivo</div>
                  <div className="percent">{utility.target_utility}%</div>
                </UtilityObjetiveWrapper>
              </UtilityInfoContent>
            )}
          </UtilityInfoContainer>
        )}
      </SectionContainer>
    </UtilityWrapper>
  );
};

export default Utility;
