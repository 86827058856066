import { useCallback, useEffect, useState } from 'react';
import moment from 'moment';

import {
  getFunnelMedianZone,
  getFunnelMetropolitanArea,
} from '../../apis/funnel/funnel';
import { useAppSelector } from '../../redux/hooks';
import { selectUserDate } from '../../redux/reducers/userSelectors';

import withRoutePage, { RoutePage } from '../../utils/withRoutePage';
import HabiLoader from '../../components/HabiLoader/HabiLoader';
import ActualRulesApproval from './components/ActualRulesApproval/ActualRulesApproval';
import FunnelFilters from './components/FunnelFilters';
import ContainerAreas from '../MarketingLeads/components/ContainerAreas/ContainerAreas';
import FunnelGraph from './components/FunnelGraph';
import ReturnLink from '../../components/ReturnLink';
import EmptyState from '../../components/EmptyState';
import AntiFunnel from './components/AntiFunnel';

import FunnelConvertionWrapper, {
  FunnelGraphsWrapper,
  ReturnContent,
  ReturnWrapper,
  Separator,
  VerticalSeparator,
  VsContainer,
} from './styles';

import { FunnelDataArrayProps } from './model';
import DateDisclaimer from '../../components/DateDisclaimer';
import ZoneValidations from './components/ZoneValidations/ZoneValidations';

const FunnelConversion: RoutePage = () => {
  const [loaded, setLoaded] = useState(false);
  const [metropolitanAreaData, setMetropolitanAreaData] = useState<
    FunnelDataArrayProps[]
  >([]);
  const [medianZoneData, setMedianZoneData] = useState<FunnelDataArrayProps[]>(
    []
  );
  const userDate = useAppSelector(selectUserDate);
  const [funnelSelectedDate, setFunnelSelectedDate] = useState<Date>(userDate);
  const [isEmptyArea, setIsEmptyArea] = useState(false);
  const [isEmptyZone, setIsEmptyZone] = useState(false);
  const [selectedDate, setSelectedDate] = useState({
    date: userDate,
    filter: 'created',
  });
  const [funnelTypeDate, setFunnelTypeDate] = useState<number>(1);
  const zoneState = useAppSelector((state) => state.zoneStore);

  const getFunnelMetropolitanAreaFunction = useCallback(() => {
    getFunnelMetropolitanArea({
      metropolitan_area_id: zoneState.zone.metropolitan_area.id,
      date: selectedDate.date,
      filter: selectedDate.filter,
    })
      .then((response) => {
        if (response?.success) {
          setLoaded(true);
          setIsEmptyArea(false);
          setMetropolitanAreaData(response.data);
        }
      })
      .catch(() => {
        setLoaded(true);
        setIsEmptyArea(true);
      });
  }, [
    selectedDate.date,
    selectedDate.filter,
    zoneState.zone.metropolitan_area.id,
  ]);

  const getFunnelMedianZoneFunction = useCallback(() => {
    getFunnelMedianZone({
      median_zone_id: zoneState.zone.middleZone.id,
      date: selectedDate.date,
      filter: selectedDate.filter,
    })
      .then((response) => {
        if (response?.success) {
          setLoaded(true);
          setIsEmptyZone(false);
          setMedianZoneData(response.data);
        }
      })
      .catch(() => {
        setLoaded(true);
        setIsEmptyZone(true);
      });
  }, [selectedDate.date, selectedDate.filter, zoneState.zone.middleZone.id]);

  const onChangeFunnelDate = (date: Date | null) => {
    if (date) {
      setFunnelSelectedDate(date);
    }
  };

  useEffect(() => {
    moment.locale('es-mx');
    setLoaded(true);
  }, []);

  useEffect(() => {
    if (userDate) {
      setSelectedDate({
        date: userDate,
        filter: funnelTypeDate === 2 ? 'managed' : 'created',
      });
    }
  }, [userDate]);

  useEffect(() => {
    setSelectedDate({
      date: funnelSelectedDate,
      filter: funnelTypeDate === 2 ? 'managed' : 'created',
    });
  }, [funnelTypeDate, funnelSelectedDate]);

  useEffect(() => {
    if (selectedDate.date) {
      setLoaded(false);
      getFunnelMetropolitanAreaFunction();
      getFunnelMedianZoneFunction();
    }
  }, [
    getFunnelMedianZoneFunction,
    getFunnelMetropolitanAreaFunction,
    selectedDate.date,
  ]);
  return (
    <FunnelConvertionWrapper>
      <ReturnWrapper>
        <ReturnContent>
          <ReturnLink
            text="Regresar a análisis de zona"
            link="/portal/analisis-de-zona"
          />
        </ReturnContent>
      </ReturnWrapper>
      <h2 className="section-title">Funnel de Conversión</h2>

      <ActualRulesApproval />
      <ZoneValidations />

      <ContainerAreas title="Análisis del área metropolitana y zona mediana">
        <FunnelFilters
          funnelSelectedDate={funnelSelectedDate}
          onChangeFunnelDate={onChangeFunnelDate}
          funnelTypeDate={funnelTypeDate}
          setFunnelTypeDate={setFunnelTypeDate}
        />

        {!!selectedDate.date && <DateDisclaimer date={selectedDate.date} />}

        <Separator />

        {!loaded ? (
          <div className="flex justify mt-20">
            <HabiLoader size="small" />
          </div>
        ) : (
          <FunnelGraphsWrapper>
            <>
              {!isEmptyArea ? (
                <FunnelGraph
                  title={zoneState.zone.area.label}
                  subTitle="Área Metropolitana"
                  data={metropolitanAreaData}
                />
              ) : (
                <EmptyState
                  title="No encontramos información."
                  body={`El area ${zoneState.zone.area.label} no cuenta con información de funnel, puedes probar cambiando la fecha que seleccionaste para obtener resultados.`}
                />
              )}

              <VerticalSeparator>
                <VsContainer>VS</VsContainer>
              </VerticalSeparator>

              {!isEmptyZone ? (
                <FunnelGraph
                  title={zoneState.zoneInfo.city}
                  subTitle="Zona Mediana"
                  data={medianZoneData}
                />
              ) : (
                <EmptyState
                  title="No encontramos información."
                  body={`La zona ${zoneState.zoneInfo.city} no cuenta con información de funnel, puedes probar cambiando la fecha que seleccionaste para obtener resultados.`}
                />
              )}
            </>
          </FunnelGraphsWrapper>
        )}

        <AntiFunnel
          funnelDate={selectedDate.date}
          funnelFilter={selectedDate.filter}
        />
      </ContainerAreas>
    </FunnelConvertionWrapper>
  );
};

FunnelConversion.requiresAuth = true;

export default withRoutePage(FunnelConversion);
