import { SetStateAction } from 'react';
import { DropdownButton } from '@habitech/call-to-action';
import {
  ColumnDescription,
  ContainerRevenueCategory,
  ContainerSelectCategory,
} from '../../styles';

interface IRevenueCategory {
  setCategorySelect: React.Dispatch<SetStateAction<any>>;
}

const RevenueCategory = ({ setCategorySelect }: IRevenueCategory) => {
  const optionsCategory = [
    {
      label: 'Prime',
      value: 'Prime',
    },
    {
      label: 'Sub-prime',
      value: 'Sub-Prime',
    },
    {
      label: 'Regular',
      value: 'Regular',
    },
    {
      label: 'Basic',
      value: 'Basic',
    },
    {
      label: 'Bone',
      value: 'Bone',
    },
  ];
  return (
    <ContainerRevenueCategory>
      <ColumnDescription>
        <div className="subtitle">
          Seleccione la categoría de revenue a editar
        </div>
      </ColumnDescription>
      <ContainerSelectCategory>
        <DropdownButton
          dataId="a"
          size="fluid"
          variant="white"
          placeholder="Seleccione"
          listOptions={optionsCategory}
          label="Revenue"
          setValue={(value) => setCategorySelect(value)}
        />
      </ContainerSelectCategory>
    </ContainerRevenueCategory>
  );
};

export default RevenueCategory;
