import styled from 'styled-components';

import {
  principalFont,
  purple,
  scarpaGray800,
  secondaryAA,
  separator,
} from '../../../../styles/stylesConstants';

export const ZonePagesContainer = styled.div`
  margin-top: 32px;
`;

export const ZonePagesTabs = styled.div`
  width: 100%;
  margin-bottom: 24px;
  border-bottom: 1px solid ${separator};
`;

export const PageTab = styled.button<{ active: boolean }>`
  display: inline-block;
  padding: 16px;
  background: none;
  border: none;
  outline: none;
  font-size: 14px;
  font-weight: 600;
  color: ${secondaryAA};
  font-family: ${principalFont};
  &:hover {
    cursor: pointer;
  }
  ${({ active }) =>
    active &&
    `
    color: ${scarpaGray800};
    border-bottom: 4px solid ${purple};
  `}
`;

export const ZonePageItem = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: space-between;

  p {
    color: ${scarpaGray800};
    margin-left: 16px;
    font-weight: 700;
  }
`;

export const ZonePageList = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, 256px);
  gap: 25px;
  justify-content: start;
`;
