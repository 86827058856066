import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '@habitech/call-to-action';
import { MonthPicker } from '@habitech/month-picker';

import { capitalize } from '@mui/material';
import gpsIcon from '../../assets/icons/gps.svg';
import BtnChevrondown from '../../assets/icons/BtnChevrondown.svg';
import BtnChevronUp from '../../assets/icons/BtnChevronUp.svg';
import exitIcon from '../../assets/icons/exit.svg';
import imgMexico from '../../static/assets/icons/mexico.svg';
import imgColombia from '../../static/assets/icons/colombia.png';

import { HeaderWrapper } from '../Layout/style';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import {
  selectUser,
  selectUserDateRange,
} from '../../redux/reducers/userSelectors';
import { userStoreActions } from '../../redux/reducers/userReducer';
import { zoneStoreActions } from '../../redux/reducers/zoneReducer';
import InteractiveButton from '../InteractiveButton';

const LayoutHeader = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const user = useAppSelector(selectUser);
  const userDateRange = useAppSelector(selectUserDateRange);
  const [value, setValue] = useState<[Date, Date?]>([
    userDateRange[0],
    userDateRange[1],
  ]);
  const [showMenu, setShowMenu] = useState(false);
  const zoneState = useAppSelector((state) => state.zoneStore);
  const country = localStorage.getItem('country');
  useEffect(() => {
    dispatch(userStoreActions.updateDateRange(value as Date[]));
  }, [dispatch, value]);

  const handleUserMenu = () => {
    setShowMenu(!showMenu);
  };

  const onLogout = () => {
    dispatch(userStoreActions.clearUser());
    localStorage.clear();
  };

  const goToSelectZone = () => {
    dispatch(zoneStoreActions.clearZone());
    navigate('/seleccion-de-zona');
  };

  const buttonImage = <img src={gpsIcon} alt="icono de gps" />;

  return (
    <HeaderWrapper>
      <div className="header-container">
        <div className="header-date">
          <MonthPicker
            dataId="1"
            value={value as [Date, Date?]}
            onChangeValue={setValue}
            isRange
            startYear={2020}
          />
        </div>

        <div className="title-zone">
          {capitalize(zoneState.zone.area.label)}
          {' - '}
          {capitalize(zoneState.zone.middleZone.label.toLowerCase())}
        </div>

        <div className="header-divider" />

        <Button
          dataId="change-zone"
          variant="stroke"
          iconLeft={buttonImage}
          size="large"
          onClick={goToSelectZone}
        >
          Cambiar de zona
        </Button>

        <div className="user-info">
          <div className="country-image">
            <img src={country === 'MX' ? imgMexico : imgColombia} alt="" />
          </div>
          <div className="user-image">
            <img
              src={user?.imageProfile}
              referrerPolicy="no-referrer"
              alt="Imagen de perfil"
            />
          </div>

          <InteractiveButton onClick={handleUserMenu}>
            <img
              src={showMenu ? BtnChevronUp : BtnChevrondown}
              className="pointer"
              alt="menu para cerrar sesion"
            />
          </InteractiveButton>

          {showMenu && (
            <div
              role="button"
              className="menu-user"
              tabIndex={0}
              onKeyDown={(ev) => ev.key === 'Enter' && onLogout()}
              onClick={onLogout}
            >
              Cerrar sesión
              <img src={exitIcon} alt="Icono de cerrar sesión" />
            </div>
          )}
        </div>
      </div>
    </HeaderWrapper>
  );
};

export default LayoutHeader;
