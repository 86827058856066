import { LayoutWrapper, ContainerPage } from './style';
import LayoutHeaderClean from '../LayoutClean';
import { ITemplateClean } from './TemplateClean.utils';
import LayoutSidebar from '../LayoutSidebar';

const TemplateClean = ({ children }: ITemplateClean) => {
  return (
    <LayoutWrapper>
      <LayoutSidebar />
      <ContainerPage>
        <LayoutHeaderClean />
        {children}
      </ContainerPage>
    </LayoutWrapper>
  );
};

export default TemplateClean;
