import { Dispatch } from 'react';
import { SimpleTextField } from '@habitech/keychain-atoms-inputs';
import {
  ContainerHistoricalRange,
  ColumnDescription,
  ContainerCounter,
  CardDescriptionCounter,
} from '../../styles';
import checkSquare from '../../../../assets/images/check-square.svg';
import { tabItemsPlural, tabItemsSingular } from '../../../HesH/HesH.utils';

interface IMinimalRange {
  minimalValue: string;
  setMinimalValue: Dispatch<React.SetStateAction<string>>;
  isSingular?: boolean;
  tabSelected?: number;
  summary: any;
  type: string;
}
interface TabItem {
  id: number;
  label: string;
}

const MinimalRange = ({
  isSingular = false,
  tabSelected,
  summary,
  type,
  minimalValue,
  setMinimalValue,
}: IMinimalRange) => {
  const tabItems = isSingular ? tabItemsSingular : tabItemsPlural;
  const foundTabItem: TabItem | undefined = tabItems.find(
    (item) => item.id === tabSelected
  );

  const handleGenerateTitle = () => {
    const pluralLabel = isSingular ? '' : 's';
    const label = foundTabItem?.label || '';

    const titleDescription = `Mínimo de ventas actual de la${
      isSingular ? ' ' : pluralLabel
    }  ${label} seleccionada${pluralLabel}`;

    return titleDescription;
  };

  const handleGenerateDescription = () => {
    const { min, max, median } = summary;
    return `Menor : ${+min || '0'}  - Mediana: ${+median || '0'}  - Mayor: ${
      +max || '0'
    } - ${type || ''}`;
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;

    if (type === 'Sin Experiencia') {
      setMinimalValue('0');
    } else if (+value >= 0 && +value <= 30) {
      setMinimalValue(value);
    }
  };

  return (
    <ContainerHistoricalRange>
      <ColumnDescription>
        <div className="subtitle">Edita el valor de mínimo de ventas</div>
      </ColumnDescription>
      <ContainerCounter>
        <CardDescriptionCounter>
          <img src={checkSquare} alt="calculator" height={48} />
          <div>
            <div className="title-description">{handleGenerateTitle()}</div>
            <div className="subtitle-description-minimal">
              {handleGenerateDescription()}
            </div>
          </div>
        </CardDescriptionCounter>
        <div className="container-input">
          <SimpleTextField
            value={minimalValue}
            name="min"
            label="Nuevo mínimo de ventas"
            onChange={handleInputChange}
            placeholder="0"
          />
        </div>
      </ContainerCounter>
    </ContainerHistoricalRange>
  );
};

export default MinimalRange;
