import styled from 'styled-components';
import {
  disabledBorder,
  scarpaGray500,
  robotoRegular,
  white,
} from '@habitech/shared';

interface IContainerTextArea {
  limit: boolean;
}

const ContainerTextArea = styled.div<IContainerTextArea>`
  .text_area {
    background: ${white};
    border: ${(props) =>
      props.limit ? `2px solid red` : `1px solid${scarpaGray500}`};
    border-radius: 8px;
    font-family: ${robotoRegular};
    font-weight: 400;
    font-size: 16px;
    line-height: 40px;
    resize: none;
    outline: none;
    width: 540px;
  }
  .label {
    font-family: ${robotoRegular};
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    margin-bottom: 4px;
    margin-left: 4px;
  }
  .counter {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    text-align: right;
    color: ${disabledBorder};
  }
`;

export default ContainerTextArea;
