import { Line } from 'react-chartjs-2';
import LocationIcon from './assets/location.svg';
import {
  CustomLegendContainer,
  CustomLegendItem,
  GraphicArea,
  IndicatorColor,
  TitleAndSubtitle,
  TitleZone,
  TitleContainer,
  TextY,
  TextX,
  ContainerGraph,
} from './styles';

interface ISingleLineGraph {
  dataArea: any;
  title: string;
  color: string;
  subtitle: string;
  border?: boolean;
}

const SingleLineGraph = ({
  dataArea,
  title,
  color,
  subtitle,
  border = false,
}: ISingleLineGraph) => {
  const options = {
    scales: {
      y: {},
      x: {
        grid: {
          drawBorder: false,
          lineWidth: 0,
        },
      },
    },
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
    },
  };
  return (
    <>
      <TitleContainer>
        <TitleZone>
          <img src={LocationIcon} alt="pin del mapa" />
          <TitleAndSubtitle>
            <div className="title">{title}</div>
            <div className="subtitle">{subtitle}</div>
          </TitleAndSubtitle>{' '}
        </TitleZone>
        <CustomLegendContainer>
          <CustomLegendItem>
            <IndicatorColor color={color} />
            <div className="text">Registros que llegan a pricing</div>
          </CustomLegendItem>
        </CustomLegendContainer>
      </TitleContainer>
      <ContainerGraph>
        <TextY>Número de Registros</TextY>
        {border}
        <GraphicArea>
          <div className={border ? 'with-border' : 'without-border'}>
            {dataArea.hasOwnProperty('datasets') && (
              <Line height="300" options={options} redraw data={dataArea} />
            )}
          </div>
        </GraphicArea>
      </ContainerGraph>
      <TextX>Fecha de registro del lead</TextX>
    </>
  );
};

export default SingleLineGraph;
