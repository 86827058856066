import { monserratRegular } from '@habitech/shared';
import styled from 'styled-components';
import {
  nightBlue500,
  scarpaGray600,
  textGray3,
  hintPerano200,
} from '../../../../styles/stylesConstants';

export const AntiFunnelGraphWrapper = styled.div`
  width: 100%;
`;

export const TitleZone = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 8px;
`;

export const TitleAndSubtitle = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 2px;
  margin-bottom: 33px;

  .title {
    font-family: ${monserratRegular};
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    display: flex;
    align-items: center;
    color: ${scarpaGray600};
  }

  .subtitle {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: ${textGray3};
  }
`;

export const GraphicsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
`;

interface GraphicWrapperProps {
  first: boolean;
}

export const GraphicWrapper = styled.div<GraphicWrapperProps>`
  border-right: ${(props) =>
    props.first ? `1px solid ${hintPerano200}` : 'none'};
  padding: ${(props) => (!props.first ? '0 0 0 28px' : '0')};
`;

export const TitleGraphic = styled.div`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  color: ${nightBlue500};
  margin-bottom: 12px;
  padding: 0 0 0 24px;
`;
