import { Button } from '@habitech/call-to-action';
import {
  DescriptionArea,
  IconDiscount,
  Indicator,
  Indicators,
  Title,
  WrapperActualDiscount,
  WrapperButton,
} from './styles';
import LogoDiscount from '../../../../assets/icons/discount-logo.svg';
import ApartmentsIcon from './assets/apartments.svg';
import HouseIcon from './assets/house-icon.svg';

const ActualDiscount: React.FC<{
  setModalOpen: Function;
  pricing: {
    apartment_discount: number;
    house_discount: number;
  };
}> = ({ setModalOpen, pricing: { apartment_discount, house_discount } }) => {
  return (
    <WrapperActualDiscount>
      <IconDiscount>
        <img src={LogoDiscount} alt="icono de descuento" />
      </IconDiscount>
      <DescriptionArea>
        <Title>Descuento actual en la zona</Title>
        <Indicators>
          <Indicator last={false}>
            <img src={ApartmentsIcon} alt="icono de apartamentos" />
            <div className="text">
              Apartamentos <b>{apartment_discount}%</b>
            </div>
          </Indicator>
          <Indicator last>
            <img src={HouseIcon} alt="icono de casa" />
            <div className="text">
              Casas <b>{house_discount}%</b>
            </div>
          </Indicator>
        </Indicators>
      </DescriptionArea>
      <WrapperButton>
        <Button
          dataId="discount-button"
          size="large"
          onClick={() => setModalOpen()}
        >
          Cambiar descuento
        </Button>
      </WrapperButton>
    </WrapperActualDiscount>
  );
};

export default ActualDiscount;
