import styled from 'styled-components';
import {
  nightBlue500,
  hintPerano200,
  scarpaGray600,
  textGray3,
  borderGrayResume,
} from '../../../../styles/stylesConstants';

export const WrapperShopping = styled.div`
  margin-bottom: 25px;

  .date-wrapper {
    width: 100%;
    display: flex;
    align-items: center;
  }
  .date-wrapper-container {
    max-width: 300px;
  }

  .date-divider {
    height: 1px;
    width: 100%;
    margin: 16px auto;
    background-color: ${borderGrayResume};
  }
`;

export const TitleSection = styled.div`
  grid-column: 1/11;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 32px;
  color: ${nightBlue500};
  display: flex;
  gap: 8px;
  margin-bottom: 35px;
  align-items: center;

  .icon {
    font-size: 20px;
    transform: rotate(180deg);
  }
`;

export const WrapperGraphics = styled.div`
  display: grid;
  grid-column: 1/11;
  grid-template-columns: 1fr 1fr;
`;

interface GraphicWrapperProps {
  first: boolean;
}

export const GraphicWrapper = styled.div<GraphicWrapperProps>`
  border-right: ${(props) =>
    props.first ? `1px solid ${hintPerano200}` : 'none'};
  padding: ${(props) => (!props.first ? '0 0 0 28px' : '0')};
`;

export const TitleGraphic = styled.div`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  color: ${nightBlue500};
  margin-bottom: 20px;
  grid-column: 1/3;
`;
export const TitleZone = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 8px;
`;

export const TitleAndSubtitle = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 2px;
  margin-bottom: 33px;

  .title {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    display: flex;
    align-items: center;
    color: ${scarpaGray600};
  }

  .subtitle {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: ${textGray3};
  }
`;

export const RadioButtonWrapper = styled.div`
  margin-left: 30px;
  margin-top: 20px;
  display: flex;
`;
