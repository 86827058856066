import zonesClient from '../../utils/zonesClient';
import { QualityInventory } from '../../pages/Revenue/types';
import dateToParams from '../../utils/dateToParams';

interface GetRevenueDetails {
  median_zone_id?: number;
  metropolitan_area_id?: number;
  date: [Date, Date?];
  type?: string;
}

export const getRevenueInfo = async ({
  median_zone_id,
  metropolitan_area_id,
  date,
  type,
}: GetRevenueDetails) => {
  const dateParams = dateToParams(date[0], date[1]);

  const { data } = await zonesClient.get<IApiResponse>(
    process.env.REACT_APP_ZONES_GET_REVENUE_INFO!,
    {
      params: {
        median_zone_id,
        metropolitan_area_id,
        ...dateParams,
        type,
      },
    }
  );

  return data;
};

export const getInventoryQuality = async (
  metropolitanId: number,
  date: [Date, Date?]
) => {
  const dateParams = dateToParams(date[0], date[1]);

  const { data } = await zonesClient.get<IApiResponse<QualityInventory>>(
    'get_info_quality',
    {
      params: { metropolitan_area_id: metropolitanId, ...dateParams },
    }
  );

  return data;
};
