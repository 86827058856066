// Components
import { useEffect, useState } from 'react';
import SectionTemplate from '../../templates/Section/Section';
import withRoutePage, { RoutePage } from '../../utils/withRoutePage';
import ActualDiscount from '../Pricing/components/ActualDiscount/ActualDiscount';
import getZoneInfo from '../ZoneDetail/services/zone.services';
import { useAppSelector } from '../../redux/hooks';
import ContainerAreas from '../MarketingLeads/components/ContainerAreas/ContainerAreas';
import ElasticityIndicators from './components/ElasticityIndicators'
import BehaviourGraphic from './components/BehaviourGraphic/BehaviourGraphic';

// Images
import elasticityIcon from '../../assets/icons/elasticity-icon.svg';

// Styles
import { SectionTitle } from '../FunnelConvertion/components/ContainerRejectionReasons/style';
import { SubTitle } from '../ApprovalRules/styles';
import ModalDiscount from '../Pricing/components/ModalDiscount/ModalDiscount';

const Elasticity: RoutePage = () => {
  const zoneState = useAppSelector((state) => state.zoneStore);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [zonePricing, setZonePricing] = useState({
    pricing: {
      apartment_discount: 0,
      house_discount: 0,
    },
  });

  const { middleZone } = zoneState.zone;

  useEffect(() => {
    getZoneInfo(middleZone.id).then((response) => {
      if (response.success) {
        setZonePricing((pricing) => ({
          ...pricing,
          pricing: response.data as any,
        }));
      }
    });
  }, [middleZone.id]);

  return (
    <SectionTemplate title="Elasticidad">
      <SubTitle>Accionable disponible</SubTitle>
      <ActualDiscount
        setModalOpen={() => setModalOpen(!modalOpen)}
        pricing={zonePricing.pricing}
      />

      <SubTitle>Análisis de la zona mediana</SubTitle>
      <ContainerAreas>
        <SectionTitle>
          <img src={elasticityIcon} alt="" />
          <span>Elasticidad</span>
        </SectionTitle>

        <ElasticityIndicators />

        <BehaviourGraphic />
      </ContainerAreas>
      <>
        {modalOpen && (
          <ModalDiscount
            setModalOpen={() => setModalOpen(!modalOpen)}
            zonePricing={zonePricing as any}
            setZonePricing={setZonePricing}
          />
        )}
      </>
    </SectionTemplate>
  );
};

Elasticity.requiresAuth = true;

export default withRoutePage(Elasticity);
