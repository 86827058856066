// eslint-disable-next-line import/no-cycle
import CardMedium from '../CardMedium/CardMedium';
import CardLarge from '../CardLarge/CardLarge';
import CardSmall from '../CardSmall/CardSmall';
import {
  DetailsRulesCardContainer,
  SqueletorTree,
  SqueletorTwo,
  Squeletor,
  SeparatorMargin,
} from './styles';

interface DetailsRulesCardProps {
  data: any[];
}

const DetailsRulesCard: React.FC<DetailsRulesCardProps> = ({ data }) => {
  return (
    <>
      <SeparatorMargin data-testid="separator-margin" />
      <DetailsRulesCardContainer data-testid="details-rules-card">
        {data.map((item: any, index: any) => {
          let indicator = null;
          const uniqueKey = `unique-${index}`;

          if (item.size === 'l') {
            if (item.loading === true) {
              indicator = (
                <SqueletorTree key={uniqueKey} data-testid="indicator-tree">
                  <div className="container-content">
                    <div className="container-title">
                      <div className="title" />
                      <div className="circle" />
                    </div>
                    <div className="container-cubes">
                      <div className="container-cube" />
                      <div className="container-cube" />
                      <div className="container-cube" />
                      <div className="container-cube" />
                    </div>
                  </div>
                </SqueletorTree>
              );
            } else {
              indicator = (
                <CardLarge
                  data={item.data}
                  approved={item.approved}
                  edited={item.edited}
                  id={item.id}
                  key={uniqueKey}
                />
              );
            }
          } else if (item.size === 'm') {
            if (item.loading === true) {
              indicator = (
                <SqueletorTwo key={uniqueKey} data-testid="indicator-two">
                  <div className="container-content">
                    <div className="container-title">
                      <div className="title" />
                      <div className="circle" />
                    </div>
                    <div className="container-cubes">
                      <div className="container-cube" />
                      <div className="container-cube" />
                      <div className="container-cube" />
                    </div>
                  </div>
                </SqueletorTwo>
              );
            } else {
              indicator = <CardMedium cardData={item} key={uniqueKey} />;
            }
          } else if (item.size === 's') {
            if (item.loading === true) {
              indicator = (
                <Squeletor key={uniqueKey} data-testid="indicator">
                  <div className="container-content">
                    <div className="container-title">
                      <div className="title" />
                      <div className="circle" />
                    </div>
                    <div className="container-cube" />
                  </div>
                </Squeletor>
              );
            } else {
              indicator = (
                <CardSmall
                  data={item.data}
                  approved={item.approved}
                  edited={item.edited}
                  showDetail={item.showDetail}
                  id={item.id}
                  key={uniqueKey}
                />
              );
            }
          }

          return indicator;
        })}
      </DetailsRulesCardContainer>
    </>
  );
};

export default DetailsRulesCard;
