import { useEffect, useState } from 'react';

import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { getZonePolygon } from '../../services/zoneServices';
import getZoneInfo, { InfoZone } from './services/zone.services';
import withRoutePage, { RoutePage } from '../../utils/withRoutePage';
import { zoneStoreActions } from '../../redux/reducers/zoneReducer';

import MapZones from '../../components/MapZones';
import ZoneInfoCards from './components/ZoneInfoCards';
import ZonePages from './components/ZonePages';
import ZoneDetailWrapper from './styles';
import ZoneActions from './components/ZoneActions';
import HabiLoader from '../../components/HabiLoader/HabiLoader';
import EmptyState from '../../components/EmptyState';

const ZoneDetail: RoutePage = () => {
  const zoneState = useAppSelector((state) => state.zoneStore);
  const [polygon, setPolygon] = useState<any>(null);
  const [infoZone, setInfoZone] = useState<InfoZone>();
  const [isEmptyData, setIsEmptyData] = useState(false);
  const { setZoneInformation } = zoneStoreActions;
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (zoneState.zone?.middleZone.id) {
      getZonePolygon('median', zoneState.zone.middleZone.id).then(
        (response) => {
          if (response.success) {
            const newPolygon = {
              data: response.data,
              zoom: 14,
            };
            setPolygon(newPolygon);
          }
        }
      );
    }
  }, [zoneState.zone?.middleZone.id]);

  useEffect(() => {
    if (zoneState.zone.middleZone?.id) {
      getZoneInfo(zoneState.zone.middleZone?.id)
        .then((response) => {
          setInfoZone(response?.data);
          setIsEmptyData(false);
          dispatch(setZoneInformation(response?.data));
        })
        .catch(() => {
          setIsEmptyData(true);
        });
    }
  }, [dispatch, setZoneInformation, zoneState.zone.middleZone?.id]);

  const isViewLoaded =
    infoZone && zoneState.zone.middleZone.id && polygon !== null;

  if (infoZone === undefined && isEmptyData && !isViewLoaded) {
    return (
      <EmptyState
        title="Jumm... no encontramos información de la zona."
        body="Es probable que la zona no cuente con data, puedes probar cambiando la zona que seleccionaste para obtener resultados."
        footer="Sí identificas que es un error del portal, reportarlo al equipo de Spacestation."
      />
    );
  }

  if (!isViewLoaded) {
    return <HabiLoader size="small" />;
  }

  return (
    <ZoneDetailWrapper>
      <div className="section-title">Análisis de zona</div>

      <div className="section-container">
        <div className="map-wrapper">
          <MapZones location={null} polygonZone={polygon} />
        </div>

        <div className="info-container">
          <ZoneInfoCards
            infoZone={infoZone}
            zoneId={zoneState.zone.middleZone.id}
            areaName={zoneState.zone.area.label}
          />

          <ZonePages />

          <ZoneActions />
        </div>
      </div>
    </ZoneDetailWrapper>
  );
};

ZoneDetail.requiresAuth = true;

export default withRoutePage(ZoneDetail);
