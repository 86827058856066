import {
  electricPurple500,
  electricPurple900,
  monserratRegular,
  scarpaGray500,
  electricPurple600,
  white,
  robotoRegular,
} from '@habitech/shared';
import styled from 'styled-components';
import {
  backgroundSite,
  hintPerano100,
  purple,
} from '../../styles/stylesConstants';

export const LayoutWrapper = styled.div`
  background: ${backgroundSite};
  width: 100%;
  height: 100%;
  min-height: 100vh;
  position: relative;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: 24px;
`;

export const ContainerPage = styled.div`
  display: flex;
  flex-direction: column;
  grid-column: 3 / 13;
  height: 100%;
  min-height: 100vh;
  margin-bottom: 100px;

  .content-wrapper {
    max-width: 1016px;
    height: calc(100% - 65px);
    overflow-y: auto;
    width: 100%;
    padding: 16px 24px 0 0;
    position: relative;
  }
`;

export const SidebarWrapper = styled.div`
  background: ${white};
  height: 100%;
  position: relative;
  z-index: 200;
  grid-column: 1/3;

  .sidebar-container {
    padding: 1rem 1rem 1rem 2rem;
  }

  .user-information {
    margin-top: 50px;
  }

  .role-user {
    color: ${scarpaGray500};
    font-family: ${robotoRegular};
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 2px;
    text-transform: uppercase;
  }
  .user-info {
    color: ${electricPurple900};
    font-family: ${monserratRegular};
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0.4px;
    margin-bottom: 12px;
  }

  .sidebar-button {
    background: ${hintPerano100};
    border-radius: 6px;
    font-size: 14px;
    color: ${purple};
    padding: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: ${monserratRegular};
    font-weight: bold;
    cursor: pointer;
  }

  .sidebar-button-selected {
    background: ${hintPerano100};
    border-radius: 6px;
    font-size: 14px;
    color: ${purple};
    padding: 1rem;
    display: flex;
    justify-content: initial;
    align-items: center;
    font-family: ${monserratRegular};
    font-weight: bold;
    cursor: pointer;
    img {
      width: 24px;
      height: 24px;
      margin-right: 8px;
    }
  }

  .sidebar-button-clean {
    background: ${white};
    border-radius: 6px;
    color: ${scarpaGray500};
    font-size: 14px;
    padding: 1rem;
    display: flex;
    justify-content: initial;
    align-items: center;
    font-family: ${monserratRegular};
    font-weight: bold;
    cursor: pointer;
    img {
      width: 24px;
      height: 24px;
      margin-right: 8px;
    }
  }
`;

export const HeaderWrapper = styled.div`
  background: ${white};
  position: relative;
  z-index: 100;
  margin-left: -24px;
  width: calc(100% + 24px);
  height: 65px;
  border-bottom: 1px solid rgba(48, 49, 51, 0.1);
  box-shadow: 0px 0px 1px rgba(48, 49, 51, 0.1),
    0px 2px 4px rgba(48, 49, 51, 0.2);

  .header-container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;

    .header-date {
      margin-left: 24px;
    }

    button {
      font-weight: bold;
    }

    .header-divider {
      flex: 1 1;
      flex-grow: auto;
    }

    .user-info {
      margin: 0 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;

      .menu-user {
        width: 150px;
        height: 45px;
        position: absolute;
        top: 50px;
        right: 0px;
        background: ${white};
        box-shadow: rgb(0 0 0 / 20%) 0px 5px 5px -3px,
          rgb(0 0 0 / 14%) 0px 8px 10px 1px, rgb(0 0 0 / 12%) 0px 3px 14px 2px;
        display: flex;
        align-items: center;
        justify-content: space-around;
        color: rgb(99, 1, 204);
        cursor: pointer;
      }
    }
  }

  .title-zone {
    margin-left: 20px;
    font-family: ${monserratRegular};
    font-weight: 600;
    color: ${electricPurple500};
    font-size: 16px;
  }

  .user-image {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 2px solid ${electricPurple600};
    overflow: hidden;
    margin: 0 16px;
    img {
      width: 100%;
      height: 100%;
    }
  }

  .country-image {
    width: 40px;
    height: 40px;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
    }
  }
`;
