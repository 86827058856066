import { PricingZone, PricingMetropolitan } from '../model/zonePricing';
import zonesClient from '../utils/zonesClient';

const url = process.env.REACT_APP_ZONES_API_URL;

export const getZonePolygon = async (typeZone: string, zoneId: number) => {
  const api = `get_polygon`;
  const endpoint = url + api;

  const { data } = await zonesClient.get<IApiResponse>(endpoint, {
    params: {
      type_zone: typeZone,
      zone_id: zoneId,
    }
  });
  return data;
};

export const getPricingZone = async (middleZone: number, date: Date) => {
  const new_date = new Date(date).toISOString().substring(0, 10);
  const { data } = await zonesClient
    .get<IApiResponse<PricingZone>>('/get_pricing_zone', {
      params: { zone_id: middleZone, start_date: new_date, end_date: new_date },
    })
    .catch(() => ({ data: { success: null } }));

  return data;
};

export const getPricingMetropolitanArea = async (
  metropolitanArea: number,
  date: Date
) => {
  const new_date = new Date(date).toISOString().substring(0, 10);

  const { data } = await zonesClient
    .get<IApiResponse<PricingMetropolitan>>('/get_pricing_metropolitan_area', {
      params: {
        metropolitan_area_id: metropolitanArea,
        start_date: new_date,
        end_date: new_date,
      },
    })
    .catch(() => ({ data: { success: null } }));

  return data;
};
