import axios, { type AxiosRequestConfig } from 'axios';
import { TOKEN } from '../shared/constants';

const API_KEY = process.env.REACT_APP_ZONES_API_KEY;
const URL_BASE = process.env.REACT_APP_ZONES_API_URL;
const CONTENT_TYPE = 'application/json';

export const HEADER_ZONAS_CLEAN: AxiosRequestConfig = {
  baseURL: URL_BASE,
  responseType: 'json',
  headers: {
    'x-api-key': API_KEY!,
    'Content-Type': CONTENT_TYPE,
    Authorization: `Bearer ${TOKEN?.token}`,
  },
};
export const AuthZonas = axios.create(HEADER_ZONAS_CLEAN);
