import { ContainerImagesResult } from './styles';
import startSearch from '../../../../assets/images/startSearch.svg';
import startLoading from '../../../../assets/images/startLoading.svg';
import startError from '../../../../assets/images/startError.svg';

const ImagesResult = ({ result }: { result: string }) => {
  const resultConfig = {
    start: {
      image: startSearch,
      title: 'Inicie la búsqueda de un inmueble',
      subtitle: 'Ingrese el NID del inmueble para mostrar la información',
    },
    loading: {
      image: startLoading,
      title: 'Estamos buscando la información del inmueble',
      subtitle: 'La búsqueda puede tardar unos minutos',
    },
    error: {
      image: startError,
      title:
        'Este inmueble no está disponible para aprobación en este momento.',
      subtitle:
        'Por favor, revisa que el número de NID de aprobación esté correcto o verifica que este inmueble sí se encuentra en etapa de aprobación.',
    },
  } as const;

  const { image, title, subtitle } =
    (resultConfig as Record<string, any>)[result] || resultConfig.start;

  return (
    <ContainerImagesResult>
      <img src={image} alt={result} className="image-show" />
      <div className="title">{title}</div>
      <div className="subtitle">{subtitle}</div>
    </ContainerImagesResult>
  );
};

export default ImagesResult;
