import { useEffect, useState, useRef } from 'react';
import { ContainerQuotaLogic, Divisor } from '../QuotaLogic/styles';
import SelectTypeZoneMx from '../ZoneValidationMx/componets/SelectTypeZoneMx/SelectTypeZoneMx';
import { TitleContainer } from '../ZoneValidationMx/styles';
import RuleTypeTab from './components/RuleTypeTab/RuleTypeTab';
import { IOption } from '../ZoneValidationMx/componets/SelectArea/interfaces';
import SelectArea from '../ZoneValidationMx/componets/SelectArea/SelectArea';
import getDataZoneType from '../../utils/getDataZonType';
import BusinessProjection from './components/BusinessProjection/BusinessProjection';
// eslint-disable-next-line import/no-cycle
import RulesList from './components/RulesList/RulesList';
import {
  getApprovalRules,
  getApprovalRulesResume,
  putChangeRules,
  getGeneralRulesByIds,
  deleteGeneralRulesByIds,
  expandGeneralRulesByIds,
  postGlobalRules,
} from '../../apis/approvalRules/approvalRules';
// eslint-disable-next-line import/no-cycle
import RuleSidebarForm from './components/RuleSidebarForm/RuleSidebarForm';
import FooterSave from './components/FooterSave/FooterSave';
import CustomModal from './components/BusinessProjection/components/CustomModal/CustomModal';
import initialStateGraph, {
  IMyState,
  IRulesValues,
  IValues,
  NewRule,
  RuleValue,
  TabItem,
} from './model';
import ModalConfirmation from '../../components/ModalConfirmation';
import { initialModalState, TModalInformation } from '../RulesEditor/model';
import CurrentGlobalRules from './components/CurrentGlobalRules/CurrentGlobalRules';
import ChangeRulesToApply from './components/ChangeRulesToApply/ChangeRulesToApply';
import {
  IRulesResponse,
  initialGeneralRules,
} from './components/CurrentGlobalRules/model';

const NewApprovalRules = () => {
  const [selectedTypeRule, setSelectedTypeRule] = useState(0);
  const [tabSelected, setTabSelected] = useState(0);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showCreate, setShowCreate] = useState<boolean>(false);
  const [showModalGlobalRules, setShowModalGlobalRules] =
    useState<boolean>(false);
  const [modifiedObjects, setModifiedObjects] = useState([]);
  const [modalInfo, setModalInfo] =
    useState<TModalInformation>(initialModalState);
  const [modalInfoGeneralRules, setModalInfoGeneralRules] =
    useState<TModalInformation>(initialModalState);
  const [dataGraph, setDataGraph] = useState<IMyState>(initialStateGraph);
  const [loading, setLoading] = useState(false);
  const [loadingRules, setLoadingRules] = useState(false);
  const [loadingGraph, setLoadingGraph] = useState(true);
  const today = new Date();
  const firstDayOfLastMonth = new Date(
    today.getFullYear(),
    today.getMonth() - 1,
    1
  );
  const lastDayOfLastMonth = new Date(today.getFullYear(), today.getMonth(), 0);

  const [date, setDate] = useState<[Date, Date?]>([
    firstDayOfLastMonth,
    lastDayOfLastMonth,
  ]);
  const [selectedPlaces, setSelectedPlaces] = useState<IOption[]>([]);
  const [rules, setRules] = useState<any[]>([]);
  const [rulesValues, setRulesValues] = useState<IRulesValues[]>([]);
  const [generalRules, setGeneralRules] =
    useState<IRulesResponse>(initialGeneralRules);
  const [selectedRule, setSelectedRule] = useState<any>(null);
  const [showModalForm, setShowModalForm] = useState(false);
  const [isContinue, setIsContinue] = useState(false);
  const [ButtonContinue, setButtonContinue] = useState({
    showButtonContinue: true,
    variant: 'primary',
    disabled: true,
  });
  const [modalOpen, setModalOpen] = useState(false);
  const country = localStorage.getItem('country');
  const [selectData, setSelectData] = useState({
    data: [],
    message: '',
    success: false,
  });
  const tabItems = [
    { id: 1, label: 'median_zone' },
    { id: 2, label: 'big_zone' },
    { id: 3, label: 'city' },
    { id: 4, label: 'metropolitan_area' },
  ];
  const tabsTofind: TabItem | undefined = tabItems.find(
    (item) => item.id === tabSelected
  );
  const [rulesToApplyData, setRulesToApplyData] = useState({
    rule_attribute_id: '',
    condition: '',
    value: '',
    approval_rule_ids: [] as string[],
    accept_all: false,
  });
  const [showSaveGlobalRules, setShowSaveGlobalRules] = useState(false);

  useEffect(() => {
    setDate([firstDayOfLastMonth, lastDayOfLastMonth]);
    setIsContinue(false);

    if (selectedPlaces.length > 0) {
      setButtonContinue({
        showButtonContinue: true,
        variant: 'primary',
        disabled: false,
      });
    } else {
      setButtonContinue({
        showButtonContinue: true,
        variant: 'primary',
        disabled: true,
      });
    }
  }, [selectedPlaces]);

  const getIdString = (data: any[]) => {
    const idArray = data.map((item) => item.id);
    const idString = idArray.join(',');
    return idString;
  };

  const handleShowRuleForm = (rule: any) => {
    setSelectedRule(rule);
    setShowModalForm(true);
  };

  useEffect(() => {
    setModifiedObjects([]);
    setShowSaveGlobalRules(false);
    getDataZoneType({ setLoading, tabSelected, setSelectData });
    setShowCreate(false);
  }, [tabSelected]);

  const handleChangeDateString = (dateTochange: Date) => {
    const newDate = new Date(dateTochange);
    const year = newDate.getFullYear();
    const month = newDate.getMonth() + 1;
    const day = newDate.getDate();

    return `${year}-${month}-${day}`;
  };

  const handleGetResume = async () => {
    setLoadingGraph(true);

    try {
      const data = await getApprovalRulesResume({
        country,
        filter_by: tabsTofind?.label,
        ids: getIdString(selectedPlaces),
        start_date: handleChangeDateString(date[0]),
        end_date: date[1]
          ? handleChangeDateString(date[1])
          : handleChangeDateString(date[0]),
        current_date: handleChangeDateString(new Date()),
      });
      setLoadingGraph(false);
      setDataGraph(data);
    } catch (error: any) {
      if (error.response && error.response.status === 404) {
        setDataGraph(initialStateGraph);
        setLoadingGraph(false);
      } else {
        console.error(error);
      }
    }
  };

  const dataToGet = {
    country,
    filter_by: tabsTofind?.label,
    ids: getIdString(selectedPlaces),
    start_date: handleChangeDateString(date[0]),
    end_date: date[1]
      ? handleChangeDateString(date[1])
      : handleChangeDateString(date[0]),
    current_date: handleChangeDateString(new Date()),
  };

  const handleGetGeneralRules = async () => {
    setLoadingRules(false);

    try {
      const response = await getGeneralRulesByIds({
        country,
        filter_by: tabsTofind?.label,
        ids: getIdString(selectedPlaces),
      });
      setGeneralRules(response);
      setShowCreate(true);
      setLoadingRules(true);
    } catch (error: any) {
      setLoadingRules(true);

      if (error.response.status === 404) {
        setShowCreate(false);
        setGeneralRules(initialGeneralRules);
      }

      console.error(error);
    }
  };

  const handleContinueAction = () => {
    const shouldFetchResume =
      selectedTypeRule === 1 && selectedPlaces.length > 0;
    const shouldFetchGeneralRules =
      selectedTypeRule === 2 && selectedPlaces.length > 0;

    if (shouldFetchResume) {
      handleGetResume();
    } else if (shouldFetchGeneralRules) {
      handleGetGeneralRules();
    } else if (selectedTypeRule === 2) {
      setGeneralRules(initialGeneralRules);
    }

    setButtonContinue({
      showButtonContinue: true,
      variant: 'stroke',
      disabled: false,
    });
    setIsContinue(true);
  };

  const prevDateRef = useRef<[Date, Date?]>(date);

  useEffect(() => {
    const datesChanged =
      date[0]?.getTime() !== prevDateRef.current[0]?.getTime() ||
      date[1]?.getTime() !== prevDateRef.current[1]?.getTime();

    if (datesChanged) {
      handleGetResume();
      prevDateRef.current = date;
    }
  }, [date]);

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleFinish = () => {
    window.location.reload();
  };

  const handleSaveChanges = async (type: string) => {
    setShowModal(true);
    let dataToSend: any = {};

    setModalInfo({
      title: 'Estamos despegando',
      title2: 'El cambio puede tomar un tiempo',
      subtitle:
        'En cualquier momento verás la notificación de que el cambio fue realizado o, sí, por el contrario, ocurrió un error.',
      buttonOk: '',
      buttonCancel: '',
      action: () => {},
      // eslint-disable-next-line global-require
      image: require('../../assets/images/ship.gif'),
      showButtons: false,
    });

    const newArray: NewRule[] = modifiedObjects.map((item: any) => {
      const fields: Record<string, string | null> = {};
      item.values.forEach((value: RuleValue) => {
        fields[value.name] = value.value;
      });

      return {
        id: item.id,
        active: item.active,
        fields,
      };
    });

    if (type === '') {
      dataToSend = {
        filter_by: tabsTofind?.label,
        ids: selectedPlaces.map((item) => item.id),
        rules: newArray,
      };
    } else if (type === 'global') {
      dataToSend = {
        filter_by: tabsTofind?.label,
        ids: selectedPlaces.map((item) => item.id),
        ...rulesToApplyData,
      };
    }

    try {
      if (type === '') {
        await putChangeRules(dataToSend);
      } else if (type === 'global') {
        await postGlobalRules(dataToSend);
      }

      setModalInfo({
        title: 'Cambio exitoso',
        title2: 'Has editado los parámetros actuales',
        subtitle:
          'Puedes regresar y revisar que los cambios se hayan aplicado.',
        buttonOk: 'Continuar',
        buttonCancel: '',
        action: handleFinish,
        // eslint-disable-next-line global-require
        image: require('../../assets/images/office-working2.svg'),
        showButtons: true,
        showButtonsCancel: false,
        cancelFunction: true,
        actionToCancel: handleFinish,
      });
    } catch (error) {
      setModalInfo({
        title: 'Cambio no aplicado',
        title2: 'No se pudieron editar los cambios',
        subtitle: '',
        buttonOk: 'Continuar',
        buttonCancel: '',
        action: handleFinish,
        // eslint-disable-next-line global-require
        image: require('../../assets/images/office-working3.svg'),
        showButtons: true,
        showButtonsCancel: false,
      });
    }
  };

  useEffect(() => {
    const newValues: any = rulesValues.filter((item) => item.modified === true);
    setModifiedObjects(newValues);
  }, [rulesValues]);

  const handleGetApprovalRules = async () => {
    try {
      const response = await getApprovalRules();

      if (response.success) {
        const data = response.rules;
        const list: IRulesValues[] = [];

        data.forEach((rule: any) => {
          const values: IValues[] = [];

          if (rule.rule_structure.fields.length > 0) {
            rule.rule_structure.fields.forEach((input: any) => {
              values.push({ name: input.name, value: null, error: false });
            });
          }

          list.push({
            id: rule.id,
            active: 1,
            modified: false,
            values,
          });
        });

        setRulesValues(list);
        setRules(data);
      }
    } catch (error) {
      console.error('Error al obtener reglas de aprobación:', error);
    }
  };

  useEffect(() => {
    setSelectedPlaces([]);
    setTabSelected(0);
    setShowSaveGlobalRules(false);

    if (selectedTypeRule > 0) {
      handleGetApprovalRules();
    }
  }, [selectedTypeRule]);

  const handleDeleteGeneralRules = async (itemValues: any | undefined) => {
    const dataToSend = {
      rule_id: itemValues.id,
      filter_by: tabsTofind?.label,
      ids: getIdString(selectedPlaces),
      approval_rule_id: itemValues.approval_rule_id,
    };

    try {
      await deleteGeneralRulesByIds(dataToSend);
      setModalInfoGeneralRules({
        title: 'Regla eliminada',
        subtitle: 'Las reglas se ha eliminado con éxito',
        buttonOk: 'Continuar',
        buttonCancel: '',
        action: handleFinish,
        // eslint-disable-next-line global-require
        image: require('../../assets/images/office-working2.svg'),
        showButtons: true,
        showButtonsCancel: false,
        cancelFunction: true,
        actionToCancel: handleFinish,
      });
    } catch (error: any) {
      setModalInfoGeneralRules({
        title: 'Regla no eliminada',
        subtitle: '',
        buttonOk: 'Continuar',
        buttonCancel: '',
        action: handleFinish,
        // eslint-disable-next-line global-require
        image: require('../../assets/images/office-working2.svg'),
        showButtons: true,
        showButtonsCancel: false,
        cancelFunction: true,
        actionToCancel: handleFinish,
      });
    }
  };

  const handleExpandGeneralRules = async (itemValues: any) => {
    const dataToSend = {
      filter_by: tabsTofind?.label,
      rule_id: itemValues.id,
      ids: selectedPlaces.map((item) => item.id),
      approval_rule_id: itemValues.approval_rule_id,
      accept_all: Boolean(itemValues.accept_all),
    };

    try {
      const response = await expandGeneralRulesByIds(country, dataToSend);
      setModalInfoGeneralRules({
        title: 'Regla expandida',
        subtitle: response.message,
        buttonOk: 'Continuar',
        buttonCancel: '',
        action: handleFinish,
        actionToCancel: handleFinish,
        // eslint-disable-next-line global-require
        image: require('../../assets/images/office-working2.svg'),
        showButtons: true,
        cancelFunction: true,
        showButtonsCancel: false,
      });
    } catch (error: any) {
      const errorMessage = error.response.data.message;
      setModalInfoGeneralRules({
        title: 'Regla no expandida',
        subtitle: errorMessage,
        buttonOk: 'Continuar',
        buttonCancel: '',
        action: handleFinish,
        // eslint-disable-next-line global-require
        image: require('../../assets/images/office-working2.svg'),
        showButtons: true,
        showButtonsCancel: false,
        cancelFunction: true,
        actionToCancel: handleFinish,
      });
    }
  };

  const handleShowModalGlobalRule = (
    openModal: boolean,
    isExpand: boolean,
    item: any
  ) => {
    setShowModalGlobalRules(openModal);

    if (isExpand) {
      setModalInfoGeneralRules({
        title: 'Expandiendo reglas',
        subtitle: '¿Está seguro que desea expandir esta regla?',
        buttonOk: 'Sí, expandir',
        buttonCancel: 'No, cancelar',
        action: () => handleExpandGeneralRules(item),
        // eslint-disable-next-line global-require
        image: require('../../assets/images/office-working.svg'),
        showButtons: true,
      });
    } else {
      setModalInfoGeneralRules({
        title: 'Eliminar Regla',
        subtitle: '¿Está seguro que desea eliminar esta regla?',
        buttonOk: 'Sí, eliminar',
        buttonCancel: 'No, cancelar',
        action: () => handleDeleteGeneralRules(item),
        // eslint-disable-next-line global-require
        image: require('../../assets/images/office-working.svg'),
        showButtons: true,
      });
    }
  };

  const rulesToApply = (
    feature: string,
    condition: string,
    complement: string,
    rule: any,
    decision: string,
    error: boolean
  ) => {
    setRulesToApplyData({
      rule_attribute_id: feature,
      condition,
      value: complement.toLocaleString().replace(/,/g, ''),
      approval_rule_ids: rule.map((item: { id: any }) => item.id),
      accept_all: decision === '1',
    });

    if (
      feature !== '' &&
      feature !== null &&
      condition !== '' &&
      condition !== null &&
      complement !== '' &&
      complement !== null &&
      rule.length > 0 &&
      decision !== '' &&
      decision !== null &&
      error === false
    ) {
      setShowSaveGlobalRules(true);
    } else {
      setShowSaveGlobalRules(false);
    }
  };

  return (
    <>
      <TitleContainer>Reglas de aprobación</TitleContainer>
      <ContainerQuotaLogic>
        <RuleTypeTab
          selectedTypeRule={selectedTypeRule}
          setSelectedTypeRule={setSelectedTypeRule}
        />
        {selectedTypeRule !== 0 && (
          <>
            <Divisor />
            <SelectTypeZoneMx
              tabSelected={tabSelected}
              setTabSelected={setTabSelected}
            />
          </>
        )}
        {tabSelected !== 0 && (
          <>
            <Divisor />
            <SelectArea
              selectedData={selectData}
              loading={loading}
              selectedPlaces={selectedPlaces}
              setSelectedPlaces={setSelectedPlaces}
              buttonProperties={ButtonContinue}
              setContinueAction={handleContinueAction}
            />
          </>
        )}

        {/* COMMENT: Contenido para reglas por zona */}
        {selectedTypeRule === 1 && (
          // eslint-disable-next-line react/jsx-no-useless-fragment
          <>
            {selectedPlaces.length > 0 && isContinue && (
              <>
                <Divisor />
                <BusinessProjection
                  date={date}
                  setDate={setDate}
                  data={dataGraph}
                  loading={loadingGraph}
                />
                <RulesList
                  rules={rules}
                  rulesValues={rulesValues}
                  setRulesValues={setRulesValues}
                  handleShowRuleForm={handleShowRuleForm}
                  description={dataToGet}
                  tabSelected={tabSelected}
                  timer={isContinue}
                />
              </>
            )}
          </>
        )}

        {/* COMMENT: Contenido para reglas globales */}
        {selectedTypeRule === 2 && (
          // eslint-disable-next-line react/jsx-no-useless-fragment
          <>
            {selectedPlaces.length > 0 && isContinue && (
              <>
                <Divisor />
                <CurrentGlobalRules
                  rules={generalRules.rules}
                  total_zones={generalRules.total_zones}
                  handleShowModal={handleShowModalGlobalRule}
                  setShowCreate={setShowCreate}
                  loadingRules={loadingRules}
                />
                {showCreate && (
                  <>
                    <Divisor />
                    <ChangeRulesToApply
                      rulesToApply={rulesToApply}
                      rules={rules}
                    />
                  </>
                )}
              </>
            )}
          </>
        )}
      </ContainerQuotaLogic>

      {showModalForm && (
        <RuleSidebarForm
          setShowModalForm={setShowModalForm}
          selectedRule={selectedRule}
          rulesValues={rulesValues}
          setRulesValues={setRulesValues}
          selectedPlaces={selectedPlaces}
        />
      )}
      {modifiedObjects.length > 0 && (
        <FooterSave
          handleOpenModal={() => handleSaveChanges('')}
          handleOpenModalProjection={() => {}}
        />
      )}
      {selectedTypeRule === 2 && showSaveGlobalRules && (
        <FooterSave
          handleOpenModal={() => handleSaveChanges('global')}
          handleOpenModalProjection={() => {}}
        />
      )}
      {modalOpen && (
        <CustomModal
          onClose={handleCloseModal}
          onSave={() => handleSaveChanges('')}
        />
      )}
      <ModalConfirmation
        showModal={showModal}
        setShowModal={setShowModal}
        modalInfo={modalInfo}
        id={1}
        showButtons={modalInfo.showButtons}
        showButtonsCancel={modalInfo.showButtonsCancel}
        cancelFunction={modalInfo.cancelFunction}
        actionToCancel={modalInfo.actionToCancel}
      />
      <ModalConfirmation
        showModal={showModalGlobalRules}
        setShowModal={setShowModalGlobalRules}
        modalInfo={modalInfoGeneralRules}
        id={1}
        showButtons={modalInfoGeneralRules.showButtons}
        showButtonsCancel={modalInfoGeneralRules.showButtonsCancel}
        cancelFunction={modalInfoGeneralRules.cancelFunction}
        actionToCancel={modalInfoGeneralRules.actionToCancel}
      />
    </>
  );
};

export default NewApprovalRules;
