import {
  getBigZonesMx,
  getCitiesMx,
  getMedianZonesMx,
  getMetropolitanAreaMx,
} from '../apis/selectZoneMx/selectZone';

interface IGetDataZoneType {
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  tabSelected: number;
  setSelectData: React.Dispatch<React.SetStateAction<any>>;
}

const getDataZoneType = async ({
  setLoading,
  tabSelected,
  setSelectData,
}: IGetDataZoneType) => {
  try {
    const dataFetchers: {
      [key: number]: () => Promise<any>;
    } = {
      1: getMedianZonesMx,
      2: getBigZonesMx,
      3: getCitiesMx,
      4: getMetropolitanAreaMx,
    };

    const selectedFunction = dataFetchers[tabSelected];

    if (selectedFunction) {
      setLoading(true);
      const newData = await selectedFunction();

      if (newData.length > 0 && tabSelected === 1) {
        const list = newData.data.sort((a: any, b: any) => {
          const [aa, bb] = [a, b].map((string: any) => {
            return {
              letters: string.label.match(/\D+/)[0],
              digits: string.label.match(/\d+/)[0],
            };
          });
          return aa.letters.localeCompare(bb.letters) || aa.digits - bb.digits;
        });
        newData.data = list;
      }

      setSelectData(newData);
      setLoading(false);
    }
  } catch (error) {
    console.error(error);
    setLoading(false);
  }
};

export default getDataZoneType;
