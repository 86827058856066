import { FC, useEffect, useState } from 'react';
import { Doughnut } from 'react-chartjs-2';
import 'chart.js/auto';

import {
  TitleAndSubtitle,
  TitleZone,
} from '../../../Pricing/components/Opportunities/styles';
import {
  DescriptionOne,
  DescriptionTwo,
  GraphicContainer,
  NoResultsContainer,
  ReasonsIndicator,
  ReasonsIndicatorsContainer,
  TextMiddle,
  WrapperRejectionReasonsGraphic,
} from './style';
import LocationIcon from './assets/location.svg';
import ApprovedIcon from './assets/beenhere.svg';
import RejectedIcon from './assets/block.svg';
import NotResultsForReasons from './assets/404.svg';
import { RejectionReasonsGraphicProps } from './model';
import { GRAPHIC_COLORS } from './utils';

const RejectionReasonsGraphic: FC<RejectionReasonsGraphicProps> = ({
  first,
  city,
  subtitle,
  rejectionData,
  resultsForReasons,
}) => {

  const [data, setData] = useState({
    labels: [],
    datasets: [
      {
        backgroundColor: GRAPHIC_COLORS,
        borderWidth: 1,
        data: [],
      },
    ],
    rejection_percent: 0,
  });

  useEffect(() => {
    if (rejectionData) {
      const newdata = {
        labels: rejectionData.labels,
        datasets: [
          {
            backgroundColor: GRAPHIC_COLORS,
            borderWidth: 1,
            data: rejectionData.datasets[0].data,
            datalabels: {
              display: false,
            },
          },
        ],
        rejection_percent: rejectionData?.rejected_reasons.find(
          (element: any) => element.label === 'Aprobado'
        )?.value,
      };

      setData(newdata);
    }
  }, [rejectionData]);

  const options = {
    plugins: {
      legend: { display: false },
    },
  };

  return (
    <WrapperRejectionReasonsGraphic first={first}>
      <TitleZone>
        <img src={LocationIcon} alt="pin del mapa" />
        <TitleAndSubtitle>
          <div className="title">{city}</div>
          <div className="subtitle">{subtitle}</div>
        </TitleAndSubtitle>
      </TitleZone>
      {!resultsForReasons ? (
        <NoResultsContainer>
          <img className="icon" src={NotResultsForReasons} />
          <DescriptionOne>
            Sí existen razones de rechazo para esta zona, pero no encontramos
            una coincidencia para la que seleccionaste
          </DescriptionOne>
          <DescriptionTwo>
            Si identificas que es un error del portal, repórtalo al equipo de
            Spacestation.
          </DescriptionTwo>
        </NoResultsContainer>
      ) : (
        <>
          <ReasonsIndicatorsContainer>
            <ReasonsIndicator first>
              <img src={ApprovedIcon} alt="" />
              <div className="text">
                <span className="title">Aprobados</span>
                <span className="indicator">{rejectionData?.approved_count}</span>
              </div>
            </ReasonsIndicator>
            <ReasonsIndicator first={false}>
              <img src={RejectedIcon} alt="" />
              <div className="text">
                <span className="title">Rechazados</span>
                <span className="indicator">{rejectionData?.rejected}</span>
              </div>
            </ReasonsIndicator>
          </ReasonsIndicatorsContainer>
          <GraphicContainer>
            <div className="graphic">
              {rejectionData?.hasOwnProperty('approved') && (
                <TextMiddle>
                  Porcentaje de aprobación
                  <div className="value">{rejectionData?.approved}%</div>
                </TextMiddle>
              )}

              <Doughnut data={data} options={options} />
            </div>
          </GraphicContainer>
        </>
      )}
    </WrapperRejectionReasonsGraphic>
  );
};

export default RejectionReasonsGraphic;
