import styled from 'styled-components';
import { robotoRegular, monserratRegular } from '@habitech/shared';

const ContainerNotFound = styled.div`
  display: grid;
  grid-template-columns: repeat(9, 1fr);
  .container-image {
    padding-top: 38px;
    grid-column: 3/5;
  }
  .container-text {
    grid-column: 5/9;
    .title {
      padding-top: 69px;
      font-family: ${monserratRegular};
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 32px;
    }
    .subtitle {
      font-family: ${robotoRegular};
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
    }
  }
`;

export default ContainerNotFound;
