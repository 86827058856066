import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ContainerWrapper } from '../../../DiscountEngine/components/ContainerDiscountEngine/style';
import CardWrapperRule, {
  ReturnLinkText,
  ReturnLinkWrapper,
  SubtitleWrapper,
  TitleWrapper,
} from './style';
import EditImg from '../../../../assets/icons/color.svg';
import { Separator } from '../../../ApprovalRules/components/PoliticsAndTolerance/styles';
import {
  TDataForm,
  TModalInformation,
  TOptionsMa,
  TValidationMessage,
} from '../../model';
import { AlertProps } from '../../../ApprovalRules/components/PoliticsAndTolerance/model';
import { useAppDispatch } from '../../../../redux/hooks';
import { alertStoreActions } from '../../../../redux/reducers/alertReducer';
import FormEditRules from '../FormEditRules';
import RulesTable from '../RulesTable';
import {
  getAllCities,
  getMetropolitanArea,
} from '../../../../apis/selectZone/areas';
import {
  deleteDiscountEngineRules,
  getDiscountEngineRules,
  postDiscountEngineRules,
} from '../../../../apis/discountEngine/discountEngine';
import arrowLeft from '../../../../static/assets/icons/arrow-left.svg';
import ModalConfirmation from '../../../../components/ModalConfirmation';
import OfficeWorking from '../../../../assets/images/office-working.svg';

const EditRule = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { updateAlert } = alertStoreActions;
  const [showModal, setShowModal] = useState(false);
  const [ruleId, setRuleId] = useState(0);
  const [showMore, setShowMore] = useState(false);
  const [loaded, setLoaded] = useState(true);
  const [listMa, setListMa] = useState<TOptionsMa[]>([{ label: '', id: 0 }]);
  const [listCities, setListCities] = useState<TOptionsMa[]>([
    { label: '', id: 0 },
  ]);
  const [maOptions, setMaOptions] = useState<TOptionsMa[]>([
    { label: '', id: 0 },
  ]);
  const [validation, setValidation] = useState<TValidationMessage>({
    error: false,
    message: '',
  });
  const [modalInfo, setModalInfo] = useState<TModalInformation>({
    title: '',
    subtitle: '',
    buttonOk: '',
    buttonCancel: '',
    action: () => {},
    image: '',
    showButtons: true,
  });
  const [listRules, setListRules] = useState<TDataForm[]>([]);
  const [dataForm, setDataForm] = useState<TDataForm>({
    id: 0,
    rule_item: '',
    rule_condition: '',
    expected_value: '',
    points_discount: '',
    zone: '',
    am_city: 0,
  });

  const elementOptions = [
    { label: 'Ask price', value: 'last_ask_price' },
    { label: 'Estrato', value: 'estrato' },
    { label: 'Área', value: 'area' },
    { label: 'Antigüedad', value: 'anos_antiguedad' },
    { label: 'No. de piso', value: 'num_piso' },
    { label: 'Ascensor / Elevador', value: 'num_ascensores' },
    { label: 'Parqueadero', value: 'garajes' },
  ];

  const todoOptions = [
    { label: 'Igual a...', value: 'equal_to' },
    { label: 'Menor a...', value: 'less_than' },
    { label: 'Mayor a...', value: 'greater_than' },
    { label: 'Mayor o igual a...', value: 'greater_equal_to' },
    { label: 'Menor o igual a...', value: 'less_equal_to' },
  ];

  const zoneOptions = [
    { label: 'General', value: 'general' },
    { label: 'Área metropolitana', value: 'area_metropolitana_id' },
    { label: 'Ciudad', value: 'ciudad_id' },
  ];

  const elementChange = (value: string) => {
    setDataForm({ ...dataForm, rule_item: value });
  };

  const todoChange = (value: string) => {
    setDataForm({ ...dataForm, rule_condition: value });
  };

  const handleInputRule = (value: number) => {
    setDataForm({ ...dataForm, expected_value: String(value) });

    switch (dataForm.rule_item) {
      case 'last_ask_price':
        if (value >= 50000000 && value <= 550000000) {
          setValidation({ error: false, message: '' });
        } else {
          setValidation({
            error: true,
            message: 'Rango 50,000,000 - 550,000,000',
          });
        }

        break;

      case 'estrato':
        if (value >= 1 && value <= 6) {
          setValidation({ error: false, message: '' });
        } else {
          setValidation({
            error: true,
            message: 'Rango 1 - 6',
          });
        }

        break;

      case 'area':
        if (value >= 20 && value <= 300) {
          setValidation({ error: false, message: '' });
        } else {
          setValidation({
            error: true,
            message: 'Rango 20 - 300',
          });
        }

        break;

      case 'anos_antiguedad':
      case 'num_piso':
        if (value >= 0 && value <= 50) {
          setValidation({ error: false, message: '' });
        } else {
          setValidation({
            error: true,
            message: 'Rango 0 - 50',
          });
        }

        break;

      case 'num_ascensores':
      case 'garajes':
        if (value >= 0 && value <= 10) {
          setValidation({ error: false, message: '' });
        } else {
          setValidation({
            error: true,
            message: 'Rango 0 - 10',
          });
        }

        break;

      default:
        break;
    }
  };

  const handleInputRulePoints = (value: string) => {
    setDataForm({ ...dataForm, points_discount: value });
  };

  const handleZoneType = (value: string) => {
    switch (value) {
      case 'area_metropolitana_id':
        setLoaded(false);
        getMetropolitanArea().then((response) => {
          if (response.success) {
            setLoaded(true);
            setMaOptions(response.data);
          }
        });
        break;

      case 'ciudad_id':
        setLoaded(false);
        getAllCities().then((response) => {
          if (response.success) {
            setLoaded(true);
            setMaOptions(response.data);
          }
        });
        break;

      default:
        break;
    }

    setDataForm({ ...dataForm, zone: value });
  };

  const handleAmCityChange = (value: number) => {
    setDataForm({ ...dataForm, am_city: value });
  };

  const saveRuleData = () => {
    const newRules = [
      {
        rule_item: dataForm.rule_item,
        rule_condition: dataForm.rule_condition,
        expected_value: Number(dataForm.expected_value),
      },
    ];

    const compoundRule = {
      rule_item: dataForm.zone,
      rule_condition: 'equal_to',
      expected_value: Number(dataForm.am_city),
    };

    if (showMore) {
      newRules.push(compoundRule);
    }

    const dataToSave = {
      rules: newRules,
      points_discount: Number(dataForm.points_discount),
    };
    setModalInfo({
      title: 'Estamos despegando',
      title2: 'El cambio puede tomar un tiempo',
      subtitle: '',
      buttonOk: '',
      buttonCancel: '',
      action: () => {},
      // eslint-disable-next-line global-require
      image: require('../../../../assets/images/ship.gif'),
      showButtons: false,
    });
    postDiscountEngineRules(dataToSave)
      .then((response) => {
        if (response.success) {
          setListRules([...listRules, dataForm]);
          setDataForm({
            id: 0,
            rule_item: '',
            rule_condition: '',
            expected_value: '',
            points_discount: '',
            zone: '',
            am_city: 0,
          });

          const alertData: AlertProps = {
            type: 'success',
            text: 'Se ha añadido una nueva regla global con éxito',
            showAlert: true,
          };

          dispatch(updateAlert(alertData));

          setShowModal(false);
        }
      })
      .catch((error) => {
        setShowModal(false);

        const alertData: AlertProps = {
          type: 'error',
          text: error.response.data.message,
          showAlert: true,
        };

        dispatch(updateAlert(alertData));
      });
  };

  const saveRule = () => {
    setModalInfo({
      title: '¿Estás seguro de añadir esta regla?',
      subtitle:
        'Esta se agregará a las reglas que actualmente vigentes en la zona y podrás consultarla en las reglas globales y NID',
      buttonOk: 'Si, añadir regla',
      buttonCancel: 'No, cancelar',
      action: saveRuleData,
      image: OfficeWorking,
      showButtons: true,
    });
    setShowModal(true);
  };

  const deleteRule = (id: number) => {
    deleteDiscountEngineRules(id).then((response) => {
      const list = listRules.filter((item) => item.id !== id);
      setListRules(list);

      const alertData: AlertProps = {
        type: 'success',
        text: 'Se ha borrado la regla global con éxito',
        showAlert: true,
      };
      dispatch(updateAlert(alertData));

      setShowModal(false);
    });
  };

  const handleDeleteClick = (id: number) => {
    setRuleId(id);
    setModalInfo({
      title: '¿Estás seguro?',
      subtitle:
        'Al borrar está regla afectarás los descuentos globales que aplica Habi ',
      buttonOk: 'Si, borrar regla',
      buttonCancel: 'No, mantener',
      action: () => {
        deleteRule(id);
      },
      image: OfficeWorking,
      showButtons: true,
    });
    setShowModal(true);
  };

  const getDiscountGlobalRules = () => {
    getDiscountEngineRules().then((response) => {
      if (response.success) {
        const list: TDataForm[] = [];
        response.data.map(
          (item: {
            id: number;
            rules: string | any[];
            value: string | number;
          }) => {
            const data: TDataForm = {
              id: 0,
              rule_item: '',
              rule_condition: '',
              expected_value: '',
              points_discount: '',
              zone: '',
              am_city: 0,
            };

            data.id = item.id;
            data.rule_item = item.rules[0].rule_item;
            data.rule_condition = item.rules[0].rule_condition;
            data.expected_value =
              item.rules[0].expected_value.toLocaleString('en-US');
            data.points_discount = String(item.value);

            if (item.rules.length > 1) {
              data.zone = item.rules[1].rule_item;
              data.am_city = item.rules[1].expected_value;
            }

            list.push(data);
          }
        );

        setListRules(list);
      }
    });
  };

  useEffect(() => {
    getDiscountGlobalRules();
  }, []);

  useEffect(() => {
    getMetropolitanArea().then((response) => {
      if (response.success) {
        setListMa(response.data);
      }
    });

    getAllCities().then((response) => {
      if (response.success) {
        setListCities(response.data);
      }
    });
  }, []);

  return (
    <ContainerWrapper>
      <ReturnLinkWrapper onClick={() => navigate('/motor-de-descuento')}>
        <img src={arrowLeft} alt="" />
        <ReturnLinkText>Regresar a las reglas globales</ReturnLinkText>
      </ReturnLinkWrapper>
      <CardWrapperRule>
        <TitleWrapper>
          <img src={EditImg} alt="EditImg" />
          <div className="title">Modificar reglas globales</div>
        </TitleWrapper>
        <Separator />

        <SubtitleWrapper>
          <div className="title">Cambio de reglas a aplicar</div>
          <div className="subtitle">*Todos los campos son obligatorios</div>
        </SubtitleWrapper>

        <FormEditRules
          elementOptions={elementOptions}
          todoOptions={todoOptions}
          zoneOptions={zoneOptions}
          dataForm={dataForm}
          elementChange={elementChange}
          todoChange={todoChange}
          handleInputRule={handleInputRule}
          handleInputRulePoints={handleInputRulePoints}
          saveRule={saveRule}
          validation={validation}
          handleZoneType={handleZoneType}
          showMore={showMore}
          setShowMore={setShowMore}
          maOptions={maOptions}
          loaded={loaded}
          handleAmCityChange={handleAmCityChange}
        />

        <Separator />

        <SubtitleWrapper>
          <div className="title">Reglas globales vigentes</div>
        </SubtitleWrapper>

        <RulesTable
          listRules={listRules}
          elementOptions={elementOptions}
          todoOptions={todoOptions}
          handleDeleteClick={handleDeleteClick}
          zoneOptions={zoneOptions}
          listMa={listMa}
          listCities={listCities}
        />
      </CardWrapperRule>

      <ModalConfirmation
        showModal={showModal}
        setShowModal={setShowModal}
        modalInfo={modalInfo}
        id={ruleId}
        showButtons={modalInfo.showButtons}
      />
    </ContainerWrapper>
  );
};

export default EditRule;
