import { robotoRegular } from '@habitech/shared';
import styled from 'styled-components';

const SelectMultiWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  margin-right: 32px;
  .MuiOutlinedInput-root {
    padding: 0px !important;
  }
  .MuiCircularProgress-root {
    margin-right: 40px !important;
  }
  .MuiAutocomplete-input {
    font-size: 14px;
    height: 34px;
    padding: 2px 6px 2px 12px !important;
    border: 1px solid #949494;
    border-radius: 4px;
  }
  .MuiAutocomplete-endAdornment {
    top: calc(50%);
  }
  .MuiSvgIcon-root {
    width: 2.5rem;
    height: 2.5rem;
  }
  .label-description {
    display: flex;
    font-family: ${robotoRegular};
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #252129;
    padding: 0 0 0px 4px;
    margin-bottom: 4px;
  }
`;

export default SelectMultiWrapper;
