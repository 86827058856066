import { FC } from 'react';

import starsStats from '../../../../static/assets/icons/stars_stats.svg';
import DateDisclaimerContainer from './style';

interface DateDisclaimerProps {
  date: Date;
  margin?: boolean;
}

const DateDisclaimer: FC<DateDisclaimerProps> = ({ date, margin = true }) => {
  const monthFormatter = new Intl.DateTimeFormat('es', { month: 'long' });
  const newDate = new Date(date);
  const month = monthFormatter.format(newDate);
  const year = newDate.getFullYear();

  return (
    <DateDisclaimerContainer className={`${!margin ? 'no-margin' : ''}`}>
      <div className="text">
        <img src={starsStats} alt="" />

        <span className="disclaimer-text">
          Mostrando data actualizada a {month} de {year}
        </span>
      </div>
    </DateDisclaimerContainer>
  );
};

export default DateDisclaimer;
