import HomeMoney from '../../../../../assets/icons/HomeMoney.svg';
import { formatMoney } from '../../../../../utils/formatDecimal';

interface IVisNoVis {
  maxPriceVis?: number;
  maxAntiquityVis?: number;
  maxPriceNoVis?: number;
  minPriceNoVis?: number;
  maxAntiquityNoVis?: number;
}

const VisNoVis: React.FC<IVisNoVis> = ({
  maxPriceVis,
  maxAntiquityVis,
  maxPriceNoVis,
  maxAntiquityNoVis,
  minPriceNoVis,
}) => {
  return (
    <>
      <div className="card-vis">
        <div className="title borderBlue">
          <img src={HomeMoney} alt="area_img" className="image" />
          <span className="text">Vivienda Vis</span>
        </div>
        <div className="maximum">
          <div className="price-max">
            <div className="title-max ">Precio máximo</div>
            <div className="subtitle-max">{formatMoney(maxPriceVis)}</div>
          </div>
          <div className="price-max">
            <div className="title-max ">Antigüedad Máxima</div>
            <div className="subtitle-max">{maxAntiquityVis} años</div>
          </div>
        </div>
      </div>
      <div className="card-vis">
        <div className="title borderBlue">
          <img src={HomeMoney} alt="area_img" className="image" />
          <span className="text">Vivienda No Vis</span>
        </div>
        <div className="maximum borderBlue padding-maximum ">
          <div className="price-max">
            <div className="title-max ">Precio máximo</div>
            <div className="subtitle-max">{formatMoney(minPriceNoVis)}</div>
          </div>
          <div className="price-max">
            <div className="title-max ">Precio Tope</div>
            <div className="subtitle-max">{formatMoney(maxPriceNoVis)}</div>
          </div>
        </div>
        <div className="maximum">
          <div className="price-max">
            <div className="title-max ">Antigüedad Máxima</div>
            <div className="subtitle-max">{maxAntiquityNoVis} años</div>
          </div>
        </div>
      </div>
    </>
  );
};

export default VisNoVis;
