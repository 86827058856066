import styled from 'styled-components';
import {
  blueLinks,
  electricPurple500,
  robotoRegular,
  scarpaGray500,
  scarpaGray600,
  scarpaGray700,
  scarpaGray800,
  skyBlue50,
} from '@habitech/shared';

export const ContainerCurrentGlobalRules = styled.div`
  display: flex;
`;
export const ColumnDescription = styled.div`
  .size {
    width: 130px;
  }
  .subtitle {
    font-family: ${robotoRegular};
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: ${scarpaGray600};
    width: 167px;
    margin-right: 64px;
  }
  .message-info {
    font-family: ${robotoRegular};
    font-weight: 400;
    font-size: 12px;
    color: ${scarpaGray500};
    margin-top: 4px;
  }
`;
export const ContainerRules = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;
export const CardRule = styled.div`
  display: flex;
  width: 642px;
  padding: 12px;
  justify-content: space-between;
  align-items: flex-start;
  border-radius: 8px;
  background: ${skyBlue50};
  margin-bottom: 8px;
  .description {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex: 1 0 0;
    margin-left: 20px;
    .title {
      color: ${scarpaGray800};
      font-family: ${robotoRegular};
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px;
      margin-bottom: 12px;
    }
    .subtitle {
      color: ${scarpaGray700};
      font-family: ${robotoRegular};
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      margin-bottom: 4px;
    }
    .small-letter {
      color: ${scarpaGray500};
      font-family: ${robotoRegular};
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
`;
export const CardRuleEmpty = styled.div`
  align-items: center;
  background: ${skyBlue50};
  border-radius: 8px;
  display: flex;
  gap: 16px;
  justify-content: center;
  padding: 32px 40px;
  width: 586px;
  .description {
    display: flex;
    padding: 0px 40px;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    .value {
      color: ${scarpaGray800};
      font-family: ${robotoRegular};
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px;
      margin-bottom: 8px;
    }
  }
  .button-container {
    width: 100%;
    text-align: left;
  }
`;
export const StyledButtonContinue = styled.button`
  appearance: none;
  background: none;
  border: none;
  color: ${blueLinks};
  cursor: pointer;
  margin: 0;
  outline: none;
  padding: 0;
  text-decoration-line: underline;
  .value-text {
    font-family: ${robotoRegular};
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
  }
`;

const ImageButton = styled.button`
  border: none;
  padding: 0;
  background: none;
  cursor: pointer;
  outline: none;

  img {
    width: 100%;
    height: auto;
  }
`;

export default ImageButton;

export const ExpandButton = styled.button`
  align-items: center;
  background: ${skyBlue50};
  border-radius: 6px;
  border: 1px solid ${electricPurple500};
  cursor: pointer;
  display: flex;
  gap: 8px;
  height: 40px;
  justify-content: center;
  margin-top: 20px;
  padding: 8px 24px;
  .text {
    color: ${electricPurple500};
    text-align: center;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
`;
