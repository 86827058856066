// Libraries
import { Button } from '@habitech/call-to-action';
import { useNavigate } from 'react-router-dom';

// Images
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';

// Styles
import WrapperReturnLink from './style';

// Interfaces
interface ReturnLinkProps {
  link: string;
  text: string;
}

const ReturnLink: React.FC<ReturnLinkProps> = ({ link, text }) => {
  const navigate = useNavigate();

  return (
    <WrapperReturnLink>
      <Button
        dataId="comeback"
        id="comeback"
        variant="ghost"
        onClick={() => navigate(link)}
        iconLeft={<ArrowBackIosNewRoundedIcon className="icon" />}
      >
        {text}
      </Button>
    </WrapperReturnLink>
  );
};

export default ReturnLink;
