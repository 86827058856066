import {
  electricPurple000,
  electricPurple200,
  electricPurple800,
  monserratRegular,
  robotoRegular,
  scarpaGray600,
} from '@habitech/shared';
import styled from 'styled-components';

import {
  footerBlue,
  purpleMedium,
  scarpaGray800,
  secondaryAA,
  secondaryFont,
} from '../../../../styles/stylesConstants';

export const InfoCardsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(10, 1fr);
  gap: 24px;

  & > div:nth-child(1) {
    grid-column: 1 / 5;
  }

  & > div:nth-child(2) {
    grid-column: 5 / 11;
  }
`;

export const InfoCardHeader = styled.div`
  display: flex;
  justify-content: space-between;

  .header-big-zone {
    padding: 3px 8px;
    background: ${electricPurple000};
    border-radius: 2px;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
    color: ${purpleMedium};
    text-transform: capitalize;
  }

  .header-left {
    display: flex;
    align-items: center;

    p {
      font-weight: 600;
      color: ${scarpaGray800};
      margin: 0 0 0 12px;
      height: 14px;
    }
  }
`;

export const InfoZoneName = styled.h4`
  font-size: 22px;
  font-weight: 700;
  color: ${scarpaGray800};
  margin: 12px 0 8px 0;
`;

export const InfoZoneId = styled.p`
  font-family: ${secondaryFont};
  font-size: 14px;
  color: ${secondaryAA};
  margin: 0 0 6px 0;
`;

export const InfoMediumZone = styled.p`
  margin: 0;
  color: ${scarpaGray800};
  margin-bottom: 4rem;
`;

export const BehaviorCardHeader = styled.div`
  display: flex;
  align-items: center;

  p {
    font-size: 14px;
    font-weight: 600;
    color: ${scarpaGray800};
    margin: 0 0 0 12px;
  }
`;

export const BehaviorCardList = styled.div`
  display: grid;
  grid-auto-flow: column;
  margin-top: 20px;

  & > div {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 4px 8px;
    min-width: 91px;

    &:not(:first-child) {
      padding-left: 12px;
      border-left: 2px solid ${electricPurple200};
    }
    &:first-child {
      padding-left: 0;
    }
  }

  p {
    font-family: ${robotoRegular};
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    color: ${scarpaGray600};
    margin: 0;
    margin-bottom: 11px;
  }
  span {
    font-family: ${monserratRegular};
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 22px;
    color: ${scarpaGray800};
  }
`;

export const ZoneFooter = styled.div`
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 100%;
  background: ${footerBlue};
  border-bottom-right-radius: 16px;
  border-bottom-left-radius: 16px;
  height: 35px;
  display: flex;
  align-items: center;
  .content {
    margin-left: 20px;
    font-family: ${robotoRegular};
    font-weight: 600;
    font-size: 14px;
    color: ${electricPurple800};
    display: flex;
    img {
      margin-right: 10px;
    }
  }
`;
