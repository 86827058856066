import { TextField } from '@habitech/keychain-atoms-inputs';
import { Controller } from 'react-hook-form';
import { InputFormProps } from './model';

const InputForm: React.FC<InputFormProps> = ({
  control,
  name,
  errors,
  iconLeft,
  id,
  type,
  originType,
  autoFocus,
  placeHolder,
  label,
  defaultValue,
}) => {
  return (
    <div>
      <Controller
        defaultValue={defaultValue}
        name={name}
        control={control}
        render={({ field: { onChange, onBlur } }) => (
          <TextField
            defaultValue={defaultValue}
            originType={originType}
            name={name}
            errorMessage={errors?.[name]?.message}
            iconLeft={iconLeft}
            dataId={id}
            id={id}
            label={label}
            autoFocus={autoFocus}
            placeHolder={placeHolder}
            type={type}
            onBlur={onBlur}
            autoComplete={false}
            onChange={(inputname, value) => onChange(value)}
            error={errors?.[name]?.message}
          />
        )}
      />
    </div>
  );
};

export default InputForm;
