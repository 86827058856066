import { scarpaGray600, white } from '@habitech/shared';
import styled from 'styled-components';
import { principalFont } from '../../../../styles/stylesConstants';

export const WrapperContainer = styled.div`
  margin-bottom: 24px;
`;
export const TitleContainer = styled.div`
  font-family: ${principalFont};
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: ${scarpaGray600};
  margin-bottom: 16px;
  margin-top: 2rem;
`;

export const ContentWrapper = styled.div`
  background: ${white};
  box-shadow: 0px 0px 1px rgba(48, 49, 51, 0.05),
    0px 2px 4px rgba(48, 49, 51, 0.1);
  border-radius: 6px;
  padding: 25px 24px 54px 24px;
  display: grid;
`;
