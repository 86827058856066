import { useEffect, useState } from 'react';
import { BrowserRouter, RouteObject, useRoutes } from 'react-router-dom';
// eslint-disable-next-line import/no-named-as-default
import { useDispatch } from 'react-redux';
import localRoutes, {
  routesMapMX,
  routesApprover,
  routesApproverMX,
} from './routesMap';
import ModalConfirmation from '../components/ModalConfirmation';
import imageSession from '../assets/images/session.svg';
import { TOKEN } from '../shared/constants';
import { authFresh, authRevoke } from '../apis/auth/auth.api';
import { TModalInformation } from '../pages/RulesEditor/model';
import { userStoreActions } from '../redux/reducers/userReducer';

const Routes = () => {
  const [routes, setRoutes] = useState<RouteObject[]>([]);
  const country = localStorage.getItem('country');

  const handleSelectRol = () => {
    const rol = localStorage.getItem('ROL');

    if (rol?.includes('equipo_bo')) {
      const list = country === 'MX' ? routesMapMX : localRoutes;
      setRoutes(list);
    } else if (country === 'MX') {
      setRoutes(routesApprover);
    } else {
      setRoutes(routesApproverMX);
    }
  };

  useEffect(() => {
    handleSelectRol();
  }, [country]);

  return useRoutes(routes);
};

const Router = () => {
  const [showModal, setShowModal] = useState(false);
  const [newSession, setNewSession] = useState(false);
  const [modalInfo, setModalInfo] = useState<TModalInformation>({
    title: '',
    title2: '',
    subtitle: '',
    buttonOk: '',
    buttonCancel: '',
    action: () => {},
    image: '',
    showButtons: true,
  });
  const user = JSON.parse(String(localStorage.getItem('user')));
  const country = localStorage.getItem('country');
  const dispatch = useDispatch();

  const handleRevokeToken = async () => {
    try {
      await authRevoke(TOKEN?.RefreshToken, country);
    } catch (error) {
      console.error(error);
    }

    window.history.replaceState(null, '', '/');
    localStorage.clear();
  };

  const logout = () => {
    setShowModal(false);
    dispatch(userStoreActions.clearUser());
    handleRevokeToken();
  };

  const refreshToken = () => {
    const token = TOKEN;
    const userName = user?.email.split('@')[0];
    setModalInfo({
      title: 'Estamos extendiendo la sesión',
      title2: 'Esto puede tomar un tiempo',
      subtitle: '',
      buttonOk: '',
      buttonCancel: '',
      action: () => {},
      // eslint-disable-next-line global-require
      image: require('../assets/images/ship.gif'),
      showButtons: false,
    });
    authFresh(token.RefreshToken, String(country), String(userName)).then(
      (response) => {
        if (response.status) {
          token.AccessToken = response.data.AccessToken;
          token.IdToken = response.data.IdToken;
          token.success = response.data.success;

          localStorage.removeItem('refresh');
          localStorage.setItem('TOKEN', JSON.stringify(token));
          setNewSession(!newSession);
          setShowModal(false);
        }
      }
    );
  };

  useEffect(() => {
    if (user) {
      let sessionTimeout: any = null;
      const secondsToExpire = TOKEN.ExpiresIn * 1000;

      if (user.isAuth) {
        sessionTimeout = setTimeout(() => {
          setModalInfo({
            title: 'Advertencia por inactividad',
            subtitle: 'La sesión se cerrará pronto por inactividad.',
            buttonOk: 'Estoy aquí',
            buttonCancel: 'Cancelar',
            action: () => {
              refreshToken();
            },
            image: imageSession,
            showButtons: true,
          });
          setShowModal(true);
        }, secondsToExpire);
      }

      return () => {
        clearTimeout(sessionTimeout);
      };
    }
  }, [newSession]);

  return (
    <>
      <BrowserRouter>
        <Routes />
      </BrowserRouter>
      <ModalConfirmation
        showModal={showModal}
        setShowModal={setShowModal}
        modalInfo={modalInfo}
        id={1}
        showButtons={modalInfo.showButtons}
        cancelFunction
        actionToCancel={logout}
      />
    </>
  );
};

export default Router;
