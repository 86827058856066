import styled from 'styled-components';
import { scarpaGray800, robotoRegular } from '@habitech/shared';

export const SearchDescription = styled.div`
  max-width: 398px;
  grid-column: 3/8;
  font-family: ${robotoRegular};
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 18px;
  color: #78747b;
`;
export const ContainerSearch = styled.div`
  max-width: 398px;
  grid-column: 3/8;
  input {
    padding-left: 30px;
    height: 38px;
    padding-right: 70px;
    border-radius: 6px;
    width: 100%;
    border: 2px solid #78747b;
    color: ${scarpaGray800};
    font-size: 16px;

    &:focus,
    &:active {
      outline: none;
    }
  }
  .icon-image {
    position: absolute;
    margin-left: 8px;
    margin-top: 12px;
  }

  .search {
    width: 40px;
    height: 43px;
    position: relative;
    right: 38px;
    cursor: pointer;

    &.search-close {
      right: 68px;
    }
  }

  .close {
    width: 30px;
    height: 33px;
    margin-top: 5px;
    position: relative;
    right: 68px;
    cursor: pointer;
  }
`;

export const ColumnDescription = styled.div`
  grid-column: 1/3;
  font-family: ${robotoRegular};
  .size {
    width: 130px;
  }
  .subtitle {
    font-family: ${robotoRegular};
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: ${scarpaGray800};
  }
`;
