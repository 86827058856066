import { SetStateAction } from 'react';
import { DropdownButton } from '@habitech/call-to-action';
import {
  ContainerRevenueCategory,
  ContainerSelectCategory,
} from '../../../HesH/styles';
import { ColumnDescription } from '../../../ZoneValidationMx/styles';

interface IExperienceType {
  setExperienceType: React.Dispatch<SetStateAction<any>>;
}

const ExperienceType = ({ setExperienceType }: IExperienceType) => {
  const optionsCategory = [
    {
      label: 'Mala experiencia',
      value: 'Mala Experiencia',
    },
    {
      label: 'Sin experiencia',
      value: 'Sin Experiencia',
    },
    {
      label: 'Poca experiencia',
      value: 'Poca Experiencia',
    },
    {
      label: 'Experiencia regular',
      value: 'Experiencia Regular',
    },
    {
      label: 'Buena experiencia',
      value: 'Buena Experiencia',
    },
  ];
  return (
    <ContainerRevenueCategory>
      <ColumnDescription>
        <div className="subtitle">
          Selecciona el tipo de experiencia a editar
        </div>
      </ColumnDescription>
      <ContainerSelectCategory>
        <DropdownButton
          dataId="experienceType"
          size="fluid"
          variant="white"
          placeholder="Seleccione"
          listOptions={optionsCategory}
          label="Tipo de experiencia"
          setValue={(value) => setExperienceType(value)}
        />
      </ContainerSelectCategory>
    </ContainerRevenueCategory>
  );
};

export default ExperienceType;
