import {
  ChangeEvent,
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useState,
} from 'react';
import ErrorOutlineRoundedIcon from '@mui/icons-material/ErrorOutlineRounded';
import { MonthPicker } from '@habitech/month-picker';
import {
  GraphicWrapper,
  RadioButtonWrapper,
  TitleAndSubtitle,
  TitleSection,
  TitleZone,
  WrapperGraphics,
  WrapperShopping,
} from './style';
import { useAppSelector } from '../../../../redux/hooks';
import {
  electricPurple100,
  electricPurple300,
  electricPurple500,
  purpleMedium,
  skyBlue400,
  tomatoCoral200,
  tomatoCoral400,
  tomatoCoral600,
} from '../../../../styles/stylesConstants';
import LocationIcon from './assets/location.svg';
import Graphic from '../Graphic/Graphic';
import DateDisclaimer from '../../../../components/DateDisclaimer';
import RadioButton from '../../../../components/RadioButton';
import EmptyState from '../../../../components/EmptyState';
import { LoaderContainer } from '../../../Pricing/components/LineGraphicMape/styles';
import HabiLoader from '../../../../components/HabiLoader/HabiLoader';

interface ShoppingDistributionProps {
  date: [Date, Date?];
  onChangeDate: Dispatch<SetStateAction<[Date, (Date | undefined)?]>>;
  onChangeCategory: any;
  category: string;
  isEmptyData: boolean;
  loaded: boolean;
}

const ShoppingDistribution: FC<ShoppingDistributionProps> = ({
  date,
  onChangeDate,
  onChangeCategory,
  category,
  isEmptyData,
  loaded,
}) => {
  const [statistics, setStatistics] = useState<any[]>([]);
  const zoneState = useAppSelector((state) => state.zoneStore);
  const [radioSelected, setRadioSelected] = useState(category);

  const handleRadioButton = (item: ChangeEvent<HTMLInputElement>) => {
    setRadioSelected(String(item.target.value));
    onChangeCategory(item.target.value);
  };

  useEffect(() => {
    const newStatistics = [];

    if (zoneState.zone.revenue) {
      const { revenue } = zoneState.zone;

      if (revenue.metropolitan_area) {
        newStatistics.push({
          ...revenue.metropolitan_area,
          type: 'primary',
          first: true,
          typeZone: 'Área Metropolitana',
          label: revenue.metropolitan_area.ma_label,
          colors: [
            electricPurple500,
            electricPurple300,
            electricPurple100,
            tomatoCoral200,
            purpleMedium,
          ],
        });
      }

      if (revenue.median_zone) {
        newStatistics.push({
          ...revenue.median_zone,
          type: 'secondary',
          typeZone: 'Zona Mediana',
          first: false,
          label: revenue.median_zone.mz_label,
          colors: [
            tomatoCoral600,
            electricPurple300,
            skyBlue400,
            tomatoCoral200,
            tomatoCoral400,
          ],
        });
      }
    }

    setStatistics(newStatistics);
  }, [zoneState]);

  if (!loaded) {
    return (
      <LoaderContainer>
        <HabiLoader size="small" />
      </LoaderContainer>
    );
  }

  if (isEmptyData) {
    return (
      <EmptyState
        title="No se encontró información de revenue."
        body="El area o zona no tiene por el momento información de revenue. Intente cambiar la fecha para obtener resultados."
      />
    );
  }

  return (
    <WrapperShopping>
      <TitleSection>
        <ErrorOutlineRoundedIcon className="icon" />
        <div>Distribución de Compras según revenue</div>
      </TitleSection>

      <div className="date-wrapper">
        <div className="date-wrapper-container">
          <MonthPicker
            dataId="1"
            value={date as [Date, Date?]}
            onChangeValue={onChangeDate}
            isRange
            startYear={2020}
          />
        </div>

        <RadioButtonWrapper>
          <RadioButton
            radioSelected={radioSelected}
            clickRadioButton={handleRadioButton}
            value="bought"
            label="Compras"
            name="compras"
          />

          <RadioButton
            radioSelected={radioSelected}
            clickRadioButton={handleRadioButton}
            value="total"
            label="Total Inventario"
            name="total-inventario"
          />
        </RadioButtonWrapper>
      </div>

      <DateDisclaimer date={date[1] as Date} />

      <div className="date-divider" />

      <WrapperGraphics>
        {statistics.map((statistic: any) => {
          return (
            <GraphicWrapper key={statistic.label} first={statistic.first}>
              {!isEmptyData ? (
                <>
                  <TitleZone>
                    <img src={LocationIcon} alt="pin del mapa" />
                    <TitleAndSubtitle>
                      <div className="title">{statistic.label}</div>
                      <div className="subtitle">{statistic.typeZone}</div>
                    </TitleAndSubtitle>
                  </TitleZone>
                  <Graphic statistics={statistic} />
                </>
              ) : (
                <EmptyState
                  title="No se encontró información de revenue."
                  body={`El area o zona ${statistic.label} no tiene por el momento información de revenue. Intente cambiar la fecha para obtener resultados.`}
                />
              )}
            </GraphicWrapper>
          );
        })}
      </WrapperGraphics>
    </WrapperShopping>
  );
};

export default ShoppingDistribution;
