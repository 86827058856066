import {
  robotoRegular,
  scarpaGray600,
  skyBlue50,
  scarpaGray800,
  scarpaGray700,
  electricPurple500,
  white,
  disabledColor,
} from '@habitech/shared';
import styled from 'styled-components';

export const ContainerRevenueCategory = styled.div`
  display: flex;
`;
export const ContainerModifyParameterTab = styled.div`
  display: flex;
`;
export const ContainerNewParameter = styled.div`
  display: flex;
`;
export const ContainerSelectCategory = styled.div`
  width: 300px;
`;
export const ContainerTabs = styled.div`
  margin-bottom: 31px;
`;
export const ContainerParameter = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  .title-modify {
    color: ${scarpaGray800};
    font-family: ${robotoRegular};
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    margin-bottom: 16px;
  }
  .container-input {
    width: 186px;
  }
`;

export const CardDescriptionParameter = styled.div`
  display: flex;
  width: 98%;
  border-radius: 8px;
  background: ${skyBlue50};
  padding: 12px 0 12px 12px;
  margin-bottom: 28px;
  img {
    margin-right: 20px;
  }
  .title-description {
    color: ${scarpaGray800};
    font-family: ${robotoRegular};
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    margin-bottom: 4px;
  }
  .subtitle-description {
    color: ${scarpaGray700};
    font-family: ${robotoRegular};
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }
`;
export const ColumnDescription = styled.div`
  .size {
    width: 130px;
  }
  .subtitle {
    font-family: ${robotoRegular};
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: ${scarpaGray600};
    width: 167px;
    margin-right: 64px;
  }
`;

export const ButtonContainerHesh = styled.div`
  display: flex;
  width: 100%;
  margin-top: 40px;
  justify-content: flex-end;
  .button-hesh {
    display: flex;
    width: 207px;
    height: 48px;
    padding: 8px 24px;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    background: ${electricPurple500};
    font-family: ${robotoRegular};
    font-size: 14px;
    font-weight: 400;
    color: ${white};
    outline: inherit;
    border: none;
    cursor: pointer;
    &:disabled {
      background: ${disabledColor};
      cursor: not-allowed;
    }
  }
`;
