export const handleGenerateComent = (
  value: string = 'warning',
  nid: string = '',
  elevator: string = '',
  median_zone: string = '',
  data_table: any[] = [
    { total_count: 0, num_elevators: 0, total_percentage: 0 },
  ]
) => {
  const totalPercentage = getTotalPercentageByNumElevator(data_table, elevator);
  let commentType = '';

  switch (value) {
    case 'approved':
      commentType = 'OK - Se aprueba';
      break;
    case 'warning':
      commentType = 'REVISAR';
      break;
    case 'rejected':
      commentType = 'RECHAZADO';
      break;
    default:
      break;
  }

  return `${commentType} - El inmueble con nid ${nid} posee una tipología de ascensores presente en un ${totalPercentage}% de los inmuebles en el barrio (ascensores: ${elevator}, zona mediana: ${median_zone})`;
};

export const getTotalPercentageByNumElevator = (
  data: any[],
  numElevator: string
) => {
  const entry = data.find((item) => item.num_elevators === numElevator);
  return entry ? entry.total_percentage.toFixed(1) : 0;
};
