import React, { useState } from 'react';
import {
  Table,
  TableBody,
  TableContainer,
  TableRow,
  Paper,
  TablePagination,
} from '@mui/material';
import {
  ContainerTable,
  CustomPaginatorContainer,
  StyledHeaderCell,
  StyledDataCell,
  StyledHeader,
  StyledDataCellHeader,
  StyledFirstDataCell,
} from './styles';
import { TablePaginationContainer } from '../InverterTable/styles';

const rowsPerPageOptions = [5];

interface IBidimensionalTable {
  data: any;
  totalCount: number;
  keyMap: string;
  axis: any;
}

const BidimensionalTable = ({
  data,
  totalCount,
  keyMap,
  axis,
}: IBidimensionalTable) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[0]);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <ContainerTable>
      <Paper
        style={{
          borderRadius: '6px',
          boxShadow: 'none',
        }}
      >
        <TableContainer
          data-testid="bidimensional-table"
          style={{
            borderRadius: '6px 6px 0 0',
            boxShadow: 'none',
          }}
        >
          <Table sx={{ minWidth: 650 }} size="small">
            <StyledHeader>
              <TableRow>
                <StyledHeaderCell>Baños / Habitaciones</StyledHeaderCell>
                {data.columns.map((column: any) => {
                  return (
                    <StyledDataCellHeader key={`${keyMap}-header-${column}`}>
                      {Number(column) % 1 !== 0
                        ? Number(column).toFixed(1)
                        : column}
                    </StyledDataCellHeader>
                  );
                })}
              </TableRow>
            </StyledHeader>
            <TableBody>
              {data.index
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row: any, index1: number) => (
                  <TableRow key={`${keyMap}-${row}`}>
                    <StyledFirstDataCell>{row}</StyledFirstDataCell>
                    {data.data[index1].map((room: any, index: number) => {
                      let rowIdx = row;
                      let colIdx = index;

                      if (row === 0) {
                        rowIdx -= 1;
                      } else {
                        colIdx += 1;
                      }

                      return (
                        <StyledDataCell
                          // eslint-disable-next-line react/no-array-index-key
                          key={`${keyMap}-cell-${room}-${row}-${colIdx}`}
                          style={{
                            backgroundColor:
                              rowIdx === axis[1] && colIdx === axis[0]
                                ? '#0444A5'
                                : 'inherit',
                            color:
                              rowIdx === axis[1] && colIdx === axis[0]
                                ? '#FFFFFF'
                                : '#252129',
                          }}
                        >
                          {(Number(room) % 1 !== 0
                            ? Number(room).toFixed(1)
                            : room) || '-'}
                        </StyledDataCell>
                      );
                    })}
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePaginationContainer>
          <CustomPaginatorContainer>
            <div className="results">{data.index.length} Resultados</div>
            <TablePagination
              rowsPerPageOptions={rowsPerPageOptions}
              component="div"
              count={totalCount}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              labelRowsPerPage="RESULTADOS POR PÁGINA"
              labelDisplayedRows={({ from, to, count }) =>
                `${from}-${to} de ${count}`
              }
            />
          </CustomPaginatorContainer>
        </TablePaginationContainer>
      </Paper>
    </ContainerTable>
  );
};

export default BidimensionalTable;
