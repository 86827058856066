import { FC, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, DropdownButton } from '@habitech/call-to-action';
import { IListOptions } from '@habitech/call-to-action/src/lib/dropdown-button/models/dropdown-button.model';

import SelectZoneContainer, { DropdownStylesContainer } from './styles';
import { zoneStoreActions } from '../../../../redux/reducers/zoneReducer';
import { useAppDispatch } from '../../../../redux/hooks';
import useZoneController, {
  SelectZoneFormValues,
} from '../../hooks/zonesFormController';

const mapToRedux = (item: IListOptions) => ({
  id: item.value,
  label: item.label,
});

interface SelectZoneFormProps {
  onChange: (formValues: SelectZoneFormValues) => void;
}

const SelectZoneForm: FC<SelectZoneFormProps> = ({ onChange }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { setMaId, setCityId, setBzId, setZoneId, formValues, formSelects } =
    useZoneController();

  useEffect(() => {
    dispatch(zoneStoreActions.clearZone());
  }, [dispatch]);

  useEffect(() => {
    onChange(formValues);
  }, [formValues, onChange]);

  const onZoneSelection = () => {
    const zone = {
      area: mapToRedux(formValues.ma!),
      middleZone: mapToRedux(formValues.zone!),
      metropolitan_area: mapToRedux(formValues.ma!),
      bigZone: mapToRedux(formValues.bz!),
      city: mapToRedux(formValues.city!),
    };
    dispatch(zoneStoreActions.updateZone(zone));
    navigate('/portal/analisis-de-zona');
  };

  return (
    <SelectZoneContainer>
      <DropdownStylesContainer>
        <DropdownButton
          dataId="metropolitan_area"
          size="fluid"
          variant="white"
          label="* Área metropolitana"
          placeholder="Selecciona"
          listOptions={formSelects.ma}
          setValue={setMaId as any}
        />
      </DropdownStylesContainer>
      <DropdownStylesContainer>
        <DropdownButton
          dataId="cities"
          size="fluid"
          variant="white"
          label="* Ciudad"
          placeholder="Selecciona"
          disabled={!formValues.ma}
          listOptions={formSelects.city}
          setValue={setCityId as any}
        />
      </DropdownStylesContainer>
      <DropdownStylesContainer>
        <DropdownButton
          dataId="big_zone"
          size="fluid"
          variant="white"
          label="* Zona grande"
          placeholder="Selecciona"
          disabled={!formValues.city}
          listOptions={formSelects.bz}
          setValue={setBzId as any}
        />
      </DropdownStylesContainer>
      <DropdownStylesContainer>
        <DropdownButton
          dataId="median_zone"
          size="fluid"
          variant="white"
          label="* Zona Mediana"
          placeholder="Selecciona"
          disabled={!formValues.bz}
          listOptions={formSelects.zone}
          setValue={setZoneId as any}
        />
      </DropdownStylesContainer>

      <Button
        fluid
        dataId="go-zone"
        size="extra-large"
        disabled={!formValues.zone}
        onClick={onZoneSelection}
      >
        Ir a la zona seleccionada
      </Button>
    </SelectZoneContainer>
  );
};

export default SelectZoneForm;
