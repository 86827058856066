import {
  ContainerSearch,
  ColumnDescription,
  SearchDescription,
} from './styles';
import point from '../../../../assets/images/point_map.svg';
import searchButton from '../../../../assets/icons/search-button.png';

export interface ISearcher {
  setNidValue: any;
  nidValue: number | undefined;
  handelCallService: any;
}

const Searcher: React.FC<ISearcher> = ({
  setNidValue,
  nidValue,
  handelCallService,
}) => {
  return (
    <>
      <ColumnDescription>
        <div className="subtitle">Buscar por NID</div>
      </ColumnDescription>
      <ContainerSearch>
        <div style={{ display: 'flex' }}>
          <img src={point} alt="point" className="icon-image" />
          <input
            data-testid="nid-search"
            value={nidValue}
            type="number"
            placeholder="NID a Consultar"
            onChange={(e) => {
              setNidValue(e.target.value);
            }}
          />
          <img
            data-testid="image-button-search"
            alt="search"
            src={searchButton}
            className="search "
            onClick={() => handelCallService()}
          />
        </div>
        <SearchDescription>Nid del inmueble a consultar</SearchDescription>
      </ContainerSearch>
    </>
  );
};

export default Searcher;
