export const dictionaryTableCo = {
  nid: 'NID',
  equal_set: '¿En conjunto?',
  status: 'Estado del lead',
  sale_days: 'Días en venta',
  sale_price: 'Precio Buyer',
  sale_price_m2: 'Precio m2 Buyer',
  buy_price: 'Precio Seller',
  buy_price_m2: 'Precio m2 Seller',
  buy_promise_date: 'C fecha de Promesa',
  desisted_date: 'C fecha de desistimiento',
  published_date: 'R Fecha de Weblisted',
  sale_promise_date: 'V fecha de Promesa',
  property_type_id: 'Tipo',
  median_zone: 'Zona mediana',
  property_set: 'Conjunto comité',
  property_set_db: 'Conjunto Db',
  antiquity: 'Antiguedad',
  area: 'Area',
  garage: 'Garages',
  bathroom: 'Baños',
  elevator: 'Ascensor',
  floor_number: 'Piso',
  is_last_floor: '¿Último piso?',
  deal_id: 'Id Negocio',
};
export const dictionaryTableMx = {
  nid: 'NID',
  status: 'Estado del lead',
  sale_days: 'Días en venta',
  sale_price: 'Precio Buyer',
  sale_price_m2: 'Precio m2 Buyer',
  buy_price: 'Precio Seller',
  buy_price_m2: 'Precio m2 Seller',
  buy_promise_date: 'C fecha de Promesa',
  desisted_date: 'C fecha de desistimiento',
  published_date: 'R Fecha de Weblisted',
  sale_promise_date: 'V fecha de Promesa',
  property_type_id: 'Tipo',
  median_zone: 'Zona mediana',
  antiquity: 'Antiguedad',
  area: 'Area',
  garage: 'Garages',
  bathroom: 'Baños',
  elevator: 'Ascensor',
  is_last_floor: '¿Último piso?',
  deal_id: 'Id Negocio',
};

export const formatToCurrency = (value: number): string => {
  const roundedValue = Math.round(value * 100) / 100;
  const numParts = roundedValue.toFixed(2).split('.');
  const num = numParts[0];
  let result = '';
  const { length } = num;
  const start = length % 3;

  if (start > 0) {
    result = num.substring(0, start) + (length > 3 ? ',' : '');
  }

  for (let i = start; i < length; i += 3) {
    result += num.substring(i, i + 3) + (i + 3 < length ? ',' : '');
  }

  return `$${result}.${numParts[1]}`;
};

export const calculateAndFormatPricePerM2 = (price: string, area: string) => {
  const numericPrice = parseFloat(price.replace(/[$,]/g, ''));
  const numericArea = parseFloat(area);

  if (numericArea !== 0) {
    const pricePerM2 = numericPrice / numericArea;
    return formatToCurrency(pricePerM2);
  }

  return '-';
};

export const organizeData = (data: any, dictionary: any) => {
  if (!data || data.length === 0) {
    return [];
  }

  return Object.entries(dictionary).map(([prop, title]) => ({
    title,
    data: data.map((item: any) => {
      if (
        (prop === 'sale_price_m2' && item.sale_price && item.area) ||
        (prop === 'buy_price_m2' && item.buy_price && item.area)
      ) {
        return calculateAndFormatPricePerM2(
          item[prop.replace('_m2', '')],
          item.area
        );
      }

      return item[prop] ? item[prop] : '-';
    }),
  }));
};
