import styled from 'styled-components';
import { white, monserratRegular, scarpaGray800 } from '@habitech/shared';

export const WrapperZoneValidation = styled.div`
  max-width: 1016px;
  height: calc(100% - 65px);
  overflow-y: auto;
  width: 100%;
  padding: 16px 24px 0 0;
  position: relative;
`;

export const TitleZonesValidation = styled.div`
  font-family: ${monserratRegular};
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 32px;
  display: flex;
  align-items: center;
  letter-spacing: -0.01em;
  color: ${scarpaGray800};
  margin-top: 57px;
  margin-bottom: 20px;
`;

export const ContainerValidationZone = styled.div`
  background: ${white};
  box-shadow: 0px 0px 1px rgba(48, 49, 51, 0.05),
    0px 2px 4px rgba(48, 49, 51, 0.1);
  border-radius: 6px;
  display: grid;
  grid-template-columns: repeat(10, 1fr);
  gap: 0 24px;
  padding: 24px 18px;
`;

export const LoaderContainerGrid = styled.div`
  grid-column: 1/12;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const ContainerZone = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 100vh;
  margin-bottom: 100px;
`;
