import { useEffect, useState } from 'react';
import { electricPurple600, skyBlue300 } from '@habitech/shared';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

import { getMarketingLeads } from '../../../../apis/marketingLeads/marketingLeads';
import { useAppSelector } from '../../../../redux/hooks';
import { LineGraphicProps, IDataArea, IDataSets } from './model';

import { ContainerFirstGraph, ContainerSecondGraph } from './styles';
import { MONTHS_DICT } from './utils';
import {
  selectZone,
  selectZoneInfo,
} from '../../../../redux/reducers/zoneSelectors';
import { LoaderContainer } from '../../../Pricing/components/LineGraphicMape/styles';
import HabiLoader from '../../../../components/HabiLoader/HabiLoader';
import SingleLineGraph from './SingleLineGraph';

const LineGraphic: React.FC<LineGraphicProps> = ({ city, date }) => {
  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
  );
  const [isLoaded, setIsLoaded] = useState(false);
  const [dataArea, setDataArea] = useState<IDataArea>();
  const [dataZone, setDataZone] = useState<IDataArea>();
  const zoneInfo = useAppSelector(selectZoneInfo);
  const area = useAppSelector(selectZone);

  useEffect(() => {
    getMarketingLeads(zoneInfo.zm_id, date).then((response) => {
      try {
        const { leads_area, leads_zone } = response.data;
        const newDataSets = leads_area.datasets.map((itm: IDataSets) => {
          return {
            ...itm,
            backgroundColor: electricPurple600,
            borderColor: electricPurple600,
          };
        });
        const newDataZone = leads_zone.datasets.map((itm: IDataSets) => {
          return {
            ...itm,
            backgroundColor: skyBlue300,
            borderColor: skyBlue300,
          };
        });
        const DICT_EN = MONTHS_DICT.EN;
        const newLabel: any = [];
        leads_area.labels.forEach((label: any) => {
          newLabel.push(
            `${DICT_EN[label.slice(0, -5).toLowerCase()]} ${label.slice(-4)}`
          );
        });
        setDataArea({ ...leads_area, datasets: newDataSets, labels: newLabel });
        setDataZone({ ...leads_zone, datasets: newDataZone, labels: newLabel });
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoaded(true);
      }
    });
  }, [date]);

  if (!isLoaded) {
    return (
      <LoaderContainer>
        <HabiLoader size="small" />
      </LoaderContainer>
    );
  }

  return (
    <>
      <ContainerFirstGraph>
        <SingleLineGraph
          dataArea={dataArea}
          title={area.area.label}
          color={electricPurple600}
          subtitle="Área Metropolitana"
          border
        />
      </ContainerFirstGraph>
      <ContainerSecondGraph>
        <SingleLineGraph
          dataArea={dataZone}
          title={zoneInfo.zg_label}
          color={skyBlue300}
          subtitle="Zona mediana"
        />
      </ContainerSecondGraph>
    </>
  );
};

export default LineGraphic;
