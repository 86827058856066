import { useState } from 'react';
import {
  ContainerRulesPendingApproved,
  StyledButton,
  TitleNameCounterRules,
} from './styles';
import ControlDown from '../../../../assets/images/ControlDown.svg';
import ControlUp from '../../../../assets/images/ControlUp.svg';
// eslint-disable-next-line import/no-cycle
import DetailsRulesCard from '../DetailsRulesCard/DetailsRulesCard';

interface IRulesPendingApprovedProps {
  title?: string;
  numberRules?: string;
  data: any[];
}

const RulesPendingApproved = ({
  title = '',
  numberRules = '',
  data,
}: IRulesPendingApprovedProps) => {
  const [showDetail, setShowDetail] = useState(true);
  const imgControl = showDetail ? ControlUp : ControlDown;

  return (
    <ContainerRulesPendingApproved>
      <TitleNameCounterRules>
        <div className="icon-title">
          <StyledButton onClick={() => setShowDetail(!showDetail)}>
            <img src={imgControl} alt="ampliar replegar" />
          </StyledButton>
          <div className="title">{title}</div>
        </div>
        <div className="rules-number">{numberRules} REGLAS</div>
      </TitleNameCounterRules>
      {showDetail && <DetailsRulesCard data={data} />}
    </ContainerRulesPendingApproved>
  );
};

export default RulesPendingApproved;
