import {
  scarpaGray100,
  scarpaGray600,
  scarpaGray800,
  white,
} from '@habitech/shared';
import styled from 'styled-components';
import {
  hintPerano200,
  principalFont,
} from '../../../../styles/stylesConstants';

export const ContainerGraphics = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  position: relative;
`;
export const SectionTitle = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  margin-bottom: 2rem;
  img {
    margin-right: 11px;
  }
  span {
    font-weight: 600;
    font-size: 20px;
    font-family: ${principalFont};
    color: ${scarpaGray600};
  }
  .icon {
    font-size: 20px;
    margin-right: 11px;
  }
`;

export const VersusIndicator = styled.div`
  display: grid;
  justify-content: center;
  align-items: center;
  width: 51px;
  height: 40px;
  border: 1px solid ${scarpaGray100};
  border-radius: 8px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 18px;
  color: ${scarpaGray800};
  top: 28px;
  left: 47%;
  background-color: ${white};
  position: absolute;
`;

export const GraphicsDivider = styled.div`
  &:first-of-type {
    padding-right: 32px;
    border-right: 1px solid ${hintPerano200};
  }

  &:not(:first-of-type) {
    padding-left: 32px;
  }
`;

export const SectionFilter = styled.div`
  margin-bottom: 40px;
`;

export const DateFilter = styled.div`
  width: 38%;
  .styles__PickerContainer-nwdsad-2 {
    z-index: 3;
  }
`;
