import { Modal } from '@mui/material';
import { Button } from '@habitech/call-to-action';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { SetStateAction } from 'react';
import ContainerTotal, {
  ContainerModalRule,
  ModalButtons,
  ModalContent,
  ModalImage,
} from './style';

interface IModalConfirmation {
  showModal: boolean;
  setShowModal: React.Dispatch<SetStateAction<boolean>>;
  modalInfo: {
    title: string;
    title2?: string;
    subtitle: string;
    buttonOk: string;
    buttonCancel: string;
    action: Function;
    image?: string;
  };
  actionToCancel?: Function;
  cancelFunction?: boolean;
  showButtons?: boolean;
  showButtonsCancel?: boolean;
  id: number;
}

const ModalConfirmation: React.FC<IModalConfirmation> = ({
  showModal,
  setShowModal,
  modalInfo,
  id,
  showButtons = true,
  showButtonsCancel = true,
  cancelFunction = false,
  actionToCancel = () => {},
}) => {
  const handleClose = () => {
    if (cancelFunction) {
      actionToCancel();
    } else {
      setShowModal(false);
    }
  };

  return (
    <Modal
      data-testid="modal-confirm"
      open={showModal}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
    >
      <ContainerTotal>
        <ContainerModalRule>
          <CloseRoundedIcon
            className="icon-close"
            onClick={handleClose}
            sx={{ fontSize: '25px' }}
          />
          <ModalImage>
            <img src={modalInfo.image} alt="office-working" />
          </ModalImage>
          <ModalContent>
            <div className="title">{modalInfo.title}</div>
            <div className="title2">{modalInfo.title2}</div>
            <div className="subtitle">{modalInfo.subtitle}</div>
          </ModalContent>
          {showButtons && (
            <ModalButtons>
              {showButtonsCancel && (
                <>
                  <Button
                    dataId="cancel-button"
                    variant="ghost"
                    size="large"
                    fluid
                    onClick={handleClose}
                  >
                    {modalInfo.buttonCancel}
                  </Button>
                  <div className="space" />
                </>
              )}
              <Button
                dataId="accept-button"
                variant="primary"
                size="large"
                fluid
                onClick={() => modalInfo.action(id)}
              >
                {modalInfo.buttonOk}
              </Button>
            </ModalButtons>
          )}
        </ContainerModalRule>
      </ContainerTotal>
    </Modal>
  );
};

export default ModalConfirmation;
