import { Doughnut } from 'react-chartjs-2';
import { MonthPicker } from '@habitech/month-picker';
import { ContainerDoughnutGraph } from '../../styles';
import calendarEdit from '../../../../../../assets/images/Calendar_edit.svg';

interface DoughnutGraphProps {
  title?: string;
  showEdit?: boolean;
  editDate?: boolean;
  setEditDate?: (value: boolean) => void;
  date?: [Date, Date?];
  setDate?: any;
  dataToShow?: any;
  loading?: boolean;
}

const DoughnutGraph = ({
  title = 'Mes Actual:',
  showEdit = false,
  editDate = false,
  date = [new Date(), new Date()],
  setDate = () => {},
  setEditDate = () => {},
  dataToShow = {
    approved: {
      percentage: '0',
    },
    rejected_commite: {
      percentage: '0',
    },
    rejected_remo: {
      percentage: '0',
    },
  },
  loading = true,
}: DoughnutGraphProps) => {
  const data = {
    labels: ['Aprobado', 'R. Comité', 'R. Remo'],
    datasets: [
      {
        data: [
          dataToShow.approved.percentage,
          dataToShow.rejected_commite.percentage,
          dataToShow.rejected_remo.percentage,
        ],
        backgroundColor: ['#00F2C3', '#FF5958', '#ADE6FF'],
        borderWidth: 0,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    width: 100,
    height: 90,
    cutout: '30%',
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  const handleShowMonth = (previous: boolean = false): string => {
    const today = new Date();
    const targetDate = new Date(
      today.getFullYear(),
      previous ? today.getMonth() - 1 : today.getMonth()
    );

    const meses = [
      'Enero',
      'Febrero',
      'Marzo',
      'Abril',
      'Mayo',
      'Junio',
      'Julio',
      'Agosto',
      'Septiembre',
      'Octubre',
      'Noviembre',
      'Diciembre',
    ];

    return meses[targetDate.getMonth()];
  };

  const renderDateIndicator = () => {
    if (!showEdit) {
      return (
        <>
          {title} <span className="value">{handleShowMonth()}</span>
        </>
      );
    }

    if (editDate) {
      return (
        <>
          {title}
          <MonthPicker
            dataId="1"
            value={date as [Date, Date?]}
            onChangeValue={setDate}
            isRange
            startYear={2020}
          />
        </>
      );
    }

    return (
      <>
        {title} <span className="value">{handleShowMonth(true)}</span>
        <button className="img-edit-btn" onClick={() => setEditDate(true)}>
          <img src={calendarEdit} alt="edit" className="img-edit" />
        </button>
      </>
    );
  };

  const renderDataInfo = () => {
    return (
      <div className="data-info">
        <div className="top-values">
          <div className="value-top">
            {dataToShow.total ? dataToShow.total : ' - '}
          </div>
          <div className="value-top-sub">Inmuebles enviados a comité</div>
        </div>
        <div>
          <div className="item-value">
            <div className="point-g turquoise" />
            Aprobado:{' '}
            <span className="dark">
              {String.fromCharCode(160)}
              {dataToShow.approved.percentage
                ? dataToShow.approved.percentage
                : '--'}
              {dataToShow.approved.percentage && '%'}
            </span>
          </div>
          <div className="item-value">
            <div className="point-g tomato" />
            Rechazo COMITÉ:
            <span className="dark">
              {String.fromCharCode(160)}
              {dataToShow.rejected_commite.percentage
                ? dataToShow.rejected_commite.percentage
                : '--'}
              {dataToShow.rejected_commite.percentage && '%'}
            </span>
          </div>
          <div className="item-value">
            <div className="point-g sky-blue" />
            Rechazo REMO:
            <span className="dark">
              {String.fromCharCode(160)}
              {dataToShow.rejected_remo.percentage
                ? dataToShow.rejected_remo.percentage
                : '--'}
              {dataToShow.rejected_remo.percentage && '%'}
            </span>
          </div>
        </div>
      </div>
    );
  };

  return (
    <ContainerDoughnutGraph>
      <div className="date-indicator">{renderDateIndicator()}</div>
      <div className="values-graphs">
        {renderDataInfo()}
        <div className="graph">
          {loading ? (
            <img
              // eslint-disable-next-line global-require
              src={require('../../../../../../assets/images/ship.gif')}
              alt="cargando"
              className="img-ship"
            />
          ) : (
            <Doughnut data={data} options={options} width={100} height={90} />
          )}
        </div>
      </div>
    </ContainerDoughnutGraph>
  );
};

export default DoughnutGraph;
