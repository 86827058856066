import { FC, useMemo } from 'react';
import { Navigate } from 'react-router-dom';

import { useAppSelector } from '../redux/hooks';
import { selectIsUserLogged } from '../redux/reducers/userSelectors';

interface RoutePageProps {
  requiresAuth?: boolean;
  hideIfLogged?: boolean;
}

export type RoutePage = FC & RoutePageProps;

const withRoutePage = (Component: RoutePage) => {
  const redirectUrl = Component.requiresAuth ? '/iniciar-sesion' : '/';

  const FinalComponent = () => {
    const isUserLogged = useAppSelector(selectIsUserLogged);
    const element = useMemo(() => {
      const shouldRedirect = isUserLogged
        ? Component.hideIfLogged
        : Component.requiresAuth;

      return shouldRedirect !== undefined && shouldRedirect ? (
        <Navigate to={redirectUrl} />
      ) : (
        <Component />
      );
    }, [isUserLogged]);

    return element;
  };

  return FinalComponent;
};

export default withRoutePage;
