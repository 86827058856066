import ControlDown from '../../../../../../assets/images/ControlDown.svg';
import ControlUp from '../../../../../../assets/images/ControlUp.svg';
import {
  ContainerValidationRoomsBathrooms,
  StyledButton,
  TitleNameCounterValidations,
} from '../ValidationRoomsBathrooms/styles';
import DetailGoodZone from '../DetailGoodZone/DetailGoodZone';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../../../../../redux/hooks';
import { updateShowDetail } from '../../../../../../redux/reducers/searchSlice';
const ValidationGoodZone = () => {
  const showDetail = useSelector((state: any) => state.searchStore.showDetail);
  const imgControl = showDetail ? ControlUp : ControlDown;
  const dispatch = useAppDispatch();

  const handleChangeShowDetail = () => {
    dispatch(
      updateShowDetail({
        itemName: 'goodZone',
        itemValue: !showDetail.goodZone,
      })
    );
  };
  return (
    <ContainerValidationRoomsBathrooms>
      <TitleNameCounterValidations>
        <div className="icon-title">
          <StyledButton
            onClick={handleChangeShowDetail}
            data-testid="show-data"
          >
            <img src={imgControl} alt="ampliar replegar" />
          </StyledButton>
          <div className="title">Validación zona buena</div>
        </div>
      </TitleNameCounterValidations>
      {showDetail.goodZone && <DetailGoodZone />}
    </ContainerValidationRoomsBathrooms>
  );
};

export default ValidationGoodZone;
