import React, { Dispatch, SetStateAction } from 'react';

import { MonthPicker } from '@habitech/month-picker';
import {
  GraphicFilterWrapper,
  FilterWrapper,
  HeatMapWrapper,
  HeatMapContent,
} from './styles';
import { formatNumberDecimal } from '../../../../utils/formatDecimal';

interface InventoryFilterProps {
  maxMarketShare?: number;
  month: [Date, Date?];
  setMonth: Dispatch<SetStateAction<[Date, (Date | undefined)?]>>;
}

const InventoryGraphicFilter: React.FC<InventoryFilterProps> = ({
  month,
  setMonth,
  maxMarketShare,
}) => {
  return (
    <GraphicFilterWrapper>
      <FilterWrapper>
        <MonthPicker
          dataId="1"
          value={month as [Date, Date?]}
          onChangeValue={setMonth}
          isRange
          startYear={2020}
        />
      </FilterWrapper>

      <HeatMapWrapper>
        <span className="map-label">Avrg market share</span>

        <HeatMapContent>
          <div className="mr-5">0</div>
          <div className="map-color" />
          <div className="ml-5">
            {formatNumberDecimal(maxMarketShare) ?? '100%'}
          </div>
        </HeatMapContent>
      </HeatMapWrapper>
    </GraphicFilterWrapper>
  );
};

export default InventoryGraphicFilter;
