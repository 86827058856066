import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';

import {
  ItemLegend,
  ItemText,
  LegendColor,
  WrapperGraphic,
  WrapperLegend,
} from './styles';

const Graphic: React.FC<{
  statistic: {
    percent_manual: number;
    percent_automatico: number;
    percent_n: number;
    percent_sweeper: number;
    count_manual: number;
    count_automatic: number;
    count_n: number;
    count_sweeper: number;
    colors: string[];
  };
}> = ({ statistic }) => {
  ChartJS.register(ArcElement, Tooltip, Legend);
  const hoverLabel = [
    ' Pricing aútomatico',
    ' Pricing Manual',
    ' No es posible colocar precio',
    ' Pricing sweeper',
  ];
  const dataCounts = [
    statistic.count_manual,
    statistic.count_automatic,
    statistic.count_n,
    statistic.count_sweeper,
  ];
  const data = {
    labels: ['[A]', '[P]', '[N]', '[S]'],
    datasets: [
      {
        data: [
          statistic.percent_automatico,
          statistic.percent_manual,
          statistic.percent_n,
          statistic.percent_sweeper,
        ],
        backgroundColor: statistic.colors,
        borderColor: statistic.colors,
        borderWidth: 1,
        datalabels: {
          display: false,
        },
      },
    ],
  };
  const options = {
    responsive: false,
    tooltips: {
      titleFontStyle: 'bold',
    },
    plugins: {
      legend: {
        display: false,
        maxWidth: 206,
        maxHeight: 206,
        position: 'left',
        labels: {
          font: {
            size: 12,
            family: 'Open Sans',
          },
        },
      },
      tooltip: {
        titleFont: { weight: 'bold' },
        callbacks: {
          label: (context: any) =>
            `${context.label} - ${context.parsed}% (${
              dataCounts[context.dataIndex]
            })`,
        },
      },
    },
  };
  return (
    <WrapperGraphic>
      <WrapperLegend>
        {data.datasets[0].backgroundColor.map((color: any, p: any) => {
          return (
            <ItemLegend key={data.datasets[0].data[p]}>
              <LegendColor color={color} />
              <ItemText>
                <div>
                  <b>{data.labels[p]}</b>
                  {hoverLabel[p]}
                </div>
                <div className="value">{data.datasets[0].data[p]}%</div>
              </ItemText>
            </ItemLegend>
          );
        })}
      </WrapperLegend>
      <div className="chart">
        <Pie data={data} options={options as any} width="156" height="156" />
      </div>
    </WrapperGraphic>
  );
};

export default Graphic;
