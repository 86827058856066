import styled, { css } from 'styled-components';
import { TableCell, TableHead, TableCellProps } from '@mui/material';
import {
  robotoRegular,
  turquoise400,
  btnDisable,
  scarpaGray800,
  electricPurple25,
  turquoise50,
} from '@habitech/shared';
interface StyledDataCellProps extends TableCellProps {
  applyCustomStyle?: boolean;
}
export const StyledDataCell = styled((props: StyledDataCellProps) => (
  <TableCell {...props} />
))`
  && {
    text-align: center;
    font-weight: 400;
    font-size: 12px;
    border: 1px solid ${btnDisable};
    padding: 8px 16px;
    color: ${scarpaGray800};
    ${(props) =>
      props.applyCustomStyle &&
      css`
         {
          background: ${turquoise50};
        }
      `}
  }
  }
`;

export const StyledFirstDataCell = styled(TableCell)`
  && {
    text-align: center;
    font-weight: normal;
    font-size: 12px;
    background: ${electricPurple25};
    border: 1px solid ${btnDisable};
    padding: 8px 16px;
    font-family: ${robotoRegular};
    font-size: 12px;
    font-weight: 500;
    color: #252129;
  }
`;
export const StyledDataCellHeader = styled(TableCell)`
  && {
    text-align: center;
    border: 1px solid ${btnDisable};
    background: ${electricPurple25};
    padding: 8px 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    font-family: ${robotoRegular};
    font-size: 12px;
    font-weight: 400;
    color: ${scarpaGray800};
  }
`;

export const StyledHeader = styled(TableHead)`
  && {
    max-height: 40px;
  }
`;
export const CustomPaginatorContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2px 16px;
  .results {
    color: ${scarpaGray800};
    font-family: ${robotoRegular};
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
  }
`;
export const ContainerTable = styled.div`
  margin-bottom: 24px;
  width: 100%;
`;

export const StyledHeaderCell = styled(({ ...props }) => (
  <TableCell {...props} />
))`
  &&.MuiTableCell-root.MuiTableCell-head.MuiTableCell-sizeSmall {
    background: ${turquoise400};
    border: 1px solid ${btnDisable};
    color: ${scarpaGray800};
    font-family: ${robotoRegular};
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    padding: 8px 16px;
    text-align: center;
    width: 148px;
  }
`;
