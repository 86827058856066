import { useSelector } from 'react-redux';
import ControlDown from '../../../../../../assets/images/ControlDown.svg';
import ControlUp from '../../../../../../assets/images/ControlUp.svg';
import { useAppDispatch } from '../../../../../../redux/hooks';
import { updateShowDetail } from '../../../../../../redux/reducers/searchSlice';
import DetailHistoricalBusinessInformation from '../DetailHistoricalBusinnesInformation/DetailHistoricalBusinessInformation';
import {
  ContainerValidationRoomsBathrooms,
  StyledButton,
  TitleNameCounterValidations,
} from '../ValidationRoomsBathrooms/styles';

const ValidationHistoricalBusinessInformation = () => {
  const showDetail = useSelector((state: any) => state.searchStore.showDetail);
  const dispatch = useAppDispatch();
  const imgControl = showDetail ? ControlUp : ControlDown;

  const handleChangeShowDetail = () => {
    dispatch(
      updateShowDetail({
        itemName: 'historicalBusiness',
        itemValue: !showDetail.historicalBusiness,
      })
    );
  };
  return (
    <ContainerValidationRoomsBathrooms>
      <TitleNameCounterValidations>
        <div className="icon-title">
          <StyledButton onClick={handleChangeShowDetail}>
            <img src={imgControl} alt="ampliar replegar" />
          </StyledButton>
          <div className="title">Información Negocios históricos</div>
        </div>
      </TitleNameCounterValidations>
      {showDetail.historicalBusiness && <DetailHistoricalBusinessInformation />}
    </ContainerValidationRoomsBathrooms>
  );
};

export default ValidationHistoricalBusinessInformation;
