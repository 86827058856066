import {
  white,
  disabledColor,
  monserratRegular,
  scarpaGray500,
  scarpaGray800,
  btnDisable,
  scarpaGray50,
  robotoRegular,
} from '@habitech/shared';
import styled from 'styled-components';

export const GeneralSummaryContainer = styled.div`
  display: grid;
  grid-gap: 16px;
  gap: 24px;
  padding: 32px 24px;
  align-items: center;
  border-radius: 6px;
  border: 1px solid ${disabledColor};
  background: ${white};
`;

export const Title = styled.div`
  font-family: ${monserratRegular};
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  color: ${scarpaGray800};
`;

export const BasicInfo = styled.div`
  display: flex;
  font-family: ${monserratRegular};
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: ${scarpaGray500};

  .separator-content {
    padding: 0 12px;
  }

  .badge {
    display: flex;
    border-radius: 13px;
    background: ${scarpaGray50};
    padding: 4px 16px;
    align-items: center;
    gap: 8px;
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    color: ${scarpaGray800};
  }
`;

export const Subtitle = styled.div`
  font-family: ${monserratRegular};
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  color: ${scarpaGray800};
  padding: 16px 0;
`;

export const IndicatorsSummary = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  border-radius: 6px;
  border: 1px solid ${btnDisable};
  background: ${white};
  height: 156px;
`;

export const IndicatorContainer = styled.div`
  position: relative;
  border-right: 1px solid ${btnDisable};
  height: 156px;
  &:last-child {
    border-right: none;
  }
  &:hover .hover-buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    .buttons {
      display: flex;
      gap: 8px;
      opacity: none !important;
    }
  }
`;

export const IndicatorBody = styled.div`
  padding: 24px;
  .body {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    .title {
      width: 140px;
      font-family: ${monserratRegular};
      font-weight: 600;
      font-size: 16px;
      color: ${scarpaGray800};
    }
  }
  .revenue {
    margin-top: 42px;
    display: flex;
    flex-direction: column;
    font-family: ${robotoRegular};
    .title {
      font-weight: 400;
      font-size: 14px;
      color: ${scarpaGray500};
    }
    .value {
      font-weight: 300;
      font-size: 24px;
      color: ${scarpaGray800};
    }
  }

  .hesh {
    font-family: ${robotoRegular};
    margin-top: 42px;
    .title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      span {
        font-weight: 500;
        font-size: 16px;
        color: ${scarpaGray800};
      }
    }
    .subtitle {
      margin-top: 7px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: ${scarpaGray500};
      span {
        font-weight: 400;
        font-size: 14px;
      }
    }
  }
`;

export const IndicatorValue = styled.div`
  margin-top: 30px;
  font-family: ${robotoRegular};
  font-weight: 300;
  font-size: 32px;
  color: ${scarpaGray800};
`;

export const ContainerComments = styled.div`
  display: grid;
  grid-template-columns: 1fr 263px;
  gap: 24px;

  .buttons-area {
    padding-top: 16.5px;
    display: grid;
    align-items: center;
    gap: 8px;
  }
  button {
    width: 100%;
    height: 48px !important;
    border-radius: 6px;

    &:first-child {
      margin-bottom: 8px;
    }
  }
`;

export const Comment = styled.div`
  display: grid;
  font-family: ${robotoRegular};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: ${scarpaGray800};
  .label {
    margin-bottom: 4px;
  }
  .MuiOutlinedInput-root {
    border-radius: 6px;
  }
  textarea {
    font-family: ${robotoRegular};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    height: 105px;
  }
`;
