import { CardLargeContainer, CustomButton } from './styles';
import negative from '../../../../assets/icons/negative.svg';
import positive from '../../../../assets/icons/positive.svg';
import warning from '../../../../assets/icons/warning.svg';
import cornerPurple from '../../../../assets/icons/cornerPurple.svg';
import { useContext } from 'react';
import { CardContext } from '../../Approver';

const CardLarge = ({ data, approved, edited, id }: any) => {
  const iconMap: Record<string, JSX.Element> = {
    negative: <img src={negative} alt="negative" data-testid="negative" />,
    positive: <img src={positive} alt="positive" data-testid="positive" />,
    warning: <img src={warning} alt="warning" data-testid="warning" />,
  };
  const role = localStorage.getItem('ROL');
  const context: any = useContext(CardContext);
  const { handleEditCard, approval } = context;

  const handleReturnIcon = (iconType: string) => {
    return iconMap[iconType] || null;
  };

  return (
    <CardLargeContainer data-testid="large">
      {edited && (
        <img src={cornerPurple} alt="cornerPurple" className="corner" />
      )}
      <div className="container-content">
        <div className="container-title">
          <div className="title"> {data?.title}</div>
          {handleReturnIcon(approved)}
        </div>
        <div className="container-description">
          <div>
            <div className="text">Askprice comité:</div>
            <div className="value">{data?.values.ask_price_committee}</div>
          </div>
          <div>
            <div className="text">Askprice Hubspot:</div>
            <div className="value">{data?.values.ask_price_hubspot}</div>
          </div>
          <div>
            <div className="text">Compra comité:</div>
            <div className="value">{data?.values.purchasing_committee}</div>
          </div>
          <div>
            <div className="text">Precio Manual Hubspot:</div>
            <div className="value">{data?.values.manual_price_hubspot}</div>
          </div>
          <div>
            <div className="text">Obra gris:</div>
            <div className="value">{data?.values.gray_work}</div>
          </div>
        </div>
        <div className="hover-buttons">
          <div className="buttons">
            <CustomButton
              className={`${
                role !== 'aprobologo' || !approval.editable ? 'disabled' : ''
              }`}
              data-testid="edit-button"
              onClick={() => {
                if (role !== 'aprobologo' || !approval.editable) return;
                handleEditCard(id, data);
              }}
            >
              <span className="text">Editar</span>
            </CustomButton>
          </div>
        </div>
      </div>
    </CardLargeContainer>
  );
};

export default CardLarge;
