import { Dispatch, SetStateAction } from 'react';
import {
  ColumnDescription,
  ContainerSelectTypeZoneMx,
  TabContainer,
  TabItem,
} from '../../../ZoneValidationMx/styles';

interface IParameterTabs {
  selectedTabParam: number;
  setSelectedTabParam: Dispatch<SetStateAction<number>>;
}

const ParameterTabs = ({
  selectedTabParam,
  setSelectedTabParam,
}: IParameterTabs) => {
  const tabItems = [
    { id: 1, label: 'Rango negocios históricos' },
    { id: 2, label: 'Mínimo ventas' },
    { id: 3, label: 'Market share' },
  ];

  return (
    <ContainerSelectTypeZoneMx>
      <ColumnDescription>
        <div className="subtitle">
          Selecciona el parámetro que quieres modificar
        </div>
      </ColumnDescription>
      <TabContainer>
        {tabItems.map((tab) => (
          <TabItem
            key={tab.id}
            className={`${tab.id === selectedTabParam && 'selected'} ${
              tab.id === 1 ? 'first' : ''
            } ${tab.id === 3 ? 'last' : ''} quota`}
            onClick={() => setSelectedTabParam(tab.id)}
          >
            {tab.label}
          </TabItem>
        ))}
      </TabContainer>
    </ContainerSelectTypeZoneMx>
  );
};

export default ParameterTabs;
