import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface ZoneStore {
  zone: any;
  zoneInfo: any;
  quotas: any;
}

const initialState: ZoneStore = {
  zone: null,
  zoneInfo: null,
  quotas: null,
};

const zoneSlice = createSlice({
  name: 'zoneStore',
  initialState,
  reducers: {
    updateZone: (state, action: PayloadAction<ZoneStore['zone']>) => {
      state.zone = action.payload;
    },
    clearZone: (state) => {
      state.zone = null;
      state.zoneInfo = null;
      state.quotas = null;
    },
    setZoneInformation: (
      state,
      action: PayloadAction<ZoneStore['zoneInfo']>
    ) => {
      state.zoneInfo = action.payload;
    },
    setQuotas: (state, action: PayloadAction<ZoneStore['quotas']>) => {
      state.quotas = action.payload;
    },
  },
});

export const zoneStoreActions = zoneSlice.actions;

export default zoneSlice.reducer;
