import {
  monserratRegular,
  robotoRegular,
  white,
  skyBlue200,
  skyBlue800,
  scarpaGray50,
  scarpaGray100,
  scarpaGray500,
  scarpaGray600,
  scarpaGray700,
  tomatoCoral50,
  tomatoCoral400,
  tomatoCoral600,
  spanishViridian25,
  electricPurple500,
  crystal400,
  crystal700,
  maximumYellow50,
  maximumYellow500,
  maximumYellow800,
  cobalBlue25,
} from '@habitech/shared';
import styled from 'styled-components';

export const ContainerTotal = styled.div`
  display: block !important;
`;

export const ContainerModal = styled.div`
  position: relative;
  width: 562px;
  margin: 5% auto;
  justify-self: center;
  align-self: center;
  background: ${white};
  outline: none;
  padding: 35px 20px 19px 22px;
  box-shadow: 0px 0px 1px rgba(48, 49, 51, 0.05),
    0px 16px 24px rgba(48, 49, 51, 0.1);
    height: 70vh;
    overflow-y: auto;
  .icon-close {
    color:${scarpaGray500}
    font-size: 19px;
    position: absolute;
    right: 19px;
    top: 19px;

    &:hover {
      cursor: pointer;
    }
  }
  .icon{
    margin-right: 12px;
  }
`;

export const ContainerTitle = styled.div`
  display: flex;
  margin-bottom: 14px;
  .text-title {
    font-family: ${monserratRegular};
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
  }
`;

export const ContainerDescription = styled.div`
  display: flex;
  padding-bottom: 10px;
  margin-bottom: 21px;
  justify-content: space-between;
  border-bottom: 1px solid ${scarpaGray100};
  .name-and-image {
    display: flex;
    .text {
      margin-top: 8px;
      .title {
        font-family: ${monserratRegular};
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 22px;
        display: flex;
      }
      .subtitle {
        min-width: 300px;
        font-family: ${robotoRegular};
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: ${scarpaGray500};
      }
    }
  }
  .zone {
    background: ${skyBlue200};
    border-radius: 2px;
    padding: 2px 7px 2x 8px;
    width: 77px;
    height: 24px;
    text-align: center;
    margin-top: 16px;
    .text {
      font-family: ${robotoRegular};
      font-style: normal;
      font-weight: 600;
      font-size: 10px;
      line-height: 24px;
      letter-spacing: 2px;
      text-transform: uppercase;
      color: ${skyBlue800};
    }
  }
`;

export const ShowComment = styled.div`
  margin-right: 21px;
  font-family: ${robotoRegular};
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  display: flex;
  align-items: center;
  text-align: center;
  color: ${electricPurple500};
  .icon-up-down {
    margin-left: 5.7px;
  }
`;
export const ContainerButtons = styled.div`
  display: flex;
  justify-content: end;
  padding-top: 11px;
`;

export const ContainerNewChange = styled.div`
  .title-new-change {
    font-family: ${robotoRegular};
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
  }
  .subtitle {
    font-family: ${robotoRegular};
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: ${scarpaGray500};
    margin-bottom: 10px;
  }
`;
export const ContainerSelects = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
  .first-select {
    margin-right: 18px;
    min-width: 168px;
    .select {
      width: 100%;
    }
  }
  .second-select {
    width: 100%;
    .select {
      width: 100%;
    }
  }
`;

export const ContainerLastChange = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
  .text-title {
    font-family: ${robotoRegular};
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 13px;
  }
  .card-info {
    border: 1px solid ${scarpaGray100};
    border-radius: 8px;
    .first-row {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid ${scarpaGray100};
      .date-info {
        margin-left: 12px;
        margin-top: 7.2px;
        font-family: ${robotoRegular};
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 20px;
      }
      .previous-list {
        display: flex;
        margin: 8px 11px 6.5px 0px;
        .text {
          font-family: ${robotoRegular};
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 20px;
          color: ${scarpaGray500};
          margin-right: 10px;
        }
        .list {
          width: 72px;
          height: 20px;
          border-radius: 2px;
          font-family: ${robotoRegular};
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 20px;
          text-align: center;
        }
        .B {
          background: ${tomatoCoral50};
          border: 0.5px solid ${tomatoCoral400};
          color: ${tomatoCoral600};
        }
        .G {
          background: ${scarpaGray50};
          border: 1px solid ${scarpaGray600};
          color: ${scarpaGray700};
        }
        .BC {
          background: ${spanishViridian25};
          border: 1px solid ${crystal400};
          color: ${crystal700};
        }
        .Sin {
          background: ${maximumYellow50};
          border: 1px solid ${maximumYellow500};
          color: ${maximumYellow800};
        }
        .W{
          background: ${cobalBlue25};
          border: 1px solid ${maximumYellow500};
          color: ${maximumYellow800};
          }
        }
      }
    }
    .second-row {
      display: flex;
      border-bottom: 1px solid ${scarpaGray100};
      padding-top: 14.5px;
      padding-bottom: 14.5px;
      .text {
        font-family: ${robotoRegular};
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 20px;
        padding-left: 12px;
        padding-right: 20px;
      }
      .list {
        width: 72px;
        height: 20px;
        background: ${maximumYellow50};
        border: 0.5px solid ${maximumYellow500};
        border-radius: 2px;
        font-family: ${robotoRegular};
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 20px;
        text-align: center;
        color: ${maximumYellow800};
      }
      .B {
          background: ${tomatoCoral50};
          border: 0.5px solid ${tomatoCoral400};
          color: ${tomatoCoral600};
        }
        .G {
          background: ${scarpaGray50};
          border: 1px solid ${scarpaGray600};
          color: ${scarpaGray700};
        }
        .BC {
          background: ${spanishViridian25};
          border: 1px solid ${crystal400};
          color: ${crystal700};
        }
        .Sin {
          background: ${maximumYellow50};
          border: 1px solid ${maximumYellow500};
          color: ${maximumYellow800};
        }
        .W{
          background: ${cobalBlue25};
          border: 1px solid ${maximumYellow500};
          color: ${maximumYellow800};
          }
    }
    .third-row {
      display: flex;
      .first-column {
        min-width: 200px;
        border-right: 1px solid ${scarpaGray100};
        padding: 7.5px 4px 9.4px 12px;
        font-family: ${robotoRegular};
        font-style: normal;
        font-size: 12px;
        line-height: 20px;
        .title-change {
          font-weight: 400;
        }
        .reason {
          font-weight: 600;
        }
      }
      .second-column {
        display: flex;
        width: 100%;
        justify-content: space-between;
        .name {
          padding: 7.5px 12px 9.4px 12px;
          font-family: ${robotoRegular};
          font-style: normal;
          font-size: 12px;
          line-height: 20px;
          .name-title {
            font-weight: 600;
          }
        }
      }
    }
    .comment-row {
      border-top: 1px solid ${scarpaGray100};
      font-family: ${robotoRegular};
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 20px;
      align-items: center;
      padding: 6.57px 11px 9px 13px;
      .title-comment {
        color: ${scarpaGray500};
      }
      .comment {
        max-width: 496px;
      }
    }
  }
`;
