import styled from 'styled-components';
import { backgroundSite } from '../../styles/stylesConstants';

export const LayoutWrapper = styled.div`
  background: ${backgroundSite};
  width: 100%;
  height: 100vh;
  position: relative;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: 24px;
`;

export const ContainerPage = styled.div`
  display: flex;
  flex-direction: column;
  grid-column: 3 / 13;
  height: 100vh;

  .content-wrapper {
    max-width: 1016px;
    height: calc(100% - 65px);
    overflow-y: auto;
    width: 100%;
    padding: 16px 24px 0 0;
    position: relative;
  }
`;
export const ContainerEngine = styled.div`
  justify-content: center;
  display: flex;
  height: 100vh;
`;
