import WrapperInputAddLess from './style';

interface IAddLessInput {
  inputDisabled?: boolean;
  initialValue?: number;
  maxValueChange?: number;
  inputValue: any;
  setInputValue: any;
}

const AddLessInput: React.FC<IAddLessInput> = ({
  inputDisabled = true,
  inputValue,
  setInputValue,
  initialValue = -3,
  maxValueChange = 3,
}) => {
  const handleAddOne = () => {
    const newValue = parseInt(inputValue, 10) + 1;
    if (newValue <= maxValueChange) setInputValue(`${newValue}`);
  };

  const handlelessOne = () => {
    const newValue = inputValue - 1;
    if (newValue >= initialValue) setInputValue(newValue);
  };

  return (
    <WrapperInputAddLess>
      <button
        className="less"
        onClick={handlelessOne}
        data-testid="less-button"
      >
        -
      </button>
      <input
        type="text"
        disabled={inputDisabled}
        value={inputValue}
        className="border"
      />
      <button className="add" onClick={handleAddOne} data-testid="add-button">
        +
      </button>
    </WrapperInputAddLess>
  );
};

export default AddLessInput;
