import { useState } from 'react';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded';
import { Button } from '@habitech/call-to-action';

import {
  WrapperContent,
  WrapperModalDiscount,
  ModalContent,
  Modal,
  TitleSection,
  WrapperActualDiscount,
  WrapperNewDiscount,
  Subtitle,
  WrapperItems,
  Item,
  InputIcon,
  WrapperInputs,
  WarningCard,
  IconWarning,
  TextWarning,
  IconError,
} from './styles';
import HabiMoney from './assets/purple-money.svg';
import ApartmentIcon from './assets/apartment.svg';
import HouseIcon from './assets/house.svg';
import HabiIcon from './assets/habi-grey.svg';
import WarningIcon from './assets/warning.svg';
import ErrorIcon from './assets/error.svg';
import { putPercentZone } from '../../../../apis/pricing/pricing';
import getZoneInfo from '../../../ZoneDetail/services/zone.services';
import { useAppSelector, useAppDispatch } from '../../../../redux/hooks';
import { selectUser } from '../../../../redux/reducers/userSelectors';
import { alertStoreActions } from '../../../../redux/reducers/alertReducer';

const ModalDiscount: React.FC<{
  setModalOpen: Function;
  setZonePricing: Function;
  zonePricing: any;
}> = ({ setModalOpen, zonePricing, setZonePricing }) => {
  const user = useAppSelector(selectUser);
  const initialState = { apartament_discount: '', house_discount: '' };
  const [form, setForm] = useState(initialState);
  const [errorMessage, setErrorMessage] = useState('');
  const { apartment_discount, house_discount, zm_id } = zonePricing.pricing;
  const dispatch = useAppDispatch();
  const { updateAlert } = alertStoreActions;
  const exceptThisSymbols = ['e', 'E', '+', '-', '.'];

  interface HandleChange {
    target: {
      name: string;
      value: string;
    };
  }

  interface AlertProps {
    type: 'success' | 'warning' | 'error';
    text: string;
    showAlert: boolean;
  }

  const handleChange = (e: HandleChange) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const cleanInput = (name: string) => {
    setForm({ ...form, [name]: '' });
  };

  const updatePricing = async () => {
    await putPercentZone({
      apartment_discount:
        form.apartament_discount === ''
          ? apartment_discount
          : +form.apartament_discount,
      house_discount:
        form.house_discount === '' ? house_discount : +form.house_discount,
      zone_id: zm_id,
      userEmail: user?.email,
    })
      .then(() => {
        setModalOpen();
        setTimeout(() => {
          getZoneInfo(zm_id).then((response) => {
            setZonePricing({ ...zonePricing, pricing: { ...response.data } });
          });
        }, 100);
        const alertData: AlertProps = {
          type: 'success',
          text: 'Se ha actualizado el porcentaje de descuento en la zona con éxito',
          showAlert: true,
        };
        dispatch(updateAlert(alertData));
      })
      .catch((error) => {
        const response = error.response.data;
        setErrorMessage(response.message);
      });
  };

  return (
    <WrapperModalDiscount>
      <WrapperContent className="container-habi">
        <Modal>
          <ModalContent>
            <CloseRoundedIcon className="icon" onClick={() => setModalOpen()} />
            <TitleSection>
              <img src={HabiMoney} alt="" />
              <div className="title">Cambiar descuento (%) en la zona</div>
            </TitleSection>
            <WrapperActualDiscount>
              <Subtitle>Descuento Actual</Subtitle>
              <WrapperItems>
                <Item>
                  <img src={ApartmentIcon} alt="" />
                  <div className="text">
                    <b>{apartment_discount}%</b>Apartamentos
                  </div>
                </Item>
                <Item>
                  <img src={HouseIcon} alt="" />
                  <div className="text">
                    <b>{house_discount}%</b>Casas
                  </div>
                </Item>
              </WrapperItems>
            </WrapperActualDiscount>
            <WrapperNewDiscount>
              <Subtitle>Cambiar descuento</Subtitle>
              <WrapperInputs>
                <InputIcon>
                  <img src={HabiIcon} alt="Apartment discount" />
                  <input
                    type="number"
                    name="apartament_discount"
                    data-id="phone-modal-contact"
                    placeholder="% Apartamentos"
                    onChange={handleChange}
                    value={form.apartament_discount}
                    onKeyDown={(e) =>
                      exceptThisSymbols.includes(e.key) && e.preventDefault()
                    }
                  />
                  {form.apartament_discount.length > 0 && (
                    <HighlightOffRoundedIcon
                      className="close"
                      onClick={() => {
                        cleanInput('apartament_discount');
                      }}
                    />
                  )}
                </InputIcon>
                <InputIcon>
                  <img src={HabiIcon} alt="House discount" />
                  <input
                    type="number"
                    name="house_discount"
                    data-id="phone-modal-contact"
                    placeholder="% Casas"
                    onChange={handleChange}
                    value={form.house_discount}
                    onKeyDown={(e) =>
                      exceptThisSymbols.includes(e.key) && e.preventDefault()
                    }
                  />
                  {form.house_discount.length > 0 && (
                    <HighlightOffRoundedIcon
                      className="close"
                      onClick={() => {
                        cleanInput('house_discount');
                      }}
                    />
                  )}
                </InputIcon>
              </WrapperInputs>
            </WrapperNewDiscount>
            {(form.apartament_discount.length > 0 || form.house_discount.length > 0) &&
            (form.apartament_discount !== String(apartment_discount) &&
            form.house_discount !== String(house_discount) &&
            errorMessage === '') ? (
              <WarningCard>
                <IconWarning>
                  <img src={WarningIcon} alt="" />
                </IconWarning>
                <TextWarning>
                  Estás a punto de cambiar el porcentaje de
                  <br /> descuento en esta zona. ¿Estás seguro de realizar
                  <br /> este cambio?
                </TextWarning>
              </WarningCard>
            ) : (
              ''
            )}

            {(errorMessage !== '' && (
              <WarningCard>
                <IconError>
                  <img src={ErrorIcon} alt="" />
                </IconError>
                <TextWarning>
                  <strong>No se pudo guardar el descuento</strong>
                  {errorMessage}
                </TextWarning>
              </WarningCard>
            ))}

            {form.apartament_discount === String(apartment_discount) ? (
              <WarningCard>
                <IconWarning>
                  <img src={WarningIcon} alt="" />
                </IconWarning>
                <TextWarning>
                  El porcentaje de descuento para apartamentos es el mismo que
                  el valor actual
                </TextWarning>
              </WarningCard>
            ) : (
              ''
            )}

            {form.house_discount === String(house_discount) ? (
              <WarningCard>
                <IconWarning>
                  <img src={WarningIcon} alt="" />
                </IconWarning>
                <TextWarning>
                  El porcentaje de descuento para casas es el mismo que el valor
                  actual
                </TextWarning>
              </WarningCard>
            ) : (
              ''
            )}

            <div className="container-buttons">
              <Button
                dataId="change-zone"
                variant="stroke"
                size="large"
                onClick={() => {
                  setModalOpen();
                }}
              >
                No, Cancelar
              </Button>
              <Button
                dataId="change-zone"
                variant="primary"
                size="large"
                disabled={
                  (form.apartament_discount.length === 0 &&
                    form.house_discount.length === 0) ||
                  form.apartament_discount === String(apartment_discount) ||
                  form.house_discount === String(house_discount)
                }
                onClick={() => updatePricing()}
              >
                Sí, Cambiar
              </Button>
            </div>
          </ModalContent>
        </Modal>
      </WrapperContent>
    </WrapperModalDiscount>
  );
};

export default ModalDiscount;
