import styled from 'styled-components';
import {
  scarpaGray800,
  robotoRegular,
  white,
  electricPurple500,
  skyBlue25,
  skyBlue200,
} from '@habitech/shared';

export const ColumnDescription = styled.div`
  grid-column: 1/3;
  font-family: ${robotoRegular};
  .size {
    width: 130px;
  }
  .subtitle {
    width: 120px;
    font-family: ${robotoRegular};
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: ${scarpaGray800};
    margin-left: 32px;
  }
`;

interface IContainerV {
  isModal: boolean;
}

export const ContainerV = styled.div<IContainerV>`
  grid-column: ${(props) => (props.isModal ? '1/4' : '3/6')};
  .card-vis {
    background: ${skyBlue25};
    border-radius: 6px;
    padding: 20px 16px;
    margin-bottom: 20px;
    .borderBlue {
      border-bottom: 1px solid ${skyBlue200};
      margin-bottom: 8.5px;
    }
    .title {
      display: flex;
      align-items: center;
      padding-bottom: 16px;

      .image {
        margin-right: 30px;
      }
      .text {
        font-family: ${robotoRegular};
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 20px;
      }
    }
    .padding-maximum {
      padding-bottom: 12px;
    }
    .maximum {
      display: flex;
      justify-content: space-between;
      .price-max {
        font-family: ${robotoRegular};
        font-style: normal;
        .title-max {
          font-weight: 600;
          font-size: 12px;
          line-height: 20px;
        }
        .subtitle-max {
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
        }
      }
    }
  }
`;
export const RulesV = styled.div<IContainerV>`
  grid-column: ${(props) => (props.isModal ? '4/7' : '6/9')};
  .card-info {
    background: ${skyBlue25};
    border-radius: 6px;
    padding: 11px 16px;
    display: flex;
    margin-bottom: 12px;
    .info-text {
      flex-direction: column;
      margin-right: 30px;
      font-family: ${robotoRegular};
      font-style: normal;
      display: flex;
      margin-left: 30px;
      .title {
        font-weight: 600;
        font-size: 12px;
        line-height: 20px;
      }
      .subtitle {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
      }
    }
  }
`;
export const EditButtonRuleZone = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${white};
  border-radius: 6px;
  border: 1px solid ${electricPurple500};
  color: ${electricPurple500};
  width: 208px;
  height: 40px;
  font-family: ${robotoRegular};
  font-weight: 400;
  font-size: 16px;
  cursor: pointer;
  img {
    margin-left: 10px;
  }
`;
