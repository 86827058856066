import { revenueClient } from '../../utils/revenueClient';
import zonesClient, {
  zonesContainer,
  EspecialLambdaClient,
  historicalDealsClient,
  quotasClient,
  typologiesClient,
  approverClient,
} from '../../utils/zonesClient';

export const getApprovalRules = async () => {
  const { data } = await zonesClient.get('approval-rules');
  return data;
};

export const putChangeRules = async (body: any) => {
  const { data } = await zonesClient.put('approval-rules', body);
  return data;
};

export const getApprovalRulesResume = async ({
  country,
  filter_by,
  ids,
  start_date,
  end_date,
  current_date,
}: any) => {
  const { data } = await zonesContainer.get('approval-rules/resume', {
    params: {
      country,
      filter_by,
      ids,
      start_date,
      end_date,
      current_date,
    },
  });
  return data;
};

export const getRuleDescriptionById = async ({
  country,
  filter_by,
  ids,
  start_date,
  end_date,
  current_date,
  id,
}: any) => {
  const { data } = await zonesContainer.get(`approval-rules/${id}`, {
    params: {
      country,
      filter_by,
      ids,
      start_date,
      end_date,
      current_date,
    },
  });
  return data;
};

export const getGlobalRulesFeatures = async () => {
  const { data } = await zonesClient.get('general-rules/attributes');
  return data;
};

export const getGeneralRulesByIds = async ({
  country,
  filter_by,
  ids,
}: any) => {
  const { data } = await zonesClient.get('general-rules', {
    params: {
      country,
      filter_by,
      ids,
    },
  });
  return data;
};

export const deleteGeneralRulesByIds = async ({
  rule_id,
  filter_by,
  ids,
  approval_rule_id,
}: any) => {
  const { data } = await zonesClient.delete('general-rules', {
    params: {
      rule_id,
      filter_by,
      ids,
      approval_rule_id,
    },
  });
  return data;
};

export const expandGeneralRulesByIds = async (
  country: string | null,
  body: any
) => {
  const { data } = await zonesClient.post(
    `${country}/general-rules/expand`,
    body
  );
  return data;
};

export const postGlobalRules = async (body: any) => {
  const country = localStorage.getItem('country');
  const { data } = await zonesClient.post(`${country}/general-rules`, body);
  return data;
};

export const getElevatorTypology = async ({ nids }: any) => {
  const { data } = await zonesClient.get('approval/elevator', {
    params: { nids },
  });
  return data;
};

export const getApprovalRuleAdministration = async ({ country, nid }: any) => {
  const { data } = await zonesContainer.get('approval/administration', {
    params: {
      country,
      nid,
    },
  });
  return data;
};

export const getGarageTypology = async ({ nid }: any) => {
  const { data } = await zonesClient.get('approval/garage_typology', {
    params: { nid },
  });
  return data;
};

export const getApprovalFloorElevator = async ({ country, nids }: any) => {
  const { data } = await zonesClient.get('approval/elevator', {
    params: {
      country,
      nids,
    },
  });
  return data;
};

export const getApprovalElevator = async ({ country, nid }: any) => {
  const { data } = await zonesClient.get('approval/elevator_typology', {
    params: {
      country,
      nid,
    },
  });
  return data;
};

export const getRevenue = async ({ country, nid }: any) => {
  const { data } = await EspecialLambdaClient.get('', {
    params: {
      country,
      nid,
    },
  });
  return data;
};

export const getHistoricalInfo = async ({ country, nid }: any) => {
  const { data } = await zonesClient.get('approval/historical-deals', {
    params: {
      country,
      nid,
    },
  });
  return data;
};

export const getBedroomBathroom = async ({ nid }: any) => {
  const country = localStorage.getItem('country');
  const { data } = await zonesContainer.get(
    'approval/typology/bedroom-vs-bathroom',
    {
      params: { nid, country },
    }
  );
  return data;
};

export const getApprovalGoodZone = async ({ nid }: any) => {
  const { data } = await zonesClient.get('approval/good_zone', {
    params: {
      nid,
    },
  });
  return data;
};

export const getApproval = async ({ nid, country }: any) => {
  const { data } = await zonesClient.post(
    'approval',
    {},
    {
      params: {
        nid,
        country,
      },
    }
  );
  return data;
};

export const getHistoricalDeals = async ({ nid }: any) => {
  const { data } = await historicalDealsClient.get('get_negocios_historicos', {
    params: {
      nid,
    },
  });
  return data;
};

export const getQuotas = async ({ nid }: any) => {
  const { data } = await quotasClient.get('get_cupos_nid', {
    params: {
      nid,
    },
  });
  return data;
};

export const putApproval = async (body: any) => {
  const country = localStorage.getItem('country');
  const { data } = await zonesClient.put(`approval?country=${country}`, body);
  return data;
};

export const getRejectedReasons = async () => {
  const { data } = await zonesClient.get('approval/rejected_reasons', {
    params: {},
  });
  return data;
};

export const getRiskAlert = async (nids: string) => {
  const { data } = await zonesClient.get('approval/risk_alert', {
    params: { nids },
  });
  return data;
};

export const getCommitteePrice = async (nid: number) => {
  const country = localStorage.getItem('country');
  const { data } = await zonesContainer.get('approval/committee-price', {
    params: { nid, country },
  });
  return data;
};

export const getPricingAccordingZone = async (nid: number) => {
  const { data } = await zonesClient.get('approval/pricing-according-to-zone', {
    params: { nid },
  });
  return data;
};

export const getFinalPrice = async (nids: string) => {
  const country = localStorage.getItem('country');
  const { data } = await zonesContainer.get('approval/final-price', {
    params: { nids, country },
  });
  return data;
};

export const getBlacklist = async (nids: string) => {
  const country = localStorage.getItem('country');
  const { data } = await zonesClient.get('approval/blacklist', {
    params: { nids, country },
  });
  return data;
};

export const getPricingTrust = async ({ nid }: any) => {
  const country = localStorage.getItem('country');
  const { data } = await zonesContainer.get('approval/pricing-trust', {
    params: { nid, country },
  });
  return data;
};

export const getRemo = async ({ nids }: any) => {
  const { data } = await zonesClient.get('approval/remo', {
    params: { nids },
  });
  return data;
};

export const getApprovalDiscountHesh = async ({ nid }: any) => {
  const country = localStorage.getItem('country');
  const { data } = await zonesContainer.get('approval/discount-hesh', {
    params: { nid, country },
  });
  return data;
};

export const getRevenueCategory = async ({ nid }: any) => {
  const response = await revenueClient.get('/get_revenue_price_mx_apro', {
    params: { nid },
  });
  const [{ Categoria, DiasProb, HesH, approval_result }] = response.data;
  return { Categoria, DiasProb, HesH, approval_result };
};

export const getPropertyTypologies = async ({ nid }: any) => {
  const response = await typologiesClient.get('/get_tipologias', {
    params: { nid },
  });
  return response;
};

export const getLemonLimit = async ({ nid }: any) => {
  const country = localStorage.getItem('country');
  const { data } = await approverClient.get('/reject_nid_lemon', {
    params: { nid, country },
  });
  return data;
};
