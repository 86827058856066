import { capitalize } from '@mui/material';
import housePrice from '../../../../assets/images/house_price.svg';
import trash from '../../../../assets/images/trashRed.svg';
import emptyRules from '../../../../assets/images/emptyRules.svg';
import { IConditions, ICurrentGlobalRules } from './model';
import ImageButton, {
  CardRule,
  CardRuleEmpty,
  ColumnDescription,
  ContainerCurrentGlobalRules,
  ContainerRules,
  ExpandButton,
  StyledButtonContinue,
} from './styles';

const CurrentGlobalRules = ({
  rules,
  total_zones,
  handleShowModal = () => {},
  setShowCreate = () => {},
  loadingRules = false,
}: ICurrentGlobalRules) => {
  const country = localStorage.getItem('country');
  const typeHouseCo = ['Apartamento', 'Casa', 'Casa en conjunto'];
  const typeHouseMx = [
    'Departamento en condominio o unidad habitacional',
    'Casa sola',
    'Casa en condominio',
    'Departamento en edificio solo',
  ];
  const typeHouse = country === 'MX' ? typeHouseMx : typeHouseCo;

  const findConditionValue = (conditionKey: keyof IConditions) => {
    const conditions: IConditions = {
      equal_to: '=',
      less_than: '<',
      greater_than: '>',
      less_equal_to: '<=',
      greater_equal_to: '>=',
    };

    return conditions[conditionKey] || '';
  };

  const findAcceptReject = (value: number) => {
    const acceptReject = ['Rechazar todo.', 'Aceptar Todo.'];
    return acceptReject[value] || '';
  };

  const handleTransFormText = (rule: string, value: any) => {
    if (rule === 'Ask Price') {
      return Number(value).toLocaleString();
    }

    if (rule === 'Tipo de inmueble') {
      return typeHouse[+value - 1] || value;
    }

    return value;
  };

  return (
    <ContainerCurrentGlobalRules>
      <ColumnDescription>
        <div className="subtitle">Reglas globales vigentes</div>
      </ColumnDescription>
      {loadingRules && (
        <ContainerRules>
          {rules && rules.length > 0 ? (
            rules.map((item: any) => (
              // eslint-disable-next-line react/no-array-index-key
              <CardRule key={item.id}>
                <img
                  src={housePrice}
                  alt="calculator"
                  height={48}
                  data-testid="calculator"
                />
                <div className="description">
                  <div className="title">
                    {capitalize(item.approval_rule_name)}
                  </div>
                  <div className="subtitle">
                    {capitalize(item.general_rule_label)}{' '}
                    {findConditionValue(item.general_rule_conditon)}{' '}
                    {handleTransFormText(
                      item.general_rule_label,
                      item.general_rule_value
                    )}{' '}
                    {findAcceptReject(item.accept_all)}
                  </div>
                  <div className="small-letter">
                    Aplica para {item.zone_applied} de {`${total_zones}`.trim()}{' '}
                    zonas
                  </div>
                  {item.zone_applied < total_zones && (
                    <ExpandButton
                      data-testid="expand-button"
                      onClick={() => handleShowModal(true, true, item)}
                    >
                      <span className="text">
                        Expandir a zonas seleccionadas
                      </span>
                    </ExpandButton>
                  )}
                </div>
                <ImageButton onClick={() => handleShowModal(true, false, item)}>
                  <img
                    src={trash}
                    alt="calculator"
                    height={32}
                    className="img-trash"
                  />
                </ImageButton>
              </CardRule>
            ))
          ) : (
            <CardRuleEmpty>
              <img src={emptyRules} alt="imagen empty" className="img-trash" />
              <div className="description">
                <div className="value">
                  Estas zonas no cuenta con reglas activas en este momento
                </div>
                <div className="button-container">
                  <StyledButtonContinue onClick={() => setShowCreate(true)}>
                    <span className="value-text">Agregar reglas</span>
                  </StyledButtonContinue>
                </div>
              </div>
            </CardRuleEmpty>
          )}
        </ContainerRules>
      )}
    </ContainerCurrentGlobalRules>
  );
};

export default CurrentGlobalRules;
