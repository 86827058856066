import { FC } from 'react';

import {
  DateSelector,
  FilterContent,
  FilterWrapper,
  LabelMonthPicker,
  SectionTitle,
  WrapperMarketingFunnel,
} from './styles';

import funnelIcon from '../../../../assets/icons/funnel-icon.svg';
import { MonthPicker } from '@habitech/month-picker';
import { DropdownButton } from '@habitech/call-to-action';

export interface FunnelFiltersProps {
  title?: boolean;
  funnelSelectedDate: Date | undefined;
  onChangeFunnelDate: (date: any) => void;
  funnelTypeDate: number;
  setFunnelTypeDate: (type: number) => void;
}

const FunnelFilters: FC<FunnelFiltersProps> = ({
  title=true,
  funnelSelectedDate,
  onChangeFunnelDate,
  funnelTypeDate,
  setFunnelTypeDate,
}) => {
  const dateTypeOptions = [
    { label: 'Fecha de creación', value: 1 },
    { label: 'Fecha de gestión', value: 2 },
  ];

  return (
    <WrapperMarketingFunnel>
      {title && (
        <>
          <SectionTitle>
            <img src={funnelIcon} alt="" />
            <span>Funnel de marketing</span>
          </SectionTitle>
          <DateSelector>
            <DropdownButton
              variant="ghost"
              dataId="date-selector"
              setValue={(type) => {
                setFunnelTypeDate(Number(type));
              }}
              listOptions={dateTypeOptions}
              value={funnelTypeDate}
            />
          </DateSelector>
        </>
      )}

      <FilterWrapper>
        <FilterContent>
          <LabelMonthPicker>Seleccione el mes a consultar</LabelMonthPicker>
          <MonthPicker
            dataId="creation-date"
            value={[funnelSelectedDate, undefined] as [Date, Date?]}
            onChangeValue={(value: any) => {
              onChangeFunnelDate(value[0]);
            }}
            isRange={false}
            startYear={2019}
          />
        </FilterContent>
      </FilterWrapper>
    </WrapperMarketingFunnel>
  );
};

export default FunnelFilters;
