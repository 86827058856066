import zonesClient from '../../utils/zonesClient';

// eslint-disable-next-line import/prefer-default-export
export const getHesHParameters = async ({
  revenue_type,
  filter_by,
  ids,
}: any) => {
  const { data } = await zonesClient.get('/hesh/parameters', {
    params: { revenue_type, filter_by, ids },
  });
  return data;
};
export const putHesHParameters = async (body: any, country: string | null) => {
  const { data } = await zonesClient.put(`${country}/hesh/parameters`, body);
  return data;
};
