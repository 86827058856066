import styled from 'styled-components';
import { white, btnDisable } from '@habitech/shared';

export const DetailsRulesCardContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  border-radius: 6px;
  background: ${white};
  border: 1px solid ${btnDisable};
  overflow: hidden;
  margin: 0;
`;

export const SeparatorMargin = styled.div`
  height: 24px;
`;

export const SqueletorTree = styled.div`
  display: grid;
  border: 1px solid ${btnDisable};
  grid-column: span 3;
  height: 108px;
  padding: 24px;
  animation: fadeInOut 2s infinite;
  .container-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .container-title {
      display: flex;
      justify-content: space-between;
      .title {
        width: 200px;
        height: 25px;
        flex-shrink: 0;
        border-radius: 6px;
        background: #d9d9d9;
      }
      .circle {
        background: #d9d9d9;
        width: 24px;
        height: 24px;
        border-radius: 50%;
      }
    }
    .container-cubes {
      display: flex;
      justify-content: space-between;
      .container-cube {
        max-width: 137px;
        width: 137px;
        height: 41px;
        flex-shrink: 0;
        border-radius: 6px;
        background: var(--UI-Colors-Input_disabled, #f3f3f3);
      }
    }
  }
`;
export const SqueletorTwo = styled.div`
  display: grid;
  border: 1px solid ${btnDisable};
  border-right: 1px solid ${btnDisable};
  grid-column: span 2;
  height: 108px;
  padding: 24px;
  animation: fadeInOut 2s infinite;
  .container-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .container-title {
      display: flex;
      justify-content: space-between;
      .title {
        width: 200px;
        height: 25px;
        flex-shrink: 0;
        border-radius: 6px;
        background: #d9d9d9;
      }
      .circle {
        background: #d9d9d9;
        width: 24px;
        height: 24px;
        border-radius: 50%;
      }
    }
    .container-cubes {
      display: flex;
      justify-content: space-between;
      .container-cube {
        width: 137px;
        height: 41px;
        flex-shrink: 0;
        border-radius: 6px;
        background: var(--UI-Colors-Input_disabled, #f3f3f3);
      }
    }
  }
`;
export const Squeletor = styled.div`
  display: grid;
  border: 1px solid ${btnDisable};
  grid-column: span 1;
  height: 108px;
  padding: 24px;
  animation: fadeInOut 2s infinite;
  .container-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .container-title {
      display: flex;
      justify-content: space-between;
      .title {
        width: 200px;
        height: 25px;
        flex-shrink: 0;
        border-radius: 6px;
        background: #d9d9d9;
      }
      .circle {
        background: #d9d9d9;
        width: 24px;
        height: 24px;
        border-radius: 50%;
      }
    }
    .container-cube {
      width: 137px;
      height: 41px;
      flex-shrink: 0;
      border-radius: 6px;
      background: var(--UI-Colors-Input_disabled, #f3f3f3);
    }
  }
`;
