import zonesClient from '../../utils/zonesClient';

export const getQuotaParameters = async (
  stat: string,
  category: string,
  filter: string,
  ids: string
) => {
  const { data } = await zonesClient.get('quota/stats', {
    params: {
      stat,
      category,
      filter_by: filter,
      ids,
    },
  });
  return data;
};
export const postQuotaProject = async (body: any) => {
  const { data } = await zonesClient.post('/quota/historical-rate', body);
  return data;
};

export const getHistoricalRate = async ({ filter_by, ids }: any) => {
  const { data } = await zonesClient.get('/quota/historical-rate', {
    params: { filter_by, ids },
  });
  return data;
};

export const putParameterMarketShareSales = async (params: any) => {
  const country = localStorage.getItem('country');
  const url = `/${country}/quota/parameters/market_share_sales`;
  const { data } = await zonesClient.put(url, params);
  return data;
};
export const putParameterHistoricalLeads = async (params: any) => {
  const country = localStorage.getItem('country');
  const url = `/${country}/quota/parameters/historical_leads`;
  const { data } = await zonesClient.put(url, params);
  return data;
};
