import axios from 'axios';
const CONTENT_TYPE = 'application/json';

export const revenueClient = axios.create({
  baseURL: process.env.REACT_APP_REVENUE_API_URL,
  headers: {
    'x-api-key': process.env.REACT_APP_REVENUE_API_KEY ?? '',
    'Content-Type': CONTENT_TYPE,
  },
});
