import styled from 'styled-components';
import {
  electricPurple500,
  scarpaGray800,
  white,
} from '../../styles/stylesConstants';

export const WrapperApprovalRules = styled.div`
  display: grid;
  #comeback {
    font-family: 'Montserrat' !important;
    font-style: normal !important ;
    font-weight: 700 !important;
    font-size: 14px !important;
    line-height: 32px;
    height: 32px;
    color: ${scarpaGray800} !important;
    .icon {
      width: 22px;
      height: 22px;
      color: ${electricPurple500};
    }
    margin-bottom: 57px;
  }
`;

export const SubTitle = styled.div`
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.02em;
  color: ${scarpaGray800};
  margin-bottom: 14px;
`;

export const RulesWrapper = styled.div`
  background: ${white};
  box-shadow: 0px 0px 1px rgba(48, 49, 51, 0.05),
    0px 2px 4px rgba(48, 49, 51, 0.1);
  border-radius: 6px;
  display: grid;
  padding: 24px 18px;
`;
