import { useState } from 'react';

import { Button } from '@habitech/call-to-action';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useAppSelector, useAppDispatch } from '../../../../redux/hooks';
import { putAdminParameters } from '../../../../apis/pricing/pricing';
import { selectUser } from '../../../../redux/reducers/userSelectors';
import { alertStoreActions } from '../../../../redux/reducers/alertReducer';
import InputForm from '../../../../components/InputForm/InputForm';
import {
  PageTab,
  ZonePageList,
  ZonePagesContainer,
  ZonePagesTabs,
  WrapperTolerance,
  PoliticsSection,
  TitleSection,
  TitleSectionTop,
  PoliticsSubSection,
  SubTitleSection,
  Indicator,
  IndicatorText,
  RulesWrapper,
  Separator,
} from './styles';
import HabiGrey from '../../assets/habigrey.svg';
import CorporateIcon from '../../assets/corporate.svg';
import HouseIcon from '../../assets/house.svg';
import AssignListToSet from '../AssignListToSet';
import { AlertProps, Inputs, PagesTabs } from './model';
import getZoneInfo from '../../../ZoneDetail/services/zone.services';
import { zoneStoreActions } from '../../../../redux/reducers/zoneReducer';
import LinesIcon from '../../assets/lines.svg';
import TotalPlacesPerList from '../TotalPlacesPerList';
import UpdateLogic from '../UpdateLogic/UpdateLogic';

const PoliticsAndTolerance = () => {
  const [currentTab, setCurrentTab] = useState<PagesTabs>('sellers');
  const user = useAppSelector(selectUser);
  const zoneState = useAppSelector((state) => state.zoneStore);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { setZoneInformation } = zoneStoreActions;
  const { updateAlert } = alertStoreActions;
  const schema = Yup.object().shape({
    maxtolerance: Yup.number()
      .typeError('El valor debe ser un número')
      .min(1, `El valor debe ser mayor a 0`)
      .max(100, `El valor debe ser menor a 101`)
      .integer('El valor debe ser entero'),
    newtolerance: Yup.number()
      .typeError('El valor debe ser un número')
      .min(1, `El valor debe ser mayor a 0`)
      .max(100, `El valor debe ser menor a 101`)
      .integer('El valor debe ser entero'),
  });
  const {
    control,
    watch,
    formState: { errors },
    reset,
  } = useForm<Inputs>({ mode: 'onChange', resolver: yupResolver(schema) });

  const saveNewParams = async () => {
    await putAdminParameters({
      zone_id: zoneState.zoneInfo.zm_id,
      tolerance: watch('newtolerance')
        ? parseInt(watch('newtolerance'))
        : zoneState.zoneInfo?.tolerance,
      limit_admin: watch('maxtolerance')
        ? parseInt(watch('maxtolerance'))
        : zoneState.zoneInfo?.percentile_admin,
      user_email: user?.email,
    }).then((response) => {
      if (response?.data.success) {
        reset();
        getZoneInfo(zoneState.zone.middleZone?.id).then((response) => {
          dispatch(setZoneInformation(response?.data));
        });

        const alertData: AlertProps = {
          type: 'success',
          text: 'Se han cambiado las políticas de administación en la zona con éxito',
          showAlert: true,
        };
        dispatch(updateAlert(alertData));
      } else {
        const alertData: AlertProps = {
          type: 'error',
          text: 'Ocurrio un error al guardar los datos, Intente más tarde',
          showAlert: true,
        };
        dispatch(updateAlert(alertData));
      }
    });
  };

  return (
    <div>
      <WrapperTolerance>
        <ZonePagesContainer>
          <ZonePageList>
            <RulesWrapper>
              <TitleSectionTop className="section-title">
                <img src={LinesIcon} />
                Cambiar reglas de aprobación
              </TitleSectionTop>
              <ZonePagesTabs>
                <PageTab
                  active={currentTab === 'sellers'}
                  onClick={() => setCurrentTab('sellers')}
                >
                  Revisión de política de administración
                </PageTab>
                <PageTab
                  active={currentTab === 'buyers'}
                  onClick={() => setCurrentTab('buyers')}
                >
                  Revisión de cupos y listas
                </PageTab>
              </ZonePagesTabs>

              {currentTab === 'sellers' && (
                <PoliticsSection>
                  <TitleSection>
                    Cambio de políticas y tolerancia en la zona
                  </TitleSection>
                  <SubTitleSection>
                    Política actual de administración
                  </SubTitleSection>
                  <PoliticsSubSection last="false">
                    <div>
                      <Indicator>
                        <img src={CorporateIcon} alt="Percentil" />
                        <IndicatorText>
                          <div className="value">
                            {zoneState.zoneInfo?.percentile_admin}%
                          </div>
                          <div className="note">Tolerancia Máxima en (%)</div>
                        </IndicatorText>
                      </Indicator>
                    </div>
                    <div>
                      <InputForm
                        control={control}
                        errors={errors}
                        originType="habi"
                        iconLeft={
                          <img
                            src={HabiGrey}
                            alt="Politica de administración"
                          />
                        }
                        defaultValue=""
                        id="maxtolerance"
                        placeHolder="Nuevo %"
                        label="Nueva política de administración en %"
                        autoFocus={false}
                        type="number"
                        name="maxtolerance"
                      />
                    </div>
                  </PoliticsSubSection>
                  <SubTitleSection>
                    Tolerancia actual en la zona
                  </SubTitleSection>
                  <PoliticsSubSection last="true">
                    <div>
                      <Indicator>
                        <img src={HouseIcon} alt="Tolerancia" />
                        <IndicatorText>
                          <div className="value">
                            {zoneState.zoneInfo?.tolerance}%
                          </div>
                          <div className="note">
                            Tolerancia actual en percentiles
                          </div>
                        </IndicatorText>
                      </Indicator>
                    </div>
                    <div>
                      <InputForm
                        defaultValue=""
                        control={control}
                        errors={errors}
                        originType="habi"
                        iconLeft={<img src={HabiGrey} alt="House icon" />}
                        id="newtolerance"
                        placeHolder="Nuevo %"
                        label="Nueva tolerancia en la zona"
                        autoFocus={false}
                        type="text"
                        name="newtolerance"
                      />
                    </div>
                  </PoliticsSubSection>

                  {watch('maxtolerance')?.length > 0 ||
                  watch('newtolerance')?.length > 0 ? (
                    <>
                      <TitleSection style={{ marginBottom: '20px' }}>
                        ¿Deseas guardar los cambios realizados?
                      </TitleSection>
                      <div className="actions">
                        <Button
                          dataId="cancel"
                          id="cancel"
                          variant="stroke"
                          onClick={() =>
                            navigate('/portal/funnel-de-conversion')
                          }
                        >
                          No, Cancelar
                        </Button>
                        <Button
                          dataId="acept"
                          id="acept"
                          variant="primary"
                          disabled={Object.keys(errors).length > 0}
                          onClick={() => saveNewParams()}
                        >
                          Sí, Cambiar políticas
                        </Button>
                      </div>
                    </>
                  ) : (
                    ''
                  )}
                </PoliticsSection>
              )}
              {currentTab === 'buyers' && (
                <PoliticsSection>
                  <TitleSection>
                    Actualizar o añadir cupos a una lista
                  </TitleSection>

                  <TotalPlacesPerList />

                  <Separator />

                  <UpdateLogic />
                </PoliticsSection>
              )}
            </RulesWrapper>
            {currentTab === 'buyers' && (
              <RulesWrapper>
                <TitleSection>Asignar una lista a un conjunto</TitleSection>
                <AssignListToSet />
              </RulesWrapper>
            )}
          </ZonePageList>
        </ZonePagesContainer>
      </WrapperTolerance>
    </div>
  );
};

export default PoliticsAndTolerance;
