import { btnDisable, scarpaGray800, turquoise50 } from '@habitech/shared';
import styled from 'styled-components';
import { TableCell } from '@mui/material';

export const TablePaginationContainer = styled('div')`
  display: block;
  border-radius: 0px 0px 6px 6px;
  border-bottom: 1px solid ${btnDisable};
  border-left: 1px solid ${btnDisable};
  border-right: 1px solid ${btnDisable};
  .MuiTablePagination-displayedRows,
  .MuiTablePagination-selectLabel {
    font-size: 12px;
    font-weight: 400;
    color: ${scarpaGray800};
  }
  .MuiSvgIcon-root {
    width: 16px;
    height: 16px;
  }
  .MuiTablePagination-select {
    display: flex;
    align-items: center;
    border-radius: 6px;
    border: 1px solid ${btnDisable};
    padding: 8px 12px;
    font-size: 15px;
    font-weight: 500;
    color: ${scarpaGray800};
  }
  .MuiTablePagination-menuItem {
    font-size: 15px;
    font-weight: 500;
    color: ${scarpaGray800};
  }
`;

export const TablePaginationBody = styled('div')`
  display: block;
`;
export const StyledDataCellTwo = styled(TableCell)`
  && {
    text-align: center;
    font-weight: 400;
    font-size: 12px;
    border: 1px solid ${btnDisable};
    padding: 8px 16px;
    color: ${scarpaGray800};
  }
  &:nth-child(even):not(:last-child) {
    background: ${turquoise50};
  }
`;
