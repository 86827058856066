import { robotoRegular, scarpaGray800, white } from '@habitech/shared';
import styled from 'styled-components';
import {
  blue100,
  borderSelect,
  disabled2,
  lightBlue000,
  redWarning,
} from '../../../../styles/stylesConstants';

export const RuleFormContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  .MuiAutocomplete-listbox {
    font-family: ${robotoRegular};
    font-size: 16px;
    overflow: auto;
    .MuiAutocomplete-option[aria-selected='true'] {
      border-left: 1px solid ${blue100};
    }
  }
`;

export const AddNewElementWrapper = styled.div`
  margin-top: 54px;
  margin-left: 10px;
  button {
    width: 35px;
    height: 35px;
  }
`;

export const RuleFormWrapper = styled.div`
  width: 97%;
  display: grid;
  grid-template-columns: repeat(4, 23.5%);
  gap: 15px;
  margin-top: 28px;
`;

export const RuleFormItem = styled.div`
  .extra-large {
    height: 49px;
  }
`;

export const RuleFormButton = styled.div`
  grid-column: 4/5;
  display: flex;
  align-items: center;
  margin-top: 15px;
`;

export const FormSearchContainer = styled.div`
  display: flex;
  flex-direction: column;
  font-family: ${robotoRegular};
  font-weight: 400;
  .text-label {
    font-size: 14px;
    margin-bottom: 4px;
    color: ${scarpaGray800};
  }
  input {
    font-size: 16px;
    height: 43px;
    background: ${white};
    border: 1px solid ${borderSelect};
    border-radius: 6px;
    width: 200px;
    padding: 1px 10px;
  }
  input: focus {
    border: 2px solid ${blue100};
    background: ${lightBlue000};
  }
  input: disabled {
    background: ${disabled2};
    border: 1px dashed ${borderSelect};
    cursor: not-allowed;
    color: ${borderSelect};
  }
`;

export const WarningMessage = styled.div`
  font-family: ${robotoRegular};
  font-weight: 400;
  font-size: 12px;
  color: ${redWarning};
  margin-left: 6px;
`;
