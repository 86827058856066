import styled from 'styled-components';
import {
  robotoRegular,
  scarpaGray800,
  electricPurple500,
  white,
} from '@habitech/shared';

interface ITabContainer {
  firstSelected: boolean;
}
const TabContainer = styled.div<ITabContainer>`
  grid-column: 3/8;
  display: flex;

  .button-selected {
    background: ${electricPurple500};
    border-radius: ${(props) =>
      props.firstSelected ? '6px 0px 0px 6px' : '0px 6px 6px 0px'};
    border-color: ${electricPurple500};
    text-align: center;
    padding: 0;
    border-width: 0;
    width: 163px;
    height: 48px;
    cursor: pointer;
    .text {
      margin: 0 16px;
      justify-content: center;
      font-family: ${robotoRegular};
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      display: flex;
      align-items: center;
      color: ${white};
    }
  }
  .button-not-selected {
    background: ${white};
    border-radius: ${(props) =>
      props.firstSelected ? '0px 6px 6px 0px' : '6px 0px 0px 6px'};
    border: 1px solid ${scarpaGray800};
    text-align: center;
    padding: 0;
    width: 163px;
    height: 48px;
    cursor: pointer;
    .text {
      margin: 0 16px;
      justify-content: center;
      font-family: ${robotoRegular};
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      display: flex;
      align-items: center;
      color: ${scarpaGray800};
    }
  }
`;

export default TabContainer;
