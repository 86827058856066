import {
  blueLinks,
  electricPurple500,
  robotoRegular,
  scarpaGray800,
} from '@habitech/shared';
import styled from 'styled-components';
import { borderSelect } from '../../../../styles/stylesConstants';

const ReasonsFilterContainer = styled.div`
  width: 100%;
  display: grid;
  position: relative;
  grid-template-columns: repeat(2, 368px);
  gap: 24px;

  .label-span {
    font-family: ${robotoRegular};
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: ${scarpaGray800};
    margin-bottom: 10px;
  }
  .MuiListItemText-root {
    span {
      font-size: 16px !important;
      line-height: 24px;
      letter-spacing: 0;
    }
  }
  .MuiTypography-root {
    font-size: 16px !important;
    line-height: 24px;
    letter-spacing: 0;
  }
  .MuiFormControl-root {
    margin: 0;
    margin-top: 2px;
  }
  .extra-large {
    font-family: ${robotoRegular};
    font-style: normal;
    font-weight: 400;
  }
  .placeholder {
    position: absolute;
    top: 55%;
    left: 8px;
    justify-self: center;
    font-family: ${robotoRegular};
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: ${borderSelect};
  }
`;

export default ReasonsFilterContainer;

export const stylesMultiSelect = {
  select: {
    '.MuiSelect-icon': {
      fontSize: '24px',
      color: scarpaGray800,
    },
    fontSize: '16px',
    lineHeight: '21px',
  },
  menu: {
    PaperProps: {
      sx: {
        maxHeight: 200,
        fontSize: '16px',
        'ul > li > .MuiListItemText-root > span': {
          fontSize: '16px',
        },
        'ul > li.Mui-selected': {
          borderLeft: `2px solid ${blueLinks}`,
        },
      },
    },
  },
  checkbox: {
    color: `${electricPurple500} !important`,
    '.MuiCheckbox-root': { fontSize: '18px !important' },
    '.MuiSvgIcon-root': {
      fontSize: '16px !important',
    },
  },
  form: {
    m: 1,
    width: '100%',
    '.MuiOutlinedInput-root': { borderRadius: '6px', height: 48 },
  },
};
