import styled from 'styled-components';
import {
  btnDisable,
  maximumYellow25,
  monserratRegular,
  robotoRegular,
  scarpaGray25,
  scarpaGray300,
  scarpaGray500,
  scarpaGray800,
  white,
} from '@habitech/shared';

export const CardSmallContainer = styled.div`
  position: relative;
  display: grid;
  border: 1px solid ${btnDisable};
  grid-column: span 1;
  height: 108px;
  padding: 24px;
  &.negative {
    background: ${scarpaGray25};
  }
  &.warning {
    background: ${maximumYellow25};
  }
  .corner {
    position: absolute;
    right: 0;
  }

  .hover-buttons {
    width: 100%;
    height: 100%;
    background: rgba(37, 33, 41, 0.8);
    position: absolute;
    top: 0px;
    right: 0px;
    z-index: 201;
    display: none;
  }

  &:hover .hover-buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    .buttons {
      display: flex;
      gap: 8px;
      opacity: none !important;
    }
  }
  .container-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .container-title {
      display: flex;
      justify-content: space-between;
      .title {
        color: ${scarpaGray800};
        font-family: ${monserratRegular};
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }
      .circle {
        background: #d9d9d9;
        width: 24px;
        height: 24px;
        border-radius: 50%;
      }
    }
    .container-description {
      display: flex;
      justify-content: space-between;
      .text-one {
        color: ${scarpaGray800};
        font-family: ${robotoRegular};
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin-bottom: 4px;
      }
      .text-two {
        color: ${scarpaGray500};
        font-family: ${robotoRegular};
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-bottom: 4px;
      }
      .text-one-double {
        color: ${scarpaGray500};
        font-family: ${robotoRegular};
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-bottom: 8px;
        height: 28px;
      }
      .value-one-double {
        color: ${scarpaGray800};
        font-family: ${robotoRegular};
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin-bottom: 4px;
      }
      .margin-double {
        margin-bottom: 8px;
      }
      .text-two-double {
        color: ${scarpaGray500};
        font-family: ${robotoRegular};
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-align: end;
        max-width: 120px;
        height: 28px;
        text-align: start;
        margin-bottom: 8px;
      }
      .value-two-double {
        color: ${scarpaGray800};
        font-family: ${robotoRegular};
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        text-align: end;
      }
      .number {
        color: ${scarpaGray800};
        font-family: ${robotoRegular};
        font-size: 32px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
      }
      .value-one {
        color: ${scarpaGray800};
        font-family: ${robotoRegular};
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        text-align: end;
        margin-bottom: 4px;
      }
      .value-two {
        color: ${scarpaGray500};
        font-family: ${robotoRegular};
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-align: end;
        max-width: 120px;
        margin-bottom: 4px;
      }
      .value-end {
        display: flex;
        align-items: start;
      }
      .single {
        display: flex;
        padding: 7px 0px;
        justify-content: space-between;
        align-items: center;
        width: 100%;
      }
      .double {
        display: flex;
        padding: 12px 0px;
        justify-content: space-between;
        align-items: center;
        height: 100%;
      }
      .separator {
        width: 1px;
        height: 24px;
        background: ${scarpaGray300};
        margin-right: 19px;
      }
      .separator-small {
        color: var(--font-habi-pcom-secundary-aa, #6d6970);
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin: 0 12px;
      }
      .number-text {
        display: flex;
        align-items: center;
        gap: 8px;
        .number {
          color: var(--font-habi-pcom-main-aaa, #252129);
          font-family: ${robotoRegular};
          font-size: 24px;
          font-style: normal;
          font-weight: 300;
          line-height: normal;
        }
        .text {
          color: var(--font-habi-pcom-secundary-aa, #6d6970);
          font-family: ${robotoRegular};
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          margin-right: 19px;
        }
      }
    }
  }
`;
export const CustomButton = styled.button`
  display: flex;
  height: 40px;
  padding: 8px 24px;
  justify-content: center;
  align-items: center;
  flex: 1 0 0;
  border-radius: 6px;
  background-color: ${white};
  border: none;
  width: 126px;
  opacity: 1;
  cursor: pointer;
  .text {
    color: var(--Brand-Electric-purple, #7c01ff);
    text-align: center;
    font-family: ${robotoRegular};
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  &.disabled {
    background: ${btnDisable};
    cursor: not-allowed;
    opacity: 0.8;
  }
`;
