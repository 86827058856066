import { FC } from 'react';
import { Wrapper, Status } from '@googlemaps/react-wrapper';

import HabiLoader from '../HabiLoader/HabiLoader';
import Map from './Components/Map';

import MapWrapper from './styles';

interface MapZonesProps {
  location: any | null;
  polygonZone?: Array<number>;
}

const Loader = () => {
  return (
    <MapWrapper>
      <div className="map-loading">
        <HabiLoader size="small" />
      </div>
    </MapWrapper>
  );
};

const MapZones: FC<MapZonesProps> = ({ location, polygonZone = [] }) => {
  const mapsKey = process.env.REACT_APP_GOOGLE_MAPS_KEY!;

  const render = (status: Status) => {
    if (status === Status.LOADING) return <Loader />;
    return <div />;
  };

  return (
    <Wrapper render={render} apiKey={mapsKey}>
      <MapWrapper>
        <Map location={location} polygonZone={polygonZone} />
      </MapWrapper>
    </Wrapper>
  );
};

export default MapZones;
