import styled from 'styled-components';

import {
  disabledColor,
  monserratRegular,
  robotoRegular,
  scarpaGray25,
  scarpaGray500,
  scarpaGray800,
  white,
} from '@habitech/shared';

export const ContainerApprover = styled.div`
  display: grid;
  gap: 36px;
  grid-column: 1 / span 10;
  margin: 21px 28px 24px 0;
  .container-pending-approved {
    gap: 24px;
  }
`;

export const ContainerSearch = styled.div`
  align-items: center;
  align-self: stretch;
  background: ${white};
  border-radius: 6px;
  border: 1px solid ${disabledColor};
  display: flex;
  justify-content: space-between;
  padding: 32px 24px;
`;

export const Divisor = styled.div`
  display: flex;
  align-items: center;
  border-top: 1px solid ${disabledColor};
  margin: 28px 0;
`;

export const NoDataContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: ${robotoRegular};
  font-weight: 400;
  font-size: 16px;
  color: ${scarpaGray800};
  background: ${scarpaGray25};
  padding: 24px;
`;

export const DialogContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 42px;
  width: 400px;
`;

export const DialogTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;
  font-weight: 600;
  .title {
    font-family: ${monserratRegular};
    font-size: 16px;
    color: ${scarpaGray800};
  }
  .subtitle {
    font-family: ${robotoRegular};
    font-size: 10px;
    color: ${scarpaGray500};
    letter-spacing: 2px;
    margin-bottom: 8px;
  }
`;

export const DialogInput = styled.div`
  display: flex;
  width: 100%;
`;

export const DialogDescription = styled.div`
  font-family: ${robotoRegular};
  font-weight: 400;
  font-size: 14px;
  color: ${scarpaGray500};
  margin-top: 16px;
`;

export const DialogButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 80px;
  button {
    width: 100%;
    height: 48px !important;
    border-radius: 6px;

    &:first-child {
      margin-right: 8px;
    }
  }
  [data-id='cancel'] {
    padding: 8px 24px;
  }
  [data-id='ok'] {
    padding: 8px 24px;
  }
`;
