import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface UserStore {
  user?: IUser;
  userDate: Date;
  dateRange: [Date, Date?];
}

const TDY = new Date();

const initialState: UserStore = {
  userDate: new Date(TDY.getFullYear(), TDY.getMonth() - 2, 1),
  dateRange: [new Date(TDY.getFullYear(), TDY.getMonth() - 2, 1), TDY],
};

const userSlice = createSlice({
  name: 'userStore',
  initialState,
  reducers: {
    updateUser: (state, action: PayloadAction<IUser>) => {
      state.user = action.payload;
    },
    clearUser: (state) => {
      state.user = undefined;
    },
    updateDate: (state, action: PayloadAction<Date>) => {
      state.userDate = action.payload;
    },
    updateDateRange: (state, action: PayloadAction<Date[]>) => {
      state.dateRange = action.payload as [Date, Date?];

      const [start] = state.dateRange;
      state.userDate = start;
    },
  },
});

export const userStoreActions = userSlice.actions;

export default userSlice.reducer;
