import { FC, useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import { useNavigate } from 'react-router-dom';
import { IListOptions } from '@habitech/call-to-action/src/lib/dropdown-button/models/dropdown-button.model';
import { Button } from '@habitech/call-to-action';
import { SearchZoneInputProps, ZoneDetail, ZoneMatchType } from './model';
import { getZoneByMatch } from '../../../../apis/searchZones/searchZones';
import capitalize from '../../../../utils/capitalize';
import {
  AddOn,
  LiElement,
  Note,
  NoteExample,
  SearchArea,
  WrapperSearchZone,
} from './styles';
import { useAppDispatch } from '../../../../redux/hooks';
import { zoneStoreActions } from '../../../../redux/reducers/zoneReducer';

const SearchZoneInput: FC<SearchZoneInputProps> = ({
  getPolygon,
  clearMap,
}) => {
  const [open, setOpen] = useState(false);
  const [zone, setZone] = useState<ZoneDetail | null>(null);
  const [search, setSearch] = useState<string>('');
  const [matchWords, setMatchWords] = useState<string>('');
  const [options, setOptions] = useState<readonly ZoneDetail[]>([]);
  const [loadingN, setLoading] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const formatOptions = (options: any) => {
    const optionsFormatted: any = [];
    options.forEach((element: ZoneMatchType) => {
      optionsFormatted.push({
        title: `${capitalize(element.zm_label)}, ${element.zg_label}, ${
          element.city_label
        } `,
        value: element.zm_id,
        ...element,
      });
    });
    return optionsFormatted;
  };

  const getOptions = async (value: string) => {
    setMatchWords(value);

    if (!value) {
      setSearch('');
      return;
    }

    if (value.length > 2) {
      setLoading(true);
      await getZoneByMatch(value)
        .then((response) => {
          const pretty_options = formatOptions(response.data.data);
          setOptions(pretty_options);
        })
        .catch((error) => {
          console.error({ error });
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const mapToRedux = (item: IListOptions) => ({
    id: item.value,
    label: item.label,
  });

  const onZoneSelection = (details: ZoneDetail) => {
    const zone = {
      area: mapToRedux({ value: details.ma_id, label: details.ma_label }),
      middleZone: mapToRedux({ value: details.zm_id, label: details.zm_label }),
      metropolitan_area: mapToRedux({
        value: details.ma_id,
        label: details.ma_label,
      }),
      bigZone: mapToRedux({ value: details.zg_id, label: details.zg_label }),
      city: mapToRedux({ value: details.city_id, label: details.city_label }),
    };
    dispatch(zoneStoreActions.updateZone(zone));
    navigate('/portal/analisis-de-zona');
  };

  useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  useEffect(() => {
    if (search === '' && zone) {
      setZone(null);
      clearMap();
    }
  }, [search]);
  return (
    <WrapperSearchZone>
      <Note>
        *Con este buscador puedes seleccionar una zona mediana por el nombre
      </Note>
      <SearchArea>
        <Autocomplete
          id="asynchronous-options"
          open={open}
          onOpen={() => {
            if (matchWords.length > 0) setOpen(true);
          }}
          onClose={() => {
            setOpen(false);
          }}
          isOptionEqualToValue={(option, value) => option.title === value.title}
          getOptionLabel={(option) => option.title}
          options={options}
          onChange={(e, value) => {
            if (value && value.hasOwnProperty('value')) {
              setZone(value);
              getPolygon(value.value);
            }
          }}
          onInputChange={(e, value) => getOptions(value)}
          popupIcon={false}
          loading={loadingN}
          clearOnBlur={false}
          loadingText="Buscando zonas medianas..."
          noOptionsText="No existen coincidencias con esta palabra."
          renderOption={(props, option, { selected }) => (
            <LiElement {...props}>{option.title}</LiElement>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder="Ingresa el nombre de la zona mediana"
              onChange={(e) => {
                setSearch(e.target.value);
              }}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {loadingN ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          )}
        />
        <AddOn
          onClick={() =>
            document.getElementById('asynchronous-options')?.focus()
          }
        >
          <SearchRoundedIcon className="icon" />
        </AddOn>
      </SearchArea>
      <NoteExample>Ejemplo: Las Margaritas, Bogotá</NoteExample>
      {zone && search !== '' && (
        <Button
          dataId="go-to-zone"
          size="extra-large"
          onClick={() => onZoneSelection(zone)}
        >
          Ir a la zona seleccionada
        </Button>
      )}
    </WrapperSearchZone>
  );
};

export default SearchZoneInput;
