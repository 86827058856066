// Libraries
import { useEffect, useState } from 'react';
import { DropdownButton, Button } from '@habitech/call-to-action';
import {
  IListOptions,
  valProp,
} from '@habitech/call-to-action/src/lib/dropdown-button/models/dropdown-button.model';
import { useNavigate } from 'react-router-dom';
import {
  getAntiFunnelPieAM,
  getAntiFunnelPieZM,
  getFunnelStages,
} from '../../../../apis/funnel/funnel';
import { useAppSelector } from '../../../../redux/hooks';
import HabiLoader from '../../../../components/HabiLoader/HabiLoader';

// Images
import antiFunnelIcon from '../../../../assets/icons/anti-funnel-icon.svg';
import bannerRejected from '../../../../assets/images/banner-rejected.svg';
import rightArrow from '../../../../assets/icons/right-arrow.svg';

// Styles
import { SectionTitle } from '../FunnelFilters/styles';
import AntiFunnelWrapper, {
  AntiFunnelFilterContent,
  BannerRejectionReasons,
} from './styles';
import AntiFunnelGraph from '../AntiFunnelGraph';
import { LoaderContainer } from '../../../Utility/style';

// Interfaces
import { IAntiFunnelProps } from './model';

const AntiFunnel: React.FC<IAntiFunnelProps> = ({
  funnelDate,
  funnelFilter,
}) => {
  const [funnelSteps, setFunnelSteps] = useState<IListOptions[]>([]);
  const [stage, setStage] = useState<valProp>('total_leads');
  const navigate = useNavigate();
  const zoneState = useAppSelector((state) => state.zoneStore);
  const [loadedAm, setLoadedAm] = useState(false);
  const [loadedZm, setLoadedZm] = useState(false);
  const DICT_LABELS: Record<string, string> = {
    total_leads: 'Registros totales',
    unique_leads: 'Registros únicos',
    complete_leads: 'Registros completos',
    unique_in_zone: 'Registros únicos en zona',
    leads_to_pricing: 'Registros a pricing',
    assigned_leads: 'Registros asignados',
    managed_leads: 'Gestionados sales',
    interested_leads: 'Interesados sales',
    visited_leads: 'Visitados',
    committee_approved_leads: 'Aproados comité',
    closure_leads: 'Cierre efectuado',
    leads_with_opportunity: 'Registros con oportunidad',
  };

  const [zonePricing, setZonePricing] = useState({
    descartado: 0,
    duplicado: 0,
    no_interesado: 0,
    otros: 0,
    volver_a_llamar: 0,
    count_descartado: 0,
    count_duplicado: 0,
    count_no_interesado: 0,
    count_otros: 0,
    count_volver_a_llamar: 0,
    loaded: false,
  });

  const [metropolitanPricing, setMetropolitanPricing] = useState({
    descartado: 0,
    duplicado: 0,
    no_interesado: 0,
    otros: 0,
    volver_a_llamar: 0,
    count_descartado: 0,
    count_duplicado: 0,
    count_no_interesado: 0,
    count_otros: 0,
    count_volver_a_llamar: 0,
    loaded: false,
  });

  const onSelectStage = (value: valProp) => {
    setStage(value);
  };

  const getPieMaFunction = () => {
    setLoadedAm(false);
    getAntiFunnelPieAM({
      metropolitan_area_id: zoneState.zone.area.id,
      stageFilter: String(stage),
      date: funnelDate,
      filter: funnelFilter,
    }).then((response) => {
      if (response.success) {
        setMetropolitanPricing(response.data);
        setLoadedAm(true);
      }
    });
  };

  const getPieZmFunction = () => {
    setLoadedZm(false);
    getAntiFunnelPieZM({
      median_zone_id: zoneState.zone.middleZone.id,
      stageFilter: String(stage),
      date: funnelDate,
      filter: funnelFilter,
    }).then((response) => {
      if (response.success) {
        setZonePricing(response.data);
      }

      setLoadedZm(true);
    });
  };

  const getFunnelStagesFunction = () => {
    getFunnelStages().then((response) => {
      if (response.success) {
        const stages: IListOptions[] = response.data.map((item: string) => {
          return { label: DICT_LABELS[item], value: item };
        });

        setFunnelSteps(stages);
      }
    });
  }

  // Get funnel stages
  useEffect(() => {
    getFunnelStagesFunction();
  }, []);

  // Get pie chart info
  useEffect(() => {
    getPieMaFunction();
    getPieZmFunction();
  }, [stage, funnelDate]);

  return (
    <AntiFunnelWrapper>
      <SectionTitle>
        <img src={antiFunnelIcon} alt="" />
        <span>Antifunnel</span>
      </SectionTitle>
      <AntiFunnelFilterContent>
        <DropdownButton
          dataId="funnel-states"
          size="fluid"
          variant="white"
          label="Selecciona una etapa del funnel"
          placeholder="Selecciona"
          disabled={false}
          listOptions={funnelSteps}
          setValue={(value) => onSelectStage(value)}
        />
      </AntiFunnelFilterContent>

      {loadedAm && loadedZm ? (
        <AntiFunnelGraph
          zonePricing={zonePricing}
          metropolitanPricing={metropolitanPricing}
        />
      ) : (
        <LoaderContainer>
          <HabiLoader size='small' />
        </LoaderContainer>
      )}


      <BannerRejectionReasons>
        <img src={bannerRejected} alt="" />
        <div className='title'>¿Cuáles son las principales razones para rechazar un inmueble en Habi?</div>
        <Button
          dataId="rejected-reason"
          size="large"
          onClick={() => navigate('/portal/razones-rechazo')}
        >
          Ver razones de rechazo
          <img src={rightArrow} alt="" />
        </Button>
      </BannerRejectionReasons>

    </AntiFunnelWrapper>
  );
}

export default AntiFunnel;
