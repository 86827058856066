import { scarpaGray600, scarpaGray100 } from '@habitech/shared';
import styled from 'styled-components';
import { textGray3 } from '../../../../styles/stylesConstants';

export const ContainerFirstGraph = styled.div`
  grid-column: 1/6;
  margin-bottom: 16px;
`;
export const ContainerSecondGraph = styled.div`
  grid-column: 6/11;
  margin-left: -60px;
`;

export const GraphicArea = styled.div`
  justify-content: center;
  min-width: 420px;
  .with-border {
    padding-right: 18px;
    border-right: 1px solid ${scarpaGray100};
  }
  .without-border {
    padding-right: 18px;
    border-right: 'none';
  }
`;
export const TitleZone = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 8px;
  margin-right: 16px;
  height: 44px;
`;
export const TitleContainer = styled.div`
  display: flex;
  margin-bottom: 23px;
  margin-left: 23px;
`;
export const TitleAndSubtitle = styled.div`
  padding-right: 6px;
  border-right: 1px solid ${scarpaGray100};
  display: grid;
  grid-template-columns: 1fr;
  gap: 2px;
  margin-bottom: 33px;

  .title {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    display: flex;
    align-items: center;
    color: ${scarpaGray600};
  }

  .subtitle {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: ${textGray3};
  }
`;

export const CustomLegendContainer = styled.div`
  display: flex;
`;

export const CustomLegendItem = styled.div`
  display: flex;
  align-items: center;
  .text {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: #474747;
  }
`;
export const IndicatorColor = styled.div`
  background: ${(props) => props.color};
  margin-right: 14px;
  border-radius: 6px;
  width: 30px;
  height: 30px;
`;
export const TextY = styled.div`
  margin-left: 30px;
  text-align: center;
  writing-mode: vertical-lr;
  transform: rotate(180deg);
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 18px;
`;
export const TextX = styled.div`
  text-align: center;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 18px;
`;

export const ContainerGraph = styled.div`
  display: flex;
  min-height: 300px;
`;
